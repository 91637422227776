import { configureStore } from '@reduxjs/toolkit';
import forceReloadReducer from '../reduxStore/forceReloadReducer';
import SettingsReducer from '../reduxStore/SettingsReducer';
import UserReducer from '../reduxStore/UserReducer';
import TranslateReducer from '../reduxStore/TranslateReducer';
import themeReducer from '../reduxStore/themeReducer';
import CompoundNestedReducer from '../reduxStore/CompoundNestedReducer';
import NotificationReducer from '../reduxStore/NotificationReducer';
import RolesReducer from '../reduxStore/RolesReducer';



export default configureStore({
  reducer: {
    forceReload: forceReloadReducer,
    settingsData:SettingsReducer,
    userData:UserReducer,
    translateData:TranslateReducer,
    themeData:themeReducer,
    compoundNested:CompoundNestedReducer,
    notification: NotificationReducer,
    rolesData:RolesReducer
  },
});
