import React, { useState, useEffect, useRef } from "react";
import { Box, FormControlLabel,  Switch, Autocomplete } from "@mui/material";
import List from "@mui/material/List";

import Drawer from "@mui/material/Drawer";
import { useTranslation } from "react-i18next";
import TextField from "@mui/material/TextField";
import CustomeInput from "./CustomeInput";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import CustomeSelect from "./CustomeSelect";
import { RiSearchFill, RiArrowDropDownLine } from 'react-icons/ri'
import { MdArrowDropDown } from "react-icons/md";

import { toast } from "react-toastify";
import generalAPI from "../../../Global/Network/General";

export default function AdvanceSearch({ 
  openFilter,
  setOpenFIlter,
  customerName,
  setCustomerName,
  customerPhone,
  setCustomerPhone,
  fromDate,
  setFromDate,
  toDate,
  setToDate,
  customerType,
  setCustomerType,
  selectedCustomerType,
  setSelectedCustomerType,
  workTypes,
  setWorkTypes,
  selectedWorkType,
  setSelectedWorkType,
  salseEmployee,
  setSalseEmployee,
  PurposeOfPurchases,
  setPurposeOfPurchases,
  selectedPurposeOfPurchases,
  setSelectedPurposeOfPurchases,
  enableAdvanceSearch,
  setEnableAdvancedSearch,
  companyName,
  setCompanyName,
  openFormDatePicker,
  setOpenFormDatePicker,
  openToDatePicker,
  setOpenToDatePicker,
  setEmployeeList,
  employeeList,
  setSelectedEmployess,
  selectedEmployee,
  ApplyAdvanceSearch,
  setCustomerStatus,
  customerStatus,
  compounds,
  setCompounds,
  selectedCompounds,
  setSelectedCompounds
}) {
  const [t] = useTranslation("common");
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };
  const [loading, setLoading] = useState(false)

  const fromDateRef = useRef(null);
  const toDateRef = useRef(null);
  const controller = new AbortController();
  const [customeEmployeeList,setCutomeEmployeeList]=useState([])

  const handleChange1 = (date) => {
    setFromDate(date);
  };
  const handleChange2 = (date) => {
    setToDate(date);
  };

  const dateChanged = (date, num) => {
    if (num == 0) {
      setFromDate(date);
      setOpenFormDatePicker(false);
    } else if (num == 1) {
      setToDate(date);
      setOpenToDatePicker(false);
    }
  };
  const handlepointerEvvent = (e) => {
    try {
      localStorage.setItem("clientY", e.clientY);
      localStorage.setItem("clientX", e.clientX);
    } catch (err) {
      console.log(err?.message);
    }
  };


  const handleAdvanceSearch=()=>{
      setEnableAdvancedSearch(!enableAdvanceSearch)
  }

  // loading Resources here.
  useEffect(()=>{
    let abortController = new AbortController();
    loadMasterData()
    return () => {
      abortController.abort();
    }
  },[])
  const loadMasterData = async () => {
    let data = null;
    try {

      setLoading(true)
      let masters = [
        {
          type: 'customer_types',
        },
        {
          type: 'working_types',
        },
        {
          type: 'purpose_of_purchases'
        },
        {
          type: 'employees'
        },
        {
          type: 'compounds'
        },

      ]


      data = await generalAPI.getMasters(
        {
          params: {
            masters: masters
          }
        }
      );
      if (data && data?.status) {

        setCustomerType(Array.isArray(data?.data?.customer_types) ? data?.data?.customer_types : [])
        setWorkTypes(Array.isArray(data?.data?.working_types) ? data?.data?.working_types : [])
        setPurposeOfPurchases(Array.isArray(data?.data?.purpose_of_purchases) ? data?.data?.purpose_of_purchases : [])
        setCompounds(Array.isArray(data?.data?.compounds) ? data?.data?.compounds : [])
        setEmployeeList(Array.isArray(data?.data?.employees) ? data?.data?.employees : [])
        
      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false)
  }



  useEffect(()=>{
    let arr=[];
    if(employeeList&&employeeList?.length){
      employeeList&&employeeList?.map((itm)=>{
        if(itm?.user)
          arr.push({
            label:itm?.user?.full_name,
            id:itm?.id,
          })
      })
    }
    setCutomeEmployeeList(arr)

  },[employeeList])

  const handleClearSearchFilters=()=>{
    setCustomerName('')
    setCustomerPhone('')
    setFromDate(null)
    setToDate(null)
    setSelectedCustomerType(null)
    setSelectedWorkType(null)
    setSelectedPurposeOfPurchases(null)
    setSelectedCompounds(null)
    setSelectedEmployess('')
    setCustomerStatus(null)
  }


  const list = (anchor) => (
    <Box
      sx={{ width: "100%" }}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          height: "75px",
        }}
      >
        <span
          style={{
            textAlign: "left",
            font: "normal normal 20px/37px Cairo-Bold",
            letterSpacing: "0px",
            color: "#22232B",
            opacity: "1",
            position:'relative',
            top:'0'
          }}
        >
          {t("ADVANCESEARCH.title")}
        </span>
      </Box>
      <List sx={{ width: "100%" }}>
        <CustomeInput
          title={t("ADVANCESEARCH.customer_name")}
          value={customerName}
          setValue={setCustomerName}
          customeTop="-50px"

        />
        <CustomeInput
          title={t("ADVANCESEARCH.customer_phone_number")}
          value={customerPhone}
          setValue={setCustomerPhone}
          customeTop="-55px"
        />
        <Box
          sx={{
            width: "80%",
            height:'30px',
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            "& .MuiInputLabel-root": {
              textAlign: "left",
              font: "normal normal  16px/30px Cairo-Bold",
              letterSpacing: "0px",
              color: "#22232B",
              opacity: "1",
              padding: "0 10px 0px 5px",
              position: "relative",
              top: "21px  !important",
            },
            margin: "auto",
            marginTop: "0px",
            position:'relative',
            top:'-30px'
          }}
        >
          <Box style={{}} className="my-1">
            <span
              style={{
                textAlign: "left",
                font: "normal normal  15px/29px Cairo-Bold",
                letterSpacing: "0px",
                color: "#22232B",
                opacity: "1",
              }}
            >
              {t("ADVANCESEARCH.Name_ofCumpany")}
            </span>
          </Box>
        </Box>
       
        <LocalizationProvider dateAdapter={AdapterDayjs} className="asdasdsa">
          <Box
            sx={{
              width: "80%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              "& .MuiInputLabel-root": {
                textAlign: "left",
                font: "normal normal  16px/30px Cairo-Bold",
                letterSpacing: "0px",
                color: "#22232B",
                opacity: "1",
                padding: "0 10px 0px 5px",
                position: "relative",
                top: "21px  !important",
              },
              margin: "auto",
              '& .MuiInputBase-input':{
                fontSize:'14px !important'
             },
             position:'relative',
             top:'-35px'

            }}
          >
            <Box sx={{display:'flex',alignItems:'center',position:'relative',width:'48%',
               marginTop:'-30px'
             }}>
              <DatePicker
                open={openFormDatePicker}
                label={t('ADVANCESEARCH.fromDate')}
                inputFormat="MM/DD/YYYY"
                value={fromDate}
                onChange={(e) => dateChanged(e, 0)}
                maxDate={toDate?toDate:null}
                renderInput={(params) => <TextField {...params} variant="standard" sx={{width:'100%'}} />}
                sx={{
                  zIndex: 9999999,
                  "& .MuiPickersPopper-root": {
                    zIndex: 99999999 + " !important",
                  },
                  top: "30px",
                }}
                onClose={(e) => setOpenFormDatePicker(false)}
                components={{
                  OpenPickerIcon: KeyboardArrowDownIcon
                }}
                onOpen={(e) => setOpenFormDatePicker(true)}
              />
              {fromDate?<CloseIcon sx={{position:'absolute',top:'51px',right:'15px',cursor:'pointer',fontSize:'20px'}} onClick={()=>setFromDate(null)}/>:null}

            </Box>
            <Box sx={{display:'flex',alignItems:'center',position:'relative',width:'48%',
             marginTop:'-30px'
            }}>
            <DatePicker
              open={openToDatePicker}
              label={t('ADVANCESEARCH.toDate')}
              inputFormat="MM/DD/YYYY"
              // inputRef={toDateRef}
              value={toDate}
              onChange={(e) => dateChanged(e, 1)}
              renderInput={(params) => <TextField {...params} variant="standard" sx={{width:'100%',fontSize:'14px'}} />}
              onClose={(e) => setOpenToDatePicker(false)}
              minDate={fromDate?fromDate:null}
              components={{
                OpenPickerIcon: KeyboardArrowDownIcon
              }}
              onOpen={(e) => setOpenToDatePicker(true)}
            />
              {toDate?<CloseIcon sx={{position:'absolute',top:'51px',right:'15px',cursor:'pointer',fontSize:'20px'}} onClick={()=>setToDate(null)}/>:null}

            </Box>
          </Box>
        </LocalizationProvider>
        
        <CustomeSelect 
           value={selectedCustomerType} 
           setValue={setSelectedCustomerType} 
           data={customerType} 
           setData={setCustomerType}
           message={t('ADVANCESEARCH.no_cutomerTypeData')}
           title={t('ADVANCESEARCH.SelectCustomerWorkType')}
           customeTop="-50px"
        />
        <Box
          sx={{
            display:'flex',
            alignItems:'start',
            justifyContent:'start',
            flexDirection:'column',
            width: "80%",
            margin:'auto',
            display: "flex",
            alignItems: "center",
            '& .MuiTypography-root':{
              fontFamily:'Cairo-Medium'
            },
            top: '-45px',
            position: 'relative',
          }}
        >
          <span style={{
          //  textAlign: "left",
           font: "normal normal  16px/30px Cairo-Bold",
           letterSpacing: "0px",
           color: "#22232B",
           opacity: "1",
          //  padding: "0 10px 0px 0",
           position: "relative",
          //  top: "21px  !important",
           width:'100%'
           }}>
              {t('ADVANCESEARCH.customerStatus')}
          </span>
          <FormControlLabel control={<Switch 
             checked={customerStatus} 
             onChange={(e)=>{setCustomerStatus(e.target.checked)}} 
             />} 
             sx={{fontFamily:'Cairo-Medium',width:'100%',paddingRight:'10px !important'}} label={customerStatus?t('ADVANCESEARCH.active'):t('ADVANCESEARCH.unActive')} 
          />
  
        </Box>

         <CustomeSelect 
           value={selectedEmployee} 
           setValue={setSelectedEmployess} 
           data={customeEmployeeList} 
           setData={setCutomeEmployeeList}
           hasLable={true}
           message={t('ADVANCESEARCH.no_salesEmployee')}
           title={t('ADVANCESEARCH.salesEmployee')}
           customeTop="-70px"
         />

        {/* <Box sx={{width:'80%',margin:'auto',
           "& .MuiInputLabel-root":{
            textAlign: "left", font: "normal normal  16px/30px Cairo-Bold", letterSpacing: "0px", color: "#22232B", opacity: "1"
           , padding:'0 0 0px 0px',position:'relative',top:'0  !important'
          },
          overflow:'hidden',

      }}>
          <Autocomplete
            id="combo-box-demo"
            value={selectedEmployee}
            options={customeEmployeeList}
            onChange={(e,value)=>{
                console.log('valuennnn',value)
                setSelectedEmployess(value)
            }}
            getOptionLabel={(option)=> option.label}
            renderInput={(params) => <TextField {...params} label={t("ADVANCESEARCH.salesEmployee")} variant="standard"/>}
          />

        </Box> */}

        <CustomeSelect 
           value={selectedPurposeOfPurchases} 
           setValue={setSelectedPurposeOfPurchases} 
           data={PurposeOfPurchases} 
           setData={setPurposeOfPurchases}
           message={t('ADVANCESEARCH.no_resones')}
           title={t('ADVANCESEARCH.selectedReason')}
           customeTop="-90px"
        />
        {
          enableAdvanceSearch ?
            <>
              <CustomeSelect 
                value={selectedWorkType} 
                setValue={setSelectedWorkType} 
                data={workTypes} 
                setData={setWorkTypes}
                message={t('ADVANCESEARCH.no_workside')}
                title={t('ADVANCESEARCH.sideOfWork')}
                customeTop="-80px"

              />
              <Box 
                sx={{
                position:'relative',
                top: "-100px",
                }}
              >
                <Autocomplete
                  multiple
                  sx={{
                    width:'100%',
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'start',
                    "& .MuiInputLabel-root":{
                      textAlign: "left", 
                      font: "normal normal  16px/30px Cairo-Bold", 
                      letterSpacing: "0px", 
                      color: "#22232B", 
                      opacity: "1", 
                      // padding:'0 10px 0px 0px',
                      marginLeft: "-12px",
                      position:'relative',
                      top:'25px  !important',
                    },
                    "& #demo-simple-select":{
                      textAlign: "left",
                      font: "normal normal  15px/29px Cairo !important", 
                      letterSpacing: "0px", 
                      color: "#22232B !important", 
                      opacity: "1",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderBottom: "1px solid #818181",
                      borderRadius: 0,
                    },
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      borderBottom: "1px solid #818181",
                      borderRadius: 0,
                    },
                    ".customIconClass": {
                      marginLeft: "-8px !important",
                      marginRight: "-8px !important",
                    },
                    pl: "30px",
                    pr: "30px",
                  }}
                  options={compounds ? compounds : []}
                  getOptionLabel={(option) => option?.name}
                  value={selectedCompounds ? selectedCompounds : []}
                  onChange={(e, newValue) =>
                    // handleInputChange(19, newValue, "compound_ids")
                    setSelectedCompounds(newValue)
                  }
                  popupIcon={<MdArrowDropDown className="customIconClass" />}
                  clearIcon={
                    <CloseIcon
                      sx={{ fontSize: "20px", color: "#000" }}
                      onClick={() => setSelectedCompounds([])}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("ADVANCESEARCH.compound_interest")}
                    />
                  )}
                  // className={`${customersErrorsObject?.compound_ids?.error}`}
                />          
              </Box>
            </> 
          : null
        }
        {/* {
          enableAdvanceSearch?
          <CustomeInput
            title={t("ADVANCESEARCH.companyname")}
            value={companyName}
            setValue={setCompanyName}
          />:null
         } */}
         {
          // !enableAdvanceSearch?
          <Box sx={{
            width:'100%',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
            height:'100px',
            position:'relative',
            top:'-60px'
          }}>
              <Box sx={{
                width: enableAdvanceSearch?'150px':"128px", 
                height: "49px", 
                background: "#1E6A99 0% 0% no-repeat padding-box", 
                boxShadow: "0px 15px 30px #43425D1A",
                opacity: "1",
                display:'flex',
                justifyContent:'center',
                alignItems:'center',
                cursor:'pointer'
              }}
              onClick={handleAdvanceSearch}
              
              >
                  <span style={{
                    color: "#FFFFFF", textAlign: "center", font: "normal normal 600 16px/30px Cairo", letterSpacing: enableAdvanceSearch?'0.5px':"0px", opacity: "1"
                  }}>{enableAdvanceSearch?t('ADVANCESEARCH.close_advancesearch'):t('ADVANCESEARCH.advancesearch')}</span>
              </Box>
          </Box>
          // :
          // <Box><br/><br/><br/></Box>
          }

        <Box sx={{
          width:'100%',
          display:'flex',
          justifyContent:'center',
          alignItems:'center',
          height:'80px',
          position:'relative',
          top:'-80px'
        }}>
            <Box sx={{
              width: "60%", 
              height: "49px", 
              background: "#994D1E 0% 0% no-repeat padding-box", 
              boxShadow: "0px 15px 30px #43425D1A",
              opacity: "1",
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              cursor:'pointer',
              position:'relative'
            }}
            onClick={ApplyAdvanceSearch}
            
            >
                <span style={{
                  color: "#FFFFFF", textAlign: "center", font: "normal normal 600 16px/30px Cairo", letterSpacing: "0px", opacity: "1"
                }}>{t('ADVANCESEARCH.applaySearch')}</span>
              <RiSearchFill color='#FFFFFF' style={{position:'absolute',top:'15px',left:'10px',fontSize:'22px'}}/>

            </Box>
            <Box sx={{
              width: "30%", 
              height: "49px", 
              background: "#1e6a99 0% 0% no-repeat padding-box", 
              boxShadow: "0px 15px 30px #43425D1A",
              opacity: "1",
              display:'flex',
              justifyContent:'center',
              alignItems:'center',
              cursor:'pointer',
              position:'relative',
              margin:'0 5px'
            }}
            onClick={handleClearSearchFilters}
            
            >
                <span style={{
                  color: "#FFFFFF", textAlign: "center", font: "normal normal 600 16px/30px Cairo", letterSpacing: "0px", opacity: "1"
                }}>{t('ADVANCESEARCH.clearSearch')}</span>
              {/* <RiSearchFill color='#FFFFFF' style={{position:'absolute',top:'15px',left:'10px',fontSize:'22px'}}/> */}

            </Box>
        </Box>

        <br />
        <br />
        <br />
        <br />




      </List>
    </Box>
  );

  return (
    <Drawer
      anchor={"right"}
      open={openFilter}
      onClose={() => setOpenFIlter(false)}
      sx={{
        "& .MuiPaper-root": {
          top: "60px",
          zIndex: 200,
          backgroundColor: "#fff",
          width: "320px",
          '&::-webkit-scrollbar':{
            width: "7px",
          },
          /* Track */
          "&::-webkit-scrollbar-track":{
            borderTopLeftRadius: '15px',
            borderBottomLeftRadius: '15px',
            background: '#ffffff',
          },
           
          /* Handle */
          "&::-webkit-scrollbar-thumb":{
            background: '#349CDD',
            borderRadius:' 5px',
          },
          
          /* Handle on hover */
          "&::-webkit-scrollbar-thumb:hover": {
            background:" #349CDD" ,
          }
        },
        zIndex: 200,
        "& .MuiBackdrop-root": {
          backgroundColor: "rgba(0,0,0,0.001) !important",
        },
        // width:'381px'
      }}
      className="advanceSearch"
      // hideBackdrop={true}
    >
      {list("left")}
    </Drawer>
  );
}
