import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Context from "./assets/js/context";
import { toast, Zoom, Flip, Bounce } from "react-toastify";
import "react-checkbox-tree/lib/react-checkbox-tree.css";
import "react-toastify/dist/ReactToastify.css";
import "./assets/css/test1.css";
import Cookies from "universal-cookie";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import rtlPlugin from "stylis-plugin-rtl";
import { CacheProvider } from "@emotion/react";
import createCache from "@emotion/cache";
import { useSelector, useDispatch } from "react-redux";
import { setUserToken } from "./reduxStore/UserReducer";
import { setscreenwidth } from "./reduxStore/SettingsReducer";
import { setLoadNotificationsData } from "./reduxStore/NotificationReducer";
import { Box } from "@mui/material";
import NetworkDetector from "./Hoc/NetworkDetector";
import { Login, AppRedirectPage } from "./Global/index";
import Echo from "laravel-echo";

import {
  Dashboard,
  ProtectionAdmin,
  SideMenu,
  RegisteredCustomers,
  ImageMapDraw,
  ImageMapView,
  ViewImage,
  AddNewCustomer,
  AvailableCompounds,
  CompoundMap,
  CompoundBuilding,
  WorkType,
  Employee,
  PurposeOfPurchases,
  MartialStatus,
  CustomerType,
  ContactMethod,
  NationalIdTypes,
  RealEstate,
  CallType,
  CallStatus,
  CallDestinations,
  CallReason,
  UnitLocation,
  RoomType,
  ImageType,
  Role,
  Calls,
  UnitStatus,
  ViewUnit,
  UnitType,
  Room,
  Unit,
  PaymentType,
  Phase,
  Company,
  CompanyProfile,
  Compound,
  Orders,
  OrderContract,
  Invoices,
  UserProfile,
  Discount,
  MasterUnitType,
  Department,
  BulkInsertFloors,
  BulkDeleteFloors,
  BulkInsertBuildings,
  BulkDeleteBuildings,
  Notification,
  NotificationType,
  FavouritePage,
  MyFavouritePage,
  CompoundDepartment,
} from "./Admin/index";
import { Home, ProtectionClient } from "./Client/index";

import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import Pusher from "pusher-js";
import Preloading from "./Global/Preload/Preloading";

const pusher = new Pusher("ba67f88f77a189074291", {
  cluster: "ap1",
  encrypted: false,
});
const cookies = new Cookies();

const App = ({ code, dataTranslate }) => {
  const [state] = useState({
    data: [],
    ms: [],
    data1: [],
    Dash: [],
    check: "",
    che: "login",
    new: "",
    inprogress: "",
    closed: "",
    approved: "",
    rejected: "",
    archived: "",
    noti: "",
    desc: "",
    commenter: "",
    body: "",
    title: "",
    SiFilter: [],
  });
  const directions = useSelector((state) => state.settingsData.directions);
  const Translate_ar = useSelector(
    (state) => state.translateData.translation_ar
  );
  const toastUnitId = React.useRef(null);
  const toastCallId = React.useRef(null);

  const maintheme = useSelector((state) => state.themeData.maintheme);
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const [shownNotifications, setShownNotifications] = useState([]);
  const dispatch = useDispatch();
  const token = cookies.get("token");

  const [receivedMessage, setReceivedMessage] = useState("");
  let subscribedToUnitsChannel = false;
  let subscribedToCallsChannel = false;
  const subscribeToNotifications = (roles) => {
    let currentNotification = null;
    if (
      rolesRedux?.get_unit_status_change_notifications?.value &&
      !subscribedToUnitsChannel
    ) {
      // subscribe to units channel
      const unitsNotificationChannel = pusher.subscribe("units-channel");
      subscribedToUnitsChannel = true;

      unitsNotificationChannel.bind("units-event", (data) => {
        console.log('PUSHER--DATA-1',data)
        // setLoadNotifications(true);
        dispatch(setLoadNotificationsData(true));
        if (
          !currentNotification ||
          currentNotification != data.notification.id
        ) {
          currentNotification = data.notification.id;
          setShownNotifications([...shownNotifications, data.notification.id]);
          console.log("notifi=>1:", data);

          toast.info(data?.notification?.title, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "dark",
            toastId: data.notification.id,
          });
        }
      });
    }
    if (
      rolesRedux?.get_calls_notifications?.value &&
      !subscribedToCallsChannel
    ) {
      // subscribe to calls channel
      const callsNotificationChannel = pusher.subscribe("calls-channel");
      subscribedToCallsChannel = true;
      callsNotificationChannel.bind("calls-event", (data) => {
        console.log('PUSHER--DATA-2',data)
        // setLoadNotifications(true);
        dispatch(setLoadNotificationsData(true));
        if (
          !currentNotification ||
          currentNotification != data.notification.id
        ) {
          currentNotification = data.notification.id;
          console.log("notifi=>2:", data);
          setShownNotifications([...shownNotifications, data.notification.id]);
          toast.info(data?.notification?.title, {
            position: "bottom-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: false,
            progress: undefined,
            theme: "dark",
            toastId: data.notification.id,
          });
        }
      });
    }
  };

  useEffect(() => {
    if (token) {
      dispatch(setUserToken(token));
    }
  }, [token]);

  const unsubscribeFromNotifications = () => {
    pusher.unsubscribe("units-channel");
    pusher.unsubscribe("calls-channel");
  };

  useEffect(() => {
    subscribeToNotifications();
    return () => {};
  }, [rolesRedux]);

  useEffect(() => {
    return () => {
      setShownNotifications([]);
      unsubscribeFromNotifications();
    };
  }, []);
  const [theme, setTheme] = useState(
    createTheme({
      direction: directions, // Both here and <body dir="rtl">
    })
  );

  try {
    const bodyWeb = document.querySelector("body");
    if (bodyWeb) {
      bodyWeb.style.background = maintheme?.background;
    }
    i18next.init({
      interpolation: { escapeValue: false }, // React already does escaping
      lng: "ar",
      resources: {
        ar: {
          common: Translate_ar,
        },
      },
    });
  } catch (error) {
    console.log(error?.message);
  }

  useEffect(() => {
    localStorage.setItem("direction", directions);
    if (directions === "rtl") {
      // setCacheRtl(
      //   createCache({
      //     key: 'muirtl',
      //     stylisPlugins: [rtlPlugin,prefixer],
      //    })
      // )
      document.dir = "rtl";
      const htmlTag = document.querySelector("html");
      if (htmlTag) {
        htmlTag.lang = "ar";
      }
      cookies.set("language", "ar");
    } else {
      // setCacheRtl(createCache({ key: 'css' }))
      document.dir = "ltr";
      cookies.set("language", "en");
    }

    setTheme(
      createTheme({
        direction: directions, // Both here and <body dir="rtl">
      })
    );
  }, [directions]);

  const isrtl = React.useMemo(() => {
    return createCache({
      key: directions === "rtl" ? "muirtl" : "muiltr",
      stylisPlugins: directions === "rtl" ? [rtlPlugin] : [],
    });
  }, [directions]);

  useEffect(() => {
    window.addEventListener("resize", () => {
      dispatch(setscreenwidth(window.innerWidth));
    });
  }, [window.innerWidth]);

  return (
    <Box
      sx={{
        width: "100%",
        height: "100%",
      }}
    >
      <I18nextProvider i18n={i18next}>
        <CacheProvider value={isrtl}>
          <ThemeProvider theme={theme}>
            <BrowserRouter>
              <Context.Provider
                value={{
                  value: state,
                  action: {},
                }}
              >
                <Routes>
                  <Route path="/" element={<Login />} />
                  <Route path="/admin" element={<SideMenu />}>
                    <Route
                      index
                      element={
                        <ProtectionAdmin>
                          <Dashboard />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="dashboard"
                      element={
                        <ProtectionAdmin>
                          <Dashboard />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="my_favourite_pages"
                      element={
                        <ProtectionAdmin>
                          <MyFavouritePage />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="registered-customers"
                      element={
                        <ProtectionAdmin>
                          <RegisteredCustomers />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="registered-customers/add"
                      element={
                        <ProtectionAdmin>
                          <AddNewCustomer />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="available-compounds"
                      element={
                        <ProtectionAdmin>
                          <AvailableCompounds />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="available-compounds/compound-map"
                      element={
                        <ProtectionAdmin>
                          <CompoundMap />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="available-compounds/compound-map/compound-building"
                      element={
                        <ProtectionAdmin>
                          <CompoundBuilding />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="image-draw"
                      element={
                        <ProtectionAdmin>
                          <ImageMapDraw />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="image-view"
                      element={
                        <ProtectionAdmin>
                          <ImageMapView />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="drawer-mapper-show"
                      element={
                        <ProtectionAdmin>
                          <ViewImage />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="user/profile"
                      element={
                        <ProtectionAdmin>
                          <UserProfile />
                        </ProtectionAdmin>
                      }
                    />
                    {/* //masters routes here................................. */}
                    <Route
                      path="masters/compound"
                      element={
                        <ProtectionAdmin>
                          <Compound />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/worktype"
                      element={
                        <ProtectionAdmin>
                          <WorkType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/employee"
                      element={
                        <ProtectionAdmin>
                          <Employee />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/favourite_pages"
                      element={
                        <ProtectionAdmin>
                          <FavouritePage />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/department"
                      element={
                        <ProtectionAdmin>
                          <Department />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/purpose-of-purshases"
                      element={
                        <ProtectionAdmin>
                          <PurposeOfPurchases />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/marital-status"
                      element={
                        <ProtectionAdmin>
                          <MartialStatus />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/customer-type"
                      element={
                        <ProtectionAdmin>
                          <CustomerType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/contact-method"
                      element={
                        <ProtectionAdmin>
                          <ContactMethod />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/national-id-types"
                      element={
                        <ProtectionAdmin>
                          <NationalIdTypes />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/real-estates"
                      element={
                        <ProtectionAdmin>
                          <RealEstate />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/call-types"
                      element={
                        <ProtectionAdmin>
                          <CallType />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="masters/call-statuses"
                      element={
                        <ProtectionAdmin>
                          <CallStatus />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/call-destinations"
                      element={
                        <ProtectionAdmin>
                          <CallDestinations />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/call-reasons"
                      element={
                        <ProtectionAdmin>
                          <CallReason />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/unit-locations"
                      element={
                        <ProtectionAdmin>
                          <UnitLocation />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/room-types"
                      element={
                        <ProtectionAdmin>
                          <RoomType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/image-types"
                      element={
                        <ProtectionAdmin>
                          <ImageType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/roles"
                      element={
                        <ProtectionAdmin>
                          <Role />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="Calls"
                      element={
                        <ProtectionAdmin>
                          <Calls />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="orders"
                      element={
                        <ProtectionAdmin>
                          <Orders />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="invoices"
                      element={
                        <ProtectionAdmin>
                          <Invoices />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/unit-statuses"
                      element={
                        <ProtectionAdmin>
                          <UnitStatus />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/unit-types"
                      element={
                        <ProtectionAdmin>
                          <UnitType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/rooms"
                      element={
                        <ProtectionAdmin>
                          <Room />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/units"
                      element={
                        <ProtectionAdmin>
                          <Unit />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/payment_types"
                      element={
                        <ProtectionAdmin>
                          <PaymentType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/phases"
                      element={
                        <ProtectionAdmin>
                          <Phase />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="masters/companies"
                      element={
                        <ProtectionAdmin>
                          <Company />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/discounts"
                      element={
                        <ProtectionAdmin>
                          <Discount />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/master-unit-types"
                      element={
                        <ProtectionAdmin>
                          <MasterUnitType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/bulk-insert-floors"
                      element={
                        <ProtectionAdmin>
                          <BulkInsertFloors />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/bulk-delete-floors"
                      element={
                        <ProtectionAdmin>
                          <BulkDeleteFloors />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/bulk-insert-buildings"
                      element={
                        <ProtectionAdmin>
                          <BulkInsertBuildings />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/notifications"
                      element={
                        <ProtectionAdmin>
                          <Notification />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/notification-types"
                      element={
                        <ProtectionAdmin>
                          <NotificationType />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/bulk-delete-buildings"
                      element={
                        <ProtectionAdmin>
                          <BulkDeleteBuildings />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="masters/compound-departments"
                      element={
                        <ProtectionAdmin>
                          <CompoundDepartment />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="order_contract"
                      element={
                        <ProtectionAdmin>
                          <OrderContract />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="view-unit"
                      element={
                        <ProtectionAdmin>
                          <ViewUnit />
                        </ProtectionAdmin>
                      }
                    />
                    <Route
                      path="company/profile"
                      element={
                        <ProtectionAdmin>
                          <CompanyProfile />
                        </ProtectionAdmin>
                      }
                    />

                    <Route
                      path="*"
                      element={
                        <ProtectionAdmin>
                          <Preloading />
                        </ProtectionAdmin>
                      }
                    />
                  </Route>

                  {/* clients route here  */}
                  <Route path="/home">
                    <Route
                      index
                      element={
                        <ProtectionClient>
                          <Home />
                        </ProtectionClient>
                      }
                    />
                    <Route
                      path="company/profile"
                      element={
                        <ProtectionClient>
                          <CompanyProfile />
                        </ProtectionClient>
                      }
                    />
                  </Route>
                </Routes>
              </Context.Provider>
            </BrowserRouter>
          </ThemeProvider>
        </CacheProvider>
      </I18nextProvider>
    </Box>
  );
};

export default NetworkDetector(App);
