import { Box, Divider, List, ListItem, Typography } from '@mui/material'
import React, { useState } from 'react'
import { useTranslation } from "react-i18next";
import CustomTableHeader from '../CustomTable/CustomTableHeader';
import * as FaIcons from 'react-icons/fa'
import * as CgIcons from 'react-icons/cg'
import { useSelector } from 'react-redux'
import helpers from '../../../assets/js/helper';
import InvoicePrintPopup from '../../Masters/Invoices/InvoicePrintPopup';
import PaymentPrintPopup from '../../Masters/Invoices/PaymentPrintPopup';
import AddFormDailog from '../../Masters/Invoices/AddFormDailog'
import RolesOfUserAPI from '../../../Global/Network/RoleUser';


function PersonalInfoTab(props) {
  const [t] = useTranslation("common");

  return (

    <Box
      sx={{
        width: '100%',
        justifySelf: 'center',
        display: 'flex',
        justifyContent: 'center',
        margin:'15px 0'

      }}
    >
      <Box
        sx={{
          width: { xs: '100%', sm: '100%', md: '70%', lg: '50%' },
          justifyContent: 'center',
          padding:'18px 0'
        }}
      >
        <Box sx={{
          display: 'flex',
          backgroundColor: '#994D1E',
          justifyContent: 'space-between',
          alignItems: 'stretch',
          padding:'10px',
          flexDirection: 'row',
        }}>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#FFFFFF',
              fontFamily: 'Cairo',
              fontSize: '13px',
              width:'100%',
              display:'flex',
              justifyContent:'center'

            }}
          >
            {t('CUSTOMER.personal_information')}
          </Typography>
        </Box>
        <Box sx={{
          display: 'flex',
          justifyContent: 'start',
          alignItems: 'start',
          flexDirection: 'column',
          margin: '10px 0'
        }}

        >
          <Box sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            width:'100%',
            flexDirection: 'row',
            margin: '10px 0'
          }}

          >
            <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              display:'flex',
              flex:1,
              justifyContent:'flex-start'

            }}
          >
            {t('CUSTOMER.customer_name') + " : "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              display:'flex',
              flex:3,
              justifyContent:'flex-start'

            }}
          >
            {props?.row?.user?.full_name}
          </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            width:'100%',
            flexDirection: 'row',
            margin: '10px 0'
          }}

          >
            <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              display:'flex',
              flex:1,
              justifyContent:'flex-start'

            }}
          >
            {t('CUSTOMER.username') + " : "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              display:'flex',
              flex:3,
              justifyContent:'flex-start'

            }}
          >
            {props?.row?.user?.username}
          </Typography>
          </Box>
          <Box sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'start',
            width:'100%',
            flexDirection: 'row',
            margin: '10px 0'
          }}

          >
            <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              flex:1,
              display:'flex',
              justifyContent:'flex-start'

            }}
          >
            {t('CUSTOMER.gender') + " : "}
          </Typography>
          <Typography
            variant="subtitle2"
            sx={{
              color: '#994D1E',
              fontFamily: 'Cairo',
              fontSize: '13px',
              display:'flex',
              flex:3,
              justifyContent:'flex-start'

            }}
          >
            {props?.row?.user?.gender?"انثى":"ذكر"}
          </Typography>
          </Box>

        </Box>
      </Box>
    </Box>

  )
}

export default PersonalInfoTab