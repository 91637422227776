import { Box, Button, Dialog } from "@mui/material";
import React, { useRef } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import Webcam from "react-webcam";
import entityAPI from "../../Global/Network/Entity";
import { toast } from "react-toastify";

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          sx={{ direction: "rtl", justifyContent: "flex-end", width: "100%" }}
        >
          <Button
            variant="outlined"
            sx={{
              width: "50% !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.cancel_button")}
          </Button>
          <Button
            variant="contained"
            sx={{
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={props?.submit}
          >
            {t("GLOBAL.capture")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function WebCam(props) {
  const [t] = useTranslation("common");
  const webcamRef = useRef(null);

  const handleChangeImage = async () => {
    const imageSrc = webcamRef.current?.getScreenshot({
      width: 480,
      height: 360,
      screenshotFormat: "image/jpeg",
    });

    if (typeof imageSrc !== "string" || !imageSrc.includes(",")) {
      console.error("Failed to capture image");
      return;
    }

    const byteCharacters = atob(imageSrc.split(",")[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const timestamp = Date.now();
    const byteArray = new Uint8Array(byteNumbers);
    const imageBlob = new Blob([byteArray], {
      type: "image/jpeg",
    });
    const imageFile = new File([imageBlob], `image_${timestamp}.jpeg`, {
      type: "image/jpeg",
    });
    
    await props?.setCustmerImage(prevState => ({
      file: imageFile,
      url: window.URL.createObjectURL(imageFile),
      name: "image.jpeg",
      index: 1,
    }));

    if (props?.user_id) {
      const formData = new FormData();
      formData.append("image", imageFile);
      formData.append(`type_id`, props?.user_id);
      formData.append(`name`, "camera");
      formData.append(`image_type_id`, 1);
      formData.append(`type`, "customer");

      let result = await entityAPI.AddRequestFile({
        data: formData,
        type: "customer",
      });

      if (result?.status) {
        props.setAddForm(false);
        props.setOpenUploadImage(false);
      } else {
        toast.error("An error occur!");
      }
    } else {
      props.setAddForm(false);
      props.setOpenUploadImage(false);
    }
  };

  const handleClose = () => {
    props.setAddForm(false);
  };

  return (
    <Dialog open={props.addForm} onClose={handleClose}>
      <Box
        display="flex"
        sx={{ justifyContent: "center", overflowX: "hidden" }}
      >
        {props.addForm ? (
          <Webcam
            audio={false}
            ref={webcamRef}
            screenshotFormat="image/jpeg"
            width={1080}
          />
        ) : null}
      </Box>
      <RenderFooter
        open={props.addForm}
        setOpen={props.setAddForm}
        submit={handleChangeImage}
      />
    </Dialog>
  );
}
