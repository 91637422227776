import React, { useState } from "react";
import Popup from "../../General/Popup";
import { Box, Typography, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { TitleText, ContainerOfInputLogin } from "../Login/ThemeModal";
import { useSelector, useDispatch } from "react-redux";
import SendMessageImage from "../../assets/img/ForgetPasswordIcon.png";
import SendIcon from '@mui/icons-material/Send';

export default function ResetPassword(props) {
  const [t] = useTranslation("common");
  const logintheme = useSelector((state) => state.themeData.logintheme);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);

  const [phone, setPhone] = useState(null);
  const setPhoneData = (e) => {
    setPhone(e.target.value);
  };
  const showSmsForm = () => {
    props?.setShowPasswordForm(false);
    props.setShowRecivedSmsCodeForm(true);
  };
  return (
    <Popup
      open={props?.showRestPasswordForm}
      setOpen={props?.setShowPasswordForm}
      sizeType="md"
      CustomeWidth="637px"
      CustomeHeight={
        screenwidth <= 900 ? (screenwidth <= 700 ? "350px" : "330px") : "317px"
      }
      title={t("LOGIN.reset_password_title")}
      hasButton1={false}
      hasButton2={false}
      handlebutton1={null}
      handlebutton2={null}
      button1Title={t("GLOBAL.save_button")}
      button2Title={t("GLOBAL.cancel_button")}
      hasTitle={false}
    >
      <Box sx={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <TitleText
            variant="p"
            logintheme={logintheme}
            sx={{
              fontSize: {
                xs: "18px !important",
                sm: "18px !important",
                md: "20px !important",
                lg: "21px !important",
                xl: "25px !important",
              },
            }}
          >
            {t("LOGIN.reset_password_title")}
          </TitleText>
        </Box>
        <ContainerOfInputLogin logintheme={logintheme} customeWidth="65%">
          <TextField
            fullWidth
            focused
            
            variant="filled"
            label={t("LOGIN.phone_number")}
            placeholder="07519279989"
            value={phone}
            onChange={setPhoneData}
            className="user-name"
          />
        </ContainerOfInputLogin>
        <Box
          sx={{
            display: "flex",
            alignContent: "center",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography
            variant="span"
            sx={{
              textAlign: "left",
              textDecoration: "underline",
              font: "normal normal medium 18px/37px Cairo",
              letterSpacing: "1px",
              color: logintheme?.primaryColor,
              opacity: "1",
              cursor: "pointer",
              margin: "0 6px",
            }}
            onClick={showSmsForm}
          >
            {t("LOGIN.Submit")}
          </Typography>
          {/* <img
            src={SendMessageImage}
            style={{
              width: "20px",
              height: "20px",
              transform: "matrix(-1, 0, 0, -1, 0, 0)",
              background:
                "transparent url('img/ForgetPasswordIcon.png') 0% 0% no-repeat padding-box",
              opacity: "1",
              cursor: "pointer",
            }}
            onClick={showSmsForm}
          /> */}
          <SendIcon
            sx={{
              fontSize:'30px',
              color:logintheme?.iconsColor,
              cursor: "pointer",
              transform: 'matrix(-1, 0, 0, -1, 0, 0)',
              cursor: "pointer",
              width: "20px",
              height: "20px",
            }}
            onClick={showSmsForm}
          />
        </Box>
      </Box>
    </Popup>
  );
}
