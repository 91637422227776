import callAPI from '../ApiConfig'
import store from '../../../app/store'

const roleCategories = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "role_categories",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const roleCategoriesList = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "role_categories_list",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}
const roleCategory = async (props) => {
    const storeState = store.getState();

    return await callAPI({
        route: "role_category",
        method: "get",
        signal: props?.signal,
        params: props?.params,
        headers: {
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }

    })

}

const roleCategoryAPI = {
    roleCategories: roleCategories,
    roleCategoriesList: roleCategoriesList,
}
export default roleCategoryAPI