const ObjectOfErrors = {
    id:"",
    name:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    color:{
        value: "",
        error: false,
        message: "",
        required: false
    },
    compound_id :{
        value: "",
        error: false,
        message: "",
        required: false
    },
}
export const resetErrors = (customerObject) => {
    var objectKeys = Object.keys(customerObject);
   
    var updatedObject = {
        ...customerObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, customerObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        customerObject[key]["error"] = true
        customerObject[key]["message"] = messages
       }
    })

  




    return customerObject
}
export const ObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
    id:updatedObject?.id,
    name: {
        value: updatedObject?.name,
        error: false,
        message: "",
        required: false
    },
    color:{
        value: updatedObject?.color,
        error: false,
        message: "",
        required: false
    },
    compound_id :{
        value: updatedObject?.compound_id ,
        error: false,
        message: "",
        required: false
    },
    }
    
    return mergedCustomerObject;
}
export const restCustomeObject=(customerObject,obj)=>{
    // var objectKeys = Object.keys(customerObject);
   
    try {
        
        customerObject[obj]["error"] = false
        customerObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return customerObject
}
export default ObjectOfErrors;