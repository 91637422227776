import { Box,TextField,Button} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/UnitType/UnitType'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import UnitTypesAPI from '../../../Global/Network/UnitType/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import buildingAPI from '../../../Global/Network/Building'
import MasterUnitTypeAPI from '../../../Global/Network/MasterUnitTyps'
import Images from '../../Components/Images'
import compoundAPI from '../../../Global/Network/Compound'
import blockAPI from '../../../Global/Network/Block'
import { debounce } from 'debounce';

const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const rolesRedux=useSelector(state=>state.rolesData.roles)

    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }

console.log("props.selectedBuilding",props.selectedBuilding);
    return(
        <Box className='Container-fluid'>
            <Box className="row my-3">
                <Box className="col-12 col-sm-12 col-md-10 col-lg-4">
                    <CustomTextField
                        label={t('UNITTYPE.add_name')}
                        value={props?.name}
                        error={ErrorsObject?.name?.error}
                        message={ ErrorsObject?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                           props?.setName(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                        onClearClick={() => {
                            props?.setName('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                    />
                    <Box sx={{margin:'10px 0'}}></Box>
                    <CustomeSelectField
                        label={t('UNITTYPE.add_building')}
                        haswidth={true}
                        value={props?.selectedBuilding}
                        list={props?.buildings?props?.buildings:[]}
                        error={ErrorsObject?.building_id?.error}
                        message={ ErrorsObject?.building_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                            console.log('asdssss',newValue);
                           props?.setSelectedBuilding(newValue)
                           setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedBuilding(null)
                            setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                        onChangeText={(e) => {
                          props.handleSearch({search_text: e.target.value}, "buildings");
                        }}
                    />
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4">
                  <CustomeSelectField
                        label={t('UNITTYPE.add_compounds')}
                        haswidth={true}
                        value={props?.selectedCompound}
                        list={props?.compounds?props?.compounds:[]}
                        // error={ErrorsObject?.compound_id?.error}
                        // message={ ErrorsObject?.compound_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                           props?.setSelectedCompound(newValue)
                        //    setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedCompound(null)
                            // setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                    />
                    <Box sx={{margin:'10px 0'}}></Box>
                     <CustomeSelectField
                        label={t('UNITTYPE.add_masterUnitName')}
                        value={props?.selectedMasterUnitType}
                        list={props?.masterUnitTypes?props?.masterUnitTypes:[]}
                        haswidth={true}
                        error={ErrorsObject?.master_unit_type_id?.error}
                        message={ ErrorsObject?.master_unit_type_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                           props?.setSelectedMasterUnitType(newValue)
                           setErrorsObject(restCustomeObject(ErrorsObject,'master_unit_type_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedMasterUnitType(null)
                            setErrorsObject(restCustomeObject(ErrorsObject,'master_unit_type_id'))
                        }}
                        onChangeText={(e) => {
                          props.handleSearch({search_text: e.target.value}, "unitTypes");
                        }}
                    />
             
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-4">
                   
                     <CustomeSelectField
                        label={t('UNITTYPE.add_blocks')}
                        haswidth={true}
                        value={props?.selectedBlock}
                        list={props?.blocks?props?.blocks:[]}
                        // error={ErrorsObject?.compound_id?.error}
                        // message={ ErrorsObject?.compound_id?.message}
                        readOnly={false}
                        onChange={(e,newValue) => {
                           props?.setSelectedBlock(newValue)
                        //    setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                        onClearClick={() => {
                            props?.setSelectedBlock(null)
                            // setErrorsObject(restCustomeObject(ErrorsObject,'building_id'))
                        }}
                        onChangeText={(e) => {
                          props.handleSearch({search_text: e.target.value}, "blocks");
                        }}
                    />
                    
                </Box>
                <Box className="col-12 col-sm-12 col-md-12 col-lg-12 my-3">
                    
                 {
                    rolesRedux?.view_image?.value?<Images 
                     imageList={props?.imageList} 
                     setImageList={props.setImageList}
                     isUpdateImage={props?.isUpdateImage} 
                     setIsUpdatedImage={props?.setIsUpdatedImage}
                     editMode={props?.editMode}
                     loading={props?.loading}
                     setLoading={props?.setLoading}
                     type={props?.type}
                     type_id={props?.type_id}
                     notHasSlice={props?.notHasSlice}
                      
                     hideImageType={props?.hideImageType}
                    />:null
                    // :null
                    }
               </Box>
                
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [buildings,setBuildings]=useState([])
    const [masterUnitTypes,setMasterUnitTypes]=useState([])
    const [selectedBuilding,setSelectedBuilding]=useState(null)
    const [selectedMasterUnitType,setSelectedMasterUnitType]=useState(null)
    const [imageList,setImageList]=useState([])
    const [isUpdateImage,setIsUpdatedImage]=useState(false)
    const [compounds,setCompounds]=useState([])
    const [selectedCompound,setSelectedCompound]=useState(null)
    const [blocks, setBlocks] = useState([])
    const [selectedBlock, setSelectedBlock] = useState(null)



    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setSelectedBuilding(props?.object?.building)
           setSelectedCompound(props?.object?.building?.block?.compound)
           setSelectedBlock(props?.object?.building?.block)
           setSelectedMasterUnitType(props?.object?.master_unit_type)
           let images = []
           props?.object?.images && props?.object?.images?.length > 0 && props?.object?.images?.map((image, index) => {
               // let checkImage = getExtension(image?.file_path)
               images.push({
                   url: process.env.REACT_APP_API_URL_image + image?.image_url,
                   file: null,
                   index: index,
                   id: image?.id,
                   type: 'image',
                   name: image?.name,
                   description:image?.description,
                   image_type:image?.image_type,
               })
           })
           setImageList(images)
        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data=new FormData()
        if(name)
        data.append('name',name)
        if(selectedBuilding&&selectedBuilding?.id)
        data.append('building_id',selectedBuilding?.id)
        if(selectedMasterUnitType&&selectedMasterUnitType?.id)
        data.append('master_unit_type_id',selectedMasterUnitType?.id)

        console.log('sadasdasdasdsads',props?.object)

        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                name:name,
                building_id:selectedBuilding?.id,
                master_unit_type_id:selectedMasterUnitType?.id,
                id:props?.object?.id,
                _method:'put'
            }
             result=await UnitTypesAPI.updateUnitType({
            type:'unit_type',
            type_id:props?.object?.id,
            id:props?.object?.id,
            data:data
            });
        }else{
            imageList&&imageList.forEach((image,index) => {
                data.append('images['+index+'][image]', image?.file);
                data.append('images['+index+'][image_type_id]', 1);
                data.append('images['+index+'][name]', image?.name);
                data.append('images['+index+'][description]', image?.description);
            });
            result=await UnitTypesAPI.addUnitType({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        setName('')
        setSelectedBuilding(null)
        setSelectedMasterUnitType(null)
        setImageList(null)
        setSelectedBlock(null)
        setSelectedCompound(null)
    }

    useEffect(()=>{
        const abortController = new AbortController()

        if(!props?.object)
          clearForm()
        // else{
            loadDataOfCompounds()
            loadDataOfMasterUnitTypes({})
        // }
        return ()=>{
            abortController.abort()
        }
    },[props?.addForm,props?.object])
    
    useEffect(()=>{
        setSelectedBlock(null)
        setSelectedBuilding(null)
        
        loadBlocks({})
    },[selectedCompound]);
    useEffect(()=>{
        setSelectedBuilding(null)
        loadDataOfBuildings({})
    },[selectedBlock]);
    const loadDataOfCompounds=async()=>{
        let data=null;
        try{
            data=await  compoundAPI.compoundsList();
            if(data&&data?.status){
                console.log('sdasdasdasdsa',data?.data);
                setCompounds(data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }

    const handleSearch = debounce((value, name)=>{
        if (name == "blocks") loadBlocks(value);
        else if (name == "buildings") loadDataOfBuildings(value);
        // else if (name == "floors") loadFloors(value);
        else if (name == "unitTypes") loadDataOfMasterUnitTypes(value);
        // else if (name == "customers") loadCustomers(value);
    }, 500);

    const loadBlocks = async (searchParams) => {
        if (!selectedCompound || !selectedCompound?.id) {
          setBuildings([])
          setBlocks([])
          return
        }
        setLoading(true)
    
    
        try {
          const result = await blockAPI.blocks({
            params: {
                page_size: 10,
                page: 0,
                compound_id: selectedCompound?.id,
                search_text: searchParams?.search_text ? searchParams?.search_text : ""
            },
          })
          if (result.status) {
    
            setBlocks(result?.data?.data)
          }
        } catch (err) {
          console.log(err?.message)
          toast.error(err?.message)
        }
        setLoading(false)
    }
    const loadDataOfBuildings=async(searchParams)=>{
        if(!selectedBlock||!selectedBlock?.id){
           setBuildings([]);
           return;
        }
        let data=null;
        try{
            data=await buildingAPI.buildings({
                params:{
                    page_size: 10,
                    page: 0,
                    block_id:selectedBlock?.id,
                    name: searchParams?.search_text ? searchParams?.search_text : ""
                }
            });
            if(data&&data?.status){
                console.log('sdasdasdasdsa',data?.data);
                setBuildings(data?.data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }
    const loadDataOfMasterUnitTypes=async(searchParams)=>{
        let data=null;
        try{
            data=await MasterUnitTypeAPI.MasterUnitTypes({
                params: {
                    page_size: 10,
                    page: 0,
                    name: searchParams?.search_text ? searchParams?.search_text : ""
                }
            });
            if(data&&data?.status){
                setMasterUnitTypes(data?.data?.data)
            }else{
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            console.log(err?.message);
        }
    }

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('UNITTYPE.updateTitle'):t('UNITTYPE.addTitle')}
        content={<RenderContent
             name={name} 
             setName={setName}
             setBuildings={setBuildings}
             buildings={buildings}
             setMasterUnitTypes={setMasterUnitTypes}
             masterUnitTypes={masterUnitTypes}
             setSelectedBuilding={setSelectedBuilding}
             selectedBuilding={selectedBuilding}
             setSelectedMasterUnitType={setSelectedMasterUnitType}
             selectedMasterUnitType={selectedMasterUnitType}
             setImageList={setImageList}
             imageList={imageList}
             editMode={props?.object?.id?true:false}
             loading={loading}
             setLoading={setLoading}
             isUpdateImage={isUpdateImage}
             setIsUpdatedImage={setIsUpdatedImage}
              
             type={'unit_type'}
             notHasSlice={true}
             type_id={props?.object?.id}
             hideImageType={true}
             open={props.addForm} 
             setOpen={props.setAddForm}
             compounds={compounds}
             selectedCompound={selectedCompound}
             setSelectedCompound={setSelectedCompound}
             blocks={blocks}
             setSelectedBlock={setSelectedBlock}
             selectedBlock={selectedBlock}
             handleSearch={handleSearch}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
