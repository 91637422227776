import { Box, TextField, Button, Typography } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, resetCurrentObject, currentObjectMerge } from '../../../Global/Errors/FavouritePage/FavouritePage'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify'
import favouritePageAPI from '../../../Global/Network/FavouritePage/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import CompanyAPI from '../../../Global/Network/Companies'
import helpers from '../../../assets/js/helper'
import ColorLensIcon from '@mui/icons-material/ColorLens';
import CustomIconsList from '../../Components/CustomIconsList'
import CustomIcons from '../../Components/CustomIcons'
import icons from './Icons'
import FavouritePageCard from '../MyFavouritePage/FavouritePageCard'


const RenderContent = (props) => {
    const [t] = useTranslation('common')

    return (
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                    <CustomTextField
                        haswidth={true}
                        label={t('FAVOURITE_PAGE.name')}
                        value={props?.favouritePage?.name?.value}
                        error={props?.favouritePage?.name?.error}
                        message={props?.favouritePage?.name?.message}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setFavouritePage({
                                ...props?.favouritePage,
                                name: {
                                    value: e.target.value,
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                        onClearClick={() => {
                            props?.setFavouritePage({
                                ...props?.favouritePage,
                                name: {
                                    value: "",
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                    />
                </Box>
                <Box sx={{ margin: '12px 0' }}></Box>
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                    <CustomTextField
                        haswidth={true}
                        inputPropStyle={{
                            textAlign: 'left',
                            direction: 'ltr'
                        }}
                        label={t('FAVOURITE_PAGE.route')}
                        value={props?.favouritePage?.route?.value}
                        error={props?.favouritePage?.route?.error}
                        message={props?.favouritePage?.route?.message}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setFavouritePage({
                                ...props?.favouritePage,
                                route: {
                                    value: e.target.value,
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                        onClearClick={() => {
                            props?.setFavouritePage({
                                ...props?.favouritePage,
                                route: {
                                    value: "",
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                    />
                </Box>
                <Box sx={{ margin: '12px 0' }}></Box>
                <Box className="col-12 col-sm-12 col-md-10 m-auto">


                    <CustomeSelectField
                        renderOption={(props, option) => (
                            <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
                                <CustomIcons
                                    style={{
                                        color: '#1E6A99',
                                        fontSize: '30px'
                                    }}
                                    iconName={option?.name}
                                />
                                <Typography
                                    sx={{
                                        marginLeft: '10px'
                                    }}
                                >
                                    {option?.label}
                                </Typography>

                            </Box>
                        )}
                        label={t('FAVOURITE_PAGE.icon')}
                        haswidth={true}
                        value={props?.favouritePage?.icon?.value}
                        list={icons}
                        error={props?.favouritePage?.icon?.error}
                        message={props?.favouritePage?.icon?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {

                            props?.setFavouritePage({
                                ...props?.favouritePage,
                                icon: {
                                    value: newValue,
                                    error: false,
                                    message: []
                                }

                            })

                        }}
                        onClearClick={() => {
                            props?.setDepartment({
                                ...props?.department,
                                company_id: {
                                    value: {
                                        id: "",
                                        name: ""
                                    },
                                    error: false,
                                    message: []
                                }

                            })

                        }}
                    />
                </Box>

                <Box className="col-md-12 my-3">
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'space-evenly',
                            alignItems: 'center',
                        }}
                    >

                        <Box
                            sx={{
                                width: '200px',
                                height: '200px',
                                borderRadius: '50%',
                                cursor: 'pointer',
                                backgroundColor: props?.favouritePage?.color?.value ? props?.favouritePage?.color?.value : 'rgba(0,0,0,0.2)',
                                border: '2px dashed green',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                position: 'relative'
                            }}
                            onClick={() => {
                                if (document.getElementById('colorPicker')) {
                                    document.getElementById('colorPicker').click();
                                }
                            }}
                        >
                            <ColorLensIcon color="#fff" sx={{
                                fontSize: '30px',
                                color: helpers.checkDarknessOrLightnessForColor(props?.favouritePage?.color?.value ? props?.favouritePage?.color?.value : '#ffffff') ? '#fff' : '#000',
                                position: 'absolute',
                                left: '50%',
                                top: '50%',
                                transform: 'translate(-50%,-50%)',
                                zIndex: '234234'
                            }} />
                            <input type="color" style={{
                                width: '100%',
                                height: '100%',
                                borderRadius: '50%',
                                visibility: 'hidden',
                            }} id="colorPicker" onChange={(e) => {
                                props?.setFavouritePage({
                                    ...props?.favouritePage,
                                    color: {
                                        value: e.target.value,
                                        error: false,
                                        message: []
                                    }

                                })
                            }} />
                        </Box>

                        <FavouritePageCard
                        onClick={()=>{}}
                            color={props?.favouritePage?.color?.value}
                            iconName={props?.favouritePage?.icon?.value?.name}
                            name={props?.favouritePage?.name?.value}
                        />
                        

                    </Box>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6"></Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');

    const [loading, setLoading] = useState(false)
    const [favouritePage, setFavouritePage] = useState(ObjectOfErrors)

    const [companies, setCompanies] = useState([])

    useEffect(() => {

        if (props?.object && props?.object?.id) {
            setFavouritePage(currentObjectMerge(props?.object))
        } else {

            clearForm()
        }
    }, [props?.object])

    const loadDataOfCompanyList = async () => {
        let data = null;
        try {
            data = await CompanyAPI.CompanyList();
            if (data && data?.status) {
                setCompanies(data?.data);
            } else {
                toast.error(t("NETWORKMESSAGE.messageError"));
            }
        } catch (err) {
            console.log(err?.message);
        }
    };

    const submit = async () => {
        try {
            setLoading(true)

            let data = {
                name: favouritePage?.name?.value,
                route: favouritePage?.route?.value,
                icon: favouritePage?.icon?.value?.name,
                color: favouritePage?.color?.value ? favouritePage?.color?.value : '#1E6A99',

            }
            let result = null;
            if (props?.object && props?.object?.id) {
                data = {

                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await favouritePageAPI.updateFavouritePage({
                    data: data
                });
            } else {
                result = await favouritePageAPI.addFavouritePage({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(favouritePage)
                    setFavouritePage(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setFavouritePage({
            ...ObjectOfErrors
        })
    }

    useEffect(() => {
        if (!props?.object)
            clearForm()
    }, [props?.addForm, props?.object])

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('FAVOURITE_PAGE.updateTitle') : t('FAVOURITE_PAGE.addTitle')}
            content={<RenderContent
                favouritePage={favouritePage}
                setFavouritePage={setFavouritePage}
                companies={companies}

                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
