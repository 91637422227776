import React from 'react'
import dayjs from 'dayjs';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { Box } from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';


export default function SearchDate({
    title,value,setValue,hasFontSize,
    clearPositionTop,clearPositionLeft,
    typeMode,disable,minDate,maxDate

}) {
    const handleChange=(date)=>{
        setValue(date)
    }
  return (
    <Box sx={{
        width:'100%',
        position:'relative',
        '& .MuiFormLabel-root,& .MuiInputBase-input':{
            fontFamily:'Cairo-Medium',
            fontSize:hasFontSize?hasFontSize:'14px',
            // direction:'rtl !important',
            textAlign:"left !important",
        }
        }}>
         <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                // label={title}
                inputFormat={typeMode?'YYYY/MM':"MM/DD/YYYY"}
                views={typeMode?['month','year']:['year','month','day']}
                value={value}
                onChange={handleChange}
                disabled={disable}
                autocomplete="off"
                minDate={minDate}
                maxDate={maxDate}
                renderInput={(params) => <TextField 
                    {...params}
                    inputProps={{
                        ...params.inputProps,
                        placeholder:title
                       }}
                    sx={{width:'95%',ml:1.5,mt:1,mb:1}} 
                    variant="standard"/>}
                />
                {value?<ClearIcon sx={{position:'absolute',top:clearPositionTop?clearPositionTop:'10px',right:clearPositionLeft?clearPositionLeft:'23px',fontSize:'25px',cursor:'pointer',color:'grey !important'}} onClick={()=>handleChange(null)} />:null}
        </LocalizationProvider>
    </Box>
  )
}
