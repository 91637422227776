import callAPI from '../ApiConfig'
import store from '../../../app/store'

const favouritePage = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"favourite_pages",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const favouritePageList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"favourite_pages_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addFavouritePage = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"favourite_pages",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateFavouritePage = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"favourite_pages/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const DeleteFavouritePage = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"favourite_pages/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const favouritePageAPI={
    favouritePage:favouritePage,
    favouritePageList:favouritePageList,
    addFavouritePage:addFavouritePage,
    updateFavouritePage:updateFavouritePage,
    DeleteFavouritePage:DeleteFavouritePage

}
export default favouritePageAPI