import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux';
import { AppProvider } from './context';
import store from './app/store';
import { ToastContainer,Slide } from 'react-toastify';

Number.prototype.format = function(n, x, s, c) {
   var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\D' : '$') + ')',
       num = this.toFixed(Math.max(0, ~~n));
   
   return (c ? num.replace('.', c) : num).replace(new RegExp(re, 'g'), '$&' + (s || ','));
 };
 
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//   <React.StrictMode>
     <Provider store={store}>
          <AppProvider> 
            <ToastContainer
                containerId="container_toast_id"
                position="top-center"
                autoClose={5000}
                hideProgressBar={true}
                newestOnTop={false}
                transition={Slide}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
             <App />
          </AppProvider>
      </Provider>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
