const icons = [
    { id:1, name: 'Add', label: 'Backup Table' },
    { id:2, name: 'Abc', label: 'Credit Card Off' },
    { id:3, name: 'AcUnit', label: 'Play For Work' },
    { id:4, name: 'Favorite', label: 'Favorite' },
    { id:5, name: 'HomeWork', label: 'Home Work'}, 
    { id:6, name: 'Settings', label: 'Settings'},
    { id:7, name: 'Image', label: 'Image'},
    { id:8, name: 'Business', label: 'Business'},
    { id:9, name: 'ShapeLine', label: 'Shape Line'},
    { id:10, name: 'Logout', label: 'Logout'},
    { id:11, name: 'Menu', label: 'Menu'},
    { id:12, name: 'Dashboard', label:'Dashboard'},
    { id:13, name: 'DonutSmall', label:'Donut'},
    { id:14, name: 'PeopleAlt', label:'People'},
    { id:15, name: 'CalendarMonth', label:'Calendar Month'},
    { id:16, name: 'DomainAdd', label:'Domain Add'},
    { id:17, name: 'Call', label:'Call'},

  ];

export default icons;