import React, { useState, useEffect } from "react";
import { Typography, Box, TextField, alertTitleClasses } from "@mui/material";
import {
  WrapperOfLogin,
  ContainerOfLoginForm,
  TextLogin,
  LeftSide,
  RightSide,
} from "./ThemeModal";
import { useTranslation } from "react-i18next";
import MilsetoneLogo from "../../assets/img/Milestonelogo.png";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import loginImage from "../../assets/img/tg_image_48673084.jpeg";
import CustomeTypography from "../../General/CustomComponents/CustomTypography";
import Host from "../../assets/js/Host";
import axios from "axios";
import { toast } from "react-toastify";
import ResetPassword from "../Components/ResetPassword";
import RecivedSMSCode from "../Components/RecivedSMSCode";
import NewPasswordGenerater from "../Components/NewPasswordGenerater";
import Cookies from 'universal-cookie'
import { sha256 } from "js-sha256";
import { useNavigate } from "react-router-dom";
import * as VscIcone from 'react-icons/vsc'
import * as BsIocne from 'react-icons/bs'
import * as TbIcons from 'react-icons/tb'

import LoginAnimationBg from '../LoginAnimationBG/LoginAnimationBg'
import Preloading from "../Preload/Preloading";
import userAPI from "../Network/User";
import { useSelector, useDispatch } from "react-redux";
import { setUserToken,setUserData } from "../../reduxStore/UserReducer";
import employeeImage from '../../assets/img/user.png'
import moment from "moment/moment";

import  isCompanyLogo from '../../assets/img/logo.jpg'
const cookies = new Cookies();

export default function Login() {
  const [t] = useTranslation("common");
  const dispatch = useDispatch();
  const controller = new AbortController();
  const token = cookies.get('token')
  const [userName, setUserName] = useState("");
  const [userPassword, setUserPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);
  const logintheme = useSelector((state) => state.themeData.logintheme);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [showRestPasswordForm, setShowPasswordForm] = useState(false);
  const [showRecivedSmsCodeForm, setShowRecivedSmsCodeForm] = useState(false);
  const [showNewUserPasswordForm, setShowNewUserPasswordForm] = useState(false);
  const [loading, setLoading] = useState(false)
  const navigate = useNavigate();
  const setUserNameValue = (e) => {
    setUserName(e.target.value);
  };
  const setUserPasswordValue = (e) => {
    setUserPassword(e.target.value);
  };
  const setShowPasswordField = (e) => {
    setShowPassword(true);
  };
  const setHidePasswordField = (e) => {
    setShowPassword(false);
  };
  const setRememberMeValue = (e) => {
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    var w = window.screen.availWidth;
    var h = window.screen.availHeight;

    console.log("width", w);
    console.log("height", w);
  }, []);

  const getImageProfile = (images) => {
    let imageUrl = null;
    let lastImageProf = null;
      images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          lastImageProf = itm?.image_url;
        }
      });
    if (lastImageProf) imageUrl = lastImageProf;
  
    console.log('image_Pro',imageUrl)
    return process.env.REACT_APP_API_URL_image+imageUrl;
  };

  const login = async () => {
    console.log("login");
    if (!userName) {
      toast.warning(t("ERROR.login_username_error"));
      return
    } else if (!userPassword) {
      toast.warning(t("ERROR.login_userpassword_error"));
      return
    }
    // if (userName != 'is_admin' || userPassword != 'password') {
    //   toast.warning(t("ERROR.userName_or_password_error"));
    //   return
    // }
    setLoading(true)
    console.log("login1");

    const result = await userAPI.login({
      username: userName,
      password: userPassword,
      signal: controller?.signal
    })

    if (result.status) {
      console.log('login',result?.data?.data)
      dispatch(setUserToken(result?.data?.data?.token));
      cookies.set("login_company_id", result?.data?.data?.user?.company_id,{ path: "/" })
      cookies.set("token", result?.data?.data?.token, { path: "/" })
      cookies.set("token_date",moment(), { path: "/" })
      cookies.set("remember_me",rememberMe?1:0, { path: "/" })
      cookies.set("user_id",result?.data?.data?.user?.id, { path: "/" })
      cookies.set("userable_id",result?.data?.data?.user?.userable?.id, { path: "/" })

      let userObject={
        username:result?.data?.data?.user?.username,
        full_name:result?.data?.data?.user?.full_name,
        image_url:result?.data?.data?.user?.userable?.images?.length?getImageProfile(result?.data?.data?.user?.userable?.images):employeeImage,
      }
      dispatch(setUserData(userObject))
      localStorage.setItem('userData',JSON.stringify(userObject))

      // cookies.set("token", sha256(userName + userPassword), { path: "/" })
      cookies.set('access_type', 'admin', { path: "/" })
      setTimeout(() => {
        setLoading(false)
      }, 4000)
    }
    else {
      if (typeof result?.errorMessage === "object") {
          
          const errorMessagesKeys = Object.keys(result?.errorMessage);
          let messages = ""
          errorMessagesKeys?.map((key) => {
              
              result?.errorMessage[key].map((message) => {
                  messages += " - " + message
              })
              
          })
          
          toast.error(messages)
      }
      else {
        toast.error(result?.errorMessage)
      }


  }
  setLoading(false)
  };
  const showRestPasswordFormFunction = () => {
    setShowPasswordForm(true);
  };
  if (token && !loading) {
    navigate('/admin/my_favourite_pages')
  } else {
    return (
      <WrapperOfLogin
        className="d-flex justify-content-center align-items-center containerOfLogin"
        logintheme={logintheme}
        screenwidth={screenwidth}
      >
        <ResetPassword
          showRestPasswordForm={showRestPasswordForm}
          setShowPasswordForm={setShowPasswordForm}
          showRecivedSmsCodeForm={showRecivedSmsCodeForm}
          setShowRecivedSmsCodeForm={setShowRecivedSmsCodeForm}
        />
        <RecivedSMSCode
          showRecivedSmsCodeForm={showRecivedSmsCodeForm}
          setShowRecivedSmsCodeForm={setShowRecivedSmsCodeForm}
          showNewUserPasswordForm={showNewUserPasswordForm}
          setShowNewUserPasswordForm={setShowNewUserPasswordForm}
        />
        <NewPasswordGenerater
          showNewUserPasswordForm={showNewUserPasswordForm}
          setShowNewUserPasswordForm={setShowNewUserPasswordForm}
        />
        {
          loading ?
            <Preloading />
             : 
            null
        }

        <ContainerOfLoginForm logintheme={logintheme} screenwidth={screenwidth}>
          <Box className="wrapperLoginContent">
            <TextLogin logintheme={logintheme} screenwidth={screenwidth}>
              {t("LOGIN.login_title")}
            </TextLogin>
            <RightSide logintheme={logintheme} screenwidth={screenwidth}>
              <Box className="ContainerOfRight d-flex flex-column justify-content-center align-items-center">
                <CustomeTypography variant="p" className="wellcome-message">
                  {t("LOGIN.wellcome_message")}
                </CustomeTypography>
                <Box className="subContainerRight">

                  {/* <Typography variant='p' className="Introductory-message">{t('LOGIN.Introductory_message')}</Typography> */}
                  <CustomeTypography
                    sx={{
                      fontSize: {
                        xxl: "16px !important",
                        xl: "16px !important",
                        lg: "14px !important",
                        md: "15px !important",
                        sm: "16px !important",
                        xs: "16px !important",
                      },
                    }}
                    variant="p"
                    className="Introductory-message"
                  >
                    {t("LOGIN.Introductory_message")}
                  </CustomeTypography>

                  <TextField
                    
                    variant="filled"
                    label={t("LOGIN.user_name")}
                    value={userName}
                    onChange={setUserNameValue}
                    className="user-name"
                  />
                  <Box sx={{ position: "relative" }} className="bassword-box">
                    <TextField
                      
                      variant="filled"
                      label={t("LOGIN.user_password")}
                      value={userPassword}
                      onChange={setUserPasswordValue}
                      className="user-password"
                      type={showPassword ? "text" : "password"}
                    />
                    {!showPassword ? (
                      <BsIocne.BsEye
                        className="passwordIcon"
                        onClick={setShowPasswordField}
                        size={27}
                      />
                    ) : (
                      <BsIocne.BsEyeSlash
                        className="passwordIcon"
                        onClick={setHidePasswordField}
                        size={27}
                      />
                    )}
                  </Box>

                  <FormControlLabel
                    value="left"
                    control={
                      <Checkbox
                        checked={rememberMe}
                        onChange={setRememberMeValue}
                      />
                    }
                    label={t("LOGIN.checkbox_remember_me")}
                    // labelPlacement={t('LOGIN.checkbox_remember_me')}
                    className="checkbox-remember-me"
                  />
                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-end",
                      alignContent: "center",
                      alignItems: "center",
                      width: "88%",
                    }}
                  >
                    <Typography
                      variant="span"
                      className="login-text"
                      onClick={login}
                    >
                      {t("LOGIN.login")}
                    </Typography>
                    {/* <img
                      src={loginImage}
                      alt="login"
                      className="login-img"
                      onClick={login}
                    /> */}
                    <TbIcons.TbLogin  
                      style={{
                        fontSize:'30px',
                        color:logintheme?.iconsColor,
                        cursor: 'pointer',
                      }}
                      onClick={login}
                    />
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      width: "88%",
                    }}
                  >
                    {/* <Typography
                      variant="span"
                      className="forget-password"
                      onClick={showRestPasswordFormFunction}
                    >
                      {t("LOGIN.forget_password")}
                    </Typography> */}
                  </Box>
                </Box>
              </Box>
            </RightSide>
            <LeftSide logintheme={logintheme} screenwidth={screenwidth}>
              <Box className="ContainerOfLeft">
                <img src={MilsetoneLogo} alt="logo for company" />
                <Box className="ContainerOfLeft_texts">
                  <Typography variant="span" className="company-name">
                    {t("LOGIN.company_name")}
                  </Typography>
                  <Typography variant="span" className="company-title">
                    {t("LOGIN.company_title")}
                  </Typography>
                </Box>
              </Box>
            </LeftSide>
          </Box>

          <Box className="footer-message">
           <span>{t("LOGIN.footer_message")}</span> 
           <img
             src={isCompanyLogo}
             style={{
              width:'55px',
              height:'45px',
              objectFit:'cover',
              backgroundPosition:'center center',
              margin:'0 10px',
              
             }}
           />
          </Box>

        </ContainerOfLoginForm>
        <LoginAnimationBg />

      </WrapperOfLogin>
    );
  }
}
