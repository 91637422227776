import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import {
  Box,
  Typography,
  Divider,
  List,
  ListItem,
  ListItemText,
  IconButton,
} from "@mui/material";
import orderAPI from "../../../Global/Network/Orders/index";
import { useLocation, useNavigate } from "react-router-dom";
import InvoiceRow from "./Components/InvoiceRow";
import { useReactToPrint } from "react-to-print";
import { BsPrinter } from "react-icons/bs";
import { height } from "@mui/system";
import helpers from "../../../assets/js/helper";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import logo from "../../../assets/img/milestone_logo.png";
import swal from "sweetalert";
import GovernorateAPI from "../../../Global/Network/Governorate";
import { t } from "i18next";
import EmployeeAPI from "../../../Global/Network/Employee";
import Cookies from "universal-cookie";

const cookies = new Cookies();

export default function InvoicePrint({ invoice, print, setPrint }) {
  const controller = new AbortController();
  const printRef = useRef();
  const [governorates, setGovernorates] = useState([]);
  const [user, setUser] = useState(null);

  const handlePrint = useReactToPrint({
    content: () => {
      const tableStat = printRef.current.cloneNode(true);
      const PrintElem = document.createElement("div");
      const header =
        `<img src="${logo}" alt="" class="watermark"/>` +
        `<div class="pageFooter">I'm The Footer</div>` +
        `<div class="page-header">I'm The Header</div>`;
      // PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  useEffect(() => {
    if (print) {
      handlePrint();
      setPrint(false);
    }

    return () => {};
  }, [print]);

  useEffect(() => {
    loadDataOfGovernorates();
    getUser();
  }, []);

  const getName = () => {
    let customer = invoice?.order?.customer;
    let title = "السيد";
    if (customer?.user?.gender) {
      title = "السيدة";
      if (customer?.marital_status_id == 2) {
        title = "الانسة";
      }
    }
    return (
      title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
    );
  };
  const getAddress = (lineNumber) => {
    let customerAddress =
      invoice?.order?.customer?.addresses?.length > 0
        ? invoice?.order?.customer?.addresses[0]
        : null;
    if (!customerAddress) {
      return;
    }
    if (lineNumber == 1) {
      return customerAddress?.name ? customerAddress?.name : "";
    } else if (lineNumber == 2) {
      return customerAddress?.address ? "\n" + customerAddress?.address : "";
    } else if (lineNumber == 3) {
      return customerAddress?.first_phone
        ? "" + customerAddress?.first_phone
        : "";
    }

    return "";
  };

  const getInvoicePriceFormated = () => {
    let invoicePrice = invoice?.total_price;
    let unitCurrencyCode = invoice?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD";

    return (
      "(" +
      invoicePrice?.format(
        invoice?.order?.unit?.iqd_usd == 1 ? 2 : 0,
        3,
        ",",
        "."
      ) +
      ") " +
      helpers?.tafqeet(invoicePrice, unitCurrencyCode)
    );
  };

  const getNoteText = () => {
    let text =
      "فاتورة صادرة بناء على العقد الموقع بين الطرفين لقاء بيع الوحدة السكنية (المساحة : ";
    text +=
      invoice?.order?.unit?.area +
      " متر مربع, النوع : " +
      invoice?.order?.unit?.unit_type?.name +
      " ) ";
    text += "من قبل الطرف الأول (" + invoice?.order?.unit?.company?.name + ") ";
    text += "الى الطرف الثاني (" + getName() + ")";
    if (invoice?.payment_type_detail?.days_duration_from_contract == 0) {
      text += "تتضمن الفاتورة الدفعة الأولية للشقة.";
    }
    return text;
  };

  function formatDate(inputDate) {
    try {
      const dateObj = new Date(inputDate);
      if (isNaN(dateObj)) {
        throw new Error("Invalid date");
      }

      const year = dateObj.getFullYear();
      const month = String(dateObj.getMonth() + 1).padStart(2, "0");
      const day = String(dateObj.getDate()).padStart(2, "0");

      return `${year}/${month}/${day}`;
    } catch (error) {
      return "Invalid date format";
    }
  }

  const loadDataOfGovernorates = async (searchParams) => {
    const result = await GovernorateAPI.GovernorateList({
      signal: controller?.signal,
    });

    if (result.status) {
      setGovernorates(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  const getUser = async () => {
    let data = null;
    try {
      let params = {
        id: cookies.get("userable_id"),
      };

      data = await EmployeeAPI.ShowEmployee({
        params: params,
      });

      setUser(data?.data?.user);
    } catch (error) {
      console.log(error);
    }
  };
  
  function formatDate(inputDate) {
    try {
        const dateObj = new Date(inputDate);
        if (isNaN(dateObj)) {
        throw new Error("Invalid date");
        }
        
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        
        return `${year}/${month}/${day}`;
    } catch (error) {
        return "Invalid date format";
    }
    }

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",

        width: "100%",
        justifyContent: "center",
        alignItems: "stretch",
        overflowX: "hidden",
      }}
      className=" pt-3 mt-1"
    >
      <Box
        sx={{
          display: "flex",
          width: "100%",
          height: "calc(100vh - 100px)",
          backgroundColor: "#FFFFFF",
          justifyContent: "center",
          alignItems: "center",
          overflow: "scroll",
        }}
      >
        <Box
          ref={printRef}
          style={{
            backgroundColor: "#FFFFFF",
            width: "100%",
            padding: "20px 40px",
            direction: "rtl",
            height: "100%",
            // height: '842px',
          }}
        >
          <img
            src={require("../../../assets/img/invoice_header.png")}
            width="100%"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
            }}
          >
            <Typography
              style={{
                // width: '100%',
                wordWrap: "break-word",
                fontSize: "20px",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                marginTop: "10px",
                 border: "2px solid gray",
                borderRadius: "15px",
                padding: "5px 10px",
                fontFamily: "Tajawal",
              }}
            >
              <Typography
                component={"span"}
                style={{
                  fontWeight: 900,
                  textAlign: "justify",
                  textJustify: "inter-word",
                  lineHeight: "normal",
                  fontSize: "18px",
                  fontFamily: "Tajawal",
                }}
              >
                {"التاريخ : "}
              </Typography>
              {formatDate(invoice?.due_date)}
            </Typography>
            <InvoiceRow
              sx={{
                flex: 1,
                direction: "ltr",
                width: "40px",
              }}
              title={"No."}
              titleSX={{
                // flex: 1,
                fontSize: "22px",
                fontWight: 800,
                color: "red",
              }}
              valueSX={{
                // flex: 1,
                fontSize: "22px",
                fontWight: 400,
              }}
              value={invoice?.id + ""}
              // paddingRight={"600px"}
            />
          </Box>
          <Box
            sx={{
              width: "100%",
              padding: "0 20px",
              bgcolor: "#61d4fa",
              borderRadius: "10px",
              border: "3px solid black",
              display: "flex",
              justifyContent: "center",
              margin: "10px 0 10px 0",
            }}
          >
            <Typography fontSize="26px" fontWeight={600} fontFamily="Cairo">
              أستمارة شراء وحدة سكنية في {invoice?.compound?.name}
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              الإسم الرباعي :
            </Typography>
            <Typography
              style={{
                flex: 3,
                width: "100%",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.order?.customer?.user?.full_name}
            </Typography>
            <Typography
              style={{
                flex: 2,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                fontWeight: 600,
                fontFamily: "Cairo",
              }}
            >
              <Typography
                component={"span"}
                style={{
                  fontWeight: 600,
                  textAlign: "justify",
                  textJustify: "inter-word",
                  lineHeight: "normal",
                  fontSize: "18px",
                  fontFamily: "Cairo",
                }}
              >
                تاريخ الميلاد :
              </Typography>
              {}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "23px",
              }}
            >
              رقم الهوية :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.order?.customer?.nation_id_number}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              رقم وتأريخ بطاقة السكن :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.order?.customer?.residential_id_number}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "58px",
              }}
            >
              نوعها :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
              
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المحافظة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                borderBottom: "2px dashed black",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {governorates?.map((itm) => {
                if (
                  itm?.id ===
                  invoice?.order?.customer?.addresses[0]?.governorate_id
                )
                  return itm?.name;
              })}
              {/* {invoice?.order?.customer?.nation_id_number} */}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المدينة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                borderBottom: "2px dashed black",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "12px",
              }}
            >
              مركز الاصدار :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              محلة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                borderBottom: "2px dashed black",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              زقاق :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                borderBottom: "2px dashed black",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              دار :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                borderBottom: "2px dashed black",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "7px"
              }}
            >
              تاريخ الاصدار :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              رقم الهاتف :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              964{invoice?.order?.customer?.user?.phone?.split('+964-').reverse().join('')}+
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "39px",
              }}
            >
              الجنسية :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              إسم البديل وعناونه :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
                marginLeft: "40px",
              }}
            >
              الوظيفة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              رقم الهاتف :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              964{invoice?.order?.customer?.user?.phone?.split('+964-').reverse().join('')}+
            </Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              padding: "0 20px",
              bgcolor: "#61d4fa",
              borderRadius: "10px",
              border: "3px solid black",
              display: "flex",
              justifyContent: "center",
              margin: "20px 0 10px 0",
            }}
          >
            <Typography fontSize="26px" fontWeight={600} fontFamily="Cairo">
              تفاصيل الوحدة السكنية
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              العمارة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.building?.name}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              الطابق :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.floor?.name}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              الشقة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.unit?.name}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المساحة الكلية :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.unit?.length * invoice?.unit?.width}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المساحة الصافية :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.unit?.area}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              تسليم الوحدة السكنية :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {formatDate(invoice?.unit?.delivery_date)}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              نقدا :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
            {invoice?.order?.total_price}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              اقساط :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.payments_sum_paid_amount}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المصرف العقاري :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              نظام المطبخ :
            </Typography>
            <Typography
              style={{
                flex: 3,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              المبلغ الكلي للوحدة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.order?.discounted_price}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              الدفعة المقدمة :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            >
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              paddingX: "20px",
              alignItems: "center",
              marginTop: "5px",
            }}
          >
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              اسم المصرف :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "0 10px",
              }}
            >
              {invoice?.order?.bank_name}
            </Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 600,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              اسم الحساب :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            ></Typography>
            <Typography
              component={"span"}
              style={{
                fontWeight: 400,
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "18px",
                fontFamily: "Tajawal",
              }}
            >
              رقم الحساب :
            </Typography>
            <Typography
              style={{
                flex: 1,
                width: "100%",
                wordWrap: "break-word",
                textAlign: "justify",
                textJustify: "inter-word",
                lineHeight: "normal",
                fontSize: "22px",
                 border: "2px solid gray",
                borderRadius: "10px",
                fontWeight: 400,
                marginLeft: "20px",
                fontFamily: "Tajawal",
                padding: "14px 10px",
              }}
            ></Typography>
          </Box>

          <Box
            sx={{
              width: "100%",
              padding: "0 20px",
              bgcolor: "#61d4fa",
              borderRadius: "10px",
              border: "3px solid black",
              display: "flex",
              justifyContent: "center",
              margin: "20px 0 10px 0",
            }}
          >
            <Typography fontSize="26px" fontWeight={600} fontFamily="Cairo">
              اقرار وتعهد
            </Typography>
          </Box>

          <Box
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              paddingX: "20px",
              gap: "10px",
            }}
          >
            <Box>
              <Typography
                style={{
                  wordWrap: "break-word",
                  textAlign: "justify",
                  textJustify: "inter-word",
                  lineHeight: "normal",
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Tajawal",
                }}
              >
                (المبلغ المدفوع غير مسترد)
              </Typography>
              <Typography
                style={{
                  wordWrap: "break-word",
                  textAlign: "justify",
                  textJustify: "inter-word",
                  lineHeight: "normal",
                  fontSize: "16px",
                  fontWeight: 600,
                  fontFamily: "Tajawal",
                }}
              >
                اني الموقع ادناه اقر باني قد قرأت جميع بنود هذه الاستمارة
                وتفاصيلها وبنود عقد البيع واتعهد بالالتزام بكافة الشروط واتحمل
                كافة التبعات القانونية والمالية واتعهد بعدم المطالبة بأسترجاع
                مبلغ نسبة شركة بوابة المستقبل للوساطة العقارية ولا يعتبر هذا
                المبلغ جزء من قيمة العقار واتعهد بدفع الدفعة الاولى للوحدة
                السكنية خلال مدة اقصاها (7 ايام) من تاريخ هذه الاستمارة وبخلافه
                يسقط حقي في المطالبة بالوحدة السكنية بدون الرجوع للمحاكم المختصة
              </Typography>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                   border: "2px solid gray",
                  borderRadius: "10px",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                  }}
                >
                  إسم المشتري :
                </Typography>
                <Typography
                  style={{
                    flex: 1,
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: 400,
                    fontFamily: "Tajawal",
                    padding: "10px 10px",
                  }}
                >
                  {invoice?.order?.customer?.user?.full_name}
                </Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                   border: "2px solid gray",
                  borderRadius: "10px",
                  alignItems: "center",
                  padding: "0 10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                  }}
                >
                  موظف المبيعات :
                </Typography>
                <Typography
                  style={{
                    flex: 1,
                    width: "100%",
                    fontSize: "20px",
                    fontWeight: 400,
                    fontFamily: "Tajawal",
                    padding: "10px 10px",
                  }}
                >
                  {user?.full_name}
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                gap: "10px",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                   border: "2px solid gray",
                  borderRadius: "10px",
                  alignItems: "center",
                  padding: "15px 10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                  }}
                >
                  التوقيع :
                </Typography>
                <Typography
                  style={{
                    flex: 1,
                    width: "100%",

                    fontSize: "22px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                    padding: "10px 10px",
                  }}
                ></Typography>
              </Box>
              <Box
                sx={{
                  flex: 1,
                  display: "flex",
                   border: "2px solid gray",
                  borderRadius: "10px",
                  alignItems: "center",
                  padding: "15px 10px",
                }}
              >
                <Typography
                  style={{
                    fontSize: "16px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                  }}
                >
                  التوقيع :
                </Typography>
                <Typography
                  style={{
                    flex: 1,
                    width: "100%",

                    fontSize: "22px",
                    fontWeight: 600,
                    fontFamily: "Tajawal",
                    padding: "10px 10px",
                  }}
                ></Typography>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
}
