import { Box, Typography } from '@mui/material'
import React from 'react'
import CustomIcons from '../../Components/CustomIcons'

const FavouritePageCard = ({color,iconName,name,onClick}) => {
    return (
        <Box
        onClick={onClick}
            sx={{
                width: '150px',
                height: '150px',
                borderRadius: '10px',
                cursor: 'pointer',
                backgroundColor: color ? color : '#1E6A99',

                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                position: 'relative',
                transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
                ":hover": {
                    cursor: 'pointer',
                    transform: 'translateY(-10px)',
                    boxShadow: '0px 10px 20px rgba(0, 0, 0, 0.15)',
                    backgroundColor: color ? color + "A0" : '#1E6A99A0',
                }
            }}

        >
            <CustomIcons
                style={{
                    color: '#FFFFFF',
                    fontSize: '50px'
                }}
                iconName={iconName ? iconName : "QuestionMark"}
            />
            <Typography
                sx={{

                    fontFamily: 'Cairo',
                    color: '#FFFFFF',
                    fontSize: '16px',
                    marginTop: '10px',
                    textAlign: 'center'
                }}
            >
                {name ? name : "عنوان الصفحة"}
            </Typography>
        </Box>

    )
}

export default FavouritePageCard