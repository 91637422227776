import { Box,TextField,Button, FormControlLabel, Checkbox, IconButton} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useDispatch, useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/CallType/CallType'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import CompanyAPI from '../../../Global/Network/Companies/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import Images from '../../Components/Images'
import Addresses from '../../Components/Addresses'
import AddressesTable from '../Compounds/PopUpComponents/AddressesTable'
import entityAPI from '../../../Global/Network/Entity'
import AddIcon from '@mui/icons-material/Add';
import { setGlobalLoading } from '../../../reduxStore/SettingsReducer'

const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };
const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [selectedAddress,setSelectedAddress]=useState(null)
    const rolesRedux = useSelector(state => state.rolesData.roles)

    const dispatch=useDispatch()


    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))

    }

    const handleUpdateAddress=(obj)=>{
        setSelectedAddress(obj)
        props?.setAddAddresses(true)
        props?.setSelectedAddress(obj)
      }

    return(
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6 ">
                    <CustomTextField
                        label={t('COMPANIES.add_name')}
                        value={props?.name}
                        error={ErrorsObject?.name?.error}
                        message={ ErrorsObject?.name?.message}
                        haswidth={true}
                        readOnly={false}
                        focused={true}
                        onChange={(e) => {
                           props?.setName(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                        onClearClick={() => {
                            props?.setName('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'name'))
                        }}
                    />
                    <Box
                        sx={{
                            backgroundColor:'#fff',
                            color:'#000',
                            // borderRadius:'10px',
                            height:'55px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'start',
                            width:'96% !important',
                            boxShadow:'0px 6px 20px 3px #00000014 !important',
                            margin:'auto',
                            paddingLeft:'10px',
                            marginTop:'15px',
                            "& .MuiTypography-root":{
                                fontFamily:'Cairo-Medium'
                            }

                        }}
                    >
                            <FormControlLabel control={<Checkbox 
                              checked={props?.activeStatus}
                              onChange={(e)=>{
                                props.setActiveStatus(e.target.checked)
                              }}
                            />} sx={{fontFamily:'Cairo-Medium'}} label={t('COMPANIES.active_status')} />

                    </Box>

                </Box>
                <Box className="col-12 col-sm-12 col-md-6 col-lg-6">
                    <CustomTextField
                        label={t('COMPANIES.add_email')}
                        value={props?.email}
                        error={ErrorsObject?.email?.error}
                        message={ ErrorsObject?.email?.message}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                           props?.setEmail(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'email'))
                        }}
                        onClearClick={() => {
                            props?.setEmail('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'email'))
                        }}
                    />
                    <Box sx={{margin:'12px 0'}}></Box>
                    <CustomTextField
                        label={t('COMPANIES.add_description')}
                        value={props?.description}
                        error={ErrorsObject?.description?.error}
                        message={ ErrorsObject?.description?.message}
                        haswidth={true}
                        readOnly={false}
                        hasMultipleLine={true}
                        onChange={(e) => {
                           props?.setDescription(e?.target?.value)
                           setErrorsObject(restCustomeObject(ErrorsObject,'description'))
                        }}
                        onClearClick={() => {
                            props?.setDescription('')
                            setErrorsObject(restCustomeObject(ErrorsObject,'description'))
                        }}
                    />

                </Box>
                <Box className="col-12 col-sm-12 col-md-12 col-lg-12 my-3">
                    
                    {
                       <Images 
                        imageList={props?.imageList} 
                        setImageList={props.setImageList}
                        isUpdateImage={props?.isUpdateImage} 
                        setIsUpdatedImage={props?.setIsUpdatedImage}
                        editMode={props?.editMode}
                        loading={props?.loading}
                        setLoading={props?.setLoading}
                        type={props?.type}
                        type_id={props?.type_id}
                        notHasSlice={props?.notHasSlice}
                        hideImageType={props?.hideImageType}
                        notHavePolyImage={true}
                       />
                       // :null
                       }
                </Box>

                {
                  rolesRedux?.view_address?.value?
                 <Box className='col-12 col-sm-12 col-md-12 col-lg-12 mt-5' sx={{paddingRight:'30px'}}>

                    {/* <Divider sx={{
                        color:'lightgrey',
                        marginBottom:'10px',
                        marginLeft:'10px',
                    }}/> */}
                    <Box sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'center',
                    }}>
                        {
                        rolesRedux?.add_address?.value?
                        <IconButton
                            onClick={()=>{
                                props.setAddAddresses(!props?.addAddresses)
                                props?.setSelectedAddress(null)
                                setSelectedAddress(null)
                            }}
                        >
                            <AddIcon sx={{color:"#1E6A99",fontSize:'30px',margin:'0 10px'}}/>
                        </IconButton>:null
                        }
                        <span style={{color:'#000',fontFamily:'Cairo-Bold',fontSize:'19px',marginRight:'10px'}}>{t('ADDRESSES.addNewAddressTitle')}</span>
                        
                   
                    </Box>
                   <AddressesTable 
                   editMode={props?.editMode}
                   listOfAddresses={props?.listOfAddresses} 
                   setListOfAddresses={props.setListOfAddresses}
                   handleUpdateAddress={handleUpdateAddress}
                   type={props?.type}

                   />
                 </Box>:null
                 }
                {
                rolesRedux?.view_address?.value&&rolesRedux?.add_address?.value?
                <Addresses 
                    editMode={props?.editMode} 
                    addForm={props?.addAddress} 
                    setAddForm={props.setAddAddresses} 
                    listOfAddresses={props?.listOfAddresses} 
                    setListOfAddresses={props.setListOfAddresses} 
                    handleGetNewAddress={props.handleGetNewAddress}
                    object={selectedAddress}
                    setObject={setSelectedAddress}
                    type={props?.type}
                />:null
                }

            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [description,setDescription]=useState('')
    const [email,setEmail]=useState('')
    const [activeStatus,setActiveStatus]=useState(null)
    const [imageList,setImageList]=useState([])
    const [isUpdateImage,setIsUpdatedImage]=useState(false)
    const [addAddress,setAddAddresses]=useState(false)
    const [listOfAddresses,setListOfAddresses]=useState([])
    const [selectedAddress,setSelectedAddress]=useState(null)
    
    const dispatch=useDispatch()

    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setDescription(props?.object?.description)
           setEmail(props?.object?.email)
           setActiveStatus(props?.object?.active_status==1?true:false)

           let images = []
           props?.object?.images && props?.object?.images?.length > 0 && props?.object?.images?.map((image, index) => {
               // let checkImage = getExtension(image?.file_path)
               images.push({
                   url: process.env.REACT_APP_API_URL_image + image?.image_url,
                   file: null,
                   index: index,
                   id: image?.id,
                   type: 'image',
                   name: image?.name,
                   description:image?.description,
                   image_type:image?.image_type,
               })
           })
           setImageList(images)
           let addArr=[]
           props?.object?.addresses&&props?.object?.addresses?.length>0&&props?.object?.addresses?.map((itm,index)=>{
                let code1='',code2='',code3=''
                let phone1='',phone2='',phone3=''

                if(itm?.first_phone){
                  let temp=itm?.first_phone?.split('-');
                  code1=temp[0]
                  phone1=temp[1]
               }
                if(itm?.second_phone){
                   let temp=itm?.second_phone?.split('-');
                   code2=temp[0]
                   phone2=temp[1]
               }
               if(itm?.third_phone){
                   let temp=itm?.third_phone?.split('-');
                   code3=temp[0]
                   phone3=temp[1]
               }

                addArr.push({
                   ...itm,
                   code1:code1,
                   code2:code2,
                   code3:code3,
                   first_phone:"0"+phone1,
                   second_phone:"0"+phone2,
                   third_phone:"0"+phone3,
                   index:index,

                })
           })
           setListOfAddresses(addArr)
        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
        if(email&&!validateEmail(email)){
            toast.warn('صيغة الايميل غير صحيح')
            return
        }
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data=new FormData()
        data.append('name',name)
        data.append('email',email)
        data.append('description',description)
        data.append('active_status',activeStatus?1:0)

        let result=null;
        if(props?.object&&props?.object?.id){
            data.append('_method','put')
             result=await CompanyAPI.updateCompany({
               data:data,
               id:props?.object?.id
            });
        }else{
            imageList&&imageList.forEach((image,index) => {
                data.append('images['+index+'][image]', image?.file);
                data.append('images['+index+'][image_type_id]', image?.image_type?.id);
                data.append('images['+index+'][name]', image?.name);
                data.append('images['+index+'][description]', image?.description);
            });

            listOfAddresses?.map((itm,index)=>{
                if(itm?.name)
                data.append(`addresses[${index}][name]`,itm?.name)
                if(itm?.address)
                data.append(`addresses[${index}][address]`,itm?.address)
                if(itm?.first_phone)
                data.append(`addresses[${index}][first_phone]`,itm?.first_phone)
                if(itm?.second_phone)
                data.append(`addresses[${index}][second_phone]`,itm?.second_phone)
                if(itm?.third_phone)
                data.append(`addresses[${index}][third_phone]`,itm?.third_phone)
                if(itm?.governorate)
                data.append(`addresses[${index}][governorate_id]`,itm?.governorate?.id)
                if(itm?.latitude)
                data.append(`addresses[${index}][latitude]`,itm?.latitude)
                if(itm?.longitude)
                data.append(`addresses[${index}][longitude]`,itm?.longitude)
            })

            
            result=await CompanyAPI.addCompany({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }

    const clearForm=()=>{
        setName('')
        setDescription('')
        setActiveStatus(null)
        setEmail('')
        setImageList([])
        setListOfAddresses([])
    }

    useEffect(()=>{
        if(!props?.object)
          clearForm()
    },[props?.addForm,props?.object])

    const handleGetNewAddress=async(newAddress)=>{
        if(!props?.object?.id){
            setListOfAddresses(itm=>[...itm,{
                ...newAddress,
                index:listOfAddresses[listOfAddresses?.length-1]&&listOfAddresses[listOfAddresses?.length-1]?.index?listOfAddresses[listOfAddresses?.length-1]?.index+1:1,
            }
            ])
            setAddAddresses(false)
            setSelectedAddress(null)

       }else{
         dispatch(setGlobalLoading(true))
        try{
            let type='company'
            // if(type&&type?.slice(type?.length-1=='s')){
            // type=type?.slice(0,type?.length-1);
            // }
            if(!selectedAddress){
                    let data=await entityAPI.addAddress({
                        type:type,
                        type_id:props?.object?.id,
                        data:{
                            ...newAddress,
                            governorate_id:newAddress?.governorate?.id,
                        },
                    })
                    if(data&&data?.status){
                        let code1='',code2='',code3=''
                        let phone1='',phone2='',phone3=''
   
                       if(newAddress?.first_phone){
                          let temp=newAddress?.first_phone?.split('-');
                          code1=temp[0]
                          phone1=temp[1]
                       }
                       if(newAddress?.second_phone){
                           let temp=newAddress?.second_phone?.split('-');
                           code2=temp[0]
                           phone2=temp[1]
                       }
                       if(newAddress?.third_phone){
                           let temp=newAddress?.third_phone?.split('-');
                           code3=temp[0]
                           phone3=temp[1]
                       }

                      if(data?.data?.addresses){
                        let addArr=[]
                        data?.data?.addresses&&data?.data?.addresses?.length>0&&data?.data?.addresses?.map((itm,index)=>{
                             let code1='',code2='',code3=''
                             let phone1='',phone2='',phone3=''
        
                             if(itm?.first_phone){
                               let temp=itm?.first_phone?.split('-');
                               code1=temp[0]
                               phone1=temp[1]
                            }
                             if(itm?.second_phone){
                                let temp=itm?.second_phone?.split('-');
                                code2=temp[0]
                                phone2=temp[1]
                            }
                            if(itm?.third_phone){
                                let temp=itm?.third_phone?.split('-');
                                code3=temp[0]
                                phone3=temp[1]
                            }
        
                             addArr.push({
                                ...itm,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                                index:index,
        
                             })
                        })
                        setListOfAddresses(addArr)
                      }else{
                        setListOfAddresses(itm=>[...itm,{
                                ...newAddress,
                                index:listOfAddresses[listOfAddresses?.length-1]&&listOfAddresses[listOfAddresses?.length-1]?.index?listOfAddresses[listOfAddresses?.length-1]?.index+1:1,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                            
                            }
                        ])
                     }

                      setAddAddresses(false)
                      setSelectedAddress(null)
                      dispatch(setGlobalLoading(false))

                    }else{
                        setAddAddresses(false)
                        dispatch(setGlobalLoading(false))

                    }

            }else{
                
                let data=await entityAPI.updateAddress({
                    id:newAddress?.id,
                    type:type,
                    type_id:props?.object?.id,
                    data:{
                        ...newAddress,
                        governorate_id:newAddress?.governorate?.id,

                    },
                })
                if(data&&data?.status){
                    let arr=[]
                    listOfAddresses&&listOfAddresses?.length>0&&listOfAddresses?.map((itm)=>{
                        if(itm?.id==newAddress?.id){
                            let code1='',code2='',code3=''
                            let phone1='',phone2='',phone3=''
       
                            if(newAddress?.first_phone){
                              let temp=newAddress?.first_phone?.split('-');
                              code1=temp[0]
                              phone1=temp[1]
                           }
                            if(newAddress?.second_phone){
                               let temp=newAddress?.second_phone?.split('-');
                               code2=temp[0]
                               phone2=temp[1]
                           }
                           if(newAddress?.third_phone){
                               let temp=newAddress?.third_phone?.split('-');
                               code3=temp[0]
                               phone3=temp[1]
                           }

                            arr.push({
                                ...itm,
                                ...newAddress,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                            })
                        }else{
                           arr.push(itm)
                        }
                        setListOfAddresses(arr)
                    })
                    setAddAddresses(false)
                    setSelectedAddress(null)
                    dispatch(setGlobalLoading(false))
                
                }else{
                    setAddAddresses(false)
                    dispatch(setGlobalLoading(false))
                    toast.error(t('NETWORKMESSAGE.messageError'))
                }

            }
        }catch(err){
          console.log(err.message);
         dispatch(setGlobalLoading(false))

        }
       }

    }

  return (
       <PopupForm 
        customeWidth={'85%'}
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('COMPANIES.updateTitle'):t('COMPANIES.addTitle')}
        content={<RenderContent 
        name={name} setName={setName}
        description={description}
        setDescription={setDescription}
        email={email}
        setEmail={setEmail}
        activeStatus={activeStatus}
        setActiveStatus={setActiveStatus}
        open={props.addForm} 
        setOpen={props.setAddForm}
        imageList={imageList}
        setImageList={setImageList}
        type={'company'}
        notHasSlice={true}
        type_id={props?.object?.id}
        hideImageType={false}
        editMode={props?.object?.id?true:false}
        loading={loading}
        setLoading={setLoading}
        setAddAddresses={setAddAddresses}
        addAddress={addAddress}
        setListOfAddresses={setListOfAddresses}
        listOfAddresses={listOfAddresses}
        handleGetNewAddress={handleGetNewAddress}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
        setIsUpdatedImage={setIsUpdatedImage}

        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
