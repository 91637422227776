import React, { useState, useEffect } from "react";
import {
  Box,
  colors,
  IconButton,
  Typography,
  MenuItem,
  ListItemIcon,
  Tooltip,
  Alert,
} from "@mui/material";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import { FaTrashAlt } from "react-icons/fa";
import AddIcon from "@mui/icons-material/Add";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import CustomCheckBox from "./CustomCheckBox";
import { useTranslation } from "react-i18next";
import DropDownGrid from "./DropDownGrid";
import DeleteIcon from "@mui/icons-material/Delete";
import { useNavigate } from "react-router";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import AppsIcon from "@mui/icons-material/Apps";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import CoPresentIcon from "@mui/icons-material/CoPresent";
import StorefrontIcon from "@mui/icons-material/Storefront";
import helpers from "../../assets/js/helper";
import PolylineIcon from "@mui/icons-material/Polyline";
import MoveDownIcon from "@mui/icons-material/MoveDown";
import { useSelector } from "react-redux";
import swal from "sweetalert";
import generalAPI from "../../Global/Network/General";
import { toast } from "react-toastify";
import LocalPhoneIcon from "@mui/icons-material/LocalPhone";

const CustomTreeViewRow = (props) => {
  const [t] = useTranslation("common");
  const navigate = useNavigate();
  const rolesRedux = useSelector((state) => state.rolesData.roles);

  const isDisable = () => {
    if (props?.row?.company_id && props?.row?.blocks?.length === 0) {
      return true;
    } else if (props?.row?.compound_id && props?.row?.buildings?.length === 0) {
      return true;
    } else if (props?.row?.block_id && props?.row?.floors?.length === 0) {
      return true;
    } else if (props?.row?.building_id) {
      return true;
    }
    return false;
  };
  const getColor = () => {
    if (props?.row?.company_id) {
      return "#1E6A99";
    } else if (props?.row?.compound_id) {
      return "#054164";
    } else if (props?.row?.block_id) {
      return "#456D82";
    } else if (props?.row?.building_id) {
      return "#6C8D9E";
    }
    return false;
  };

  const getType = () => {
    if (props?.row?.company_id) {
      return "blocks";
    } else if (props?.row?.compound_id) {
      return "buildings";
    } else if (props?.row?.block_id) {
      return "floors";
    } else if (props?.row?.building_id) {
      return "units";
    }
    return "";
  };
  const getCurrentType = () => {
    if (props?.row?.company_id) {
      return "compounds";
    } else if (props?.row?.compound_id) {
      return "blocks";
    } else if (props?.row?.block_id) {
      return "buildings";
    } else if (props?.row?.building_id) {
      return "floors";
    }
    return "";
  };
  const getRoleCheck = (index) => {
    let type = getCurrentType();
    if (type == "compounds") {
      if (index == 1) {
        return rolesRedux.add_compound?.value;
      } else if (index == 2) {
        return rolesRedux.update_compound?.value;
      } else if (index == 3) {
        return rolesRedux.delete_compound?.value;
      }
    } else if (type == "blocks") {
      if (index == 1) {
        return rolesRedux.add_block?.value;
      } else if (index == 2) {
        return rolesRedux.update_block?.value;
      } else if (index == 3) {
        return rolesRedux.delete_block?.value;
      }
    } else if (type == "buildings") {
      if (index == 1) {
        return rolesRedux.add_building?.value;
      } else if (index == 2) {
        return rolesRedux.update_building?.value;
      } else if (index == 3) {
        return rolesRedux.delete_building?.value;
      }
    } else if (type == "floors") {
      if (index == 1) {
        return rolesRedux.add_floor?.value;
      } else if (index == 2) {
        return rolesRedux.update_floor?.value;
      } else if (index == 3) {
        return rolesRedux.delete_floor?.value;
      }
    }

    return false;
  };

  const redirectToDrawImagePolygon = (type) => {
    swal({
      title: t("GLOBAL.are_you_sure"),
      text: t("GLOBAL.redirect_to_image_polygon"),
      icon: "warning",
      buttons: [t("GLOBAL.no"), t("GLOBAL.yes")],
      dangerMode: true,
    }).then(async (iAmSure) => {
      if (iAmSure) {
        confirmRedirect(type);
      } else {
        // alert('canceled')
      }
    });
  };
  const confirmRedirect = async (type) => {
    let structureType = getCurrentType();
    let structureId = props?.row?.id;
    if (type == "path_2_with_floors") {
      structureType = "blocks";
      structureId = props?.row?.block?.id;
    }
    const result = await generalAPI.getSourceAndLinkedToEntities({
      params: {
        type: structureType,
        id: structureId,
      },
    });

    if (result?.status) {
      navigate("/admin/image-draw", {
        state: {
          data: {
            selectedEntity: result?.data,
            ...(type == "path_2_with_floors"
              ? {
                  floor: [
                    {
                      id: props?.row?.id,
                      name: props?.row?.name,
                      label: props?.row?.name,
                    },
                  ],
                  building: {
                    ...props?.row?.building,
                    label: props?.row?.building?.name,
                  },
                  block: {
                    ...props?.row?.block,
                    label: props?.row?.block?.name,
                  },
                  compound: {
                    ...props?.row?.compound,
                    label: props?.row?.compound?.name,
                  },
                }
              : {}),
            image_type: type,
          },
        },
      });
    } else {
      // alert('3')

      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      } else {
        toast.error(result?.data?.error);
        return;
      }
    }
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        width: "100%",
        marginBottom: "10px",
        backgroundColor: getColor(),
        borderRadius: "5px",
      }}
    >
      <CustomCheckBox
        checked={props?.row?.checked}
        setChecked={props?.setCheckedRow}
        disabled={isDisable()}
      />
      <Box
        sx={{
          flex: 1,
          maxHeight: "52px",
          minHeight: "52px",
          // backgroundColor: '#FFFFFF',
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <Typography
          sx={{
            color: "#FFFFFF",
            fontFamily: "Cairo",
          }}
        >
          {props?.row?.name}
        </Typography>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",

            paddingLeft: "10px",
            paddingRight: "10px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "end",
              alignItems: "center",
            }}
          >
            {!props?.row?.has_p2 ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0 5px",
                  border: "1px solid #fff",
                  backgroundColor: props?.row?.needs_to_be_linked_p2
                    ? "#d32f2f"
                    : "#2e7d32",
                  padding: "0px 5px",
                  borderRadius: "10px",
                  "& .MuiButtonBase-root": {
                    padding: "4px 2px !important",
                  },
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Bold" }}>
                      {props?.row?.needs_to_be_linked_p2
                        ? t("GLOBAL.needs_to_be_linked_p2")
                        : t("GLOBAL.does_not_need_to_be_linked_p2")}
                    </span>
                  }
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      if (props?.row?.needs_to_be_linked_p2) {
                        redirectToDrawImagePolygon("path_2_with_floors");
                      }
                    }}
                  >
                    <MoveDownIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {props?.row?.skip ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0 5px",
                  border: "1px solid #fff",
                  backgroundColor: props?.row?.needs_to_be_linked
                    ? "#d32f2f"
                    : "#2e7d32",
                  padding: "0px 5px",
                  borderRadius: "10px",
                  "& .MuiButtonBase-root": {
                    padding: "4px 2px !important",
                  },
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Bold" }}>
                      {props?.row?.needs_to_be_linked
                        ? t("GLOBAL.needs_to_be_linked")
                        : t("GLOBAL.does_not_need_to_be_linked")}
                    </span>
                  }
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      if (props?.row?.needs_to_be_linked) {
                        redirectToDrawImagePolygon("path_1_standard");
                      }
                    }}
                  >
                    <PolylineIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {getCurrentType() === "compounds" ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0 5px",
                  border: "1px solid #fff",
                  padding: "0px 5px",
                  borderRadius: "10px",
                  "& .MuiButtonBase-root": {
                    padding: "4px 2px !important",
                  },
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Bold" }}>
                      {t("GLOBAL.callCompounds")}
                    </span>
                  }
                  arrow
                >
                  <IconButton>
                    <LocalPhoneIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
                <span
                  style={{
                    fontFamily: "Cairo",
                    color: "#fff",
                    fontSize: "18px",
                  }}
                >
                  {helpers.getFormatedPriceV2(
                    props?.row?.all_calls_count,
                    0,
                    1
                  )}
                </span>
              </Box>
            ) : null}

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "0 5px",
                border: "1px solid #fff",
                padding: "0px 5px",
                borderRadius: "10px",
                "& .MuiButtonBase-root": {
                  padding: "4px 2px !important",
                },
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Bold" }}>
                    {t("GLOBAL.allUnit")}
                  </span>
                }
                arrow
              >
                <IconButton>
                  <AppsIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <span
                style={{ fontFamily: "Cairo", color: "#fff", fontSize: "18px" }}
              >
                {helpers.getFormatedPriceV2(props?.row?.all_units_count, 0, 1)}
              </span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "0 5px",
                border: "1px solid #fff",
                padding: "0px 5px",
                borderRadius: "10px",
                "& .MuiButtonBase-root": {
                  padding: "4px 2px !important",
                },
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Bold" }}>
                    {t("GLOBAL.soldUnit")}
                  </span>
                }
                arrow
              >
                <IconButton>
                  <ShoppingBasketIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <span
                style={{ fontFamily: "Cairo", color: "#fff", fontSize: "18px" }}
              >
                {helpers.getFormatedPriceV2(props?.row?.sold_units_count, 0, 1)}
              </span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "0 5px",
                border: "1px solid #fff",
                padding: "0px 5px",
                borderRadius: "10px",
                "& .MuiButtonBase-root": {
                  padding: "4px 2px !important",
                },
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Bold" }}>
                    {t("GLOBAL.reservedUnit")}
                  </span>
                }
                arrow
              >
                <IconButton>
                  <CoPresentIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <span
                style={{ fontFamily: "Cairo", color: "#fff", fontSize: "18px" }}
              >
                {helpers.getFormatedPriceV2(
                  props?.row?.reserved_units_count,
                  0,
                  1
                )}
              </span>
            </Box>

            <Box
              sx={{
                display: "flex",
                justifyContent: "end",
                alignItems: "center",
                margin: "0 5px",
                // backgroundColor:'#d1d1d1e0',
                border: "1px solid #fff",
                padding: "0px 5px",
                borderRadius: "10px",
                "& .MuiButtonBase-root": {
                  padding: "4px 2px !important",
                },
              }}
            >
              <Tooltip
                title={
                  <span style={{ fontFamily: "Cairo-Bold" }}>
                    {t("GLOBAL.freeUnit")}
                  </span>
                }
                arrow
              >
                <IconButton>
                  <StorefrontIcon sx={{ color: "#fff" }} />
                </IconButton>
              </Tooltip>
              <span
                style={{ fontFamily: "Cairo", color: "#fff", fontSize: "18px" }}
              >
                {helpers.getFormatedPriceV2(props?.row?.free_units_count, 0, 1)}
              </span>
            </Box>
          </Box>
          <DropDownGrid>
            {!getCurrentType().includes("floors") && getRoleCheck(1) ? (
              <MenuItem
                onClick={() => {
                  props?.addEntity({ entity: props?.row, type: getType() });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <AddIcon style={{ fill: "#009900" }} />
                </ListItemIcon>
                {t(getCurrentType()?.toUpperCase() + ".action_add")}
              </MenuItem>
            ) : (
              <MenuItem
                onClick={() => {
                  console.log("dataHeree===>floorDATA", props?.row);
                  navigate("/admin/masters/units", {
                    state: {
                      data: {
                        floor: props?.row,
                      },
                    },
                  });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <VisibilityIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("UNITS.view_units")}
              </MenuItem>
            )}
            {getRoleCheck(2) ? (
              <MenuItem
                onClick={() => {
                  props?.editEntity({
                    entity: props?.row,
                    type: getCurrentType(),
                  });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <EditIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t(getCurrentType()?.toUpperCase() + ".action_edit")}
              </MenuItem>
            ) : null}
            {getRoleCheck(3) ? (
              <MenuItem
                onClick={() => {
                  props?.deleteObject({
                    entity: props?.row,
                    type: getCurrentType(),
                  });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t(getCurrentType()?.toUpperCase() + ".action_delete")}
              </MenuItem>
            ) : null}
            {!getRoleCheck(2) ? (
              <MenuItem
                onClick={() => {
                  props?.viewObject({
                    entity: props?.row,
                    type: getCurrentType(),
                  });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <RemoveRedEyeIcon style={{ fill: "#1e6a99" }} />
                </ListItemIcon>
                {t(getCurrentType()?.toUpperCase() + ".action_view")}
              </MenuItem>
            ) : null}
            {(getCurrentType().includes("floors") &&
              !getRoleCheck(1) &&
              !getRoleCheck(3)) ||
            !rolesRedux ? (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: "10px",
                  fontFamily: "Cairo",
                }}
              >
                {t("GLOBAL.noData")}
              </Box>
            ) : null}
            {getCurrentType() === "compounds" ? (
              <MenuItem
                onClick={() => {
                  props?.viewCallCompounds({
                    entity: props?.row,
                    type: getCurrentType(),
                  });
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <RemoveRedEyeIcon style={{ fill: "#1e6a99" }} />
                </ListItemIcon>
                {t("GLOBAL.view_call_compounds")}
              </MenuItem>
            ) : null}
          </DropDownGrid>

          {/* <IconButton onClick={() => { props?.addEntity({ entity: props?.row, type: getType() }) }} >
                        <AddIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <IconButton onClick={() => { props?.editEntity(props?.row) }} >
                        <EditIcon sx={{ color: "#FFFFFF" }} />
                    </IconButton>
                    <IconButton onClick={() => { props?.deleteObject(props?.row) }}>
                        <FaTrashAlt style={{ color: "#FFFFFF" }} />
                    </IconButton> */}
        </Box>
      </Box>
    </Box>
  );
};

export default CustomTreeViewRow;
