import React, { useEffect, useState } from "react";
import { styled, useTheme } from "@mui/material/styles";
import Box from "@mui/material/Box";
// import Drawer from '@mui/material/Drawer';
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import { Drawer, AppBar, DrawerHeader } from "./SideMenuTheme";
import { useSelector, useDispatch } from "react-redux";
import HeaderDrawer from "../Components/HeaderDrawer";
import SideMenuItemArray from "./SideMenuItemArray";
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { useLocation } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import DonutSmallIcon from "@mui/icons-material/DonutSmall";
import PeopleAltIcon from "@mui/icons-material/PeopleAlt";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import * as FaIcons from "react-icons/fa";
import * as BsIcons from "react-icons/bs";
import * as BiIcons from "react-icons/bi";
import * as ImIcons from "react-icons/im";
import * as MdIcons from "react-icons/md";
import DomainAddIcon from "@mui/icons-material/DomainAdd";
import BookIcon from '@mui/icons-material/Book';

import * as RiIcons from "react-icons/ri";
import * as TbIcons from "react-icons/tb";
import * as AiIcons from "react-icons/ai";
import * as VscIcons from 'react-icons/vsc'
import { Outlet } from "react-router-dom";
import Cookies from "universal-cookie";
import { useNavigate } from "react-router-dom";
import ShapeLineIcon from "@mui/icons-material/ShapeLine";
import MilsetoneLogo from "../../assets/img/Milestonelogo.png";
import CallIcon from '@mui/icons-material/Call';
import { TbBoxMultiple9, TbBoxMultiple } from 'react-icons/tb'
import {  VscCloseAll } from 'react-icons/vsc'
import { MdOutlineLayersClear } from 'react-icons/md'


import {
  setIsOpenSideMenu,
  setCompanyProfileFullScreen,
} from "../../reduxStore/SettingsReducer";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import Preloading from "../../Global/Preload/Preloading";
import AddHomeWorkIcon from "@mui/icons-material/AddHomeWork";
import BedroomParentIcon from "@mui/icons-material/BedroomParent";
import ApartmentIcon from "@mui/icons-material/Apartment";
import PaymentsIcon from "@mui/icons-material/Payments";
import StairsIcon from "@mui/icons-material/Stairs";
import BusinessIcon from "@mui/icons-material/Business";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { IconButton, Menu, MenuItem } from "@mui/material";
import DiscountIcon from "@mui/icons-material/Discount";
import helpers from "../../assets/js/helper";
import userAPI from "../../Global/Network/User";
import SettingsIcon from '@mui/icons-material/Settings';
import GroupIcon from '@mui/icons-material/Group';
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import { toast } from "react-toastify";
import NotificationMenu from "./NotificationMenu";
import { CircleNotificationsTwoTone, Favorite } from "@mui/icons-material";
import ViewModuleIcon from '@mui/icons-material/ViewModule';
const cookies = new Cookies();

export default function SideMenu() {
  const [t] = useTranslation("common");
  const location = useLocation();
  const drawerwidth = useSelector((state) => state.settingsData.drawerwidth);
  const sidemenutheme = useSelector((state) => state.themeData.sidemenutheme);
  const menuList = useSelector((state) => state.settingsData.menuList);
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const globalloading = useSelector(
    (state) => state.settingsData.globalloading
  );
  const company_profile_full_screen = useSelector(
    (state) => state.settingsData.company_profile_full_screen
  );
  const [appbarTitle, setAppBarTitle] = React.useState("");
  const appbarTheme = useSelector((state) => state.themeData.appbar);
  const maintheme = useSelector((state) => state.themeData.appbar);
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const dispatch = useDispatch();

  const handleDrawerOpen = () => {
    setOpen(true);
    dispatch(setIsOpenSideMenu(true));
  };
  const handleDrawerClose = () => {
    setOpen(false);
    dispatch(setIsOpenSideMenu(false));
  };
  const navigate = useNavigate();
  useEffect(() => {
    setOpen(isopensidemenu);
  }, [isopensidemenu]);

  const [pathsList, setPathList] = useState([
    {
      id: 1,
      // roles: [],
      name: t("SIDEMENU.mainSettings"),
      to: "",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }} className="tooltipeSideMenu">
                {t("SIDEMENU.mainSettings")}
              </span>
            ) : (
              ""
            )
          }
        >
          <SettingsIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [
        {
          id: 2,
          roles:(rolesRedux)=> rolesRedux?.view_all_companies?.value||rolesRedux?.view_company?.value, //only user have role viewAllCompanies can access this.
          to: "masters/companies",
          name: t("SIDEMENU.companies"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.companies")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <BusinessIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 3,
          roles: (rolesRedux)=> rolesRedux?.view_employee_table?.value,
          name: t("SIDEMENU.Employee"),
          to: "masters/employee",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.Employee")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <BsIcons.BsFillPeopleFill className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 3000,
          roles:(rolesRedux)=> rolesRedux?.view_department?.value,
          name: t("SIDEMENU.Department"),
          to: "masters/department",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.Department")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <MdIcons.MdSettingsAccessibility className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 4,
          roles:(rolesRedux)=> rolesRedux?.view_role?.value,
          name: t("SIDEMENU.roles"),
          to: "masters/roles",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.roles")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <MdIcons.MdSettingsSuggest className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 3000,
          roles: (rolesRedux)=> rolesRedux?.view_favourite_page?.value,
          name: t("SIDEMENU.Favourite_Pages"),
          to: "masters/favourite_pages",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.Favourite_Pages")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <BsIcons.BsBookmarkStarFill className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 5,
          roles:(rolesRedux)=> rolesRedux?.user_profile?.value, //show user profile,role 0 because all user have access to his profile.
          name: t("SIDEMENU.userProfile"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.userProfile")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <FaIcons.FaUserAlt className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          to: "user/profile",
          subLink: [],
        },
        {
          id: 6,
          roles:(rolesRedux)=> rolesRedux?.view_image_type?.value,
          name: t("SIDEMENU.imageTypes"),
          to: "masters/image-types",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.imageTypes")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <BsIcons.BsImages className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 7,
          roles:(rolesRedux)=> rolesRedux?.view_call_type?.value,
          name: t("SIDEMENU.call_types"),
          to: "masters/call-types",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.call_types")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <CallIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
       
        {
          id: 8,
          roles:(rolesRedux)=> rolesRedux?.view_call_status?.value,
          name: t("SIDEMENU.call_status"),
          to: "masters/call-statuses",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.call_status")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <CallIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 9,
          roles:(rolesRedux)=> rolesRedux?.view_call_destinations?.value,
          name: t("SIDEMENU.call_destinations"),
          to: "masters/call-destinations",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.call_destinations")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <CallIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 10,
          roles:(rolesRedux)=> rolesRedux?.view_call_reasons?.value,
          name: t("SIDEMENU.call_reasons"),
          to: "masters/call-reasons",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.call_reasons")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <CallIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 1900,
          roles:(rolesRedux)=>  rolesRedux?.add_bulk_data?.value,
          to: "masters/bulk-insert-floors",
          name: t("SIDEMENU.bulk_insert_floors"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.bulk_insert_floors")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <TbBoxMultiple9 className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 1901,
          roles:(rolesRedux)=> rolesRedux?.delete_bulk_data?.value,
          to: "masters/bulk-delete-floors",
          name: t("SIDEMENU.bulk_delete_floors"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.bulk_delete_floors")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                {/* <VscClearAll className="iconSideMenu" /> */}
                <VscCloseAll className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 2000,
          roles:(rolesRedux)=> rolesRedux?.add_bulk_data?.value,
          to: "masters/bulk-insert-buildings",
          name: t("SIDEMENU.bulk_insert_buildings"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.bulk_insert_buildings")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <TbBoxMultiple className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 1902,
          roles:(rolesRedux)=> rolesRedux?.delete_bulk_data?.value,
          to: "masters/bulk-delete-buildings",
          name: t("SIDEMENU.bulk_delete_buildings"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.bulk_delete_buildings")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                {/* <VscClearAll className="iconSideMenu" /> */}
                <MdOutlineLayersClear className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 1903,
          roles:(rolesRedux)=> rolesRedux?.view_notifications_type?.value,
          to: "masters/notification-types",
          name: t("SIDEMENU.notificationTypes"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.notificationTypes")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                {/* <VscClearAll className="iconSideMenu" /> */}
                <CircleNotificationsTwoTone className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 1904,
          roles:(rolesRedux)=> rolesRedux?.view_compound_department?.value,
          to: "masters/compound-departments",
          name: t("SIDEMENU.compoundDepartments"),
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.compoundDepartments")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <ViewModuleIcon className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },

      ],
    },
    {
      id: 11,
      roles:(rolesRedux)=> rolesRedux?.dashboard?.value,
      name: t("SIDEMENU.dashboard"),
      to: "dashboard",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.dashboard")}
              </span>
            ) : (
              ""
            )
          }
        >
          <DashboardIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
    },
    {
      id: 0,
      roles: (rolesRedux)=> rolesRedux?.view_favourite_page?.value,
      name: t("SIDEMENU.my_favourite_pages"),
      to: "my_favourite_pages",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.my_favourite_pages")}
              </span>
            ) : (
              ""
            )
          }
        >
          <Favorite className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
    },
    {
      id: 12,
      roles:(rolesRedux)=>rolesRedux?.view_company?.value,
      name: t("SIDEMENU.companyProfile"),
      to: "company/profile",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.companyProfile")}
              </span>
            ) : (
              ""
            )
          }
        >
          <AccountCircleIcon className="iconSideMenu" />
        </Tooltip>
      ),
      subLink: [],
    },
    {
      id: 1903,
      roles:(rolesRedux)=> rolesRedux?.view_notifications?.value,
      to: "masters/notifications",
      name: t("SIDEMENU.notifications"),
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.notifications")}
              </span>
            ) : (
              ""
            )
          }
          sx={{ fontFamily: "Cairo-Bold" }}
        >
          <span>
            {/* <VscClearAll className="iconSideMenu" /> */}
            <NotificationsActiveIcon className="iconSideMenu" />
          </span>
        </Tooltip>
      ),
      subLink: [],
    },
    {
      id: 13,
      roles:(rolesRedux)=> rolesRedux?.view_available_compounds?.value,
      name: t("SIDEMENU.realStateMaster"),
      to: "",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.realStateMaster")}
              </span>
            ) : (
              ""
            )
          }
          sx={{ fontFamily: "Cairo-Bold" }}
        >
          <span>
            <DomainAddIcon
              className="iconSideMenu"
              sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
            />
          </span>
        </Tooltip>
      ),
      subLink: [
        {
          id: 14,
          roles:(rolesRedux)=> rolesRedux?.view_available_compounds?.value,
          name: t("SIDEMENU.Available_compounds"),
          to: "available-compounds",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.Available_compounds")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <ImIcons.ImHome3
                  className="iconSideMenu"
                  sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
                />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 15,
          roles:(rolesRedux)=> rolesRedux?.view_available_compounds?.value,
          name: t("SIDEMENU.realStateSettings"),
          to: "",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.realStateSettings")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <SettingsIcon
                  className="iconSideMenu"
                  sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
                />
              </span>
            </Tooltip>
          ),
          subLink: [
            {
              id: 16,
              name: t("SIDEMENU.mapper"),
              roles: (rolesRedux)=>rolesRedux?.view_draw_image?.value,
              to: "image-draw",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.mapper")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <ShapeLineIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 17,
              roles: (rolesRedux)=> rolesRedux?.view_compound?.value,
              name: t("SIDEMENU.Compound"),
              to: "masters/compound",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.Compound")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <DomainAddIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 18,
              roles:(rolesRedux)=> rolesRedux?.view_unit?.value,
              to: "masters/units",
              name: t("SIDEMENU.units"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.units")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <ApartmentIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },

            {
              id: 19,
              roles:(rolesRedux)=>  rolesRedux?.view_master_unit_type?.value,
              to: "masters/master-unit-types",
              name: t("SIDEMENU.MasterUnitTypes"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.MasterUnitTypes")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <RiIcons.RiBuildingFill className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },

            {
              id: 41,
              roles:(rolesRedux)=>rolesRedux?.view_real_estate?.value,
              name: t("SIDEMENU.realestates"),
              to: "masters/real-estates",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.realestates")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <FaIcons.FaStoreAlt className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 20,
              roles: (rolesRedux)=>rolesRedux?.view_unit_type?.value,
              to: "masters/unit-types",
              name: t("SIDEMENU.unitTypes"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.unitTypes")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <AddHomeWorkIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 21,
              roles:(rolesRedux)=> rolesRedux?.view_unit_location?.value,
              name: t("SIDEMENU.unitLocations"),
              to: "masters/unit-locations",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.unitLocations")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <MdIcons.MdLocationPin className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 22,
              roles:(rolesRedux)=> rolesRedux?.view_unit_statuses?.value,
              name: t("SIDEMENU.unitStatuses"),
              to: "masters/unit-statuses",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.unitStatuses")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <RiIcons.RiCommunityFill className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 23,
              roles:(rolesRedux)=> rolesRedux?.view_room?.value,
              to: "masters/rooms",
              name: t("SIDEMENU.rooms"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.rooms")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <BedroomParentIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 24,
              roles:(rolesRedux)=> rolesRedux?.view_room_type?.value,
              name: t("SIDEMENU.roomtypes"),
              to: "masters/room-types",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.roomtypes")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <AiIcons.AiOutlineHome className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 25,
              roles:(rolesRedux)=> rolesRedux?.view_phase?.value,
              to: "masters/phases",
              name: t("SIDEMENU.phases"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.phases")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <StairsIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
          ],
        },
      ],
    },
    {
      id: 26,
      // roles: [22],
      name: t("SIDEMENU.customerMaster"),
      to: "",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.customerMaster")}
              </span>
            ) : (
              ""
            )
          }
          sx={{ fontFamily: "Cairo-Bold" }}
        >
          <span>
            <GroupIcon
              className="iconSideMenu"
            // sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
            />
          </span>
        </Tooltip>
      ),
      subLink: [
        {
          id: 27,
          roles:(rolesRedux)=> rolesRedux?.view_registered_customers?.value,
          name: t("SIDEMENU.Registered_customers"),
          to: "registered-customers",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.Registered_customers")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <PeopleAltIcon className="iconSideMenu" />
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 28,
          // roles: [22],
          name: t("SIDEMENU.customerSettings"),
          to: "",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.customerSettings")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <SettingsIcon
                  className="iconSideMenu"
                  sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
                />
              </span>
            </Tooltip>
          ),
          subLink: [
            {
              id: 29,
              roles:(rolesRedux)=> rolesRedux?.view_purpose_of_purshases?.value,
              name: t("SIDEMENU.PurposeOfPurchase"),
              to: "masters/purpose-of-purshases",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.PurposeOfPurchase")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <ShoppingBasketIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 30,
              roles:(rolesRedux)=> rolesRedux?.view_marital_status?.value,
              name: t("SIDEMENU.MaritalStatus"),
              to: "masters/marital-status",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.MaritalStatus")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <FaIcons.FaHouseUser className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 31,
              roles:(rolesRedux)=>rolesRedux?.view_customer_type?.value,
              name: t("SIDEMENU.customertype"),
              to: "masters/customer-type",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.customertype")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <RiIcons.RiCustomerServiceFill className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 32,
              roles:(rolesRedux)=> rolesRedux?.view_contact_method?.value,
              name: t("SIDEMENU.contactmethod"),
              to: "masters/contact-method",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.contactmethod")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <MdIcons.MdContacts className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 33,
              roles:(rolesRedux)=> rolesRedux?.view_national_id_types?.value,
              name: t("SIDEMENU.nationalIdTypes"),
              to: "masters/national-id-types",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.nationalIdTypes")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <FaIcons.FaIdCard className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 34,
              roles: (rolesRedux)=>rolesRedux?.view_worktype?.value,
              name: t("SIDEMENU.WorkType"),
              to: "masters/worktype",
              component: "",
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.WorkType")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <MdIcons.MdWorkspacesFilled className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },

          ],
        },
      ],
    },
    {
      id: 26,
      roles:(rolesRedux)=> rolesRedux?.view_calls?.value,
      name: t("SIDEMENU.Calls"),
      to: "calls",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.Calls")}
              </span>
            ) : (
              ""
            )
          }
          sx={{ fontFamily: "Cairo-Bold" }}
        >
          <span>
            <MdIcons.MdLocalLibrary className="iconSideMenu" />
          </span>
        </Tooltip>
      ),
      subLink: [],
    },
    {
      id: 35,
      // roles: [22],
      name: t("SIDEMENU.Processes"),
      to: "",
      component: "",
      icon: (open) => (
        <Tooltip
          arrow
          placement="right"
          title={
            !open ? (
              <span style={{ fontFamily: "Cairo-Medium" }}>
                {t("SIDEMENU.Processes")}
              </span>
            ) : (
              ""
            )
          }
          sx={{ fontFamily: "Cairo-Bold" }}
        >
          <span>
            <VscIcons.VscServerProcess
              className="iconSideMenu"
              sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
            />
          </span>
        </Tooltip>
      ),
      subLink: [
        {
          id: 36,
          // roles: [22],
          name: t("SIDEMENU.customerSettings"),
          to: "",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.customerSettings")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <SettingsIcon
                  className="iconSideMenu"
                  sx={{ transform: " matrix(0, -1, 1, 0, 0, 0)" }}
                />
              </span>
            </Tooltip>
          ),
          subLink: [
            {
              id: 37,
              roles:(rolesRedux)=> rolesRedux?.view_payment_type?.value,
              to: "masters/payment_types",
              name: t("SIDEMENU.payment_types"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.payment_types")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <PaymentsIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
            {
              id: 38,
              roles:(rolesRedux)=> rolesRedux?.view_discount?.value,
              to: "masters/discounts",
              name: t("SIDEMENU.Discount"),
              icon: (open) => (
                <Tooltip
                  arrow
                  placement="right"
                  title={
                    !open ? (
                      <span style={{ fontFamily: "Cairo-Medium" }}>
                        {t("SIDEMENU.Discount")}
                      </span>
                    ) : (
                      ""
                    )
                  }
                  sx={{ fontFamily: "Cairo-Bold" }}
                >
                  <span>
                    <DiscountIcon className="iconSideMenu" />
                  </span>
                </Tooltip>
              ),
              subLink: [],
            },
          ],
        },
        {
          id: 39,
          roles:(rolesRedux)=> rolesRedux?.view_order?.value,
          name: t("SIDEMENU.orders"),
          to: "orders",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.orders")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <FaIcons.FaTags className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },
        {
          id: 40,
          roles:(rolesRedux)=> rolesRedux?.view_invoice?.value,
          name: t("SIDEMENU.invoices"),
          to: "invoices",
          component: "",
          icon: (open) => (
            <Tooltip
              arrow
              placement="right"
              title={
                !open ? (
                  <span style={{ fontFamily: "Cairo-Medium" }}>
                    {t("SIDEMENU.invoices")}
                  </span>
                ) : (
                  ""
                )
              }
              sx={{ fontFamily: "Cairo-Bold" }}
            >
              <span>
                <FaIcons.FaFileInvoiceDollar className="iconSideMenu" />
              </span>
            </Tooltip>
          ),
          subLink: [],
        },

      ],
    },
  ]);
  
  useEffect(()=>{
    if(rolesRedux)
    setPathList(pathsList)
  },[rolesRedux])

  const logout = async () => {
    try {
      let data = await userAPI.logout();
      if (data && data?.status) {
        if (cookies.get("token")) cookies.remove("token", { path: "/" });
        if (cookies.get("language", { path: "/" }))
          cookies.remove("language", { path: "/" });
        if (cookies.get("access_type", { path: "/" }))
          cookies.remove("access_type", { path: "/" });
        if (cookies.get("login_company_id", { path: "/" }))
          cookies.remove("login_company_id", { path: "/" });

        navigate("/");
      }
    } catch (err) {
      toast.warn(t("NETWORKMESSAGE.messageError"));
    }
  };
  var checkName = "";
  const getAppBarTitle = (data) => {
    data &&
      data?.length > 0 &&
      data?.map((itm) => {
        if (itm?.subLink?.length > 0) {
          getAppBarTitle(itm?.subLink);
        } else {
          if ("/admin/" + itm?.to == window.location.pathname) {
            checkName = itm?.name;
          }
        }
      });
    return checkName;
    // setAppBarTitle(checkName)
  };

  // useEffect(()=>{
  //   getAppBarTitle(pathsList)
  // },[window.location.pathname])

  return (
    <Box sx={{ display: "flex", transition: "all 0.9 ease !important" }}>
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        drawerwidth={drawerwidth}
        sidemenutheme={sidemenutheme}
        appbarTheme={appbarTheme}
        className={`${company_profile_full_screen ? "d-none" : ""}`}
      >
        <Box
          sx={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            paddingLeft: '0',
            paddingRight: '15px',
          }}
        >

          {!open ? (
            <Box>
              <a
                href={`#${helpers.removeForwordSplash(window.location.pathname)}`}
                style={{ textDecoration: "none" }}
              >
                <IconButton
                  color="inherit"
                  aria-label="open drawer"
                  onClick={handleDrawerOpen}
                  edge="start"
                  sx={{
                    // marginRight: 5,
                    ...(open && { display: "none" }),
                  }}
                >
                  <RiIcons.RiMenuFoldFill
                    style={{ color: "#fff", fontSize: "25px" }}
                  />
                </IconButton>

              </a>
              {rolesRedux?.view_notifications?.value?<NotificationMenu />:null}
              <Tooltip arrow title={<span style={{fontFamily:'Cairo-Bold'}}>{t("GLOBAL.FavoritePage")}</span>}>
                  <IconButton onClick={()=>navigate('my_favourite_pages')}><BookIcon sx={{color:'#fff'}}/></IconButton>
              </Tooltip>
            </Box>
          ) : (
            <Box sx={{ width: "fit-content" }}>
              {rolesRedux?.view_notifications?.value?<NotificationMenu />:null}
              <Tooltip arrow title={<span style={{fontFamily:'Cairo-Bold'}}>{t("GLOBAL.FavoritePage")}</span>}>
                  <IconButton onClick={()=>navigate('my_favourite_pages')}><BookIcon sx={{color:'#fff'}}/></IconButton>
              </Tooltip>
            </Box>
          )}

          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              fontFamily: "Cairo-Medium",
              position: "relative",
              // right: "-50px",
            }}
          >
            <span
              style={{
                color: "#fff",
                fontFamily: "Cairo-Bold",
                fontSize: "18px",
              }}
            >
              {getAppBarTitle(pathsList)}
            </span>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            {screenwidth <= 700 ? null : (
              <Typography
                variant="h6"
                noWrap
                component="div"
                style={{
                  color: appbarTheme?.colorWhite,
                  textAlign: "left",
                  font: "normal normal 21x/40px Cairo-Bold",
                  letterSpacing: " 5.5px",
                  color: "#FFFFFF",
                  opacity: 1,
                  marginLeft: "15px",
                }}
              >
                MILESTONE
              </Typography>
            )}

            <img
              src={MilsetoneLogo}
              style={{
                width: "64px",
                height: " 48px",
                dropShadow: "0px 3px 6px #00000029",
                opacity: 1,
                filter: "brightness(0) invert(1)",
              }}
            />
          </Box>
        </Box>
      </AppBar>
      {!company_profile_full_screen ? (
        <Drawer
          variant="permanent"
          open={open}
          drawerwidth={drawerwidth}
          sidemenutheme={sidemenutheme}
          isSmallWidth={screenwidth <= 700}
        >
          <HeaderDrawer
            open={open}
            setOpen={setOpen}
            handleDrawerClose={handleDrawerClose}
            handleDrawerOpen={handleDrawerOpen}
            isSmallWidth={screenwidth <= 700}
          />

          <Divider
            sx={{
              width: open ? "92%" : "50%",
              height: "2px",
              background: "#EBF2F7 0% 0% no-repeat padding-box",
              margin: "10px auto",
              opacity: "1",
              display: "flex",
              alignSelf: "center",
            }}
          />

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "space-between",
              width: "100%",
              height: "100%",
              // overflowY:'scroll',
              overflow: "hidden",
            }}
          >
            <Box
              sx={{
                width: "100%",
                height: "100%",
                overflowY: "auto",
                overflowX: "hidden",
              }}
            >
              <SideMenuItemArray
                open={open}
                setOpen={setOpen}
                pathsList={pathsList}
                setPathList={setPathList}
              />
            </Box>
            <Box
              sx={{
                // flex:0.5,
                // height:'30%',
                display: "flex",
                justifyContent: open ? "start" : "center",
                flexDirection: "column",
                width: "100%",
                alignItems: open ? "start" : "center",
                "& .iconSideMenu": {
                  color: `${sidemenutheme?.iconColor}`,
                  fontSize: "28px",
                },
                "& .MuiListItemText-root .Mui51Typography-root,& .logout": {
                  textAlign: "left",
                  font: "normal normal 15px/30px Cairo-Medium !important",
                  letterSpacing: "0px",
                  color: "#52575D",
                  opacity: "1",
                },
                "& .logout": {
                  margin: "0 7px !important",
                  position: "relative",
                  top: "-2px",
                },
                transition: "width 225ms cubic-bezier(0.4, 0, 0.6, 1) 0ms",
                // boxShadow:'-9px 3px 15px -7px #000'
              }}
            >
              <Divider
                sx={{
                  width: !open ? "60%" : "95%",
                  height: "3px",
                  background: "#EBF2F7 0% 0% no-repeat padding-box",
                  margin: "0px auto",
                  marginTop: "5px",
                  // marginRight:open?0:'15px',
                  opacity: "1",
                  marginLeft: open ? "9px" : "17px",
                  display: "flex",
                  // alignSelf:'center'
                }}
              />

              <Box
                sx={{
                  marginLeft: !open ? "0px" : "10px",
                  padding: "15px 10px",
                  display: "flex !important",
                  justifyContent: open ? "start" : "center",
                  alignItems: "center",
                  cursor: "pointer",
                }}
              >
                <LogoutIcon
                  onClick={logout}
                  sx={{ transform: " matrix(-1, 0, 0, -1, 0, 0)" }}
                  className="iconSideMenu"
                />
                {open ? (
                  <span
                    onClick={logout}
                    className="logout"
                    sx={{ opacity: open ? 1 : 0, margin: "0 10px !important" }}
                  >
                    {t("SIDEMENU.logout")}
                  </span>
                ) : null}
              </Box>
            </Box>
          </Box>
        </Drawer>
      ) : null}
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 3,
          marginTop:
            window.location.pathname ==
              "/admin/available-compounds/compound-map"
              ? "0px"
              : company_profile_full_screen
                ? 0
                : "60px",
          backgroundColor: "transparent",
          paddingRight: 0,
          paddingLeft: 0,
          paddingTop: 0,
          paddingBottom: 0,
          // minWidth:'1000px !important'
        }}
      >
        {/* <DrawerHeader /> */}
        {globalloading ?
          <Preloading />
          : null}
        <Outlet />
      </Box>
    </Box>
  );
}
