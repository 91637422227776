import React from 'react';
import ContractRow from '../Components/ContractRow';
import { Box, Typography } from '@mui/material';
import helpers from '../../../../assets/js/helper';
import { useRef } from 'react';

export default function BuyContract2({ order, bankName, bankBranch, contractNumber, printRef }) {

    const getName = () => {
        let customer = order?.customer
        let title = "السيد"
        if (customer?.user?.gender) {
            title = "السيدة"
            if (customer?.marital_status_id == 2) {
                title = "الانسة"
            }
        }
        return title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
    }

    const getAddress = (lineNumber) => {
        let customerAddress = order?.customer?.addresses?.length > 0 ? order?.customer?.addresses[0] : null
        if (!customerAddress) {
            return
        }
        if (lineNumber == 1) {
            return customerAddress?.name ? customerAddress?.name : ""
        }
        else if (lineNumber == 2) {
            return (customerAddress?.address ? "\n" + customerAddress?.address : "")
        }
        else if (lineNumber == 3) {
            return (customerAddress?.first_phone ? "\nموبايل " + customerAddress?.first_phone : "")
        }

        return ""

    }

    const getUnitPriceFormated = () => {
        let unitPrice = order?.discounted_price
        let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

        return "(" + unitPrice?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(unitPrice, unitCurrencyCode)
    }

    const getUnitPaymentTypeDetailFormated = (paymentTypeDetail) => {
        let paymentSentence = ""
        if (paymentTypeDetail?.percentage_absolute == 0)//percentage
        {
            paymentSentence = paymentTypeDetail?.value + "% من ثمن الوحدة السكنية";
        }
        else {
            let paymentValue = paymentTypeDetail?.value
            let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

            paymentSentence = "(" + paymentValue?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(paymentValue, unitCurrencyCode)
        }
        return paymentSentence

    }

    const getFirstPaymentValueFormated = () => {
        if (order?.payment_type?.payment_type_details?.length > 0) {
            let sortedPaymentTypeDetails = order?.payment_type?.payment_type_details
            sortedPaymentTypeDetails?.sort((a, b) => a?.days_duration_from_contract - b?.days_duration_from_contract)
            return getUnitPaymentTypeDetailFormated(sortedPaymentTypeDetails[0])
        }
        else {
            return ""
        }
    }
    
    const getDaysDurationFromContractFormated = (days) => {
        return " " + days + " يوم على توقيع العقد"
    }

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: 'calc(100vh - ' + ((order?.bank_name || order?.bank_branch || order?.contract_number) ? '170px' : '250px') + ')',
                backgroundColor: "#FFFFFF",
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'scroll'
            }}
        >
            <Box
                ref={printRef}
                style={{
                    backgroundColor: "#FFFFFF",
                    width: '100%',
                    padding: '30px',
                    direction: 'rtl',
                    height: '100%',
                    // height: '842px',
                }}>
                <Typography
                    style={{
                        width: '100%',
                        wordWrap: 'break-word',
                        fontFamily: 'Cairo',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        lineHeight: 'normal'
                    }}>
                    <Typography
                        component={'span'}
                        style={{
                            fontFamily: 'Cairo-Bold',
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal'
                        }}>
                        {"الطرف الأول - "}
                    </Typography>
                    {"" + order?.unit?.company?.name + ". ممثلة ب " + order?.unit?.company?.name + "، إضافة لوظيفته او من يخوله."}
                </Typography>
                <Typography
                    style={{
                        width: '100%',
                        wordWrap: 'break-word',
                        fontFamily: 'Cairo',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        lineHeight: 'normal'
                    }}>
                    <Typography
                        component={'span'}
                        style={{
                            fontFamily: 'Cairo-Bold',
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal'
                        }}>
                        {"الطرف الثاني – "}
                    </Typography>
                    {"المشتري " + getName()}
                </Typography>
                <Box
                    style={{
                        display: 'flex',
                        flexDirection: 'row'
                    }}>
                    <Typography
                        component={'span'}
                        style={{
                            fontFamily: 'Cairo-Bold',
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal'
                        }}>
                        {"عنوانه "}
                    </Typography>
                    <Box
                        style={{
                            display: 'flex',
                            flexDirection: 'column',
                            marginRight: '10px'
                        }}>
                        {getAddress(1) ?
                            <Typography
                                style={{
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    fontFamily: 'Cairo',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal'
                                }}>
                                {getAddress(1)}
                            </Typography> : null
                        }
                        {getAddress(2) ?
                            <Typography
                                style={{
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    fontFamily: 'Cairo',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal'
                                }}>
                                {getAddress(2)}
                            </Typography> : null
                        }
                        {getAddress(3) ?
                            <Typography
                                style={{
                                    width: '100%',
                                    wordWrap: 'break-word',
                                    fontFamily: 'Cairo',
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal'
                                }}>
                                {getAddress(3)}
                            </Typography> : null
                        }
                    </Box>
                </Box>
                <Typography
                    style={{
                        width: '100%',
                        wordWrap: 'break-word',
                        fontFamily: 'Cairo',
                        textAlign: 'justify',
                        textJustify: 'inter-word',
                        marginTop: '10px',
                        lineHeight: 'normal'
                    }}>
                    {"بناءاً على رغبة الطرف الأول في توفير السكن اللائق والملائم للمواطنين ودعم الاستثمار في هذا المجال، فقد تم الاتفاق مع (" + order?.unit?.company?.name + ") لبناء " + order?.unit?.compound?.name + "، ولرغبة الطرف الثاني بالحصول على شقة سكنية ضمن مشروع " + order?.unit?.compound?.name + "، فقد اتفق الطرفان على أن يقوم الطرف الاول ببيع الطرف الثاني الشقة المبينة أوصافها في الجدول المرافق لهذا العقد ووفقا لما يأتي:"}
                </Typography>
                <ContractRow
                    title={"1-"}
                    value={"	تبلغ مساحة الشقة (" + order?.unit?.area + ") متر مربع نوع(" + order?.unit?.unit_type?.name + ") وتحتوي على الغرف وملحقاتها وكما مبين في الجدول المرافق لهذا العقد."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"2-"}
                    value={"يكون ثمن الشقة " + getUnitPriceFormated() + ". ويلتزم الطرف الثاني (المشتري) بدفعه للمصرف الذي يحدده الطرف الاول كاملا أو وفق الآلية المبينة في الفقرة (3) أدناه."}
                    paddingRight={"20px"}

                />

                <ContractRow
                    title={"3-"}
                    value={"طريقة الدفع:"}
                    paddingRight={"20px"}

                />
                {order?.payment_type?.payment_type_details?.length > 0 ?
                    order?.payment_type?.payment_type_details?.map((payment_type_detail, index) => {

                        if (payment_type_detail?.days_duration_from_contract == 0) {
                            return (
                                <ContractRow
                                    key={index + "_payment_details"}
                                    title={helpers?.getArabicLettersNumbering(index) + ") "}
                                    value={"يلتزم الطرف الثاني (المشتري) بتسديد " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " من ثمن الشقة إلى الطرف الأول (" + order?.unit?.company?.name + ") عند توقيع هذا العقد كضمان الحجز المسبق للشقة، ويودع هذا المبلغ في حساب خاص باسم " + order?.unit?.company?.name + " (مشروع " + order?.unit?.compound?.name + " السكني) لدى " + bankName?.value + "."}
                                    paddingRight={"40px"}

                                />
                            )
                        }
                        else if (order?.payment_type?.payment_type_details?.length == index + 1) {
                            return (
                                <React.Fragment key={index + "_payment_details"}>
                                    <ContractRow
                                        title={helpers?.getArabicLettersNumbering(index) + ") "}
                                        value={"يلتزم الطرف الثاني (المشتري) بتسديد: " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " عند استلام الوحدة السكنية"}
                                        paddingRight={"40px"}

                                    />
                                    <ContractRow
                                        title={helpers?.getArabicLettersNumbering(index + 1) + ") "}
                                        value={"يتم تسديد المبالغ المستحقة في الفقرات أعلاه ب" + (order?.unit?.iqd_usd == 0 ? "الدينار العراقي" : "الدولار الامريكي") + " او ما يعادله ب" + (order?.unit?.iqd_usd == 0 ? "الدولار الامريكي" : "الدينار العراقي") + " وبسعر الصرف المعتمد."}
                                        paddingRight={"40px"}

                                    />
                                </React.Fragment>

                            )
                        }
                        else {
                            return (
                                <ContractRow
                                    key={index + "_payment_details"}
                                    title={helpers?.getArabicLettersNumbering(index) + ") "}
                                    value={"يلتزم الطرف الثاني (المشتري) بتسديد: " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " بعد مرور " + getDaysDurationFromContractFormated(payment_type_detail?.days_duration_from_contract)}
                                    paddingRight={"40px"}

                                />
                            )
                        }

                    }) : null}


                <ContractRow
                    title={"4-"}
                    value={""}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"أ) "}
                    value={"يلتزم الطرف الثاني (المشتري) بتقديم كتاب من دائرته ان كان موظفا او من الهيئة الوطنية للتقاعد ان كان متقاعدا، معنونا إلى الطرف الأول (" + order?.unit?.company?.name + ") ونسخة منه الى المصرف تتعهد فيه جهة الأصدار باستقطاع الأقساط المترتبة على المبلغ المتبقي على الشقة هن راتبه شهريا وإرسالها إلى حساب " + order?.unit?.company?.name + " لدى (" + bankName?.value + " / " + bankBranch?.value + ") ويقدم الكتاب عند التوقيع على العقد ويكون ملزما للجهة التي أصدرته."}
                    paddingRight={"40px"}

                />
                <ContractRow
                    title={"ب) "}
                    value={"يلتزم الطرف الثاني (المشتري) ان لم يكن موظفا بتقديم كفيل ضامن إلى المصرف لضمان تسديد الاقساط المتبقية من ثمن الشقة في الوقت المحدد."}
                    paddingRight={"40px"}

                />
                <ContractRow
                    title={"5- "}
                    value={"في حالة تلكؤ الطرف الثاني (المشتري) في دفع ستة أقساط متتالية فإنه يعد ناكلا ويلغى عقده ويعاد اعلان بيع الشقة على ان يتحمل الطرف الثاني كافة التبعات القانونية والمالية لإعادة البيع."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"6- "}
                    value={"عند تسليم الشقة إلى الطرف الثاني (المشتري)، يتم نقل ملكية الشقة في دائرة التسجيل العقاري وتسجل باسم الطرف الثاني (المشتري) على أن يتم وضع إشارة رهن على الشقة في السجل العقاري لصالح المصرف المحدد لحين تسديد كامل ثمن الشقة ويسدد الطرف الثاني (المشتري) رسوم نقل الملكية لدى دائرة التسجيل العقاري المعنية وبأمكان الطرف الثاني تسديد الأقساط المتبقية دفعة واحدة في أي وقت يشاع لغرض رفع اشارة الرهن العقاري."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"7- "}
                    value={"يلتزم الطرف الثاني (المشتري) باستلام الشقة عند إكمالها في الموعد الذي يحدده الطرف الاول (" + order?.unit?.company?.name + ") وفي حالة رفضه الاستلام لا يعاد له مبلغ الدفعة الاولى " + getFirstPaymentValueFormated() + " من ثمن الشقة الذي سدده إلى الطرف الأول (" + order?.unit?.company?.name + ") ويعتبر حكمه حكم العربون."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"8- "}
                    value={"ستقوم " + order?.unit?.company?.name + " بالتعاون مع الشركة المستثمرة بتأسيس شركة او شركات متخصصة تتولى المحافظة على ديمومة المشروع بمختلف تفاصيله وصيانته واستمرار عمل المرافق والمنظومات كافة وتوفير الخدمات التي يحتاجها الساكنين ويلتزم الطرف الثاني بتسديد ها يترتب عن هذه الخدمات من مبالغ بشكل شهري."}
                    paddingRight={"20px"}

                />

                <ContractRow
                    title={" "}
                    value={"ويخضع الطرف الثاني (المشتري) لأحكام قانون ملكية الطبقات المرقم 61 لسنة 2022 في كل ما يتعلق بالملكية والسكن في هذه الشقة بما في ذلك:"}
                    paddingRight={"20px"}

                />

                <ContractRow
                    title={"أ) "}
                    value={"الالتزام بالمحافظة على الشقة وان يجري عليها الإصلاحات الضرورية والصيانة الدورية."}
                    paddingRight={"40px"}

                />
                <ContractRow
                    title={"ب) "}
                    value={"الالتزام بعدم القيام بأي إضافات أو هدم أو تبديلات أو تغييرات هيكلية في العقار تؤثر على البناية إلا بموافقة الجهة المسؤولة عن إدارة المدينة وصيانتها بالدخول لمعاينة وتنفيذ الإصلاحات اللازمة."}
                    paddingRight={"40px"}

                />

                <ContractRow
                    title={"ج) "}
                    value={"يلتزم الطرف الثاني (المشتري) بدفع التكلفة التي تتكبدها إدارة المدينة عن الأضرار التي يتسبب بها هو أو الساكنين معه أو ضيوفه."}
                    paddingRight={"40px"}

                />

                <ContractRow
                    title={"9- "}
                    value={"لا يحق للطرف الثاني (المشتري) التصرف بالشقة بأي نوع من أنواع التصرفات القانونية إلا بعد تسديد كامل الأقساط المترتبة على الشقة."}
                    paddingRight={"20px"}

                />

                <ContractRow
                    title={"10- "}
                    value={"تنتهي مسؤولية الطرف الأول (" + order?.unit?.company?.name + ") بموجب هذا العقد عند تسليم الشقة للطرف الثاني (المشتري)."}
                    paddingRight={"20px"}

                />


                <ContractRow
                    title={"11- "}
                    value={"ا يحق للطرف الثاني (المشتري) استعمال الشقة لغير أغراض السكن."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"12- "}
                    value={"لا يحق للطرف الثاني مطالبة الطرف الأول بالتعويض نتيجة لتأخر الطرف الأول في تنفيذ التزاماته المنصوص عليها في هذا العقد بسبب القوة القاهرة أو غيرها."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"13- "}
                    value={"تنتقل الحقوق المكتسبة بموجب هذا العقد للطرف الثاني (المشتري) الى الورثة في حالة الوفاة حسب الأحكام العامة للقانون."}
                    paddingRight={"20px"}

                />

                <ContractRow
                    title={"14- "}
                    value={"في حالة حصول نزاع بين الطرفين بشأن تفسير وتنفيذ هذا العقد فيتم حله وديا خلال 30 يوما من تاريخ تبليغ أحد الطرفين للأخر بحصول هذا النزاع وبخلافه يمكن اللجوء إلى المحكمة المختصة لحل هذا النزاع."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"15- "}
                    value={"يكون الموقع والعنوان الالكتروني (10000.801/.9م00609101/651) للطرف الأول (" + order?.unit?.company?.name + ") هو المعتمد للتبليغات والمعلومات ويكون الموقع والعنوان الالكتروني للطرف الثاني المدون أدناه هو المعتمد للتبليغات والمعلومات."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"16- "}
                    value={"تطبق أحكام القانون العراقي على هذا العقد وتكون المحكمة العراقية التي يقع العقار ضمن اختصاصها المكاني هي المختصة بالنظر في الدعاوى الناشئة عن هذا العقد."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"17- "}
                    value={"يتحمل الطرف الثاني / المشتري (مسؤولية تقديم أية وثائق رسمية غير أصولية للطرف الأول)."}
                    paddingRight={"20px"}

                />

                <Typography
                    component={'h1'}
                    className={'break_contract_attachment'}
                    style={{
                        marginTop: "20px",
                        fontFamily: 'Cairo-Bold',
                        textAlign: 'center',
                        lineHeight: 'normal'
                    }}
                >
                    {"ملحق العقد"}
                </Typography>
                <ContractRow
                    title={"الطرف الأول: - "}
                    titleFlex={'none'}
                    value={"" + order?.unit?.company?.name + ""}
                    paddingRight={"20px"}
                />
                <ContractRow
                    title={"الطرف الثاني: - "}
                    titleFlex={'none'}
                    value={"المشتري"}
                    paddingRight={"20px"}
                />
                <ContractRow
                    title={" "}
                    value={"إشارة إلى العقد المرقم (" + contractNumber?.value + ") اتفق الطرفان على ما يلي:"}
                    paddingRight={"20px"}
                />
                <ContractRow
                    title={"1. "}
                    value={"يقر الطرف الثاني باستلام مفاتيح الوحدة السكنية العائدة له موضوع العقد المرقم أعلاه."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"2. "}
                    value={"يلتزم الطرف الثاني بدفع الأقساط الشهرية وعدم التلكؤ في ذلك وبعكسه يتم اعتباره ناكلا ويتحمل كافة التبعات القانونية وفقا للعقد المبرم معه."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"3. "}
                    value={"يلتزم الطرف الثاني بدفع أجور الماء والكهرباء والصرف الصحي والغاز وأجور التنظيف"}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"4. "}
                    value={"يلتزم الطرف الثاني بالمحافظة على نظافة الوحدات السكنية والمدينة وعدم رمي النفايات ألا في الأماكن المخصصة لها."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"5. "}
                    value={"يلتزم الطرف الثاني بالمحافظة على النظام العام والآداب العامة والهدوء والسكينة في المدينة."}
                    paddingRight={"20px"}

                />
                <ContractRow
                    title={"6. "}
                    value={"يلتزم الطرف الثاني بعدم الاقتراب من أعمال البناء وعدم التدخل في شؤون الشركة والعاملين معها. يعتبر هذا الملحق جزء لا يتجزأ من العقد الأصلي وفي حالة مخالفة أي بند من بنود العقد الأصلي أو هذا الملحق يكون من حق الطرف الأول فسخ العقد مع تحميل الطرف الثاني كافة التبعات القانونية."}
                    paddingRight={"20px"}

                />
            </Box>

        </Box>
    )
}