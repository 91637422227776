import icons from "../../../Admin/Masters/FavouritePage/Icons";

const ObjectOfErrors = {
    id:"",
    name:{
        value: "",
        error: false,
        message: "",
        required: true
    },
    color:{
        value: '#1E6A99',
        error: false,
        message: "",
        required: false
    },
    route:{
        value: "",
        error: false,
        message: "",
        required: true
    },
    icon:{
        value: {
            id:"",
            name:"",
            label:""
        },
        error: false,
        message: "",
        required: true
    },
}
export const resetErrors = (currentObject) => {
    var objectKeys = Object.keys(currentObject);
   
    var updatedObject = {
        ...currentObject
    }
    try {
        objectKeys.filter(key=>key!=="id").map((key) => {
        
            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {
        
    }
    
    return updatedObject

}
export const checkErrors = (errorMessages, currentObject) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if(!key?.includes('contacts')){
        let messages = []
        errorMessages[key].map((message) => {
            messages.push(message)
        })
       
        currentObject[key]["error"] = true
        currentObject[key]["message"] = messages
       }
    })


    return currentObject
}

const getIconByName = (name) => {
    let icon = {
        id:"",
        name:"",
        label:""
    }
    const filteredIcons = icons?.filter(item => {
        return item?.name == name
    })
    if(filteredIcons?.length)
    {
        icon = filteredIcons[0]
    }
    return icon
   
}
export const currentObjectMerge = (updatedObject) => {
    const mergedCurrentObject = {
    id:updatedObject?.id,
    name: {
        value: updatedObject?.name,
        error: false,
        message: "",
        required: false
    },
    icon: {
        value: getIconByName(updatedObject?.icon),
        error: false,
        message: "",
        required: true
    },
    route: {
        value: updatedObject?.route,
        error: false,
        message: "",
        required: true
    },
    color: {
        value: updatedObject?.color,
        error: false,
        message: "",
        required: false
    },

    }
    
    return mergedCurrentObject;
}
export const restCurrentObject=(currentObject,obj)=>{
    // var objectKeys = Object.keys(currentObject);
   
    try {
        
        currentObject[obj]["error"] = false
        currentObject[obj]["message"] = ""
    } catch (error) {
        
    }
    
    return currentObject
}
export default ObjectOfErrors;