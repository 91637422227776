import {
  Box,
  TextField,
  Button,
  Autocomplete,
  IconButton,
  Switch,
  FormControlLabel,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import EmployeeError, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../../../Global/Errors/Employee/Employee";
import CloseIcon from "@mui/icons-material/Close";
import EmployeeAPI from "../../../../Global/Network/Employee/index";
import { toast } from "react-toastify";
import * as RiIcons from "react-icons/ri";
import * as BsIcons from "react-icons/bs";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import entityAPI from "../../../../Global/Network/Entity";
import { setGlobalLoading } from "../../../../reduxStore/SettingsReducer";
import { useDispatch } from "react-redux";
import Images from "../../../Components/Images";
import AddressesTable from "../../Compounds/PopUpComponents/AddressesTable";
import Addresses from "../../../Components/Addresses";
import AddIcon from "@mui/icons-material/Add";
import CustomePhoneField from "../../../../General/CustomComponents/CustomePhoneField";
import helpers from "../../../../assets/js/helper";
import CompanyAPI from "../../../../Global/Network/Companies";
import compoundAPI from "../../../../Global/Network/Compound";

const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL_image}`,
};

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(EmployeeError);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)
  const rolesRedux = useSelector(state=>state.rolesData.roles)
  const handleChange = (e, number, errName) => {
    if (number == 1) {
      props.setUserName(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setFullName(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setPhone(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setEmail(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setPassword(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setConfirmPassword(e.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setCompany(e);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 8) {
      props.setSelectedCompounds(e);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setUserName("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setFullName("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setPhone("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setEmail("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setPassword("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setConfirmPassword("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setCompany(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };

  const openImageSelector = (e) => {
    let imageElem = e.target;
    if (imageElem) {
      imageElem = imageElem.querySelector("input");
      if (imageElem) {
        imageElem.click();
      }
    }
  };
  const onImageChange = (e) => {
    console.log("image", e);
    props.setImage(e.target.files[0]);
    props.setSelectedImageURL(window.URL.createObjectURL(e.target.files[0]));
  };
  const handleUpdateAddress = (obj) => {
    setSelectedAddress(obj);
    props?.setAddAddresses(true);
    props?.setSelectedAddress(obj);
  };

  const handlePhoneChange = (name, e) => {
    let inputtxt = e;
    var phoneno = /^\d{11}$/;
    let object = {
      error: "",
      message: false,
    };
    if (isNaN(inputtxt)) {
      props?.setFirstPhoneMessageError({
        message: "يجب ان يكون الهاتف رقم",
        error: true,
      });
      return false;
    }

    if (e?.length == 11 && !isNaN(inputtxt)) {
      object = {
        message: "",
        error: false,
      };
    } else if (e?.length >= 11) {
      object = {
        message: "رقم الهاتف بصيغة غير صحيحة !",
        phone: true,
      };
    } else {
      object = {
        message: "",
        phone: false,
      };
    }
    props?.setFirstPhoneMessageError({
      ...object,
    });
  };

  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-6 ">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                
                variant="filled"
                label={t("EMPLOYEE.fullName")}
                value={props?.fullName}
                onChange={(e) => handleChange(e, 2, "full_name")}
                placeholder="..."
                type={"text"}
                focused
                className={`${ErrorsObject?.full_name?.error ? "errors" : ""}`}
              />
              {props?.fullName ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(2, "full_name")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.full_name?.error &&
            ErrorsObject?.full_name?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.full_name?.message &&
                ErrorsObject?.full_name?.message?.length > 0
                  ? ErrorsObject?.full_name?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>
          <CustomePhoneField
            value={props?.phone}
            title={t("EMPLOYEE.phone")}
            paddingHorizontal="0 10px"
            direction="rtl"
            onChange={(e) => {
              if (!isNaN(e?.target?.value)) {
                handleChange(e, 3, "phone");
                handlePhoneChange("first_phone", e?.target?.value);
              }
            }}
            onClearClick={() => {
              clearInput(3, "phone");
              props?.setStateCode("");
            }}
            haswidth={false}
            customeWidth={"100%"}
            stateCode={props?.stateCode}
            setStateCode={props?.setStateCode}
            messageStateCode={props?.firstPhoneMessageError?.message}
            error={
              props?.firstPhoneMessageError?.error
                ? props?.firstPhoneMessageError?.error
                : ErrorsObject?.phone?.error
            }
            message={ErrorsObject?.phone?.message}
          />
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                
                variant="filled"
                label={t("EMPLOYEE.email")}
                value={props?.email}
                onChange={(e) => handleChange(e, 4, "email")}
                placeholder="test@test.com"
                type={"text"}
                focused
                className={`${ErrorsObject?.email?.error ? "errors" : ""}`}
              />
              {props?.email ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(4, "email")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.email?.error &&
            ErrorsObject?.email?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.email?.message &&
                ErrorsObject?.email?.message?.length > 0
                  ? ErrorsObject?.email?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>

          {rolesRedux?.view_all_companies?.value ? (
            <ContainerOfSelectField
              mainTheme={maintheme}
              customeWidth={"100%"}
              hasError={ErrorsObject?.company_id?.error}
              isForm={true}
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "10px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-2"
              >
                <Autocomplete
                  //    disablePortal
                  id="combo-box-demo"
                  options={props?.companies ? props?.companies : []}
                  value={props?.company}
                  onChange={(e, newValue) =>
                    handleChange(newValue, 7, "company_id")
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  popupIcon={<RiIcons.RiArrowDropDownLine />}
                  clearIcon={
                    <CloseIcon
                      sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                      onClick={() => clearInput(7, "company_id")}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("EMPLOYEE.company")}
                      focused={true}
                      variant="filled"
                    />
                  )}
                  className={`${ErrorsObject?.company_id?.error} ${
                    ErrorsObject?.company_id?.error
                      ? "Mui-focused"
                      : "Mui-focused"
                  }`}
                />
              </Box>
              {ErrorsObject?.company_id?.error &&
              ErrorsObject?.company_id?.message?.length ? (
                <Box
                  sx={{
                    height: "fit-content",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "95%",
                  }}
                >
                  {ErrorsObject?.company_id?.message &&
                  ErrorsObject?.company_id?.message?.length > 0
                    ? ErrorsObject?.company_id?.message?.map((messg) => (
                        <span
                          style={{
                            fontFamily: "Cairo-Bold",
                            fontSize: "14px",
                            color: "red",
                            height: "auto",
                          }}
                        >
                          {messg}
                        </span>
                      ))
                    : null}
                </Box>
              ) : null}
            </ContainerOfSelectField>
          ) : null}
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.company_id?.error}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-2"
            >
              <Autocomplete
                multiple={true}
                options={
                  props?.compoundsList && props?.compoundsList?.length > 0
                    ? props?.compoundsList
                    : []
                }
                value={
                  props?.selectedCompounds?.length > 0
                    ? props?.selectedCompounds
                    : []
                }
                getOptionLabel={(option) => option?.name || ""}
                onChange={(e, newValue) =>
                  handleChange(newValue, 8, "compounds")
                }
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "unit_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("EMPLOYEE.compounds")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.compounds?.error}`}
              />
            </Box>
            </ContainerOfSelectField>
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 ">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                
                variant="filled"
                label={t("EMPLOYEE.username")}
                value={props?.username}
                onChange={(e) => handleChange(e, 1, "username")}
                placeholder="..."
                type={"text"}
                focused
                className={`${ErrorsObject?.username?.error ? "errors" : ""}`}
              />
              {props?.username ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(1, "username")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.username?.error &&
            ErrorsObject?.username?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.username?.message &&
                ErrorsObject?.username?.message?.length > 0
                  ? ErrorsObject?.username?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}

            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                
                variant="filled"
                label={t("EMPLOYEE.password")}
                value={props?.password}
                onChange={(e) => handleChange(e, 5, "password")}
                type={showPassword ? "text" : "password"}
                autoComplete="new-password"
                focused
                className={`${ErrorsObject?.password?.error ? "errors" : ""}`}
                sx={{
                  "& input": {
                    paddingRight: "70px !important",
                  },
                }}
              />
              {props?.password ? (
                !showPassword ? (
                  <RemoveRedEyeIcon
                    className="closeIcon"
                    sx={{
                      marginRight: "35px !important",
                      fontSize: "21px",
                      color: maintheme?.iconColor,
                    }}
                    onClick={() => {
                      setShowPassword(true);
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    className="closeIcon mr-3"
                    sx={{
                      marginRight: "35px !important",
                      fontSize: "21px",
                      color: maintheme?.iconColor,
                    }}
                    onClick={() => {
                      setShowPassword(false);
                    }}
                  />
                )
              ) : null}
              {props?.password ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(5, "password")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.password?.error &&
            ErrorsObject?.password?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.password?.message &&
                ErrorsObject?.password?.message?.length > 0
                  ? ErrorsObject?.password?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}

            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <TextField
                
                variant="filled"
                label={t("EMPLOYEE.confirmPassword")}
                value={props?.confirmPassword}
                autoComplete="new-password"
                onChange={(e) => handleChange(e, 6, "confirm_password")}
                type={showConfirmPassword ? "text" : "password"}
                focused
                className={`${
                  ErrorsObject?.confirm_password?.error ? "errors" : ""
                }`}
                sx={{
                  "& input": {
                    paddingRight: "70px !important",
                  },
                }}
              />
              {props?.confirmPassword ? (
                !showConfirmPassword ? (
                  <RemoveRedEyeIcon
                    className="closeIcon"
                    sx={{
                      marginRight: "35px !important",
                      fontSize: "21px",
                      color: maintheme?.iconColor,
                    }}
                    onClick={() => {
                      setShowConfirmPassword(true);
                    }}
                  />
                ) : (
                  <VisibilityOffIcon
                    className="closeIcon"
                    sx={{
                      marginRight: "35px !important",
                      fontSize: "21px",
                      color: maintheme?.iconColor,
                    }}
                    onClick={() => {
                      setShowConfirmPassword(false);
                    }}
                  />
                )
              ) : null}
              {props?.confirmPassword ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(6, "confirm_password")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.confirm_password?.error &&
            ErrorsObject?.confirm_password?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.confirm_password?.message &&
                ErrorsObject?.confirm_password?.message?.length > 0
                  ? ErrorsObject?.confirm_password?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}

            <Box
              sx={{
                backgroundColor: "#fff",
                boxShadow: "0 0 15px -1px rgba(0,0,0,0.2)",
                padding: "10px",
                margin: "2px 10px",
                "& .MuiTypography-root ": {
                  fontFamily: "Cairo-Medium",
                },
                padding: "7px 10px",
                margin: "10px auto",
                width: screenwidth < 991 ? "100%" : "100%",
                height: "55px",
              }}
            >
              <FormControlLabel
                sx={{
                  width: "100%",
                  fontFamily: "Cairo-Medium",
                }}
                control={
                  <Switch
                    checked={props?.active}
                    onChange={(e) => {
                      props?.setActive(e.target.checked);
                    }}
                  />
                }
                label={props?.active ? t('EMPLOYEE.enable') : t('EMPLOYEE.disable')}
              />
            </Box>
          </ContainerOfInputFields>
        </Box>
        <Box className="col-12 col-sm-12 col-md-12 col-lg-12 my-3">
          {
            <Images
              imageList={props?.imageList}
              setImageList={props.setImageList}
              isUpdateImage={props?.isUpdateImage}
              setIsUpdatedImage={props?.setIsUpdatedImage}
              editMode={props?.editMode}
              loading={props?.loading}
              setLoading={props?.setLoading}
              type={props?.type}
              type_id={props?.type_id}
              notHasSlice={props?.notHasSlice}
               
              hideImageType={props?.hideImageType}
              notHavePolyImage={true}
            />
            // :null
          }
        </Box>
        {rolesRedux?.view_address?.value ? (
          <Box
            className="col-12 col-sm-12 col-md-12 col-lg-12 mt-5"
            sx={{ paddingRight: "30px" }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "start",
                alignItems: "center",
              }}
            >
              {rolesRedux?.add_address?.value ? (
                <IconButton
                  onClick={() => {
                    props.setAddAddresses(!props?.addAddresses);
                    props?.setSelectedAddress(null);
                    setSelectedAddress(null);
                  }}
                >
                  <AddIcon
                    sx={{
                      color: "#1E6A99",
                      fontSize: "30px",
                      margin: "0 10px",
                    }}
                  />
                </IconButton>
              ) : null}
              <span
                style={{
                  color: "#000",
                  fontFamily: "Cairo-Bold",
                  fontSize: "19px",
                  marginRight: "10px",
                }}
              >
                {t("ADDRESSES.addNewAddressTitle")}
              </span>
            </Box>
            <AddressesTable
              editMode={props?.editMode}
              listOfAddresses={props?.listOfAddresses}
              setListOfAddresses={props.setListOfAddresses}
              handleUpdateAddress={handleUpdateAddress}
               
              type={props?.type}
              deleteAddressNotHasSlice={props?.deleteAddressNotHasSlice}
            />
          </Box>
        ) : null}
        {rolesRedux?.view_address?.value && rolesRedux?.add_address?.value ? (
          <Addresses
            editMode={props?.editMode}
            addForm={props?.addAddress}
            setAddForm={props.setAddAddresses}
            listOfAddresses={props?.listOfAddresses}
            setListOfAddresses={props.setListOfAddresses}
            handleGetNewAddress={props.handleGetNewAddress}
            object={selectedAddress}
            setObject={setSelectedAddress}
             
            type={props?.type}
          />
        ) : null}
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={props.submit}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddEmployee(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(EmployeeError);
  const [username, setUserName] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [company, setCompany] = useState("");
  const [companies, setCompanies] = useState([]);
  const [image, setImage] = useState();
  const [selectedImageURL, setSelectedImageURL] = useState("");
  const dispatch = useDispatch();
  const [stateCode, setStateCode] = useState("");
  const [imageList, setImageList] = useState([]);
  const [isUpdateImage, setIsUpdatedImage] = useState(false);
  const [addAddress, setAddAddresses] = useState(false);
  const [compoundsList, setCompoundsList] = useState([]);
  const [selectedCompounds, setSelectedCompounds] = useState([]);
  const [listOfAddresses, setListOfAddresses] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const rolesRedux=useSelector(state=>state.rolesData.roles)
  const [firstPhoneMessageError, setFirstPhoneMessageError] = useState({
    error: false,
    message: "",
  });
  const [active, setActive] = useState(false);

  useEffect(() => {
    let dumData = resetErrors(ErrorsObject);
    clearForm();
    if (props?.employee) {
      props?.employee?.images &&
        props?.employee?.images?.length > 0 &&
        props?.employee?.images?.map((itm) => {
          console.log("img", itm?.image_url);
          setSelectedImageURL(APP_CONSTANTS.API_URL_IMG + itm?.image_url);
        });
      let images = [];
      props?.employee?.images &&
        props?.employee?.images?.length > 0 &&
        props?.employee?.images?.map((image, index) => {
          // let checkImage = getExtension(image?.file_path)
          images.push({
            url: process.env.REACT_APP_API_URL_image + image?.image_url,
            file: null,
            index: index,
            id: image?.id,
            type: "image",
            name: image?.name,
            description: image?.description,
            image_type: image?.image_type,
          });
        });
      setImageList(images);
      let addArr = [];
      props?.employee?.addresses &&
        props?.employee?.addresses?.length > 0 &&
        props?.employee?.addresses?.map((itm, index) => {
          let code1 = "",
            code2 = "",
            code3 = "";
          let phone1 = "",
            phone2 = "",
            phone3 = "";

          if (itm?.first_phone) {
            let temp = itm?.first_phone?.split("-");
            code1 = temp[0];
            phone1 = temp[1];
          }
          if (itm?.second_phone) {
            let temp = itm?.second_phone?.split("-");
            code2 = temp[0];
            phone2 = temp[1];
          }
          if (itm?.third_phone) {
            let temp = itm?.third_phone?.split("-");
            code3 = temp[0];
            phone3 = temp[1];
          }

          addArr.push({
            ...itm,
            code1: code1,
            code2: code2,
            code3: code3,
            first_phone: "0" + phone1,
            second_phone: "0" + phone2,
            third_phone: "0" + phone3,
            index: index,
          });
        });
      setListOfAddresses(addArr);
    }
    if (props?.employee && props?.employee?.user) {
      console.log("empl", props?.employee);
      setUserName(props?.employee?.user?.username);
      setFullName(props?.employee?.user?.full_name);
      setActive(props?.employee?.user?.active==1?true:false);
      setSelectedCompounds(props?.employee?.compounds);
      let objectPhone = helpers.handleSplitPhoneWithStateCode(
        props?.employee?.user?.phone
      );
      setPhone(objectPhone?.phone);
      setStateCode(objectPhone?.code);

      setEmail(props?.employee?.user?.email);
      setCompany(props?.employee?.user?.company);
    }
  }, [props?.employee]);

  const submit = async () => {
    let message = "";
    if (!phone) {
      message += "رقم الهاتف مطلوب";
    } else if (phone && (phone?.length > 11 || phone?.length < 3)) {
      message += "رقم الهاتف يجب ان يكون اكثر من 3 ارقام واقل من 12 رقم \n";
    } else if (phone && isNaN(phone)) {
      message += " " + "رقم الهاتف يجب ان يكون رقم";
    }
    if (phone && !stateCode) {
      message += "كود الدولة للرقم مطلوب";
    }

    if (message) {
      setFirstPhoneMessageError({
        message: message,
        error: false,
      });
    }
    if (message) {
      return;
    }

    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);
      const compound_ids = selectedCompounds?.map(c => c.id);

      const dataForm = new FormData();
      if (username) {
        dataForm.append("username", username);
      }
      if (fullName) {
        dataForm.append("full_name", fullName);
      }
      if (password) {
        dataForm.append("password", password);
      }
      if (confirmPassword) {
        dataForm.append("confirm_password", confirmPassword);
      }
      if (phone && stateCode) {
        let phoneMergeWithCode = helpers.handleMergePhoneWithStateCode(
          phone,
          stateCode
        );
        dataForm.append("phone", phoneMergeWithCode);
      }
      if (email) {
        dataForm.append("email", email);
      }
      if (company && rolesRedux?.view_all_companies?.value) {
        dataForm.append("company_id", company?.id);
      }
      if (compound_ids && compound_ids.length > 0) {
        compound_ids.forEach((compound_id, index) => {
          dataForm.append(`compound_ids[${index}]`, compound_id);
        });
      }
      // if (active) {
        dataForm.append("active", active?1:0);
      // }
      //  if(image){
      //   dataForm.append('image',image)
      // }
      let result = null;
      if (props?.employee && props?.employee?.id) {
        dataForm.append("_method", "put");
        result = await EmployeeAPI.UpdateEmployee({
          data: dataForm,
          id: props?.employee?.id,
        });
      } else {
        imageList &&
          imageList.forEach((image, index) => {
            dataForm.append("images[" + index + "][image]", image?.file);
            dataForm.append(
              "images[" + index + "][image_type_id]",
              image?.image_type?.id
            );
            dataForm.append("images[" + index + "][name]", image?.name);
            dataForm.append(
              "images[" + index + "][description]",
              image?.description
            );
          });

        listOfAddresses?.map((itm, index) => {
          if (itm?.name)
            dataForm.append(`addresses[${index}][name]`, itm?.name);
          if (itm?.address)
            dataForm.append(`addresses[${index}][address]`, itm?.address);
          if (itm?.first_phone)
            dataForm.append(
              `addresses[${index}][first_phone]`,
              itm?.first_phone
            );
          if (itm?.second_phone)
            dataForm.append(
              `addresses[${index}][second_phone]`,
              itm?.second_phone
            );
          if (itm?.third_phone)
            dataForm.append(
              `addresses[${index}][third_phone]`,
              itm?.third_phone
            );
          if (itm?.governorate)
            dataForm.append(
              `addresses[${index}][governorate_id]`,
              itm?.governorate?.id
            );
          if (itm?.latitude)
            dataForm.append(`addresses[${index}][latitude]`, itm?.latitude);
          if (itm?.longitude)
            dataForm.append(`addresses[${index}][longitude]`, itm?.longitude);
        });
        result = await EmployeeAPI.AddEmployee({
          data: dataForm,
        });
      }

      if (result?.status) {
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        clearForm();
        props.loadData();
        props.setAddForm(false);
      } else {
        if (typeof result.errorMessage === "object") {
          let updatedObject = resetErrors(ErrorsObject);
          //  alert('asdasd')
          console.log("message", result.errorMessage, updatedObject);

          setErrorsObject(checkErrors(result.errorMessage, updatedObject));
        }
        setLoading(false);
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error.message);
    }
  };
  const clearForm = () => {
    setUserName("");
    setFullName("");
    setPhone("");
    setEmail("");
    setPassword("");
    setConfirmPassword("");
    setImage("");
    setSelectedImageURL("");
    setCompany(null);
    setImageList([]);
    setListOfAddresses([]);
    setActive(false);
  };
  useEffect(() => {
    if (!props?.employee) clearForm();
    if (rolesRedux?.view_all_companies?.value) loadDataOfCompanyList();
  }, [props?.addForm, props?.employee]);

  const handleGetNewAddress = async (newAddress) => {
    if (!props?.object?.id) {
      setListOfAddresses((itm) => [
        ...itm,
        {
          ...newAddress,
          index:
            listOfAddresses[listOfAddresses?.length - 1] &&
            listOfAddresses[listOfAddresses?.length - 1]?.index
              ? listOfAddresses[listOfAddresses?.length - 1]?.index + 1
              : 1,
        },
      ]);
      setAddAddresses(false);
      setSelectedAddress(null);
    } else {
      dispatch(setGlobalLoading(true));
      try {
        let type = "employee";
        // if(type&&type?.slice(type?.length-1=='s')){
        // type=type?.slice(0,type?.length-1);
        // }
        if (!selectedAddress) {
          let data = await entityAPI.addAddress({
            type: type,
            type_id: props?.object?.id,
            data: {
              ...newAddress,
              governorate_id: newAddress?.governorate?.id,
            },
          });
          if (data && data?.status) {
            let code1 = "",
              code2 = "",
              code3 = "";
            let phone1 = "",
              phone2 = "",
              phone3 = "";

            if (newAddress?.first_phone) {
              let temp = newAddress?.first_phone?.split("-");
              code1 = temp[0];
              phone1 = temp[1];
            }
            if (newAddress?.second_phone) {
              let temp = newAddress?.second_phone?.split("-");
              code2 = temp[0];
              phone2 = temp[1];
            }
            if (newAddress?.third_phone) {
              let temp = newAddress?.third_phone?.split("-");
              code3 = temp[0];
              phone3 = temp[1];
            }

            if (data?.data?.addresses) {
              let addArr = [];
              data?.data?.addresses &&
                data?.data?.addresses?.length > 0 &&
                data?.data?.addresses?.map((itm, index) => {
                  let code1 = "",
                    code2 = "",
                    code3 = "";
                  let phone1 = "",
                    phone2 = "",
                    phone3 = "";

                  if (itm?.first_phone) {
                    let temp = itm?.first_phone?.split("-");
                    code1 = temp[0];
                    phone1 = temp[1];
                  }
                  if (itm?.second_phone) {
                    let temp = itm?.second_phone?.split("-");
                    code2 = temp[0];
                    phone2 = temp[1];
                  }
                  if (itm?.third_phone) {
                    let temp = itm?.third_phone?.split("-");
                    code3 = temp[0];
                    phone3 = temp[1];
                  }

                  addArr.push({
                    ...itm,
                    code1: code1,
                    code2: code2,
                    code3: code3,
                    first_phone: "0" + phone1,
                    second_phone: "0" + phone2,
                    third_phone: "0" + phone3,
                    index: index,
                  });
                });
              setListOfAddresses(addArr);
            } else {
              setListOfAddresses((itm) => [
                ...itm,
                {
                  ...newAddress,
                  index:
                    listOfAddresses[listOfAddresses?.length - 1] &&
                    listOfAddresses[listOfAddresses?.length - 1]?.index
                      ? listOfAddresses[listOfAddresses?.length - 1]?.index + 1
                      : 1,
                  code1: code1,
                  code2: code2,
                  code3: code3,
                  first_phone: "0" + phone1,
                  second_phone: "0" + phone2,
                  third_phone: "0" + phone3,
                },
              ]);
            }

            setAddAddresses(false);
            setSelectedAddress(null);
            dispatch(setGlobalLoading(false));
          } else {
            setAddAddresses(false);
            dispatch(setGlobalLoading(false));
          }
        } else {
          let data = await entityAPI.updateAddress({
            id: newAddress?.id,
            type: type,
            type_id: props?.object?.id,
            data: {
              ...newAddress,
              governorate_id: newAddress?.governorate?.id,
            },
          });
          if (data && data?.status) {
            let arr = [];
            listOfAddresses &&
              listOfAddresses?.length > 0 &&
              listOfAddresses?.map((itm) => {
                if (itm?.id == newAddress?.id) {
                  let code1 = "",
                    code2 = "",
                    code3 = "";
                  let phone1 = "",
                    phone2 = "",
                    phone3 = "";

                  if (newAddress?.first_phone) {
                    let temp = newAddress?.first_phone?.split("-");
                    code1 = temp[0];
                    phone1 = temp[1];
                  }
                  if (newAddress?.second_phone) {
                    let temp = newAddress?.second_phone?.split("-");
                    code2 = temp[0];
                    phone2 = temp[1];
                  }
                  if (newAddress?.third_phone) {
                    let temp = newAddress?.third_phone?.split("-");
                    code3 = temp[0];
                    phone3 = temp[1];
                  }

                  arr.push({
                    ...itm,
                    ...newAddress,
                    code1: code1,
                    code2: code2,
                    code3: code3,
                    first_phone: "0" + phone1,
                    second_phone: "0" + phone2,
                    third_phone: "0" + phone3,
                  });
                } else {
                  arr.push(itm);
                }
                setListOfAddresses(arr);
              });
            setAddAddresses(false);
            setSelectedAddress(null);
            dispatch(setGlobalLoading(false));
          } else {
            setAddAddresses(false);
            dispatch(setGlobalLoading(false));
            toast.error(t("NETWORKMESSAGE.messageError"));
          }
        }
      } catch (err) {
        console.log(err.message);
        dispatch(setGlobalLoading(false));
      }
    }
  };
  const loadDataOfCompanyList = async () => {
    let data = null;
    try {
      data = await CompanyAPI.CompanyList();
      if (data && data?.status) {
        setCompanies(data?.data);
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };
  const loadDataOfCompoundList = async () => {
    let data = null;
    try {
      let data = await compoundAPI.compounds();
      if (data && data?.status) {
        setCompoundsList(data?.data?.data);
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
  };

  useEffect(() => {
    loadDataOfCompoundList();
  }, []);

  return (
    <PopupForm
      customeWidth="85%"
      open={props.addForm}
      setOpen={props.setAddForm}
      title={
        props?.employee ? t("EMPLOYEE.updateTitle") : t("EMPLOYEE.addTitle")
      }
      content={
        <RenderContent
          username={username}
          setUserName={setUserName}
          fullName={fullName}
          setFullName={setFullName}
          password={password}
          setPassword={setPassword}
          confirmPassword={confirmPassword}
          setConfirmPassword={setConfirmPassword}
          phone={phone}
          setPhone={setPhone}
          email={email}
          setEmail={setEmail}
          company={company}
          setCompany={setCompany}
          companies={companies}
          image={image}
          setImage={setImage}
          selectedImageURL={selectedImageURL}
          setSelectedImageURL={setSelectedImageURL}
          setStateCode={setStateCode}
          stateCode={stateCode}
          open={props.addForm}
          setOpen={props.setAddForm}
          imageList={imageList}
          setImageList={setImageList}
          type={"employee"}
          notHasSlice={true}
          type_id={props?.object?.id}
          hideImageType={false}
           
          editMode={props?.object?.id ? true : false}
          loading={loading}
          setLoading={setLoading}
          setAddAddresses={setAddAddresses}
          addAddress={addAddress}
          setListOfAddresses={setListOfAddresses}
          listOfAddresses={listOfAddresses}
          handleGetNewAddress={handleGetNewAddress}
          setSelectedAddress={setSelectedAddress}
          selectedAddress={selectedAddress}
          setIsUpdatedImage={setIsUpdatedImage}
          deleteAddressNotHasSlice={true}
          setFirstPhoneMessageError={setFirstPhoneMessageError}
          firstPhoneMessageError={firstPhoneMessageError}
          active={active}
          setActive={setActive}
          compoundsList={compoundsList}
          selectedCompounds={selectedCompounds}
          setSelectedCompounds={setSelectedCompounds}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
