import React, { useState, useEffect, useMemo } from "react";
import Grid from "../../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import DropDownGrid from "../../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Autocomplete,
  TextField,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddFormDailog from "./AddFormDailog";
import swal from "sweetalert";
import SearchInput from "../../Components/SearchInput";
import SearchDate from "../../Components/SearchDate";
import EmployeeAPI from "../../../Global/Network/Employee/index";
import { from } from "stylis";
import helpers from "../../../assets/js/helper";
import { useGlobalContext } from "../../../context";
import * as MdIcons from "react-icons/md";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import * as FaIcons from "react-icons/fa";
import customerAPI from "../../../Global/Network/Customer";
import CompoundDepartmentAPI from "../../../Global/Network/CompoundDepartment/index";
import RolesOfUserAPI from "../../../Global/Network/RoleUser/index";
import CallIcon from "@mui/icons-material/Call";
import DepartmentPhoneNumbers from "./DepartmentPhoneNumbers";
import compoundAPI from "../../../Global/Network/Compound";

const RenderSearchContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.objectName}
        setValue={props?.setobjectName}
        title={t("COMPOUNDDEPARTMENTS.work_name_search")}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t("COMPOUNDDEPARTMENTS.work_fromDate_search")}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t("COMPOUNDDEPARTMENTS.work_toDate_search")}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.compounds ? props.compounds : []}
        getOptionLabel={(option) =>
          (option?.name) || ""
        }
        value={props?.selectedCompound}
        onChange={(e, v, r) => {
          props.setSelectedCompound(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("COMPOUNDDEPARTMENTS.compound")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
    </Box>
  );
};

export default function CompoundDepartment() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);
  const [addForm, setAddForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [objectName, setobjectName] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [users, setUsers] = useState([]);
  const { exportToCSV } = useGlobalContext();
  const [filterParams, setFilterParams] = useState({});
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const [compounds, setCompounds] = useState("");
  const [selectedCompound, setSelectedCompound] = useState(null);

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [showAddPhoneNumber, setShowAddPhoneNumber] = useState(null);

  const columns = useMemo(
    () => [
      {
        headerName: t("COMPOUNDDEPARTMENTS.id"),
        field: "id",
        flex: 0.5,
        minWidth: 50,
        maxWidth: 150,
        hide: false,
      },
      {
        headerName: t("COMPOUNDDEPARTMENTS.name"),
        field: "name",
        flex: 1,
        minWidth: 150,
        maxWidth: 450,
        hide: false,
      },

      {
        headerName: t("COMPOUNDDEPARTMENTS.compound"),
        field: "compound",
        flex: 1,
        minWidth: 150,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
              alignItems: "center",
            }}
          >
            <span style={{ fontFamily: "Cairo" }}>
              {rows?.row?.compound?.name}
            </span>
          </Box>
        ),
      },
      {
        headerName: t("COMPOUNDDEPARTMENTS.color"),
        field: "color",
        flex: 1,
        minWidth: 150,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                backgroundColor: rows?.row?.color,
                width: "90%",
                height: "40px",
                margin: "10px 3px",
              }}
            ></Box>
          </Box>
        ),
      },

      {
        headerName: t("COMPOUNDDEPARTMENTS.actions"),
        field: "Actions",
        flex: 0.4,
        minWidth: 100,
        maxWidth: 250,
        hide: false,
        renderCell: (rows) => (
          <DropDownGrid className="containerOFDropDownToolTipeGrid">
            {rolesRedux?.add_compound_department_phone?.value ? (
              <MenuItem
                onClick={() => {
                  addPhoneNumber(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <CallIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("COMPOUNDDEPARTMENTS.addPhoneNumber")}
              </MenuItem>
            ) : null}
            {rolesRedux?.update_compound_department?.value ? (
              <MenuItem
                onClick={() => {
                  updateFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <EditIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("GLOBAL.action_edit")}
              </MenuItem>
            ) : null}
            {rolesRedux?.delete_compound_department?.value ? (
              <MenuItem
                onClick={() => {
                  deleteFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t("GLOBAL.action_delete")}
              </MenuItem>
            ) : null}
            {rolesRedux?.update_compound_department?.value == false &&
            rolesRedux?.delete_compound_department?.value == false ? (
              <p style={{ fontFamily: "Cairo", margin: "5px 20px" }}>
                {t("GLOBAL.emptyPoPup")}
              </p>
            ) : null}
          </DropDownGrid>
        ),
      },
    ],
    [rolesRedux]
  );
  useEffect(() => {
    let abortController = new AbortController();

    loadData();
    return () => {
      abortController.abort();
    };
  }, [pageNumber, pageSize,filterParams]);

  useEffect(() => {
    let abortController = new AbortController();

    loadDataOfUsers();
    loadDataOfCompounds();
    return () => {
      abortController.abort();
    };
  }, []);

  const loadData = async (searchParams) => {
    setLoading(true);
    const filterParamsData = {
      ...filterParams,
      ...searchParams,
    };
    const result = await CompoundDepartmentAPI.compoundDepartments({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData,
      },
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      setRows(result?.data?.data);
      setRowsTotal(result?.data?.total);
      setPageCount(result?.data?.last_page);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true);

    const result = await EmployeeAPI.EmployeeList({
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      setUsers(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const createFUnction = () => {
    setselectedObject(null);
    setAddForm(true);
  };
  const updateFunction = (obj) => {
    setselectedObject(obj);
    setAddForm(true);
  };
  const addPhoneNumber = (obj) => {
    setselectedObject(obj);
    setShowAddPhoneNumber(true);
  };
  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setLoading(true);

          let result = null;
          if (obj && obj?.id) {
            result = await CompoundDepartmentAPI.deleteCompoundDepartments({
              data: {
                id: obj.id,
              },
            });
          }

          if (result?.status) {
            setLoading(false);
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber,
            });
          } else {
            setLoading(false);
            if (result?.errorMessage) {
              toast.error(result?.errorMessage);
            } else toast.error(t("NETWORKMESSAGE.deleteErrorr"));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };
  const clearFunction = () => {
    setobjectName("");
    setFromDate(null);
    setToDate(null);
    setCreatedBy(null);
    setFilterParams(null);
    setSelectedCompound(null)
  };
  const searchFunction = () => {
    let data = {};
    if (objectName) {
      data = {
        ...data,
        name: objectName,
      };
    }
    if (selectedCompound) {
      data = {
        ...data,
        compound_id: selectedCompound?.id,
      };
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id,
      };
    }
    setFilterParams(data);
    // loadData(data);
  };
  const loadDataForExport = async (searchParams) => {
    let result = await CompoundDepartmentAPI.compoundDepartments({
      params: {
        page_size: rowsTotal,
        ...searchParams,
      },
      signal: controller?.signal,
    });
    return result;
  };
  const ExportFunction = async () => {
    let filteredData = {};
    if (objectName) {
      filteredData = {
        ...filteredData,
        name: objectName,
      };
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id,
      };
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات");
      return;
    }

    let arr = [];
    fetchedData?.data?.data &&
      fetchedData?.data?.data?.map((itm) => {
        arr.push({
          [`${t("COMPOUNDDEPARTMENTS.id")}`]: itm?.id,
          [`${t("COMPOUNDDEPARTMENTS.name")}`]: itm?.name,
          [`${t("COMPOUNDDEPARTMENTS.color")}`]: itm?.color,
          [`${t("COMPOUNDDEPARTMENTS.compound")}`]: itm?.compound?.name,
          [`${t("COMPOUNDDEPARTMENTS.created_at")}`]: itm?.created_at
            ? helpers.formatDate(itm?.created_at) +
              " " +
              helpers.formatTime(itm?.created_at)
            : "0000-00-00 00:00:00",
        });
      });
    exportToCSV(arr, "COMPOUNDDEPARTMENTS");
  };
  const loadDataOfCompounds = async () => {
    const result = await compoundAPI.compoundsList();

    if (result.status) {
      let arr = [];
      result?.data &&
        result?.data?.length > 0 &&
        result?.data?.map((itm) => {
          arr.push({
            id: itm?.id,
            name: itm?.name,
          });
        });
      setCompounds(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
      }}
      className=" pt-3 mt-1"
    >
      {addForm ? (
        <AddFormDailog
          object={selectedObject}
          addForm={addForm}
          setAddForm={setAddForm}
          loadData={loadData}
        />
      ) : null}
      {showAddPhoneNumber ? (
        <DepartmentPhoneNumbers
          object={selectedObject}
          addForm={showAddPhoneNumber}
          setAddForm={setShowAddPhoneNumber}
          loadData={loadData}
        />
      ) : null}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginLeft: "10px",
              marginBottom: "10px",
              fontFamily: "Cairo-Bold",
              fontSize: "16px",
            }}
          >
            {t("COMPOUNDDEPARTMENTS.title")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginRight: "20px",
            backgroundColor: "#1E6A99",
            color: "#FFFFFF",
            width: "42px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          aria-controls={openFilterColumn ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? "true" : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser style={{ fontSize: "30px", color: "#fff" }} />
        </Box>
      </Box>

      <Divider
        sx={{
          backgroundColor: "#EBF2F7",
          margin: "30px",
          marginTop: "10px",
          width: "100%",
        }}
        style={{
          height: "3px",
        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_call_type?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_call_type?.value}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={
          <RenderSearchContent
            objectName={objectName}
            setobjectName={setobjectName}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            compounds={compounds}
            selectedCompound={selectedCompound}
            setSelectedCompound={setSelectedCompound}
            users={users}
          />
        }
      />
    </Box>
  );
}
