import {
  Autocomplete,
  Box,
  Button,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import departmentObject, {
  checkErrors,
  resetErrors,
  resetCurrentObject,
  currentObjectMerge
} from "../../../Global/Errors/Department/Department";
import { toast } from "react-toastify";
import { setGlobalLoading } from "../../../reduxStore/SettingsReducer";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import SearchInput from "../../Components/SearchInput";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import RolesAPI from "../../../Global/Network/Roles";
import rolesOfDepartmentAPI from "../../../Global/Network/DepartmentRole";
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import roleCategoryAPI from '../../../Global/Network/RoleCategory'


const FilterSearchContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.unitNameSearch}
        setValue={props?.setUnitNameSearch}
        title={t("DEPARTMENT.role_name_search")}
      />
      <Box sx={{ margin: '10px 0' }}></Box>
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.roleCategories ? props?.roleCategories : []}
        getOptionLabel={(option) => option?.name || ""}

        value={props?.roleCategory}
        onChange={(e, v, r) => { props.setRoleCategory(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ROLE.role_category')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.checkAllselected}
              onChange={(e) => {
                props.setCheckAllselected(e.target.checked);
                if (e.target.checked) props.setCheckAllNotSelected(false);
              }}
            />
          }
          label={t('DEPARTMENT.role_selected')}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props.checkAllNotSelected}
              onChange={(e) => {
                props.setCheckAllNotSelected(e.target.checked);
                if (e.target.checked) props.setCheckAllselected(false);
              }}
            />
          }
          label={t('DEPARTMENT.role_unselected')}
        />

      </Box>
    </Box>
  );
};

const RenderContent = (props) => {
  const [t] = useTranslation("common");

  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("DEPARTMENT.role_id"), field: "id", flex: 0.5 },
    { headerName: t("DEPARTMENT.role_name"), field: "name", flex: 2 },
    {headerName:t('ROLE.role_category'),field:'role_category',flex:2,minWidth:250,maxWidth:450,hide:false,
    renderCell: (rows) => (
      <Box 
      sx={{
        display:'flex',
        flexDirection:'column',
        alignItems: 'center',
        justifyContent: 'center',
    }}
      >
        <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.role_category?.name}</span>

      </Box>
    )
  },
  ]);
  const handleCheckBoxChange = useCallback((rows) => {

    props.setListOfSelectedRows(rows);
    let arr = [];
    props?.roles &&
      props?.roles?.length &&
      props?.roles?.map((itm) => {
        if (rows?.find((e) => e == itm?.id)) {
          arr.push(itm);
        }
      });

    props?.setRole(arr);
  }, []);


  return (
    <Box className="Container-fluid">
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Grid
            rows={props?.roles}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={props?.roles?.length}
            checkScreenSize={screenwidth}
            pageCount={Math.ceil(props?.roles?.length / pageSize)}
            openFilterColumn={null}
            elementClicked={null}
            setOpenFilterColumn={null}
            setColumnFilterValue={null}
            columFilterValue={null}
            clearFunction={props?.handleClearInAddvancSearch}
            searchFunction={props?.handleSearchInAddvancSearch}
            creatFunction={null}
            ExportFunction={null}
            importFunction={null}
            mapFunction={null}
            telegramFunction={null}
            hasCreate={false}
            hasImport={false}
            // paginationMode="client"
            hasExport={false}
            hasMap={false}
            hasTetelgram={false}
            filterChilds={
              <FilterSearchContent
                setUnitNameSearch={props?.setUnitNameSearch}

                unitNameSearch={props?.unitNameSearch}
                roleCategories={props?.roleCategories}
                roleCategory={props?.roleCategory}
                setRoleCategory={props?.setRoleCategory}
                setCheckAllselected={props.setCheckAllselected}
                checkAllselected={props.checkAllselected}
                setCheckAllNotSelected={props.setCheckAllNotSelected}
                checkAllNotSelected={props?.checkAllNotSelected}
              />
            }
            filterHasSelectCounter={true}
            filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
            notHaveAdvancSearch={false}
            hasSelectOption={true}
            handleCheckBoxChange={handleCheckBoxChange}
            listOfSelectedRows={props?.listOfSelectedRows}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,

              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AssignRoleForDepartment(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(departmentObject);
  const dispatch = useDispatch();
  const [roles, setRoles] = useState([])
  const [listOfSelectedRows, setListOfSelectedRows] = useState([])
  const [filterdRoles, setFilterdRoles] = useState([])
  const [unitNameSearch, setUnitNameSearch] = useState()
  const [isSearchApplay, setIsSearchApplay] = useState(false)
  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)
  const [isUploadingTime, setIsUploadingTime] = useState(false)
  const [role, setRole] = useState('')
  const [roleCategories, setRoleCategories] = useState([])
  const [roleCategory, setRoleCategory] = useState(null)

  const submit = async () => {
    if (!props?.department || !props?.department?.id) {
      toast.warn('يجب اختيار الموظف')
      return
    }
    // if(listOfSelectedRows?.length==0){
    //   toast.warn('يجب اختيار الصلاحيات')
    //    return
    // }
    try {
      let result = null;
      setLoading(true)
      if (props?.department && props?.department?.id) {
        result = await rolesOfDepartmentAPI.setDepartmentRolesBulk({
          data: {
            department_id: props?.department?.id,
            role_ids: listOfSelectedRows
          },
        });
      }

      if (result?.status) {
        setLoading(false)
        toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
        clearForm()
        props.loadData()
        props.setAddForm(false)
      } else {
        setLoading(false)
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
      setLoading(false)
    } catch (error) {
      setLoading(false)
      toast.error(error.message)
    }

  }

  const clearForm = () => {
    setListOfSelectedRows([])
  };

  useEffect(() => {
    if (!props?.department) clearForm();
    loadRolesData()
    loadRoleCategories()
    getDepartmentRoles(props?.department)
  }, [props?.addForm, props?.department]);

  const loadRolesData = async () => {
    let data = null;
    try {
      data = await RolesAPI.RooleList();
      if (data && data?.status) {
        setRoles(data?.data)
      } else {
        toast.warn(t('NETWORKMESSAGE.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleSearchInAddvancSearch = () => {
    let arr = []
    if (unitNameSearch) {
      setIsSearchApplay(true)

      roles && roles?.length && roles?.map((itm) => {
        if (itm?.name.includes(unitNameSearch)) {
          arr.push(itm)
        }
      })
      setFilterdRoles(arr)
    } else {
      arr = [...roles];
      setFilterdRoles(roles)
    }

    if (roleCategory) {
      arr = arr.filter(itm => itm?.role_category_id == roleCategory?.id)
      setFilterdRoles(arr)
    }
    if (checkAllselected) {
      setFilterdRoles(arr.filter(itm => listOfSelectedRows?.includes(itm?.id)))
      
    }
    if (checkAllNotSelected) {
      setFilterdRoles(arr.filter(itm => !listOfSelectedRows?.includes(itm?.id)))
    }

  }
  const handleClearInAddvancSearch = () => {
    setIsSearchApplay(false)
    setUnitNameSearch('')
    setFilterdRoles(roles)
    setCheckAllselected(false)
    console.log('hhhddkkkdd', role)
  }

  useEffect(() => {
    if (roles) {
      setFilterdRoles(roles)
    }
  }, [roles])

  const getDepartmentRoles = async (department) => {
    // let arr=[]
    if (department?.roles?.length) {
      setListOfSelectedRows(department?.roles?.map((itm) => itm?.id))//get only ids 
    } else {
      setListOfSelectedRows([])
    }

  }
  const controller = new AbortController()
  const loadRoleCategories = async () => {

    try {
      const result = await roleCategoryAPI.roleCategoriesList({
        signal: controller?.signal
      })
      if (result.status) {
        setRoleCategories(result?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  return (
    <PopupForm
      customeWidth="100%"
      width={"100%"}
      customeHeight={"100%"}
      isFullScreen={true}
      customePadding={'5px 20px'}
      open={props.addForm}
      setOpen={props.setAddForm}
      title={t("DEPARTMENT.AssignRoleForDepartment")}
      content={
        <RenderContent
          setRoles={setRoles}
          roles={filterdRoles}
          role={role}
          setRole={setRole}
          allRoles={roles}
          roleCategories={roleCategories}
          roleCategory={roleCategory}
          setRoleCategory={setRoleCategory}
          unitNameSearch={unitNameSearch}
          setUnitNameSearch={setUnitNameSearch}
          handleSearchInAddvancSearch={handleSearchInAddvancSearch}
          handleClearInAddvancSearch={handleClearInAddvancSearch}
          setListOfSelectedRows={setListOfSelectedRows}
          listOfSelectedRows={listOfSelectedRows}
          isSearchApplay={isSearchApplay}
          checkAllselected={checkAllselected}
          setCheckAllselected={setCheckAllselected}
          setCheckAllNotSelected={setCheckAllNotSelected}
          checkAllNotSelected={checkAllNotSelected}
          open={props.addForm} setOpen={props.setAddForm}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
