import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import { Box, Typography, Divider } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import * as AiIcons from "react-icons/ai";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as FaIcons from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BarCart from "./BarChart";
import PieChartDashboard from "./PieChartDashboard";
import RadialBar from "./RadialBar";
import StackedBarCgart from "./StackedBarCgart";
import LineColumnChart from "./LineColumnChart";
import HomeIcon from "@mui/icons-material/Home";
import "../style.css";
import Filter from "./Filter";
import compoundAPI from "../../../Global/Network/Compound";
import { toast } from "react-toastify";
import DashboardCard from "./DashboardCard";
import PieChart from "./PieChart";
import SalesManBarChart from "./SalesManBarChart";
import BarChartIcon from '@mui/icons-material/BarChart';

export default function Salse(props) {
  const [t] = useTranslation("common");
  const dashboardtheme = useSelector((state) => state.themeData.dashboardtheme);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [country, setCountry] = useState(1);
  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
  };

  const handleSplitIQDAndUSDChart=()=>{
     let arrOfIQD=[],arrOfUSD=[]
     props?.dashboards?.sales?.salesman_sold_unit_value&&props?.dashboards?.sales?.salesman_sold_unit_value?.length>0&&props?.dashboards?.sales?.salesman_sold_unit_value?.map((itm)=>{
          if(itm?.iqd_usd==1){
            arrOfUSD.push(itm)
          }else if(itm?.iqd_usd==0){
            arrOfIQD.push(itm)
          }
     })
     return {
      IQD:arrOfIQD,
      USD:arrOfUSD,
     }
  }

  return (
    <Box  sx={{ width: "100%" }}>
      <Box
        sx={{
          display:'flex',
          justifyContent:'start',
          alignItems:'center',
          flexWrap:'wrap',
          width:'100%',
          "& .MuiCardContent-root":{
            padding:'0 !important'
          }
        }}
        className="px-0"
      >
          {
          props?.dashboards &&
          props?.dashboards?.sales &&
          props?.dashboards?.sales?.units_sold_per_compound?.length
            ? props?.dashboards?.sales?.units_sold_per_compound?.map((itm) => (
                <DashboardCard compounds={itm} dashboards={props?.dashboards} />
              ))
            : null
          }
      </Box>

      <Box
        sx={{
          display:'flex',
          justifyContent:'start',
          alignItems:'center',
          flexWrap:'wrap',
          width:'100%'
        }}
        className="px-0"
      >
      { props?.dashboards &&
      props?.dashboards?.sales &&
      props?.dashboards?.sales?.units_per_status?.length
        ? props?.dashboards?.sales?.units_per_status?.map((itm) =>
            <Box
              sx={{
                width: "fit-content",
                minWidth:'450px',
                maxWidth:'400px',
                height: "395px",
                backgroundColor: "#fff",
                boxShadow: "0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)",
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
                borderRadius: "5px",
                position: "relative",
                overflowX: "hidden",
                margin:'10px',
                '& .apexcharts-legend-series':{
                  display:'flex !important',
                  alignItems:'center !important'
                },
                '& .apexcharts-legend-marker':{
                  margin:'0 7px',
                }
              }}
            >
              <span
                style={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                  fontSize: "20px",
                  fontFamily: "Cairo-medium",
                }}
              >
                {t("DASHBOARD.statusesCompound") +
                  '  "' +
                  itm?.compound_name +
                  ' "'}
              </span>
              <PieChart dashboardObject={itm} />
            </Box>
          )
        : null}
      </Box>



      <Box
        className="col-12 col-md-12 col-lg-12   mb-4 mt-3 px-2"
        sx={{
          width:'100%',
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "500px",
            backgroundColor: "#fff",
            boxShadow: "0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "5px",
            position: "relative",
            overflowX: "auto",
            "& .apexcharts-data-labels":{
              display:'none !important'
            }
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "20px",
              fontFamily: "Cairo-medium",
            }}
          >
            {t("DASHBOARD.salesMansSoldUnitUSD")}
          </span>
          <SalesManBarChart
            dashboardData={handleSplitIQDAndUSDChart()?.USD}
            title={t('DASHBOARD.salesManSoldUnitUSD')}
            color={'#fa2855'}
          />
        </Box>
      </Box>

      <Box
        className="col-12 col-md-12 col-lg-12   mb-4 mt-3 px-2"
        sx={{
          width:'100%',
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "500px",
            backgroundColor: "#fff",
            boxShadow: "0 0.15rem 1.75rem 0 rgba(58, 59, 69, 0.15)",
            display: "flex",
            justifyContent: "center",
            flexDirection: "column",
            alignItems: "center",
            borderRadius: "5px",
            position: "relative",
            overflowX: "auto",
            "& .apexcharts-data-labels":{
              display:'none !important'
            }
          }}
        >
          <span
            style={{
              position: "absolute",
              top: "10px",
              right: "10px",
              fontSize: "20px",
              fontFamily: "Cairo-medium",
            }}
          >
            {t("DASHBOARD.salesMansSoldUnitIQD")}
          </span>
          <SalesManBarChart
            dashboardData={handleSplitIQDAndUSDChart()?.IQD}
            title={t('DASHBOARD.salesManSoldUnitIQD')}
            color={'#1e6a99'}
          />
        </Box>
      </Box>
     
    </Box>
  );
}
