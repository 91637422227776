import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import * as VscIcons from 'react-icons/vsc'

import customerAPI from '../../../Global/Network/Customer';
import OrderAPI from '../../../Global/Network/Orders/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import { useNavigate } from 'react-router-dom'
import GavelIcon from '@mui/icons-material/Gavel';
import ReceiptIcon from '@mui/icons-material/Receipt';
import orderAPI from '../../../Global/Network/Orders/index';
import { setGlobalLoading } from '../../../reduxStore/SettingsReducer';
import PaymentTypeAPI from '../../../Global/Network/PaymentType';
import ContractTemplateAPI from '../../../Global/Network/ContractTemplates';

import discountAPI from '../../../Global/Network/Discount';
import OrderStatusAPI from '../../../Global/Network/OrderStatuses';

import compoundAPI from '../../../Global/Network/Compound';
import blockAPI from '../../../Global/Network/Block';
import buildingAPI from '../../../Global/Network/Building';
import floorAPI from '../../../Global/Network/Floor';
import unitAPI from '../../../Global/Network/Unit';
import PaymentsIcon from '@mui/icons-material/Payments';
import OrderInvoicePayments from './OrderInvoicePayments';
import SearchPhone from '../../Components/SearchPhone';
import generalAPI from '../../../Global/Network/General';
import { debounce } from 'debounce';

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  console.log('user', props?.users)
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      {/* <SearchInput
        value={props?.totalPrice}
        setValue={props?.setTotalPrice}
        title={t('ORDERS.total_price')}
      /> */}
      {/* <SearchInput
        value={props?.discountPrice}
        setValue={props?.setDiscountPrice}
        title={t('ORDERS.discounted_price')}
      /> */}
      {/* <SearchDate
        value={props?.orderedDate}
        setValue={props?.setOrderedDate}
        title={t('ORDERS.ordered_date')}
      /> */}
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('ORDERS.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('ORDERS.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.compounds ? props?.compounds : []}
        clearOnBlur={false}
        value={props?.selectedCompound}
        onChange={(e, v, r) => {
          if (v) {
            props?.setSelectedCompound(v)
            props?.setSelectedBlock(null)
            props?.setSelectedBuilding(null)
            props?.setSelectedFloor(null)

          }
          else {
            props?.setSelectedCompound(null)
          }

        }}
        getOptionLabel={((option) => option?.name)}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر المجمع السكني"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.blocks ? props?.blocks : []}
        clearOnBlur={false}

        value={props?.selectedBlock}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBlock(null)
            props?.setSelectedBuilding(null)
            props?.setSelectedFloor(null)

          }
          else {
            props?.setSelectedBlock(v)
            props?.setSelectedBuilding(null)
            props?.setSelectedFloor(null)


          }


        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر قاطع"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "blocks");
            }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.buildings ? props?.buildings : []}
        clearOnBlur={false}
        value={props?.selectedBuilding}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBuilding(null)
            props?.setSelectedFloor(null)


          }
          else {
            props?.setSelectedBuilding(v)
            props?.setSelectedFloor(null)


          }

        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر البناية"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "buildings");
            }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props?.floors ? props?.floors : []}
        clearOnBlur={false}

        value={props?.selectedFloor && props?.selectedFloor?.id ? props?.selectedFloor : null}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedFloor(null)
          }
          else {
            props?.setSelectedFloor(v)
          }

        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={"اختر الطابق"}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "floors");
            }} />
        )}
        getOptionLabel={((option) => option?.name)}
      />

      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.units ? props.units : []}
        clearOnBlur={false}
        getOptionLabel={(option) => option?.name || ""}

        value={props?.selectedUnit}
        onChange={(e, v, r) => { props.setSelectedUnit(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ORDERS.unit')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "unit");
            }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.orderStatuses ? props.orderStatuses : []}
        getOptionLabel={(option) => option?.name || ""}

        value={props?.selectedOrderStatus}
        onChange={(e, v, r) => { props.setSelectedOrderStatus(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ORDERS.order_status')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.paymentTypes ? props.paymentTypes : []}
        getOptionLabel={(option) => option?.name || ""}

        value={props?.selectedPaymentType}
        onChange={(e, v, r) => { props.setSelectedPaymentType(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ORDERS.payment_type')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.customers ? props.customers : []}
        clearOnBlur={false}
        getOptionLabel={(option) => option?.user?.full_name || ""}

        value={props?.selectedCustomer}
        onChange={(e, v, r) => { props.setSelectedCustomer(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('ORDERS.customer')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "customers");
            }} />
        )}
      />
      <SearchPhone
        value={props?.customerPhone}
        setValue={props?.setCustomerPhone}
        title={t('GLOBAL.customerPohone')}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}

        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('WORKTYPE.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />


    </Box>
  )
}

export default function Order() {
  const [t] = useTranslation("common");
  const navigate = useNavigate()
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const dispatch = useDispatch()
  const rolesRedux = useSelector(state => state.rolesData.roles)



  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null)
  const [contractTemplates, setContractTemplates] = useState([]);
  const [selectedContractTemplate, setSelectedContractTemplate] = useState(null)
  const [units, setUnits] = useState([]);
  const [discounts, setDiscounts] = useState([])
  const [selectedUnit, setSelectedUnit] = useState(null)
  const [selectedDiscounts, setSelectedDiscounts] = useState([])
  const [orderedDate, setOrderedDate] = useState(null)
  const [calculatedDiscountedPrice, setCalculatedDiscountedPrice] = useState(null)
  const [reply, setReply] = useState('')
  const [customerReadOnly, setCustomerReadOnly] = useState(false)
  const [unitReadOnly, setUnitREadOnly] = useState(false)
  const [totalPrice, setTotalPrice] = useState('')
  const [discountPrice, setDiscountPrice] = useState('')
  const [iqdUSD, setIqdUSD] = useState(false)




  const [orderStatuses, setOrderStatuses] = useState([]);
  const [selectedOrderStatus, setSelectedOrderStatus] = useState(null)

  const [compounds, setCompounds] = useState([])
  const [blocks, setBlocks] = useState([])
  const [buildings, setBuildings] = useState([])
  const [floors, setFloors] = useState()
  const [showOrderInvoicesPayments, setShowOrderInvoicesPayments] = useState(false)
  const [selectedCompound, setSelectedCompound] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedFloor, setSelectedFloor] = useState(null)
  const controller = new AbortController();
  const [customerPhone, setCustomerPhone] = useState(null)
  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const [filterParams, setFilterParams] = useState({})
  const showNoActionDropDownItems = () => {
    let check = false;
    if (
      (!rolesRedux?.update_order?.value || (rows?.row?.bank_name || rows?.row?.bank_branch || rows?.row?.contract_number))
      &&
      (!rolesRedux?.delete_order?.value || ((rows?.row?.bank_name && rows?.row?.bank_branch && rows?.row?.contract_number) && rows?.row?.order_status?.id != 1))
      &&
      (!rolesRedux?.view_contract?.value && !rolesRedux?.add_contract?.value)
      &&
      ((!rolesRedux?.view_invoice?.value && !rolesRedux?.add_invoice?.value) || (!rows?.row?.bank_name && !rows?.row?.bank_branch && !rows?.row?.contract_number))
      && !rolesRedux?.add_payment?.value
    ) {
      check = true;
    }

    return check
  }

  const columns = useMemo(() => [
    { headerName: t('ORDERS.id'), field: 'id', width: 30, flex: 0.3, hide: false },
    {
      headerName: t('ORDERS.unit_name'), field: 'unit.name', flex: 1, minWidth: 100, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.unit?.name}
        </Typography>
      )
    },
    {
      headerName: t('ORDERS.customer_name'), field: 'customer.user.full_name', flex: 1.2, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.customer?.user?.full_name}
        </Typography>
      )
    },
    {
      headerName: t('ORDERS.total_price'), field: 'total_price', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {helpers.getFormatedPrice(rows?.row?.total_price, rows?.row?.iqd_usd)}
        </Typography>
      )
    },
    {
      headerName: t('ORDERS.discounted_price'), field: 'discounted_price', flex: 1, minWidth: 200, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {helpers.getFormatedPrice(rows?.row?.discounted_price, rows?.row?.iqd_usd)}
        </Typography>
      )
    },
    {
      headerName: t('ORDERS.order_status'), field: 'order_status', flex: 1, minWidth: 90, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Typography>
          {rows?.row?.order_status?.name}
        </Typography>
      )
    },
    // {
    //   headerName: t('ORDERS.created_at'), field: 'created_at', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
    //   renderCell: (rows) => (
    //     <Box
    //       sx={{
    //         display: 'flex',
    //         flexDirection: 'column',
    //         alignItems: 'center',
    //         justifyContent: 'center',
    //       }}
    //     >
    //       <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatDate(rows?.row?.created_at) : '0000-00-00'}</span>
    //       <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatTime(rows?.row?.created_at) : '00:00:00'}</span>

    //     </Box>
    //   )
    // },
    {
      headerName: t('ORDERS.actions'), field: 'Actions', minWidth: 80, flex: 0.5, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            rolesRedux?.view_invoice?.value || rolesRedux?.add_invoice?.value ?
              (rows?.row?.bank_name || rows?.row?.bank_branch || rows?.row?.contract_number) ?
                <MenuItem onClick={() => {
                  if (rows?.row?.bank_name || rows?.row?.bank_branch || rows?.row?.contract_number) {// order has contract
                    if (rows?.row?.invoices?.length > 0)//has invoices
                    {
                      // navigate('/admin/invoices', {
                      //   state: {
                      //     data: {
                      //       order: rows?.row
                      //     }
                      //   }
                      // })
                      window.open(window.location.origin + '/admin/invoices?order_id=' + rows?.row?.id)
                    }
                    else// doesn't have invoices 
                    {
                      generateOrderInvoices(rows?.row?.id)
                    }

                  }

                }} className="ActionIcons">
                  <ListItemIcon sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    margin: '0px 7px 0 0'
                  }}>
                    <ReceiptIcon style={{ fill: "#1E6A99" }} />

                  </ListItemIcon>
                  {rows?.row?.invoices?.length > 0 ? t('ORDERS.invoices') : t('ORDERS.generate_invoices')}
                </MenuItem>
                : null
              : null

          }
          {

            rolesRedux?.add_contract?.value || rolesRedux?.view_contract?.value ?
              <MenuItem onClick={() => {

                // navigate('/admin/order_contract', {
                //   state: {
                //     data: {
                //       order: rows?.row
                //     }
                //   }
                // })
                window.open(window.location.origin + '/admin/order_contract?order_id=' + rows?.row?.id)

              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <GavelIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('ORDERS.contract')}
              </MenuItem>
              : null
          }

          {
            (!rows?.row?.bank_name && !rows?.row?.bank_branch && !rows?.row?.contract_number) && rolesRedux?.update_order?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem>
              : null
          }


          {
            (!rows?.row?.bank_name && !rows?.row?.bank_branch && !rows?.row?.contract_number) && rows?.row?.order_status?.id == 1 && rolesRedux?.delete_order?.value ?
              <MenuItem onClick={() => { cancelOrder({ id: rows?.row?.id }) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.Cancel_order')}

              </MenuItem>
              : null
          }

          {

            rolesRedux?.add_payment?.value ?
              <MenuItem onClick={() => {
                setShowOrderInvoicesPayments(true)
                setselectedObject(rows?.row)
              }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <PaymentsIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('ORDERS.invoicePayments')}
              </MenuItem>
              : null
          }




          {
            showNoActionDropDownItems() ?
              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',

                }}
              >
                <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              </Box> : null
          }

        </DropDownGrid>
      ),
    },
  ], [rolesRedux])


  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };

  useEffect(() => {
    let abortController = new AbortController();
    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize,filterParams])

  useEffect(() => {
    let abortController = new AbortController();


    // loadDataOfUsers()
    setLoading(true)

    loadMasterData()

    // const p1 = new Promise((resolve, reject) => {
    //   loadDataOfPaymentType().then(() => resolve("loadDataOfPaymentType"));
    // });
    // const p2 = new Promise((resolve, reject) => {
    //   loadDataOfContractTemplate().then(() => resolve("loadDataOfContractTemplate"));
    // });
    const p3 = new Promise((resolve, reject) => {
      loadDataOfUnit().then(() => resolve("loadDataOfUnit"));
    });
    // const p4 = new Promise((resolve, reject) => {
    //   loadDataOfDiscount().then(() => resolve("loadDataOfDiscount"));
    // });
    // const p5 = new Promise((resolve, reject) => {
    //   loadDataOfCustomer().then(() => resolve("loadDataOfCustomer"));
    // });
    // const p6 = new Promise((resolve, reject) => {
    //   loadDataOfOrderStatuses().then(() => resolve("loadDataOfOrderStatuses"));
    // });
    const p7 = new Promise((resolve, reject) => {
      loadMasterData().then(() => resolve("loadMasterData"));
    });
    Promise.all([
      // p1, 
      // p2, 
      p3,
      // p4, 
      // p5, 
      // p6,
      p7
    ])
      .then((values) => {
        setLoading(false)
      })
      .catch((error) => {
        console.error(error.message);
      });

    return () => {
      abortController.abort();
    }
  }, [])

  const loadMasterData = async () => {
    let data = null;
    try {

      setLoading(true)
      let masters = [
        {
          type: 'payment_types',
        },
        {
          type: 'employees',
        },
        {
          type: 'customers',
        },
        {
          type: 'contract_templates',
        },
        {
          type: 'discounts',
        },
        {
          type: 'order_statuses'
        },
        {
          type: 'compounds'
        }

      ]


      data = await generalAPI.getMasters(
        {
          params: {
            masters: masters
          }
        }
      );
      if (data && data?.status) {

        setPaymentTypes(Array.isArray(data?.data?.payment_types) ? data?.data?.payment_types : [])
        setUsers(Array.isArray(data?.data?.employees) ? data?.data?.employees : [])
        setCustomers(Array.isArray(data?.data?.customers) ? data?.data?.customers : [])
        setContractTemplates(Array.isArray(data?.data?.contract_templates) ? data?.data?.contract_templates : [])
        setDiscounts(Array.isArray(data?.data?.discounts) ? data?.data?.discounts : [])
        setOrderStatuses(Array.isArray(data?.data?.order_statuses) ? data?.data?.order_statuses : [])
        setCompounds(Array.isArray(data?.data?.compounds) ? data?.data?.compounds : [])
      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false)
  }

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData = {
      ...filterParams,
      ...searchParams
    }
    const result = await OrderAPI.orders({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)

    const result = await EmployeeAPI.EmployeeList({
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setUsers(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log('1')
      if (willDelete) {
        console.log('2')

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            console.log('3')

            result = await OrderAPI.deleteorder({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const generateOrderInvoices = async (orderId) => {
    swal({
      title: '?هل انت متأكد',
      text: "سوف يتم انشاء فواتير لهذا الطب حسب طريقة الدفع وتفاصيلها المحددة بالطلب ولايمكنك التراجع عن هذه الخطوة!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {

        try {
          setLoading(true)

          let result = null;
          if (orderId) {

            result = await OrderAPI.generateOrderInvoices({
              data: {
                id: orderId,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.invoiceGeneratedSuccessfully')}`, {
              icon: "success",
            });
            loadData()
          } else {
            setLoading(false)
            
            if(result?.errorMessage)
            {
              toast.error(t(result?.errorMessage))
            }
            else
            {
              toast.error(t('NETWORKMESSAGE.operationFailed'))
            }
            
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setSelectedPaymentType(null)
    setSelectedContractTemplate(null)
    setSelectedUnit(null)
    setSelectedDiscounts(null)
    setSelectedCustomer(null)
    setOrderedDate(null)
    setCalculatedDiscountedPrice(null)
    setTotalPrice('')
    setDiscountPrice('')
    setSelectedOrderStatus(null)
    setFilterParams(null)

  }
  const searchFunction = () => {
    let data = {};
    if (totalPrice) {
      data = {
        ...data,
        totlal_price: totalPrice
      }
    }
    if (discountPrice) {
      data = {
        ...data,
        discount_price: discountPrice
      }
    }
    if (selectedCompound?.id) {
      data = {
        ...data,
        compound_id: selectedCompound?.id
      }
    }
    if (selectedBlock?.id) {
      data = {
        ...data,
        block_id: selectedBlock?.id
      }
    }
    if (selectedBuilding?.id) {
      data = {
        ...data,
        building_id: selectedBuilding?.id
      }
    }
    if (selectedFloor?.id) {
      data = {
        ...data,
        floor_id: selectedFloor?.id
      }
    }
    if (selectedUnit) {
      data = {
        ...data,
        unit_id: selectedUnit?.id
      }
    }
    if (orderedDate) {
      data = {
        ...data,
        ordered_date: helpers?.formatDate(orderedDate)
      }
    }
    if (selectedOrderStatus) {
      data = {
        ...data,
        order_status_id: selectedOrderStatus?.id
      }
    }
    if (selectedPaymentType) {
      data = {
        ...data,
        payment_type_id: selectedPaymentType?.id
      }
    }
    if (selectedCustomer) {
      data = {
        ...data,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      data = {
        ...data,
        customer_phone: customerPhone
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)

    // loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await OrderAPI.orders({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (totalPrice) {
      filteredData = {
        ...filteredData,
        total_price: totalPrice
      }
    }
    if (discountPrice) {
      filteredData = {
        ...filteredData,
        discount_price: discountPrice
      }
    }
    if (orderedDate) {
      filteredData = {
        ...filteredData,
        ordered_date: helpers?.formatDate(orderedDate)
      }
    }
    if (selectedUnit) {
      filteredData = {
        ...filteredData,
        unit_id: selectedUnit?.id
      }
    }
    if (selectedOrderStatus) {
      filteredData = {
        ...filteredData,
        order_status_id: selectedOrderStatus?.id
      }
    }
    if (selectedPaymentType) {
      filteredData = {
        ...filteredData,
        payment_type_id: selectedPaymentType?.id
      }
    }
    if (selectedCustomer) {
      filteredData = {
        ...filteredData,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      filteredData = {
        ...filteredData,
        customer_phone: customerPhone
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }
    if (selectedCompound) {
      filteredData = {
        ...filteredData,
        compound_id: selectedCompound?.id
      }
    }
    if (selectedBlock) {
      filteredData = {
        ...filteredData,
        block_id: selectedBlock?.id
      }
    }
    if (selectedBuilding) {
      filteredData = {
        ...filteredData,
        building_id: selectedBuilding?.id
      }
    }
    if (selectedFloor) {
      filteredData = {
        ...filteredData,
        floor_id: selectedFloor?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }
    console.log('asdasdasasd')
    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('ORDERS.id')}`]: itm?.id,
        [`${t('ORDERS.unit_name')}`]: itm?.unit?.name,
        [`${t('ORDERS.customer')}`]: itm?.customer?.user?.full_name,
        [`${t('ORDERS.total_price')}`]: itm?.total_price,
        [`${t('ORDERS.discounted_price')}`]: itm?.discounted_price,
        [`${t('ORDERS.order_status')}`]: itm?.order_status?.name,
        [`${t('ORDERS.payment_type')}`]: itm?.payment_type?.name,
        [`${t('ORDERS.iqd_usd')}`]: itm?.iqd_usd ? 'USD' : 'IQD',
        [`${t('ORDERS.ordered_date')}`]: itm?.ordered_date ? helpers.formatDate(itm?.ordered_date) : '',
        [`${t('ORDERS.contract_template')}`]: itm?.contract_template?.name,
        [`${t('ORDERS.bank_name')}`]: itm?.bank_name,
        [`${t('ORDERS.bank_branch')}`]: itm?.bank_branch,
        [`${t('ORDERS.contract_number')}`]: itm?.contract_number,
        [`${t('ORDERS.contract_printed_date')}`]: itm?.contract_printed_date,
        [`${t('ORDERS.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'ORDERS')

  }

  const cancelOrder = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {

        try {
          dispatch(setGlobalLoading(true))
          let result = null;
          if (obj && obj?.id) {
            result = await orderAPI.cancelOrder({
              data: {
                id: obj.id,
              },
              params: {
                order_status_id: 3
              }
            });
          }

          if (result?.status) {
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.canceledSuccess')}`, {
              icon: "success",
            });
            loadData()
          } else {
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          dispatch(setGlobalLoading(false))

        } catch (error) {
          toast.error(t(error?.message))
          dispatch(setGlobalLoading(false))
        }
      } else {
        // alert('canceled')
        dispatch(setGlobalLoading(true))

      }
    })
  }

  // const loadDataOfCustomer = async (searchParams) => {
  //   setLoading(true)

  //   const result = await customerAPI.customersList({
  //     params: {
  //       ...searchParams
  //     },
  //     signal: controller?.signal
  //   })

  //   if (result.status) {
  //     setCustomers(result?.data)
  //   }
  //   else {
  //     if (!result?.data?.error) {
  //       toast.error(t('NETWORKMESSAGE.messageError'))
  //       return
  //     }
  //   }

  // }
  const loadDataOfPaymentType = async (searchParams) => {
    setLoading(true)

    const result = await PaymentTypeAPI.PaymentTypeList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    if (result.status) {
      setPaymentTypes(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const loadDataOfContractTemplate = async (searchParams) => {
    setLoading(true)

    const result = await ContractTemplateAPI.ContractTemplateList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    if (result.status) {
      setContractTemplates(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }

  const loadDataOfDiscount = async (searchParams) => {
    setLoading(true)

    const result = await discountAPI.discountsList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    if (result.status) {
      setDiscounts(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }

  const loadDataOfOrderStatuses = async (searchParams) => {
    setLoading(true)

    const result = await OrderStatusAPI.OrderStatusList({
      params: {
        ...searchParams
      },
      signal: controller?.signal
    })

    if (result.status) {
      setOrderStatuses(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }




  // useEffect(() => {

  //   loadCompounds()

  //   return () => {

  //   }
  // }, [])

  useEffect(() => {
    if (selectedCompound?.id)
      loadBlocks({})

    return () => {

    }
  }, [selectedCompound])

  useEffect(() => {
    if (selectedBlock?.id)
      loadBuildings({})

    return () => {

    }
  }, [selectedBlock])

  useEffect(() => {
    if (selectedBuilding?.id)
      loadFloors({})

    return () => {

    }
  }, [selectedBuilding])

  useEffect(() => {

    loadDataOfUnit({})

    return () => {

    }
  }, [selectedCompound, selectedBlock, selectedBuilding, selectedFloor])

  const handleSearch = debounce((value, name)=>{
    if (name == "blocks") loadBlocks(value);
    else if (name == "buildings") loadBuildings(value);
    else if (name == "floors") loadFloors(value);
    else if (name == "customers") loadCustomers(value);
    else if (name == "unit") loadDataOfUnit(value);
  }, 500);

  const loadCompounds = async () => {
    setLoading(true)

    try {
      const result = await compoundAPI.compoundsList({
        params: {

        },
        signal: controller?.signal
      })
      if (result.status) {
        let list = result?.data;

        setCompounds(list)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBlocks = async (searchParams) => {
    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([])
      setBuildings([])
      setBlocks([])
      return
    }
    setLoading(true)
    setBlocks([])


    try {
      const result = await blockAPI.blocks({
        params: {
          page_size: 10,
          page: 0,
          compound_id: selectedCompound?.id,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal
      })
      if (result.status) {
        setBlocks(result?.data?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadBuildings = async (searchParams) => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([])
      setFloors([])
      return
    }
    setLoading(true)
    setBuildings([])
    try {
      const result = await buildingAPI.buildings({
        params: {
          page_size: 10,
          page: 0,
          block_id: selectedBlock?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal
      })
      if (result.status) {
        setBuildings(result?.data?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadFloors = async (searchParams) => {
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([])
      return
    }
    setLoading(true)
    setFloors([])
    try {
      const result = await floorAPI.floors({
        params: {
          page_size: 10,
          page: 0,
          building_id: selectedBuilding?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal
      })
      if (result.status) {
        setFloors(result?.data?.data)
      }
    } catch (err) {
      console.log(err?.message)
      toast.error(err?.message)
    }
    setLoading(false)
  }
  const loadCustomers = async (searchParams) => {
    setLoading(true);
    setCustomers([]);
    try {
      const result = await customerAPI.customers({
        params: {
          page_size: 10,
          page: 0,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setCustomers(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };

  const loadDataOfUnit = async (searchParams) => {
    setLoading(true)
    setUnits([]);
    const result = await unitAPI.units({
      params: {
        page_size: 10,
        page: 0,
        name: searchParams?.search_text ? searchParams?.search_text : "",
        // ...searchParams,
        // ...(selectedCompound?.id ? {
        //   compound_id: selectedCompound?.id
        // } : {}),
        // ...(selectedBlock?.id ? {
        //   block_id: selectedBlock?.id
        // } : {}),
        // ...(selectedBuilding?.id ? {
        //   building_id: selectedBuilding?.id
        // } : {}),
        // ...(selectedFloor?.id ? {
        //   floor_id: selectedFloor?.id
        // } : {})
      },
      signal: controller?.signal
    })
    setLoading(false)
    if (result.status) {
      setUnits(result?.data?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }

  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      {addForm ?
        <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData}
          compound={selectedCompound}
          block={selectedBlock}
          building={selectedBuilding}
          floor={selectedFloor}
        /> : null}
      {showOrderInvoicesPayments ?
        <OrderInvoicePayments
          object={selectedObject}
          addForm={showOrderInvoicesPayments} setAddForm={setShowOrderInvoicesPayments}
          loadData={loadData}
        /> : null}

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('ORDERS.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}

          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_order?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_order?.value}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}

          totalPrice={totalPrice}
          setTotalPrice={setTotalPrice}
          discountPrice={discountPrice}
          setDiscountPrice={setDiscountPrice}

          orderedDate={orderedDate}
          setOrderedDate={setOrderedDate}

          floors={floors}
          selectedFloor={selectedFloor}
          setSelectedFloor={setSelectedFloor}

          buildings={buildings}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}

          blocks={blocks}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}

          compounds={compounds}
          selectedCompound={selectedCompound}
          setSelectedCompound={setSelectedCompound}

          calculatedDiscountedPrice={calculatedDiscountedPrice}
          setCalculatedDiscountedPrice={setCalculatedDiscountedPrice}
          paymentTypes={paymentTypes}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}

          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          customerReadOnly={customerReadOnly}

          contractTemplates={contractTemplates}
          selectedContractTemplate={selectedContractTemplate}
          setSelectedContractTemplate={setSelectedContractTemplate}

          units={units}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          unitReadOnly={unitReadOnly}

          orderStatuses={orderStatuses}
          selectedOrderStatus={selectedOrderStatus}
          setSelectedOrderStatus={setSelectedOrderStatus}

          discounts={discounts}
          selectedDiscounts={selectedDiscounts}
          setSelectedDiscounts={setSelectedDiscounts}

          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}

          handleSearch={handleSearch}
        />}
      />

    </Box>
  )
}
