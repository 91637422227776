import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, FormControlLabel, Checkbox } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as FaIcons from 'react-icons/fa'
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import CommunicationsTab from './CommunicationsTab';
import CallAPI from '../../../Global/Network/Calls/index'
import customerAPI from '../../../Global/Network/Customer';
import CallsTypeAPI from '../../../Global/Network/CallsType/index';
import CallStatusAPI from '../../../Global/Network/CallStatus/index'
import call_reasonsAPI from '../../../Global/Network/CallReasons/index'
import generalAPI from '../../../Global/Network/General';
import Addresses from '../../Components/Addresses';
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import SearchPhone from '../../Components/SearchPhone';
import { debounce } from 'debounce';

const urlParams = new URLSearchParams(window.location.search);
const customerFromUrl = urlParams.get('CustomerId');
const callIdFromUrl = urlParams.get('id');

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      {/* <SearchInput
       value={props?.objectName}
       setValue={props?.setobjectName}
       title={t('WORKTYPE.work_name_search')}
  />*/}
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('WORKTYPE.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('WORKTYPE.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
       
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.customers ? props.customers : []}
        clearOnBlur={false}
        getOptionLabel={(option) => (option && option?.user?.full_name) || ""}
      
        value={props?.selectedCustomer}
        onChange={(e, v, r) => {
          props.setSelectedCustomer(v ? v : '');
          props?.setSelectCustomerFormState(null)
        }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.CUSTOMERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "customers");
            }} />
        )}
      />
      <SearchPhone
        value={props?.customerPhone}
        setValue={props?.setCustomerPhone}
        title={t('GLOBAL.customerPohone')}
       />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.callTypes ? props.callTypes : []}
        getOptionLabel={(option) => option?.name && option?.name || ""}
        
        value={props?.selectedCallType}
        onChange={(e, v, r) => { props.setSelectedCallType(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.types')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.callstateus ? props.callstateus : []}
        getOptionLabel={(option) => option?.name && option?.name || ""}
        
        value={props?.selectedCallStatus}
        onChange={(e, v, r) => { props.setSelectedCallStatus(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.STATUS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.destinations ? props.destinations : []}
        getOptionLabel={(option) => option?.name && option?.name || ""}
        
        value={props?.selectedDestination}
        onChange={(e, v, r) => { props.setSelectedDestination(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.destinations')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.callReasons ? props.callReasons : []}
        getOptionLabel={(option) => option?.name && option?.name || ""}
        
        value={props?.selectedCallReasons}
        onChange={(e, v, r) => { props.setSelectedCallReasons(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('CALLS.reasons')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }} />
        )}
      />

      <Box sx={{
        backgroundColor: 'lightgray', width: '97%', display: 'flex', alignItems: 'center', justifyContent: 'start', ml: 1.5, mt: 1, mb: 1, width: '95%',
        '& .MuiTypography-root': {
          fontFamily: 'Cairo'
        }
      }} className="px-2 rounded" >
        <FormControlLabel control={<Checkbox checked={props?.neededFollowUp}
          onChange={(e) => {
            props.setNeededFollowUp(e?.target.checked)
          }}
        />} sx={{}} label={t('CALLS.neededFollowUp')}
        />
      </Box>
    </Box>
  )
}

export const RenderContentOfContact = (props) => {
  return (
    // !props?.loading?
    <CommunicationsTab {...props} />
    //  :
    //  <Preloading/>
  )
}

export default function Calls() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null)
  const [customerFromState, setSelectCustomerFormState] = useState(null)
  const [callTypes, setCallTypes] = useState([]);
  const [selectedCallType, setSelectedCallType] = useState(null)
  const [callstateus, setCallStateus] = useState([]);
  const [selectedCallStatus, setSelectedCallStatus] = useState(null)
  const [callReasons, setCallReasons] = useState([]);
  const [selectedCallReasons, setSelectedCallReasons] = useState(null)
  const [neededFollowUp, setNeededFollowUp] = useState(null)
  const [selectedDestination, setSelectedDestination] = useState(null)
  const [destinations, setDestinations] = useState([])
  const [customerPhone,setCustomerPhone]=useState(null)
  const [filterParams,setFilterParams]=useState({})
  const rolesRedux = useSelector(state => state.rolesData.roles)
  

  const { state } = useLocation();

  const [showAllCustomersCalls, setShowAllCustomersCalls] = useState(null)


  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const columns = useMemo(() => [
    { headerName: t('CALLTYPE.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    { headerName: t('CALLTYPE.name'), field: 'name', flex: 2, minWidth: 250, maxWidth: 450, hide: false },
    {
      headerName: t('CALLTYPE.created_at'), field: 'created_at', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatDate(rows?.row?.created_at) : '0000-00-00'}</span>
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatTime(rows?.row?.created_at) : '00:00:00'}</span>

        </Box>
      )
    },
    {
      headerName: t('CALLTYPE.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            rolesRedux?.update_calls?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null
          }
          {
            rolesRedux?.delete_calls?.value ?
              <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.action_delete')}

              </MenuItem> : null
          }
          {
            rolesRedux?.update_calls?.value == false &&  rolesRedux?.delete_calls?.value == false ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], [rolesRedux])
  useEffect(() => {
    let abortController = new AbortController();
    if (customerFromState) {
      setSelectedCustomer({
        id: customerFromState?.id,
        name: customerFromState?.user?.full_name
      })
    }
    loadData({
      customer_id: showAllCustomersCalls ? showAllCustomersCalls : customerFromState?.id,
    })
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize, customerFromState, showAllCustomersCalls,filterParams])

  useEffect(() => {
    if (customerFromUrl) {
      setShowAllCustomersCalls(customerFromUrl)
    }
  }, [customerFromUrl])
  useEffect(() => {

    if (state?.data && state?.data?.customer) {
      setSelectCustomerFormState(state?.data?.customer)
    }
  }, [state])

  useEffect(() => {
    let abortController = new AbortController();
    loadMasterData()
    loadCustomers({});



    return () => {
      abortController.abort();
    }
  }, [])
  const loadMasterData = async () => {
    let data = null;
    try {

      setLoading(true)
      let masters = [
        // {
        //   type: 'customers',
        // },
        {
          type: 'call_types',
        },
        {
          type: 'call_reasons',
        },
        {
          type: 'call_statuses',
        },
        {
          type: 'call_destinations',
        },
        {
          type: 'employees'
        },


      ]


      data = await generalAPI.getMasters(
        {
          params: {
            masters: masters
          }
        }
      );
      if (data && data?.status) {
        let usersList = [{
          id:-1,
          user:{
            id:-1,
            full_name:t('CALLS.no_user_selected')
          }
        }]
        if(Array.isArray(data?.data?.employees))
        {
          usersList= [...usersList,...data?.data?.employees]
        }
        setUsers(usersList)

        // if (Array.isArray(data?.data?.customers)) {
        //   let arr = []
        //   data?.data?.customers?.map((itm) => {
        //     if (customerFromUrl == itm?.id) {
        //       setSelectedCustomer({
        //         id: itm?.id,
        //         name: itm?.user?.full_name,
        //       })
        //     }
        //     arr.push({
        //       id: itm?.id,
        //       name: itm?.user?.full_name,
        //     })
        //   })
        //   setCustomers(arr)
        // }

        setCallTypes(Array.isArray(data?.data?.call_types) ? data?.data?.call_types : [])
        setCallStateus(Array.isArray(data?.data?.call_statuses) ? data?.data?.call_statuses : [])

        setCallReasons(Array.isArray(data?.data?.call_reasons) ? data?.data?.call_reasons : [])
        setDestinations(Array.isArray(data?.data?.call_destinations) ? data?.data?.call_destinations : [])




      } else {
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false)
  }
  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams
     }
    const result = await CallAPI.Calls({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...(callIdFromUrl? {
          id: callIdFromUrl
        }:{}),
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }

  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {

      if (willDelete) {


        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {


            result = await CallAPI.deleteCall({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams(null)

  }
  const searchFunction = () => {
    let data = {};
    if (selectedCustomer) {
      data = {
        ...data,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      data = {
        ...data,
        customer_phone: customerPhone
      }
    }
    if (selectedCallType) {
      data = {
        ...data,
        call_type_id: selectedCallType?.id
      }
    }
    if (selectedCallStatus) {
      data = {
        ...data,
        call_status_id: selectedCallStatus?.id
      }
    }
    if (selectedCallReasons) {
      data = {
        ...data,
        call_reason_id: selectedCallReasons?.id
      }
    }
    if (selectedDestination) {
      data = {
        ...data,
        call_destination_id: selectedDestination?.id
      }
    }
    if (neededFollowUp) {
      data = {
        ...data,
        needs_follow_up: neededFollowUp ? 1 : 0
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
   
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)
    // loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await CallAPI.Calls({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (selectedCustomer) {
      filteredData = {
        ...filteredData,
        customer_id: selectedCustomer?.id
      }
    }
    if (customerPhone) {
      filteredData = {
        ...filteredData,
        customer_phone: customerPhone
      }
    }
    if (selectedCallType) {
      filteredData = {
        ...filteredData,
        call_type_id: selectedCallType?.id
      }
    }
    if (selectedCallStatus) {
      filteredData = {
        ...filteredData,
        call_status_id: selectedCallStatus?.id
      }
    }
    if (selectedCallReasons) {
      filteredData = {
        ...filteredData,
        call_reason_id: selectedCallReasons?.id
      }
    }
    if (selectedDestination) {
      filteredData = {
        ...filteredData,
        call_destination_id: selectedDestination?.id
      }
    }
    if (neededFollowUp || neededFollowUp == false) {
      filteredData = {
        ...filteredData,
        needs_follow_up: neededFollowUp ? 1 : 0
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }

    
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy.user?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }

    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      
      arr.push({
        [`${t('CALLS.id')}`]: itm?.id,
        [`${t('CALLS.destination')}`]: itm?.call_destination?.name,
        [`${t('CALLS.callReason')}`]: itm?.call_reason?.name,
        [`${t('CALLS.other_call_reasons')}`]: itm?.call_reason_other,
        [`${t('CALLS.callStatus')}`]: itm?.call_status?.name,
        [`${t('CALLS.callType')}`]: itm?.call_type?.name,
        [`${t('CALLS.USERS')}`]: itm?.created_by?.full_name,
        [`${t('CALLS.company')}`]: itm?.created_by?.company?.name,
        [`${t('CALLS.customers')}`]: itm?.customer?.user?.full_name,
        [`${t('CALLS.date_time')}`]: itm?.date_time,
        [`${t('CALLS.Importance')}`]: itm?.importance_degree,
        [`${t('CALLS.neededFollowUps')}`]: itm?.needs_follow_up,
        [`${t('CALLS.neededFollowupDate')}`]: itm?.needs_follow_up_date,
        [`${t('CALLS.Reply')}`]: itm?.reply,

        [`${t('CALLS.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'CALLS')

  }

  const handleSearch = debounce((value, name)=>{
    if (name == "customers") loadCustomers(value);
  }, 500);

  const loadCustomers = async (searchParams) => {
    setLoading(true);
    setCustomers([])
    try {
      const result = await customerAPI.customers({
        params: {
          page_size: 10,
          page: 0,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setCustomers(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };

  const submitNeedFollowUp = async (itm) => {
    let result = null;
    try {

      if (itm && itm?.id) {
        setLoading(true)
        let data = {
          needs_follow_up: 0,
          id: itm?.id,
          _method: 'put'
        }
        result = await CallAPI.DisActiveFollowUp({
          data: data
        });
      }

      if (result?.status) {
        setLoading(false)
        toast.success(t('NETWORKMESSAGE.UpdateCustomerMessageSuccess'))
        loadData()
      } else {
        setLoading(false)
        toast.error(t('NETWORKMESSAGE.messageError'))
      }
    } catch (err) {
      console.log(err?.message)
    }
  }

  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: 'flex',
        flexDirection: 'column',
        height: '100%',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'start',
        overflowX: 'hidden',

      }}
      className=" pt-3 mt-1"

    >
      {addForm ?
        <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData} />
        : null
      }


      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('CALLS.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        notHaveGrid={true}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_calls.value}
        hasImport={false}
        hasExport={rolesRedux?.export_calls?.value}
        hasMap={false}
        hasTetelgram={false}
        renderContentOfContact={
          <RenderContentOfContact
            rows={rows}
            columns={columns}
            setColumns={null}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={rowsTotal}
            checkScreenSize={screenwidth}
            deleteFunction={deleteFunction}
            updateFunction={updateFunction}
            pageCount={pageCount}
            submitNeedFollowUp={submitNeedFollowUp}
          />}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          callTypes={callTypes}
          selectedCallType={selectedCallType}
          setSelectedCallType={setSelectedCallType}
          callReasons={callReasons}
          selectedCallReasons={selectedCallReasons}
          setSelectedCallReasons={setSelectedCallReasons}
          callstateus={callstateus}
          selectedCallStatus={selectedCallStatus}
          setSelectedCallStatus={setSelectedCallStatus}
          neededFollowUp={neededFollowUp}
          setNeededFollowUp={setNeededFollowUp}
          users={users}
          setSelectCustomerFormState={setSelectCustomerFormState}
          destinations={destinations}
          selectedDestination={selectedDestination}
          setSelectedDestination={setSelectedDestination}
          customerPhone={customerPhone}
          setCustomerPhone={setCustomerPhone}
          handleSearch={handleSearch}
        />}
      />

    </Box>
  )
}
