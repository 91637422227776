import { Box,TextField,Button, Autocomplete, FormControlLabel, Checkbox} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from '../../Components/PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../../Global/Errors/CallReasons/CallReasons'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import {toast} from 'react-toastify'
import call_reasonsAPI from '../../../Global/Network/CallReasons/index'
import CallDestinationsAPI from '../../../Global/Network/CallDestinations'
import * as RiIcons from 'react-icons/ri'

const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const handleChange=(e)=>{
       props.setName(e.target.value)
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))
    }
    const clearInput=()=>{
       props.setName('')
       setErrorsObject(restCustomeObject(ErrorsObject,'name'))

    }
    const handleInputChangeSelect=(value,errName)=>{
        props.setSelectCallDestinations(value)
        setErrorsObject(restCustomeObject(ErrorsObject,'call_destination_id'))

    }
    const clearSelectUser=(name)=>{
        props.setSelectCallDestinations(null)
       setErrorsObject(restCustomeObject(ErrorsObject,'call_destination_id'))
    }

    return(
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-6 m-auto">
                    <ContainerOfInputFields
                            mainTheme={maintheme}
                            customeWidth={"100%"}
                            isForm={true}

                    >
                        <Box
                            sx={{
                            position: "relative",
                            // margin: "5px 0",
                            width: "100%",
                            backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                            
                            variant="filled"
                            label={t("WORKTYPE.add_name")}
                            value={props?.name}
                            onChange={(e)=>handleChange(e)}
                            type={'text'}
                            focused
                            className={`${ErrorsObject?.name?.error?'errors':''}`}
                            />
                            {props?.name ? (
                            <CloseIcon
                            className="closeIcon"
                            onClick={()=>clearInput()}
                            />
                            ) : null}
                        </Box>
                        {
                        ErrorsObject?.name?.error&&ErrorsObject?.name?.message?.length?
                        <Box sx={{
                            height: 'fit-content',
                            padding:'5px',
                            display: 'flex',
                            flexDirection:'column',
                            flexWrap:'wrap',
                            width:'95%',
                        }}>
                            {
                            ErrorsObject?.name?.message&&ErrorsObject?.name?.message?.length>0?ErrorsObject?.name?.message?.map((messg)=>(
                            <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                            )):null
                            }
                        </Box>:null}

                        <Box
                        sx={{
                            backgroundColor:'#fff',
                            color:'#000',
                            // borderRadius:'10px',
                            height:'55px',
                            display:'flex',
                            alignItems:'center',
                            justifyContent:'start',
                            width:'100% !important',
                            boxShadow:'0px 6px 20px 3px #00000014 !important',
                            margin:'auto',
                            marginTop:'18px',
                            paddingLeft:'10px',
                            "& .MuiTypography-root":{
                                fontFamily:'Cairo-Medium'
                            }

                        }}
                    >
                       <FormControlLabel control={<Checkbox checked={props?.isOther} onChange={(e)=>{
                           props.setIsOther(e.target.checked)
                       }} />} sx={{fontFamily:'Cairo-Medium'}} label={t('CALLREASONS.other')} />

                   </Box>
                    </ContainerOfInputFields>
                   
                </Box>
                <Box className="col-12 col-sm-12 col-md-6">
                   <ContainerOfSelectField
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.call_destination_id?.error}
                   >
                     <Box
                        sx={{
                        position: "relative",
                        // margin: "10px 0",
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                     >
                        <Autocomplete
                           // disablePortal
                           id="combo-box-demo"
                           options={props?.CallDestinations?props?.CallDestinations:[]}
                           getOptionLabel={(option)=>option?.name||''}
                           value={props?.selectedCallDestination}
                           onChange={(e,newValue)=>handleInputChangeSelect(newValue,'call_destination_id')}
                           popupIcon={<RiIcons.RiArrowDropDownLine/>}
                           clearIcon={<CloseIcon  sx={{fontSize:'20px',color:maintheme?.iconColor}} onClick={()=>clearSelectUser('call_destination_id')}/>}
                           renderInput={(params) => <TextField 
                              {...params} label={t('CALLDESTINATIONS.governorate')} 
                              className="mt-3 errors" variant="filled"
                               />}
                           className={`${ErrorsObject?.call_destination_id?.error}`}     
                        />
                     </Box>
                     {
                     ErrorsObject?.call_destination_id?.error&&ErrorsObject?.call_destination_id?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.call_destination_id?.message&&ErrorsObject?.call_destination_id?.message?.length>0?ErrorsObject?.call_destination_id?.message?.map((messg)=>(
                        <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                   </ContainerOfSelectField>
                </Box>
            </Box>
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)

    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                            props.submit()
                      }} 
                      >
                      {t('GLOBAL.Save')}
                  </Button>
          </Box>
      </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [selectedCallDestination,setSelectCallDestinations]=useState(null)
    const [CallDestinations,setCallDestinations]=useState([])
    const [isOther,setIsOther]=useState(0)
   
    useEffect(()=>{
        if(props?.object&&props?.object?.id){
            let dumData=resetErrors(ErrorsObject)
           setName(props?.object?.name)
           setSelectCallDestinations(props?.object?.call_destination)
           setIsOther(props?.object?.needs_text==1?true:false)

        }else{
            let dumData=resetErrors(ErrorsObject)
            clearForm()
        }
    },[props?.object])

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
            name:name,
            call_destination_id:selectedCallDestination?.id,
            needs_text:isOther?1:0
        }
        let result=null;
        if(props?.object&&props?.object?.id){
            data={
                ...data,
                id:props?.object?.id,
                call_destination_id:selectedCallDestination?.id,
                _method:'put'
            }
             result=await call_reasonsAPI.updatecall_reasons({
            data:data
            });
        }else{
            result=await call_reasonsAPI.addcall_reasons({
                data:data
            });
        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.loadData()
          props.setAddForm(false)
        }else{
          
           if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }
    const clearForm=()=>{
        setName('')
    }
    useEffect(()=>{
        let abortController = new AbortController();
        getDataoFCalldestination()
        if(!props?.object)
          clearForm()
        return () => {
        abortController.abort();
        }
    },[props?.addForm,props?.object])

    const getDataoFCalldestination=async()=>{
      let data=null;
      try{
        data=await CallDestinationsAPI.CallDestinationsList();
        if(data&&data?.status){
            console.log('datofCalldestinations',data?.data)
            setCallDestinations(data?.data)
        }
      }catch(err){
        console.log('errr',err?.message)
      }
    }

  return (
       <PopupForm 
        open={props.addForm} 
        setOpen={props.setAddForm}
        title={props?.object?t('CALLREASONS.updateTitle'):t('CALLREASONS.addTitle')}
        content={<RenderContent name={name} setName={setName}
        CallDestinations={CallDestinations}
        setCallDestinations={setCallDestinations}
        setSelectCallDestinations={setSelectCallDestinations}
        selectedCallDestination={selectedCallDestination}
        open={props.addForm} 
        setOpen={props.setAddForm}
        isOther={isOther}
        setIsOther={setIsOther}
        />}
        footer={<RenderFooter
            open={props.addForm} 
            setOpen={props.setAddForm}
            submit={submit}
        />}
       />
  )
}
