import { Stage, Layer, Circle, Image } from 'react-konva';
import React, { useMemo, useRef, useState, useEffect } from "react";
import useImage from 'use-image';
import { Box, Tooltip } from '@mui/material';
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import GestureIcon from '@mui/icons-material/Gesture';
import LayersClearIcon from '@mui/icons-material/LayersClear';
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate';
import FlipCameraAndroidIcon from '@mui/icons-material/FlipCameraAndroid';
import RemoveIcon from '@mui/icons-material/Remove';
import PolygonAnnotation from "../components/PolygonAnnotation";
import { useSelector, useDispatch } from 'react-redux';
import { setPolygonsRedux } from '../../../reduxStore/SettingsReducer'
import PopupForm from '../../Components/PopupForm';
import FilterImage from '../components/FilterImage';
import FilterImageFooter from '../components/FilterImageFooter';
import helpers from '../../../assets/js/helper';
import SaveIcon from '@mui/icons-material/Save';
import LinkImagePoly from '../components/LinkImagePoly';
import LinkImageFooter from '../components/LinkImagePolyFooter';
import PolygonToolTip from '../components/PolygonToolTip';
import { toast } from 'react-toastify';
import Preloading from '../../../Global/Preload/Preloading';
import ImagePolygonsAPI from '../../../Global/Network/ImagePolygon';
import * as MdIcons from 'react-icons/md'
import swal from 'sweetalert';
import { useTranslation } from 'react-i18next';
import { useLocation, useNavigate } from 'react-router-dom';
import TextDecreaseIcon from '@mui/icons-material/TextDecrease';
import TextIncreaseIcon from '@mui/icons-material/TextIncrease';
import EditIcon from '@mui/icons-material/Edit';
const scaleBy = 1.04;

const BuildingImage = (props) => {
  const [image] = useImage(props?.imageUrl);
  return <Image image={image} />;
};

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

function isTouchEnabled() {
  return ('ontouchstart' in window) ||
    (navigator.maxTouchPoints > 0) ||
    (navigator.msMaxTouchPoints > 0);
}

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: 'center',
  marginTop: '-90px',
  borderRadius: '10px',
  backgroundColor: "#fff",
  width: '100%',
  margin: '0 auto',
  overflow: 'hidden'
};
const ImageMapDraw = () => {
  const [t] = useTranslation("common");
  const [imageType, setImageType] = useState(null)
  const [runSubmitSelectImage, setRunSubmitSelectImage] = useState(false)
  const navigate = useNavigate()
  const { state } = useLocation();
  const dispatch = useDispatch()
  const controller = new AbortController();
  const [loading, setLoading] = useState(false)
  const isopensidemenu = useSelector(state => state.settingsData.isopensidemenu)
  const drawerwidth = useSelector(state => state.settingsData.drawerwidth)
  const [points, setPoints] = useState([]);
  const [flattenedPoints, setFlattenedPoints] = useState();
  const [polygons, setPolygons] = useState([]);
  const [isDrawingModeOn, setIsDrawingModeOn] = useState(false)
  const [hidePolygons, setHidePolygons] = useState(false)
  const [position, setPosition] = useState([0, 0]);
  const [isPolyComplete, setPolyComplete] = useState(false);
  const [isMouseOverPoint, setMouseOverPoint] = useState(false);
  const [selectedEntity, setSelectedEntity] = useState({
    sourceEntity: {
      type: '',
      entity: {
        id: '',
        label: ''
      },
      label: ''
    },
    linkedToEntity: {
      type: '',
      entity: {
        id: '',
        label: ''
      },
      label: ''
    }

  })
  const [nameOfCurrentCriteria, setNameOfCurrentCriteria] = useState('');
  const [nameOfCurrentCriteriaSwitch, setNameOfCurrentCriteriaSwitch] = useState('');

  const [selectedBuilding, setSelectedBuilding] = useState(null)
  const [selectedCompound, setSelectedCompound] = useState(null)
  const [chosenBuilding, setChosenBuilding] = useState(null)
  const [selectedBlock, setSelectedBlock] = useState(null)
  const [selectedFloor, setSelectedFloor] = useState([])
  const [selectedUnitType, setSelectedUnitType] = useState([])
  const [selectedUnitLocation, setSelectedUnitLocation] = useState([])
  // const polygons = useSelector(state => state.settingsData.polygons)
  const [polygonToolTipObject, setPolygonToolTipObject] = useState({
    title: "",
    icon: "",
    availableUnits: 0,
    showAvailableUnits: true,
    show: false,
    top: "",
    left: ""
  })
  const [openFilterImage, setOpenFilterImage] = useState(false)
  const [openImagePolygon, setOpenImagePolygon] = useState(false)
  const [polygonToEdit, setPolygonToEdit] = useState(null)
  const [selectedImages, setSelectedImages] = useState([])
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [selectedImagePolyLink, setSelectedImagePolyLink] = useState(null)
  const [imagePath, setImagePath] = useState(null)
  const [stageZoomChanged, setStageZoomChanged] = useState(false)
  const zoomInterval = useRef(null)
  const stageRef = useRef(null);
  let lastCenter = null;
  let lastDist = 0;

  function zoomStage(event) {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      setStageZoomChanged(!stageZoomChanged)
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      }
      stage.position(newPos);
      stage.batchDraw();
    }
  }
  const zoomStageWithButton = (zoomIn) => {
    if (stageRef.current !== null) {
      var pointerX = window.innerWidth / 2;
      var pointerY = window.innerHeight / 2;
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      // const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = zoomIn ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      setStageZoomChanged(!stageZoomChanged)
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      }
      stage.position(newPos);
      stage.batchDraw();
    }
  };

  function handleTouch(e) {
    e.evt.preventDefault();
    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    const stage = stageRef.current;
    if (stage !== null) {
      if (touch1 && touch2) {
        if (stage.isDragging()) {
          stage.stopDrag();
        }

        var p1 = {
          x: touch1.clientX,
          y: touch1.clientY
        };
        var p2 = {
          x: touch2.clientX,
          y: touch2.clientY
        };

        if (!lastCenter) {
          lastCenter = getCenter(p1, p2);
          return;
        }
        var newCenter = getCenter(p1, p2);

        var dist = getDistance(p1, p2);

        if (!lastDist) {
          lastDist = dist;
        }

        // local coordinates of center point
        var pointTo = {
          x: (newCenter.x - stage.x()) / stage.scaleX(),
          y: (newCenter.y - stage.y()) / stage.scaleX()
        };

        var scale = stage.scaleX() * (dist / lastDist);

        stage.scaleX(scale);
        stage.scaleY(scale);
        setStageZoomChanged(!stageZoomChanged)

        // calculate new position of the stage
        var dx = newCenter.x - lastCenter.x;
        var dy = newCenter.y - lastCenter.y;

        var newPos = {
          x: newCenter.x - pointTo.x * scale + dx,
          y: newCenter.y - pointTo.y * scale + dy
        };

        stage.position(newPos);
        stage.batchDraw();

        lastDist = dist;
        lastCenter = newCenter;
      }
    }
  }

  function handleTouchEnd() {
    lastCenter = null;
    lastDist = 0;
  }

  //////////////////////// mouse move related functions /////////////////////
  const handleMouseMove = (e) => {

    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);

    setPosition(mousePos);
  };
  const getMousePos = (stage) => {
    let pos = getRelativePointerPosition(stage)
    return [pos?.x, pos?.y];
  };
  function getRelativePointerPosition(node) {
    // the function will return pointer position relative to the passed node
    var transform = node.getAbsoluteTransform().copy();
    // to detect relative position we need to invert transform
    transform.invert();

    // get pointer (say mouse or touch) position
    var pos = node.getStage().getPointerPosition();

    // now we find relative point
    return transform.point(pos);
  }
  /////////////////////// end mouse move related functions //////////////////

  /////////////////////// mouse down related functions //////////////////////
  const shiftPlusLeftClickHandler = (e) => {
    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (isEditModeOn && polygonToEdit) {
      console.log("polygon_before", polygonToEdit);

      setPolygons(polygons?.map(poly => {
        if (poly?.id == polygonToEdit?.id) {
          let polygonEdited =
          {
            ...poly,
            points: [...poly?.points, mousePos],
            flattenedPoints: poly?.points
              .concat(isPolyComplete ? [] : position)
              .reduce((a, b) => a.concat(b), [])
          }
          console.log("polygon_after", polygonEdited);
          return polygonEdited
        }
        else {
          return poly
        }
      }))

    }
  }
  const handleMouseDown = (e) => {
    if (e.evt.shiftKey && e.evt.button === 0) {
      shiftPlusLeftClickHandler(e)

      return
    }

    const stage = e.target.getStage();
    const mousePos = getMousePos(stage);
    if (!isDrawingModeOn)
      return
    // if (isPolyComplete) return;

    if (isMouseOverPoint && points.length >= 3) {
      // setPolyComplete(true);

      if (selectedEntity?.linkedToEntity?.entity?.id) {
        savePoly()

      }
      else {
        setOpenImagePolygon(true)
      }


      // dispatch(setPolygonsRedux([...polygons,
      // {
      //   points: points,
      //   flattenedPoints: flattenedPoints
      // }]))

      // setPoints([])
      // setFlattenedPoints()

    } else {
      setPoints([...points, mousePos]);
    }
  };
  /////////////////////// end mouse down related functions///////////////////

  ////////////////////// polygon lines /////////////////
  const handleMouseOverStartPoint = (e) => {
    if (isDrawingModeOn) {
      if (isPolyComplete || points.length < 3) return;
      e.target.scale({ x: 3, y: 3 });
      setMouseOverPoint(true);
    }

  };
  const handleMouseOutStartPoint = (e) => {
    if (isDrawingModeOn) {
      e.target.scale({ x: 1, y: 1 });
      setMouseOverPoint(false);
    }

  };
  useEffect(() => {
    setFlattenedPoints(
      points
        .concat(isPolyComplete ? [] : position)
        .reduce((a, b) => a.concat(b), [])
    );
  }, [points, isPolyComplete, position]);

  const submitSelectImage = () => {

    let title = selectedEntity?.sourceEntity?.label + " : " + selectedEntity?.sourceEntity?.entity?.name

    if (selectedEntity?.linkedToEntity?.entity?.name) {
      title += " ربطه بـ" + selectedEntity?.linkedToEntity?.label + " : " + selectedEntity?.linkedToEntity?.entity?.name
    }
    if (selectedEntity?.linkedToEntity?.unitLocation?.entity?.name) {
      title += " و " + selectedEntity?.linkedToEntity?.unitLocation?.label + " : " + selectedEntity?.linkedToEntity?.unitLocation?.entity?.name
    }

    setNameOfCurrentCriteria(title);
    const imagePoly = selectedEntity?.sourceEntity?.entity?.images?.filter(image => image?.image_type_id == 4)
    const imageFloorsPoly = selectedEntity?.sourceEntity?.entity?.images?.filter(image => image?.image_type_id == 5)
    let userSelectedImages = []
    if (imagePoly?.length) {
      userSelectedImages.push(selectedEntity?.sourceEntity?.entity?.images?.filter(image => image?.image_type_id == 4)[0])
    }
    if (imageFloorsPoly?.length) {
      userSelectedImages.push(selectedEntity?.sourceEntity?.entity?.images?.filter(image => image?.image_type_id == 5)[0])
    }
    setSelectedImages(userSelectedImages)
    if (userSelectedImages?.length > 0) {
      if (imageType && imageType == "path_2_with_floors") {
        userSelectedImages?.map((image, index) => {
          if (image?.image_type_id == 5) {
            selectImageToDraw(image)
            setSelectedImageIndex(index)
          }
        })
      }
      else if (imageType && imageType == "path_1_standard") {
        userSelectedImages?.map((image, index) => {
          if (image?.image_type_id == 4) {
            selectImageToDraw(image)
            setSelectedImageIndex(index)
          }
        })
      }
      else {
        selectImageToDraw(userSelectedImages[0])
        setSelectedImageIndex(0)
      }

    }
    setOpenFilterImage(false)
  }
  const selectImageToDraw = (image) => {

    if (image && image?.image_type_id == 5) {
      setNameOfCurrentCriteriaSwitch('عرض الجميع حسب الطوابق');
    }
    setImagePath(process.env.REACT_APP_API_URL_image + image?.image_url)
    if (image?.image_polygons?.length > 0) {

      let imagePolygons = image?.image_polygons?.map((imagePolygon, index) => {
        let polyCoords = []
        try {
          polyCoords = JSON.parse(imagePolygon?.coords)
        } catch (error) {

        }

        return {
          id: index + 1,
          points: helpers.arrayChunks(polyCoords, 2),
          flattenedPoints: polyCoords,
          notHasLine: true,
          readOnly: true,
          polygonInfo: imagePolygon
        }
      })

      setPolygons(imagePolygons)
    }
    else {
      setPolygons([])
    }
  }
  const cancelSavePoly = () => {
    setSelectedEntity({
      ...selectedEntity,
      linkedToEntity: {
        type: '',
        entity: {
          id: '',
          label: ''
        },
        label: ''
      },
    })
    setPoints([])
    setFlattenedPoints([])
    setOpenImagePolygon(false)
  }
  const savePoly = () => {

    if (selectedEntity?.linkedToEntity?.type == "unit_types" && !selectedEntity?.linkedToEntity?.unitLocation) {
      toast("في حالة اختيار نوع الوحدة يجب اختيار موقع الوحدة كذلك")
      return
    }
    if (selectedImages[selectedImageIndex]?.image_type_id == 5 && selectedEntity?.linkedToEntity?.type != "unit_types") {
      toast("يجب اختيار نوع الوحدة وموقعها في حالة اختيار صورة الطوابق")
      return
    }
    if (selectedImages[selectedImageIndex]?.image_type_id == 5
      &&
      (
        !Array.isArray(selectedEntity?.linkedToEntity?.floor)
        ||
        (Array.isArray(selectedEntity?.linkedToEntity?.floor) && selectedEntity?.linkedToEntity?.floor?.length <= 0)
      )) {
      toast("يجب اختيار طابق واحد على الاقل")
      return
    }
    // const imageId = selectedEntity?.sourceEntity?.entity?.images?.filter(image => image?.image_type_id == 4)[0]?.id
    const imageId = selectedImages[selectedImageIndex]?.id
    let polygonInfo = {
      image_id: imageId,
      coords: "[" + flattenedPoints?.toString() + "]",
      image_polygonable_id: selectedEntity?.linkedToEntity?.entity?.id,
      image_polygonable: {
        name: selectedEntity?.linkedToEntity?.entity?.name
      }
    }
    if (selectedEntity?.linkedToEntity?.type == "unit_types") {
      polygonInfo = {
        ...polygonInfo,
        image_polygonable_type: "App\\Models\\UnitType",
        unit_location_id: selectedEntity?.linkedToEntity?.unitLocation?.entity?.id,
        unit_location_name: selectedEntity?.linkedToEntity?.unitLocation?.entity?.name,
        ...(Array.isArray(selectedEntity?.linkedToEntity?.floor) ?
          {
            floor_ids: selectedEntity?.linkedToEntity?.floor?.length > 0 ? selectedEntity?.linkedToEntity?.floor?.map(item => item?.id) : [],
          } :
          {
            floor_id: selectedEntity?.linkedToEntity?.floor?.id,
          })

      }
    }

    else {
      const modelType = selectedEntity?.linkedToEntity?.type
      const modelName = modelType.charAt(0).toUpperCase() + modelType.substring(1, modelType?.length - 1);
      polygonInfo = {
        ...polygonInfo,
        "image_polygonable_type": "App\\Models\\" + modelName,
      }
    }

    if (selectedEntity?.linkedToEntity?.type == "floors") {
      polygonInfo = {
        ...polygonInfo,
        floor_id: selectedEntity?.linkedToEntity?.entity?.id,


      }
      

    }

    const polygId = polygons?.length > 0 ? Math.max.apply(Math, polygons?.map(function (poly) { return poly?.id; })) + 1 : 1


    setPolygons([...polygons,
    {
      id: polygId,
      points: points,
      flattenedPoints: flattenedPoints,
      polygonInfo: polygonInfo
    }])
    setPoints([])
    setFlattenedPoints([])
    setOpenImagePolygon(false)
    setSelectedBlock(null)
    setSelectedCompound(null)
    setChosenBuilding(null)
    setSelectedFloor(null)
    setSelectedUnitLocation(null)
    setSelectedUnitType(null)

    setSelectedEntity({
      ...selectedEntity,
      linkedToEntity: {
        type: '',
        entity: {
          id: '',
          label: ''
        },
        label: ''
      },
    })
  }
  const handleMouseOutPolygon = (e, polygon) => {
    setPolygonToolTipObject({
      title: "",
      availableUnits: 0,
      icon: "",
      show: false,
      top: "",
      left: ""
    })
  }
  const handleMouseOverPolygon = (e, polygon, n, eventType) => {

    let top = null, left = null;
    if (e?.type == 'tap') {
      top = e?.evt?.changedTouches[0]?.clientY
      left = e?.evt?.changedTouches[0]?.clientX

      setPolygonToolTipObject({
        title: polygon?.polygonInfo?.image_polygonable?.name,
        icon: "",
        availableUnits: 0,
        show: true,
        showAvailableUnits: false,
        top: top,
        left: left
      })
    } else if (e?.type == 'mouseover') {
      top = e.evt.clientY
      left = e.evt.clientX
      let polyTitle = polygon?.polygonInfo?.image_polygonable?.name
      if (polygon?.polygonInfo?.floor_ids?.length) {
        polyTitle += ": (" + polygon?.polygonInfo?.floor_ids?.join(",") + ")" + " - " + polygon?.polygonInfo?.unit_location_name
      }
      setPolygonToolTipObject({
        title: polyTitle,
        icon: "",
        availableUnits: 0,
        show: true,
        showAvailableUnits: false,
        top: top,
        left: left
      })
    }



  }
  const submit = async () => {

    setLoading(true)

    try {

      // return 
      let data = new FormData()
      let imageId = null
      polygons?.map((polygon, index) => {

        let polygonInfo = polygon?.polygonInfo
        imageId = polygonInfo?.image_id
        data.append('id[' + index + ']', polygonInfo?.id)
        data.append('coords[' + index + ']', polygonInfo?.coords)
        data.append('image_polygonable_type[' + index + ']', polygonInfo?.image_polygonable_type)
        data.append('image_polygonable_id[' + index + ']', polygonInfo?.image_polygonable_id)
        data.append('unit_location_id[' + index + ']', polygonInfo?.unit_location_id ? polygonInfo?.unit_location_id : '')
        data.append('image_id[' + index + ']', polygonInfo?.image_id)
        if (polygonInfo?.floor_ids?.length > 0) {
          polygonInfo?.floor_ids?.map((floor_id, index2) => {
            data.append('floor_ids[' + index + '][' + index2 + ']', floor_id)
          }
          )

        }
        else {
          data.append('floor_id[' + index + ']', polygonInfo?.floor_id ? polygonInfo?.floor_id : '')
        }

      })

      data.append('image_id', imageId)

      const result = await ImagePolygonsAPI.addImagePolygon({
        data: data,
        signal: controller?.signal
      })


      if (result.status) {
        toast('تم الحفظ')

      }
      else {
        toast('فشلت عملية الحفظ')
      }
    } catch (error) {

      toast('فشلت عملية الحفظ')
    }
    setLoading(false)
  }

  useEffect(() => {

    if (state?.data?.selectedEntity) {

      setImageType(state?.data?.image_type)
      if (state?.data?.image_type == "path_2_with_floors") {
        setSelectedUnitType(state?.data?.unit_type)
        setSelectedUnitLocation(state?.data?.unit_location)
        setSelectedFloor(state?.data?.floor)
        setChosenBuilding(state?.data?.building)
        setSelectedBlock(state?.data?.block)
        setSelectedCompound(state?.data?.compound)
        setSelectedEntity({
          ...selectedEntity,
          ...state?.data?.selectedEntity,
          linkedToEntity: {
            type: '',
            entity: {
              id: '',
              label: ''
            },
            label: ''
          }
        })
      }
      else {
        setSelectedEntity({
          ...selectedEntity,
          ...state?.data?.selectedEntity
        })
      }

      setRunSubmitSelectImage(true)
    }

  }, [state]);



  useEffect(() => {
    if (runSubmitSelectImage) {
      submitSelectImage()
      setRunSubmitSelectImage(false)
    }


    return () => {

    }
  }, [runSubmitSelectImage])

  const updatePolygon = (newPoints, polygon) => {
    const newFlattenedPoints = newPoints
      .reduce((a, b) => a.concat(b), [])

    const updatePolygons = {
      ...polygon,
      points: newPoints,
      polygonInfo: {
        ...polygon?.polygonInfo,
        coords: "[" + newFlattenedPoints?.toString() + "]",
      },
      flattenedPoints: newFlattenedPoints,
    }
    setPolygons(polygons?.map(poly => {
      if (poly?.id == polygon?.id)
        return updatePolygons
      else
        return poly
    }))

  }

  const [zoomScale, setZoomScale] = useState(1)
  const [isEditModeOn, setIsEditModeOn] = useState(false)

  return (
    <div className="container-fluid">

      <div className="row">
        <div className="col-md-12 my-2" style={{ padding: 0, marginTop: "-24px !important" }}>
          <div style={wrapperStyle}>
            {
              // loading ? <Preloading /> :
              <>

                <Stage
                  width={window.innerWidth}
                  height={window.innerHeight - 40}
                  // draggable={!isTouchEnabled()}
                  draggable
                  onWheel={zoomStage}
                  onTouchMove={handleTouch}
                  onTouchEnd={handleTouchEnd}
                  onMouseMove={handleMouseMove}
                  onMouseDown={handleMouseDown}
                  ref={stageRef}

                >

                  <Layer id='stuffToShow'>
                    {imagePath &&
                      <BuildingImage imageUrl={imagePath} />
                    }
                    {!hidePolygons &&
                      <>
                        {polygons.map((polygon, index) => (
                          <PolygonAnnotation
                            update={(newPoints) => { updatePolygon(newPoints, polygon) }}
                            key={index}
                            stageZoomChanged={stageZoomChanged}
                            zoomScale={zoomScale}
                            stage={stageRef}
                            points={polygon?.points}
                            flattenedPoints={polygon?.flattenedPoints}
                            handleMouseOverStartPoint={handleMouseOverStartPoint}
                            handleMouseOutStartPoint={handleMouseOutStartPoint}
                            isFinished={true}
                            notHasLine={polygon?.notHasLine}
                            readOnly={polygon?.readOnly}
                            onPolygonClicked={(e) => {
                              if (!isEditModeOn) {
                                swal({
                                  title: t('IMAGE_MAP_DRAW.info_alert'),
                                  text: t('IMAGE_MAP_DRAW.you_can_edit_delete_or_cancel_polygon'),
                                  icon: 'warning',
                                  // buttons: [t('GLOBAL.no'), t('GLOBAL.yes'), t('GLOBAL.yes')],
                                  buttons: {
                                    edit: {
                                      text: t('GLOBAL.edit'),
                                      className: 'sweet-edit'
                                    },
                                    delete: {
                                      text: t('GLOBAL.delete'),
                                      className: 'sweet-delete'
                                    },
                                    cancel: t('GLOBAL.cancel'),
                                  },
                                  dangerMode: true,
                                })
                                  // .then(async (iAmSure) => {
                                  //   console.log("iAmSure",iAmSure);
                                  //   if (iAmSure) {
                                  //     setPolygons(polygons?.filter(poly => poly?.id != polygon?.id))
                                  //   } else {
                                  //     // alert('canceled')
                                  //   }
                                  // })
                                  .then((value) => {
                                    switch (value) {

                                      case "edit":
                                        // setPoints(polygon?.points)
                                        // setFlattenedPoints(polygon?.flattenedPoints)
                                        setIsEditModeOn(true)
                                        setPolygonToEdit(polygon)
                                        setPolygons(polygons?.map(poly => {
                                          if (poly?.id == polygon?.id) {
                                            return {
                                              ...poly,
                                              notHasLine: false,
                                              readOnly: false
                                            }
                                          }
                                          else {
                                            return {
                                              ...poly,
                                              notHasLine: true,
                                              readOnly: true
                                            }
                                          }
                                        }))

                                        break;

                                      case "delete":
                                        setPolygons(polygons?.filter(poly => poly?.id != polygon?.id))
                                        break;

                                      default:
                                        return
                                    }
                                  });
                              }



                            }}
                            hasFreeUnits={false}
                            onMouseOver={(e) => { handleMouseOverPolygon(e, polygon) }}
                            onMouseOut={(e) => { handleMouseOutPolygon(e, polygon) }}

                          />
                        ))}
                        <PolygonAnnotation
                          points={points}
                          zoomScale={zoomScale}
                          flattenedPoints={flattenedPoints}
                          handleMouseOverStartPoint={handleMouseOverStartPoint}
                          handleMouseOutStartPoint={handleMouseOutStartPoint}
                          isFinished={isPolyComplete}
                        />
                      </>}
                  </Layer>
                </Stage>
                <PolygonToolTip {...polygonToolTipObject} />

                {nameOfCurrentCriteria ? <Box
                  sx={{
                    width: "fit-content",
                    height: "30px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "20px",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer',
                    position: 'absolute',
                    top: '65px',
                    // left:'50%',
                    left: isopensidemenu ? '55%' : '50%',
                    transform: 'translateX(-50%)',
                    fontFamily: 'Cairo',
                    padding: '0 10px'
                  }}
                >
                  {nameOfCurrentCriteriaSwitch ? nameOfCurrentCriteria + ' / ' + nameOfCurrentCriteriaSwitch : nameOfCurrentCriteria}
                </Box> : null}

                <Box
                  sx={{
                    width: "auto",
                    height: "auto",
                    //  background: "#FAFAFA 0% 0% no-repeat padding-box", 
                    opacity: "1",
                    position: "fixed",
                    bottom: '25px',
                    right: '25px',
                    zIndex: 200,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column',

                  }}
                >
                  {isEditModeOn ?
                    <>
                      <Box sx={{
                        width: "50px", height: "50px", boxShadow: "0px 0 10px -3px #000", opacity: "1",
                        borderRadius: "50%",
                        display: 'flex',
                        background: isEditModeOn ? "#1E6A99 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: '5px 0',
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          setIsEditModeOn(!isEditModeOn)
                          setPolygons(polygons?.map(poly => {

                            return {
                              ...poly,
                              notHasLine: true,
                              readOnly: true
                            }

                          }))
                          setIsDrawingModeOn(false)

                        }}

                      >
                        <Tooltip sx={{ fontFamily: 'Cairo' }} title={isEditModeOn ? <span style={{ fontFamily: 'Cairo-Medium' }}>{" ايقاف وضع التعديل "}</span> : <span style={{ fontFamily: 'Cairo-Medium' }}>{'تفعيل وضع التعديل'} </span>}>
                          <EditIcon sx={{ color: isEditModeOn ? '#FFFFFF' : '#1E6A99', fontSize: '34px' }} />
                        </Tooltip>
                      </Box>
                    </> : null}
                  {isEditModeOn || isDrawingModeOn ?
                    <>
                      <Box sx={{
                        width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0 10px -3px #000", opacity: "1",
                        borderRadius: "50%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: '5px 0',
                        cursor: 'pointer'
                      }}
                        onClick={() => {
                          setZoomScale(zoomScale + 1)

                        }}

                      >
                        <TextIncreaseIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                      </Box>
                      <Box
                        sx={{
                          width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0 10px -3px #000", opacity: "1",
                          borderRadius: "50%",
                          display: 'flex',
                          justifyContent: "center",
                          alignItems: "center",
                          margin: '5px 0',
                          cursor: 'pointer',

                        }}
                        onClick={() => {

                          if (zoomScale - 1 > 0)
                            setZoomScale(zoomScale - 1)
                        }}

                      >
                        <TextDecreaseIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                      </Box>
                    </> : null}



                  {!isDrawingModeOn ?
                    <Box
                      sx={{
                        width: "50px", height: "50px",
                        background: "#FFFFFF 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0 10px -3px #000", opacity: "1",
                        borderRadius: "50%",
                        display: 'flex',
                        justifyContent: "center",
                        alignItems: "center",
                        margin: '5px 0',
                        cursor: 'pointer'
                      }}
                      onClick={() => {
                        setPoints([])
                        setFlattenedPoints([])
                        setPolygons([])
                      }}
                    >
                      <Tooltip sx={{ fontFamily: 'Cairo' }} title={<span style={{ fontFamily: 'Cairo-Medium' }}>{"مسح جميع الطبقات"}</span>}>
                        <LayersClearIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                      </Tooltip>
                    </Box> : null}
                  {isDrawingModeOn ?
                    <Box sx={{
                      width: "50px", height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 0 10px -3px #000", opacity: "1",
                      borderRadius: "50%",
                      display: 'flex',
                      justifyContent: "center",
                      alignItems: "center",
                      margin: '5px 0',
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        setPoints([])
                        setFlattenedPoints([])
                      }}
                    >
                      <Tooltip sx={{ fontFamily: 'Cairo' }} title={<span style={{ fontFamily: 'Cairo-Medium' }}>{"مسح الطبقة الحالية"}</span>}>
                        <RemoveIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                      </Tooltip>
                    </Box> : null}
                  <Box sx={{
                    width: "50px", height: "50px",
                    background: isDrawingModeOn ? "#1E6A99 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      setIsDrawingModeOn(!isDrawingModeOn)
                      setPolygons(polygons?.map(poly => {

                        return {
                          ...poly,
                          notHasLine: true,
                          readOnly: true
                        }

                      }))
                      setIsEditModeOn(false)
                    }}
                  >
                    <Tooltip sx={{ fontFamily: 'Cairo' }} title={isDrawingModeOn ? <span style={{ fontFamily: 'Cairo-Medium' }}>{" ايقاف وضع الرسم "}</span> : <span style={{ fontFamily: 'Cairo-Medium' }}>{'تفعيل وضع الرسم'} </span>}>
                      <GestureIcon sx={{ color: isDrawingModeOn ? "#FFFFFF" : '#1E6A99', fontSize: '34px' }} />
                    </Tooltip>
                  </Box>
                  <Box sx={{
                    width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer'
                  }}
                    onMouseDown={() => {

                      zoomInterval.current = setInterval(() => {
                        zoomStageWithButton(true)

                      }, 50);
                    }}
                    onMouseUp={() => {

                      clearInterval(zoomInterval?.current)
                    }}

                  >
                    <ZoomInIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                  </Box>
                  <Box
                    sx={{
                      width: "50px", height: "50px", background: "#FFFFFF 0% 0% no-repeat padding-box", boxShadow: "0px 0 10px -3px #000", opacity: "1",
                      borderRadius: "50%",
                      display: 'flex',
                      justifyContent: "center",
                      alignItems: "center",
                      margin: '5px 0',
                      cursor: 'pointer',

                    }}
                    onMouseDown={() => {

                      zoomInterval.current = setInterval(() => {
                        zoomStageWithButton(false)

                      }, 50);
                    }}
                    onMouseUp={() => {
                      clearInterval(zoomInterval?.current)
                    }}
                  >
                    <ZoomOutIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                  </Box>

                  <Box sx={{
                    width: "50px", height: "50px",
                    background: hidePolygons ? "#1E6A99 0% 0% no-repeat padding-box" : "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      setHidePolygons(!hidePolygons)
                    }}
                  >
                    <Tooltip sx={{ fontFamily: 'Cairo' }} title={hidePolygons ? <span style={{ fontFamily: 'Cairo-Medium' }}>{" اظهار التقسيمات"}</span> : <span style={{ fontFamily: 'Cairo-Medium' }}>{'اخفاء التقسيمات'} </span>}>
                      <RemoveRedEyeIcon sx={{ color: hidePolygons ? "#FFFFFF" : '#1E6A99', fontSize: '34px' }} />
                    </Tooltip>
                  </Box>
                </Box>

                <Box
                  sx={{
                    width: "auto",
                    height: "auto",
                    //  background: "#FAFAFA 0% 0% no-repeat padding-box", 
                    opacity: "1",
                    position: "fixed",
                    top: '65px',
                    right: '25px',
                    zIndex: 200,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column'
                  }}
                >
                  <Box sx={{
                    width: "50px", height: "50px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer',
                  }}
                    onClick={() => {
                      navigate({ state: {} });
                      setOpenFilterImage(true)
                    }}
                  >
                    <Tooltip sx={{ fontFamily: 'Cairo' }} title={<span style={{ fontFamily: 'Cairo-Medium' }}>{"اختر الصورة"}</span>}>
                      <AddPhotoAlternateIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                    </Tooltip>
                  </Box>

                  {selectedImages?.length > 1 ?
                    <Box sx={{
                      width: "50px", height: "50px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 0 10px -3px #000", opacity: "1",
                      borderRadius: "50%",
                      display: 'flex',
                      justifyContent: "center",
                      alignItems: "center",
                      margin: '5px 0',
                      cursor: 'pointer'
                    }}
                      onClick={() => {
                        selectImageToDraw(selectedImages[selectedImageIndex ? 0 : 1])
                        setNameOfCurrentCriteriaSwitch(!selectedImageIndex ? "عرض الجميع حسب الطوابق" : '')
                        setSelectedImageIndex(selectedImageIndex ? 0 : 1)
                      }}
                    >
                      <Tooltip sx={{ fontFamily: 'Cairo' }} title={<span style={{ fontFamily: 'Cairo-Medium' }}>{"اقلب الصورة"}</span>}>
                        <FlipCameraAndroidIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                      </Tooltip>
                    </Box>
                    :
                    null}
                </Box>
                <Box
                  sx={{
                    width: "auto",
                    height: "auto",
                    //  background: "#FAFAFA 0% 0% no-repeat padding-box", 
                    opacity: "1",
                    position: "absolute",
                    top: '65px',
                    left: isopensidemenu ? (drawerwidth + 5) + 'px' : '90px',
                    zIndex: 1000,
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: 'column'
                  }}
                >
                  <Box sx={{
                    width: "50px", height: "50px",
                    background: "#FFFFFF 0% 0% no-repeat padding-box",
                    boxShadow: "0px 0 10px -3px #000", opacity: "1",
                    borderRadius: "50%",
                    display: 'flex',
                    justifyContent: "center",
                    alignItems: "center",
                    margin: '5px 0',
                    cursor: 'pointer'
                  }}
                    onClick={() => {
                      submit()
                    }}
                  >
                    <Tooltip sx={{ fontFamily: 'Cairo' }} title={<span style={{ fontFamily: 'Cairo-Medium' }}>{"حفظ"}</span>}>
                      <SaveIcon sx={{ color: '#1E6A99', fontSize: '34px' }} />
                    </Tooltip>
                  </Box>
                </Box>
              </>
            }
          </div>
        </div>
        {openFilterImage &&
          <PopupForm
            width={{ xs: '95%', sm: '95%', md: '50%', lg: '40%' }}
            open={openFilterImage}
            setOpen={setOpenFilterImage}
            title={'اختر صورة'}
            content={<FilterImage
              selectedBuilding={selectedBuilding}
              setSelectedBuilding={setSelectedBuilding}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
            />}
            footer={<FilterImageFooter
              setOpen={setOpenFilterImage}
              submit={submitSelectImage}
            />}
          />}
        {openImagePolygon &&
          <PopupForm
            width={{ xs: '95%', sm: '95%', md: '50%', lg: '40%' }}
            open={openImagePolygon}
            setOpen={(value) => {

              setOpenImagePolygon(value)
              setPoints([])
              setFlattenedPoints([])
            }}
            title={'ربط التقسيم بعنصر معين'}
            content={<LinkImagePoly
              open={openImagePolygon}
              selectedEntity={selectedEntity}
              setSelectedEntity={setSelectedEntity}
              selectedImagePolyLink={selectedImagePolyLink}
              selectedImage={selectedImages[selectedImageIndex]}
              setSelectedImagePolyLink={setSelectedImagePolyLink}
              selectedBuilding={selectedBuilding}
              setSelectedBuilding={setSelectedBuilding}
              selectedBlock={selectedBlock}
              setChosenBuilding={setChosenBuilding}
              chosenBuilding={chosenBuilding}
              setSelectedBlock={setSelectedBlock}
              selectedCompound={selectedCompound}
              setSelectedCompound={setSelectedCompound}
              selectedFloor={selectedFloor}
              setSelectedFloor={setSelectedFloor}
              selectedUnitLocation={selectedUnitLocation}
              setSelectedUnitLocation={setSelectedUnitLocation}
              selectedUnitType={selectedUnitType}

            />}
            footer={<LinkImageFooter
              setOpen={setOpenImagePolygon}
              cancel={cancelSavePoly}
              submit={savePoly}
            />}
          />}
      </div>
    </div>
  )
}

export default ImageMapDraw;