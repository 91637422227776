import callAPI from '../ApiConfig'
import store from '../../../app/store'

const notifications = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notifications",
        method:"get",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const notificationsList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notifications_list",
        method:"get",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addNotifications = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notifications",
        method:"post",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateNotification = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notifications/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteNotification = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notifications/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}
const myNotifications = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"my_notifications",
        method:"get",
        withOutGLovalLoading:props?.withOutGLovalLoading,
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const setMyNotificationAsRead = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"set_my_notification_as_read",
        method:"post",
        data:props?.data,
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const myUnreadNotificationsCount = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"my_unread_notifications_count",
        method:"get",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}


const notificationsAPI={
    notifications:notifications,
    notificationsList:notificationsList,
    addNotifications:addNotifications,
    updateNotification:updateNotification,
    deleteNotification:deleteNotification,
    myNotifications:myNotifications,
    setMyNotificationAsRead:setMyNotificationAsRead,
    myUnreadNotificationsCount:myUnreadNotificationsCount

}
export default notificationsAPI