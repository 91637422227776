import React, { useState, useEffect, useMemo } from "react";
import Cookies from "universal-cookie";
import { useNavigate, useLocation } from "react-router-dom";
import RolesOfUserAPI from "../Global/Network/RoleUser";
import RedirectPages from "../Global/RedirectPages/RedirectPages";
import { useTranslation } from "react-i18next";
import Preloading from "../Global/Preload/Preloading";
import Pusher from "pusher-js";
import { toast } from "react-toastify";
import notificationsAPI from "../Global/Network/Notification";
import {
  setNotificationsRedux,
  setTotalUnreadNotificationsCountRedux,
  setLoadNotificationsData
} from "../reduxStore/NotificationReducer";
import { useDispatch, useSelector } from "react-redux";
import { setRolesRedux } from "../reduxStore/RolesReducer";
const cookies = new Cookies();



// const pusher = new Pusher('ba67f88f77a189074291', {
//   cluster: 'ap1',
//   encrypted: true,
// });
export default function ProtectionAdmin(props) {
  const [t] = useTranslation("common");
  const token = cookies.get("token");
  const access_type = cookies.get("access_type");
  const navigate = useNavigate();
  const [userRoles, setUserRoles] = useState([]);
  const [hasAccessToThisRoute, setHasAccessToThisRoute] = useState(false);
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const loadNotificationsData=useSelector(state=>state.settingsData.loadNotificationsData)

  const [loadNotifications, setLoadNotifications] = useState(false);
  const [code, setCode] = useState(null);
  const [loading, setLoading] = useState(false);
  let location = useLocation();
  // let unitsNotificationChannel = null;
  // let callsNotificationChannel = null;
  const [pathsList,setPathList] = useState([
      {
        id: 0,
        roles: (rolesRedux)=>rolesRedux?.view_favourite_page?.value,
        to: 'my_favourite_pages',
        subLink: []
      },
      {
        id: 1,
        roles:(rolesRedux)=>rolesRedux?.dashboard?.value,
        to: "dashboard",
        subLink: [],
      },
      {
        id: 2,
        roles:(rolesRedux)=> rolesRedux?.view_available_compounds?.value,
        to: "available-compounds",
        subLink: [],
      },
      {
        id: 5,
        roles:(rolesRedux)=> rolesRedux?.view_registered_customers?.value,
        to: "registered-customers",
        subLink: [],
      },
      {
        id: 5,
        roles:(rolesRedux)=> rolesRedux?.add_customer?.value,
        to: "registered-customers/add",
        subLink: [],
      },
      {
        id: 80,
        roles:(rolesRedux)=> rolesRedux?.view_draw_image?.value,
        to: "image-draw",
        subLink: [],
      },
      {
        id: 80,
        roles:(rolesRedux)=> rolesRedux?.manage_draw_image?.value,
        to: "image-view",
        subLink: [],
      },
      {
        id: 26,
        roles:(rolesRedux)=> rolesRedux?.view_calls?.value,
        to: "calls",
        subLink: [],
      },
      {
        id: 27,
        roles:(rolesRedux)=> rolesRedux?.view_order?.value,
        to: "orders",
        subLink: [],
      },
      {
        id: 28,
        roles:(rolesRedux)=> rolesRedux?.view_contract?.value,
        to: "order_contract",
        subLink: [],
      },
      {
        id: 29,
        roles:(rolesRedux)=> rolesRedux?.view_invoice?.value,
        to: "invoices",
        subLink: [],
      },
      {
        id: 29,
        roles:(rolesRedux)=>rolesRedux?.view_unit?.value,
        to: "view-unit",
        subLink: [],
      },
      {
        id: 36,
        roles:(rolesRedux)=> rolesRedux?.view_company?.value, //show company profile, with role view company.
        to: "company/profile",
        subLink: [],
      },
      {
        id: 37,
        roles:(rolesRedux)=> rolesRedux?.user_profile?.value, //show user profile,role 0 because all user have access to his profile.
        to: "user/profile",
        subLink: [],
      },

      {
        id: 10,
        roles:(rolesRedux)=> rolesRedux?.view_worktype?.value,
        to: "masters/worktype",
        subLink: [],
      },
      {
        id: 11,
        roles:(rolesRedux)=> rolesRedux?.view_employee_table?.value,
        to: "masters/employee",
        subLink: [],
      },
      {
        id: 1111,
        roles:(rolesRedux)=> rolesRedux?.view_department?.value,
        to: "masters/department",
        subLink: [],
      },
      {
        id: 1112,
        roles:(rolesRedux)=> rolesRedux?.view_favourite_page?.value,
        to: 'masters/favourite_pages',
        subLink: []
      },
      {
        id: 12,
        roles:(rolesRedux)=> rolesRedux?.view_purpose_of_purshases?.value,
        to: "masters/purpose-of-purshases",
        subLink: [],
      },
      {
        id: 13,
        roles:(rolesRedux)=> rolesRedux?.view_marital_status?.value,
        to: "masters/marital-status",
        subLink: [],
      },
      {
        id: 14,
        roles:(rolesRedux)=> rolesRedux?.view_customer_type?.value,
        to: "masters/customer-type",
        subLink: [],
      },
      {
        id: 15,
        roles:(rolesRedux)=> rolesRedux?.view_contact_method?.value,
        to: "masters/contact-method",
        subLink: [],
      },
      {
        id: 16,
        roles:(rolesRedux)=> rolesRedux?.view_national_id_types?.value,
        to: "masters/national-id-types",
        subLink: [],
      },
      {
        id: 17,
        roles:(rolesRedux)=> rolesRedux?.view_real_estate?.value,
        to: "masters/real-estates",
        subLink: [],
      },

      {
        id: 19,
        roles:(rolesRedux)=> rolesRedux?.view_call_type?.value,
        to: "masters/call-types",
        subLink: [],
      },
      {
        id: 20,
        roles:(rolesRedux)=> rolesRedux?.view_call_status?.value,
        to: "masters/call-statuses",
        subLink: [],
      },
      {
        id: 21,
        roles:(rolesRedux)=> rolesRedux?.view_call_destinations?.value,
        to: "masters/call-destinations",
        subLink: [],
      },
      {
        id: 22,
        roles:(rolesRedux)=> rolesRedux?.view_call_reasons?.value,
        to: "masters/call-reasons",
        subLink: [],
      },

      {
        id: 23,
        roles:(rolesRedux)=> rolesRedux?.view_unit_location?.value,
        to: "masters/unit-locations",
        subLink: [],
      },
      {
        id: 24,
        roles:(rolesRedux)=> rolesRedux?.view_room_type?.value,
        to: "masters/room-types",
        subLink: [],
      },
      {
        id: 25,
        roles:(rolesRedux)=> rolesRedux?.view_image_type?.value,
        to: "masters/image-types",
        subLink: [],
      },
      {
        id: 26,
        roles:(rolesRedux)=> rolesRedux?.view_role?.value,
        to: "masters/roles",
        subLink: [],
      },
      {
        id: 27,
        roles:(rolesRedux)=> rolesRedux?.view_compound?.value,
        to: "masters/compound",
        subLink: [],
      },
      {
        id: 28,
        roles:(rolesRedux)=> rolesRedux?.view_unit_statuses?.value,
        to: "masters/unit-statuses",
        subLink: [],
      },
      {
        id: 30,
        roles:(rolesRedux)=> rolesRedux?.view_unit_type?.value,
        to: "masters/unit-types",
        subLink: [],
      },
      {
        id: 31,
        roles:(rolesRedux)=> rolesRedux?.view_room?.value,
        to: "masters/rooms",
        subLink: [],
      },
      {
        id: 32,
        roles:(rolesRedux)=> rolesRedux?.view_unit?.value,
        to: "masters/units",
        subLink: [],
      },
      {
        id: 33,
        roles:(rolesRedux)=> rolesRedux?.view_payment_type?.value,
        to: "masters/payment_types",
        subLink: [],
      },
      {
        id: 34,
        roles:(rolesRedux)=> rolesRedux?.view_phase?.value,
        to: "masters/phases",
        subLink: [],
      },
      {
        id: 35,
        roles:(rolesRedux)=> rolesRedux?.view_all_companies?.value||rolesRedux?.view_company?.value , //only user have role viewAllCompanies can access this.
        to: "masters/companies",
        subLink: [],
      },
      {
        id: 38,
        roles:(rolesRedux)=> rolesRedux?.view_discount?.value,
        to: "masters/discounts",
        subLink: [],
      },
      {
        id: 39,
        roles:(rolesRedux)=> rolesRedux?.view_master_unit_type?.value,
        to: "masters/master-unit-types",
        subLink: [],
      },
      {
        id: 40,
        roles:(rolesRedux)=> rolesRedux?.add_bulk_data?.value,
        to: "masters/bulk-insert-floors",
        subLink: [],
      },
      {
        id: 41,
        roles:(rolesRedux)=> rolesRedux?.delete_bulk_data?.value,
        to: "masters/bulk-delete-floors",
        subLink: [],
      },
      {
        id: 42,
        roles:(rolesRedux)=> rolesRedux?.add_bulk_data?.value,
        to: "masters/bulk-insert-buildings",
        subLink: [],
      },
      {
        id: 43,
        roles:(rolesRedux)=> rolesRedux?.delete_bulk_data?.value,
        to: "masters/bulk-delete-buildings",
        subLink: [],
      },
      {
        id: 44,
        roles:(rolesRedux)=> rolesRedux?.view_notifications?.value,
        to: "masters/notifications",
        subLink: [],
      },
      {
        id: 45,
        roles:(rolesRedux)=> rolesRedux?.view_notifications_type?.value,
        to: "masters/notification-types",
        subLink: [],
      },
      {
        id: 46,
        roles:(rolesRedux)=> rolesRedux?.view_compound_department?.value,
        to: "masters/compound-departments",
        subLink: [],
      },
    ]);

  const dispatch = useDispatch();

  // const subscribeToNotifications = (roles) => {
  //   if (roles?.includes(220)) {
  //     // subscribe to units channel
  //     const unitsNotificationChannel = pusher.subscribe("units-channel");
  //     unitsNotificationChannel.bind("units-event", (data) => {
  //       setLoadNotifications(true);
  //       toast.info(data?.notification?.title, {
  //         position: "bottom-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     });
  //   }
  //   if (roles?.includes(221)) {
  //     // subscribe to calls channel
  //     const callsNotificationChannel = pusher.subscribe("calls-channel");
  //     callsNotificationChannel.bind("calls-event", (data) => {
  //       setLoadNotifications(true);
  //       toast.info(data?.notification?.title, {
  //         position: "bottom-left",
  //         autoClose: 5000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: false,
  //         progress: undefined,
  //         theme: "dark",
  //       });
  //     });
  //   }
  // };

  useEffect(() => {
    if (loadNotifications) {
      loadNotificationsDataCount();
      setLoadNotifications(false);
      dispatch(setLoadNotificationsData(false))
    }
    return () => {};
  }, [loadNotificationsData]);

  const loadNotificationsDataCount = async () => {
    try {
      let result = await notificationsAPI.myUnreadNotificationsCount({
        params: {
          page_size: 5,
          page: 1,
        },
      });
      if (result?.status) {
        // dispatch(setNotificationsRedux(result?.data?.data))
        dispatch(
          setTotalUnreadNotificationsCountRedux(
            result?.data?.data?.total_unread
          )
        );
      } else {
      }
    } catch (err) {}
  };
  const hadleCheckIdExists = (roles) => {
    
    const updatedRoles = Object.entries(rolesRedux).reduce(
      (acc, [key, value]) => {
        if (roles?.includes(value?.id)) {
          acc[key] = { ...value, value: true };
        } else {
          acc[key] = { ...value, value: false };
        }
        return acc;
      },
      {}
    );
    dispatch(setRolesRedux(updatedRoles));
    
  };

  const getUserRoles = async () => {
    setLoading(true);
    try {
      let data = await RolesOfUserAPI.GetRolesOfUser();
      if (data && data?.status) {
        let arr = [];
        if (data?.data) {
          arr = [...data?.data];
          arr.push(2000);
        }
        setUserRoles(arr);
        
        // subscribeToNotifications(arr);
      } else {
        if (data?.errorCode == 401) {
          cookies.set("token", null);
          cookies.remove("token");
          cookies.remove("token", { path: "/" });
          navigate("/");
        }
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
    }
  };
  useEffect(() => {
    let abortController = new AbortController();
    getUserRoles();
    loadNotificationsDataCount();
    return () => {
      abortController.abort();
      // if (unitsNotificationChannel) {
      //   unitsNotificationChannel.unbind();
      // }
      // if (callsNotificationChannel) {
      //   callsNotificationChannel.unbind();
      // }
      // pusher.disconnect();
    };
  }, []);

  useEffect(() => {
    let abortController = new AbortController();
    if (userRoles) {
      hadleCheckIdExists(userRoles);
    }
    return () => {
      abortController.abort();
    };
  }, [userRoles]);

  useEffect(() => {
    let abortController = new AbortController();
    if (rolesRedux) {
      routeChange();
    }
    return () => {
      abortController.abort();
    };
  }, [rolesRedux]);

  useEffect(() => {
    let abortController = new AbortController();
    getUserRoles()
    return () => {
      abortController.abort();
    };
  }, [location?.pathname]);

  const routeChange = async () => {
    setLoading(true);
    if (userRoles && userRoles?.length > 0) {
      let check = false;
      let codeError = null;
      pathsList &&
        pathsList?.length > 0 &&
        pathsList?.map((itm) => {
          if (!check) {

            // if (itm && itm?.subLink && itm?.subLink?.length > 0) {
            //   //has sub
            //   itm?.subLink?.map((subItm) => {
            //     if (!check) {
            //       if (
            //         subItm &&
            //         subItm?.subLink &&
            //         subItm?.subLink?.length > 0
            //       ) {
            //         //has sub and has sub
            //         // here will be the condition of sub sub...
            //         subItm?.subLink?.map((subSubItem) => {
            //           if (!check) {
            //             if (subSubItem && subSubItem?.length > 0) {
            //             } else {
            //               if (
            //                 location?.pathname == `/admin/${subSubItem?.to}`
            //               ) {
            //                 let checkIsInRole = false;
            //                 subSubItem &&
            //                   subSubItem?.roles &&
            //                   subSubItem?.roles?.map((role) => {
            //                     if (!checkIsInRole)
            //                       checkIsInRole = userRoles.includes(role);
            //                   });
            //                 if (checkIsInRole) {
            //                   check = true;
            //                 } else {
            //                   check = false;
            //                   codeError = 501;
            //                 }
            //               } else {
            //                 check = false;
            //                 if (!codeError) codeError = 404;
            //               }
            //             }
            //           }
            //         });
            //       } else {
            //         if (location?.pathname == `/admin/${subItm?.to}`) {
            //           let checkIsInRole = false;
            //           subItm &&
            //             subItm?.roles &&
            //             subItm?.roles?.map((role) => {
            //               if (!checkIsInRole)
            //                 checkIsInRole = userRoles.includes(role);
            //             });
            //           if (checkIsInRole) {
            //             check = true;
            //           } else {
            //             check = false;
            //             codeError = 501;
            //           }
            //         } else {
            //           check = false;
            //           if (!codeError) codeError = 404;
            //         }
            //       }
            //     }
            //   });
            // } else {
              if (location?.pathname == `/admin/${itm?.to}`) {
                let checkIsInRole = false;
                  if(itm?.roles(rolesRedux)==true){
                    checkIsInRole=true;
                  }
                if (checkIsInRole) {
                  check = true;
                } else {
                  check = false;
                  codeError = 501;
                }
              } else {
                check = false;
                if (!codeError) codeError = 404;
              }
            // }
          }
        });
      if (check) {
        setHasAccessToThisRoute(true);
        
        setLoading(false);
      } else {
        setHasAccessToThisRoute(false);
        setLoading(false);
        setCode(codeError);
      }
    } else if (!loading && !userRoles) {
      setCode(503);
      setHasAccessToThisRoute(false);
      setLoading(false);
    }
    setLoading(false);
  };

  if (!loading) {
    if (access_type === "admin" && hasAccessToThisRoute && token) {
      return props?.children;
    } else {
      if (code === 404)
        return <RedirectPages
          code={code}
          reason={t('ERRORS.reason_404')}
          message={t('ERRORS.message_404')}
          path={'/admin/my_favourite_pages'}
          goTo={t('ERRORS.goTo_404')}
        />
      else if (code === 501)
        return <RedirectPages
          code={code}
          reason={t('ERRORS.reason_501')}
          message={t('ERRORS.message_501')}
          path={'/admin/my_favourite_pages'}
          goTo={t('ERRORS.goTo_501')}
        />
      else if (code === 503)
        return <RedirectPages
          code={code}
          reason={t('ERRORS.reason_503')}
          message={t('ERRORS.message_503')}
          path={'/admin/my_favourite_pages'}
          goTo={t('ERRORS.goTo_503')}
          hasReload={true}
        />
    }
  } else {
    return <Preloading />;
  }
}
