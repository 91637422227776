import callAPI from '../ApiConfig'
import store from '../../../app/store'

const getFavouritePagesOfUser = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"favourite_page_users",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const getUsersOfFavouritePage = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"favourite_page_users/users",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const setFavouritePageUsersBulk = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"favourite_page_users/bulk_sync",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
} 
const setFavouritePageUsersBulkUsers = async (props) => {
    const storeState = store.getState();
    return await callAPI({
        route:"favourite_page_users/bulk_sync_users",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
} 



const favouritePageUserApi={
    getFavouritePagesOfUser:getFavouritePagesOfUser,
    getUsersOfFavouritePage:getUsersOfFavouritePage,
    setFavouritePageUsersBulk:setFavouritePageUsersBulk,
    setFavouritePageUsersBulkUsers:setFavouritePageUsersBulkUsers

}
export default favouritePageUserApi