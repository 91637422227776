import React, { useState, useEffect, useRef } from 'react'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify';
import { Box, Typography, Divider, List, ListItem, ListItemText, IconButton, FormControlLabel, Checkbox } from '@mui/material'
import orderAPI from '../../../Global/Network/Orders/index';
import { useLocation, useNavigate } from 'react-router-dom'
import Preloading from '../../../Global/Preload/Preloading';
import ContractRow from './Components/ContractRow';
import { useReactToPrint } from 'react-to-print';
import { BsPrinter } from 'react-icons/bs'
import { height } from '@mui/system';
import CloseIcon from '@mui/icons-material/Close';
import helpers from '../../../assets/js/helper';
import CustomTextField from '../../../General/CustomComponents/CustomTextField';
import logo from './assets/alrawan.png'
import draftImage from './assets/draft.png'

import swal from 'sweetalert';
import RolesOfUserAPI from '../../../Global/Network/RoleUser';
import { useSelector } from 'react-redux';
import BuyContract from './Contracts/BuyContract';
import BuyContract2 from './Contracts/BuyContract2';
import { Image } from 'react-konva';
const urlParams = new URLSearchParams(window.location.search);
const orderIdFromState = urlParams.get('order_id');

export default function OrderContract() {
  const [t] = useTranslation("common");
  const { state } = useLocation();
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false)
  const controller = new AbortController();
  const [order, setOrder] = useState(orderIdFromState)
  const printRef = useRef();
  const [isDraft, setIsDraft] = useState(true);
  const [orderId, setOrderId] = useState(null);
  const rolesRedux = useSelector(state => state.rolesData.roles)

  const handlePrint = useReactToPrint({

    // pageStyle: `@page {
    //       size: 210mm 297mm;
    //       }
    //       @media print {
    //       @page {  size: a4 portrait;
    //           margin: 20mm !important;
    //       }
    //       @media all {
    //                       .pagebreak {
    //                         overflow: visible; 
    //                       }
    //                   }
    //               }
    //           }
    //           @media print {
    //             .watermark {
    //               position: fixed;
    //               top: 50vh;
    //               z-index: 9;
    //               width: 50vw;
    //               page-break-after: always;
    //               left: 50%;
    //               transform: translate(-50%, -50%);
    //               opacity: .1;
    //             }
    //             div.page-footer {
    //               position: fixed;
    //               z-index: 9;
    //               bottom: 0;
    //               width: 100%;
    //               height: 50px;
    //               font-size: 15px;
    //               color: #fff;
    //               background: red;
    //               opacity: 0.5;
    //               page-break-after: always;
    //             }
    //           }
    //           `,
    // content: () => printRef.current
    content: () => {
      const tableStat = printRef.current.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header =
        `<img src="${!(order?.bank_name || order?.bank_branch || order?.contract_number) && isDraft ? draftImage : logo}" alt="" class="watermark"/>`;
      //  +
      // `<div class="pageFooter">I'm The Footer</div>` +
      // `<div class="page-header">I'm The Header</div>`;
      PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });

  useEffect(() => {
    document.body.style.overflow = 'none'
    let abortController = new AbortController();
    console.log("order state", state);
    loadData()

    return () => {
      abortController.abort();
      document.body.style.overflow = 'auto'
    }

  }, [])

  const loadData = async () => {
    setLoading(true)

    const result = await orderAPI.order({
      params: {
        id: orderIdFromState ? orderIdFromState : state?.data?.order?.id
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      if (result?.data?.contract_number) {
        setContractNumber({
          ...contractNumber,
          value: result?.data?.contract_number
        })
      }
      if (result?.data?.bank_name) {
        setBankName({
          ...bankName,
          value: result?.data?.bank_name
        })
      }
      if (result?.data?.bank_branch) {
        setBankBranch({
          ...contractNumber,
          value: result?.data?.bank_branch
        })
      }
      setOrder(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }

  const getName = () => {
    let customer = order?.customer
    // let title = "السيد"
    // if (customer?.user?.gender) {
    //   title = "السيدة"
    //   if (customer?.marital_status_id == 2) {
    //     title = "الانسة"
    //   }
    // }
    return (customer?.user?.full_name ? customer?.user?.full_name : "")
  }
  const getAddress = (lineNumber) => {
    let customerAddress = order?.customer?.addresses?.length > 0 ? order?.customer?.addresses[0] : null
    if (!customerAddress) {
      return
    }
    if (lineNumber == 1) {
      return customerAddress?.name ? customerAddress?.name : ""
    }
    else if (lineNumber == 2) {
      return (customerAddress?.address ? "\n" + customerAddress?.address : "")
    }
    else if (lineNumber == 3) {
      return (customerAddress?.first_phone ? "\nموبايل " + customerAddress?.first_phone : "")
    }

    return ""

  }

  const getUnitPriceFormated = () => {
    let unitPrice = order?.discounted_price
    let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

    return "(" + unitPrice?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(unitPrice, unitCurrencyCode)
  }
  const getUnitPaymentTypeDetailFormated = (paymentTypeDetail) => {
    let paymentSentence = ""
    if (paymentTypeDetail?.percentage_absolute == 0)//percentage
    {
      paymentSentence = paymentTypeDetail?.value + "% من ثمن الوحدة السكنية";
    }
    else {
      let paymentValue = paymentTypeDetail?.value
      let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

      paymentSentence = "(" + paymentValue?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(paymentValue, unitCurrencyCode)
    }
    return paymentSentence

  }
  const getFirstPaymentValueFormated = () => {
    if (order?.payment_type?.payment_type_details?.length > 0) {
      let sortedPaymentTypeDetails = order?.payment_type?.payment_type_details
      sortedPaymentTypeDetails?.sort((a, b) => a?.days_duration_from_contract - b?.days_duration_from_contract)
      return getUnitPaymentTypeDetailFormated(sortedPaymentTypeDetails[0])
    }
    else {
      return ""
    }
  }
  const getDaysDurationFromContractFormated = (days) => {
    return " " + days + " يوم على توقيع العقد"
  }

  const [bankName, setBankName] = useState({
    value: "",
    message: [],
    error: false
  })
  const [bankBranch, setBankBranch] = useState({
    value: "",
    message: [],
    error: false
  })
  const [contractNumber, setContractNumber] = useState({
    value: "",
    message: [],
    error: false
  })
  const cancelOperation = () => {
    navigate(-1)
  }



  const handleSignContract = () => {
    let hasError = false
    if (!bankBranch?.value || bankBranch?.value?.trim() == 0) {
      hasError = true
      setBankBranch({
        ...bankBranch,
        error: true,
        message: ["الرجاء ادخال اسم الفرع"]
      })
    }
    else {
      setBankBranch({
        ...bankBranch,
        error: false,
        message: []
      })
    }
    if (!bankName?.value || bankName?.value?.trim() == 0) {
      hasError = true
      setBankName({
        ...bankName,
        error: true,
        message: ["الرجاء ادخال اسم البنك"]
      })
    }
    else {
      setBankName({
        ...bankName,
        error: false,
        message: []
      })
    }
    if (!contractNumber?.value || contractNumber?.value?.trim() == 0) {
      hasError = true
      setContractNumber({
        ...contractNumber,
        error: true,
        message: ["الرجاء ادخال رقم العقد"]
      })
    }
    else {
      setContractNumber({
        ...contractNumber,
        error: false,
        message: []
      })
    }
    if (hasError) {
      return
    }
    if (!isDraft) {
      swal({
        title: '?هل انت متأكد',
        text: "في حالة الطباعة سوف يتم حفظ العقد ولايمكن تغيير معلوماته؟",
        icon: 'warning',
        buttons: ["الغاء!", "نعم!"],
        dangerMode: true,
      }).then(async (willDelete) => {

        if (willDelete) {
          try {
            // setLoading(true)
            let result = null;
            if (order && order?.id) {
              let data = {
                id: order?.id,
                bank_branch: bankBranch?.value,
                bank_name: bankName?.value,
                contract_number: contractNumber?.value,
                _method: 'put'
              }
              result = await orderAPI.updateOrder({
                data: data
              });

              if (result?.status) {
                // setLoading(false)
                handlePrint()
                setOrder({
                  ...order,
                  bank_name: bankName?.value,
                  bank_branch: bankBranch?.value,
                  contract_number: contractNumber?.value
                })
              } else {
                setLoading(false)
                console.log("result?.data", result);
                toast.error(t(result?.errorMessage))
              }
              setLoading(false)
            }
          } catch (error) {
            setLoading(false)
            console.log("error", error);
            toast.error(t(error?.message))
          }
        } else {
          // alert('canceled')
        }

      })
    } else {
      handlePrint()
    }
  }


  return (
    // loading ? <Preloading /> :
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      {!orderIdFromState ? <CloseIcon size={40} color="#349CDD" sx={{ color: '#349CDD', fontSize: '45px', position: 'relative', left: '20px', cursor: 'pointer' }} onClick={cancelOperation} /> : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',

          justifyContent: 'center',
          alignItems: 'stretch',

          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px',
              lineHeight: 'normal'
            }}>
            {t('ORDERS.contract') + (order?.contract_number ? " : " + order?.contract_number : "")}
          </Typography>
        </Box>



      </Box>
      <Box style={{ width: '100%', flexDirection: 'column', display: 'flex', justifyContent: 'center', alignItems: 'flex-start' }}>
        {!(order?.bank_name || order?.bank_branch || order?.contract_number) ? <Box
          sx={{
            '& .MuiTypography-root': {
              fontFamily: 'Cairo-Bold',
            },
            marginLeft: '10px',
          }}
        >
          <FormControlLabel control={<Checkbox checked={isDraft} onChange={(e) => {
            setIsDraft(e?.target?.checked)
          }} />} label={t('ORDERS.draft')} />
        </Box> : null}
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          {(order?.bank_name || order?.bank_branch || order?.contract_number) ?
            rolesRedux?.print_contract?.value ? <IconButton
              sx={{
                borderRadius: '5px',
                backgroundColor: "#FF0000",
                // marginRight: '10px',
                width: '98.8%',
                // flex: 1
              }}
              onClick={() => {
                handlePrint()
              }}
            >
              <BsPrinter style={{ fontSize: 25, color: '#FFFFFF', }} />
            </IconButton> : null
            :

            rolesRedux?.update_order?.value && rolesRedux?.print_contract?.value ?
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '100%',

                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <CustomTextField
                  label={t('ORDERS.bank_name')}
                  value={bankName?.value}
                  error={bankName?.error}
                  message={bankName?.message}
                  readOnly={(order?.bank_name || order?.bank_branch || order?.contract_number) ? true : false}
                  onChange={(e) => {
                    setBankName({
                      value: e.target.value,
                      error: false,
                      message: []
                    })
                  }}
                  onClearClick={() => {
                    setBankName({
                      value: "",
                      error: false,
                      message: []
                    })
                  }}
                />
                <CustomTextField
                  label={t('ORDERS.bank_branch')}
                  value={bankBranch?.value}
                  error={bankBranch?.error}
                  message={bankBranch?.message}
                  readOnly={(order?.bank_name || order?.bank_branch || order?.contract_number) ? true : false}
                  onChange={(e) => {
                    setBankBranch({
                      value: e.target.value,
                      error: false,
                      message: []
                    })
                  }}
                  onClearClick={() => {
                    setBankBranch({
                      value: "",
                      error: false,
                      message: []
                    })
                  }}
                />
                <CustomTextField
                  label={t('ORDERS.contract_number')}
                  value={contractNumber?.value}
                  error={contractNumber?.error}
                  message={contractNumber?.message}
                  readOnly={(order?.bank_name || order?.bank_branch || order?.contract_number) ? true : false}
                  onChange={(e) => {
                    setContractNumber({
                      value: e.target.value,
                      error: false,
                      message: []
                    })
                  }}
                  onClearClick={() => {
                    setContractNumber({
                      value: "",
                      error: false,
                      message: []
                    })
                  }}
                />

                <IconButton
                  sx={{
                    borderRadius: '5px',
                    backgroundColor: "#FF0000",
                    marginRight: '10px',

                    flex: 1
                  }}
                  onClick={handleSignContract}
                >
                  <BsPrinter style={{ fontSize: 25, color: '#FFFFFF', }} />
                </IconButton>


              </Box> : null
          }
        </Box>

      </Box>


      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      {order.contract_template_id == 1 ?
        <BuyContract order={order} bankName={bankName} bankBranch={bankBranch} contractNumber={contractNumber} printRef={printRef} />
        : null}

      {order.contract_template_id !== 1 ?
        <BuyContract2 order={order} bankName={bankName} bankBranch={bankBranch} contractNumber={contractNumber} printRef={printRef} />
        : null}


    </Box>
  )
}
