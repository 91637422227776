 const


// host="http://iraq-gis.com:3001/"
// host="https://www.iraq-gis.com/ticket/"
//  host="http://192.168.80.166:8000/api/"
// host="http://192.168.80.4:3000/"
// host="http://192.168.80.176:8080/api/"
 host="http://127.0.0.1:8000/api/"
//  host ="http://192.168.81.51:8000/api/"
//  host ="http://192.168.0.102:8000/api/"
// host="http://mandoobeen.iraq-gps.com/api/public/api/"
// host='http://demo.data-crew.com/api/public/api/'
// host="http://jp.data-crew.com/api/public/api/"
// host="http://192.168.80.173:8080/api/"
//  const FrontEndDomain="http://dev.ticket.is/";
//  const FrontEndDomain="http://localhost:3000/";
// const FrontEndDomain="http://192.168.80.166:3000/";
//FrontEndDomain="http://192.168.80.176:8080/api/"
//  const FrontEndDomain= "http://192.168.80.159/"
 const FrontEndDomain= "http://demo.data-crew.com/"
// const FrontEndDomain= "http://jp.data-crew.com/"
// const FrontEndDomain = "http://mandoobeen.iraq-gps.com/"
//  const FrontEndDomain="http://127.0.0.1:3000/"
export const ImagePath='http://demo.data-crew.com/api/public/'
// export const ImagePath='http://192.168.80.173:8080/'



 export default host;

 export {FrontEndDomain};
