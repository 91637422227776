import { Card, CardContent, Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router';
import FavouritePageCard from './FavouritePageCard';



export default function FavouritePagesGrid({ favouritePages }) {
    const navigate = useNavigate();
    return (
        <div style={{
            flexGrow: 1,
        }}>
            <Grid container spacing={3} wrap="wrap">
                {favouritePages?.map((favouritePage, index) => {
                    return (
                        <Grid item key={index + "_favourite_page"}>
                            <FavouritePageCard
                                onClick={() => { navigate(favouritePage?.route) }}
                                name={favouritePage?.name}
                                iconName={favouritePage?.icon}
                                color={favouritePage?.color}
                            />
                        </Grid>
                    )
                })}
            </Grid>
        </div>
    );
}
