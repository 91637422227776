import { Box,TextField,Button, Autocomplete, FormControlLabel, Checkbox, IconButton} from '@mui/material'
import React,{useState,useEffect}from 'react'
import PopupForm from './PopupForm'
import {useTranslation} from 'react-i18next'
import {ContainerOfInput,ContainerOfInputFields,ContainerOfSelectField} from '../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../Global/Errors/Addresses/Addresses'
import CloseIcon from '@mui/icons-material/Close';
import AddressesAPI from '../../Global/Network/Addresses/index'
import {toast} from 'react-toastify'
import * as RiIcons from 'react-icons/ri'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import Preloading from '../../Global/Preload/Preloading';
import customerAPI from '../../Global/Network/Customer';
import CallsTypeAPI from '../../Global/Network/CallsType/index';
import CallStatusAPI from '../../Global/Network/CallStatus/index'
import call_reasonsAPI from '../../Global/Network/CallReasons/index'
import CallDestinationsAPI from '../../Global/Network/CallDestinations/index'
import helpers from '../../assets/js/helper'
import MappPicker from './MappPicker'
import GoogleMap from './GoogleMap'
import GovernorateAPI from '../../Global/Network/Governorate/index'
import CustomPhoneTextField from './CustomPhoneTextField'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CustomPhoneCodeList from './CustomPhoneCodeList'
import CustomePhoneField from '../../General/CustomComponents/CustomePhoneField'
const RenderContent=(props)=>{
    const [t]=useTranslation('common')
    const maintheme=useSelector(state=>state.themeData.maintheme);
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [anchorEl, setAnchorEl] = React.useState(null);
    const rolesRedux = useSelector(state => state.rolesData.roles)

    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };
    
    const [ErrorsObject2,setErrorsObject2]=useState(ObjectOfErrors)
    const [anchorEl2, setAnchorEl2] = React.useState(null);
    const open2 = Boolean(anchorEl2);
    const handleClick2 = (event) => {
      setAnchorEl2(event.currentTarget);
    };
    const handleClose2 = () => {
      setAnchorEl2(null);
    };

    const [ErrorsObject3,setErrorsObject3]=useState(ObjectOfErrors)
    const [anchorEl3, setAnchorEl3] = React.useState(null);
    const open3 = Boolean(anchorEl3);
    const handleClick3 = (event) => {
      setAnchorEl3(event.currentTarget);
    };
    const handleClose3 = () => {
      setAnchorEl3(null);
    };
    

    const [stateCode1,setStateCode1]=useState('')
    const [stateCode2,setStateCode2]=useState('')
    const [stateCode3,setStateCode3]=useState('')
   
    useEffect(()=>{

    },[])

    //set according to number of fields.
    const handleInputChange=(number,value,errName)=>{
      if(number==1){
          props.setName(value?.target.value)
          setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==2){
         props.setAddress(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==3){
         props.setFirstPhone(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==4){
         props.setSecondPhone(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==5){
         props.setThirdPhone(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==6){
         props.setLatitdue(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==7){
         props.setLongitude(value?.target.value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }else if(number==8){
         props.setGovernorate(value)
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
      }
    }
    //clear data according to number of fields.
    const clearInput=(number,errName)=>{
      if(number==1){
         props.setName('')
         setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==2){
        props.setAddress('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==3){
        props.setFirstPhone('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==4){
        props.setSecondPhone('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==5){
        props.setThirdPhone('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==6){
        props.setLatitdue('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==7){
        props.setLongitude('')
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }else if(number==8){
        props.setGovernorate(null)
        setErrorsObject(restCustomeObject(ErrorsObject,errName))
     }
    }
    const handleSetChanges=(name,value,errName,code)=>{
      let vl=value
      let object={
            ...props?.localObject,
            [name]:vl,
       }
      props.setLocalObject(object)
      handlePhoneChange(name,value)
      setErrorsObject(restCustomeObject(ErrorsObject,errName))
    }

    const handlePhoneChange=(name,e)=>{
      let inputtxt=e;
      var phoneno = /^\d{11}$/;
      if(name=='first_phone'){
         let object={
         ...props?.phonesError,
         first_message:'',
         first_error:false,
         }
         if(isNaN(inputtxt)){
         console.log('messgae','string');
         props.setPhonesError({
            ...props?.phonesError,
            first_message:'يجب ان يكون الهاتف رقم',
            first_phone:true,
         })
         return false
         }
   
         if(e?.length==11&&!isNaN(inputtxt))
         {
         console.log('messgae','true');
         object={
            ...props?.phonesError,
            first_message:'',
            first_error:false,
         }
         }
         else if(e?.length>=11)
         {
         console.log('messgae','false');
         object={
            ...props?.phonesError,
            first_message:'رقم الهاتف بصيغة غير صحيحة !',
            first_phone:true,
         }
         }else{
         object={
         ...props?.phonesError,
         first_message:'',
         first_phone:false,
         }
         }
         props.setPhonesError({
         ...props?.phonesError,
            ...object,
         })
      }else  if(name=='second_phone'){
         let object={
         ...props?.phonesError,
         second_message:'',
         second_error:false,
         }
         if(isNaN(inputtxt)){
         console.log('messgae','string');
         props.setPhonesError({
            ...props?.phonesError,
            second_message:'يجب ان يكون الهاتف رقم',
            second_phone:true,
         })
         return false
         }
   
         if(e?.length==11&&!isNaN(inputtxt))
         {
         console.log('messgae','true');
         object={
            ...props?.phonesError,
            second_message:'',
            frist_error:false,
         }
         }
         else if(e?.length>=11)
         {
         console.log('messgae','false');
         object={
            ...props?.phonesError,
            second_message:'رقم الهاتف بصيغة غير صحيحة !',
            second_phone:true,
         }
         }else{
         object={
         ...props?.phonesError,
         second_message:'',
         second_phone:false,
         }
         }
         props.setPhonesError({
         ...props?.phonesError,
            ...object,
         })
      }else  if(name=='third_phone'){
         let object={
         ...props?.phonesError,
         third_message:'',
         third_error:false,
         }
         if(isNaN(inputtxt)){
         console.log('messgae','string');
         props.setPhonesError({
            ...props?.phonesError,
            third_message:'يجب ان يكون الهاتف رقم',
            third_phone:true,
         })
         return false
         }
   
         if(e?.length==11&&!isNaN(inputtxt))
         {
         console.log('messgae','true');
         object={
            ...props?.phonesError,
            third_message:'',
            third_error:false,
         }
         }
         else if(e?.length>=11)
         {
         console.log('messgae','false');
         object={
            ...props?.phonesError,
            third_message:'رقم الهاتف بصيغة غير صحيحة !',
            third_phone:true,
         }
         }else{
         object={
         ...props?.phonesError,
         third_message:'',
         third_phone:false,
         }
         }
         props.setPhonesError({
         ...props?.phonesError,
            ...object,
         })
      }
    }
    
   //  useEffect(()=>{
   //    props?.setStateCode1(stateCode1)
   //    props?.setStateCode2(stateCode2)
   //    props?.setStateCode3(stateCode3)
   //  },[stateCode1,stateCode2,stateCode3])

    useEffect(()=>{
      //  if(props?.editMode&&props?.object){
      //      let first_phone=props?.object.first_phone?.split('');
      //      let second_phone=props?.object.second_phone?.split('');
      //      let third_phone=props?.object.third_phone?.split('');
      //      let p1=null,
      //          p2=null,
      //          p3=null;
      //       for(let i=0;i<first_phone?.length;i++){
               
      //       }


      //  }
    },[props?.editMode,props?.object])


    return(
        <Box className='Container-fluid position-relative'>
            <Box className="row"
              sx={{
               '& .MuiFormHelperText-root':{
                  color:'red !important'
               }
              }}
            >
               {/* MAP HERE  */}
                <div className="col-md-12">
                    <Box sx={{width:'100%',height:'250px'}}>
                        <GoogleMap
                           customeWidth={'100%'}
                           customeHeight={'400px'}
                           marks={props?.marks}
                           setMarks={props.setMarks}
                        />
                    </Box>
                </div>

                <Box className="col-12 col-sm-12 col-md-6 ">
                   <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.name?.error}
                        haswidth={true}
                    >
                       <Box
                        sx={{
                        position: "relative",
                        // margin: "5px 0",
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                        className="mt-3 orderdata"
                     >
                        <TextField
                        
                        variant="filled"
                        label={t("ADDRESSES.name")}
                        value={props?.localObject?.name?props?.localObject?.name:''}
                        onChange={(e)=>handleSetChanges('name',e?.target?.value,'name')}
                        focused
                        type={'text'}
                        className={`${ErrorsObject?.name?.error?'errors':'Mui-focused'}`}
                        />
                        {props?.localObject?.name ? (
                        <CloseIcon
                           className="closeIcon"
                           onClick={()=>handleSetChanges('name','','name')}
                        />
                        ) : null}
                     </Box>
                     {
                     ErrorsObject?.name?.error&&ErrorsObject?.name?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.name?.message&&ErrorsObject?.name?.message?.length>0?ErrorsObject?.name?.message?.map((messg)=>(
                           <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                   </ContainerOfInputFields>
                    <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.address?.error}
                        haswidth={true}
                    >
                       <Box
                        sx={{
                        position: "relative",
                        // margin: "5px 0",
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                        className="mt-3 orderdata"
                     >
                        <TextField
                           
                           variant="filled"
                           label={t("ADDRESSES.address_name")}
                           value={props?.localObject?.address?props?.localObject?.address:''}
                           onChange={(e)=>handleSetChanges('address',e?.target?.value,'address')}
                           type={'text'}
                           className={`${ErrorsObject?.address?.error?'errors':'Mui-focused'}`}
                        />
                        {props?.localObject?.address ? (
                        <CloseIcon
                           className="closeIcon"
                           onClick={()=>handleSetChanges('address','','address')}
                        />
                        ) : null}
                     </Box>
                     {
                     ErrorsObject?.address?.error&&ErrorsObject?.address?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.address?.message&&ErrorsObject?.address?.message?.length>0?ErrorsObject?.address?.message?.map((messg)=>(
                           <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                   </ContainerOfInputFields>
                   <ContainerOfSelectField
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.governorate_id?.error}
                        haswidth={true}
                   >
                     <Box
                        sx={{
                        position: "relative",
                        // margin: "10px 0",
                        marginTop:'18px',
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                        // className="mt-3 pt-0"
                     >
                        <Autocomplete
                           // disablePortal
                           
                           options={props?.governorates?props?.governorates:[]}
                           value={props?.localObject&&props?.localObject?.governorate?props?.localObject?.governorate:null}
                           onChange={(e,newValue)=>handleSetChanges('governorate',newValue,'governorate_id')}
                           getOptionLabel={(option)=>option?.name||''}
                           popupIcon={<RiIcons.RiArrowDropDownLine/>}
                           clearIcon={<CloseIcon  sx={{fontSize:'20px',color:maintheme?.iconColor}} onClick={()=>handleSetChanges('governorate',null,'governorate_id')}/>}
                           renderInput={(params) => <TextField 
                              {...params} label={t('ADDRESSES.governorates')} 
                              className=" errors" variant="filled"
                               />}
                           className={`${ErrorsObject?.governorate_id?.error}`}
                        />
                     </Box>
                     {
                     ErrorsObject?.governorate_id?.error&&ErrorsObject?.governorate_id?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.governorate_id?.message&&ErrorsObject?.governorate_id?.message?.length>0?ErrorsObject?.governorate_id?.message?.map((messg)=>(
                        <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                    </ContainerOfSelectField>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 ">
                    {/* <ContainerOfInputFields
                           mainTheme={maintheme}
                           customeWidth={"100%"}
                           hasError={ErrorsObject?.first_phone?.error}
                           haswidth={true}
                           customePaddingRight="85px"
                     >
                        <Box
                           sx={{
                           position: "relative",
                           // margin: "5px 0",
                           width: "100%",
                           backgroundColor: "#fff",
                           }}
                           className="mt-3 orderdata"
                        >
                           <TextField
                           
                           variant="filled"
                           label={t("ADDRESSES.first_phone")}
                           value={props?.localObject?.first_phone?props?.localObject?.first_phone:''}
                           onChange={(e)=>handleSetChanges('first_phone',e?.target?.value,'first_phone')}
                           type={'text'}
                           error={props?.phonesError?.first_error}
                           helperText={props?.phonesError?.first_message}
                           className={`${ErrorsObject?.first_phone?.error?'errors':'Mui-focused'}`}
                           />
                           {props?.localObject?.first_phone ? (
                           <CloseIcon
                              className="closeIcon"
                              sx={{
                                 width:'15px',
                                 height:'15px'
                              }}
                              onClick={()=>{
                                 handleSetChanges('first_phone','','first_phone')
                                 setStateCode1('')
                              }}
                           />
                           ) : null}
                           <Box 
                             sx={{
                              position: "absolute",
                              top: "30px",
                              transform: "translate(0, -50%)",
                              right: "45px",
                              width: "fit-content",
                              minWidth:'32px',
                              maxWidth:'55px',
                              cursor:'pointer',
                              height: "21px",
                              backgroundColor: "#e7e2e2",
                              color: "#000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              direction: 'rtl',
                             }}
                             
                             aria-controls={open ? 'demo-customized-menu' : undefined}
                             aria-haspopup="true"
                             aria-expanded={open ? 'true' : undefined}
                             onClick={handleClick}
                           >
                              <IconButton>
                                 {
                                 !props?.stateCode1?
                                    <ArrowDropDownIcon sx={{color:'#1E6A99'}}/>
                                    :
                                    <Box sx={{fontFamily:'Cairo-Medium',fontSize:'12px',display:'flex',flexDirection:'row',color:'#1e6a99 !important'}}>
                                       {props?.stateCode1}
                                    </Box>
                                 }
                             </IconButton>
                           </Box>

                           <CustomPhoneCodeList
                              anchorEl={anchorEl}
                              setAnchorEl={setAnchorEl}
                              open={open}
                              handleClose={handleClose}
                              setStateCode={props?.setStateCode1}
                              stateCode={props?.stateCode1}
                           />
                        </Box>
                        
                        {
                        ErrorsObject?.first_phone?.error&&ErrorsObject?.first_phone?.message?.length?
                        <Box sx={{
                           height: 'fit-content',
                           padding:'5px',
                           display: 'flex',
                           flexDirection:'column',
                           flexWrap:'wrap',
                           width:'95%',
                        }}>
                           {
                           ErrorsObject?.first_phone?.message&&ErrorsObject?.first_phone?.message?.length>0?ErrorsObject?.first_phone?.message?.map((messg)=>(
                              <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                           )):null
                           }
                        </Box>:null}
                    </ContainerOfInputFields> */}
                    {/* <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.second_phone?.error}
                        haswidth={true}
                    >
                       <Box
                        sx={{
                        position: "relative",
                        // margin: "5px 0",
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                        className="mt-3 orderdata"
                     >
                        <TextField
                       
                        variant="filled"
                        label={t("ADDRESSES.second_phone")}
                        value={props?.localObject?.second_phone?props?.localObject?.second_phone:''}
                        onChange={(e)=>handleSetChanges('second_phone',e?.target?.value,'second_phone')}
                        type={'text'}
                        error={props?.phonesError?.second_error}
                        helperText={props?.phonesError?.second_message}
                        className={`${ErrorsObject?.second_phone?.error?'errors':'Mui-focused'}`}
                        />
                        {props?.localObject?.second_phone ? (
                        <CloseIcon
                           className="closeIcon"
                           onClick={()=>
                              {
                                 handleSetChanges('second_phone','','second_phone')
                                 setStateCode2('')
                                
                              }
                              }
                        />
                        ) : null}

                           <Box 
                             sx={{
                              position: "absolute",
                              top: "30px",
                              transform: "translate(0, -50%)",
                              right: "45px",
                              width: "fit-content",
                              minWidth:'32px',
                              maxWidth:'55px',
                              cursor:'pointer',
                              height: "21px",
                              backgroundColor: "#e7e2e2",
                              color: "#000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              direction: 'rtl',
                             }}
                            
                             aria-controls={open2 ? 'demo-customized-menu' : undefined}
                             aria-haspopup="true"
                             aria-expanded={open2 ? 'true' : undefined}
                             onClick={handleClick2}
                           >
                              <IconButton>
                                 {
                                 !props?.stateCode2?
                                    <ArrowDropDownIcon sx={{color:'#1E6A99'}}/>
                                    :
                                    <Box sx={{fontFamily:'Cairo-Medium',fontSize:'12px',display:'flex',flexDirection:'row',color:'#1e6a99 !important'}}>
                                       {props?.stateCode2}
                                    </Box>
                                 }
                             </IconButton>
                           </Box>

                           <CustomPhoneCodeList
                              anchorEl={anchorEl2}
                              setAnchorEl={setAnchorEl2}
                              open={open2}
                              handleClose={handleClose2}
                              setStateCode={props?.setStateCode2}
                              stateCode={props?.stateCode2}
                           />
                     </Box>
                     {
                     ErrorsObject?.second_phone?.error&&ErrorsObject?.second_phone?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.second_phone?.message&&ErrorsObject?.second_phone?.message?.length>0?ErrorsObject?.second_phone?.message?.map((messg)=>(
                           <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                    </ContainerOfInputFields>
                    <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        hasError={ErrorsObject?.third_phone?.error}
                        haswidth={true}
                    >
                       <Box
                        sx={{
                        position: "relative",
                        // margin: "5px 0",
                        width: "100%",
                        backgroundColor: "#fff",
                        }}
                        className="mt-3 orderdata"
                     >
                        <TextField
                   
                        variant="filled"
                        label={t("ADDRESSES.third_phone")}
                        value={props?.localObject?.third_phone?props?.localObject?.third_phone:''}
                        onChange={(e)=>handleSetChanges('third_phone',e?.target?.value,'third_phone')}
                        type={'text'}
                        error={props?.phonesError?.third_error}
                        helperText={props?.phonesError?.third_message}
                        className={`${ErrorsObject?.third_phone?.error?'errors':'Mui-focused'}`}
                        />
                        {props?.localObject?.third_phone ? (
                        <CloseIcon
                           className="closeIcon"
                           onClick={()=>{
                              handleSetChanges('third_phone','','third_phone')
                              setStateCode3('')
                           }
                           }
                        />
                        ) : null}
                        
                           <Box 
                             sx={{
                              position: "absolute",
                              top: "30px",
                              transform: "translate(0, -50%)",
                              right: "45px",
                              width: "fit-content",
                              minWidth:'32px',
                              maxWidth:'55px',
                              cursor:'pointer',
                              height: "21px",
                              backgroundColor: "#e7e2e2",
                              color: "#000",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              borderRadius: "3px",
                              direction: 'rtl',
                             }}
                           
                             aria-controls={open3 ? 'demo-customized-menu' : undefined}
                             aria-haspopup="true"
                             aria-expanded={open3 ? 'true' : undefined}
                             onClick={handleClick3}
                           >
                              <IconButton>
                                 {
                                 !props?.stateCode3?
                                    <ArrowDropDownIcon sx={{color:'#1E6A99'}}/>
                                    :
                                    <Box sx={{fontFamily:'Cairo-Medium',fontSize:'12px',display:'flex',flexDirection:'row',color:'#1e6a99 !important'}}>
                                       {props?.stateCode3}
                                    </Box>
                                 }
                             </IconButton>
                           </Box>

                           <CustomPhoneCodeList
                              anchorEl={anchorEl3}
                              setAnchorEl={setAnchorEl3}
                              open={open3}
                              handleClose={handleClose3}
                              setStateCode={props?.setStateCode3}
                              stateCode={props?.stateCode3}
                           />
                     </Box>
                     {
                     ErrorsObject?.third_phone?.error&&ErrorsObject?.third_phone?.message?.length?
                     <Box sx={{
                        height: 'fit-content',
                        padding:'5px',
                        display: 'flex',
                        flexDirection:'column',
                        flexWrap:'wrap',
                        width:'95%',
                     }}>
                        {
                        ErrorsObject?.third_phone?.message&&ErrorsObject?.third_phone?.message?.length>0?ErrorsObject?.third_phone?.message?.map((messg)=>(
                           <span style={{fontFamily:'Cairo-Bold',fontSize:'14px',color:'red',height:'auto'}}>{messg}</span>
                        )):null
                        }
                     </Box>:null}
                    </ContainerOfInputFields> */}

                    <CustomePhoneField
                        value={props?.localObject?.first_phone?props?.localObject?.first_phone:''}
                        title={t("ADDRESSES.first_phone")}
                        onChange={(e) => {
                           if (!isNaN(e?.target?.value)) {
                              handleSetChanges('first_phone',e?.target?.value,'first_phone')
                           }
                        }}
                        onClearClick={() => {
                           handleSetChanges('first_phone','','first_phone')
                           setStateCode1('')
                        }}
                        haswidth={true}
                        stateCode={props?.stateCode1}
                        setStateCode={props?.setStateCode1}
                        messageStateCode={props?.phonesError?.first_message}
                        error={props?.phonesError?.first_error?props?.phonesError?.first_error:ErrorsObject?.first_phone?.error}
                        message={ErrorsObject?.first_phone?.message}
                     />
                     <CustomePhoneField
                        value={props?.localObject?.second_phone?props?.localObject?.second_phone:''}
                        title={t("ADDRESSES.second_phone")}
                        onChange={(e) => {
                           if (!isNaN(e?.target?.value)) {
                              handleSetChanges('second_phone',e?.target?.value,'second_phone')
                           }
                        }}
                        onClearClick={() => {
                           handleSetChanges('second_phone','','second_phone')
                           setStateCode2('')
                        }}
                        haswidth={true}
                        stateCode={props?.stateCode2}
                        setStateCode={props?.setStateCode2}
                        messageStateCode={props?.phonesError?.second_message}
                        error={props?.phonesError?.second_error?props?.phonesError?.second_error:ErrorsObject?.second_phone?.error}
                        message={ErrorsObject?.second_phone?.message}
                     />
                     <CustomePhoneField
                        value={props?.localObject?.third_phone?props?.localObject?.third_phone:''}
                        title={t("ADDRESSES.third_phone")}
                        onChange={(e) => {
                           if (!isNaN(e?.target?.value)) {
                              handleSetChanges('third_phone',e?.target?.value,'third_phone')
                           }
                        }}
                        onClearClick={() => {
                           handleSetChanges('third_phone','','third_phone')
                           setStateCode1('')
                        }}
                        haswidth={true}
                        stateCode={props?.stateCode3}
                        setStateCode={props?.setStateCode3}
                        messageStateCode={props?.phonesError?.third_message}
                        error={props?.phonesError?.third_error?props?.phonesError?.third_error:ErrorsObject?.third_phone?.error}
                        message={ErrorsObject?.third_phone?.message}
                     />
              
                </Box>

            </Box>
            {/* <Box
              sx={{
               position: "absolute",
               top: "0px",
               right: "0",
               display: "flex",
               alignItems: "center",
               
              }}
            >
                  <Box 
                  sx={{
                     backgroundColor: "#fff",
                     width: "fit-content",
                     minWidth:'250px',
                     height: "40px",
                     borderRadius: "3px",
                     boxShadow: "0 0 24px -2px rgb(100 100 100 / 52%)",
                     display: "flex",
                     alignItems: "center",
                     justifyContent: "space-evenly",
                     background: "linear-gradient(145deg, #ffffff, #ffffff)",
                     padding:'0 10px'
                  }}
                  >
                  <Box sx={{display:'flex',flexDirection:'row-reverse',alignItems:'center',margin:'0 10px'}}>
                     <span style={{fontFamily:'Cairo-Bold'}}>{t('COMPOUNDS.SelectMark')}</span>
                  </Box>
                  <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                     <span style={{margin:'0 3px',fontFamily:'Cairo-Bold',color:'#000'}}>{t('ADDRESSES.latitude')+' :'}</span>
                     <span style={{margin:'0 3px',fontFamily:'Cairo-Medium',color:'#000'}}>{props?.marks?.lng?props?.marks?.lng:0.0}</span>
                  </Box>
                  <Box sx={{display:'flex',flexDirection:'row',alignItems:'center'}}>
                     <span style={{margin:'0 3px',fontFamily:'Cairo-Bold',color:'#000'}}>{t('ADDRESSES.longitude')+' :'}</span>
                     <span style={{margin:'0 3px',fontFamily:'Cairo-Medium',color:'#000'}}>{props?.marks?.lat?props?.marks?.lat:0.0}</span>
                  </Box>
                     
                  </Box>
                
            </Box> */}
        </Box>
    )
}

const RenderFooter=(props)=>{
    const [t]=useTranslation('common')
    const gridtheme=useSelector(state=>state.themeData.gridtheme)
    const rolesRedux = useSelector(state => state.rolesData.roles)

    const handlePhoneChange=(name,e)=>{
      
      if(name=="first_phone"){
      let inputtxt=props?.localObject?.first_phone;
      let check=false;
       var phoneno = /^\d{11}$/;
        
         if(isNaN(inputtxt)){
         console.log('messgae','string');
         //   check=true;
         return true
         }
   
         if(props?.localObject?.first_phone?.length==11&&!isNaN(inputtxt))
         {
         console.log('messgae','true');
         check=false;
         }
         else if(props?.localObject?.first_phone?.length>=11)
         {
         console.log('messgae','false');
         check=true;
         }else{
            check=false
         }
        return check
      }else if(name=="second_phone"){
         let inputtxt=props?.localObject?.second_phone;
         let check=false;
         var phoneno = /^\d{11}$/;
          
           if(isNaN(inputtxt)){
           console.log('messgae','string');
             check=true;
           return true
           }
     
           if(props?.localObject?.second_phone?.length==11&&!isNaN(inputtxt))
           {
           console.log('messgae','true');
           check=false;
           }
           else if(props?.localObject?.second_phone?.length>=11)
           {
           console.log('messgae','false');
           check=true;
           }else{
              check=false
           }
          return check
      }else if(name=='third_phone'){
         let inputtxt=props?.localObject?.third_phone;
         let check=false;
         var phoneno = /^\d{11}$/;
          
           if(isNaN(inputtxt)){
           console.log('messgae','string');
             check=true;
           return true
           }
     
           if(props?.localObject?.third_phone?.length==11&&!isNaN(inputtxt))
           {
           console.log('messgae','true');
           check=false;
           }
           else if(props?.localObject?.third_phone?.length>=11)
           {
           console.log('messgae','false');
           check=true;
           }else{
              check=false
           }
          return check
      }
      
    }
    return(
        <Box sx={{
            display:'flex',
            alignItems:'center',
        }}>
          

              <Box sx={{display:'flex',justifyContent: 'end',alignItems: 'center',width:'100%'}}>
          <Box display="flex" >
              
                  <Button variant="outlined"
                      // spacing={2}
                      sx={{
                            width:'50% !important',
                              // flexGrow: 1, 
                              minWidth:'80px !important',
                              maxWidth:'80px !important',
                              margin: 1,
                              backgroundColor:'#f7f7f7',
                              borderColor:gridtheme?.colorWhite,
                              color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                          '&:hover':{
                            backgroundColor:'#f7f7f7',
                            borderColor:gridtheme?.colorWhite,
                            color:gridtheme?.colorblack,
                             boxShadow:'0 0 7px 1px white',
                             boxShadow:'0',


                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'

                      }}
                      className="iconeFilterClear"
                      color="secondary"
                      onClick={()=>{
                        props.setOpen(false)
                        props.setMarks(null)
                        props?.setPhonesError(null)
                    }}
                        >
                      {t('GLOBAL.close')}
                  </Button>
                  {
                     rolesRedux?.add_address?.value?
                  <Button
                      variant="contained"
                      // spacing={2} 
                      sx={{ 
                          // flexGrow: 1,
                          margin: 1,
                          width:'80px !important',
                          minWidth:'80px !important',
                          maxWidth:'80px !important',
                          '&:hover':{
                            //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                          },
                          height:'35px',
                          fontFamily:'Cairo-Bold'
                      }}
                      className="iconeFilterSearch"
                      onClick={() => {
                        let obj=props?.localObject
                        if(!props?.localObject?.first_phone){
                           toast.warn('رقم الهاتف الاول مطلوب')
                           return;
                        }
                        if(handlePhoneChange('first_phone',props?.localObject?.first_phone))
                        {
                             toast.warn('صيغة الهاتف الاول  غير صحيحة')
                             return;
                        }else if(props?.localObject?.second_phone&&handlePhoneChange('second_phone',props?.localObject?.second_phone))
                        {
                             toast.warn('صيغة الهاتف الثاني  غير صحيحة')
                             return;
                        }else if(props?.localObject?.third_phone&&handlePhoneChange('third_phone',props?.localObject?.third_phone))
                        {
                             toast.warn('صيغة الهاتف الثالث  غير صحيحة')
                             return;
                        }

                        if(
                           props?.localObject?.first_phone&&(props?.localObject?.first_phone?.length<3||props?.localObject?.first_phone?.length>11)||
                           props?.localObject?.second_phone&&(props?.localObject?.second_phone?.length<3||props?.localObject?.second_phone?.length>11)||
                           props?.localObject?.third_phone&&(props?.localObject?.third_phone?.length<3||props?.localObject?.third_phone?.length>11)

                        
                        )
                        {
                             toast.warn('رقم الهاتف يجب ان يكون اكثر من 3 ارقام واقل من 12 رقم')
                             return;
                        }

                        if(props?.localObject?.first_phone&&!props?.stateCode1)
                        {
                             toast.warn('كود الدولة للهاتف الاول مطلوب')
                             return;
                        }else if(props?.localObject?.second_phone&&!props?.stateCode2)
                        {
                           toast.warn('كود الدولة للهاتف الثاني مطلوب')
                             return;
                        }else if(props?.localObject?.third_phone&&!props?.stateCode3)
                        {
                           toast.warn('كود الدولة للهاتف الثالث مطلوب')
                             return;
                        }

                        if(props?.localObject?.first_phone&&props?.stateCode1)
                        {
                             let num1=obj.first_phone;
                               if(num1?.slice(0,1)==0){
                                 num1=num1?.slice(1)   
                               }
                              let code=props?.stateCode1?.replace('-','');
                              

                            obj={
                              ...obj,
                              first_phone:code+"-"+num1,
                            }
                        }
                        if(props?.localObject?.second_phone&&props?.stateCode2)
                        {
                           let num2=obj.second_phone;
                           if(num2?.slice(0,1)==0){
                             num2=num2?.slice(1)   
                           }
                           let code=props?.stateCode2?.replace('-','');

                           obj={
                              ...obj,
                              second_phone:code+'-'+num2,
                            }
                        }
                         if(props?.localObject?.third_phone&&props?.stateCode3)
                        {
                           let num3=obj.third_phone;
                           if(num3?.slice(0,1)==0){
                             num3=num3?.slice(1)   
                           }
                           let code=props?.stateCode3?.replace('-','');

                           obj={
                              ...obj,
                              third_phone:code+'-'+num3,
                            }
                        }

                        if(props?.localObject&&!props?.localObject?.name){
                           toast.warn('اسم الموقع مطلوب')
                           return;
                        }
                        if(props?.localObject&&!props?.localObject?.address){
                           toast.warn('يرجى تحديد العنوان')
                           return;
                        }
                        if(props?.localObject&&(!props?.localObject?.latitude||!props?.localObject?.longitude)){
                           toast.warn('يرجى تحديد الموقع على الخريطة')
                           return;
                        }

                        props.handleGetNewAddress(obj)
                        props.setLocalObject(null)
                        // props.setOpen(false)
                        props.setMarks(null)
                        props?.setPhonesError(null)
                        props?.setObject(null)
                      }} 
                      >
                      {props?.object?t('GLOBAL.update'):t('GLOBAL.Save')}
                  </Button>:null
                  }
          </Box>
      </Box>
        </Box>
    )
}


export default function Addresses(props) {
    const [t]=useTranslation('common');
    const [name,setName]=useState(null)
    const [loading,setLoading]=useState(false)
    const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)
    const [governorates,setGovernorates]=useState([])
    const controller = new AbortController();
    const [localObject,setLocalObject]=useState(null)
    const rolesRedux = useSelector(state => state.rolesData.roles)

    const [marks,setMarks]=useState({
      lat:33.33,
      lng:44.44,
    })
    const [phonesError,setPhonesError]=useState({
      first_error:false,
      first_message:'',

      second_error:false,
      second_message:'',

      third_error:false,
      third_message:'',
    })
    const [stateCode1,setStateCode1]=useState(null)
    const [stateCode2,setStateCode2]=useState(null)
    const [stateCode3,setStateCode3]=useState(null)

    const submit=async()=>{
     try{
        setLoading(true)
        let dumData=resetErrors(ErrorsObject)
        let data={
           
        }
        let result=null;
        if(props?.object&&props?.object?.id){
            data={

                _method:'put'
            }

        }else{

        }
  
        if(result?.status){
          setLoading(false)
          toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
          clearForm()
          props.setAddForm(false)
        }else{
          if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject)
              setErrorsObject(checkErrors(result.errorMessage, updatedObject))   
          }
          setLoading(false)
          toast.error(t('NETWORKMESSAGE.messageError'))
        }
        setLoading(false)
     }catch(error){
        setLoading(false)
        toast.error(error?.message)
     }
        
    }



    const clearForm=()=>{
      setStateCode1('')
      setStateCode2('')
      setStateCode3('')
    }
    useEffect(()=>{
      let abortController = new AbortController();
        if(!props?.object)
          clearForm()
         else{
            console.log('asdsaasdas=>',props?.object?.code1);
            console.log('asdsaasdas=>',props?.object?.code2);
            console.log('asdsaasdas=>',props?.object?.code3);

            setLocalObject(props?.object)
            setMarks({
               lat:props?.object?.latitude,
               lng:props?.object?.longitude,
            })
            setStateCode1(props?.object?.code1)
            setStateCode2(props?.object?.code2)
            setStateCode3(props?.object?.code3)
         }
      
      loadDataOfGovernorates()
      return () => {
         abortController.abort();
       }
    },[props?.addForm,props?.object])

    const loadDataOfGovernorates = async (searchParams) => {
        setLoading(true)
      
        const result = await GovernorateAPI.GovernorateList({
           params: {
              ...searchParams
           },
           signal: controller?.signal
        })
      
        if (result.status) {
          setGovernorates(result?.data)
        }
        else {
           if(!result?.data?.error)
           {
            toast.error(t('NETWORKMESSAGE.messageError'))
            return
           }
        }
      
    }

    useEffect(()=>{
      let compounentMounted=true;
      let object={
         ...localObject,
         latitude:marks?.lat,
         longitude:marks?.lng,
      }
      if(compounentMounted)
       setLocalObject(object)

      return()=>{
         compounentMounted=false;
       }
    },[marks])
  
    useEffect(()=>{
      if(!props?.object)
      clearForm()
    },[props?.addForm])

  return (
   <PopupForm 
   open={props.addForm} 
   setOpen={props.setAddForm}
   customeWidth={'60% !important'}
   customeHeight={'100% !important'}
   customePadding="0 !IMPORTANT"
   title={props?.object?t('ADDRESSES.updateTitle'):t('ADDRESSES.addTitle')}
   content={<RenderContent 
         open={props.addForm} 
         setOpen={props.setAddForm}
         {...props}
         governorates={governorates}
         handleGetNewAddress={props.handleGetNewAddress}
         setLocalObject={setLocalObject}
         localObject={localObject}
         marks={marks}
         setMarks={setMarks}
         setPhonesError={setPhonesError}
         phonesError={phonesError}
         setStateCode1={setStateCode1}
         stateCode1={stateCode1}
         setStateCode2={setStateCode2}
         stateCode2={stateCode2}
         setStateCode3={setStateCode3}
         stateCode3={stateCode3}
         editMode={props?.editMode}
         object={props?.object}
         setObject={props?.setObject}

   />}
   footer={<RenderFooter
      open={props.addForm} 
      setOpen={props.setAddForm}
      submit={submit}
      setLocalObject={setLocalObject}
      localObject={localObject}
      handleGetNewAddress={props.handleGetNewAddress}
      marks={marks}
      setMarks={setMarks}
      setPhonesError={setPhonesError}
      phonesError={phonesError}
      stateCode1={stateCode1}
      setStateCode2={setStateCode2}
      stateCode2={stateCode2}
      setStateCode3={setStateCode3}
      stateCode3={stateCode3}
      setObject={props?.setObject}
      object={props?.object}

  />}
  />
  )
}

