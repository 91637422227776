import React from 'react';
import ContractRow from '../Components/ContractRow';
import { Box, Typography } from '@mui/material';
import helpers from '../../../../assets/js/helper';
import { nArabicWords } from 'narabicwords'

export default function BuyContract({ order, printRef }) {

    const getName = () => {
        let customer = order?.customer
        let title = "السيد"
        if (customer?.user?.gender) {
            title = "السيدة"
            if (customer?.marital_status_id == 2) {
                title = "الانسة"
            }
        }
        return title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
    }

    function toWords(s) {
        return nArabicWords(s);
    }

    const getAddress = (lineNumber) => {
        let customerAddress = order?.customer?.addresses?.length > 0 ? order?.customer?.addresses[0] : null
        if (!customerAddress) {
            return
        }
        if (lineNumber == 1) {
            return customerAddress?.name ? customerAddress?.name : ""
        }
        else if (lineNumber == 2) {
            return (customerAddress?.address ? "\n" + customerAddress?.address : "")
        }
        else if (lineNumber == 3) {
            return (customerAddress?.first_phone ? "\nموبايل " + customerAddress?.first_phone : "")
        }

        return ""

    }

    const getUnitPriceFormated = () => {
        let unitPrice = order?.discounted_price
        let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

        return "(" + unitPrice?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(unitPrice, unitCurrencyCode)
    }

    const getUnitPaymentTypeDetailFormated = (paymentTypeDetail) => {
        let paymentSentence = ""
        if (paymentTypeDetail?.percentage_absolute == 0)//percentage
        {
            paymentSentence = paymentTypeDetail?.value + "% من ثمن الوحدة السكنية";
        }
        else {
            let paymentValue = paymentTypeDetail?.value
            let unitCurrencyCode = order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

            paymentSentence = "(" + paymentValue?.format(order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(paymentValue, unitCurrencyCode)
        }
        return paymentSentence

    }

    const getFirstPaymentValueFormated = () => {
        if (order?.payment_type?.payment_type_details?.length > 0) {
            let sortedPaymentTypeDetails = order?.payment_type?.payment_type_details
            sortedPaymentTypeDetails?.sort((a, b) => a?.days_duration_from_contract - b?.days_duration_from_contract)
            return getUnitPaymentTypeDetailFormated(sortedPaymentTypeDetails[0])
        }
        else {
            return ""
        }
    }

    const getDaysDurationFromContractFormated = (days) => {
        return " " + days + " يوم على توقيع العقد"
    }

    function formatDate(inputDate) {
    try {
        const dateObj = new Date(inputDate);
        if (isNaN(dateObj)) {
        throw new Error("Invalid date");
        }
        
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        
        return `${year}/${month}/${day}`;
    } catch (error) {
        return "Invalid date format";
    }
    }

    return (
        <Box
            sx={{
                display: 'flex',
                width: '100%',
                height: 'calc(100vh - ' + ((order?.bank_name || order?.bank_branch || order?.contract_number) ? '170px' : '250px') + ')',
                backgroundColor: "#FFFFFF",
                justifyContent: 'center',
                alignItems: 'center',
                overflow: 'scroll'
            }}
        >
            <Box
                ref={printRef}
                style={{
                    backgroundColor: "#FFFFFF",
                    width: '100%',
                    padding: '20px',
                    direction: 'rtl',
                    height: '100%',
                    // height: '842px',
                }}>
                    {console.log('sss', order)}
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    flexDirection: "column",
                    width: "100%",
                }}>
                    <Typography
                        component={'span'}
                        style={{
                            fontWeight: 900,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: 24,
                            marginTop: "20px"
                        }}>
                        بسم الله الرحمن الرحيم
                    </Typography>
                    <Typography
                        component={'span'}
                        style={{
                            fontWeight: 900,
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: 22
                        }}>
                        عقد بيع شقة سكنية
                    </Typography>
                </Box>
                <Box paddingX={"80px"}>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "24px",
                            marginBottom: "40px"
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 600,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "24px",
                            }}>
                            {"التمهيد:"}
                        </Typography>
                        <Typography
                            style={{
                                width: '100%',
                                wordWrap: 'break-word',
                                fontSize: "20px",
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                marginTop: "20px",
                                marginBottom: "10px",
                            }}
                        >
                            بما ان الطرف االول حاصل على االجازة االستثمارية المرقمة 387/2017 من هيئة استثمار
                            بغداد وعلى قانون الاستثمار المرقم 13 لسنة 2006 ونظام بيع العقارات واراضي الدولة
                            للقطاع العام ولما كان اختصاص الشركة هو تطوير الاراضي وتحويلها الى مجمعات سكنية
                            متكاملة الخدمات.
                            <br />
                            فأن الشركة المتمثلة بالطرف الاول المالكة والمنفذة للمشروع طرحت وحدات سكنية وعلى
                            مرحلتين المرحلة الاولى تتكون من 272 شقة والمرحلة الثانية من 272 وبواقع 4 ابراج لكل
                            مرحلة. يتكون كل برج من 17 طابق + سرداب علماً ان المجمع متكامل الخدمات ليوفر حياة
                            معاصرة للمواطن بالاضافة الى تأمينه بالحراسات الامنية لمدة 24 ساعة / يوم مع توفير
                            الكهرباء على مدار 24 ساعة وخدمات توصيل الغاز بنظام الانابيب الواصلة الى كل شقة
                            ومؤسسات تعليمية تحتوي على رياض اطفال ومدرسة ابتدائية نموذجية.
                        </Typography>
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"الطرف الأول البائع: "}
                        </Typography>
                        {"" + order?.unit?.company?.name + ". ممثلة برئيس " + order?.unit?.company?.name + "، إضافة لوظيفته او من يخوله."}
                        
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"الشركة: "}
                        </Typography>
                        {order?.unit?.company?.name} 
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"إسم وكيل المدير المفوض للشركة: "}
                        </Typography>
                        {order?.created_by.full_name} 
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"اسم المشروع: "}
                        </Typography>
                        {order?.unit?.compound?.name}  
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"عنوان المشروع: "}
                        </Typography>
                        {order?.unit?.compound?.addresses[0]?.name}
                    </Typography>
                </Box>
                <Box style={{ marginTop: "60px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (1)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "10px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"}>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "22px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            marginTop: "60px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"الطرف الثاني المشتري السيد: "}
                        </Typography>
                        {getName()}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"اسم المشتري الثلاثي السيد: "}
                        </Typography>
                        {getName()}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"رقم البطاقة الشخصية: "}
                        </Typography>
                        {order?.customer?.nation_id_number}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"جهة االصدار: "}
                        </Typography>
                        {/* {getName()} */}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"تاريخ االصدار: "}
                        </Typography>
                        {/* {getName()} */}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"رقم بطاقة السكن: "}
                        </Typography>
                        {order?.customer?.residential_id_number}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"عنوان بطاقة السكن: "}
                        </Typography>
                        {/* {order?.customer?.nation_id_number} */}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"رقم الموبايل: "}
                        </Typography>
                        {order?.customer?.user?.phone?.split('+964-').reverse().join('')}964+
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"العنوان الوظيفي او المهنة: "}
                        </Typography>
                        {order?.customer?.academic_achievement}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"في حال تعذر الوصول الى المشتري يتم االتصال على: "}
                        </Typography>
                        {order?.customer?.another_contact_name}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"اسم الشخص البديل: "}
                        </Typography>
                        {order?.customer?.another_contact_name}
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"رقم الشخص البديل: "}
                        </Typography>
                        {order?.customer?.another_contact_phone?.split('+').reverse().join('')}+
                    </Typography>
                    <Typography
                        style={{
                            width: '100%',
                            wordWrap: 'break-word',
                            fontSize: "20px",
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "22px",
                            marginTop: "10px",
                        }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: "22px",
                            }}>
                            {"عنوان الشخص البديل: "}
                        </Typography>
                        {/* {getName()} */}
                    </Typography>
                </Box>
                <Box style={{ marginTop: "80px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (2)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "20px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"}>
                    <ContractRow
                        title={"1-"}
                        value={`باع الطرف الاول بموجب هذا العقد الى الطرف الثاني الشقة المرقمة (${order?.unit?.name}) في الطابق المرقم (${order?.unit?.floor?.name}) في العمارة (${order?.unit?.building?.name}) في مجمع ألروان السكني لغرض السكن العائلي بما يتناسب مع غرض المشروع و بمبلغ اجمالي قدره (${order?.unit?.price}) دولار وكتابة (${toWords(order?.unit?.price)})  علماً ان مساحة الشقة السكنية ${order?.unit?.width * order?.unit?.length} م² مع الخدمات وصافي مساحة الشقة ${order?.unit?.area} م².`}
                        paddingRight={"20px"}
                        marginTop={"60px"}
                    />
                    <ContractRow
                        title={"2-"}
                        value={"يقوم الطرف الثاني بدفع مبلغ الشقة الى الطرف الاول كما يلي: "}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"أ-"}
                        value={`عند توقيع العقد يتم دفع مبلغ (30,000) دولار دفعة اولى وباقي المبلغ يقسم على 16 دفعة تدفع كل (3) أشهر ولمدة لا تتجاوز 4 سنوات من تاريخ توقيع هذا العقد.`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"ب-"}
                        value={"يقوم المشتري بدفع الدفعة الاولى الى المصرف العراقي الاسلامي وجلب فيش التوديع وتسلميها الى الموظف القانوني واتمام عملية توقيع العقد."}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"ج-"}
                        value={`في حال تأخر الطرف الثاني عن جدول تسديد
                المستحقات المالية المرفق طيا التي بذمته في
                اوقاتها المحددة لفترة تتجاوز ال(7) ايام يقوم الطرف الاول بتوجيه انذار بواسطة كاتب العدل مرة
                واحدة بوجوب دفع المستحقات المالية التي بذمته وخلال مدة اسبوع من تاريخ توجيه الانذار او
                التبليغ وعند عدم التسديد يعتبر العقد مفسوخا من تلقاء نفسه دون الحاجة الى اصدار حكم قضائي مع
                احتفاظ الشركة بحقها باعتبار المبلغ المدفوع عند توقيع العقد غير قابل للرجوع بأي شكل من الاشكال
                و في حال كان هناك دفعات مدفوعة غير الدفعة الاولية تكون الشركة غير مسؤولة عن استرجاعها
                الا في حالة بيع الشقة الى زبون ثاني و حسب تسديدات الزبون الثاني يتم الاسترجاع على ان لا
                تتحمل الشركة اي خسائر مالية بسبب بيع الشقة الى زبون ثاني .`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"د-"}
                        value={`يتحمل المشتري غرامة تأخيريه مقدارها (100) دولار عن كل يوم تأخير في تسديد الدفعات .`}
                        paddingRight={"40px"}

                    />
                    {/* {order?.payment_type?.payment_type_details?.length > 0 ?
                order?.payment_type?.payment_type_details?.map((payment_type_detail, index) => {

                    if (payment_type_detail?.days_duration_from_contract == 0) {
                        return (
                            <ContractRow
                                key={index + "_payment_details"}
                                title={helpers?.getArabicLettersNumbering(index) + ") "}
                                value={"يلتزم الطرف الثاني (المشتري) بتسديد " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " من ثمن الشقة إلى الطرف الأول (" + order?.unit?.company?.name + ") عند توقيع هذا العقد كضمان الحجز المسبق للشقة، ويودع هذا المبلغ في حساب خاص باسم " + order?.unit?.company?.name + " (مشروع " + order?.unit?.compound?.name + " السكني) لدى " + bankName?.value + "."}
                                paddingRight={"40px"}

                            />
                        )
                    }
                    else if (order?.payment_type?.payment_type_details?.length == index + 1) {
                        return (
                            <React.Fragment key={index + "_payment_details"}>
                                <ContractRow
                                    title={helpers?.getArabicLettersNumbering(index) + ") "}
                                    value={"يلتزم الطرف الثاني (المشتري) بتسديد: " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " عند استلام الوحدة السكنية"}
                                    paddingRight={"40px"}

                                />
                                <ContractRow
                                    title={helpers?.getArabicLettersNumbering(index + 1) + ") "}
                                    value={"يتم تسديد المبالغ المستحقة في الفقرات أعلاه ب" + (order?.unit?.iqd_usd == 0 ? "الدينار العراقي" : "الدولار الامريكي") + " او ما يعادله ب" + (order?.unit?.iqd_usd == 0 ? "الدولار الامريكي" : "الدينار العراقي") + " وبسعر الصرف المعتمد."}
                                    paddingRight={"40px"}

                                />
                            </React.Fragment>

                        )
                    }
                    else {
                        return (
                            <ContractRow
                                key={index + "_payment_details"}
                                title={helpers?.getArabicLettersNumbering(index) + ") "}
                                value={"يلتزم الطرف الثاني (المشتري) بتسديد: " + getUnitPaymentTypeDetailFormated(payment_type_detail) + " بعد مرور " + getDaysDurationFromContractFormated(payment_type_detail?.days_duration_from_contract)}
                                paddingRight={"40px"}

                            />
                        )
                    }

                }) : null} */}


                    {/* <ContractRow
                title={"4-"}
                value={""}
                paddingRight={"20px"}

            /> */}
                </Box>
                <Box>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px",
                        marginTop: "20px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (3)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "60px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"} marginTop={"60px"}>
                    <ContractRow
                        title={"3-"}
                        value={`يتعهد الطرف الاول بتسليم الطرف الثاني الشقة السكنية محل العقد بمدة اقصاها () من
                    تاريخ التعاقد اي من تاريخ ابرام العقد بشرط ان يكون الطرف الثاني قد ادى كل ما عليه من التزامات
                    واردة بهذا العقد.`}
                        paddingRight={"20px"}
                        

                    />
                    <ContractRow
                        title={"4-"}
                        value={`اتفق الطرفان على انه لا يجوز للطرف الثاني التصرف في الوحدة محل العقد سواء بالبيع او التنازل
                او الهبة او المطالبة بنقل الملكية او التسجيل الا بعد سداد كامل البدل او الحصول على موافقة
                تحريرية من الطرف الاول وفي حينها تطبق الفقرة (5).`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"5- "}
                        value={`لا يجوز تغيير عقد الشقة باسم مستفيد ثاني بدون موافقة الشركة الطرف الاول وفي حال وافقت الشركة على التغيير يلتزم الطرف الثاني بدفع مبلغ (2500) دولار الى الطرف الاول.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow 
                        title={"6- "}
                        value={`يلتزم الطرف الاول البائع بأن الوحدة السكنية المباعة محل هذا العقد خالية من كافة الحقوق أيا كان
                نوعها ظاهرة او خاصة مثل حقوق الامتياز والرهن والوقف والحكر وحقوق الانتفاع.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"7- "}
                        value={`في حال لم يتم تسليم مفاتيح الوحدة السكنية الى الطرف الثاني في الوقت المحدد عندها يكون الطرف
                الاول مسؤول عن دفع مبلغ من المال قدره (100) دولار عن كل يوم تأخير للطرف الثاني.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"8- "}
                        value={` يلتزم الطرف الاول بتسليم الطرف الثاني الوحدة السكنية محل العقد كاملة التشطيب وطبقا للأصول
                الفنية والمهنية وطبقا للرسومات والمواصفات الفنية المقدمة من الطرف الاول لهيئة للاستثمار بغداد
                وامانة بغداد كما ويلتزم بتنفيذ مرافق البناء المشتركة للعقار وجعلها صالحة للاستخدام على الوجه
                الاكمل بما فيه البنى التحتية (من خطوط الماء والصرف الصحي والكهرباء والحدائق العامة
                والشوارع والارصفة) ولكل مرحلة على حدة.`}
                        paddingRight={"20px"}
                    // marginTop="40px"

                    />
                </Box>
                <Box style={{ marginTop: "100px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (4)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "20px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"} marginTop={"60px"}>
                    <ContractRow
                        title={"9- "}
                        value={`يتحمل الطرف الثاني كافة الرسوم والمصاريف القانونية الخاصة بالتسجيل في دائرة التسجيل
                العقاري المختصة وعلى الطرف الثاني دفع مبلغ قدره (100) دولار لا غير كأتعاب محاماة الى
                الطرف االول مقابل اكمال الاجراءات القانونية الخاصة بتسليم السند.`}
                        paddingRight={"20px"}
                    // marginTop="60px"

                    />
                    <ContractRow
                        title={"10- "}
                        value={`بعد تسليم الشقة الى الطرف الثاني وقام بتسديد كافة الدفعات يلتزم الطرف الاول بتسجيل الشقة
                بدوائر التسجيل العقاري باسم الطرف الثاني على ان يتحمل الطرف الثاني (وحده) كافة الرسوم
                والضرائب والمصاريف المترتبة على الشقة ومن تاريخ استالمه الشقة.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"11- "}
                        value={"يقر الطرف الثاني (تحريرياً) بانه على علم تام بموقع الشقة والطابق والعمارة وقد اطلع على الرسومات الهندسية للمشروع وطابقها على ما سيتم تنفيذه في الطبيعة."}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"12- "}
                        value={`تطبق القوانين العراقية على هذا العقد وتكون المحاكم التي يقع فيها موقع العقار هي المختصة
                بالنظر في الدعاوى الناشئة عن هذا العقد.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"13- "}
                        value={`في حالة توقف اعمال تنفيذ المشروع بسبب القوة القاهرة اي بسبب اي ظرف خارج عن ارادة
                الطرف الاول والتي تشمل على سبيل المثال الحرب , اعمال الحرب و العدوان , الاعمال
                والاعتداءات االرهابية ,التلوث الشعاعي , التمرد والعصيان المدني , الاضراب , الانقلاب ,
                الكوارث الطبيعية (الاعصار و الزلزال و العواصف او الاوبئة و تفشي الامراض) التي تؤدي الى
                توقف او شبه توقف في العمل او بسبب اي قرار اداري صادر عن السلطات العراقية فيتم التوقف
                عن احتساب المدة الزمنية المنصوص عليها في هذا العقد ويتم التوقف عن تسديد الدفعات خلال هذه
                المدة.`}
                        paddingRight={"20px"}

                    />
                </Box>
                <Box style={{ marginTop: "180px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (5)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "20px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"} marginTop={"60px"}>
                    <ContractRow
                        title={"14- "}
                        value={` في حال رغبة الطرف الثاني بفسخ هذا العقد او الرجوع عنه لا يحق للطرف الثاني المطالبة
                باسترجاع الدفعة الاولى المقدمة المدفوعة عند توقيع العقد بأي شكل من الاشكال و في حال كان
                هناك دفعات مدفوعة غير الدفعة الاولية تكون الشركة غير مسؤولة عن استرجاعها الا في حال بيع
                الشقة الى زبون ثاني و حسب تسديدات الزبون الثاني يتم الاسترجاع على ان لا تتحمل الشركة اي
                خسائر مالية بسبب بيع هذه الشقة الى زبون ثاني في حال انخفاض سعر الشقة عن سعر البيع في
                هذا العقد .`}
                        paddingRight={"20px"}
                    // marginTop={"60px"}

                    />
                    <ContractRow
                        title={"15- "}
                        value={`يتفق الطرفان على شرط جزائي قدره مبلغ الدفعة المقدمة عند توقيع العقد في حال رغبة الطرف
                الثاني فسخ هذا العقد او الرجوع عنه بشرط ان يكون الطرف الثاني قد ادى ما عليه من التزامات
                وارده في هذا العقد و في حال كانت قيمة المبالغ المسددة اكثر من مبلغ المقدمة فلا يحق للطرف
                الثاني المطالبة باسترجاعها الا بعد قيام الطرف الاول ببيع الشقة الى زبون اخر بنفس سعر الشراء
                او اكثر .`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"16- "}
                        value={"تعتبر العناوين المذكورة في العقد هي العناوين الرسمية في المخاطبات والرسائل والانذارات."}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"17- "}
                        value={"في حالة قيام احد الاطراف بتغيير عنوانه يلتزم بأشعار الطرف الاخر خلال مدة 7 ايام من تاريخ تغيير العنوان."}
                        paddingRight={"20px"}

                    />
                </Box>
                <Box style={{ marginTop: "200px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (6)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "40px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"} marginTop={"60px"}>
                    <ContractRow
                        title={"18- "}
                        value={"في حالة نشوب اي نزاع بين الطرفين فيتم حله بالطرق الاتية:"}
                        paddingRight={"20px"}
                    // marginTop={"40px"}

                    />
                    <ContractRow
                        title={"أ- "}
                        value={"يتم حل النزاع وديا."}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"ب- "}
                        value={"في حالة عدم حل النزاع وديا يتم حله عن طريق التحكيم من خلال اشخاص ذوي خبرة."}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"ج- "}
                        value={"في حالة عدم اتفاق الطرفين على اختيار المحكمين يتم حل النزاع باللجوء الى المحاكم العراقية وتطبيق القوانين العراقية لحل النزاع."}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"19- "}
                        value={` يلتزم الطرف الثاني بتنفيذ كافة التعليمات الصادرة من ادارة المجمع بما يحفظ امن وسلامة
                الساكنين داخل المجمع المذكورة لاحقاً.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"20- "}
                        value={`يلتزم الطرف الثاني بإيقاف سيارته في الاماكن المحددة لإيقاف السيارات الخاصة
                بالعمارة .`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"21- "}
                        value={`يلتزم الطرف الثاني بعدم تشويه المنظر الخارجي للبناية بما فيها الاستخدام غير
                الحضاري للبالكونات مثل تعليق الملابس وغيرها.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"22- "}
                        value={`لا يجوز للطرف الثاني او خلفه العام او الخاص عمل تعديلات خارجية في الواجهات او السلالم
                او المناور او تغيير االلوان الخارجية للواجهات سواء في الجدران والبالكونات و في الشبابيك من
                جهة الخارج او الداخل.`}
                        paddingRight={"20px"}
                    // marginTop={"60px"}

                    />
                    <ContractRow
                        title={"23- "}
                        value={`لا يجوز للطرف الثاني نصب انترنيت خارجي بما يشوه منظر البناية حيث تقوم الشركة بإيصال
                خدمة الانترنيت الى داخل الشقة مقابل اجور التنصيب والاشتراك تدفع الى الشركة المجهزة بخدمة
                الانترنيت .`}
                        paddingRight={"20px"}
                    // marginTop="40px"
                    />
                </Box>
                <Box style={{ marginTop: "100px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (7)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "40px" }} />
                </Box>
                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"} marginTop={"60px"}>
                    <ContractRow
                        title={"24- "}
                        value={`لا يجوز للطرف الثاني نصب الستلايت في الشرفة او فوق العمارة لان الطرف الاول يقوم
                بنصب ستلايت مركزي بدون مقابل.`}
                        paddingRight={"20px"}
                    // marginTop="40px"

                    />
                    <ContractRow
                        title={"25- "}
                        value={`كلفة التنظيف ستكون على عاتق الطرف الثاني بكلفة قدرها لا يقل عن (60) دولار
                    تدفع شهريا للطرف الاول ويشمل اجور التنظيف ورفع النفايات وادامة الحدائق و الانارات
                    الخارجية و امن وحماية المجمع و الممرات واصلاح المصاعد وفي حالة المخالفة و عدم
                    الدفع سيتم قطع الماء والكهرباء عن الشقة.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"26- "}
                        value={`لا يسمح للطرف الثاني استخدام مولدة خاصة لان توزيع الطاقة الكهربائية من مسؤوليات
                الطرف الاول.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"27- "}
                        value={`فيما يخص المصاعد على الطرف الاول تجهيزها والقيام بصيانتها سنوياً.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"28- "}
                        value={`يشمل هذا البيع اجزاء البناء المعدة للاستعمال المشترك هي الجدران المشتركة والمدخل
                والسلالم والمصاعد والسطح وكذلك حصة من الارض المقامة عليها العمارة بنسبة تعادل
                الوحدة السكنية منسوبة الى مجموع مسطح الشقة المبنية.`}
                        paddingRight={"20px"}

                    />
                    <ContractRow
                        title={"29- "}
                        value={`يتعهد الطرف الثاني بعدم الاساءة الى سمعة الشركة بأي شكل من الاشكال او النشر
                    باي طريقة من الطرق الاعلانية كأنشاء صفحات او مجموعات على مواقع التواصل
                    الاجتماعي وغيرها بخالف ذلك يحق للطرف الاول فسخ العقد دون انذار او استصدار حكم
                    قضائي مع احتفاظ الطرف الاول بالمطالبة بالشرط الجزائي المنصوص عليه في الفقرة
                    (15) من هذا العقد وكذلك التعويضات المادية التي تلحق الشركة`}
                        paddingRight={"20px"}

                    />
                </Box>
                <Box style={{ marginTop: "140px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px"
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (8)
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', margin: "20px" }}></Typography>
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" style={{ marginTop: "40px" }} />
                </Box>

                <img src={require("../../../../assets/img/contract_header.png")} width="100%" />
                <Box paddingX={"80px"}>
                    <ContractRow
                        title={"30- "}
                        value={`اللغة والنسخ وملاحق العقد:`}
                        paddingRight={"20px"}
                        // marginTop="40px"

                    />
                    <ContractRow
                        title={"أ- "}
                        value={`تكون اللغة العربية هي اللغة المعتمدة.`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"ب- "}
                        value={`حرر هذا العقد من (9 صفحات) بـ(3) نسخ اصلاً على ان يتم تسليم كل طرف نسخة
                    من هذا العقد للعمل بموجبها.`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"*"}
                        value={`ملاحق العقد`}
                        paddingRight={"40px"}
                        marginTop={"40px"}
                    />
                    <ContractRow
                        title={"*"}
                        value={`ملحق رقم (1) المخطط الشمولي للمشروع.`}
                        paddingRight={"40px"}
                        marginTop={"20px"}

                    />
                    <ContractRow
                        title={"*"}
                        value={`ملحق رقم (2) مخطط الوحدة السكنية.`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={"*"}
                        value={`ملحق رقم (3) جدول التسديدات.`}
                        paddingRight={"40px"}

                    />
                    <ContractRow
                        title={""}
                        value={`يعتبر هذا العقد نافذا من تاريخ التوقيع عليه`}
                        paddingRight={"0px"}
                        marginTop={"40px"}

                    />
                    <ContractRow
                        title={"*"}
                        value={`يجب وضع توقيع الطرفين على كل صفحة من صفحات هذا العقد.`}
                        paddingRight={"40px"}

                    />
                </Box>
                <Box style={{ marginTop: "40px" }}>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px",
                    }}>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 24,
                            }}>
                            الطرف الاول
                            <Typography
                                style={{
                                    fontSize: 22,
                                    width: "300px"
                                }}>
                                {order?.unit?.company?.name} 
                            </Typography>
                            <Typography style={{ textAlign: 'center', margin: "10px" }}></Typography>
                        </Typography>
                        <Typography
                            component={'span'}
                            style={{
                                fontWeight: 900,
                                textAlign: 'justify',
                                textJustify: 'inter-word',
                                lineHeight: 'normal',
                                fontSize: 22
                            }}>
                            الطرف الثاني
                            <Typography style={{ textAlign: 'center', marginRight: "-70px", fontSize: "24px" }}>{getName()}</Typography>
                        </Typography>
                    </Box>
                    <Box style={{
                        display: "flex",
                        justifyContent: "space-between",
                        width: "100%",
                        paddingInline: "80px",
                        marginTop: "20px"
                    }}>
                        <Box style={{
                            display: "flex", flexDirection: "column",
                            width: "100%",
                        }}>
                            <Typography
                                component={'span'}
                                style={{
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal',
                                    fontSize: "28px",
                                }}>
                                السيد:
                                <Typography
                                    component={'span'}
                                    style={{
                                        fontWeight: 900,
                                        fontSize: "20px",
                                    }}>
                                    {order?.created_by?.full_name} </Typography>
                            </Typography>
                            <Typography
                                component={'span'}
                                style={{
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal',
                                    fontSize: "28px",
                                    marginTop: "10px"
                                }}>
                                التوقيع:
                            </Typography>
                        </Box>
                        <Box style={{
                            display: "flex", flexDirection: "column",
                            width: "70%",
                        }}>
                            <Typography
                                component={'span'}
                                style={{
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal',
                                    fontSize: "28px",
                                }}>
                                السيد:
                                <Typography
                                    component={'span'}
                                    style={{
                                        fontWeight: 600,
                                        fontSize: "18px",
                                    }}>{getName()}</Typography>
                            </Typography>

                            <Typography
                                component={'span'}
                                style={{
                                    textAlign: 'justify',
                                    textJustify: 'inter-word',
                                    lineHeight: 'normal',
                                    fontSize: "28px",
                                    marginTop: "10px"
                                }}>
                                التوقيع:
                            </Typography>
                        </Box>
                    </Box>
                    <Typography
                        style={{
                            textAlign: 'justify',
                            textJustify: 'inter-word',
                            lineHeight: 'normal',
                            fontSize: "28px",
                            marginTop: "20px",
                            marginBottom: "20px",
                            marginRight: "80px"
                        }}>
                        تاريخ تنظيم العقد: {formatDate(order?.created_at)}
                    </Typography>
                    <Box style={{width: "100%", display: "flex", justifyContent:"center"}}>
                        <Typography
                            component={'span'}
                            style={{
                                // marginTop: "40px",
                                fontSize: 24,
                            }}>
                                (9)
                        </Typography>
                    </Box>
                    <img src={require("../../../../assets/img/contract_footer.png")} width="100%" />
                </Box>
            </Box>

        </Box>
    )
}