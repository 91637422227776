import callAPI from '../ApiConfig'
import store from '../../../app/store'

const Calls = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const callCompounds = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const CallList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const AddCall = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const UpdateCall = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCall = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const disActiveFollowUp = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"calls_deactivate_followup_follow_up/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}


const CallAPI={
    Calls:Calls,
    CallList:CallList,
    AddCall:AddCall,
    UpdateCall:UpdateCall,
    deleteCall:deleteCall,
    DisActiveFollowUp:disActiveFollowUp,
    callCompounds: callCompounds,
}
export default CallAPI