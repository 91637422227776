import { useState, useEffect, Fragment } from 'react';
import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import CircularProgress from '@mui/material/CircularProgress';
import NotificationsIcon from "@mui/icons-material/Notifications";
import NotificationCard from './NotificationCard';
import { Badge, Button } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useNavigate } from "react-router-dom";
import { setNotificationsRedux, setTotalUnreadNotificationsCountRedux } from '../../reduxStore/NotificationReducer';
import notificationsAPI from '../../Global/Network/Notification';

export default function NotificationMenu() {
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true)
    const [notificationIdsLoaded, setNotificationIdsLoaded] = useState([])
    const [t] = useTranslation('common')
    const navigate = useNavigate()
    const rowsRedux = useSelector(state => {
        return state.notification.rows
    })
    const totalUnreadRedux = useSelector(state => {
        return state.notification.total_unread
    })
    const [anchorEl, setAnchorEl] = useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };


    const onNotificationForward = (notification) => {
        if (notification?.notificationable_type == "App\\Models\\Call") {
           
            window.open(
                window.location.origin +
                "/admin/calls?id=" +
                notification?.notificationable_id,
                "_blank"
            );
        }
        else if (notification?.notificationable_type == "App\\Models\\Unit") {
            window.open(
                window.location.origin +
                "/admin/view-unit?id=" +
                notification?.notificationable_id,
                "_blank"
            );
        }
    }
    const loadNotificationsData = async () => {

        try {
            setLoading(true)
            let result = await notificationsAPI.myNotifications({
                withOutGLovalLoading: true,
                params: {
                    page_size: 5,
                    page: 1
                },

            })
            setLoading(false)
            if (result?.status) {
                dispatch(setNotificationsRedux(result?.data?.data))
                setNotificationIdsLoaded(result?.data?.data?.map(item => item?.id))
                dispatch(setTotalUnreadNotificationsCountRedux(result?.data?.total_unread ? result?.data?.total_unread : 0))
            } else {

            }
        } catch (err) {
        }
    }

    const setNotificationIdsLoadedAsRead = () => {
        notificationsAPI.setMyNotificationAsRead({
            data: {
                notification_ids: notificationIdsLoaded
            },
            withOutGLovalLoading: true,

        }).then(() => {
            notificationsAPI.myUnreadNotificationsCount({
                withOutGLovalLoading: true,

            }).then((result) => {
                console.log("result_notification", result);
                if (result?.status) {
                    dispatch(setTotalUnreadNotificationsCountRedux(result?.data?.data?.total_unread))
                }
            })
        })
    }
    useEffect(() => {
        if (open)
            loadNotificationsData()
        return () => {

        }

    }, [anchorEl])
    useEffect(() => {
        if (notificationIdsLoaded?.length > 0) {
            setNotificationIdsLoadedAsRead(notificationIdsLoaded)
        }
        return () => {

        }

    }, [notificationIdsLoaded])

    return (
        <Fragment>

            <Tooltip title={<span style={{fontFamily:'Cairo-Medium'}}>{"الاشعارات"}</span>}>
                <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                    color="inherit"
                >
                    <Badge badgeContent={totalUnreadRedux} color="error">
                        <NotificationsIcon sx={{ width: 32, height: 32 }} />
                    </Badge>
                </IconButton>
            </Tooltip>

            <Menu
                anchorEl={anchorEl}
                id="account-menu"
                open={open}
                onClose={handleClose}
                onClick={handleClose}
                PaperProps={{
                    elevation: 0,
                    sx: {
                        overflow: 'visible',
                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                        mt: 1.5,
                        '& .MuiAvatar-root': {
                            width: 32,
                            height: 32,

                        },
                        '&:before': {
                            content: '""',
                            display: 'block',
                            position: 'absolute',
                            top: 0,
                            left: 14,
                            width: 10,
                            height: 10,
                            bgcolor: 'background.paper',
                            transform: 'translateY(-50%) rotate(45deg)',
                            zIndex: 0,
                        },
                    },
                }}
                transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
            >
                {loading ?
                    <MenuItem key={"notification_loading"} >
                        <Box sx={{
                            display: 'flex',
                            alignItems: 'flex-start',
                            flexDirection: 'column',

                            width: '300px'
                        }}>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '100%'

                                }}
                            >
                                <CircularProgress />
                            </Box>
                        </Box>
                    </MenuItem> :

                    rowsRedux?.length ?
                        <>
                            {rowsRedux?.map((notification, index) => {
                                return (
                                    <MenuItem key={"notification_" + index} onClick={handleClose}>
                                        <NotificationCard
                                            {...notification}
                                            onClick={() => { onNotificationForward(notification) }}

                                        />
                                    </MenuItem>
                                )
                            })}

                            <Button
                                variant="contained"
                                // spacing={2} 
                                sx={{
                                    // flexGrow: 1,

                                    width: '100%',
                                    '&:hover': {
                                        backgroundColor: "#FFFFFF",
                                        color: "#126A99A0",
                                        boxShadow: "none"
                                    },
                                    backgroundColor: "#FFFFFF",
                                    color: "#126A99",
                                    boxShadow: "none",
                                    height: '35px',
                                    fontFamily: 'Cairo-Bold'
                                }}
                                onClick={()=>{
                                    navigate('/admin/masters/notifications')
                                }}

                            >
                                {t('GLOBAL.more')}
                            </Button>
                        </> :
                        <MenuItem key={"notification_"} onClick={handleClose}>
                            <NotificationCard

                            />
                        </MenuItem>
                }

            </Menu>
        </Fragment>
    );
}