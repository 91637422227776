import { Box, Button, ListItemIcon, MenuItem, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import Grid from "../../Grid/Grid";
import DropDownGrid from "../../Grid/Components/DropDownGrid";
import { AiTwotonePrinter } from "react-icons/ai";
import helpers from "../../../assets/js/helper";
import PaymentsAPI from "../../../Global/Network/Payment";
import { toast } from "react-toastify";
import PaymentPrintPopup from "../Invoices/PaymentPrintPopup";
import ObjectOfErrors, {
  checkErrors,
  resetErrors,
  restObject,
} from "../../../Global/Errors/CompoundDepartmentPhone/CompoundDepartmentPhone";
import RolesOfUserAPI from "../../../Global/Network/RoleUser";
import CompoundDepartmentPhoneAPI from "../../../Global/Network/CompoundDepartmentPhone/index";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomeSelectField from "../../../General/CustomComponents/CustomeSelectField";
import CustomePhoneField from "../../../General/CustomComponents/CustomePhoneField";
import swal from "sweetalert";
import SearchInput from "../../Components/SearchInput";
import SearchDate from "../../Components/SearchDate";

const RenderSearchContent = (props) => {
  const [t] = useTranslation("common");
  console.log("user", props?.users);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.objectName}
        setValue={props?.setobjectName}
        title={t("COMPOUNDDEPARTMENTS.phone")}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t("COMPOUNDDEPARTMENTS.work_fromDate_search")}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t("COMPOUNDDEPARTMENTS.work_toDate_search")}
        minDate={props?.fromDate}
        maxDate={null}
      />

    </Box>
  );
};

export default function DepartmentPhoneNumbers(props) {
  const [t] = useTranslation("common");
  const controller = new AbortController();
  const gridtheme = useSelector((state) => state.themeData.gridtheme);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [rowsTotal, setRowsTotal] = useState(0);
  const [rows, setRows] = useState([]);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const [selectedObject, setSelectedObject] = useState(null);
  const [openPaymentPrint, setOpenPaymentPrint] = useState(false);
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const [phone, setPhone] = useState("");
  const [code, setCode] = useState("");
  const [searchPhoneNumber, setSearchPhoneNumber] = useState("");
   const [fromDate,setFromDate]=useState(null)
 const [toDate,setToDate]=useState(null)
 const [filterParams,setFilterParams]=useState({})

  const [phoneError, setPhoneError] = useState({
    err: false,
    mes: "",
  });
  const [selectedDepartment, setSelectedDepartment] = useState(null);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const [addForm, setAddForm] = useState(false);

  const [paidAmount, setPaidAmount] = useState({
    value: "",
    message: [],
    error: false,
  });
  const columns = [
    {
      headerName: t("COMPOUNDDEPARTMENTS.department_phone_id"),
      field: "id",
      flex: 0.5,
      minWidth: 50,
      maxWidth: 150,
      hide: false,
    },
    {
      headerName: t("COMPOUNDDEPARTMENTS.department_phone"),
      field: "phone",
      flex: 2,
      minWidth: 350,
      maxWidth: 450,
      hide: false,
      renderCell: (rows) => <Typography>{rows?.row?.phone}</Typography>,
    },
    {
      headerName: t("COMPOUNDDEPARTMENTS.created_at"),
      field: "created_at",
      flex: 2,
      minWidth: 250,
      maxWidth: 450,
      hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <span
            style={{ fontFamily: "Cairo-Medium" }}
            className="gridHeaderItem"
          >
            {rows?.row?.created_at
              ? helpers.formatDate(rows?.row?.created_at)
              : "0000-00-00"}
          </span>
          <span
            style={{ fontFamily: "Cairo-Medium" }}
            className="gridHeaderItem"
          >
            {rows?.row?.created_at
              ? helpers.formatTime(rows?.row?.created_at)
              : "00:00:00"}
          </span>
        </Box>
      ),
    },
    {
      headerName: t("INVOICES.actions"),
      field: "Actions",
      flex: 0.4,
      minWidth: 100,
      maxWidth: 250,
      hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {rolesRedux?.update_compound_department_phone?.value ? (
            <MenuItem
              onClick={() => {
                updateFunction(rows?.row);
              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <EditIcon style={{ fill: "#1E6A99" }} />
              </ListItemIcon>
              {t("GLOBAL.action_edit")}
            </MenuItem>
          ) : null}
          {rolesRedux?.delete_compound_department_phone?.value ? (
            <MenuItem
              onClick={() => {
                deleteFunction(rows?.row);
              }}
              className="ActionIcons"
            >
              <ListItemIcon
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  margin: "0px 7px 0 0",
                }}
              >
                <DeleteIcon style={{ fill: "#f00" }} />
              </ListItemIcon>
              {t("GLOBAL.action_delete")}
            </MenuItem>
          ) : null}
          {rolesRedux?.update_compound_department?.value == false &&
          rolesRedux?.delete_compound_department?.value == false ? (
            <p style={{ fontFamily: "Cairo", margin: "5px 20px" }}>
              {t("GLOBAL.emptyPoPup")}
            </p>
          ) : null}
        </DropDownGrid>
      ),
    },
  ];

  useEffect(() => {
    let abortController = new AbortController();
    if (props?.object?.id) loadData();

    return () => {
      abortController.abort();
    };
  }, [pageNumber, pageSize, props?.addForm, props?.object]);

  useEffect(() => {}, [props?.object]);
  const loadData = async (searchParams) => {
    if (!props?.object?.id) {
      toast.warn(" معرف قسم المجمع مطلوب");
      return;
    }
    setLoading(true);

    const result = await CompoundDepartmentPhoneAPI.compoundDepartmentPhones({
      params: {
        page_size: pageSize,
        page: pageNumber,
        compound_department_id: props?.object?.id,
        ...searchParams,
      },
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      setRows(result?.data?.data);
      setRowsTotal(result?.data?.total);
      setPageCount(result?.data?.last_page);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const submit = async () => {
    if (!props?.object?.id) {
      toast.warn(" معرف قسم المجمع مطلوب");
      return;
    }
    if (!phone) {
      toast.warn("رقم الهاتف مطلوب");
      return;
    }
    if (!code) {
      toast.warn("رمز الدولة للهاتف مطلوب");
      return;
    }
    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);
      let FinalPhone = helpers.handleMergePhoneWithStateCode(phone, code);
      let data = {
        phone: FinalPhone,
        compound_department_id: props?.object?.id,
      };
      let result = null;

      if (selectedObject) {
        result = await CompoundDepartmentPhoneAPI.updateCompoundDepartmentPhone(
          {
            data: {
              id: selectedObject?.id,
              ...data,
            },
          }
        );
      } else {
        result = await CompoundDepartmentPhoneAPI.addCompoundDepartmentPhone({
          data: data,
        });
      }
      if (result?.status) {
        setLoading(false);
        setAddForm(false);
        setSelectedObject(null);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        setPaidAmount({
          value: "",
          error: false,
          message: [],
        });
        loadData();
      } else {
        if (typeof result.errorMessage === "object") {
          let updatedObject = resetErrors(ErrorsObject);
          setErrorsObject(checkErrors(result.errorMessage, updatedObject));
        } else if (result?.errorMessage) {
          toast.error(t(result.errorMessage));
        } else {
          toast.error(t("NETWORKMESSAGE.messageError"));
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const clearForm = () => {
    handleSetChanges("");
  };

  const handleSetChanges = (value) => {
    let vl = value;
    setPhone(value);
    handlePhoneChange(value);
    // setErrorsObject(resetErrors(ErrorsObject,'phone'))
  };

  const handlePhoneChange = (e) => {
    let inputtxt = e;
    var phoneno = /^\d{11}$/;
    let object = {
      mes: "",
      err: false,
    };
    if (isNaN(inputtxt)) {
      setPhoneError({
        mes: "يجب ان يكون الهاتف رقم",
        err: true,
      });
      return false;
    }

    if (e?.length == 11 && !isNaN(inputtxt)) {
      object = {
        ...phoneError,
        mes: "",
        err: false,
      };
    } else if (e?.length >= 11) {
      object = {
        ...phoneError,
        mes: "رقم الهاتف بصيغة غير صحيحة !",
        err: true,
      };
    } else {
      object = {
        ...phoneError,
        mes: "",
        err: false,
      };
    }
    setPhoneError({
      ...phoneError,
      ...object,
    });
  };

  const updateFunction = (obj) => {
    setSelectedObject(obj);
    setAddForm(true);
  };

  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setLoading(true);

          let result = null;
          if (obj && obj?.id) {
            result =
              await CompoundDepartmentPhoneAPI.deleteCompoundDepartmentPhone({
                data: {
                  id: obj.id,
                },
              });
          }

          if (result?.status) {
            setLoading(false);
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber,
            });
          } else {
            setLoading(false);
            if (result?.errorMessage) {
              toast.error(result?.errorMessage);
            } else toast.error(t("NETWORKMESSAGE.deleteErrorr"));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };
  useEffect(() => {
    if (selectedObject && selectedObject?.phone) {
      let selectedPhone = helpers.handleSplitPhoneWithStateCode(
        selectedObject?.phone
      );
      setPhone(selectedPhone?.phone ? selectedPhone?.phone : "");
      setCode(selectedPhone?.code ? selectedPhone?.code : "");
    }
  }, [selectedObject]);

  const clearFunction=()=>{
   setSearchPhoneNumber('')
   setFromDate(null)
   setToDate(null)
  }
  const searchFunction=()=>{
    let data={};
    if(searchPhoneNumber){
      data={
       ...data,
       phone:searchPhoneNumber
      }
    }
    if(fromDate){
      data={
      ...data,
      creation_date_from:helpers.formatDate(fromDate)
      }
   }
   if(toDate){
    data={
    ...data,
    creation_date_to:helpers.formatDate(toDate)
    }
  }
    setFilterParams(data)
    loadData(data)
  }
  return (
    <>
      <PopupForm
        open={props.addForm}
        setOpen={props.setAddForm}
        customePadding="0"
        customeWidth={"100%"}
        customeHeight={"100%"}
        isFullScreen={true}
        title={t("COMPOUNDDEPARTMENTS.add_phone_number")}
        content={
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                width: "100%",
                gap: "5px",
                padding: "0 20px",
                margin: "0 0 35px 0",
              }}
            >
              <Box sx={{ width: "79.2%" }}>
                <CustomePhoneField
                  value={phone}
                  title={t("COMPOUNDDEPARTMENTS.phone")}
                  onChange={(e) => {
                    if (!isNaN(e?.target?.value)) {
                      handleSetChanges(e?.target?.value);
                    }
                  }}
                  onClearClick={() => {
                    handleSetChanges("");
                    setCode("");
                  }}
                  haswidth={true}
                  stateCode={code}
                  setStateCode={setCode}
                  messageStateCode={phoneError?.mes}
                  error={
                    ErrorsObject?.phone?.error
                      ? ErrorsObject?.phone?.error
                      : phoneError?.err
                  }
                  message={ErrorsObject?.phone?.message}
                />
              </Box>
              <Box
                sx={{
                  width: "20%",
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1e6a99",
                    width: "fit-content",
                    minWidth:'80%',
                    height: "55px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 20px",
                    marginLeft: 12,
                    cursor: "pointer",
                  }}
                  onClick={submit}
                >
                  <span
                    style={{
                      fontFamily: "Cairo",
                      color: "#fff",
                      fontSize: "18px",
                    }}
                  >
                    {t("COMPOUNDDEPARTMENTS.addPhone")}
                  </span>
                </Box>
              </Box>
            </Box>
            <Grid
              rows={rows}
              columns={columns}
              hideFilter={false}
              pageSize={pageSize}
              setPageSize={setPageSize}
              pageNumber={pageNumber}
              setPageNumber={setPageNumber}
              loading={loading}
              pageCount={pageCount}
              handlePageChange={handlePageChange}
              rowsTotal={rowsTotal}
              clearFunction={clearFunction}
              searchFunction={searchFunction}
              filterChilds={
                <RenderSearchContent
                  objectName={searchPhoneNumber}
                  setobjectName={setSearchPhoneNumber}
                  fromDate={fromDate}
                  setFromDate={setFromDate}
                  toDate={toDate}
                  setToDate={setToDate}
                />
              }
            />
          </Box>
        }
        footer={
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setAddForm(false);
              clearForm();
            }}
          >
            {t("GLOBAL.close")}
          </Button>
        }
      />
      <PopupForm
        open={addForm}
        setOpen={setAddForm}
        // customePadding="0"
        // customeWidth={"70%"}
        // customeHeight={"50%"}
        // isFullScreen={false}
        title={t("COMPOUNDDEPARTMENTS.update_phone_number")}
        content={
          <Box sx={{ width: "100%" }}>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "stretch",
                width: "100%",
                gap: "5px",
                padding: "0 20px",
                margin: "0 0 15px 0",
              }}
            >
              <Box sx={{ width: "100%" }}>
                <CustomePhoneField
                  value={phone}
                  title={t("COMPOUNDDEPARTMENTS.phone")}
                  onChange={(e) => {
                    if (!isNaN(e?.target?.value)) {
                      handleSetChanges(e?.target?.value);
                    }
                  }}
                  onClearClick={() => {
                    handleSetChanges("");
                    setCode("");
                  }}
                  haswidth={true}
                  stateCode={code}
                  setStateCode={setCode}
                  messageStateCode={phoneError?.mes}
                  error={
                    ErrorsObject?.phone?.error
                      ? ErrorsObject?.phone?.error
                      : phoneError?.err
                  }
                  message={ErrorsObject?.phone?.message}
                />
              </Box>
              {/* <Box
                sx={{
                  width: "40%",
                  paddingTop: "20px",
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "center",
                }}
              >
                <Box
                  sx={{
                    backgroundColor: "#1e6a99",
                    width: "fit-content",
                    height: "55px",
                    borderRadius: "5px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    padding: "10px 20px",
                    marginLeft: 12,
                    cursor: "pointer",
                  }}
                  onClick={submit}
                >
                  <span
                    style={{
                      fontFamily: "Cairo",
                      color: "#fff",
                      fontSize: "18px",
                    }}
                  >
                    {t("COMPOUNDDEPARTMENTS.addPhone")}
                  </span>
                </Box>
                
              </Box> */}
            </Box>
          </Box>
        }
        footer={
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Button
              variant="outlined"
              // spacing={2}
              sx={{
                width: "50% !important",
                // flexGrow: 1,
                minWidth: "80px !important",
                maxWidth: "80px !important",
                margin: 1,
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px -2px white",
                //   color:SearchButtonTheme?.clear_button_text_color,
                "&:hover": {
                  backgroundColor: "#f7f7f7",
                  borderColor: gridtheme?.colorWhite,
                  color: gridtheme?.colorblack,
                  boxShadow: "0 0 7px 1px white",
                  boxShadow: "0",
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterClear"
              color="secondary"
              onClick={() => {
                setAddForm(false);
                clearForm();
                setSelectedObject(null);
              }}
            >
              {t("GLOBAL.close")}
            </Button>
            <Button
              variant="contained"
              // spacing={2}
              sx={{
                // flexGrow: 1,
                margin: 1,
                width: "80px !important",
                minWidth: "80px !important",
                maxWidth: "80px !important",
                "&:hover": {
                  //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                },
                height: "35px",
                fontFamily: "Cairo-Bold",
              }}
              className="iconeFilterSearch"
              onClick={submit}
            >
              {t("GLOBAL.Save")}
            </Button>
          </Box>
        }
      />
    </>
  );
}
