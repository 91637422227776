import { Box, Button, Typography } from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import PopupForm from "../../../Components/PopupForm";
import MessageCard from "../../Calls/CommunicationsTabComponents/MessageCard";

const RenderContent = ({ setOpen, callCompounds }) => {
  const [t] = useTranslation("common");
  return (
    <Box className="Container-fluid">
      <Box
        sx={{
          flex: 1,
          width: "100%",
          display: "flex",
          justifyContent: "center",
          alignItems: callCompounds?.length ? "" : "center",
          flexDirection: "column",
          rowGap: "10px",
          paddingTop: 2,
        }}
      >
        {callCompounds?.length ? (
          callCompounds?.map((call, index) => {
            return (
              <MessageCard
                {...call}
                itm={call}
                deleteFunction={null}
                updateFunction={null}
                submitNeedFollowUp={null}
                fontCompoundGrid={true}
                // {...props}
              />
            );
          })
        ) : (
          <Typography sx={{ fontFamily: "Cairo" }}>لا توجد اتصالات</Typography>
        )}
      </Box>
    </Box>
  );
};
const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          display="flex"
          sx={{ direction: "rtl", justifyContent: "flex-end", width: "100%" }}
        >
          <Button
            variant="outlined"
            sx={{
              width: "50% !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setCallCompounds([]);
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.cancel_button")}
          </Button>
          {/* <Button
            variant="contained"
            sx={{
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={props?.submit}
          >
            {t("GLOBAL.capture")}
          </Button> */}
        </Box>
      </Box>
    </Box>
  );
};

export default function CallCompounds(props) {
  const [t] = useTranslation("common");

  return (
    <PopupForm
      width={"700px"}
      open={props.addForm}
      setOpen={props.setAddForm}
      customePadding="10px 20px !important"
      //   isFullScreen={true}
      title={t("SIDEMENU.calls")}
      content={
        <RenderContent
          open={props.addForm}
          setOpen={props.setAddForm}
          callCompounds={props?.callCompounds}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          setCallCompounds={props?.setCallCompounds}
          //   submit={submit}
        />
      }
    />
  );
}
