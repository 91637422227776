import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roles: {
    user_profile: {
      value: true,
      id: 2000,
    },
    dashboard: {
      value: false,
      id: 1,
    },
    add_user: {
      value: false,
      id: 2,
    },
    view_user: {
      value: false,
      id: 3,
    },
    update_user: {
      value: false,
      id: 4,
    },
    delete_user: {
      value: false,
      id: 5,
    },
    add_role: {
      value: false,
      id: 6,
    },
    view_role: {
      value: false,
      id: 7,
    },
    update_role: {
      value: false,
      id: 8,
    },
    delete_role: {
      value: false,
      id: 9,
    },
    add_role_to_user: {
      value: false,
      id: 10,
    },
    view_users_role: {
      value: false,
      id: 11,
    },
    update_user_roles: {
      value: false,
      id: 12,
    },
    delete_a_role_from_user: {
      value: false,
      id: 13,
    },
    add_customer: {
      value: false,
      id: 14,
    },
    view_customer: {
      value: false,
      id: 15,
    },
    update_customer: {
      value: false,
      id: 16,
    },
    delete_customer: {
      value: false,
      id: 17,
    },
    add_employee: {
      value: false,
      id: 18,
    },
    view_employee: {
      value: false,
      id: 19,
    },
    update_employee: {
      value: false,
      id: 20,
    },
    delete_employee: {
      value: false,
      id: 21,
    },
    view_available_compounds: {
      value: false,
      id: 22,
    },
    view_contracts_documents: {
      value: false,
      id: 23,
    },
    view_analytics: {
      value: false,
      id: 24,
    },
    view_registered_customers: {
      value: false,
      id: 25,
    },
    view_reservations_and_appointments: {
      value: false,
      id: 26,
    },
    view_alert_and_notification: {
      value: false,
      id: 27,
    },
    view_payments_and_reimbursement: {
      value: false,
      id: 28,
    },
    view_worktype: {
      value: false,
      id: 29,
    },
    view_employee_table: {
      value: false,
      id: 30,
    },
    view_purpose_of_purshases: {
      value: false,
      id: 31,
    },
    view_marital_status: {
      value: false,
      id: 32,
    },
    view_customer_type: {
      value: false,
      id: 33,
    },
    view_contact_method: {
      value: false,
      id: 34,
    },
    view_national_id_types: {
      value: false,
      id: 35,
    },
    add_worktype: {
      value: false,
      id: 36,
    },
    update_worktype: {
      value: false,
      id: 37,
    },
    delete_worktype: {
      value: false,
      id: 38,
    },
    export_worktype: {
      value: false,
      id: 39,
    },
    add_employee_table: {
      value: false,
      id: 40,
    },
    update_employee_table: {
      value: false,
      id: 41,
    },
    delete_employee_table: {
      value: false,
      id: 42,
    },
    export_employee_table: {
      value: false,
      id: 43,
    },
    add_purpose_of_purshases: {
      value: false,
      id: 44,
    },
    update_purpose_of_purshases: {
      value: false,
      id: 45,
    },
    delete_purpose_of_purshases: {
      value: false,
      id: 46,
    },
    export_purpose_of_purshases: {
      value: false,
      id: 47,
    },
    add_marital_status: {
      value: false,
      id: 48,
    },
    update_marital_status: {
      value: false,
      id: 49,
    },
    delete_marital_status: {
      value: false,
      id: 50,
    },
    export_marital_status: {
      value: false,
      id: 51,
    },
    add_customer_type: {
      value: false,
      id: 52,
    },
    update_customer_type: {
      value: false,
      id: 53,
    },
    delete_customer_type: {
      value: false,
      id: 54,
    },
    export_customer_type: {
      value: false,
      id: 55,
    },
    add_contact_method: {
      value: false,
      id: 56,
    },
    update_contact_method: {
      value: false,
      id: 57,
    },
    delete_contact_method: {
      value: false,
      id: 58,
    },
    export_contact_method: {
      value: false,
      id: 59,
    },
    add_national_id_types: {
      value: false,
      id: 60,
    },
    update_national_id_types: {
      value: false,
      id: 61,
    },
    delete_national_id_types: {
      value: false,
      id: 62,
    },
    export_national_id_types: {
      value: false,
      id: 63,
    },
    view_real_estate: {
      value: false,
      id: 64,
    },
    add_real_estate: {
      value: false,
      id: 65,
    },
    update_real_estate: {
      value: false,
      id: 66,
    },
    delete_real_estate: {
      value: false,
      id: 67,
    },
    export_real_estate: {
      value: false,
      id: 68,
    },
    view_call_type: {
      value: false,
      id: 69,
    },
    add_call_type: {
      value: false,
      id: 70,
    },
    update_call_type: {
      value: false,
      id: 71,
    },
    delete_call_type: {
      value: false,
      id: 72,
    },
    export_call_type: {
      value: false,
      id: 73,
    },
    view_call_status: {
      value: false,
      id: 74,
    },
    add_call_status: {
      value: false,
      id: 75,
    },
    update_call_status: {
      value: false,
      id: 76,
    },
    delete_call_status: {
      value: false,
      id: 77,
    },
    export_call_status: {
      value: false,
      id: 78,
    },
    view_call_destinations: {
      value: false,
      id: 79,
    },
    add_call_destinations: {
      value: false,
      id: 80,
    },
    update_call_destinations: {
      value: false,
      id: 81,
    },
    delete_call_destinations: {
      value: false,
      id: 82,
    },
    export_call_destinations: {
      value: false,
      id: 83,
    },
    view_call_reasons: {
      value: false,
      id: 84,
    },
    add_call_reasons: {
      value: false,
      id: 85,
    },
    update_call_reasons: {
      value: false,
      id: 86,
    },
    delete_call_reasons: {
      value: false,
      id: 87,
    },
    export_call_reasons: {
      value: false,
      id: 88,
    },
    view_unit_location: {
      value: false,
      id: 89,
    },
    add_unit_location: {
      value: false,
      id: 90,
    },
    update_unit_location: {
      value: false,
      id: 91,
    },
    delete_unit_location: {
      value: false,
      id: 92,
    },
    export_unit_location: {
      value: false,
      id: 93,
    },
    view_room_type: {
      value: false,
      id: 94,
    },
    add_room_type: {
      value: false,
      id: 95,
    },
    update_room_type: {
      value: false,
      id: 96,
    },
    delete_room_type: {
      value: false,
      id: 97,
    },
    export_room_type: {
      value: false,
      id: 98,
    },
    export_role: {
      value: false,
      id: 99,
    },
    view_image_type: {
      value: false,
      id: 100,
    },
    add_image_type: {
      value: false,
      id: 101,
    },
    update_image_type: {
      value: false,
      id: 102,
    },
    delete_image_type: {
      value: false,
      id: 103,
    },
    export_image_type: {
      value: false,
      id: 104,
    },
    view_calls: {
      value: false,
      id: 105,
    },
    add_calls: {
      value: false,
      id: 106,
    },
    update_calls: {
      value: false,
      id: 107,
    },
    delete_calls: {
      value: false,
      id: 108,
    },
    export_calls: {
      value: false,
      id: 109,
    },
    view_customer_master: {
      value: false,
      id: 110,
    },
    view_customer_units: {
      value: false,
      id: 111,
    },
    view_customer_financial: {
      value: false,
      id: 112,
    },
    deactivate_followup_followup: {
      value: false,
      id: 113,
    },
    view_compound: {
      value: false,
      id: 114,
    },
    add_compound: {
      value: false,
      id: 115,
    },
    update_compound: {
      value: false,
      id: 116,
    },
    delete_compound: {
      value: false,
      id: 117,
    },
    export_compound: {
      value: false,
      id: 118,
    },
    view_block: {
      value: false,
      id: 119,
    },
    add_block: {
      value: false,
      id: 120,
    },
    update_block: {
      value: false,
      id: 121,
    },
    delete_block: {
      value: false,
      id: 122,
    },
    export_block: {
      value: false,
      id: 123,
    },
    view_building: {
      value: false,
      id: 124,
    },
    add_building: {
      value: false,
      id: 125,
    },
    update_building: {
      value: false,
      id: 126,
    },
    delete_building: {
      value: false,
      id: 127,
    },
    export_building: {
      value: false,
      id: 128,
    },
    view_floor: {
      value: false,
      id: 129,
    },
    add_floor: {
      value: false,
      id: 130,
    },
    update_floor: {
      value: false,
      id: 131,
    },
    delete_floor: {
      value: false,
      id: 132,
    },
    export_floor: {
      value: false,
      id: 133,
    },
    view_unit_statuses: {
      value: false,
      id: 134,
    },
    add_unit_statuses: {
      value: false,
      id: 135,
    },
    update_unit_statuses: {
      value: false,
      id: 136,
    },
    delete_unit_statuses: {
      value: false,
      id: 137,
    },
    export_unit_statuses: {
      value: false,
      id: 138,
    },
    view_image: {
      value: false,
      id: 139,
    },
    add_image: {
      value: false,
      id: 140,
    },
    update_image: {
      value: false,
      id: 141,
    },
    delete_image: {
      value: false,
      id: 142,
    },
    export_image: {
      value: false,
      id: 143,
    },
    view_address: {
      value: false,
      id: 144,
    },
    add_address: {
      value: false,
      id: 145,
    },
    update_address: {
      value: false,
      id: 146,
    },
    delete_address: {
      value: false,
      id: 147,
    },
    export_address: {
      value: false,
      id: 148,
    },
    view_unit_type: {
      value: false,
      id: 149,
    },
    add_unit_type: {
      value: false,
      id: 150,
    },
    update_unit_type: {
      value: false,
      id: 151,
    },
    delete_unit_type: {
      value: false,
      id: 152,
    },
    export_unit_type: {
      value: false,
      id: 153,
    },
    view_room: {
      value: false,
      id: 154,
    },
    add_room: {
      value: false,
      id: 155,
    },
    update_room: {
      value: false,
      id: 156,
    },
    delete_room: {
      value: false,
      id: 157,
    },
    export_room: {
      value: false,
      id: 158,
    },
    view_unit: {
      value: false,
      id: 159,
    },
    add_unit: {
      value: false,
      id: 160,
    },
    update_unit: {
      value: false,
      id: 161,
    },
    delete_unit: {
      value: false,
      id: 162,
    },
    export_unit: {
      value: false,
      id: 163,
    },
    view_order: {
      value: false,
      id: 164,
    },
    add_order: {
      value: false,
      id: 165,
    },
    update_order: {
      value: false,
      id: 166,
    },
    delete_order: {
      value: false,
      id: 167,
    },
    export_order: {
      value: false,
      id: 168,
    },
    print_order: {
      value: false,
      id: 169,
    },
    view_invoice: {
      value: false,
      id: 170,
    },
    add_invoice: {
      value: false,
      id: 171,
    },
    update_invoice: {
      value: false,
      id: 172,
    },
    delete_invoice: {
      value: false,
      id: 173,
    },
    export_invoice: {
      value: false,
      id: 174,
    },
    print_invoice: {
      value: false,
      id: 175,
    },
    view_contract: {
      value: false,
      id: 176,
    },
    add_contract: {
      value: false,
      id: 177,
    },
    update_contract: {
      value: false,
      id: 178,
    },
    delete_contract: {
      value: false,
      id: 179,
    },
    export_contract: {
      value: false,
      id: 180,
    },
    print_contract: {
      value: false,
      id: 181,
    },
    view_payment: {
      value: false,
      id: 182,
    },
    add_payment: {
      value: false,
      id: 183,
    },
    update_payment: {
      value: false,
      id: 184,
    },
    delete_payment: {
      value: false,
      id: 185,
    },
    export_payment: {
      value: false,
      id: 186,
    },
    print_payment: {
      value: false,
      id: 187,
    },
    export_customer: {
      value: false,
      id: 188,
    },
    view_draw_image: {
      value: false,
      id: 189,
    },
    manage_draw_image: {
      value: false,
      id: 190,
    },
    view_company: {
      value: false,
      id: 191,
    },
    add_company: {
      value: false,
      id: 192,
    },
    update_company: {
      value: false,
      id: 193,
    },
    delete_company: {
      value: false,
      id: 194,
    },
    export_company: {
      value: false,
      id: 195,
    },
    view_all_companies: {
      value: false,
      id: 196,
    },
    view_payment_type: {
      value: false,
      id: 197,
    },
    add_payment_type: {
      value: false,
      id: 198,
    },
    update_payment_type: {
      value: false,
      id: 199,
    },
    delete_payment_type: {
      value: false,
      id: 200,
    },
    export_payment_type: {
      value: false,
      id: 201,
    },
    view_phase: {
      value: false,
      id: 202,
    },
    add_phase: {
      value: false,
      id: 203,
    },
    update_phase: {
      value: false,
      id: 204,
    },
    delete_phase: {
      value: false,
      id: 205,
    },
    export_phase: {
      value: false,
      id: 206,
    },
    assign_role: {
      value: false,
      id: 207,
    },
    view_discount: {
      value: false,
      id: 208,
    },
    add_discount: {
      value: false,
      id: 209,
    },
    update_discount: {
      value: false,
      id: 210,
    },
    delete_discount: {
      value: false,
      id: 211,
    },
    export_discount: {
      value: false,
      id: 212,
    },
    view_master_unit_type: {
      value: false,
      id: 213,
    },
    add_master_unit_type: {
      value: false,
      id: 214,
    },
    update_master_unit_type: {
      value: false,
      id: 215,
    },
    delete_master_unit_type: {
      value: false,
      id: 216,
    },
    export_master_unit_type: {
      value: false,
      id: 217,
    },
    add_bulk_data: {
      value: false,
      id: 218,
    },
    delete_bulk_data: {
      value: false,
      id: 219,
    },
    get_unit_status_change_notifications: {
      value: false,
      id: 220,
    },
    get_calls_notifications: {
      value: false,
      id: 221,
    },
    view_notifications: {
      value: false,
      id: 222,
    },
    add_notifications: {
      value: false,
      id: 223,
    },
    update_notifications: {
      value: false,
      id: 224,
    },
    delete_notifications: {
      value: false,
      id: 225,
    },
    export_notifications: {
      value: false,
      id: 226,
    },
    view_notifications_type: {
      value: false,
      id: 227,
    },
    add_notifications_type: {
      value: false,
      id: 228,
    },
    update_notifications_type: {
      value: false,
      id: 229,
    },
    delete_notifications_type: {
      value: false,
      id: 230,
    },
    export_notifications_type: {
      value: false,
      id: 231,
    },
    view_department: {
      value: false,
      id: 232,
    },
    add_department: {
      value: false,
      id: 233,
    },
    update_department: {
      value: false,
      id: 234,
    },
    delete_department: {
      value: false,
      id: 235,
    },
    export_department: {
      value: false,
      id: 236,
    },
    view_favourite_page:{
      value:false,
      id:237,
    },
    add_favourite_page:{
      value:false,
      id:238,
    },
    update_favourite_page:{
      value:false,
      id:239,
    },
    delete_favourite_page:{
      value:false,
      id:240,
    },
    export_favourite_page:{
      value:false,
      id:241,
    },
    assign_favourite_page:{
      value:false,
      id:242,
    },

    view_compound_department: {
      value: false,
      id: 243,
    },
    add_compound_department: {
      value: false,
      id: 244,
    },
    update_compound_department: {
      value: false,
      id: 245,
    },
    delete_compound_department: {
      value: false,
      id: 246,
    },
    export_compound_department: {
      value: false,
      id: 247,
    },
    view_compound_department_phone: {
      value: false,
      id: 248,
    },
    add_compound_department_phone: {
      value: false,
      id: 249,
    },
    update_compound_department_phone: {
      value: false,
      id: 250,
    },
    delete_compound_department_phone: {
      value: false,
      id: 251,
    },
    export_compound_department_phone: {
      value: false,
      id: 252,
    },

  },
};
export const RolesReducer = createSlice({
  name: "rolesData",
  initialState: initialState,
  reducers: {
    setRolesRedux: (state, action) => {
      state.roles = action.payload;
    },
  },
});

export const { setRolesRedux } =
  RolesReducer.actions;

export default RolesReducer.reducer;
