import Host from "./Host";
import moment from "moment";

//==================== Common ISO Currency List in Arabic ===============
let tafqeetISOList = {
  AED: {
    uSingle: "درهم إماراتي",
    uDouble: "درهمان إماراتيان",
    uPlural: "دراهم إماراتية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 2,
  },

  BHD: {
    uSingle: "دينار بحريني",
    uDouble: "ديناران بحرينيان",
    uPlural: "دنانير بحرينية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 3,
  },

  EGP: {
    uSingle: "جنيه مصري",
    uDouble: "جنيهان مصريان",
    uPlural: "جنيهات مصرية",
    uGender: "male",
    sSingle: "قرش",
    sDouble: "قرشان",
    sPlural: "قروش",
    sGender: "male",
    fraction: 2,
  },

  EUR: {
    uSingle: "يورو",
    uDouble: "يوروان",
    uPlural: "يوروات",
    uGender: "male",
    sSingle: "سنت",
    sDouble: "سنتان",
    sPlural: "سنتات",
    sGender: "male",
    fraction: 2,
  },

  GBP: {
    uSingle: "جنيه إسترليني",
    uDouble: "جنيهان إسترلينيان",
    uPlural: "جنيهات إسترلينية",
    uGender: "male",
    sSingle: "بنس",
    sDouble: "بنسان",
    sPlural: "بنسات",
    sGender: "male",
    fraction: 2,
  },

  INR: {
    uSingle: "روبية هندية",
    uDouble: "روبيتان هنديتان",
    uPlural: "روبيات هندية",
    uGender: "female",
    sSingle: "بيسة",
    sDouble: "بيستان",
    sPlural: "بيسات",
    sGender: "female",
    fraction: 2,
  },

  IQD: {
    uSingle: "دينار عراقي",
    uDouble: "ديناران عراقيان",
    uPlural: "دنانير عراقية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 2,
  },

  JOD: {
    uSingle: "دينار أردني",
    uDouble: "ديناران أردنيان",
    uPlural: "دنانير أردنية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 3,
  },

  KWD: {
    uSingle: "دينار كويتي",
    uDouble: "ديناران كويتيان",
    uPlural: "دنانير كويتية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 3,
  },

  LBP: {
    uSingle: "ليرة لبنانية",
    uDouble: "ليرتان لبنانيتان",
    uPlural: "ليرات لبنانية",
    uGender: "female",
    sSingle: "قرش",
    sDouble: "قرشان",
    sPlural: "قروش",
    sGender: "male",
    fraction: 2,
  },

  LYD: {
    uSingle: "دينار ليبي",
    uDouble: "ديناران ليبيان",
    uPlural: "دنانير ليبية",
    uGender: "male",
    sSingle: "درهم",
    sDouble: "درهمان",
    sPlural: "دراهم",
    sGender: "male",
    fraction: 3,
  },

  MAD: {
    uSingle: "درهم مغربي",
    uDouble: "درهمان مغربيان",
    uPlural: "دراهم مغربية",
    uGender: "male",
    sSingle: "سنتيم",
    sDouble: "سنتيمان",
    sPlural: "سنتيمات",
    sGender: "male",
    fraction: 2,
  },

  OMR: {
    uSingle: "ريال عماني",
    uDouble: "ريالان عمانيان",
    uPlural: "ريالات عمانية",
    uGender: "male",
    sSingle: "بيسة",
    sDouble: "بيستان",
    sPlural: "بيسات",
    sGender: "female",
    fraction: 3,
  },

  QAR: {
    uSingle: "ريال قطري",
    uDouble: "ريالان قطريان",
    uPlural: "ريالات قطرية",
    uGender: "male",
    sSingle: "درهم",
    sDouble: "درهمان",
    sPlural: "دراهم",
    sGender: "male",
    fraction: 2,
  },

  SAR: {
    uSingle: "ريال سعودي",
    uDouble: "ريالان سعوديان",
    uPlural: "ريالات سعودية",
    uGender: "male",
    sSingle: "هللة",
    sDouble: "هللتان",
    sPlural: "هللات",
    sGender: "female",
    fraction: 2,
  },

  SDG: {
    uSingle: "جنيه سوداني",
    uDouble: "جنيهان سودانيان",
    uPlural: "جنيهات سودانية",
    uGender: "male",
    sSingle: "قرش",
    sDouble: "قرشان",
    sPlural: "قروش",
    sGender: "male",
    fraction: 2,
  },

  SOS: {
    uSingle: "شلن صومالي",
    uDouble: "شلنان صوماليان",
    uPlural: "شلنات صومالية",
    uGender: "male",
    sSingle: "سنت",
    sDouble: "سنتان",
    sPlural: "سنتات",
    sGender: "male",
    fraction: 2,
  },

  SSP: {
    uSingle: "جنيه جنوب سوداني",
    uDouble: "جنيهان جنوب سودانيان",
    uPlural: "جنيهات جنوب سودانية",
    uGender: "male",
    sSingle: "قرش",
    sDouble: "قرشان",
    sPlural: "قروش",
    sGender: "male",
    fraction: 2,
  },

  SYP: {
    uSingle: "ليرة سورية",
    uDouble: "ليرتان سوريتان",
    uPlural: "ليرات سورية",
    uGender: "female",
    sSingle: "قرش",
    sDouble: "قرشان",
    sPlural: "قروش",
    sGender: "male",
    fraction: 2,
  },

  TND: {
    uSingle: "دينار تونسي",
    uDouble: "ديناران تونسيان",
    uPlural: "دنانير تونسية",
    uGender: "male",
    sSingle: "مليم",
    sDouble: "مليمان",
    sPlural: "ملاليم",
    sGender: "male",
    fraction: 3,
  },

  USD: {
    uSingle: "دولار أمريكي",
    uDouble: "دولاران أمريكيان",
    uPlural: "دولارات أمريكية",
    uGender: "male",
    sSingle: "سنت",
    sDouble: "سنتان",
    sPlural: "سنتات",
    sGender: "male",
    fraction: 2,
  },

  YER: {
    uSingle: "ريال يمني",
    uDouble: "ريالان يمنيان",
    uPlural: "ريالات يمنية",
    uGender: "male",
    sSingle: "فلس",
    sDouble: "فلسان",
    sPlural: "فلوس",
    sGender: "male",
    fraction: 2,
  },

  //==== add here
}; // end of list
const helpers = {
  getImageUrl: function getImageUrl(image_url) {
    var hostURLParts = Host.split("/");
    var hostURL = hostURLParts[0] + "//" + hostURLParts[2] + "/";
    const fullImageURL = hostURL + "api/public/" + image_url;

    return fullImageURL;
  },
  stringToColour: function stringToColour(str) {
    var colour = "#fff";
    if (str) {
      var hash = 0;
      for (var i = 0; i < str.length; i++) {
        hash = str.charCodeAt(i) + ((hash << 5) - hash);
      }
      colour = "#";
      for (var i = 0; i < 3; i++) {
        var value = (hash >> (i * 8)) & 0xff;
        colour += ("00" + value.toString(16)).substr(-2);
      }
    }

    return colour;
  },
  recursionCategory: function recursionCategory(data) {
    var parent = [];
    for (let item = 0; item < data.length; item++) {
      var child = {
        name: data[item].name,
        attributes: {
          id: data[item].id,
          color: helpers.stringToColour(data[item].condition_value),
          default_value: data[item].default_value,
          is_mandatory: data[item].is_mandatory,
          is_multiple_select: data[item].is_multiple_select,
          has_condition: data[item].has_condition,
          condition_value: data[item].condition_value,
          field_type_name: data[item]["field_type"].name,
          field_type_has_list: data[item]["field_type"].has_list,
        },
      };

      var children = data[item].children;
      var childrenMapped = null;
      if (children != null && children.length > 0) {
        childrenMapped = recursionCategory(children);
      }
      parent.push({
        ...child,
        children: childrenMapped,
      });
    }
    return parent;
  },
  listToTree: function listToTree(items, id = null, link = "survey_field_id") {
    return items
      .filter((item) => item[link] === id)
      .map((item) => ({
        ...item,
        children: helpers.listToTree(items, item.id),
      }));
  },
  checkDarknessOrLightnessForColor: function checkDarknessOrLightnessForColor(
    color
  ) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false; //light
    } else {
      return true; //dark
    }
  },
  formatDate: function formatDate(date) {
    var d = new Date(date),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear();
    if (month.length < 2) month = "0" + month;
    if (day.length < 2) day = "0" + day;

    let formatedDate = [year, month, day].join("-");
    return formatedDate;
  },
  formatTime: function formatTime(date) {
    var d = new Date(date),
      hours = d.getHours(),
      min = d.getMinutes(),
      sec = d.getSeconds();

    if (hours < 10) hours = "0" + hours;
    if (min < 10) min = "0" + min;
    if (sec < 10) {
      sec = "0" + sec;
    }

    return [hours, min, sec].join(":");
  },
  getImage: (obj, demourl) => {
    let imageSrc = "";
    if (obj?.image?.image_url)
      imageSrc = helpers.getImageUrl(obj?.image?.image_url);
    else imageSrc = demourl;

    return imageSrc;
  },
  formatTimeToMinutesSingle: (date) => {
    let startTime = moment(date).format("HH:mm");
    var timePartsStartTime = startTime.split(":");
    //   var timePartsWorkTime = workingTime.split(":");

    let MinuteStartTime =
      Number(timePartsStartTime[0] * 60) + Number(timePartsStartTime[1]);
    return MinuteStartTime;
  },
  refomrmatMinutesToHours: (min) => {
    let hourse = Math.floor(min / 60);
    let minutes = Math.floor(min % 60);
    // let dateNow= new Date();
    let dateNow = helpers.formatDate(new Date());
    dateNow += " " + hourse + ":" + minutes + ":00";
    return new Date(dateNow);
  },
  checkDarknessOrLightnessForColor: function checkDarknessOrLightnessForColor(
    color
  ) {
    // Variables for red, green, blue values
    var r, g, b, hsp;

    // Check the format of the color, HEX or RGB?
    if (color.match(/^rgb/)) {
      // If RGB --> store the red, green, blue values in separate variables
      color = color.match(
        /^rgba?\((\d+),\s*(\d+),\s*(\d+)(?:,\s*(\d+(?:\.\d+)?))?\)$/
      );

      r = color[1];
      g = color[2];
      b = color[3];
    } else {
      // If hex --> Convert it to RGB: http://gist.github.com/983661
      color = +(
        "0x" + color.slice(1).replace(color.length < 5 && /./g, "$&$&")
      );

      r = color >> 16;
      g = (color >> 8) & 255;
      b = color & 255;
    }

    // HSP (Highly Sensitive Poo) equation from http://alienryderflex.com/hsp.html
    hsp = Math.sqrt(0.299 * (r * r) + 0.587 * (g * g) + 0.114 * (b * b));

    // Using the HSP value, determine whether the color is light or dark
    if (hsp > 127.5) {
      return false; //light
    } else {
      return true; //dark
    }
  },
  arrayChunks: (array, chunk_size) =>
    Array(Math.ceil(array.length / chunk_size))
      .fill()
      .map((_, index) => index * chunk_size)
      .map((begin) => array.slice(begin, begin + chunk_size)),
  tafqeet: (numIn = 0, code, op = {}) => {
    let iso = tafqeetISOList[code];
    if (!iso) throw new Error("Currency code not in the list!");
    let arr = (numIn += "").split((0.1).toLocaleString().substring(1, 2)),
      out = nToW(arr[0], iso.uGender == "female", op, [
        iso.uSingle,
        iso.uDouble,
        iso.uPlural,
      ]),
      frc = arr[1] ? (arr[1] + "000").substring(0, iso.fraction) : 0;
    if (frc != 0) {
      out +=
        "، و" +
        (op.format == "short"
          ? frc + "/1" + "0".repeat(+iso.fraction) + " " + iso.uSingle
          : nToW(frc, iso.sGender == "female", op, [
              iso.sSingle,
              iso.sDouble,
              iso.sPlural,
            ]));
    }
    return out;

    function nToW(numIn = 0, fm, { comma, legal } = {}, names) {
      if (numIn == 0) return "صفر " + iso.uSingle;
      let tS = [
          ,
          "ألف",
          "مليون",
          "مليار",
          "ترليون",
          "كوادرليون",
          "كوينتليون",
          "سكستليون",
        ],
        tM = [
          "",
          "واحد",
          "اثنان",
          "ثلاثة",
          "أربعة",
          "خمسة",
          "ستة",
          "سبعة",
          "ثمانية",
          "تسعة",
          "عشرة",
        ],
        tF = [
          "",
          "واحدة",
          "اثنتان",
          "ثلاث",
          "أربع",
          "خمس",
          "ست",
          "سبع",
          "ثمان",
          "تسع",
          "عشر",
        ],
        num = (numIn += ""),
        tU = [...tM],
        t11 = [...tM],
        out = "",
        n99,
        SpWa = " و",
        miah = "مائة",
        last =
          ~~(
            ((numIn = "0".repeat((numIn.length * 2) % 3) + numIn).replace(
              /0+$/g,
              ""
            ).length +
              2) /
            3
          ) - 1;
      t11[0] = "عشر";
      t11[1] = "أحد";
      t11[2] = "اثنا";
      numIn
        .match(/.{3}/g)
        .forEach(
          (n, i) =>
            +n &&
            ((out += do999(numIn.length / 3 - i - 1, n, i == last)),
            i !== last && (out += (comma == "on" ? "،" : "") + SpWa))
        );
      let sub = " " + names[0],
        n = +(num + "").slice(-2);
      if (n > 10) sub = " " + tanween(names[0]);
      else if (n > 2) sub = " " + names[2];
      else if (n > 0) sub = names[n - 1] + " " + (fm ? tF[n] : tM[n]);
      return out + sub;

      function tanween(n, a = n.split` `, L = a.length - 1) {
        const strTF = (str, l = str.slice(-1), o = str + "ًا") => {
          return (
            "ا" == l
              ? (o = str.slice(0, -1) + "ًا")
              : "ة" == l && (o = str + "ً"),
            o
          );
        };
        for (let i = 0; i <= L; i++) if (!i || i == L) a[i] = strTF(a[i]);
        return a.join` `;
      }

      function do999(sPos, num, last) {
        let scale = tS[sPos],
          n100 = ~~(num / 100),
          nU = (n99 = num % 100) % 10,
          n10 = ~~(n99 / 10),
          w100 = "",
          w99 = "",
          e8 = nU == 8 && fm && !scale ? "ي" : "";
        if (fm && !scale) {
          [tU, t11, t11[0], t11[1], t11[2]] = [
            [...tF],
            [...tF],
            "عشرة",
            "إحدى",
            "اثنتا",
          ];
          if (n99 > 20) tU[1] = "إحدى";
        }
        if (n100) {
          if (n100 > 2) w100 = tF[n100] + miah;
          else if (n100 == 1) w100 = miah;
          else
            w100 = miah.slice(0, -1) + (!n99 || legal == "on" ? "تا" : "تان");
        }
        if (n99 > 19)
          w99 = tU[nU] + (nU ? SpWa : "") + (n10 == 2 ? "عشر" : tF[n10]) + "ون";
        else if (n99 > 10) w99 = t11[nU] + e8 + " " + t11[0];
        else if (n99 > 2) w99 = tU[n99] + e8;
        let nW = w100 + (n100 && n99 ? SpWa : "") + w99;
        if (!scale) return nW;
        if (!n99) return nW + " " + scale;
        if (n99 > 2)
          return (
            nW +
            " " +
            (n99 > 10
              ? scale + (last ? "" : "ًا")
              : sPos < 3
              ? [, "آلاف", "ملايين"][sPos]
              : tS[sPos] + "ات")
          );
        nW =
          (n100
            ? w100 + (legal == "on" && n99 < 3 ? " " + scale : "") + SpWa
            : "") + scale;
        return n99 == 1 ? nW : nW + (last ? "ا" : "ان");
      }
    }
  },
  getArabicLettersNumbering: (number) => {
    const letters = [
      "ا",
      "ب",
      "ج",
      "د",
      "هـ",
      "و",
      "ز",
      "ح",
      "ط",
      "ي",
      "ك",
      "ل",
      "م",
      "ن",
      "س",
      "ع",
      "ف",
      "ص",
      "ق",
      "ر",
      "ش",
      "ت",
      "ث",
      "خ",
      "ذ",
      "ض",
      "ظ",
      "غ",
    ];
    return letters[number % 28];
  },
  getFormatedPrice: (price, iqd_usd, code_or_text = 0) => {
    if (price) {
      if (code_or_text) {
        return (
          price?.format(iqd_usd == 1 ? 2 : 0, 3, ",", ".") +
          " " +
          (iqd_usd == 1 ? "دولار امريكي" : "دينار عراقي")
        );
      } else {
        return (
          (iqd_usd == 1 ? "USD" : iqd_usd != -1 ? "IQD" : "") +
          " " +
          price?.format(iqd_usd == 1 ? 2 : 0, 3, ",", ".")
        );
      }
    } else {
      return 0;
    }
  },
  getFormatedPriceV2: (price, iqd_usd, zPrice) => {
    if (price) {
      return price?.format(iqd_usd == 1 ? 2 : 0, 3, ",", ".");
    } else if (zPrice) {
      return 0;
    } else {
      return "";
    }
  },
  getAddress: (addresses, addressIndex = 0, lineNumber = -1) => {
    /// lineNumber=-1 for full address

    let address =
      addresses?.length > addressIndex ? addresses[addressIndex] : null;

    if (!address) {
      return "";
    }
    if (lineNumber == 0) {
      return address?.governorate?.name ? address?.governorate?.name : "";
    } else if (lineNumber == 1) {
      return address?.name ? address?.name : "";
    } else if (lineNumber == 2) {
      return address?.address ? address?.address : "";
    } else if (lineNumber == 3) {
      return address?.first_phone ? address?.first_phone : "";
    } else if (lineNumber == 4) {
      return address?.second_phone ? address?.second_phone : "";
    } else if (lineNumber == 5) {
      return address?.third_phone ? address?.third_phone : "";
    }

    if (lineNumber == -1) {
      let fullAddress = " ";
      fullAddress += " " + address?.name ? address?.name : "";
      fullAddress += " " + (address?.address ? "\n" + address?.address : "");
      fullAddress +=
        " " + (address?.first_phone ? "\nموبايل " + address?.first_phone : "");
      fullAddress +=
        " " +
        (address?.second_phone ? "\nموبايل " + address?.second_phone : "");
      fullAddress +=
        " " + (address?.third_phone ? "\nموبايل " + address?.third_phone : "");
      return fullAddress;
    }

    return "";
  },
  getFormatTimeCounDown: (timeInSecond) => {
    let seconds = timeInSecond;

    //days
    let days = Math.floor(seconds / (24 * 3600));
    seconds -= days * 24 * 3600;

    //hours
    let hours = Math.floor(seconds / 3600);
    seconds -= hours * 3600;

    //minutes
    let minut = Math.floor(seconds / 60);
    seconds -= minut * 60;

    return {
      days: "يوم: " + days,
      days_without_text: days,
      hour: hours > 9 ? hours : "0" + hours,
      min: minut > 9 ? minut : "0" + minut,
      sec: seconds > 9 ? seconds : "0" + seconds,
      show: timeInSecond <= 0 ? false : true,
    };
  },
  handleValidatePhoneValue: (e) => {
    let inputtxt = e;
    var phoneno = /^\d{11}$/;
    let object = {
      error: "",
      message: false,
    };
    if (isNaN(inputtxt)) {
      return {
        message: "يجب ان يكون الهاتف رقم",
        error: true,
      };
    }

    if (e?.length == 11 && !isNaN(inputtxt)) {
      object = {
        message: "",
        error: false,
      };
    } else if (e?.length >= 11) {
      object = {
        message: "رقم الهاتف بصيغة غير صحيحة !",
        phone: true,
      };
    } else {
      object = {
        message: "",
        phone: false,
      };
    }
    return object;
  },
  getImageProfile: (images) => {
    let imageUrl = null;
    let lastImageProf = null;
    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          lastImageProf = itm?.image_url;
        }
      });
    if (lastImageProf) imageUrl = lastImageProf;

    if (imageUrl) return process.env.REACT_APP_API_URL_image + imageUrl;

    return "";
  },
  getImageProfileID: (images) => {
    let id = null;

    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 1) {
          id = itm?.id;
        }
      });
    return id;
  },
  getImageCover: (images) => {
    let imageUrl = null;
    let lastImageCover = null;
    images?.length &&
      images?.map((itm) => {
        if (itm?.image_type_id == 2) {
          lastImageCover = itm?.image_url;
        }
      });
    if (lastImageCover) imageUrl = lastImageCover;

    if (imageUrl) return process.env.REACT_APP_API_URL_image + imageUrl;

    return "";
  },
  handleMergePhoneWithStateCode: (phone, code) => {
    let str_phone = phone?.slice(0, 1);
    if (str_phone != 0) {
      return code + "-" + phone;
    } else {
      str_phone = phone?.slice(1);
      return code + "-" + str_phone;
    }
  },

  handleSplitPhoneWithStateCode: (phone) => {
    if (phone?.includes("-")) {
      let temp = phone?.split("-");
      return {
        code: temp[0] ? temp[0] : "",
        phone: temp[1] ? "0" + temp[1] : "",
      };
    } else {
      return {
        code: "",
        phone: phone,
      };
    }
  },
  getDayNumberFromDate: (date) => {
    let dayDate = new Date(date);
    let day = dayDate?.getDay();
  },
  getFirstAndLastDayNumberFromDate: (monthDate) => {
    var date = new Date(monthDate);
    var firstDay = new Date(date.getFullYear(), date.getMonth(), 1);
    var lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    return {
      first: helpers.formatDate(firstDay),
      last: helpers.formatDate(lastDay),
    };
  },
  removeForwordSplash: (pathname) => {
    let text = pathname?.replace(/\//g, "");
    return text;
  },
  validateEmail: (email) => {
    var mailformat =
      /^([A-Za-z0-9_\-\.])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,4})$/;
    if (email?.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  },

  //=====================================================================
  //***********************************************
};
export default helpers;
