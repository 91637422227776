import callAPI from '../ApiConfig'
import store from '../../../app/store'

const getNextStructureLevel = async ({signal,params}) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"get_next_structure_level",
        method:"get",
        signal:signal,
        params:params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const getMasters = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"masters",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
          "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const getSourceAndLinkedToEntities = async ({signal,params}) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"get_source_and_linked_to_entities",
        method:"get",
        signal:signal,
        params:params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    
    })
    
}

const generalAPI={
    getNextStructureLevel:getNextStructureLevel,
    getMasters:getMasters,
    getSourceAndLinkedToEntities:getSourceAndLinkedToEntities

}
export default generalAPI