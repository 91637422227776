import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import initCallTypeObject, { checkErrors, ObjectMerge, resetErrors, restCustomeObject } from '../../../Global/Errors/CallType/CallType'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify'
import CallsTypeAPI from '../../../Global/Network/CallsType/index'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'


const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const maintheme = useSelector(state => state.themeData.maintheme);

    const webOrMobileList = [
        {
            id:"mobile",
            name:t('CALLTYPE.mobile')
        },
        {
            id:"web",
            name:t('CALLTYPE.web')
        },
        {
            id:"both",
            name:t('CALLTYPE.both')
        }
    ]
    const getWebOrMobileValue = (id) => { 
        let webOrObileValueList = webOrMobileList?.filter(item=>item?.id==id)
        if(webOrObileValueList?.length)
        {
            return webOrObileValueList[0]
        }
        else{
            return {
                id:"",
                name:""
            }
        }
    }

    return (
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                    <CustomTextField
                        label={t('CALLTYPE.name')}
                        haswidth={true}
                        value={props?.object?.name?.value}
                        error={props?.object?.name?.error}
                        message={props?.object?.name?.message}
                        readOnly={false}
                        onChange={(e) => {
                            props?.setObject({
                                ...props?.object,
                                name: {
                                    ...props?.object?.name,
                                    value: e.target.value,
                                    error: false,
                                    message: []
                                }
                            })

                        }}
                        onClearClick={() => {
                            props?.setObject({
                                ...props?.object,
                                name: {
                                    value: "",
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                    />
                </Box>
                <Box sx={{margin:'10px 0'}}></Box>
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                <CustomeSelectField
                        label={t('CUSTOMER.relation_type')}
                        haswidth={true}
                        value={getWebOrMobileValue(props?.object?.web_or_mobile?.value)}
                        list={webOrMobileList}
                        error={props?.object?.web_or_mobile.error}
                        message={props?.object?.web_or_mobile.message}
                        readOnly={false}
                        focused={true}
                        onChange={(e, newValue) => {

                            props?.setObject({
                                ...props?.object,
                                web_or_mobile:{
                                    ...props?.object?.web_or_mobile,
                                    value:newValue?.id,
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                        onClearClick={() => {
                            
                            props?.setObject({
                                ...props?.object,
                                web_or_mobile:{
                                    ...props?.object?.web_or_mobile,
                                    value:{
                                        id:"",
                                        name:""
                                    },
                                    error:false,
                                    message:[]
                                },
                            })
                        }}
                    />
                </Box>
                
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [callTypeObject, setCallTypeObject] = useState(initCallTypeObject)


    useEffect(() => {
        if (props?.object && props?.object?.id) {
      
            let updatedObject = ObjectMerge(props?.object)
         
            setCallTypeObject(updatedObject)
        } else {
            clearForm()
        }
    }, [props?.object])

    const submit = async () => {
        try {
            setLoading(true)

            let data = {
                name: callTypeObject?.name?.value,
                web_or_mobile: callTypeObject?.web_or_mobile?.value,
            }
            console.log('dataHERE',data)
            let result = null;
            if (props?.object?.id) {
                data = {
                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await CallsTypeAPI.updatecallsType({
                    data: data
                });
            } else {
                result = await CallsTypeAPI.addCallsType({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(callTypeObject)
                    setCallTypeObject(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setCallTypeObject({
            ...initCallTypeObject
        })
    }

    useEffect(() => {
        if (!props?.object)
            clearForm()
    }, [props?.addForm, props?.object])

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('CALLTYPE.updateTitle') : t('CALLTYPE.addTitle')}
            content={<RenderContent
                object={callTypeObject}
                setObject={setCallTypeObject}
                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
