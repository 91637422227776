import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import customerAPI from '../../../Global/Network/Customer';
import favouritePageAPI from '../../../Global/Network/FavouritePage/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import CustomIcons from '../../Components/CustomIcons';
import AssignUsersToFavouritePage from './AssignUsersFavouritePage';
import { Favorite } from '@mui/icons-material';
import { debounce } from 'debounce';

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')

  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.objectName}
        setValue={props?.setobjectName}
        title={t('WORKTYPE.work_name_search')}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('WORKTYPE.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('WORKTYPE.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        clearOnBlur={false}
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('WORKTYPE.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({searchText: e.target.value});
            }} />
        )}
      />

    </Box>
  )
}

export default function FavouritePage() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([])
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedObject, setselectedObject] = useState(null)
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [objectName, setobjectName] = useState(null)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const [filterParams, setFilterParams] = useState({})
  const rolesRedux = useSelector((state) => state.rolesData.roles);




  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();
  const getMinutsFormattoTime = (min) => {
    // let timeInMin=min;
    // if(timeInMin){
    //   timeInMin=timeInMin*60;
    //    let hours=Math.floor(parseInt(timeInMin)/3600);
    //    let mins=Math.floor((parseInt(timeInMin)%3600)/60)
    //    let seconds=Math.floor((parseInt(timeInMin)%3600)%60)
    //    if(hours>0&&hours<10){
    //     hours='0'+hours;
    //    }else if(!hours){
    //     hours='00';
    //    }

    //    if(mins>0&&mins<10){
    //     mins='0'+mins;
    //    }else if(!mins){
    //     mins='00';
    //    }


    //    if(seconds>0&&seconds<10){
    //     seconds='0'+seconds;
    //    }else if(!seconds){
    //     seconds='00';
    //    }


    //    return `${hours}:${mins}:${seconds}`;

    // }return "00:00:00";
    let object = helpers.getFormatTimeCounDown(min * 60)//send  in seconds format
    return `يوم :${object?.days_without_text} ${object?.hour}:${object?.min}:${object?.sec}`

  }
  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const columns = useMemo(() => [
    { headerName: t('FAVOURITE_PAGE.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    {
      headerName: t('FAVOURITE_PAGE.name'), field: 'name', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            sx={{
              marginLeft: '10px'
            }}
          >
            {rows?.row?.name}
          </Typography>
          <CustomIcons
            style={{
              color: '#1E6A99',
              fontSize: '30px'
            }}
            iconName={rows?.row?.icon}
          />
          
        </Box>
      )
    },

    {
      headerName: t('FAVOURITE_PAGE.color'), field: 'color', flex: 2, minWidth: 150, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              backgroundColor: rows?.row?.color,
              width: '200px',
              height: '30px',
              borderRadius: '3px'
            }}
          >

          </div>
        </Box>
      )
    },
    {
      headerName: t('FAVOURITE_PAGE.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            rolesRedux?.update_favourite_page?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null
          }
          { rolesRedux?.assign_favourite_page?.value ?
            <MenuItem onClick={() => { assignUsers(rows?.row) }} className="ActionIcons">
              <ListItemIcon sx={{
                display: 'flex',
                justifyContent: 'center',
                margin: '0px 7px 0 0'
              }}>
                <Favorite style={{ fill: "#1e6a99" }} />
              </ListItemIcon>
              {t('FAVOURITE_PAGE.assign_users')}

            </MenuItem> : null}
          {
             rolesRedux?.delete_favourite_page?.value ?
              <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.action_delete')}

              </MenuItem> : null
          }
          {
             rolesRedux?.update_favourite_page?.value == false && rolesRedux?.delete_favourite_page?.value == false&&!rolesRedux?.assign_favourite_page?.value ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], [rolesRedux])
  useEffect(() => {
    let abortController = new AbortController();

    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize,filterParams])

  useEffect(() => {
    let abortController = new AbortController();

    loadDataOfUsers({})
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData = {
      ...filterParams,
      ...searchParams
    }
    const result = await favouritePageAPI.favouritePage({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const handleSearch = debounce((value)=>{
    loadDataOfUsers(value);
  }, 500);
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)
  
    const result = await EmployeeAPI.Employee({
      params: {
        page_size: 3,
        page: 0,
        full_name: searchParams.searchText ? searchParams.searchText : "",
      },
      signal: controller?.signal,
    });
    setLoading(false)
    if (result.status) {
      setUsers(result?.data?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setselectedObject(null)
    setAddForm(true)
  }
  const updateFunction = (obj) => {
    setselectedObject(obj)
    setAddForm(true)
  }
  const deleteFunction = async (obj) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {

      if (willDelete) {

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {

            result = await favouritePageAPI.DeleteFavouritePage({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            if (result?.errorMessage) {
              toast.error(result?.errorMessage)
            } else
              toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams(null)

  }
  const searchFunction = () => {
    let data = {};
    if (objectName) {
      data = {
        ...data,
        name: objectName
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    } setFilterParams(data)

    // loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await favouritePageAPI.FavouritePage({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (objectName) {
      filteredData = {
        ...filteredData,
        name: objectName
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }

    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('FAVOURITE_PAGE.id')}`]: itm?.id,
        [`${t('FAVOURITE_PAGE.name')}`]: itm?.name,
        [`${t('FAVOURITE_PAGE.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'favourite pages')

  }


  const assignUsers = (obj) => {
    // alert(obj?.name)
    setSelectedFavouritePage(obj)
    setShowAssignUsers(true)
  }
  const [showAssignUsers, setShowAssignUsers] = useState(false)
  const [selectedFavouritePage, setSelectedFavouritePage] = useState(null)
  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      
      {addForm ? <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData} />:null}
      {showAssignUsers ? <AssignUsersToFavouritePage favouritePage={selectedFavouritePage} object={selectedFavouritePage} addForm={showAssignUsers} setAddForm={setShowAssignUsers} loadData={loadData} /> : null}
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('FAVOURITE_PAGE.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}

          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_favourite_page?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_favourite_page?.value?false:false}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
          handleSearch={handleSearch}
        />}
      />

    </Box>
  )
}
