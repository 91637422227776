import * as React from 'react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import { Icon } from '@mui/material';
import CustomIcons from './CustomIcons';

export default function CustomIconsList() {
  return (
    
    <Autocomplete
      id="country-select-demo"
      sx={{ width: 300 }}
      options={icons}
      autoHighlight
      getOptionLabel={(option) => option.label}
      renderOption={(props, option) => (
        <Box component="li" sx={{ '& > img': { mr: 2, flexShrink: 0 } }} {...props}>
            <CustomIcons iconName={option?.name} />
               
               <span style={{
                 textAlign:'justify',
                 wordWrap:'break-word',
                 whiteSpace:'break-spaces'
               }}>{option?.label}</span>
        </Box>
      )}
      renderInput={(params) => (
        <TextField
          {...params}
          label="Choose a country"
          inputProps={{
            ...params.inputProps,
            autoComplete: 'new-password', // disable autocomplete and autofill
          }}
        />
      )}
    />
        
  );
}



export const icons = [
    { id:1, name: 'Add', label: 'Backup Table' },
    { id:2, name: 'Abc', label: 'Credit Card Off' },
    { id:3, name: 'AcUnit', label: 'Play For Work' },
  
    
  ];