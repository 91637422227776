import { Stage, Layer, Image } from "react-konva";
import React, { useRef, useState, useEffect } from "react";
import useImage from "use-image";
import { Box, Tooltip, Typography } from "@mui/material";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import PolygonAnnotation from "../components/PolygonAnnotation";
import { useSelector, useDispatch } from "react-redux";
import PolygonToolTip from "../components/PolygonToolTip";
import { useLocation, useNavigate } from "react-router-dom";
import generalAPI from "../../../Global/Network/General";
import Preloading from "../../../Global/Preload/Preloading";
import helpers from "../../../assets/js/helper";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import FlipCameraAndroidIcon from "@mui/icons-material/FlipCameraAndroid";
import SaveIcon from "@mui/icons-material/Save";
import CustomSlider from "../../../General/CustomComponents/CustomSlider";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
const scaleBy = 1.04;

const BuildingImage = (props) => {
  const [image] = useImage(props?.imageUrl);
  // useEffect(() => {
  //   if (image) {

  //     props?.onLoad()

  //   }
  // }, [image]);
  return (
    <Image
      image={image}
    // onload={props?.onLoad}
    />
  );
};

function getDistance(p1, p2) {
  return Math.sqrt(Math.pow(p2.x - p1.x, 2) + Math.pow(p2.y - p1.y, 2));
}

function getCenter(p1, p2) {
  return {
    x: (p1.x + p2.x) / 2,
    y: (p1.y + p2.y) / 2,
  };
}

function isTouchEnabled() {
  return (
    "ontouchstart" in window ||
    navigator.maxTouchPoints > 0 ||
    navigator.msMaxTouchPoints > 0
  );
}

const wrapperStyle = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  backgroundColor: "#fff",
  width: "100%",
  margin: "0 auto",
  overflow: "hidden",
};
const ImageMapView = (props) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const { state } = useLocation();
  const [t] = useTranslation("common");
  const controller = new AbortController();
  const [type, setType] = useState("");
  const [typeId, setTypeId] = useState("");
  const [title, setTitle] = useState("");
  const [hidePolygons, setHidePolygons] = useState(false);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const drawerwidth = useSelector((state) => state.settingsData.drawerwidth);

  const [nameOfCurrentCriteriaSwitch, setNameOfCurrentCriteriaSwitch] =
    useState(false);

  // const polygons = useSelector(state => state.settingsData.polygons)
  const [polygonToolTipObject, setPolygonToolTipObject] = useState({
    title: "",
    icon: "",
    availableUnits: 0,
    showAvailableUnits: true,
    show: false,
    top: "",
    left: "",
  });
  const [imagePath, setImagePath] = useState("");
  // const [image] = useImage(imagePath);
  const [polygons, setPolygons] = useState([]);
  const [viewTrackIndex, setViewTrackIndex] = useState(-1);
  const [viewTrackTypesArray, setViewTrackTypesArray] = useState([
    //   {
    //   type: "country",
    //   id: 0,
    //   title: ""
    // }
  ]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [selectedFloor, setSelectedFloor] = useState(null);
  const [imageFloors, setImageFloors] = useState([]);
  const [unFilteredPolygons, setUnFilteredPolygons] = useState([]);

  const [draggable, setDraggable] = useState(true);
  const zoomInterval = useRef(null);
  const stageRef = useRef(null);
  // const layerRef = useRef(null);
  let lastCenter = null;
  let lastDist = 0;

  function zoomStage(event) {
    event.evt.preventDefault();
    if (stageRef.current !== null) {
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale =
        event.evt.deltaY > 0 ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  }
  const zoomStageWithButton = (zoomIn) => {
    if (stageRef.current !== null) {
      var pointerX = window.innerWidth / 2;
      var pointerY = window.innerHeight / 2;
      const stage = stageRef.current;
      const oldScale = stage.scaleX();
      // const { x: pointerX, y: pointerY } = stage.getPointerPosition();
      const mousePointTo = {
        x: (pointerX - stage.x()) / oldScale,
        y: (pointerY - stage.y()) / oldScale,
      };
      const newScale = zoomIn ? oldScale * scaleBy : oldScale / scaleBy;
      stage.scale({ x: newScale, y: newScale });
      const newPos = {
        x: pointerX - mousePointTo.x * newScale,
        y: pointerY - mousePointTo.y * newScale,
      };
      stage.position(newPos);
      stage.batchDraw();
    }
  };

  function handleTouch(e) {
    e.evt.preventDefault();

    //TODO drag using one touch

    var touch1 = e.evt.touches[0];
    var touch2 = e.evt.touches[1];
    const stage = stageRef.current;
    if (touch1 && touch2) {
      // if the stage was under Konva's drag&drop
      // we need to stop it, and implement our own pan logic with two pointers
      if (stage.isDragging()) {
        stage.stopDrag();
      }

      var p1 = {
        x: touch1.clientX,
        y: touch1.clientY,
      };
      var p2 = {
        x: touch2.clientX,
        y: touch2.clientY,
      };

      if (!lastCenter) {
        lastCenter = getCenter(p1, p2);
        return;
      }
      var newCenter = getCenter(p1, p2);

      var dist = getDistance(p1, p2);

      if (!lastDist) {
        lastDist = dist;
      }

      // local coordinates of center point
      var pointTo = {
        x: (newCenter.x - stage.x()) / stage.scaleX(),
        y: (newCenter.y - stage.y()) / stage.scaleX(),
      };

      var scale = stage.scaleX() * (dist / lastDist);

      stage.scaleX(scale);
      stage.scaleY(scale);

      // calculate new position of the stage
      var dx = newCenter.x - lastCenter.x;
      var dy = newCenter.y - lastCenter.y;

      var newPos = {
        x: newCenter.x - pointTo.x * scale + dx,
        y: newCenter.y - pointTo.y * scale + dy,
      };

      stage.position(newPos);
      stage.batchDraw();
      lastDist = dist;
      lastCenter = newCenter;
    }
  }

  function handleTouchEnd() {
    setDraggable(true);
    lastDist = 0;
    lastCenter = null;
  }

  const onPolygonClicked = (e, polygon) => {
    let model = polygon?.polygonInfo?.image_polygonable_type;
    let modelSplited = model.split("\\");
    let newType = "";
    let newTypeId = polygon?.polygonInfo?.image_polygonable_id;
    let newTitle = "";
    if (modelSplited.length > 2) {
      newType = modelSplited[2];
      newType = newType.toLocaleLowerCase();
    }

    if (newType) {
      newTitle =
        t("IMAGE_MAP_VIEW." + newType) +
        " : " +
        (polygon?.polygonInfo?.morph_object?.name
          ? polygon?.polygonInfo?.morph_object?.name
          : "");
    }

    if (newType == "unittype") {
      window.open(
        window.location.origin +
        "/admin/view-unit?id=" +
        polygon?.polygonInfo?.morph_object?.id,
        "_blank"
      );
      return;
    }
    if (type && typeId) {
      setViewTrackIndex(viewTrackIndex + 1);
      setViewTrackTypesArray([
        ...viewTrackTypesArray,
        {
          type: type,
          id: typeId,
          title: title,
        },
      ]);
    }

    setType(newType);
    setTypeId(newTypeId);
    setTitle(newTitle);
  };

  const handleMouseOutPolygon = (e, polygon) => {
    setPolygonToolTipObject({
      title: "",
      availableUnits: 0,
      icon: "",
      show: false,
      top: "",
      left: "",
    });
  };
  const handleMouseOverPolygon = (e, polygon, n, eventType) => {
    let showAvailableUnits = true;
    if (polygon?.polygonInfo?.image_polygonable_type?.includes("UnitType")) {
      showAvailableUnits = false;
    }
    let top = 0,
      left = 0;
    if (e?.type == "tap") {
      setPolygonToolTipObject({
        title: polygon?.polygonInfo?.morph_object?.name,
        icon: "",
        availableUnits:
          polygon?.polygonInfo?.morph_object?.available_units_counts,
        show: true,
        showAvailableUnits: showAvailableUnits,
        top: e?.evt?.changedTouches[0]?.clientY,
        left: e?.evt?.changedTouches[0]?.clientX,
      });
    } else if (e?.type == "mouseover") {
      top = e.evt.clientY;
      left = e.evt.clientX;
      setPolygonToolTipObject({
        title: polygon?.polygonInfo?.morph_object?.name,
        icon: "",
        availableUnits:
          polygon?.polygonInfo?.morph_object?.available_units_counts,
        show: true,
        showAvailableUnits: showAvailableUnits,
        top: e.evt.clientY,
        left: e.evt.clientX,
      });
    }
  };

  useEffect(() => {
    if (type && typeId) {
      loadData({
        id: typeId,
        type: type,
      });
    } else {
      setViewTrackIndex(viewTrackIndex + 1);
      setViewTrackTypesArray([
        ...viewTrackTypesArray,
        {
          id: state?.data?.type_id,
          type: state?.data?.type,
          title:
            t("IMAGE_MAP_VIEW." + state?.data?.type) +
            " : " +
            state?.data?.title,
        },
      ]);
      loadData({
        id: state?.data?.type_id,
        type: state?.data?.type,
      });
    }

    return () => { };
  }, [type, typeId]);

  const loadData = async (searchParams) => {
    setLoading(true);

    const result = await generalAPI.getNextStructureLevel({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      console.log(result?.data);
      if (Array.isArray(result?.data?.images) && result?.data?.images?.length>0) {
        
        
          setSelectedImages(result?.data?.images);
          selectImageToDraw(result?.data?.images[0]);
          setSelectedImageIndex(0);

          if (
            result?.data?.images[0] &&
            result?.data?.images[0]?.image_type_id == 5
          ) {
            setNameOfCurrentCriteriaSwitch(true);
          }
        
      } else {
        changeImage(viewTrackIndex);
        toast.warn(t('GLOBAL.there_is_no_image_provided_for_this_element'))
      }
    } else {
      toast.warn(t('GLOBAL.there_is_no_image_provided_for_this_element'))
    }
  };

  const selectImageToDraw = (image) => {
    setImagePath(process.env.REACT_APP_API_URL_image + image?.image_url);
    let floors = [];
    if (image?.image_polygons?.length > 0) {
      let imagePolygons = image?.image_polygons?.map((imagePolygon) => {
        if (image?.image_type_id == 5) {
          setNameOfCurrentCriteriaSwitch(true);
          floors.push({
            id: imagePolygon?.floor_object?.id,
            value: imagePolygon?.floor_object?.floor_number,
            label: imagePolygon?.floor_object?.name,
          });
        } else {
          setNameOfCurrentCriteriaSwitch(false);
        }
        let polyCoords = [];
        try {
          polyCoords = JSON.parse(imagePolygon?.coords);
        } catch (error) { }

        return {
          floor_id: imagePolygon?.floor_object?.id,
          floor_number: imagePolygon?.floor_object?.floor_number,
          points: helpers.arrayChunks(polyCoords, 2),
          flattenedPoints: polyCoords,
          polygonInfo: imagePolygon,
        };
      });
      if (floors?.length) {
        floors = [
          ...new Map(floors.map((item) => [item["value"], item])).values(),
        ];
        floors?.sort((a, b) => a?.value - b?.value);
        setImageFloors(floors);

        setSelectedFloor(floors[0]);
        setUnFilteredPolygons(imagePolygons);
        setPolygons(
          imagePolygons?.filter(
            (item) => item?.floor_number == floors[0]?.value
          )
        );
      } else {
        setImageFloors([]);
        setSelectedFloor(null);
        setPolygons(imagePolygons);
      }
    } else {
      setPolygons([]);
    }
  };

  const getStatus = (polygon) => {
    if (polygon?.polygonInfo?.image_polygonable_type?.includes("UnitType")) {
      return polygon?.polygonInfo?.morph_object?.unit_status_id === 1;
    } else {
      return polygon?.polygonInfo?.morph_object?.available_units_counts > 0;
    }
  };

  const hanldeTouchStart = (e) => {
    setDraggable(false);
  };

  const changeImage = (index) => {
    let previousType = viewTrackTypesArray[index];
    
    if (viewTrackTypesArray?.length == 0) {
      navigate("/admin/available-compounds");
    } else {
      setType(previousType?.type);
      setTypeId(previousType?.id);
      setTitle(previousType?.title);
      setViewTrackIndex(index - 1);
      setViewTrackTypesArray(viewTrackTypesArray?.slice(0, index));
    }
  };

  const getTitle = () => {
    const titleSx = {
      width: "fit-content",
      height: "25px",
      color: "#1e6a99",
      "&:hover": {
        background: "#FFFFFF 0% 0% no-repeat padding-box",
        boxShadow: "0px 0 10px -3px #000",
        backgroundColor: "#1e6a99",
        color: "#FFFFFF",
      },
      maxWidth: "200px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      whiteSpace: "nowrap",

      opacity: "1",
      borderRadius: "20px",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",

      cursor: "pointer",

      fontFamily: "Cairo",
      padding: "0 10px",
    };
    const typographySx = {
      maxWidth: "200px",
      textOverflow: "ellipsis",
      overflow: "hidden",
      color: "#1e6a99",
      fontFamily: "Cairo",
      marginLeft: "5px",
      whiteSpace: "nowrap",
    };
    let fullTitle = viewTrackTypesArray?.map((item, index) => {
      // if (viewTrackTypesArray?.length > 0) {
      if (nameOfCurrentCriteriaSwitch && index == 0) {
        return (
          <>
            <Typography component={"span"} sx={typographySx}>
              {item?.title}
            </Typography>
            <Typography component={"span"} sx={typographySx}>
              {" / " + t("IMAGE_MAP_VIEW.show_all_floors")}
            </Typography>
          </>
        );
      } else {
        return (
          <>
            <Box
              sx={titleSx}
              onClick={() => {
                changeImage(index);
              }}
            >
              {item?.title}
            </Box>

            {index == viewTrackTypesArray?.length - 1 ? (
              <>
                <Typography component={"span"} sx={typographySx}>
                  {" / "}
                </Typography>
                <Typography component={"span"} sx={typographySx}>
                  {title}
                </Typography>
              </>
            ) : null}
          </>
        );
      }

      // }
      // else if (viewTrackTypesArray?.length == 0) {
      //   if (title) {
      //     return (<>
      //       <Box
      //         sx={titleSx}
      //         onClick={() => { changeImage(index) }}
      //       >{item?.title}</Box>
      //       {/* <Typography component={'span'} sx={typographySx}>{" / "}</Typography>
      //       <Typography component={'span'} sx={typographySx}>{title}</Typography> */}
      //     </>)
      //   }
      //   else if (nameOfCurrentCriteriaSwitch) {
      //     return (<>
      //       <Typography component={'span'} sx={typographySx}>{item?.title}</Typography>
      //       <Typography component={'span'} sx={typographySx}>{" / " + t('IMAGE_MAP_VIEW.show_all_floors')}</Typography>
      //     </>)
      //   }
      //   else {
      //     return <Typography component={'span'} sx={typographySx}>{item?.title}</Typography>
      //   }

      // }
    });

    return fullTitle?.length > 0 ? (
      fullTitle
    ) : (
      <Typography component={"span"} sx={typographySx}>
        {title}
      </Typography>
    );
  };

  const getUnitStatusColors = () => {
    let arr = [];
    let arrUniq = [];
    polygons &&
      polygons?.map((polygon) => {
        let checkExists = true;
        let color = "rgba(0,0,0,1)";
        let name = "";
        if (polygon?.polygonInfo?.morph_object) {
          if (polygon?.polygonInfo?.morph_object?.floor_id) {
            if (polygon?.polygonInfo?.morph_object?.unit_status) {
              color =
                polygon?.polygonInfo?.morph_object?.unit_status?.color + "a0";
              name = polygon?.polygonInfo?.morph_object?.unit_status?.name;
              if (arrUniq.length == 0) {
                arrUniq.push(
                  polygon?.polygonInfo?.morph_object?.unit_status?.id
                );
                checkExists = false;
              } else {
                checkExists = arrUniq?.includes(
                  polygon?.polygonInfo?.morph_object?.unit_status?.id
                );
                if (!checkExists) {
                  arrUniq.push(
                    polygon?.polygonInfo?.morph_object?.unit_status?.id
                  );
                }
              }
            }
          }
        }
        if (!checkExists) {
          arr.push(
            <Box
              sx={{ display: "flex", alignItems: "center", margin: "0 5px" }}
            >
              <span
                style={{
                  width: 18,
                  height: 18,
                  backgroundColor: color,
                  margin: "0 5px",
                }}
              ></span>
              <span style={{ fontFamily: "Cairo-Light", fontSize: 14 }}>
                {name}
              </span>
            </Box>
          );
        }
      });
    return arr;
  };

  return (
    <div className="container-fluid px-0 mx-0">
      <div className="row px-0 mx-0">
        {loading ? "loading..." :
          <div
            className="col-md-12"
            style={{ padding: 0, margin: "0px !important" }}
          >
            <div style={wrapperStyle}>
              {
                // loading ? <Preloading /> :
                <>
                  <Stage
                    width={window.innerWidth - 94}
                    height={window.innerHeight}
                    draggable={draggable} //!isTouchEnabled()}
                    // draggable
                    onWheel={zoomStage}
                    onTouchStart={hanldeTouchStart}
                    onTouchMove={handleTouch}
                    onTouchEnd={handleTouchEnd}
                    // onTap={() => {
                    //   if (polygonToolTipObject?.show) {
                    //     setPolygonToolTipObject({
                    //       title: "",
                    //       availableUnits: 0,
                    //       icon: "",
                    //       show: false,
                    //       top: "",
                    //       left: ""
                    //     })
                    //   }
                    // }}
                    ref={stageRef}
                  >
                    {/* {imagePath  !== "" ? */}
                    <Layer id="stuffToShow">
                      <BuildingImage
                        imageUrl={imagePath}
                      // onLoad={() => {
                      //   // const image = e.target;

                      //   layerRef.current.x(window.innerWidth / 2 - layerRef.current.width() / 2);
                      //   layerRef.current.y(window.innerHeight / 2 - layerRef.current.height() / 2);
                      // }}
                      />
                      {/* <Image image={image?.data}
                      onLoad={(e) => {
                        const image = e.target;
                        layerRef.current.x(window.innerWidth / 2 - image.width() / 2);
                        layerRef.current.y(window.innerHeight / 2 - image.height() / 2);
                      }}
                    /> */}
                      {!hidePolygons
                        ? polygons.map((polygon, index) => (
                          <PolygonAnnotation
                            key={index}
                            points={polygon.points}
                            polygonInfo={polygon}
                            flattenedPoints={polygon.flattenedPoints}
                            isFinished={true}
                            readOnly={true}
                            notHasLine={true}
                            onPolygonClicked={(e) => {
                              onPolygonClicked(e, polygon);
                            }}
                            hasFreeUnits={getStatus(polygon)}
                            onMouseOver={(e) => {
                              handleMouseOverPolygon(e, polygon);
                            }}
                            onMouseOut={(e) => {
                              handleMouseOutPolygon(e, polygon);
                            }}
                          />
                        ))
                        : null}
                    </Layer>
                  </Stage>
                  <PolygonToolTip {...polygonToolTipObject} />

                  <Box
                    sx={{
                      width: "fit-content",
                      height: "30px",
                      background: "#FFFFFF 0% 0% no-repeat padding-box",
                      boxShadow: "0px 0 10px -3px #000",
                      opacity: "1",
                      borderRadius: "20px",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      margin: "5px 0",
                      // cursor: 'pointer',
                      position: "absolute",
                      // direction:'rtl',
                      top: "65px",
                      // left:'50%',
                      left: isopensidemenu ? "55%" : "50%",
                      transform: "translateX(-50%)",
                      fontFamily: "Cairo",
                      padding: "0 10px",
                    }}
                  >
                    {getTitle()}
                  </Box>

                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                      //  background: "#FAFAFA 0% 0% no-repeat padding-box",
                      opacity: "1",
                      position: "fixed",
                      top: "65px",
                      right: "25px",
                      zIndex: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background:
                          "rgba(255,255,255,0.8) 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0 10px -3px #000",
                        opacity: "1",
                        backgroundColor: "#1e6a99",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        changeImage(viewTrackIndex);
                      }}
                    >
                      <ArrowBackIosNewIcon
                        sx={{ color: "#fff", fontSize: "34px" }}
                      />
                    </Box>
                    {selectedImages?.length > 1 ? (
                      <Box
                        sx={{
                          width: "50px",
                          height: "50px",
                          background: "#FFFFFF 0% 0% no-repeat padding-box",
                          boxShadow: "0px 0 10px -3px #000",
                          opacity: "1",
                          borderRadius: "50%",
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          margin: "5px 0",
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          selectImageToDraw(
                            selectedImages[selectedImageIndex ? 0 : 1]
                          );
                          // setNameOfCurrentCriteriaSwitch(selectedImageIndex ? true : false)
                          setSelectedImageIndex(selectedImageIndex ? 0 : 1);
                        }}
                      >
                        <Tooltip
                          sx={{ fontFamily: "Cairo" }}
                          title={
                            <span style={{ fontFamily: "Cairo-Medium" }}>
                              {"اقلب الصورة"}
                            </span>
                          }
                        >
                          <FlipCameraAndroidIcon
                            sx={{ color: "#1E6A99", fontSize: "34px" }}
                          />
                        </Tooltip>
                      </Box>
                    ) : null}
                  </Box>
                  {imageFloors?.length > 1 ? (
                    <Box
                      sx={{
                        width: "auto",

                        height: "calc(100% - 65px)",

                        opacity: "1",
                        position: "absolute",
                        top: "65px",

                        left: isopensidemenu ? drawerwidth + 5 + "px" : "90px",
                        zIndex: 2444,
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "flex-start",
                        flexDirection: "column",
                      }}
                    >
                      <Box
                        sx={{
                          height: "calc(100% - 20px)",
                          width: "31px",
                          // background: "#FFFFFFA0 0% 0% no-repeat padding-box",
                          // boxShadow: "0px 0 10px -3px #000",
                          paddingTop: "20px",
                          paddingBottom: "20px",
                          opacity: "1",
                          borderRadius: "10px",
                          display: "flex",
                          background: "#FFFFFFA0",
                          justifyContent: "flex-start",
                          alignItems: "center",
                          margin: "5px 0",
                          marginTop: "10px",
                          cursor: "pointer",
                        }}
                        onClick={() => { }}
                      >
                        <CustomSlider
                          marks={imageFloors}
                          value={selectedFloor?.value}
                          onChange={(value) => {
                            let floorPolygons = unFilteredPolygons?.filter(
                              (item) => item?.floor_number == value
                            );

                            setPolygons(floorPolygons);

                            imageFloors?.map((floor) => {
                              if (floor?.value == value) {
                                setSelectedFloor(floor);
                              }
                            });
                          }}
                        />
                      </Box>
                    </Box>
                  ) : null}
                  <Box
                    sx={{
                      width: "auto",
                      height: "auto",
                      //  background: "#FAFAFA 0% 0% no-repeat padding-box",
                      opacity: "1",
                      position: "fixed",
                      bottom: "25px",
                      right: "25px",
                      zIndex: 200,
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                    }}
                  >
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background:
                          "rgba(255,255,255,0.8) 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0 10px -3px #000",
                        opacity: "1",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        zoomStageWithButton(true);
                      }}
                      onMouseDown={() => {
                        zoomInterval.current = setInterval(() => {
                          zoomStageWithButton(true);
                        }, 50);
                      }}
                      onMouseUp={() => {
                        clearInterval(zoomInterval?.current);
                      }}
                    >
                      <ZoomInIcon sx={{ color: "#1E6A99", fontSize: "34px" }} />
                    </Box>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background:
                          "rgba(255,255,255,0.8) 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0 10px -3px #000",
                        opacity: "1",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        zoomStageWithButton(false);
                      }}
                      onMouseDown={() => {
                        zoomInterval.current = setInterval(() => {
                          zoomStageWithButton(false);
                        }, 50);
                      }}
                      onMouseUp={() => {
                        clearInterval(zoomInterval?.current);
                      }}
                    >
                      <ZoomOutIcon sx={{ color: "#1E6A99", fontSize: "34px" }} />
                    </Box>
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                        background:
                          "rgba(255,255,255,0.8) 0% 0% no-repeat padding-box",
                        boxShadow: "0px 0 10px -3px #000",
                        opacity: "1",
                        backgroundColor: hidePolygons ? "#1e6a99" : "#ffffff",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        margin: "5px 0",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setHidePolygons(!hidePolygons);
                      }}
                    >
                      {!hidePolygons ? (
                        <RemoveRedEyeIcon
                          sx={{ color: "#1E6A99", fontSize: "34px" }}
                        />
                      ) : (
                        <VisibilityOffIcon
                          sx={{ color: "#fff", fontSize: "34px" }}
                        />
                      )}
                    </Box>
                  </Box>

                  {viewTrackTypesArray?.length || (getUnitStatusColors() && getUnitStatusColors()?.length > 0) ? <Box
                    sx={{
                      position: "absolute",
                      bottom: 0,
                      zIndex: 2000,
                      left: isopensidemenu ? drawerwidth : "84px",
                      backgroundColor: "#fff",
                      boxShadow: "0 0 7px -2px rgba(0,0,0,0.4)",
                      height: 50,
                      width: "fit-content",
                      minWidth: "60px",
                      padding: "5px 10px",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "center",
                    }}
                  >
                    {viewTrackTypesArray?.length < 3 ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 5px",
                          }}
                        >
                          <span
                            style={{
                              width: 18,
                              height: 18,
                              backgroundColor: "rgba(255,0,0,0.4)",
                              margin: "0 5px",
                            }}
                          ></span>
                          <span
                            style={{ fontFamily: "Cairo-Light", fontSize: 14 }}
                          >
                            {t("GLOBAL.notavailableUnit")}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 5px",
                          }}
                        >
                          <span
                            style={{
                              width: 18,
                              height: 18,
                              backgroundColor: "rgba(247, 211, 32,0.4)",
                              margin: "0 5px",
                            }}
                          ></span>
                          <span
                            style={{ fontFamily: "Cairo-Light", fontSize: 14 }}
                          >
                            {t("GLOBAL.partialavailableUnit")}
                          </span>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            margin: "0 5px",
                          }}
                        >
                          <span
                            style={{
                              width: 18,
                              height: 18,
                              backgroundColor: "rgba(0,255,0,0.4)",
                              margin: "0 5px",
                            }}
                          ></span>
                          <span
                            style={{ fontFamily: "Cairo-Light", fontSize: 14 }}
                          >
                            {t("GLOBAL.availableUnit")}
                          </span>
                        </Box>
                      </>
                    ) : (
                      getUnitStatusColors()
                    )}
                  </Box> : null}
                </>
              }
            </div>
          </div>
        }
      </div>
    </div>
  );
};

export default ImageMapView;
