import React, { useState } from 'react';
import Box from '@mui/material/Box';
import KeyboardArrowDown from '@mui/icons-material/KeyboardArrowDown';
import { ClickAwayListener, Grow, IconButton, ListItemButton, MenuItem, MenuList, Paper, Popper, Typography } from '@mui/material';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { StarBorder } from '@mui/icons-material';
import AppRegistrationIcon from '@mui/icons-material/AppRegistration';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router';
import GavelIcon from '@mui/icons-material/Gavel';
import { useSelector } from 'react-redux';

const primaryTextSX = {
    color: '#1F1F1F',
    fontFamily: 'Cairo',
    fontSize: '13px',
    fontWeight: 500
}

const secondaryTextSX = {
    color: '#52575D',
    fontFamily: 'Cairo-ExtraLight',
    fontSize: '12px'
}

export default function CustomTableRow(props) {
    const navigate = useNavigate()
    const [open, setOpen] = useState(false);
    const [t] = useTranslation("common");
    const [openDropDown, setOpenDropDown] = React.useState(false);
    const anchorRef = React.useRef(null);
    const rolesRedux=useSelector(state=>state.rolesData.roles)

    const handleToggle = () => {
        setOpenDropDown((prevOpen) => !prevOpen);
    };

    const onDelete = () => {
        handleClose()
        props?.onDelete && props?.onDelete()
    }

    const onEdit = () => {
        handleClose()
        props?.onEdit && props?.onEdit()
    }

    const handleClose = (event) => {
        // if (anchorRef.current && anchorRef.current.contains(event.target)) {
        //     return;
        // }

        setOpenDropDown(false);
    };


    console.log("props?.firstCellIcon", props?.firstCellIcon);

    return (


        <Box
            sx={{
                display: 'flex',

                justifyContent: 'space-between',
                alignItems: 'stretch',
                flexDirection: 'column',
                backgroundColor: '#FAFAFA'
            }}
        >
            <Box sx={{
                display: 'flex',

                justifyContent: 'space-between',
                alignItems: 'stretch',
                flexDirection: 'row',
            }}>
                {props?.firstCellIcon}
                {/* <KeyboardArrowDown
                    sx={{
                        mr: -1,
                        opacity: 1,
                        transform: open ? 'rotate(-180deg)' : 'rotate(0)',
                        transition: '0.2s',
                        color: '#52575D',
                        width: '25px'
                    }}
                /> */}

                {Object?.entries(props?.row)?.map(item => {
                    console.log(item);
                    if (item?.length > 0 && item[0] == "id" || item?.length <= 0)
                        return
                    let itemStyle = props?.rowsStyle[item[0]]
                    return (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center',
                                flexDirection: 'column',
                                flex: 1,
                                ...props?.rowsStyle[item[0]]?.BoxSX

                            }}
                        >
                            <Typography
                                variant="subtitle2"
                                sx={{
                                    ...primaryTextSX,
                                    ...props?.rowsStyle[item[0]]?.TypographySX
                                }}
                            >
                                {item?.length > 1 ? item[1] : ""}
                            </Typography>

                        </Box>
                    )
                })}

                {!props?.hideMore &&
                    <IconButton
                        sx={{
                            width: '70px',
                            "&:hover": {
                                borderRadius: '0px'
                            }
                        }}
                        ref={anchorRef}
                        id="composition-button"
                        aria-controls={openDropDown ? 'composition-menu' : undefined}
                        aria-expanded={openDropDown ? 'true' : undefined}
                        aria-haspopup="true"
                        onClick={handleToggle}
                    >
                        <MoreVertIcon />
                    </IconButton>
                }
                <Popper
                    open={openDropDown}
                    anchorEl={anchorRef.current}
                    role={undefined}
                    placement="bottom-start"
                    transition
                    disablePortal
                    sx={{
                        zIndex: 2342423
                    }}
                >
                    {({ TransitionProps, placement }) => (
                        <Grow
                            {...TransitionProps}
                            style={{
                                transformOrigin:
                                    placement === 'bottom-start' ? 'left top' : 'left bottom',
                            }}
                        >
                            <Paper sx={{
                                backgroundColor: '#994D1E',
                                borderRadius: '0 !important'
                            }}>
                                <ClickAwayListener onClickAway={handleClose}>
                                    <MenuList
                                        autoFocusItem={openDropDown}
                                        id="composition-menu"
                                        aria-labelledby="composition-button"

                                    >
                                        {props?.type == "order"&&( rolesRedux?.view_contract?.value  || rolesRedux?.add_contract?.value)?
                                            <MenuItem onClick={() => {
                                                navigate('/admin/order_contract', {
                                                    state: {
                                                        data: {
                                                            order: props?.row
                                                        }
                                                    }
                                                })
                                            }}>
                                                <GavelIcon sx={{
                                                    color: '#FFFFFF',
                                                    fontSize: '20px',
                                                    margin: '0 10px'

                                                }} />
                                                <span style={{
                                                    textAlign: "right", font: "normal normal 300 14px/22px Cairo", letterSpacing: "1.26px", color: "#FFFFFF", opacity: "1"
                                                }}>{t('ORDERS.contract')}</span>
                                            </MenuItem> :props?.type !== "order"?
                                            <>

                                                {
                                                rolesRedux?.update_customer?.value?
                                                <MenuItem onClick={onEdit}>
                                                    <AppRegistrationIcon sx={{
                                                        color: '#FFFFFF',
                                                        fontSize: '20px',
                                                        margin: '0 10px'

                                                    }} />
                                                    <span style={{
                                                        textAlign: "right", font: "normal normal 300 14px/22px Cairo", letterSpacing: "1.26px", color: "#FFFFFF", opacity: "1"
                                                    }}>{t('CUSTOMER.edit_informations')}</span>
                                                </MenuItem>:null}
                                                {
                                                rolesRedux?.delete_customer?.value?
                                                <MenuItem onClick={onDelete}>
                                                    <DeleteIcon sx={{
                                                        color: '#FFFFFF',
                                                        fontSize: '20px',
                                                        margin: '0 10px'
                                                    }} />
                                                    <span style={{
                                                        textAlign: "right", font: "normal normal 300 14px/22px Cairo", letterSpacing: "1.26px", color: "#FFFFFF", opacity: "1"
                                                    }}>{t('CUSTOMER.delete_contact')}</span>
                                                </MenuItem>:null}
                                                {
                                                  !rolesRedux?.delete_customer?.value&& !rolesRedux?.update_customer?.value  ?
                                                  <Box 
                                                  sx={{
                                                    display:'flex',
                                                    justifyContent:'center',
                                                    alignItems:'center',
                                                    padding:'5px 15px',
                                                    color:'#fff',
                                                    fontFamily:'Cairo'
                                                  }}
                                                >
                                                    {t('GLOBAL.emptyPoPup')}
                                                </Box>:null
                                                }
                                            </>:
                                                  <>  {
                                                        (props?.type !== "order"||( !rolesRedux?.view_contract?.value&&!rolesRedux?.add_contract?.value)) ?
                                                          <Box 
                                                            sx={{
                                                              display:'flex',
                                                              justifyContent:'center',
                                                              alignItems:'center',
                                                              padding:'5px 15px',
                                                              color:'#fff',
                                                              fontFamily:'Cairo'
                                                            }}
                                                          >
                                                              {t('GLOBAL.emptyPoPup')}
                                                          </Box>:null
                                                      }</>
                                            }
                                    </MenuList>
                                </ClickAwayListener>
                            </Paper>
                        </Grow>
                    )}
                </Popper>


            </Box>

        </Box >

    );
}
