import {
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Autocomplete,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import ObjectOfErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../../Global/Errors/Notification/Notification";
import CloseIcon from "@mui/icons-material/Close";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import notificationsAPI from "../../../Global/Network/Notification/index";
import customerAPI from "../../../Global/Network/Customer";
import EmployeeAPI from "../../../Global/Network/Employee";
import notificationTypeAPI from "../../../Global/Network/NotificationType";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import CustomeSelectField from "../../../General/CustomComponents/CustomeSelectField";
import CheckBoxOutlineBlankIcon from "@mui/icons-material/CheckBoxOutlineBlank";
import CheckBoxIcon from "@mui/icons-material/CheckBox";

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const [notificationAbleType, setNotificationAbleType] = useState([
    {
      id: 1,
      name: "الاتصالات",
    },
    {
      id: 2,
      name: "الوحدات",
    },
    {
      id: 3,
      name: "الزبائن",
    },
  ]);



  const handleCustomerChaneg = (values) => {
    props?.setSelectedCustomers(values);
  };
  const handleEmployeeChaneg = (values) => {
    props?.setSelectedEmployees(values);
  };
  const handleCheckAllCustomers = (check) => {
    if(check){
       props?.setSelectedCustomers(props?.customers);
    }else{
      props?.setSelectedCustomers([]);
    }
    props?.setSelectedAllCustomers(check)
  };

  const handleCheckAllEmployee = (check) => {
    if(check){
       props?.setSelectedEmployees(props?.employees);
    }else{
      props?.setSelectedEmployees([]);
    }
    props?.setSelectedAllEmployee(check)
  };

  return (
    <Box className="Container-fluid">
      <Box className="row pt-2">
        <Box className="col-12 col-sm-12 col-md-6">
        <CustomTextField
            label={t("NOTIFICATIONS.title")}
            value={props?.title}
            haswidth={true}
            error={ErrorsObject?.title?.error}
            message={ErrorsObject?.title?.message}
            readOnly={false}
            onChange={(e) => {
              props?.setTitle(e?.target?.value);
              setErrorsObject(restCustomeObject(ErrorsObject, "title"));
            }}
            onClearClick={() => {
              props?.setTitle("");
              setErrorsObject(restCustomeObject(ErrorsObject, "title"));
            }}
          />
          {/* <CustomeSelectField
            label={t("NOTIFICATIONS.notificationAbleType")}
            haswidth={true}
            value={props?.selectedNotifcationAbleType}
            list={notificationAbleType ? notificationAbleType : []}
            error={false}
            message={""}
            multiple={false}
            readOnly={false}
            onChange={(e, newValue) => {
              props?.setSelectedNotifcationAbleType(newValue);
              // setErrorsObject(
              //   restCustomeObject(ErrorsObject, "notification_type_id")
              // );
            }}
            onClearClick={() => {
              props?.setSelectedNotifcationAbleType(null);
              // setErrorsObject(
              //   restCustomeObject(ErrorsObject, "notification_type_id")
              // );
            }}
          /> */}
          <Box sx={{ margin: "10px 0" }}></Box>
          <CustomTextField
            label={t("NOTIFICATIONS.body")}
            value={props?.body}
            error={ErrorsObject?.body?.error}
            message={ErrorsObject?.body?.message}
            readOnly={false}
            haswidth={true}
            hasMultipleLine={true}
            onChange={(e) => {
              props?.setBody(e?.target?.value);
              setErrorsObject(restCustomeObject(ErrorsObject, "body"));
            }}
            onClearClick={() => {
              props?.setBody("");
              setErrorsObject(restCustomeObject(ErrorsObject, "body"));
            }}
          />

          <Box sx={{ margin: "10px 0" }}></Box>
          <CustomeSelectField
            label={t("NOTIFICATIONS.notificationType")}
            haswidth={true}
            value={props?.selectedNotificationType}
            list={props?.notificationsType ? props?.notificationsType : []}
            error={ErrorsObject?.notification_type_id?.error}
            message={ErrorsObject?.notification_type_id?.message}
            multiple={false}
            readOnly={false}
            onChange={(e, newValue) => {
              props?.setSelectedNotificationType(newValue);
              setErrorsObject(
                restCustomeObject(ErrorsObject, "notification_type_id")
              );
            }}
            onClearClick={() => {
              props?.setSelectedNotificationType(null);
              setErrorsObject(
                restCustomeObject(ErrorsObject, "notification_type_id")
              );
            }}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-6 ">

          <ContainerOfInputFields haswidth={true} customeWidth={"100%"}>
            <Box
              sx={{
                margin: "10px 0",
                marginTop:'0px',
                backgroundColor: "#fff",
                boxShadow: "0 0 10px -2px rgba(0,0,0,0.2)",
                padding: "7px 10px",
                width: "100%",
                "& .MuiTypography-root": {
                  fontFamily: "Cairo",
                },
              }}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={props?.sendPushNotification}
                    onChange={(e) => {
                      props?.setSendPushNotification(e?.target?.checked);
                    }}
                  />
                }
                sx={{
                  fontFamily: "Cairo-Regular",
                }}
                label={t("NOTIFICATIONS.sendPushNotification")}
              />
            </Box>
          </ContainerOfInputFields>

            <ContainerOfSelectField
              mainTheme={maintheme}
              customeWidth={"100%"}
              // hasError={ErrorsObject?.governorate_id?.error}
              haswidth={true}
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "10px 0",
                  // marginTop: "18px",
                  width: "100%",
                  backgroundColor: "#fff",
                  display:'flex',
                  alignItems:'center',
                  "& .MuiTypography-root": {
                    fontFamily: "Cairo",
                  },
                }}
                // className="mt-3 pt-0"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.selectAllCustomers}
                      onChange={(e) => {handleCheckAllCustomers(e?.target?.checked)}}
                    />
                  }
                  sx={{
                    fontFamily: "Cairo",
                    width:'18%'
                  }}
                  label={t("NOTIFICATIONS.all")}
                />

                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={props?.customers ? props?.customers : []}
                  disableCloseOnSelect
                  value={
                    props?.selectedCustomers ? props?.selectedCustomers : []
                  }
                  sx={{width:'82%'}}
                  getOptionLabel={(option) => option.label || ""}
                  onChange={(e, newValue) => {
                    handleCustomerChaneg(newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("NOTIFICATIONS.customers")}
                      variant="filled"
                      // sx={{
                      //   border:'none',
                      //   outline:'none',
                      // }}
                      // placeholder="Favorites"
                    />
                  )}
                />
              </Box>
            </ContainerOfSelectField>
          
            <ContainerOfSelectField
              mainTheme={maintheme}
              customeWidth={"100%"}
              // hasError={ErrorsObject?.governorate_id?.error}
              haswidth={true}
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "10px 0",
                  marginTop: "18px",
                  width: "100%",
                  backgroundColor: "#fff",
                  display:'flex',
                  alignItems:'center',
                  "& .MuiTypography-root": {
                    fontFamily: "Cairo",
                  },
                }}
                // className="mt-3 pt-0"
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={props?.selectAllEmployee}
                      onChange={(e) => {handleCheckAllEmployee(e?.target?.checked)}}
                    />
                  }
                  sx={{
                    fontFamily: "Cairo",
                    width:'18%'
                  }}
                  label={t("NOTIFICATIONS.all")}
                />

                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={props?.employees ? props?.employees : []}
                  disableCloseOnSelect
                  value={
                    props?.selectedEmployees ? props?.selectedEmployees : []
                  }
                  sx={{width:'82%'}}
                  getOptionLabel={(option) => option.label || ""}
                  onChange={(e, newValue) => {
                    handleEmployeeChaneg(newValue);
                  }}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option?.label}
                    </li>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("NOTIFICATIONS.employees")}
                      variant="filled"
                      // sx={{
                      //   border:'none',
                      //   outline:'none',
                      // }}
                      // placeholder="Favorites"
                    />
                  )}
                />
              </Box>
            </ContainerOfSelectField>
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddFormDailog(props) {
  const [t] = useTranslation("common");
  const [title, setTitle] = useState("");
  const [body, setBody] = useState("");
  const [dataoTher, setDataOther] = useState("");
  const [sendPushNotification, setSendPushNotification] = useState(false);
  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);
  const [notificationsType, setNotificationsType] = useState([]);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomers, setSelectedCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [selectedNotifcationAbleType, setSelectedNotifcationAbleType] =
    useState(null);

  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const [selectAllCustomers,setSelectedAllCustomers]=useState(false)
  const [selectAllEmployee,setSelectedAllEmployee]=useState(false)
  

  useEffect(() => {
    if (props?.object && props?.object?.id) {
      let dumData = resetErrors(ErrorsObject);
      setTitle(props?.object?.title)
      setBody(props?.object?.body)
      setSendPushNotification(props?.object?.send_push_notification==1?true:false)
      setDataOther(props?.object?.data)
      setSelectedNotificationType(props?.object?.notification_type)
      let arr=[]
      let arr2=[]
      console.log('sadasdasd=>',props?.object?.users);
      props?.object?.users?.map((user)=>{
        if(user?.userable_type&&user?.userable_type?.includes('Customer')){
          console.log('sadasdasd=>',1);
          arr.push({
            id: user?.id,
            label: user?.full_name,
          })
        }else if(user?.userable_type&&user?.userable_type?.includes('Employee')){
          console.log('sadasdasd=>',2);

          arr2.push({
            id: user?.id,
            label: user?.full_name,
          })
        }
      })
      setSelectedCustomers(arr)
      setSelectedEmployees(arr2)
    } else {
      let dumData = resetErrors(ErrorsObject);
      clearForm();
    }
  }, [props?.object]);

  const submit = async () => {
        try {
          setLoading(true);
          let dumData = resetErrors(ErrorsObject);
          let data = {
            title: title,
            body:body,
            data:dataoTher,
            notification_type_id:selectedNotificationType?.id,
            user_ids:[...selectedCustomers?.map(itm=>itm?.id),...selectedEmployees?.map(itm=>itm?.id)],
            send_push_notification:sendPushNotification?1:0
          };
          let result = null;
          if (props?.object && props?.object?.id) {
            data = {
              id: props?.object?.id,
              ...data,
            };
            result = await notificationsAPI.updateNotification({
              data: data,
            });
          } else {
            result = await notificationsAPI.addNotifications({
              data: data,
            });
          }
          if (result?.status) {
            setLoading(false);
            toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
            clearForm();
            props.loadData();
            props.setAddForm(false);
          } else {
            if (typeof result.errorMessage === "object") {
              let updatedObject = resetErrors(ErrorsObject);
              setErrorsObject(checkErrors(result.errorMessage, updatedObject));
            }
            setLoading(false);
            toast.error(t("NETWORKMESSAGE.messageError"));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(error?.message);
        }
  };

  const clearForm = () => {
    setTitle("");
    setBody("");
    setDataOther("");
    setSendPushNotification(false);
    setSelectedCustomers([]);
    setSelectedEmployees([]);
    setSelectedNotificationType(null);
    setSelectedNotifcationAbleType(null);
    setSelectedAllEmployee(false)
    setSelectedAllCustomers(false)
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadDataOfCustomers();
    loadDataOfEmployees();
    loadDataOfNotificationType();
  }, [props?.addForm, props?.object]);

  const loadDataOfCustomers = async (searchParams) => {
    setLoading(true);

    const result = await customerAPI.customersList();

    setLoading(false);
    if (result.status) {
      let arr=[]
      result?.data?.map((customer)=>{
        arr.push({
          id: customer?.user?.id,
          label: customer?.user?.full_name,
        })
      })
      setCustomers(arr);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfEmployees = async (searchParams) => {
    setLoading(true);

    const result = await EmployeeAPI.EmployeeList({
      params:{
        view_all:0,
      }
    });

    setLoading(false);
    if (result.status) {
      let arr=[]
      result?.data?.map((employee)=>{
        arr.push({
          id: employee?.user?.id,
          label: employee?.user?.full_name,
        })
      })
      setEmployees(arr);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfNotificationType = async (searchParams) => {
    setLoading(true);

    const result = await notificationTypeAPI.notificationTypesList();

    setLoading(false);
    if (result.status) {
      setNotificationsType(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  useEffect(()=>{
    if(customers&&selectedCustomers&&customers?.length==selectedCustomers?.length){
        setSelectedAllCustomers(true)
    }
  },[customers,selectedCustomers])
  useEffect(()=>{
    if(employees&&selectedEmployees&&employees?.length==selectedEmployees?.length){
        setSelectedAllEmployee(true)
    }
  },[employees,selectedEmployees])

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={
        props?.object
          ? t("NOTIFICATIONS.updateTitle")
          : t("NOTIFICATIONS.addTitle")
      }
      content={
        <RenderContent
          title={title}
          setTitle={setTitle}
          body={body}
          setBody={setBody}
          data={dataoTher}
          setData={setDataOther}
          sendPushNotification={sendPushNotification}
          setSendPushNotification={setSendPushNotification}
          notificationsType={notificationsType}
          setSelectedNotificationType={setSelectedNotificationType}
          selectedNotificationType={selectedNotificationType}
          customers={customers}
          setSelectedCustomers={setSelectedCustomers}
          selectedCustomers={selectedCustomers}
          employees={employees}
          setEmployees={setEmployees}
          selectedEmployees={selectedEmployees}
          setSelectedEmployees={setSelectedEmployees}
          setSelectedNotifcationAbleType={setSelectedNotifcationAbleType}
          selectedNotifcationAbleType={selectedNotifcationAbleType}
          setSelectedAllCustomers={setSelectedAllCustomers}
          selectAllCustomers={selectAllCustomers}
          setSelectedAllEmployee={setSelectedAllEmployee}
          selectAllEmployee={selectAllEmployee}
          open={props.addForm}
          setOpen={props.setAddForm}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
