import { createSlice } from '@reduxjs/toolkit';

const initialState={
    rows:[],
    total_unread:0,
    loadNotificationsData:false,
}
export const notificationSlice = createSlice({
  name: 'notification',
  initialState:initialState,
  reducers: {
    setLoadNotificationsData:(state,action)=>{
      state.loadNotificationsData=action.payload
   },
    setNotificationsRedux:(state,action)=>{
      state.rows=action.payload
    },
    setTotalUnreadNotificationsCountRedux:(state,action)=>{
      state.total_unread=action.payload
    },
  }
});

export const {
  setLoadNotificationsData,
  setNotificationsRedux,
  setTotalUnreadNotificationsCountRedux
} = notificationSlice.actions;



export default notificationSlice.reducer;
