import React,{useState,useEffect} from 'react'
// reactstrap components
import {styled} from '@mui/material/styles'
import {Box,Button, CircularProgress} from '@mui/material'
import {useSelector,useDispatch} from 'react-redux'
import FilterCenterFocusIcon from '@mui/icons-material/FilterCenterFocus';
import PhotoCameraIcon from '@mui/icons-material/PhotoCamera';
import swal from 'sweetalert'
import {useTranslation} from "react-i18next";
import Host from "../../assets/js/Host";
import {ImagePath} from '../../assets/js/Host'
import { toast } from 'react-toastify';
import Cookies from "universal-cookie";
import axios from "axios";
// import LandingPage from '../LandingPage'
import TextField from '@mui/material/TextField';
import { useNavigate } from 'react-router-dom';
import {
    setUserName,setUserPhone,setUserData,setUserRoleName,setFullName,setImage
  } from "../../reduxStore/UserReducer";
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import VisibilityIcon from '@mui/icons-material/Visibility';
import EmployeeAPI from '../../Global/Network/Employee/index';
import entityAPI from '../../Global/Network/Entity';
import CustomePhoneField from '../../General/CustomComponents/CustomePhoneField';
import CustomTextField from '../../General/CustomComponents/CustomTextField';
import ObjectOfErrors,{checkErrors,resetErrors,restCustomeObject} from '../../Global/Errors/Profile/Profile'
import employeeImage from '../../assets/img/user.png'
import helpers from '../../assets/js/helper';
 

const cookies = new Cookies();
// const token = cookies.get("token")
const language = cookies.get("language") ? cookies.get("language") : "ar"
const APP_CONSTANTS = {
  API_URL_IMG: `${process.env.REACT_APP_API_URL}`,
}

const CustomeHeader=styled(Box)(({theme,MainTheme,SideMenuTheme,cover_image_url,screenWidth})=>({
    width:screenWidth<=700?'100%':'95%',
    height:'300px',
    background:cover_image_url?`url(${cover_image_url})`:'#c8c8c8',
    backgroundSize:'cover',
    backgroundPosition:'center center',
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.primaryTextColor,
    position:'relative',
    top:'0px',
    borderRadius:'10px',
    zIndex:0,
    marginTop:screenWidth<=700?0:'10px',
}))

const CustomeWrapperContent=styled(Box)(({theme,MainTheme,screenWidth})=>({
    width:'100%',
    display: 'flex',
    flexDirection:screenWidth<=991?'column':'row',
    justifyContent: 'center',
    alignItems: 'start',
    position: 'relative',
    top:'-120px',    
    zIndex:100,
}))

const LeftSide=styled(Box)(({theme,MainTheme,screenWidth})=>({
  width:screenWidth<=700?'95%':screenWidth<=991?'80%':'30%',
  height:'250px',
  borderRadius:'5px',
  backgroundColor:'#fff',
  boxShadow:`1px 0px 8px 1px ${MainTheme?.paperTextColor+'33'}`,
  margin:' 10px auto',
  display: 'flex',
  flexDirection:'column',
  justifyContent: 'center',
  alignItems: 'center',
  padding:"10px 0px",
  // overflow: 'hidden',
  position: 'relative',
  top:screenWidth<=990?'0':'75px',
  "& h3":{
    position:'relative',
    top:'-50px',
    fontSize:'30px',
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
  },
  "& p":{
    position:'relative',
    top:'-50px',
    fontSize:'19px',
    color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
  },
  "& span":{
    fontSize:'16px',
    margin:'5px 30px'
  },
  '& .headerPanner':{
    position: 'absolute',
    backgroundRepeat: 'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center',
    top: 0,
    left: 0,
    right: 0,
    height: '100px',
    // backgroundImage:"url('https://demos.creative-tim.com/now-ui-dashboard-react/static/media/bg5.056eb058.jpg')"
  },
  borderRadius: "5px", boxShadow: "5px 5px 40px #c5c5c5,-5px -5px 40px #fbfbfb"

}))

const RightSide=styled(Box)(({theme,MainTheme,screenWidth})=>({
    width:screenWidth<=700?'95%':screenWidth<=991?'80%':'50%',
    height:'400px',
    borderRadius:'5px',
    backgroundColor:'#fff',
    boxShadow:`1px 0px 8px 1px ${MainTheme?.paperTextColor+'33'}`,
    zIndex:"2000 !important",
    margin:' 10px auto',
    padding:'10px 20px',
    position: 'relative',
    '& p':{
        fontSize:'27px',
        color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor,
    },
    borderRadius: "5px", boxShadow: "5px 5px 40px #c5c5c5,-5px -5px 40px #fbfbfb"

}))

const CustomeImageProfile=styled(Box)(({theme,MainTheme,img})=>({
    width:'140px',
    height:'150px',
    borderRadius:'50%',
    backgroundImage:`url('${img}')`,
    backgroundColor:MainTheme?.paperColor+'aa',
    backgroundRepeat:'no-repeat',
    backgroundSize:'cover',
    backgroundPosition:'center',
    border:'2px solid white',
    boxShadow:'0 2px 5px -4px #000',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'relative',
    top:'-80px',
    cursor:'pointer',
    '&:hover':{
      backgroundColor:'rgba(0,0,0,0.4)'
    },
    '& .chooiceImage':{
        position:'absolute',
        bottom:2,
        right:'12px',
        cursor:'pointer',
        backgroundColor:'rgba(255,255,255,0.8)',
        borderRadius:'50%',
        padding:'1px',
        zIndex:'2000',
        color:'#116e90 !important'
    }

}))

export default function Profile() {
   const directions=useSelector(state =>state.settingsData.directions)
   const MainTheme=useSelector(state =>state.settingsData.MainTheme)
   const SideMenuTheme=useSelector(state =>state.settingsData.SideMenuTheme)
  const userData = useSelector((state) => state.userData.userData);

   const navigate=useNavigate()
   const {t, i18n} = useTranslation('common');
   const token = cookies.get("token")
   const [loading,setLoading]=useState(false)
   const userName= cookies.get('user_name')?cookies.get('user_name'):''
   const fullName= cookies.get('user_name')?cookies.get('user_name'):''
   const image=''
   const dispatch=useDispatch();
   const [showPassword,setShowPassword]=useState(false)
   const [userInfo,setUserInfo]=useState({
      name:userName,
      phone:'',
      image:'',
      full_name:'',
      img_url:'',
      password:'',
      email:'',
      phoneErroreMessage:'',
      phoneErrore:false,
      emailErroreMessage:'',
      emailErrore:false,
      image_id:'',
      employee_id:'',
      cover_image_url:''
   })
   const [ErrorsObject,setErrorsObject]=useState(ObjectOfErrors)

   const [phone,setPhone]=useState('');
   const [stateCode,setStateCode]=useState(null);
   useEffect(()=>{
    console.log('image',ImagePath+image)
    setUserInfo({
        ...userInfo,
        image: ImagePath+image,
        img_url:ImagePath+image
    })
   },[image])

   const [firstPhoneMessageError,setFirstPhoneMessageError]=useState({
    error:false,
    message:''
   })


   const [screenWidth,setScreenWidth]=useState(window.innerWidth)

   useEffect(()=>{
    let dumData=resetErrors(ErrorsObject)
 
    getUserData()
    window.addEventListener('resize',()=>{
        setScreenWidth(window.innerWidth)
    })
     return()=>{

     }
   },[])

   const openImage=(e)=>{
    let userIconeCamera=document.getElementById('userImage')
    if(userIconeCamera)
    userIconeCamera.click()
   }
   
   const updateUserInfo=async () => {
    
    let data=null
    if(!userInfo?.full_name){
        toast.warning('اسم المستخمد مطلوب')
        return
    }
    if(!phone){
        toast.warning('رقم الهاتف مطلوب')
        return
    }
    if(userInfo?.phoneErrore)
    {
      console.log('messgae','false');

      toast.warn('رقم الهاتف بصيغة غير صحيحة !')
      return
    }
    if((phone?.length<3||phone?.length>11)&&!isNaN(phone)){
      toast.warning('رقم الهاتف يجب ان يكون اكثر من 3 ارقام واقل من 12 رقم')
      return
    }else if(isNaN(phone)){
        toast.warning('صيغة رقم الهاتف غير صحيحة')
        return
    }

    if(userInfo?.email&&checkEmail(userInfo?.email)){
      setUserInfo({
        ...userInfo,
        emailErrore:true,
        emailErroreMessage:'صيغة البريد الالكتروني غير صحيحة !'
      })
      return
    }

  
    try{
      setLoading(true)
      let params={
          
      }
      var str =userInfo?.full_name?userInfo?.full_name?.replace(/ +(?= )/g,''):'';
      str=str?str.trim():'';
      // let dataList={
      //   userName:str,
      //   full_name:userInfo?.full_name,
      //   phone:phone,
      //   email:userInfo?.email
      // }
      let resultOfPhone=handleMergePhoneWithStateCode(phone,stateCode)
      let formData=new FormData();
      formData.append('username',userInfo?.name)
      formData.append('full_name',str)
      formData.append('phone',resultOfPhone)
      formData.append('email',userInfo?.email)

      formData.append('_method','put')

      data=await EmployeeAPI.UpdateEmployee({
          params:params,
          data:formData,
          id:userInfo?.employee_id,
      });

      if(data&&data?.status){
        setLoading(false)
        toast.success(t('NETWORKMESSAGE.UpdateCustomerMessageSuccess'))
        setUserInfo({
          ...userInfo,
          name:userInfo?.name,
          full_name:str,
        })

        let userObject={
          username:userInfo?.name,
          full_name:str,
          image_url:userData?.image_url,
        }
        dispatch(setUserData(userObject))
        localStorage.setItem('userData',JSON.stringify(userObject))

      }else{
        if (typeof data.errorMessage === "object") {
          let updatedObject = resetErrors(ErrorsObject)
          setErrorsObject(checkErrors(data.errorMessage, updatedObject))   
        }
        setLoading(false)
        toast.warning(t('NETWORKMESSAGE.deleteErrorr'))

      }
    }catch(e){
       setLoading(false)
       console.log(e?.message)
    }
   }


const addImageToUser=async(e)=>{
    let images=new FormData()

    if( userInfo?.image_id){
      await handleDeleteImage()
    }
        images.append(`image`, e.target.files[0]);
        images.append(`type_id`, userInfo?.employee_id);
        images.append(`image_type_id`,1);
        images.append(`type`,'employee');
        let result = await entityAPI.AddRequestFile({
          data: images,
          type:'employee',
        });

        if (result?.status) {
            setUserInfo({
              ...userInfo,
              img_url:window.URL?.createObjectURL(e.target.files[0]),
              image_id:getImageProfileID(result?.data?.images)
            })
            let userObject={
              username:userInfo?.name,
              full_name:userInfo?.full_name,
              image_url:getImageProfile(result?.data?.images),
            }
            dispatch(setUserData(userObject))
            localStorage.setItem('userData',JSON.stringify(userObject))

        } else {
        

        }
      

}

const handleDeleteImage = async () => {
     
  let params = {};
  let result = await entityAPI.DeleteRequestFile({
    params: params,
    type:'employee',
    id: userInfo?.image_id,
  });

  if (result?.status && result?.data?.success) {
    setUserInfo({
      ...userInfo,
      img_url:'',
    })
    return true
  } else {
    return false

  }

};

const setNewUserData=(name,value)=>{
// str.trim();
setUserInfo({
    ...userInfo,
    [name]:value ,
    emailErrore:false,
  emailErroreMessage:''
})

} 

const getUserData=async()=>{
let data=null
try{
  let params={
      id:cookies.get('userable_id')
  }


  data=await EmployeeAPI.ShowEmployee({
      params:params,
  });
  if(data&&data?.status){
      console.log('userInfo',data?.data)
      let objectPhone=handleSplitPhoneWithStateCode(data?.data?.user?.phone)
      setUserInfo({
        ...userInfo,
        phone:objectPhone?.phone,
        full_name:data?.data?.user?.full_name,
        name:data?.data?.user?.username,
        img_url:helpers.getImageProfile(data?.data?.images),
        email:data?.data?.user?.email,
        image_id:getImageProfileID(data?.data?.images),
        cover_image_url:helpers.getImageCover(data?.data?.images),
        employee_id:data?.data?.id,
        })
        setPhone(objectPhone?.phone)
        setStateCode(objectPhone?.code)

        let userObject={
          username:data?.data?.user?.username,
          full_name:data?.data?.user?.full_name,
          image_url:helpers.getImageProfile(data?.data?.images),
        }
        dispatch(setUserData(userObject))
        localStorage.setItem('userData',JSON.stringify(userObject))

  }else{
    toast.warning(t('NETWORKMESSAGE.messageError'))
  }
}catch(e){
    console.log(e?.message)
}
}

const handleMergePhoneWithStateCode=(phone,code)=>{
    let str_phone=phone?.slice(0,1)
    if(str_phone!=0){
      return code+'-'+phone
    }else{
      str_phone=phone?.slice(1)
      return code+'-'+str_phone
    }
}

const handleSplitPhoneWithStateCode=(phone)=>{
   if(phone?.includes('-')){
      let temp=phone?.split('-');
      return{
         code:temp[0]?temp[0]:'',
         phone:temp[1]?'0'+temp[1]:''
      }
  }else{
   return{
      code:'',
      phone:phone,
   }
  }
}

const reformatDisplayPhone=(phone)=>{
    let cuirrentphone=phone
    
    if(phone){
        if(phone.slice(0,1)=='+'){
            cuirrentphone=phone.slice(1)+phone.slice(0,1);
            console.log('currentphone',cuirrentphone)
        }else{
            return phone
        }
    }
    return cuirrentphone
}


  
const checkEmail=(value)=>{

    var email = value;
    var filter = /^([a-zA-Z0-9_\.\-])+\@(([a-zA-Z0-9\-])+\.)+([a-zA-Z0-9]{2,4})+$/;

    if (!filter.test(email)) 
     return true;
    else return false
 }


 const getImageProfile = (images) => {
  let imageUrl = null;
  let lastImageProf = null;
    images?.length &&
    images?.map((itm) => {
      if (itm?.image_type_id == 1) {
        lastImageProf = itm?.image_url;
      }
    });
  if (lastImageProf) imageUrl = lastImageProf;

  console.log('image_Pro',imageUrl)
  return process.env.REACT_APP_API_URL_image+imageUrl;
};
const getImageProfileID = (images) => {
  let id = null;

    images?.length &&
    images?.map((itm) => {
      if (itm?.image_type_id == 1) {
        id = itm?.id;
      }
    });
 return id;
};

const getImageCover = (images) => {
  let imageUrl = null;
  let lastImageCover = null;
  images?.length &&
    images?.map((itm) => {
      if (itm?.image_type_id == 2) {
        lastImageCover = itm?.image_url;
      }
    });
  if (lastImageCover) imageUrl = lastImageCover;
  console.log('image_Cov',imageUrl)

   if(imageUrl)
    return process.env.REACT_APP_API_URL_image+imageUrl;

    return ''
};

const handlePhoneChange=(name,e)=>{
  let inputtxt=e;
  var phoneno = /^\d{11}$/;
     let object={
        error:'',
        message:false,
     }
     if(isNaN(inputtxt)){
        setFirstPhoneMessageError({
           message:'يجب ان يكون الهاتف رقم',
           error:true,
        })
        return false
     }

     if(e?.length==11&&!isNaN(inputtxt))
     {
        object={
           message:'',
           error:false,
        }
     }
     else if(e?.length>=11)
     {
        object={
           message:'رقم الهاتف بصيغة غير صحيحة !',
           phone:true,
        }
     }else{
        object={
           message:'',
           phone:false,
        }
     }
     setFirstPhoneMessageError({
        ...object,
     })
  
}


  return (
    <Box sx={{display:'flex',justifyContent:'center',alignItems:'center',flexDirection:'column'}}>
    <CustomeHeader screenWidth={screenWidth} MainTheme={MainTheme} SideMenuTheme={SideMenuTheme} cover_image_url={userInfo.cover_image_url}/>

    <CustomeWrapperContent screenWidth={screenWidth} MainTheme={MainTheme}>
        <LeftSide screenWidth={screenWidth} MainTheme={MainTheme}>
            <div className="headerPanner"></div>
            <CustomeImageProfile onClick={openImage}  MainTheme={MainTheme} img={userInfo?.img_url?userInfo?.img_url:employeeImage}>
                <input type="file" style={{display: 'none'}} id='userImage' onChange={(e)=>{
                   
                  //  uploadImages(e.target.files[0])
                  addImageToUser(e)
                  
                }} accept="image/png, image/gif, image/jpeg ,image/jpg"/>
                <PhotoCameraIcon onClick={openImage} size={30} className="chooiceImage" sx={{color:MainTheme?.disabledGlobalText?MainTheme?.gloablTextColor:MainTheme?.paperTextColor}}/>
            </CustomeImageProfile>
            <h3 style={{fontFamily:'Cairo-Bold'}}>{userInfo?.name}</h3>
            <p style={{fontFamily:'Cairo'}}>{userInfo?.full_name}</p>
            {/* <span>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Totam aperiam natus quis odio obcaecati pariatur sunt deserunt ipsam quaerat voluptatibus?</span> */}
        </LeftSide>
        <RightSide screenWidth={screenWidth} MainTheme={MainTheme}>
            <p style={{display: 'flex',justifyContent: 'start',fontFamily:'Cairo-Medium'}}>{t('PROFILE.USER_TITLE')}</p>
            <Box 
              sx={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
                flexDirection:'column',
                flexWrap: 'wrap',
                width: '100% !important',
                '& .muiltr-a4ms82-MuiInputBase-root-MuiOutlinedInput-root-MuiSelect-root,& svg,& .muirtl-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input':{
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                 },
                '& input':{
                    color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                
                    },
                    '& .MuiOutlinedInput-root':{
                        borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiInputLabel-root':{
                        // backgroundColor:`${MainTheme?.paperTextColor+'22'} !important`,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiFormControl-root':{
                      // border:`1px solid ${MainTheme?.paperTextColor} !important`,
                      color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                    },
                    '& .MuiOutlinedInput-root': {
                      '& fieldset': {
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                      },
                      '&:hover fieldset': {
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                      },
                      '&.Mui-focused fieldset': {
                        borderColor: MainTheme?.secondaryColor,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                      },
                    },
                    '& .MuiOutlinedInput':{
                      // borderColor:`1px solid ${MainTheme?.paperTextColor} !important`,
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        // border: "0 none",
                     },
                     '& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root:before,& .muiltr-1ptx2yq-MuiInputBase-root-MuiInput-root:before':{
                        borderColor:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.paperTextColor,
                     },
                     '& .muirtl-1ptx2yq-MuiInputBase-root-MuiInput-root .Mui-focused':{
                        borderColor: MainTheme?.secondaryColor,
                        color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.secondaryColor,
                     },
                     '& p,& span,& label,& input,& button':{
                        fontFamily:'Cairo'
                     },
                     '& .MuiFormHelperText-root':{
                      fontSize:'14px !important'
                     }
              }}
              fullWidth
            >
              
                <Box
                 sx={{
                  display:'flex',
                  alignItems:'center',
                  // flexWrap:'wrap',
                  width:'100%',

                 }}
                >
                  <CustomTextField
                        label={t('PROFILE.USERNAME')}
                        value={userInfo?.name}
                        error={ErrorsObject?.username?.error}
                        message={ ErrorsObject?.username?.message}
                        hasMultipleLine={false}
                        haswidth={true}
                        readOnly={false}
                        onChange={(e) => {
                          setNewUserData('name', e.target.value);
                          setErrorsObject(restCustomeObject(ErrorsObject,'username'))
                        }}
                        onClearClick={() => {
                          setNewUserData('name', '');
                          setErrorsObject(restCustomeObject(ErrorsObject,'username'))
                        }}
                  />
                  <CustomTextField
                      label={t('PROFILE.FULL_NAME')}
                      value={userInfo?.full_name}
                      error={ErrorsObject?.full_name?.error}
                      message={ ErrorsObject?.full_name?.message}
                      hasMultipleLine={false}
                      haswidth={true}
                      readOnly={false}
                      onChange={(e) => {
                        setNewUserData('full_name', e.target.value);
                        setErrorsObject(restCustomeObject(ErrorsObject,'full_name'))
                      }}
                      onClearClick={() => {
                        setNewUserData('full_name', '');
                        setErrorsObject(restCustomeObject(ErrorsObject,'full_name'))
                      }}
                  />
                </Box>
                    <Box sx={{marginTop:'10px'}}></Box>
                <CustomTextField
                    label={t('PROFILE.email')}
                    value={userInfo?.email}
                    error={ErrorsObject?.email?.error}
                    message={ErrorsObject?.email?.message}
                    hasMultipleLine={false}
                    customWidth={'100%'}
                    customePadding="0px 0 0 0"
                    haswidth={true}
                    readOnly={false}
                    onChange={(e) => {
                      setNewUserData('email', e.target.value);
                      checkEmail(e)
                      setErrorsObject(restCustomeObject(ErrorsObject,'email'))
                    }}
                    onClearClick={() => {
                      setNewUserData('email','');
                      setErrorsObject(restCustomeObject(ErrorsObject,'email'))
                    }}
                  />
          
                  <CustomePhoneField
                    value={phone}
                    title={t("PROFILE.PHONE")}
                    paddingHorizontal="0 10px"
                    direction="rtl"
                    onChange={(e)=>{
                        if(!isNaN(e?.target?.value)){
                            setPhone(e?.target?.value)
                            handlePhoneChange('second_phone',e?.target?.value)
                        }
                    }}
                    onClearClick={()=>{
                        setPhone('')
                        setStateCode('')
                    }}
                    haswidth={true}
                    customeWidth={'100%'}
                    stateCode={stateCode}
                    setStateCode={setStateCode}
                    messageStateCode={firstPhoneMessageError?.message}
                    error={userInfo?.phoneErrore}
                    message={firstPhoneMessageError?.message}
                  />

                 

                <Box
                    //  
                    sx={{
                      width: "100%",
                      display: "flex",
                      justifyContent: "end",
                      mr:0.6
                    }}
                    fullWidth
                    //   
                    // autoComplete="off"
                    >
                          <Button 
                          onClick={updateUserInfo}
                          sx={{
                            mt:2,
                            backgroundColor:MainTheme?.secondaryColor,
                            color:MainTheme?.disabledGlobalText?MainTheme.gloablTextColor:MainTheme?.oppositeSecondaryColor,
                            }} variant="contained">
                              
                              {t('PROFILE.UPDATE_BUTTON')}</Button>

                </Box>
            </Box>

        </RightSide>
    </CustomeWrapperContent>
  
     {/* {
        loading?
        <div
          style={{
            width:'100vw',
            height:'100vh',
            position:'fixed',
            top:0,
            right:0,
            left:0,
            bottom:0,
            zIndex:324234,
            backgroundColor:'rgba(0,0,0,0.3)',
            display:'flex',
            justifyContent:'center',
            alignItems:'center',
          }}
        >
          <CircularProgress  
             size={40}
             style={{
              color:'#fff',
              
             }}
          />
        </div>
        :null
     } */}
    </Box>
  )
}
