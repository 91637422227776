import callAPI from '../ApiConfig'
import store from '../../../app/store'

const compoundDepartmentPhones = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_department_phones",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const compoundDepartmentPhoneList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_department_phones_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCompoundDepartmentPhone = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_department_phones",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateCompoundDepartmentPhone = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_department_phones/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCompoundDepartmentPhone= async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_department_phones/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const CompoundDepartmentPhoneAPI={
    compoundDepartmentPhones:compoundDepartmentPhones,
    compoundDepartmentPhoneList:compoundDepartmentPhoneList,
    addCompoundDepartmentPhone:addCompoundDepartmentPhone,
    updateCompoundDepartmentPhone:updateCompoundDepartmentPhone,
    deleteCompoundDepartmentPhone:deleteCompoundDepartmentPhone,
}
export default CompoundDepartmentPhoneAPI