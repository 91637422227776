import {
  Box,
  Button,
  FormControlLabel,
  Switch,
} from "@mui/material";
import React, { useState, useEffect, useCallback, useMemo } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import departmentObject, {
  checkErrors,
  resetErrors,
  resetCurrentObject,
  currentObjectMerge
} from "../../../Global/Errors/Department/Department";
import { toast } from "react-toastify";
import { setGlobalLoading } from "../../../reduxStore/SettingsReducer";
import { useDispatch } from "react-redux";
import Grid from '../../Grid/Grid'
import SearchInput from "../../Components/SearchInput";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from '@mui/x-data-grid'
import buildingAPI from "../../../Global/Network/Building";



const FilterSearchContent = (props) => {
  const [t] = useTranslation("common");

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.buildingNameSearch}
        setValue={props?.setBuildingNameSearch}
        title={t("BUILDING_SELECTION.building_name_search")}
      />
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props?.checkAllselected}
              onChange={(e) => {
                props?.setCheckAllselected(e.target.checked);
                if (e.target.checked) props?.setCheckAllNotSelected(false);
              }}
            />
          }
          label={t('BUILDING_SELECTION.building_selected')}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "start",
          width: "100%",
          marginLeft: "20px !important",
          marginTop: "10px !important",
          "& .MuiTypography-root": {
            fontFamily: "Cairo-Medium",
          },
        }}
      >
        <FormControlLabel
          control={
            <Switch
              checked={props?.checkAllNotSelected}
              onChange={(e) => {
                props?.setCheckAllNotSelected(e.target.checked);
                if (e.target.checked) props?.setCheckAllselected(false);
              }}
            />
          }
          label={t('BUILDING_SELECTION.building_unselected')}
        />
      </Box>
    </Box>
  );
};

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(departmentObject);
  const [selectedImage, setSelectedImage] = useState("");
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const [isUpdateImage, setIsUpdatedImage] = useState(false);
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [nameForUpdatedImageValue, setNameForUpdatedImageValue] = useState("");
  const [nameForUpdatedImage, setNameForUpdatedImage] = useState(false);
  const [fileForUpdatedImage, setFileForUpdatedImage] = useState(null);
  const [fileForUpdatedImageExtention, setFileForUpdatedImageExtention] =
    useState("");
  const [isUploadingEditMode, setIsUploadingEditMode] = useState(false);
  const [columns, setColumns] = useState([
    {
      ...GRID_CHECKBOX_SELECTION_COL_DEF,
      width: 40,
    },
    { headerName: t("BUILDING_SELECTION.building_id"), field: "id", flex: 0.5 },
    { headerName: t("BUILDING_SELECTION.building_name"), field: "name", flex: 2 },
  ]);
  const handleCheckBoxChange = useCallback((rows) => {

    props?.setListOfSelectedRows(rows);
    // let arr = [];

    // props?.buildings?.length && props?.buildings?.map((item) => {
    //   if (rows?.find((e) => e == item?.id)) {
    //     arr.push(item);
    //   }
    // });

    // props?.setListOfSelectedRows(arr);
  }, []);


  return (
    <Box className="Container-fluid" sx={{
      width: '100%'
    }}>
      <Box className="row d-flex justify-content-center align-items-start">
        <Box className="col-12 col-sm-12 col-md-12 my-3 px-0"
          sx={{
            '& .MuiCheckbox-root': {
              color: '#1e6a99 !important',
              fill: '#1e6a99 !important'

            }
          }}
        >
          <Grid
            rows={props?.buildings}
            columns={columns}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={props?.buildings?.length}
            checkScreenSize={screenwidth}
            pageCount={Math.ceil(props?.buildings?.length / pageSize)}
            openFilterColumn={null}
            elementClicked={null}
            setOpenFilterColumn={null}
            setColumnFilterValue={null}
            columFilterValue={null}
            clearFunction={props?.handleClearInAddvancSearch}
            searchFunction={props?.handleSearchInAddvancSearch}
            creatFunction={null}
            ExportFunction={null}
            importFunction={null}
            mapFunction={null}
            telegramFunction={null}
            hasCreate={false}
            hasImport={false}
            // paginationMode="client"
            hasExport={false}
            hasMap={false}
            hasTetelgram={false}
            filterChilds={
              <FilterSearchContent
                setBuildingNameSearch={props?.setBuildingNameSearch}
                buildingNameSearch={props?.BuildingNameSearch}
                setCheckAllselected={props?.setCheckAllselected}
                checkAllselected={props?.checkAllselected}
                setCheckAllNotSelected={props?.setCheckAllNotSelected}
                checkAllNotSelected={props?.checkAllNotSelected}
              />
            }
            filterHasSelectCounter={true}
            filterHasSelectCounterValue={props?.listOfSelectedRows?.length}
            notHaveAdvancSearch={false}
            hasSelectOption={true}
            handleCheckBoxChange={handleCheckBoxChange}
            listOfSelectedRows={props?.listOfSelectedRows}
            // handleRowCellChange={handleRowCellChange}
            paginationMode={"client"}
          />
        </Box>
      </Box>
    </Box>
  );
};


export default function BuildingSelection(props) {
  const [t] = useTranslation("common");

  const [buildings, setBuildings] = useState([])
 
  const [filterdBuildings, setFilterdBuildings] = useState([])
  const [buildingNameSearch, setBuildingNameSearch] = useState()
  const [isSearchApplay, setIsSearchApplay] = useState(false)
  const [checkAllselected, setCheckAllselected] = useState(false)
  const [checkAllNotSelected, setCheckAllNotSelected] = useState(false)
 
  const [building, setBuilding] = useState('')

 

  useEffect(() => {
    
    loadData()
   
  }, [ props?.selectedBuildings]);

  const loadData = async () => {
    let data = null;
    try {
      data = await buildingAPI.buildingsList({
        params: {
          block_id: props?.blockId,
          has_units:0
        }
      });
      if (data && data?.status) {
        setBuildings(data?.data?.length>0?data?.data?.filter(item=>item?.id!=props?.buildingId):[])
      } else {
        setBuildings([])
        toast.warn(t('NETWORKMESSAGE.messageError'))
      }
    } catch (error) {
      console.log(error?.message)
    }
  }

  const handleSearchInAddvancSearch = () => {
    let arr = []
    if (buildingNameSearch) {
      setIsSearchApplay(true)

      buildings?.length && buildings?.map((item) => {
        if (item?.name && item?.name?.toLowerCase().includes(buildingNameSearch?.toLowerCase())) {
          arr.push(item)
        }
      })
      setFilterdBuildings(arr)
    } else {
      arr = [...buildings];
      setFilterdBuildings(buildings)
    }
    if (checkAllselected) {
      setFilterdBuildings(arr.filter(itm => props?.listOfSelectedRows?.includes(itm?.id)))

    }
    if (checkAllNotSelected) {
      setFilterdBuildings(arr.filter(itm => !props?.listOfSelectedRows?.includes(itm?.id)))
    }
  }
  const handleClearInAddvancSearch = () => {
    setIsSearchApplay(false)
    setBuildingNameSearch('')
    setFilterdBuildings(buildings)
    setCheckAllselected(false)

  }

  useEffect(() => {
    if (buildings) {
      setFilterdBuildings(buildings)
    }
  }, [buildings])


  return (
  
      <RenderContent
        setBuildings={setBuildings}
        buildings={filterdBuildings}
        building={building}
        setBuilding={setBuilding}
        allBuildings={buildings}

        buildingNameSearch={buildingNameSearch}
        setBuildingNameSearch={setBuildingNameSearch}
        handleSearchInAddvancSearch={handleSearchInAddvancSearch}
        handleClearInAddvancSearch={handleClearInAddvancSearch}
        setListOfSelectedRows={props?.setListOfSelectedRows}
        listOfSelectedRows={props?.listOfSelectedRows}
        isSearchApplay={isSearchApplay}
        checkAllselected={checkAllselected}
        setCheckAllselected={setCheckAllselected}
        setCheckAllNotSelected={setCheckAllNotSelected}
        checkAllNotSelected={checkAllNotSelected}
        
      />

  );
}
