import { Box, Divider, Typography } from '@mui/material'
import React,{useState, useEffect} from 'react'
import CustomButton from '../../../General/CustomComponents/CustomButton'
import CustomTable from '../CustomTable/CustomTable'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import Person2Icon from '@mui/icons-material/Person2';
import AddFormDailog from './ContactsTabComponents/AddFormDailog';
import swal from 'sweetalert';
import customerContactAPI from '../../../Global/Network/CustomerContact'
import { toast } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';

const addButtonSX = {
    width: 'max-content',
    fontFamily: 'Cairo',
    fontSize: '13px',
    backgroundColor: '#FFFFFF66',
    color: '#994D1E',
    paddingLeft: '0px',
    paddingRight: '0px',
    border: 'none',

}
const addButtonHoverStyle = {
    backgroundColor: '#FFFFFF',
    color: '#994D1EA0',
    border: 'none'
}

const columns = [
    { title: 'اسم المرافق',align:'right',alignItems:'flex-start' },
    { title: 'رقم الهاتف',align:'center',alignItems:'center' },
    { title: 'العنوان' ,align:'center',alignItems:'center'},
    { title: 'صلة المرافق',align:'center',alignItems:'center' },
]

const rowsStyle = {
    name: {
        BoxSX: {
            display: 'flex',
            alignItems: 'center',
            justifyContent:'flex-start',
            flexDirection:'row',
        },
        TypographySX: {
            paddingLeft: '10px',
            textAlign:'left'
        }
    },
    phone: {
       
        TypographySX: {
            direction: 'rtl'
        }
    },
}
function ContactsTab(props) {
    const [rows, setRows] = useState([])
    const [showForm, setShowForm] = useState(false)
    const [selectedObject, setSelectedObject] = useState(null)
    const [loading, setLoading] = useState(false)
    const [t] = useTranslation('common')
    const rolesRedux=useSelector(state=>state.rolesData.roles)

    useEffect(() => {
        setRows(props?.row?.customer_contacts?.map(customerContact=>{
            return {
                id:customerContact?.id,
                name:customerContact?.name,
                phone:customerContact?.phone,
                address:customerContact?.address,
                relation:customerContact?.relation_type?.name
            }
        }))
      return () => {
      }
    }, [props?.row])

    const onEdit = (row) => { 
        let selectedContact = props?.row?.customer_contacts?.filter(item=>item?.id == row?.id)
        if(selectedContact?.length>0)
        {
            setSelectedObject(selectedContact[0])
            setShowForm(true)
        }
        
        
    }
    const onDelete = async(row) => { 
            swal({
              title: '?هل انت متأكد',
              text: "لن تتمكن من التراجع عن هذا!",
              icon: 'warning',
              buttons: ["الغاء!", "نعم!"],
              dangerMode: true,
            }).then(async (willDelete) => {
              console.log('1')
              if (willDelete) {
        
                try {
                  setLoading(true)
        
                  let result = null;
                  if (row && row?.id) {
        
                    result = await customerContactAPI.deleteCustomerContact({
                      data: {
                        id: row.id,
                      }
                    });
                  }
        
                  if (result?.status) {
                    setLoading(false)
                    // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
                    swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
                      icon: "success",
                    });
                    props?.loadData()
                  } else {
                    setLoading(false)
                    toast.error(t('NETWORKMESSAGE.deleteErrorr'))
                  }
                  setLoading(false)
                } catch (error) {
                  setLoading(false)
                  toast.error(t(error?.message))
                }
              } else {
                // alert('canceled')
              }
            })
          
    }
    
    return (
        <Box
            sx={{
                flex: 1,
                margin:'15px 0'

            }}
        >
            <AddFormDailog customer={props?.row} object={selectedObject} addForm={showForm} setAddForm={setShowForm} loadData={props?.loadData} />
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    padding:'10px 0'
                }}
            >   {
                rolesRedux?.add_customer?.value ?
                <Box
                    sx={{
                        flex: 1,
                        marginLeft: '40px',
                        display: 'flex',

                    }}

                >
                    <CustomButton
                        text={"اضافة مرافق جديد"}
                        sx={addButtonSX}
                        buttonHoverStyle={addButtonHoverStyle}
                        startIcon={<AddCircleIcon />}
                        onClick={() => {
                            setShowForm(true)
                        }}

                    />
                   
                </Box>:null
                }
                <Box
                    sx={{
                        flex: 1,
                        justifyContent: 'center',
                        display: 'flex',
                        alignItems: 'center',
                        flexDirection: 'column'
                    }}
                >
                    <Box
                        sx={{
                            justifyContent: 'center',
                            display: 'flex',
                            alignItems: 'center',
                            flexDirection: 'column'
                        }}
                    >
                        <Typography
                            sx={{
                                color: '#994D1F',
                                fontSize: '16px',
                                fontFamily: 'Cairo',
                                fontWeight: 'bold',
                            }}
                        >
                            المرافقين للعميل
                        </Typography>
                        <Divider sx={{ width: '100%', opacity: 1, height: '2px', color: '#994D1E' }} />
                    </Box>
                </Box>
                <Box
                    sx={{
                        flex: 1
                    }}
                >

                </Box>
            </Box>
            <CustomTable
                sx={{
                    marginLeft: '16px',
                    marginRight: '16px',
                    borderWidth: '1px',
                    borderColor: '#994D1E',
                    borderStyle: 'solid',
                    marginBottom: '5px',
                    backgroundColor: '#994D1E'
                }}
                 
                onDelete={(row)=>{onDelete(row)}}
                onEdit={(row)=>{onEdit(row)}}
                columns={columns}
                rows={rows}
                rowsStyle={rowsStyle}
                firstCellIcon={<Person2Icon sx={{
                    color: '#7D8185',
                    alignSelf: 'center'
                }} />}
            />
        </Box>
    )
}

export default ContactsTab