import {
  Box,
  Divider,
  IconButton,
  List,
  ListItem,
  MenuItem,
  Typography,
} from "@mui/material";
import React from "react";
import { useTranslation } from "react-i18next";
import * as FaIcons from "react-icons/fa";
import * as CgIcons from "react-icons/cg";
import { useDispatch, useSelector } from "react-redux";
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import Menu from "@mui/material/Menu";
import { styled, alpha } from "@mui/material/styles";


const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: "right",
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: "right",
      }}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiPaper-root": {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 180,
      color:
        theme.palette.mode === "light"
          ? "rgb(55, 65, 81)"
          : theme.palette.grey[300],
      boxShadow:
        "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
      "& .MuiMenu-list": {
        padding: "4px 0",
      },
      "& .MuiMenuItem-root": {
        "& .MuiSvgIcon-root": {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        "&:active": {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity
          ),
        },
      },
    },
  }));

export default function FinancialCard({ itm, ...props }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const [t] = useTranslation("common");
  
    const dispatch = useDispatch();
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <React.Fragment>
      {props?.isFatoora ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
          }}
        >
          <FaIcons.FaFileAlt
            style={{ color: "#349CDD", fontSize: "22px" }}
            className="mx-1"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
              cursor: "pointer",
            }}
            className="mx-1"
          >
            <span
              style={{
                textAlign: "right",
                textDecoration: "underline",
                font: "normal normal bold 14px/26px Cairo",
                letterSpacing: "0px",
                color: "#349CDD",
                opacity: "1",
              }}
            >
              فاتورة دفع القسط الاول للوحدة السكنية
            </span>
            <span
              style={{
                textAlign: "right",
                font: "normal normal 300 12px/22px Cairo",
                letterSpacing: "0px",
                color: "#7D8185",
                opacity: "1",
              }}
            >
              مجمع بوابة العراق - عمارة رقم ١٨ شقة ٣
            </span>
          </Box>
          <IconButton 
            aria-label="settings"
            
            aria-controls={open ? "demo-customized-menu" : undefined}
            aria-haspopup="true"
            aria-expanded={open ? "true" : undefined}
            disableElevation
            onClick={handleClick}
          >
            <CgIcons.CgMenuRight
              style={{ color: "#1E6A99", fontSize: "22px", cursor: "pointer" }}
              className="mx-1"
            />
          </IconButton>
        </Box>
      ) : (
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
          }}
        >
          <FaIcons.FaFileAlt
            style={{ color: "#349CDD", fontSize: "22px" }}
            className="mx-1"
          />
          <Box
            sx={{
              display: "flex",
              justifyContent: "start",
              alignItems: "start",
              flexDirection: "column",
            }}
            className="mx-1"
          >
            <span
              style={{
                textAlign: "right",
                textDecoration: "underline",
                font: "normal normal bold 14px/26px Cairo",
                letterSpacing: "0px",
                color: "#349CDD",
                opacity: "1",
              }}
            >
              فاتورة دفع القسط الاول للوحدة السكنية
            </span>
            <span
              style={{
                textAlign: "right",
                font: "normal normal 300 12px/22px Cairo",
                letterSpacing: "0px",
                color: "#7D8185",
                opacity: "1",
              }}
            >
              مجمع بوابة العراق - عمارة رقم ١٨ شقة ٣
            </span>
          </Box>
          <CgIcons.CgMenuRight
            style={{ color: "#1E6A99", fontSize: "22px" }}
            className="mx-1"
          />
        </Box>
      )}

      {/* menu popup action fro card  */}
      <StyledMenu
        
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem
          onClick={() => handleClose()}
          disableRipple
          sx={{
            fontFamily: "Cairo",
            display: "flex",
            alignItems: "center",
          }}
        >
         <RemoveRedEyeIcon
           sx={{
            color:'#1e6a99'
           }}
         />
          <span
            style={{
              fontFamily: "Cairo-Bold",
              margin: "0 10px",
            }}
          >
            {t('GLOBAL.action_view')}
          </span>
        </MenuItem>
      </StyledMenu>
    </React.Fragment>
  );
}
