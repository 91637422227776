import React from 'react'
import { styled, alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import EditIcon from '@mui/icons-material/Edit';
import Divider from '@mui/material/Divider';
import ArchiveIcon from '@mui/icons-material/Archive';
import FileCopyIcon from '@mui/icons-material/FileCopy';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import {Checkbox,ListItemText} from '@mui/material'
import {Box} from '@mui/material'
import {useTranslation} from 'react-i18next'
const StyledMenu = styled((props) => (
    <Menu
      elevation={0}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    />
  ))(({ theme }) => ({
    '& .MuiPaper-root': {
      borderRadius: 6,
      marginTop: theme.spacing(1),
      minWidth: 280,
      paddingTop:'10px',
      color:
        theme.palette.mode === 'light' ? 'rgb(55, 65, 81)' : theme.palette.grey[300],
      boxShadow:
        'rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px',
      '& .MuiMenu-list': {
        padding: '4px 0',
      },
      '& .MuiMenuItem-root': {
        '& .MuiSvgIcon-root': {
          fontSize: 18,
          color: theme.palette.text.secondary,
          marginRight: theme.spacing(1.5),
        },
        '&:active': {
          backgroundColor: alpha(
            theme.palette.primary.main,
            theme.palette.action.selectedOpacity,
          ),
        },
      },
    },
  }));

export default function FilterColumn(props) {
    const [anchorEl, setAnchorEl] = React.useState(props?.elementClicked);
    const [t] =useTranslation('common')
    const open = Boolean(props?.elementClicked);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
      props?.setOpen(false)
    };
  const handleChange = (name) => {
    let arr=[];
    // alert(name)
    props?.columns&&props?.columns?.map((itm)=>{
         if(itm?.headerName==name){
            if(itm?.hide)
            arr.push({
                ...itm,
                hide:false,
            })
            else
            arr.push({
                ...itm,
                hide:true,
            })
         }else{
            arr.push(itm)
         }
    })
    if(arr?.length>0)
    props.setColumns(arr)
  };

  return (
   <StyledMenu
     
     MenuListProps={{
       'aria-labelledby': 'demo-customized-button',
     }}
     anchorEl={props?.elementClicked}
     open={props?.open}
     onClose={handleClose}
   >
      <Box sx={{width:'100%',display:'flex',justifyContent:'center',alignItems:'center',}}>
        <p style={{
          fontFamily:'Cairo-Bold',
          fontSIze:'15px',
        }}>{t('WORKTYPE.hide_Columns')}</p>
      </Box>
      {props?.columns.map((name) => (
          <MenuItem key={name?.headerName} value={name?.headerName} onClick={()=>handleChange(name?.headerName)}>
            <Checkbox checked={name?.hide?name?.hide:false} />
            <ListItemText primary={name?.headerName} sx={{
                '& .MuiTypography-root':{
                  fontFamily:'Cairo'
                }
                }}/>
          </MenuItem>
        ))}
   </StyledMenu>
  )
}
