import { Box } from '@mui/material'
import React,{useEffect,useState} from 'react'
import LeftSide from './Components/LeftSide'
import RightSide from './Components/RightSide'
import {useSelector} from 'react-redux'
import unitAPI from '../../Global/Network/Unit'
import { useTranslation } from 'react-i18next'
import { toast } from 'react-toastify'
import RolesOfUserAPI from '../../Global/Network/RoleUser'
const urlParams = new URLSearchParams(window.location.search);
const UnitId = urlParams.get('id');

export default function ViewUnit() {
  const screenwidth=useSelector((state)=>state.settingsData.screenwidth)
  const [UnitData,setUnitData]=useState(null)
  const rolesRedux=useSelector(state=>state.rolesData.roles)


  // const {id}=useParams()
  const [t]=useTranslation('common')
  useEffect(() => {
    const abortController=new AbortController();
    loadDataOfUnit();
     
    return () => {
      abortController.abort();
    };
  }, []);

  const loadDataOfUnit=async()=>{
    let data=null;
    try{
        data=await unitAPI.unit({
         params:{
          id:UnitId
         }
        });
        if(data&&data?.status){
          console.log('unit-view',data?.data);
          setUnitData(data?.data)
        }else{
            toast.warn(t('NETWORKMESSAGE.messageError'))
        }
    }catch(err){
        console.log(err?.message);
    }
}


  return (
    <Box
     className="container-fluid mt-4"
    >
     <Box className={`row ${screenwidth<1100?'flex-column-reverse':'flex-row'} justify-content-evenly`}>
        <Box className={`col-12 col-sm-12 col-md-12 ${screenwidth<1100?'col-lg-12':'col-lg-6'}`}>
            <LeftSide 
             UnitData={UnitData}
             loadDataOfUnit={loadDataOfUnit}
             UnitId={UnitId}
              
            />
        </Box>
        <Box className={`col-12 col-sm-12 col-md-12 ${screenwidth<1100?'col-lg-12':'col-lg-6'}`}>
           <RightSide
             UnitData={UnitData}
             loadDataOfUnit={loadDataOfUnit}
             UnitId={UnitId}
              
           />
        </Box>
     </Box>

    </Box>
  )
}
