import React ,{useState,useEffect,useMemo} from 'react'
import Grid from '../../Grid/Grid'
import {useTranslation} from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import {Box,MenuItem,ListItemIcon,Typography,Divider,Autocomplete,TextField} from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
// import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import customerAPI from '../../../Global/Network/Customer';
import CallsTypeAPI from '../../../Global/Network/CallsType/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import DashboardAPI from '../../../Global/Network/Dashboard';
import { debounce } from 'debounce';

const RenderSearchContent=(props)=>{
  const [t]=useTranslation('common')
  console.log('user',props?.users)
  return(
    <Box sx={{
      display:"flex",
      justifyContent:"center",
      alignItems: "center",
      flexDirection:'column',
      width: '100% !important',
      '& .MuiChip-label':{
        fontFamily:'Cairo-Light',
      }
    }}>

      <SearchDate
      value={props?.monthDate}
      setValue={props?.setMonthDate}
      title={t('DASHBOARD.month')}
      typeMode="month"
      disable={!props?.monthDate&&props?.dayDate}
     />
     <SearchDate
      value={props?.dayDate}
      setValue={props?.setDayDate}
      title={t('DASHBOARD.daydate')}
      disable={props?.monthDate&&!props?.dayDate}
     />
     <Autocomplete
        sx={{ ml: 1.5,mt:1,mb:1,width:'95%',
        '& .MuiFormLabel-root,& .MuiInputBase-input':{
          fontFamily:'Cairo-Medium'
      }
      }}
        options={props.customers?props.customers:[]}
        getOptionLabel={(option) => option?.user&&option?.user?.full_name|| ""}
      
        value={props?.selectedCustomer}
        multiple
        onChange={(e, v, r) => { props.setSelectedCustomer(v ? v : ''); }}
        renderInput={(params) => (
            <TextField {...params} 
            placeholder={t('DASHBOARD.customer_outstanding_customer')}
            variant="standard" sx={{fontFamily:'Cairo-Medium'}}
            onChange={(e) => {
              props.handleSearch({search_text: e.target.value}, "customers");
            }}/>
        )}
      />

    </Box>
  )
}

export default function DelinquentCutomerGrid(props) {
 const [t] = useTranslation("common");
 const [rows,setRows]=useState([])
 const [pageSize, setPageSize] = useState(5);
 const [pageNumber, setPageNumber] = useState(1);
 const [loading,setLoading]=useState(false)
 const [pageCount,setPageCount]=useState(1)
 const [openFilterColumn,setOpenFilterColumn]=useState(false)
 const [openFilterColumnValue,setOpenFilterColumnValue]=useState([])
 const [elementClicked,setElementClicked]=useState(null)
 const [addForm,setAddForm]=useState(false)
 const [selectedObject,setselectedObject]=useState(null)
 const [fromDate,setFromDate]=useState(null)
 const [toDate,setToDate]=useState(null)
 const [objectName,setobjectName]=useState(null)
 const [createdBy,setCreatedBy]=useState(null)
 const [users,setUsers]=useState([])
 const { exportToCSV} = useGlobalContext()
 const [customers,setCustomers]=useState([])
 const [selectedCustomer,setSelectedCustomer]=useState([])
 const [dayDate,setDayDate]=useState(null)
 const [monthDate,setMonthDate]=useState(null)
 const rolesRedux = useSelector(state => state.rolesData.roles)



 useEffect(()=>{
    if(props?.dashboards?.finance?.delinquent_customers){
      let arr=[]
      props?.dashboards?.finance?.delinquent_customers?.data&&props?.dashboards?.finance?.delinquent_customers?.data?.map((itm,index)=>{
        arr.push({
          ...itm,
          id:index,
        })
      })
      setRows(arr)
      setRowsTotal(props?.dashboards?.finance?.delinquent_customers?.total)
      setPageCount(props?.dashboards?.finance?.delinquent_customers?.last_page)

    }
 },[props?.dashboards?.finance?.delinquent_customers])


 const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
 const controller = new AbortController();

 const [rowsTotal,setRowsTotal]=useState(2)
 const screenwidth=useSelector(state=>state.settingsData.screenwidth)
 const columns=useMemo(()=>[
    {headerName:t('DASHBOARD.customer_delinquent_id'),field:'id',flex:0.5,minWidth:50,maxWidth:150,hide:false},
    {headerName:t('DASHBOARD.customer_delinquent_name'),field:'full_name',flex:2,minWidth:150,maxWidth:250,hide:false},
    {headerName:t('DASHBOARD.customer_delinquent_mobile'),field:'phone',flex:2,minWidth:150,maxWidth:250,hide:false},
    {headerName:t('DASHBOARD.customer_delinquent_compound'),field:'compound_name',flex:2,minWidth:150,maxWidth:250,hide:false},
    {headerName:t('DASHBOARD.customer_delinquent_unit'),field:'unit_name',flex:2,minWidth:90,maxWidth:250,hide:false},
    {headerName:t('DASHBOARD.customer_delinquent_overDueAmount'),field:'overDueAmount',flex:2,minWidth:150,maxWidth:250,hide:false,
  renderCell:(rows)=>(
    <Box
      sx={{
         display:'flex',
         justifyContent:'start',
         alignItems:'end',
         flexDirection:'column',
         padding:'10px 0'
      }}
    >
        <span style={{fontFamily:'Cairo'}}>{helpers?.getFormatedPrice(rows?.row?.overdue,rows?.row?.iqd_usd)}</span>
    </Box>
  )
    },
    {headerName:t('DASHBOARD.customer_delinquent_dueAmount'),field:'dueAmount',flex:2,minWidth:150,maxWidth:250,hide:false,
      renderCell:(rows)=>(
        <Box
          sx={{
             display:'flex',
             justifyContent:'start',
             alignItems:'end',
             flexDirection:'column',
             padding:'10px 0'
          }}
        >
            <span style={{fontFamily:'Cairo'}}>{helpers?.getFormatedPrice(rows?.row?.all_invoices_total_price,rows?.row?.iqd_usd)}</span>
        </Box>
      )
    },
    {headerName:t('DASHBOARD.customer_delinquent_paidAmount'),field:'paidAmount',flex:2,minWidth:150,maxWidth:250,hide:false,
      renderCell:(rows)=>(
        <Box
          sx={{
             display:'flex',
             justifyContent:'start',
             alignItems:'end',
             flexDirection:'column',
             padding:'10px 0'
          }}
        >
            <span style={{fontFamily:'Cairo'}}>{helpers?.getFormatedPrice(rows?.row?.all_payments_total_paid,rows?.row?.iqd_usd)}</span>
        </Box>
      )
    },
    {headerName:t('DASHBOARD.customer_delinquent_balancingAmount'),field:'balancingAmount',flex:2,minWidth:150,maxWidth:250,hide:false,
      renderCell:(rows)=>(
        <Box
          sx={{
             display:'flex',
             justifyContent:'start',
             alignItems:'end',
             flexDirection:'column',
             padding:'10px 0'
          }}
        >
            <span style={{fontFamily:'Cairo'}}>{helpers?.getFormatedPrice(rows?.row?.balance,rows?.row?.iqd_usd)}</span>
        </Box>
      )
    },

 ],[rolesRedux])
useEffect(()=>{
  let abortController = new AbortController();

  props?.setPageNumber(pageNumber)
  props?.setPageSize(pageSize)
  return () => {
    abortController.abort();
  }
},[pageNumber,pageSize])

useEffect(()=>{
  let abortController = new AbortController();

  console.log('saldkjaskd',1);
   
  loadDataOfCustomers({})
  return () => {
    abortController.abort();
  }
},[])


const handleSearch = debounce((value, name) => {
  // if (name == "blocks") loadBlocks(value);
  // else if (name == "buildings") loadBuildings(value);
  // else if (name == "floors") loadFloors(value);
  // else if (name == "unitTypes") loadDataOfUnitTypes(value);
  if (name == "customers") loadDataOfCustomers(value);
}, 500);

const loadDataOfCustomers = async (searchParams) => {
  setLoading(true)
  setCustomers([])
  const result = await customerAPI.customers({
     signal: controller?.signal,
     page_size: 10,
     page: 0,
     search_text: searchParams?.search_text ? searchParams?.search_text : "",
  })

  setLoading(false)
  if (result.status) {
     setCustomers(result?.data?.data)
  }
  else {
     if(!result?.data?.error)
     {
      toast.error(t('NETWORKMESSAGE.messageError'))
      return
     }
  }

}
const createFUnction=()=>{
  setselectedObject(null)
   setAddForm(true)
}

const clearFunction=()=>{
  setobjectName('')
  setFromDate(null)
  setToDate(null)
  setCreatedBy(null)
}
const searchFunction=()=>{
  let data={};
  if(selectedCustomer){
    let arr=[]
    selectedCustomer?.map((itm)=>{
       arr.push(itm?.id)
    })
    data={
     ...data,
     customer_ids:arr
    }
  }
  if(monthDate){
    data={
    ...data,
    cut_off_date:helpers.getFirstAndLastDayNumberFromDate(monthDate)?.first
    }
  }
  if(dayDate){
    data={
  ...data,
    cut_off_date:helpers.formatDate(dayDate)
  }
  }
  props?.loadDataOfDashboards(data)
}
const loadDataForExport = async (searchParams) => {
  let  result = await DashboardAPI.dashboard({
    params: {
       page_size: rowsTotal,
       ...searchParams
    },
    signal: controller?.signal
 })
 return result;
}
const ExportFunction=async () => {
  let filteredData={}
  if(selectedCustomer){
    let arr=[]
    selectedCustomer?.map((itm)=>{
       arr.push(itm?.id)
    })
    filteredData={
     ...filteredData,
     customer_ids:arr
    }
  }
  if(monthDate){
    filteredData={
    ...filteredData,
    cut_off_date:helpers.getFirstAndLastDayNumberFromDate(monthDate)?.first
    }
  }
  if(dayDate){
    filteredData={
  ...filteredData,
    cut_off_date:helpers.formatDate(dayDate)
  }
  }

  const fetchedData =await loadDataForExport(filteredData);
  if(!fetchedData||!fetchedData?.status){
    toast.error("لا يمكن استرجاع البيانات")
     return
  }
  console.log('sdasdasdas',fetchedData?.data?.finance?.delinquent_customers)
  let arr=[];
  fetchedData?.data?.finance?.delinquent_customers?.data&&fetchedData?.data?.finance?.delinquent_customers?.data?.map((itm,index)=>{
   arr.push({
    [`${t('DASHBOARD.customer_outstanding_id')}`]:index,
    [`${t('DASHBOARD.customer_outstanding_name')}`]:itm?.full_name,
    [`${t('DASHBOARD.customer_outstanding_mobile')}`]:itm?.phone,
    [`${t('DASHBOARD.customer_outstanding_compound')}`]:itm?.compound_name,
    [`${t('DASHBOARD.customer_outstanding_unit')}`]:itm?.unit_name,
    [`${t('DASHBOARD.customer_delinquent_overDueAmount')}`]:helpers.getFormatedPrice(itm?.overdue,itm?.iqd_usd),
    [`${t('DASHBOARD.customer_delinquent_dueAmount')}`]:helpers.getFormatedPrice(itm?.all_invoices_total_price,itm?.iqd_usd),
    [`${t('DASHBOARD.customer_delinquent_paidAmount')}`]:helpers.getFormatedPrice(itm?.all_payments_total_paid,itm?.iqd_usd),
    [`${t('DASHBOARD.customer_delinquent_balancingAmount')}`]:helpers.getFormatedPrice(itm?.balance,itm?.iqd_usd),
   })
 })
 exportToCSV(arr,'CUSTOMERS_OVER_DUE')

}



  return (

        <Grid
          customContainerPadding={'10px 0'}
          rows={rows}
          columns={columns}
          setColumns={null}
          pageSize={pageSize}
          setPageSize={setPageSize}
          pageNumber={pageNumber}
          setPageNumber={setPageNumber}
          loading={loading}
          handlePageChange={handlePageChange}
          rowsTotal={rowsTotal}
          checkScreenSize={screenwidth}
          pageCount={pageCount}
          openFilterColumn={openFilterColumn}
          elementClicked={elementClicked}
          setOpenFilterColumn={setOpenFilterColumn}
          setColumnFilterValue={setOpenFilterColumnValue}
          columFilterValue={openFilterColumnValue}
          clearFunction={clearFunction}
          searchFunction={searchFunction}
          creatFunction={createFUnction}
          ExportFunction={ExportFunction}
          importFunction={null}
          mapFunction={null}
          telegramFunction={null}
          hasCreate={false}
          hasImport={false}
          hasExport={true}
          hasMap={false}
          hasTetelgram={false}
          filterChilds={<RenderSearchContent
            objectName={objectName}
            setobjectName={setobjectName}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            users={users}
            customers={customers}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            dayDate={dayDate}
            setDayDate={setDayDate}
            monthDate={monthDate}
            setMonthDate={setMonthDate}
            handleSearch={handleSearch}
          />}
        />
  )
}
