import React, { useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify'
import { Box } from '@mui/material'
import favouritePageUserApi from '../../../Global/Network/FavouritePageUser'
import FavouritePagesGrid from './FavouritePagesGrid';


export default function MyFavouritePage() {
  const [t] = useTranslation("common");
  const [loading, setLoading] = useState(false)
  const [favouritePages, setFavouritePages] = useState([])


  const controller = new AbortController();
  const screenwidth = useSelector(state => state.settingsData.screenwidth)


  useEffect(() => {
    let abortController = new AbortController();

    loadDataOfUsers()
    return () => {
      abortController.abort();
    }
  }, [])

  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)

    const result = await favouritePageUserApi.getFavouritePagesOfUser({
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      console.log(result?.data);
      setFavouritePages(result?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',
      paddingLeft: '10px',
      paddingRight: '10px'

    }}
      className=" pt-3 mt-1"

    >
      <FavouritePagesGrid favouritePages={favouritePages} />
    </Box>
  )
}
