import callAPI from '../ApiConfig'
import store from '../../../app/store'

const callsType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_types",
        method:"get",
        signal:props?.signal,
        params:{
            web_or_mobile_list:['both','web'],
            ...props?.params
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const callsTypeList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_types_list",
        method:"get",
        signal:props?.signal,
        params:{
            web_or_mobile_list:['both','web'],
            ...props?.params
        },
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCallsType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_types",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updatecallsType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deletecallsType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"call_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}



const CallsTypeAPI={
    CallsType:callsType,
    CallsTypeList:callsTypeList,
    addCallsType:addCallsType,
    updatecallsType:updatecallsType,
    deletecallsType:deletecallsType,

}
export default CallsTypeAPI