import React from 'react'
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import Slide from '@mui/material/Slide';
import { Box } from '@mui/material';
import { useSelector } from 'react-redux';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function PopupForm(props) {
  const [open, setOpen] = React.useState(false);
  const screenwidth=useSelector(state=>state.settingsData.screenwidth)

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    props.setOpen(false);
  };

  const getMaxWidth = () => {
    if (props?.width) {
      return props?.width + ' !important'
    }
    else if (props?.customeWidth) {
      return props?.customeWidth
    }
    else {
      // return '60% !important'
      return {xs:'95% !important',sm:'85% !important',md:'80% !important',lg:'60% !important'}
    }
  }
  return (
    <Box
      sx={{
        width:screenwidth<=700?'100%': props?.width ? props?.width : {xs:'95%',sm:'85%',md:'80%',lg:'60%'},
        height: 'auto',
        '& .MuiDialog-root':{
          width:'100% !important',
          height:'100%  !important',
          display:'flex  !important',
          justifyContent:'center  !important',
          alignItems:'center !important',
        }
      }}
    >

      <Dialog
        open={props?.open}
        TransitionComponent={Transition}
        keepMounted
        onClose={handleClose}
        aria-describedby="alert-dialog-slide-description"
        sx={{
          width: '100%',
          height: screenwidth<=700?'100%':props?.customeHeight?props?.customeHeight:'auto',
          '& .MuiPaper-root': {
            width: '100%',
            height: screenwidth<=700?'100%':props?.customeHeight?props?.customeHeight:'auto',
            padding: screenwidth<=700?'10px 0 !important':props?.customePadding?props?.customePadding:'30px 20px !important',
            minHeight: '400px',
            maxWidth:  screenwidth<=700?'100%':getMaxWidth(),
            position: 'relative',
            '& .backgroundSvg': {
              position: 'absolute',
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              width: '100%',
              height: '100%',
              zIndex: 0,
              backgroundColor: '#1E6A99',
              clipPath: 'polygon(60% 42%, 100% 0, 100% 100%, 75% 100%, 0 100%, 0 100%, 26% 73%)',
            },
            '& .iconeFilterClear':{
              color:'#1e6a99 !important',
              border:'1px solid #1e6a99 !important',
            },
            '& .iconeFilterClear:hover':{
              color:'#1e6a99 !important',
              border:'1px solid #1e6a99 !important',
            },
          }
        }}
        BackdropProps={{
          style: {
            background: 'rgba(255, 255, 255, 0.1)',
            backdropFilter: 'blur(3px)',
          }
        }}
        fullScreen={screenwidth<=700?true:props?.isFullScreen}
      >
        {/* <Box
         className='backgroundSvg'
       >

       </Box> */}
        <DialogTitle sx={{ display: 'flex', alignSelf: 'center', fontFamily: 'Cairo-Bold', fontSize: '22px !important' ,paddign:'0',margin:'0',height:'fit-content'}}>{props?.title}</DialogTitle>
        <DialogContent>
          {props?.content}
        </DialogContent>
        <DialogActions>
          {props?.footer}
        </DialogActions>
      </Dialog>
    </Box>
  )
}
