import {
  Box,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import ObjectOfErrors, {
  checkErrors,
  resetErrors,
  restCustomeObject,
} from "../../../Global/Errors/Calls/Calls";
import CloseIcon from "@mui/icons-material/Close";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import CallAPI from "../../../Global/Network/Calls/index";
import * as RiIcons from "react-icons/ri";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import call_reasonsAPI from "../../../Global/Network/CallReasons/index";
import CallDestinationsAPI from "../../../Global/Network/CallDestinations/index";
import helpers from "../../../assets/js/helper";
import generalAPI from "../../../Global/Network/General";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import CustomePhoneField from "../../../General/CustomComponents/CustomePhoneField";

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const [stateCode1, setStateCode1] = useState("");
  const autocomplete = React.useRef();
  const handleInputChange = (number, value, errName) => {
    if (number == 1) {
      props.setSelectedDestination(value);
      props?.callReasons &&
        props?.callReasons?.map((itm) => {
          if (itm?.call_destination_id == value?.id) {
            props?.setSelectedCallReasons(itm);
          }
        });
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setSelectedCallType(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setSelectedCallStatus(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setSelectedCallReasons(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setSelectedCustomer(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setNeededFollowUpDate(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setDate_time(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 9) {
      props.setCallReasonOther(value?.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 10) {
      props.setReply(value?.target.value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 11) {
      props?.setSelectedContactMethod(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 12) {
      props?.setSelectedCompounds(value);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setSelectedDestination(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setSelectedCallType(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setSelectedCallStatus(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setSelectedCallReasons(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setSelectedCustomer(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setNeededFollowUpDate(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setDate_time(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 9) {
      props.setCallReasonOther("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 10) {
      props.setReply("");
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 11) {
      props?.setSelectedContactMethod(null);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    } else if (number == 12) {
      props?.selectedCompounds([]);
      setErrorsObject(restCustomeObject(ErrorsObject, errName));
    }
  };
  const userData = localStorage.getItem("userData")
    ? JSON.parse(localStorage.getItem("userData"))
    : null;
  React.useEffect(() => {
    if (autocomplete) autocomplete?.current?.focus();
  }, []);
  return (
    <Box className="Container-fluid">
      <Box className="row">
        <Box className="col-12 col-sm-12 col-md-6">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.customer_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                margin: "15px 0 10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              {props?.isExternalCustomer ? (
                <TextField
                  label={t("CALLS.customers")}
                  className="Mui-focused"
                  variant="filled"
                  value={props?.externalCustomer}
                  readOnly={false}
                  style={{
                    fontFamily: "Cairo",
                    borderRight: "5px solid #1E6A99",
                  }}
                  onChange={(e) => {
                    props?.setExternalCustomer(e.target.value);
                  }}
                  InputLabelProps={{
                    style: {
                      fontSize: 15,
                      color: "GrayText",
                      fontFamily: "Cairo",
                    },
                  }}
                />
              ) : (
                <Autocomplete
                  options={props?.customers ? props?.customers : []}
                  value={props?.selectedCustomer}
                  onChange={(e, newValue) =>
                    handleInputChange(5, newValue, "customer_id")
                  }
                  getOptionLabel={(option) => option?.name || ""}
                  popupIcon={<RiIcons.RiArrowDropDownLine />}
                  readOnly={props?.customerReadOnly}
                  inputProps={{
                    focus: true,
                  }}
                  inputRef={autocomplete}
                  clearIcon={
                    <CloseIcon
                      sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                      onClick={() => clearInput(5, "customer_id")}
                    />
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("CALLS.customers")}
                      className="errors"
                      variant="filled"
                      focused
                    />
                  )}
                  className={`${ErrorsObject?.customer_id?.error}`}
                />
              )}
            </Box>
            {ErrorsObject?.customer_id?.error &&
            ErrorsObject?.customer_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.customer_id?.message &&
                ErrorsObject?.customer_id?.message?.length > 0
                  ? ErrorsObject?.customer_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
        </Box>{" "}
        <Box className="col-12 col-sm-12 col-md-6 my-2 pt-1">
          <CustomTextField
            label={t("CALLS.user")}
            value={userData?.full_name}
            haswidth={true}
            readOnly={true}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-4 ">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.call_destination_id?.error}
            haswidth={true}
            // isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="my-3"
            >
              <Autocomplete
                // disablePortal

                options={props?.destinations ? props?.destinations : []}
                getOptionLabel={(option) => option?.name || ""}
                value={props?.selectedDestination}
                onChange={(e, newValue) =>
                  handleInputChange(1, newValue, "call_destination_id")
                }
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(1, "call_destination_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.destinations")}
                    className="mt-2 errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.call_destination_id?.error}`}
              />
            </Box>
            {ErrorsObject?.call_destination_id?.error &&
            ErrorsObject?.call_destination_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.call_destination_id?.message &&
                ErrorsObject?.call_destination_id?.message?.length > 0
                  ? ErrorsObject?.call_destination_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.call_reason_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
            >
              <Autocomplete
                // disablePortal

                options={props?.callReasons ? props?.callReasons : []}
                value={props?.selectedCallReasons}
                onChange={(e, newValue) =>
                  handleInputChange(4, newValue, "call_reason_id")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(4, "call_reason_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.callReason")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.call_reason_id?.error}`}
              />
            </Box>
            {ErrorsObject?.call_reason_id?.error &&
            ErrorsObject?.call_reason_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.call_reason_id?.message &&
                ErrorsObject?.call_reason_id?.message?.length > 0
                  ? ErrorsObject?.call_reason_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.call_type_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                // disablePortal

                options={props?.callTypes ? props?.callTypes : []}
                value={props?.selectedCallType}
                onChange={(e, newValue) =>
                  handleInputChange(2, newValue, "call_type_id")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(2, "call_type_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.callType")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.call_type_id?.error}`}
              />
            </Box>
            {ErrorsObject?.call_type_id?.error &&
            ErrorsObject?.call_type_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.call_type_id?.message &&
                ErrorsObject?.call_type_id?.message?.length > 0
                  ? ErrorsObject?.call_type_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.date_time?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateTimePicker
                  label={t("CALLS.date_time")}
                  inputFormat="MM/DD/YYYY HH:mm:ss"
                  value={props?.date_time}
                  // minDate={new Date()}
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  maxDate={new Date()}
                  onChange={(date) => handleInputChange(7, date, "date_time")}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={props?.date_time ? true : false}
                      className={`${
                        ErrorsObject?.date_time?.error ? "errors" : ""
                      }`}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {props?.date_time ? (
                  <CloseIcon
                    className="closeIcon"
                    onClick={() => clearInput(7, "date_time")}
                    sx={{
                      right: "37px !important",
                      top: "14px !important",
                    }}
                  />
                ) : null}
              </LocalizationProvider>
            </Box>
            {ErrorsObject?.preferedDate?.error &&
            ErrorsObject?.preferedDate?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.preferedDate?.message &&
                ErrorsObject?.preferedDate?.message?.length > 0
                  ? ErrorsObject?.preferedDate?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>

          {props?.selectedCallReasons?.needs_text == 1 ? (
            <ContainerOfInputFields
              mainTheme={maintheme}
              customeWidth={"100%"}
              // hasError={ErrorsObject?.address_governorate_id?.error}
              haswidth={true}
              hasMultiLine={false}
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "10px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-3"
              >
                <TextField
                  variant="filled"
                  label={t("CALLS.other_call_reasons")}
                  value={props?.callReasonOther}
                  onChange={(e) => handleInputChange(9, e, "call_reason_other")}
                  type={"text"}
                  // focused={ErrorsObject?.address_name?.error}
                  className={`${
                    ErrorsObject?.call_reason_other?.error ? "errors" : ""
                  }`}
                />
                {props?.callReasonOther ? (
                  <CloseIcon
                    className="closeIcon"
                    onClick={() => clearInput(9, "call_reason_other")}
                  />
                ) : null}
              </Box>
              {ErrorsObject?.call_reason_other?.error &&
              ErrorsObject?.call_reason_other?.message?.length ? (
                <Box
                  sx={{
                    height: "fit-content",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "95%",
                  }}
                >
                  {ErrorsObject?.call_reason_other?.message &&
                  ErrorsObject?.call_reason_other?.message?.length > 0
                    ? ErrorsObject?.call_reason_other?.message?.map((messg) => (
                        <span
                          style={{
                            fontFamily: "Cairo-Bold",
                            fontSize: "14px",
                            color: "red",
                          }}
                        >
                          {messg}
                        </span>
                      ))
                    : null}
                </Box>
              ) : null}
            </ContainerOfInputFields>
          ) : null}
        </Box>
        <Box className="col-12 col-sm-12 col-md-4">
          <Box
            sx={{
              backgroundColor: "#fff",
              color: "#000",
              // borderRadius:'10px',
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "93% !important",
              boxShadow: "0px 6px 20px 3px #00000014 !important",
              margin: "auto",
              marginTop: "25px",
              paddingLeft: "10px",
              "& .MuiTypography-root": {
                fontFamily: "Cairo-Medium",
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props?.isExternalCustomer}
                  onChange={(e) => {
                    props.setIsExternalCustomer(e.target.checked);
                  }}
                />
              }
              sx={{ fontFamily: "Cairo-Medium" }}
              label={t("CALLS.isExternalCustomer")}
            />
          </Box>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.call_status_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                // disablePortal
                id="combo-box-demo"
                options={props?.callstateus ? props?.callstateus : []}
                value={props?.selectedCallStatus}
                getOptionLabel={(option) => option?.name || ""}
                onChange={(e, newValue) =>
                  handleInputChange(3, newValue, "call_status_id")
                }
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "call_status_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.callStatus")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.call_status_id?.error}`}
              />
            </Box>
            {ErrorsObject?.call_status_id?.error &&
            ErrorsObject?.call_status_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.call_status_id?.message &&
                ErrorsObject?.call_status_id?.message?.length > 0
                  ? ErrorsObject?.call_status_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <Box
            sx={{
              backgroundColor: "#fff",
              color: "#000",
              // borderRadius:'10px',
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "93% !important",
              boxShadow: "0px 6px 20px 3px #00000014 !important",
              margin: "auto",
              marginTop: "18px",
              paddingLeft: "10px",
              "& .MuiTypography-root": {
                fontFamily: "Cairo-Medium",
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props?.importanceDegree}
                  onChange={(e) => {
                    props.setImportanceDegree(e.target.checked);
                  }}
                />
              }
              sx={{ fontFamily: "Cairo-Medium" }}
              label={t("CALLS.Importance")}
            />
          </Box>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.contact_method_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                // disablePortal

                options={props?.contactMethods ? props?.contactMethods : []}
                value={props?.selectedContactMethod}
                onChange={(e, newValue) =>
                  handleInputChange(11, newValue, "contact_method_id")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(11, "contact_method_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.call_method")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.contact_method_id?.error}`}
              />
            </Box>
            {ErrorsObject?.contact_method_id?.error &&
            ErrorsObject?.contact_method_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.contact_method_id?.message &&
                ErrorsObject?.contact_method_id?.message?.length > 0
                  ? ErrorsObject?.contact_method_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
        </Box>
        <Box className="col-12 col-sm-12 col-md-4">
          <Box
            sx={{
              backgroundColor: "#fff",
              color: "#000",
              // borderRadius:'10px',
              height: "55px",
              display: "flex",
              alignItems: "center",
              justifyContent: "start",
              width: "93% !important",
              boxShadow: "0px 6px 20px 3px #00000014 !important",
              margin: "auto",
              paddingLeft: "10px",
              marginTop: "25px",
              "& .MuiTypography-root": {
                fontFamily: "Cairo-Medium",
              },
            }}
          >
            <FormControlLabel
              control={
                <Checkbox
                  checked={props?.neededFollowUp}
                  onChange={(e) => {
                    props.setNeededFollowUp(e.target.checked);
                  }}
                />
              }
              sx={{ fontFamily: "Cairo-Medium" }}
              label={t("CALLS.neededFollowUps")}
            />
          </Box>

          {props?.neededFollowUp ? (
            <ContainerOfInputFields
              mainTheme={maintheme}
              customeWidth={"100%"}
              hasError={ErrorsObject?.needs_follow_up_date?.error}
              haswidth={true}
            >
              <Box
                sx={{
                  position: "relative",
                  // margin: "5px 0",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="mt-3 orderdata"
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DesktopDatePicker
                    label={t("CALLS.neededFollowupDate")}
                    inputFormat="MM/DD/YYYY"
                    value={props?.neededFollowUpDate}
                    // minDate={new Date()}
                    inputProps={{
                      placeholder: "لم يتم التحديد",
                    }}
                    minDate={new Date()}
                    onChange={(date) =>
                      handleInputChange(6, date, "needs_follow_up_date")
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="filled"
                        focused={props?.neededFollowUpDate ? true : false}
                        className={`${
                          ErrorsObject?.needs_follow_up_date?.error
                            ? "errors"
                            : ""
                        }`}
                        inputProps={{
                          ...params.inputProps,
                          placeholder: "لم يتم التحديد",
                        }}
                      />
                    )}
                  />
                  {props?.neededFollowUpDate ? (
                    <CloseIcon
                      className="closeIcon"
                      onClick={() => clearInput(6, "needs_follow_up_date")}
                      sx={{
                        right: "37px !important",
                        top: "14px !important",
                      }}
                    />
                  ) : null}
                </LocalizationProvider>
              </Box>
              {ErrorsObject?.needs_follow_up_date?.error &&
              ErrorsObject?.needs_follow_up_date?.message?.length ? (
                <Box
                  sx={{
                    height: "fit-content",
                    padding: "5px",
                    display: "flex",
                    flexDirection: "column",
                    flexWrap: "wrap",
                    width: "95%",
                  }}
                >
                  {ErrorsObject?.needs_follow_up_date?.message &&
                  ErrorsObject?.needs_follow_up_date?.message?.length > 0
                    ? ErrorsObject?.needs_follow_up_date?.message?.map(
                        (messg) => (
                          <span
                            style={{
                              fontFamily: "Cairo-Bold",
                              fontSize: "14px",
                              color: "red",
                              height: "auto",
                            }}
                          >
                            {messg}
                          </span>
                        )
                      )
                    : null}
                </Box>
              ) : null}
            </ContainerOfInputFields>
          ) : null}
          {props?.isExternalCustomer ? (
            <ContainerOfInputFields
              mainTheme={maintheme}
              customeWidth={"100%"}
              haswidth={true}
              hasMultiLine={false}
            >
              <Box
                sx={{
                  position: "relative",
                  width: "100%",
                  backgroundColor: "#fff",
                }}
                className="orderdata"
              >
                <CustomePhoneField
                  value={props?.externalCustomerPhone}
                  title={t("CUSTOMER.phone")}
                  noTopMargin={false}
                  paddingHorizontal="0"
                  onChange={(e) => {
                    if (!isNaN(e?.target?.value)) {
                      props?.setExternalCustomerPhone(e?.target?.value);
                    }
                  }}
                  onClearClick={() => {
                    props?.setExternalCustomerPhone("");
                  }}
                  haswidth={true}
                  stateCode={stateCode1}
                  setStateCode={setStateCode1}
                />
              </Box>
            </ContainerOfInputFields>
          ) : null}
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.call_type_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <Autocomplete
                options={props?.compounds ? props?.compounds : []}
                value={props?.selectedCompounds}
                onChange={(e, newValue) =>
                  handleInputChange(12, newValue, "interests")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(12, "interests")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("CALLS.interests")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.interests?.error}`}
                multiple
              />
            </Box>
          </ContainerOfSelectField>
        </Box>
        <Box className="col-md-12 col-12 col-lg-12">
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            // hasError={ErrorsObject?.address_governorate_id?.error}
            haswidth={true}
            hasMultiLine={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
              }}
              className="mt-3"
            >
              <TextField
                variant="filled"
                label={t("CALLS.Reply")}
                value={props?.reply}
                onChange={(e) => handleInputChange(10, e, "reply")}
                type={"text"}
                multiline
                // focused={ErrorsObject?.address_name?.error}
                className={`${
                  ErrorsObject?.address_name?.error ? "errors" : ""
                }`}
              />
              {props?.reply ? (
                <CloseIcon
                  className="closeIcon"
                  onClick={() => clearInput(10, "reply")}
                />
              ) : null}
            </Box>
            {ErrorsObject?.reply?.error &&
            ErrorsObject?.reply?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.reply?.message &&
                ErrorsObject?.reply?.message?.length > 0
                  ? ErrorsObject?.reply?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.clearForm();
            }}
          >
            {t("GLOBAL.clear")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddFormDailog(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const [destinations, setDestinations] = useState([]);
  const [selectedDestination, setSelectedDestination] = useState(null);
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [callTypes, setCallTypes] = useState([]);
  const [selectedCallType, setSelectedCallType] = useState(null);
  const [selectedContactMethod, setSelectedContactMethod] = useState(null);
  const [contactMethods, setContactMethods] = useState([]);
  const [callstateus, setCallStateus] = useState([]);
  const [selectedCallStatus, setSelectedCallStatus] = useState(null);
  const [callReasons, setCallReasons] = useState([]);
  const [selectedCallReasons, setSelectedCallReasons] = useState(null);
  const [neededFollowUp, setNeededFollowUp] = useState(null);
  const [date_time, setDate_time] = useState(null);
  const [neededFollowUpDate, setNeededFollowUpDate] = useState(null);
  const [callReasonOther, setCallReasonOther] = useState(null);
  const [importanceDegree, setImportanceDegree] = useState(false);
  const [reply, setReply] = useState("");
  const controller = new AbortController();
  const [customerReadOnly, setCustomerReadOnly] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isExternalCustomer, setIsExternalCustomer] = useState(false);
  const [externalCustomer, setExternalCustomer] = useState(null);
  const [externalCustomerPhone, setExternalCustomerPhone] = useState(null);
  const [compounds, setCompounds] = useState([]);
  const [selectedCompounds, setSelectedCompounds] = useState([]);

  useEffect(() => {
    if (props?.object && props?.object?.id) {
      let dumData = resetErrors(ErrorsObject);
      setSelectedDestination(props?.object?.call_destination);
      setSelectedCallReasons(props?.object?.call_reason);
      setSelectedCallStatus(props?.object?.call_status);
      setSelectedCallType(props?.object?.call_type);
      setSelectedContactMethod(props?.object?.contact_method);
      if (props?.object?.customer) {
        setSelectedCustomer({
          id: props?.object?.customer?.id,
          name: props?.object?.customer?.user?.full_name,
        });
      }
      setNeededFollowUp(props?.object?.needs_follow_up);
      setNeededFollowUpDate(
        props?.object?.needs_follow_up_date
          ? helpers.formatDate(props?.object?.needs_follow_up_date)
          : null
      );
      setDate_time(
        props?.object?.date_time ? new Date(props?.object?.date_time) : null
      );
      setImportanceDegree(props?.object?.importance_degree > 0 ? true : false);
      setReply(props?.object?.reply ? props?.object?.reply : null);
      setCallReasonOther(props?.object?.call_reason_other);
      setSelectedCompounds(props?.object?.compounds);
    } else {
      let dumData = resetErrors(ErrorsObject);
      clearForm();
    }
  }, [props?.object]);

  useEffect(() => {
    if (props?.customer) {
      setSelectedCustomer({
        id: props?.customer?.id,
        name: props?.customer?.user?.full_name,
      });
      setCustomerReadOnly(true);
    }
  }, [props?.customer]);

  useEffect(() => {
    let abortController = new AbortController();
    loadMasterData();

    return () => {
      abortController.abort();
    };
  }, []);
  const loadMasterData = async () => {
    let data = null;
    try {
      setLoading(true);
      let masters = [
        {
          type: "customers",
        },
        {
          type: "call_types",
          params: {
            web_or_mobile_list: ["web", "both"],
          },
        },
        {
          type: "call_destinations",
        },
        {
          type: "call_statuses",
        },
        {
          type: "contact_methods",
        },
        {
          type: "contact_methods",
        },
        {
          type: "compounds",
        },
      ];

      data = await generalAPI.getMasters({
        params: {
          masters: masters,
        },
      });
      if (data && data?.status) {
        if (Array.isArray(data?.data?.customers)) {
          let arr = [];
          data?.data?.customers?.map((itm) => {
            arr.push({
              id: itm?.id,
              name: itm?.user?.full_name,
            });
          });
          setCustomers(arr);
          if (arr?.length > 0) {
            setSelectedCustomer(arr[0]);
          }
        }
        setCallTypes(
          Array.isArray(data?.data?.call_types) ? data?.data?.call_types : []
        );
        setContactMethods(
          Array.isArray(data?.data?.contact_methods)
            ? data?.data?.contact_methods
            : []
        );
        setCallStateus(
          Array.isArray(data?.data?.call_statuses)
            ? data?.data?.call_statuses
            : []
        );
        setDestinations(
          Array.isArray(data?.data?.call_destinations)
            ? data?.data?.call_destinations
            : []
        );
        setCompounds(
          Array.isArray(data?.data?.compounds) ? data?.data?.compounds : []
        );
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };
  useEffect(() => {
    if (selectedDestination && selectedDestination?.id) {
      setCallReasons([]);
      loadDataOfCallReasons({
        call_destination_id: selectedDestination?.id,
      });
    }
  }, [selectedDestination]);

  const checkContactDate = (date) => {
    let getDate = new Date(helpers.formatDate(date));
    let dateNow = new Date(helpers.formatDate(new Date()));
    if (getDate > dateNow) {
      return false;
    } else if (getDate <= dateNow) {
      return true;
    }
  };

  const submit = async () => {
    if (!checkContactDate(date_time)) {
      toast.warn(t("CALLS.mustContatDateBeLessOrEqualCurrentDate"));
      return false;
    }
    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);
      let data = {
        call_destination_id:
          selectedDestination && selectedDestination?.id
            ? selectedDestination?.id
            : null,
        call_type_id:
          selectedCallType && selectedCallType?.id
            ? selectedCallType?.id
            : null,
        contact_method_id:
          selectedContactMethod && selectedContactMethod?.id
            ? selectedContactMethod?.id
            : null,
        call_status_id:
          selectedCallStatus && selectedCallStatus?.id
            ? selectedCallStatus?.id
            : null,
        call_reason_id:
          selectedCallReasons && selectedCallReasons?.id
            ? selectedCallReasons?.id
            : null,
        call_reason_other: callReasonOther ? callReasonOther : null,
        needs_follow_up: neededFollowUp
          ? 1
          : neededFollowUp == false
          ? 0
          : null,
        needs_follow_up_date: neededFollowUpDate
          ? helpers.formatDate(neededFollowUpDate)
          : null,
        importance_degree: importanceDegree
          ? 1
          : importanceDegree == false
          ? 0
          : null,
        date_time: date_time
          ? helpers.formatDate(date_time) + " " + helpers.formatTime(date_time)
          : null,
        reply: reply ? reply : null,
        customer_id: !isExternalCustomer
          ? selectedCustomer && selectedCustomer?.id
            ? selectedCustomer?.id
            : null
          : null,
        compound_ids:
          selectedCompounds && selectedCompounds?.length
            ? selectedCompounds?.map((c) => c?.id)
            : [],
        is_external_customer: isExternalCustomer ? 1 : 0,
        external_customer: isExternalCustomer ? externalCustomer : null,
        external_customer_phone: isExternalCustomer
          ? externalCustomerPhone
          : null,
      };
      let result = null;
      if (props?.object && props?.object?.id) {
        data = {
          ...data,
          id: props?.object?.id,
          _method: "put",
        };
        result = await CallAPI.UpdateCall({
          data: data,
        });
      } else {
        result = await CallAPI.AddCall({
          data: data,
        });
      }

      if (result?.status) {
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        clearForm();
        props.loadData();
        props.setAddForm(false);
      } else {
        if (typeof result.errorMessage === "object") {
          let updatedObject = resetErrors(ErrorsObject);
          setErrorsObject(checkErrors(result.errorMessage, updatedObject));
        }
        setLoading(false);
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const clearForm = () => {
    setSelectedDestination(null);
    setSelectedCallReasons(null);
    setSelectedCallStatus(null);
    setSelectedCallType(null);
    setSelectedContactMethod(null);
    setSelectedCustomer(null);
    setCallReasonOther("");
    setReply("");
    setNeededFollowUp(null);
    setNeededFollowUpDate(null);
    setImportanceDegree(false);
    setDate_time(null);
  };

  useEffect(() => {
    if (!props?.object && !props?.customer) clearForm();
  }, [props?.addForm, props?.object]);

  const loadDataOfCallReasons = async (searchParams) => {
    setLoading(true);

    const result = await call_reasonsAPI.call_reasonsList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setCallReasons(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      customeWidth={"80% !important"}
      title={props?.object ? t("CALLS.updateTitle") : t("CALLS.addTitle")}
      content={
        <RenderContent
          date_time={date_time}
          compounds={compounds}
          selectedCompounds={selectedCompounds}
          setSelectedCompounds={setSelectedCompounds}
          setDate_time={setDate_time}
          neededFollowUpDate={neededFollowUpDate}
          setNeededFollowUpDate={setNeededFollowUpDate}
          callReasonOther={callReasonOther}
          setCallReasonOther={setCallReasonOther}
          importanceDegree={importanceDegree}
          setImportanceDegree={setImportanceDegree}
          destinations={destinations}
          selectedDestination={selectedDestination}
          setSelectedDestination={setSelectedDestination}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          callTypes={callTypes}
          contactMethods={contactMethods}
          selectedCallType={selectedCallType}
          setSelectedCallType={setSelectedCallType}
          selectedContactMethod={selectedContactMethod}
          setSelectedContactMethod={setSelectedContactMethod}
          callReasons={callReasons}
          selectedCallReasons={selectedCallReasons}
          setSelectedCallReasons={setSelectedCallReasons}
          callstateus={callstateus}
          selectedCallStatus={selectedCallStatus}
          setSelectedCallStatus={setSelectedCallStatus}
          neededFollowUp={neededFollowUp}
          setNeededFollowUp={setNeededFollowUp}
          open={props.addForm}
          setOpen={props.setAddForm}
          customerReadOnly={customerReadOnly}
          setReply={setReply}
          reply={reply}
          isExternalCustomer={isExternalCustomer}
          setIsExternalCustomer={setIsExternalCustomer}
          externalCustomer={externalCustomer}
          setExternalCustomer={setExternalCustomer}
          externalCustomerPhone={externalCustomerPhone}
          setExternalCustomerPhone={setExternalCustomerPhone}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          clearForm={clearForm}
          submit={submit}
        />
      }
    />
  );
}
