import React, { useState, useEffect, useMemo } from "react";
import Grid from "../../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import DropDownGrid from "../../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Switch,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import AddFormDailog from "./AddFormDailog";
import swal from "sweetalert";
import SearchInput from "../../Components/SearchInput";
import SearchDate from "../../Components/SearchDate";
import helpers from "../../../assets/js/helper";
import { useGlobalContext } from "../../../context";
import * as FaIcons from "react-icons/fa";
import unitAPI from "../../../Global/Network/Unit/index";
import RolesOfUserAPI from "../../../Global/Network/RoleUser/index";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import { useLocation, useNavigate } from "react-router-dom";

import blockAPI from "../../../Global/Network/Block";
import buildingAPI from "../../../Global/Network/Building";
import floorAPI from "../../../Global/Network/Floor";
import generalAPI from "../../../Global/Network/General";
import customerAPI from "../../../Global/Network/Customer";
import unitTypeAPI from "../../../Global/Network/UnitType";

import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import SearchPhone from "../../Components/SearchPhone";

import PolylineIcon from '@mui/icons-material/Polyline';
import MoveDownIcon from '@mui/icons-material/MoveDown';
import { debounce } from 'debounce';
import Preloading from "../../../Global/Preload/Preloading";


const RenderSearchContent = (props) => {
  const [t] = useTranslation("common");
  const controller = new AbortController();

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
        padding: "0 8px",
        "& .MuiChip-label": {
          fontFamily: "Cairo-Light !important",
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <SearchInput
          value={props?.name}
          setValue={props?.setName}
          title={t("UNITS.name")}
        />
        {/* <SearchInput
          value={props?.price}
          setValue={props?.setPrice}
          title={t("UNITS.price")}
        /> */}
        <SearchInput
          value={props?.area}
          setValue={props?.setArea}
          title={t("UNITS.area")}
        />
      </Box>

      <SearchDate
        value={props?.deliveryDate}
        setValue={props?.setDeliveryDate}
        title={t("UNITS.deliveryDate")}
      />
      <Box
        sx={{
          display: "flex",

          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>{t("GLOBAL.is_it_linked_with_image")}</Typography>

          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={props?.needsToBeLinked}
            onChange={(e) => {
              props.setNeedsToBeLinked(e?.target.value);
              // setGender(e?.target?.value)
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.needs_draw")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.doesnt_need_draw")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.all")}
            />
          </RadioGroup>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          {/* <FormControlLabel control={<Switch checked={props?.iqdUSD}
            onChange={(e) => {
              props.setIqdUsd(e?.target.checked)
            }}
          />} sx={{}} label={props?.iqdUSD ? 'USD' : 'IQD'}
          /> */}
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={props?.iqdUSD}
            onChange={(e) => {
              console.log("e?.target.value", e?.target.value);
              props.setIqdUsd(e?.target.value);
              // setGender(e?.target?.value)
            }}
          >
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.all")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.iqd")}
            />
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("UNITS.usd")}
            />
          </RadioGroup>
        </Box>
      </Box>

      {/* <Box
        sx={{
          display: 'flex',
          justifyContent: "space-between",
          alignItems: "center",
          width: '100%',
          paddingRight: '5px',
        }}
      >
        <SearchDate
          value={props?.fromDate}
          setValue={props?.setFromDate}
          title={t('UNITS.work_fromDate_search')}
          hasFontSize={'15px !important'}
          clearPositionTop={'10px'}
          clearPositionLeft={'10px'}

        />
        <SearchDate
          value={props?.toDate}
          setValue={props?.setToDate}
          title={t('UNITS.work_toDate_search')}
          hasFontSize={'15px !important'}
          clearPositionTop={'10px'}
          clearPositionLeft={'10px'}
        />
      </Box> */}
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.customers ? props.customers : []}
        clearOnBlur={false}
        getOptionLabel={(option) => (option && option?.user?.full_name) || ""}
        value={props?.selectedCustomer}
        onChange={(e, v, r) => {
          props.setSelectedCustomer(v ? v : "");
          props?.setSelectCustomerFormState(null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.customer")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({ search_text: e.target.value }, "customers");
            }}
          />
        )}
      />
      <SearchPhone
        value={props?.customerPhone}
        setValue={props?.setCustomerPhone}
        title={t("GLOBAL.customerPohone")}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.unitLocations ? props.unitLocations : []}
        getOptionLabel={(option) => (option && option?.name) || ""}
        value={props?.selectedUnitLocation}
        onChange={(e, v, r) => {
          props.setSelectedUnitLocation(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.unitLocation")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.unitTypes ? props.unitTypes : []}
        clearOnBlur={false}
        getOptionLabel={(option) => (option && option?.name) || ""}
        value={props?.selectedUnitType}
        onChange={(e, v, r) => {
          props.setSelectedUnitType(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.unitType")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({ search_text: e.target.value }, "unitTypes");
            }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.unitStatuses ? props.unitStatuses : []}
        getOptionLabel={(option) => (option && option?.name) || ""}
        value={props?.selectedUnitStatus}
        multiple
        onChange={(e, v, r) => {
          props.setSelectedUnitStatus(v ? v : "");
          props?.setSelectedUnitTypeFromState(null);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.unitStatus")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.compoundPhases ? props.compoundPhases : []}
        getOptionLabel={(option) =>
          (option && option?.phase && option?.phase?.name) || ""
        }
        value={props?.selectedCompoundPhase}
        onChange={(e, v, r) => {
          props.setSelectedCompoundPhase(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.compoundPhase")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) =>
          (option?.user && option?.user?.full_name) || ""
        }
        value={props?.createdBy}
        onChange={(e, v, r) => {
          props.setCreatedBy(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("UNITS.SEARCH_USERS")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />

      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.compounds ? props?.compounds : []}
        value={props?.selectedCompound}
        onChange={(e, v, r) => {
          if (v) {
            props?.setSelectedCompound(v);
            props?.setSelectedBlock({
              id: "",
              name: "",
            });
            props?.setSelectedBuilding({
              id: "",
              name: "",
            });
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          } else {
            props?.setSelectedCompound({
              id: "",
              name: "",
            });
          }
        }}
        getOptionLabel={(option) => option?.name}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"اختر المجمع السكني"}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.blocks ? props?.blocks : []}
        clearOnBlur={false}
        value={props?.selectedBlock}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBlock({
              id: "",
              name: "",
            });
            props?.setSelectedBuilding({
              id: "",
              name: "",
            });
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          } else {
            props?.setSelectedBlock(v);
            props?.setSelectedBuilding({
              id: "",
              name: "",
            });
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"اختر قاطع"}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({ search_text: e.target.value }, "blocks");
            }}
          />
        )}
        getOptionLabel={(option) => option?.name}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.buildings ? props?.buildings : []}
        clearOnBlur={false}
        value={props?.selectedBuilding}
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedBuilding({
              id: "",
              name: "",
            });
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          } else {
            props?.setSelectedBuilding(v);
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"اختر البناية"}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({ search_text: e.target.value }, "buildings");
            }}
          />
        )}
        getOptionLabel={(option) => option?.name}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props?.floors ? props?.floors : []}
        clearOnBlur={false}
        value={
          props?.selectedFloor && props?.selectedFloor?.id
            ? props?.selectedFloor
            : null
        }
        onChange={(e, v, r) => {
          if (!v) {
            props?.setSelectedFloor({
              id: "",
              name: "",
            });
          } else {
            props?.setSelectedFloor(v);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={"اختر الطابق"}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({ search_text: e.target.value }, "floors");
            }}
          />
        )}
        getOptionLabel={(option) => option?.name}
      />
    </Box>
  );
};

export default function Unit() {
  const urlParams = new URLSearchParams(window.location.search);
  const customerFromUrl = urlParams.get("customer_id");
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);
  const [addForm, setAddForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [objectName, setobjectName] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [users, setUsers] = useState([]);
  const { exportToCSV } = useGlobalContext();
  const navigate = useNavigate();
  const [filterParams, setFilterParams] = useState({});
  const [customerFromState, setSelectCustomerFormState] = useState(null);
  const { state } = useLocation();
  console.log("dataHeree===>state", state);
  const rolesRedux = useSelector((state) => state.rolesData.roles);

  const [selectedCompound, setSelectedCompound] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);
  //filters states
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");

  const [selectedUnitLocation, setSelectedUnitLocation] = useState(null);
  const [unitLocations, setUnitLocations] = useState([]);

  const [selectedUnitType, setSelectedUnitType] = useState(null);
  const [unitTypes, setUnitTypes] = useState([]);

  const [selectedUnitStatus, setSelectedUnitStatus] = useState([]);
  const [unitStatuses, setUnitStatuses] = useState([]);

  const [selectedCompoundPhase, setSelectedCompoundPhase] = useState(null);
  const [compoundPhases, setCompoundPhases] = useState([]);

  const [price, setPrice] = useState(null);
  const [iqdUSD, setIqdUsd] = useState(-1);
  const [area, setArea] = useState(null);
  const [deliveryDate, setDeliveryDate] = useState(null);

  const [customers, setCustomers] = useState(null);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [compounds, setCompounds] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState();
  const [customerPhone, setCustomerPhone] = useState(null);

  const [needsToBeLinked, setNeedsToBeLinked] = useState(-1);

  const controller = new AbortController();
  const [exportLoading, setExportLoading] = useState(false)
  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const columns = useMemo(
    () => [
      {
        headerName: t("UNITS.id"),
        field: "id",
        flex: 0.2,
        minWidth: 70,
        maxWidth: 70,
        hide: false,
      },
      {
        headerName: t("UNITS.unitStatus"),
        field: "unit_status",
        flex: 0.5,
        minWidth: 150,
        maxWidth: 150,
        hide: false,
        renderHeader: (rows) => <Box></Box>,
        renderCell: (rows) => (
          <>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Medium" }}>
                      {rows?.row?.unit_status?.name
                        ? rows?.row?.unit_status?.name
                        : t("GLOBAL.notAssignedYet")}
                    </span>
                  }
                >
                  <span
                    style={{
                      fontFamily: "Cairo-Medium",
                      width: "10px",
                      height: "10px",
                      borderRadius: "50%",
                      backgroundColor: rows?.row?.unit_status?.color
                        ? rows?.row?.unit_status?.color
                        : "lightgray",
                    }}
                    className="gridHeaderItem"
                  >
                    {
                      // rows?.row?.customer?.user?.full_name?rows?.row?.customer?.user?.full_name:t('GLOBAL.notAssignedYet')
                    }
                  </span>
                </Tooltip>
              }
            </Box>
            {!rows?.row?.has_p2 ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0 5px",
                  border: "1px solid #fff",
                  backgroundColor: rows?.row?.needs_to_be_linked_p2
                    ? "#d32f2f"
                    : "#2e7d32",
                  padding: "0px 5px",
                  borderRadius: "10px",
                  "& .MuiButtonBase-root": {
                    padding: "4px 2px !important",
                  },
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Bold" }}>
                      {rows?.row?.needs_to_be_linked_p2
                        ? t("GLOBAL.needs_to_be_linked_p2")
                        : t("GLOBAL.does_not_need_to_be_linked_p2")}
                    </span>
                  }
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      if (rows?.row?.needs_to_be_linked_p2) {
                        redirectToDrawImagePolygon(
                          rows?.row,
                          "path_2_with_floors"
                        );
                      }
                    }}
                  >
                    <MoveDownIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
            {rows?.row?.skip ? null : (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "end",
                  alignItems: "center",
                  margin: "0 5px",
                  border: "1px solid #fff",
                  backgroundColor: rows?.row?.needs_to_be_linked
                    ? "#d32f2f"
                    : "#2e7d32",
                  padding: "0px 5px",
                  borderRadius: "10px",
                  "& .MuiButtonBase-root": {
                    padding: "4px 2px !important",
                  },
                }}
              >
                <Tooltip
                  title={
                    <span style={{ fontFamily: "Cairo-Bold" }}>
                      {rows?.row?.needs_to_be_linked
                        ? t("GLOBAL.needs_to_be_linked")
                        : t("GLOBAL.does_not_need_to_be_linked")}
                    </span>
                  }
                  arrow
                >
                  <IconButton
                    onClick={() => {
                      if (rows?.row?.needs_to_be_linked) {
                        redirectToDrawImagePolygon(
                          rows?.row,
                          "path_1_standard"
                        );
                      }
                    }}
                  >
                    <PolylineIcon sx={{ color: "#fff" }} />
                  </IconButton>
                </Tooltip>
              </Box>
            )}
          </>
        ),
      },
      // { headerName: t('UNITS.name'), field: 'name', flex: 1, minWidth: 70, maxWidth: 450, hide: false },
      {
        headerName: t("UNITS.unitInfo"),
        field: "floor_id",
        flex: 1,
        minWidth: 390,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "end",
              justifyContent: "center",
              padding: "4px 0",
            }}
          >
            <span style={{ margin: "3px 0" }}>{rows?.row?.name}</span>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row-reverse",
                alignItems: "center",
                justifyContent: "center",
                // padding:'4px 0'
                gap: "5px",
                "& span": {
                  fontSize: "10.5px",
                },
              }}
            >
              <span
                style={{
                  fontFamily: "Cairo-Medium",
                  marginTop: 2,
                  marginLeft: 2,
                  color: "gray",
                  alignItems: "center",
                  display: "flex",
                }}
                className="gridHeaderItem"
              >
                <span style={{ fontFamily: "Cairo", fontSize: "9px" }}>
                  {rows?.row?.compound?.name}
                </span>
                <span style={{ fontFamily: "Cairo", direction: "rtl" }}>
                  {t("UNITS.compound") + " : "}
                </span>
              </span>
              <span style={{ fontSize: "10px" }}>/</span>
              <span
                style={{
                  fontFamily: "Cairo-Medium",
                  marginTop: 2,
                  marginLeft: 2,
                  color: "gray",
                  alignItems: "center",
                  display: "flex",
                }}
                className="gridHeaderItem"
              >
                <span style={{ fontFamily: "Cairo", fontSize: "9px" }}>
                  {rows?.row?.block?.name}
                </span>
                <span style={{ fontFamily: "Cairo", direction: "rtl" }}>
                  {t("UNITS.block") + " : "}
                </span>
              </span>
              <span style={{ fontSize: "10px" }}>/</span>
              <span
                style={{
                  fontFamily: "Cairo-Medium",
                  marginTop: 2,
                  marginLeft: 2,
                  color: "gray",
                  alignItems: "center",
                  display: "flex",
                }}
                className="gridHeaderItem"
              >
                <span style={{ fontFamily: "Cairo", fontSize: "9px" }}>
                  {" "}
                  {rows?.row?.building?.name}
                </span>
                <span style={{ fontFamily: "Cairo", direction: "rtl" }}>
                  {t("UNITS.building") + " : "}
                </span>
              </span>
              <span style={{ fontSize: "10px" }}>/</span>
              <span
                style={{
                  fontFamily: "Cairo-Medium",
                  marginTop: 2,
                  marginLeft: 2,
                  color: "gray",
                  alignItems: "center",
                  display: "flex",
                }}
                className="gridHeaderItem"
              >
                <span style={{ fontFamily: "Cairo", fontSize: "9px" }}>
                  {" "}
                  {rows?.row?.floor?.name}
                </span>
                <span style={{ fontFamily: "Cairo", direction: "rtl" }}>
                  {t("UNITS.floor") + " : "}
                </span>
              </span>
            </Box>
          </Box>
        ),
      },
      {
        headerName: t("UNITS.area"),
        field: "area",
        flex: 1,
        minWidth: 80,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.area}
            </span>
          </Box>
        ),
      },
      // {headerName:t('UNITS.description'),field:'description',flex:2,minWidth:200,maxWidth:450,hide:false},

      {
        headerName: t("UNITS.unitLocation"),
        field: "unit_location_id",
        flex: 1,
        minWidth: 100,
        maxWidth: 450,
        hide: false,
        renderHeader: () => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "start",
              justifyContent: "start",
              height: "69.5px",
              // top:''
            }}
            className="cutomeRenderHeader"
          >
            <span
              style={{ height: "20px", textAlign: "left" }}
              className="gridHeaderItem"
            >
              {t("UNITS.unitLocation")}
            </span>
            <span
              style={{ height: "20px", textAlign: "left" }}
              className="gridHeaderItem"
            >
              {t("UNITS.unitType")}
            </span>
          </Box>
        ),
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.unit_location?.name}
            </span>
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.unit_type?.name}
            </span>
          </Box>
        ),
      },
      // {headerName:t('UNITS.unitType'),field:'unit_type_id',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.unit_type?.name}</span>
      //   </Box>
      // )
      // },
      // {headerName:t('UNITS.unitStatus'),field:'unit_status_id',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.unit_status?.name}</span>
      //   </Box>
      // )
      // },

      // {headerName:t('UNITS.compoundPhase'),field:'compoundPhase',flex:2,minWidth:100,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.compound_phase?.phase?.name}</span>
      //   </Box>
      // )
      // },
      {
        headerName: t("UNITS.price"),
        field: "price",
        flex: 1,
        minWidth: 130,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.price
                ? helpers.getFormatedPrice(rows?.row?.price, rows?.row?.iqd_usd)
                : 0}
            </span>
          </Box>
        ),
      },
      // {headerName:t('UNITS.iqd_usd'),field:'iqd_usd',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{!rows?.row?.iqd_usd?"IQD":'USD'}</span>
      //   </Box>
      // )
      // },
      // {headerName:t('UNITS.width'),field:'width',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.width}</span>
      //   </Box>
      // )
      // },
      // {headerName:t('UNITS.length'),field:'length',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.length}</span>
      //   </Box>
      // )
      // },
      // {headerName:t('UNITS.deliveryDate'),field:'deliveryDate',flex:2,minWidth:150,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.delivery_date&&rows?.row?.delivery_date!='0000-00-00'?helpers.formatDate(rows?.row?.delivery_date):t('GLOBAL.notAssingedDateYet')}</span>

      //   </Box>
      // )
      // },
      // {headerName:t('UNITS.created_at'),field:'created_at',flex:2,minWidth:250,maxWidth:450,hide:false,
      // renderCell: (rows) => (
      //   <Box
      //   sx={{
      //     display:'flex',
      //     flexDirection:'column',
      //     alignItems: 'center',
      //     justifyContent: 'center',
      // }}
      //   >
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.created_at?helpers.formatDate(rows?.row?.created_at):'0000-00-00'}</span>
      //     <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.created_at?helpers.formatTime(rows?.row?.created_at):'00:00:00'}</span>

      //   </Box>
      // )
      // },
      {
        headerName: t("UNITTYPE.actions"),
        field: "Actions",
        flex: 0.4,
        minWidth: 90,
        maxWidth: 250,
        hide: false,
        renderCell: (rows) => (
          <DropDownGrid className="containerOFDropDownToolTipeGrid">
            {rolesRedux?.view_unit?.value ? (
              <MenuItem
                onClick={() => {
                  window.open(
                    window.location.origin +
                    "/admin/view-unit?id=" +
                    rows?.row?.id,
                    "_blank"
                  );
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <RemoveRedEyeIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("GLOBAL.action_view")}
              </MenuItem>
            ) : null}
            {rolesRedux?.update_unit?.value ? (
              <MenuItem
                onClick={() => {
                  updateFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <EditIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("GLOBAL.action_edit")}
              </MenuItem>
            ) : null}
            {rolesRedux?.delete_unit?.value ? (
              <MenuItem
                onClick={() => {
                  deleteFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t("GLOBAL.action_delete")}
              </MenuItem>
            ) : null}
            {rolesRedux?.update_unit?.value == false &&
              rolesRedux?.delete_unit?.value == false &&
              !rolesRedux?.view_unit?.value ? (
              <p style={{ fontFamily: "Cairo", margin: "5px 20px" }}>
                {t("GLOBAL.emptyPoPup")}
              </p>
            ) : null}
          </DropDownGrid>
        ),
      },
    ],
    [rolesRedux]
  );
  useEffect(() => {
    console.log("customerFromUrl=====>", customerFromUrl);
    if (customerFromUrl) {
      console.log("customerFromUrl=====>", customerFromUrl);
      setSelectCustomerFormState(customerFromUrl);
      setFilterParams({
        ...filterParams,
        customer_id: customerFromUrl,
      });
    }
  }, [customerFromUrl]);

  useEffect(() => {
    console.log("dataHeree===>enterd", state?.data);
    let obj = null;
    if (state && state?.data) {
      if (state?.data?.floor) {
        if (state?.data?.floor?.compound) {
          console.log(
            "dataHeree===>compound",
            state?.data?.floor?.compound?.id
          );
          setSelectedCompound({
            id: state?.data?.floor?.compound?.id,
            name: state?.data?.floor?.compound?.name,
          });
          obj = {
            ...obj,
            compound_id: state?.data?.floor?.compound?.id,
          };
        }
        if (state?.data?.floor?.block) {
          console.log("dataHeree===>block", state?.data?.floor?.block?.id);

          setSelectedBlock({
            id: state?.data?.floor?.block?.id,
            name: state?.data?.floor?.block?.name,
          });
          obj = {
            ...obj,
            block_id: state?.data?.floor?.block?.id,
          };
        }
        if (state?.data?.floor?.building) {
          console.log(
            "dataHeree===>building",
            state?.data?.floor?.building?.id
          );

          setSelectedBuilding({
            id: state?.data?.floor?.building?.id,
            name: state?.data?.floor?.building?.name,
          });
          obj = {
            ...obj,
            building_id: state?.data?.floor?.building?.id,
          };
        }
        if (state?.data?.floor) {
          console.log("dataHeree===>floor", state?.data?.floor?.id);

          setSelectedFloor({
            id: state?.data?.floor?.id,
            name: state?.data?.floor?.name,
          });
          obj = {
            ...obj,
            floor_id: state?.data?.floor?.id,
          };
        }
      }
      // console.log('hfkjhaskldf',state)
      if (state?.data && state?.data?.customer) {
        setSelectCustomerFormState(state?.data?.customer);
        obj = {
          ...obj,
          customer_id: state?.data?.customer?.id,
        };
      }
      setFilterParams({
        ...filterParams,
        ...obj,
      });
    }
  }, [state?.data]);
  useEffect(() => {
    if (selectedCompound?.id) loadBlocks();

    return () => { };
  }, [selectedCompound]);

  useEffect(() => {
    if (selectedBlock?.id) loadBuildings({});

    return () => { };
  }, [selectedBlock]);

  useEffect(() => {
    if (selectedBuilding?.id) loadFloors({});
    return () => { };
  }, [selectedBuilding]);

  const handleSearch = debounce((value, name) => {
    if (name == "blocks") loadBlocks(value);
    else if (name == "buildings") loadBuildings(value);
    else if (name == "floors") loadFloors(value);
    else if (name == "unitTypes") loadUnitTypes(value);
    else if (name == "customers") loadCustomers(value);
  }, 500);

  const loadBlocks = async (searchParams) => {
    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([]);
      setBuildings([]);
      setBlocks([]);
      return;
    }
    setLoading(true);
    setBlocks([]);

    try {
      const result = await blockAPI.blocks({
        params: {
          page_size: 10,
          page: 0,
          compound_id: selectedCompound?.id,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setBlocks(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadBuildings = async (searchParams) => {
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([]);
      setFloors([]);
      return;
    }
    setLoading(true);
    setBuildings([]);
    try {
      const result = await buildingAPI.buildings({
        params: {
          page_size: 10,
          page: 0,
          block_id: selectedBlock?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setBuildings(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadFloors = async (searchParams) => {
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([]);
      return;
    }
    setLoading(true);
    setFloors([]);
    try {
      const result = await floorAPI.floors({
        params: {
          page_size: 10,
          page: 0,
          building_id: selectedBuilding?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setFloors(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadCustomers = async (searchParams) => {
    setLoading(true);
    setCustomers([]);
    try {
      const result = await customerAPI.customers({
        params: {
          page_size: 10,
          page: 0,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setCustomers(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadUnitTypes = async (searchParams) => {
    setLoading(true);
    setUnitTypes([]);
    try {
      const result = await unitTypeAPI.unitTypes({
        params: {
          page_size: 10,
          page: 0,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setUnitTypes(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };

  useEffect(() => {
    let abortController = new AbortController();
    loadData();
    return () => {
      abortController.abort();
    };
  }, [pageNumber, pageSize, filterParams]);

  useEffect(() => {
    let abortController = new AbortController();
    loadMasterData();
    loadCustomers({});
    loadUnitTypes({});
    // loadDataOfUsers()
    // loadDataOfUnitLocations()
    // loadDataOfUnitTypes()
    // loadDataOfUnitStatus()
    // loadDataOfUnitFloor()
    // loadDataOfCompoundPhase()
    // loadDataOfCustomer()
    return () => {
      abortController.abort();
    };
  }, []);

  const loadData = async (searchParams) => {
    setLoading(true);
    console.log("dataHeree===>Params", filterParams);
    const filterParamsData = {
      ...filterParams,
      ...searchParams,
    };

    const result = await unitAPI.units({
      params: {
        ...(state?.data?.floor?.id ? { floor_id: state?.data?.floor?.id } : {}),
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData,
      },
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      setRows(result?.data?.data);
      setRowsTotal(result?.data?.total);
      setPageCount(result?.data?.last_page);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  const createFUnction = () => {
    setselectedObject(null);
    setAddForm(true);
  };
  const updateFunction = (obj) => {
    setselectedObject(obj);
    setAddForm(true);
  };
  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {
        try {
          setLoading(true);
          let result = null;
          if (obj && obj?.id) {
            result = await unitAPI.deleteUnit({
              data: {
                id: obj.id,
              },
            });
          }

          if (result?.status) {
            setLoading(false);
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber,
            });
          } else {
            setLoading(false);
            if (result?.errorMessage) {
              toast.error(result?.errorMessage);
            } else toast.error(t("NETWORKMESSAGE.deleteErrorr"));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };
  const clearFunction = () => {
    setobjectName("");
    setNeedsToBeLinked(-1);
    setFromDate(null);
    setToDate(null);
    setCreatedBy(null);
    setName("");
    setDescription("");
    setSelectedFloor(null);
    setSelectedBuilding(null);
    setSelectedBlock(null);
    setSelectedCompound(null);

    setSelectedUnitLocation(null);
    setSelectedUnitType(null);
    setSelectedUnitStatus([]);
    setSelectedCompoundPhase(null);
    setPrice("");
    setIqdUsd(-1);
    setArea("");
    setDeliveryDate(null);
    setFilterParams(null);
    navigate(window.location.pathname, { replace: true });
  };
  const searchFunction = () => {
    let data = {};
    if (name) {
      data = {
        ...data,
        name: name,
      };
    }
    if (description) {
      data = {
        ...data,
        description: description,
      };
    }
    if (selectedCustomer) {
      data = {
        ...data,
        customer_id: selectedCustomer?.id,
      };
    }
    if (customerPhone) {
      data = {
        ...data,
        customer_phone: customerPhone,
      };
    }
    if (selectedUnitLocation) {
      data = {
        ...data,
        unit_location_id: selectedUnitLocation?.id,
      };
    }
    if (selectedUnitType) {
      data = {
        ...data,
        unit_type_id: selectedUnitType?.id,
      };
    }
    if (selectedUnitStatus && selectedUnitStatus?.length > 0) {
      data = {
        ...data,
        unit_status_ids: selectedUnitStatus?.map((itm) => itm?.id),
      };
    }
    if (selectedCompoundPhase) {
      data = {
        ...data,
        compound_phase_id: selectedCompoundPhase?.id,
      };
    }
    if (selectedCompound?.id) {
      data = {
        ...data,
        compound_id: selectedCompound?.id,
      };
    }
    if (selectedBlock?.id) {
      data = {
        ...data,
        block_id: selectedBlock?.id,
      };
    }
    if (selectedBuilding?.id) {
      data = {
        ...data,
        building_id: selectedBuilding?.id,
      };
    }
    if (selectedFloor?.id) {
      data = {
        ...data,
        floor_id: selectedFloor?.id,
      };
    }
    if (price) {
      data = {
        ...data,
        price: price,
      };
    }
    if (area) {
      data = {
        ...data,
        area: area,
      };
    }
    if (deliveryDate) {
      data = {
        ...data,
        delivery_date: helpers.formatDate(deliveryDate),
      };
    }
    if (iqdUSD > -1) {
      data = {
        ...data,
        iqd_usd: iqdUSD,
      };
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id,
      };
    }
    if (needsToBeLinked > -1) {
      data = {
        ...data,
        needs_to_be_linked: needsToBeLinked,
      };
    }
    setFilterParams(data);
    // loadData(data);
  };
  const loadDataForExport = async (searchParams) => {
    setExportLoading(true);
    let result = await unitAPI.units({
      params: {
        page_size: rowsTotal,
        ...searchParams,
      },
      signal: controller?.signal,
    });
    setExportLoading(false);
    return result;
  };
  const ExportFunction = async () => {
    let filteredData = {
      export_from_back:true
    };
    if (name) {
      filteredData = {
        ...filteredData,
        name: name,
      };
    }
    if (selectedCustomer) {
      filteredData = {
        ...filteredData,
        customer_id: selectedCustomer?.id,
      };
    }
    if (customerPhone) {
      filteredData = {
        ...filteredData,
        customer_phone: customerPhone,
      };
    }
    if (description) {
      filteredData = {
        ...filteredData,
        description: description,
      };
    }
    if (selectedUnitLocation) {
      filteredData = {
        ...filteredData,
        unit_location_id: selectedUnitLocation?.id,
      };
    }
    if (selectedUnitType) {
      filteredData = {
        ...filteredData,
        unit_type_id: selectedUnitType?.id,
      };
    }
    if (selectedUnitStatus && selectedUnitStatus?.length > 0) {
      filteredData = {
        ...filteredData,
        unit_status_ids: selectedUnitStatus?.map((itm) => itm?.id),
      };
    }
    if (selectedCompoundPhase) {
      filteredData = {
        ...filteredData,
        compound_phase_id: selectedCompoundPhase?.id,
      };
    }
    if (selectedCompound) {
      filteredData = {
        ...filteredData,
        compound_id: selectedCompound?.id,
      };
    }
    if (selectedBlock) {
      filteredData = {
        ...filteredData,
        block_id: selectedBlock?.id,
      };
    }
    if (selectedBuilding) {
      filteredData = {
        ...filteredData,
        building_id: selectedBuilding?.id,
      };
    }
    if (selectedFloor) {
      filteredData = {
        ...filteredData,
        floor_id: selectedFloor?.id,
      };
    }
    if (price) {
      filteredData = {
        ...filteredData,
        price: price,
      };
    }
    if (area) {
      filteredData = {
        ...filteredData,
        area: area,
      };
    }
    if (deliveryDate) {
      filteredData = {
        ...filteredData,
        delivery_date: helpers.formatDate(deliveryDate),
      };
    }
    if (iqdUSD > -1) {
      filteredData = {
        ...filteredData,
        iqd_usd: iqdUSD,
      };
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id,
      };
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      if(fetchedData?.errorMessage)
      toast.warn(fetchedData?.errorMessage)
      else
      toast.error("لا يمكن استرجاع البيانات");
      return;
    }

    let arr = [];
    window.open(fetchedData?.data,'_blank')
    // fetchedData?.data?.data &&
    //   fetchedData?.data?.data?.map((itm) => {
    //     arr.push({
    //       [`${t("UNITS.id")}`]: itm?.id,
    //       [`${t("UNITS.name")}`]: itm?.name,
    //       [`${t("UNITS.description")}`]: itm?.description,
    //       [`${t("UNITS.floor")}`]: itm?.floor?.name,
    //       [`${t("UNITS.unitLocation")}`]: itm?.unit_location?.name,
    //       [`${t("UNITS.unitType")}`]: itm?.unit_type?.name,
    //       [`${t("UNITS.unitStatus")}`]: itm?.unit_status?.name,
    //       [`${t("UNITS.compoundPhase")}`]: itm?.compound_phase?.phase?.name,
    //       [`${t("UNITS.customer")}`]: itm?.customer?.user?.full_name,
    //       [`${t("UNITS.price")}`]: itm?.price,
    //       [`${t("UNITS.iqd_usd")}`]: !itm?.iqd_usd ? "IQD" : "USD",
    //       [`${t("UNITS.area")}`]: itm?.area,
    //       [`${t("UNITS.width")}`]: itm?.width,
    //       [`${t("UNITS.length")}`]: itm?.length,
    //       [`${t("UNITS.deliveryDate")}`]:
    //         itm?.delivery_date && itm?.delivery_date != "0000-00-00"
    //           ? helpers.formatDate(itm?.created_at)
    //           : t("GLOBAL.notAssingedDateYet"),
    //       [`${t("UNITS.created_at")}`]: itm?.created_at
    //         ? helpers.formatDate(itm?.created_at) +
    //         " " +
    //         helpers.formatTime(itm?.created_at)
    //         : "0000-00-00 00:00:00",
    //     });
    //   });
    // exportToCSV(arr, "UNITS");
  };

  const loadMasterData = async () => {
    let data = null;
    try {
      setLoading(true);
      let masters = [
        {
          type: "unit_locations",
          // params:{

          // }
        },
        // {
        //   type: "unit_types",
        // },
        {
          type: "unit_statuses",
        },
        // {
        //   type: "floors",
        //   params:{
        //     page_size: 10,
        //   }
        // },
        // {
        //   type: "customers",
        // },
        {
          type: "compound_phases",
        },
        {
          type: "employees",
        },
        {
          type: "compounds",
        },
      ];

      data = await generalAPI.getMasters({
        params: {
          masters: masters,
        },
      });
      if (data && data?.status) {
        setUnitLocations(
          Array.isArray(data?.data?.unit_locations)
            ? data?.data?.unit_locations
            : []
        );
        // setUnitTypes(
        //   Array.isArray(data?.data?.unit_types) ? data?.data?.unit_types : []
        // );
        setUnitStatuses(
          Array.isArray(data?.data?.unit_statuses)
            ? data?.data?.unit_statuses
            : []
        );
        if (Array.isArray(data?.data?.unit_statuses)) {
          if (state?.data?.status_id) {
            let selectedStatus = data?.data?.unit_statuses.filter(
              (item) => item?.id == state?.data?.status_id
            );
            if (selectedStatus && selectedStatus?.length > 0)
              setSelectedUnitStatus(selectedStatus);
          }
        }
        setFloors(Array.isArray(data?.data?.floors) ? data?.data?.floors : []);
        setCompoundPhases(
          Array.isArray(data?.data?.compound_phases)
            ? data?.data?.compound_phases
            : []
        );
        setUsers(
          Array.isArray(data?.data?.employees) ? data?.data?.employees : []
        );
        setCompounds(
          Array.isArray(data?.data?.compounds) ? data?.data?.compounds : []
        );

        // if (Array.isArray(data?.data?.customers)) {
        //   if (customerFromUrl) {
        //     data?.data?.customers?.map((itm) => {
        //       if (itm?.id == customerFromUrl) {
        //         setSelectedCustomer(itm);
        //       }
        //     });
        //   }
        //   setCustomers(data?.data?.customers);
        // } else {
        //   setSelectedCustomer(null);
        //   setCustomers([]);
        // }
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };

  const redirectToDrawImagePolygon = (unit, type) => {
    swal({
      title: t("GLOBAL.are_you_sure"),
      text: t("GLOBAL.redirect_to_image_polygon"),
      icon: "warning",
      buttons: [t("GLOBAL.no"), t("GLOBAL.yes")],
      dangerMode: true,
    }).then(async (iAmSure) => {
      if (iAmSure) {
        confirmRedirect(unit, type);
      } else {
        // alert('canceled')
      }
    });
  };
  const confirmRedirect = async (unit, type) => {
    console.log("unit_details", unit);
    // return
    let selectedEntity = {
      sourceEntity: {
        type: "floors",
        entity: {
          label: unit?.floor?.name,
          name: unit?.floor?.name,
          id: unit?.floor?.id,
          images: unit?.floor?.images,
        },
        label: "الطابق",
      },
      linkedToEntity: {
        type: "unit_types",
        entity: {
          label: unit?.unit_type?.name,
          name: unit?.unit_type?.name,
          needs_to_be_linked: unit?.unit_type?.needs_to_be_linked,
          id: unit?.unit_type?.id,
          images: [],
        },
        label: "نوع الوحدة",
        unitLocation: {
          type: "unit_locations",
          entity: {
            label: unit?.unit_location?.name,
            name: unit?.unit_location?.name,
            id: unit?.unit_location?.id,
          },
          label: "موقع الوحدة",
        },
        floor: {
          id: unit?.floor?.id,
          label: unit?.floor?.name,
          name: unit?.floor?.name,
        },
      },
    };

    if (type == "path_2_with_floors") {
      selectedEntity = {
        ...selectedEntity,
        sourceEntity: {
          type: "compounds",
          entity: {
            label: unit?.compound?.name,
            name: unit?.compound?.name,
            id: unit?.compound?.id,
            images: unit?.compound?.images,
          },
          label: "المجمع",
        },
        linkedToEntity: {
          type: "",
          entity: {
            id: "",
            label: "",
          },
          label: "",
        },
      };
    }
    navigate("/admin/image-draw", {
      state: {
        data: {
          selectedEntity: selectedEntity,
          ...(type == "path_2_with_floors"
            ? {
              unit_location: {
                ...unit?.unit_location,
                label: unit?.unit_location?.name,
              },
              unit_type: {
                ...unit?.unit_type,
                label: unit?.unit_type?.name,
              },
              floor: [
                {
                  id: unit?.floor?.id,
                  name: unit?.floor?.name,
                  label: unit?.floor?.name,
                },
              ],
              building: {
                ...unit?.building,
                label: unit?.building?.name,
              },
              block: {
                ...unit?.block,
                label: unit?.block?.name,
              },
              compound: {
                ...unit?.compound,
                label: unit?.compound?.name,
              },
            }
            : {}),
          image_type: type,
        },
      },
    });
  };
  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
      }}
      className=" pt-3 mt-1"
    >
      {exportLoading ? <Preloading /> : null}
      {addForm ? (
        <AddFormDailog
          object={selectedObject}
          addForm={addForm}
          setAddForm={setAddForm}
          loadData={loadData}
          compound={selectedCompound}
          block={selectedBlock}
          building={selectedBuilding}
          floor={selectedFloor}
        />
      ) : null}

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginLeft: "10px",
              marginBottom: "10px",
              fontFamily: "Cairo-Bold",
              fontSize: "16px",
            }}
          >
            {t("UNITS.title")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginRight: "20px",
            backgroundColor: "#1E6A99",
            color: "#FFFFFF",
            width: "42px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          aria-controls={openFilterColumn ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? "true" : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser style={{ fontSize: "30px", color: "#fff" }} />
        </Box>
      </Box>

      <Divider
        sx={{
          backgroundColor: "#EBF2F7",
          margin: "30px",
          marginTop: "10px",
          width: "100%",
        }}
        style={{
          height: "3px",
        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_unit?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_unit?.value}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={
          <RenderSearchContent
            objectName={objectName}
            setobjectName={setobjectName}
            fromDate={fromDate}
            setNeedsToBeLinked={setNeedsToBeLinked}
            needsToBeLinked={needsToBeLinked}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            users={users}
            name={name}
            setName={setName}
            description={description}
            setDescription={setDescription}
            floors={floors}
            selectedFloor={selectedFloor}
            setSelectedFloor={setSelectedFloor}
            buildings={buildings}
            selectedBuilding={selectedBuilding}
            setSelectedBuilding={setSelectedBuilding}
            blocks={blocks}
            selectedBlock={selectedBlock}
            setSelectedBlock={setSelectedBlock}
            compounds={compounds}
            selectedCompound={selectedCompound}
            setSelectedCompound={setSelectedCompound}
            selectedUnitLocation={selectedUnitLocation}
            setSelectedUnitLocation={setSelectedUnitLocation}
            unitLocations={unitLocations}
            setUnitLocations={setUnitLocations}
            setSelectedUnitType={setSelectedUnitType}
            selectedUnitType={selectedUnitType}
            unitTypes={unitTypes}
            setSelectedUnitStatus={setSelectedUnitStatus}
            selectedUnitStatus={selectedUnitStatus}
            setSelectCustomerFormState={setSelectCustomerFormState}
            customers={customers}
            selectedCustomer={selectedCustomer}
            setSelectedCustomer={setSelectedCustomer}
            setPrice={setPrice}
            price={price}
            setIqdUsd={setIqdUsd}
            iqdUSD={iqdUSD}
            setArea={setArea}
            area={area}
            setDeliveryDate={setDeliveryDate}
            deliveryDate={deliveryDate}
            unitStatuses={unitStatuses}
            setSelectedCompoundPhase={setSelectedCompoundPhase}
            selectedCompoundPhase={selectedCompoundPhase}
            compoundPhases={compoundPhases}
            customerPhone={customerPhone}
            setCustomerPhone={setCustomerPhone}
            handleSearch={handleSearch}
          />
        }
      />
    </Box>
  );
}
