import {
  Box,
  TextField,
  Button,
  Autocomplete,
  FormControlLabel,
  Checkbox,
  Typography,
} from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import ObjectOfErrors, {
  checkErrors,
  resetErrors,
  resetModelObject,
} from "../../../Global/Errors/Orders/Orders";
import CloseIcon from "@mui/icons-material/Close";
import { toast } from "react-toastify";
import * as RiIcons from "react-icons/ri";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker";
import Preloading from "../../../Global/Preload/Preloading";
import customerAPI from "../../../Global/Network/Customer";
import paymentTypeAPI from "../../../Global/Network/PaymentType";
import contractTemplateAPI from "../../../Global/Network/ContractTemplates";
import unitAPI from "../../../Global/Network/Unit/index";
import discountAPI from "../../../Global/Network/Discount/index";
import helpers from "../../../assets/js/helper";
import orderAPI from "../../../Global/Network/Orders";
import EmployeeAPI from "../../../Global/Network/Employee";
import CustomeSelectField from "../../../General/CustomComponents/CustomeSelectField";

import compoundAPI from "../../../Global/Network/Compound";
import blockAPI from "../../../Global/Network/Block";
import buildingAPI from "../../../Global/Network/Building";
import floorAPI from "../../../Global/Network/Floor";
import generalAPI from "../../../Global/Network/General";
import debounce from "debounce";

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);
  const handleInputChange = (number, value, errName) => {
    if (number == 1) {
      props.setSelectedPaymentType(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 2) {
      props?.setSelectedDiscounts(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setSelectedUnit(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setSelectedContractTemplate(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setSelectedCustomer(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setSelectedEmployee(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setOrderedDate(value);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    }
  };
  const clearInput = (number, errName) => {
    if (number == 1) {
      props.setSelectedPaymentType(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 2) {
      props.setSelectedDiscounts(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 3) {
      props.setSelectedUnit(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 4) {
      props.setSelectedContractTemplate(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 5) {
      props.setSelectedCustomer(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 6) {
      props.setSelectedEmployee(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    } else if (number == 7) {
      props.setOrderedDate(null);
      setErrorsObject(resetModelObject(ErrorsObject, errName));
    }
  };

  useEffect(() => {
    if (props?.selectedDiscounts?.length > 0) {
      props?.calculateDiscountedPrice();
    } else {
      props?.setCalculatedDiscountedPrice(null);
    }
  }, [props?.selectedDiscounts]);

  const handleCheckDateIsBetweenRange = (data) => {
    let check = false;
    if (data && data?.active_from && data?.active_to) {
      let actvieFrom = new Date(helpers.formatDate(data?.active_from));
      let activeTo = new Date(helpers.formatDate(data?.active_to));
      let dateNow = new Date();
      if (dateNow >= actvieFrom && dateNow <= activeTo) {
        check = true;
      }
    }
    return check;
  };

  return (
    <Box className="Container-fluid">
      <Box className="row">
        <Box className="col-12 col-sm-12 col-md-4 ">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.payment_type_id?.error}
            haswidth={true}
            // isForm={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
            >
              <Autocomplete
                // disablePortal

                options={props?.paymentTypes ? props?.paymentTypes : []}
                getOptionLabel={(option) => option?.name || ""}
                value={props?.selectedPaymentType}
                onChange={(e, newValue) =>
                  handleInputChange(1, newValue, "payment_type_id")
                }
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(1, "payment_type_id")}
                  />
                }
                autoComplete
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.payment_type")}
                    className="errors"
                    variant="filled"
                    focused
                  />
                )}
                className={`${ErrorsObject?.payment_type_id?.error}`}
              />
            </Box>
            {ErrorsObject?.payment_type_id?.error &&
            ErrorsObject?.payment_type_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.payment_type_id?.message &&
                ErrorsObject?.payment_type_id?.message?.length > 0
                  ? ErrorsObject?.payment_type_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfInputFields
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.ordered_date?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "5px 0",
                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
              className="mt-3 orderdata"
            >
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DesktopDatePicker
                  label={t("ORDERS.ordered_date")}
                  inputFormat="MM/DD/YYYY"
                  value={props?.orderedDate}
                  // minDate={new Date()}
                  inputProps={{
                    placeholder: "لم يتم التحديد",
                  }}
                  onChange={(date) =>
                    handleInputChange(7, date, "ordered_date")
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant="filled"
                      focused={props?.orderedDate ? true : false}
                      className={`${
                        ErrorsObject?.ordered_date?.error ? "errors" : ""
                      }`}
                      inputProps={{
                        ...params.inputProps,
                        placeholder: "لم يتم التحديد",
                      }}
                    />
                  )}
                />
                {props?.orderedDate ? (
                  <CloseIcon
                    className="closeIcon"
                    onClick={() => clearInput(7, "ordered_date")}
                    sx={{
                      right: "37px !important",
                      top: "14px !important",
                    }}
                  />
                ) : null}
              </LocalizationProvider>
            </Box>
            {ErrorsObject?.ordered_date?.error &&
            ErrorsObject?.ordered_date?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.ordered_date?.message &&
                ErrorsObject?.ordered_date?.message?.length > 0
                  ? ErrorsObject?.ordered_date?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfInputFields>
          <Box sx={{ margin: "6px 0" }}></Box>
          <CustomeSelectField
            label={t("UNITS.compound")}
            haswidth={true}
            value={props?.selectedCompound}
            list={props?.compounds ? props?.compounds : []}
            error={false}
            message={""}
            readOnly={props?.compoundRead}
            onChange={(e, newValue) => {
              props?.setSelectedCompound(newValue);
            }}
            onClearClick={() => {
              props?.setSelectedCompound(null);
              props?.setSelectedBlock(null);
              props?.setSelectedBuilding(null);
              props?.setSelectedFloor(null);
              props?.setSelectedUnit(null);
            }}
          />
          <Box sx={{ margin: "10px 0" }}></Box>

          <CustomeSelectField
            label={t("UNITS.add_floor")}
            haswidth={true}
            value={props?.selectedFloor}
            list={props?.floors ? props?.floors : []}
            error={ErrorsObject?.floor_id?.error}
            message={ErrorsObject?.floor_id?.message}
            readOnly={props?.floorRead}
            onChange={(e, newValue) => {
              props?.setSelectedFloor(newValue);
            }}
            onClearClick={() => {
              props?.setSelectedFloor(null);
              props?.setSelectedUnit(null);
            }}
            onChangeText={(e) => {
              props.handleSearch({ search_text: e.target.value }, "floors");
            }}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-4">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.contract_template_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
              className="mt-4"
            >
              <Autocomplete
                // disablePortal

                options={
                  props?.contractTemplates ? props?.contractTemplates : []
                }
                value={props?.selectedContractTemplate}
                onChange={(e, newValue) =>
                  handleInputChange(4, newValue, "contract_template_id")
                }
                getOptionLabel={(option) => option?.name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(4, "contract_template_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.contract_template")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.contract_template_id?.error}`}
              />
            </Box>
            {ErrorsObject?.contract_template_id?.error &&
            ErrorsObject?.contract_template_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.contract_template_id?.message &&
                ErrorsObject?.contract_template_id?.message?.length > 0
                  ? ErrorsObject?.contract_template_id?.message?.map(
                      (messg) => (
                        <span
                          style={{
                            fontFamily: "Cairo-Bold",
                            fontSize: "14px",
                            color: "red",
                            height: "auto",
                          }}
                        >
                          {messg}
                        </span>
                      )
                    )
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.employee_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",

                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
            >
              <Autocomplete
                // disablePortal
                options={props?.employees ? props?.employees : []}
                value={props?.selectedEmployee}
                onChange={(e, newValue) =>
                  handleInputChange(6, newValue, "employee_id")
                }
                getOptionLabel={(option) => option?.user?.full_name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(6, "employee_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.employees")}
                    className="errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.employee_id?.error}`}
              />
            </Box>
            {ErrorsObject?.employee_id?.error &&
            ErrorsObject?.employee_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.employee_id?.message &&
                ErrorsObject?.employee_id?.message?.length > 0
                  ? ErrorsObject?.employee_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <Box sx={{ margin: "10px 0" }}></Box>
          <CustomeSelectField
            label={t("UNITS.block")}
            haswidth={true}
            value={props?.selectedBlock}
            list={props?.blocks ? props?.blocks : []}
            error={false}
            message={""}
            readOnly={props?.blockRead}
            onChange={(e, newValue) => {
              props?.setSelectedBlock(newValue);
            }}
            onClearClick={() => {
              props?.setSelectedBlock(null);
              props?.setSelectedBuilding(null);
              props?.setSelectedFloor(null);
              props?.setSelectedUnit(null);
            }}
            onChangeText={(e) => {
              props.handleSearch({ search_text: e.target.value }, "blocks");
            }}
          />

          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.unit_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
              className="mt-4"
            >
              <Autocomplete
                // disablePortal

                options={props?.units ? props?.units : []}
                value={props?.selectedUnit}
                getOptionLabel={(option) => option?.name || ""}
                readOnly={props?.unitReadOnly}
                onChange={(e, newValue) =>
                  handleInputChange(3, newValue, "unit_id")
                }
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "unit_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.unit")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.unit_id?.error}`}
              />
            </Box>
            {ErrorsObject?.unit_id?.error &&
            ErrorsObject?.unit_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.unit_id?.message &&
                ErrorsObject?.unit_id?.message?.length > 0
                  ? ErrorsObject?.unit_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
        </Box>
        <Box className="col-12 col-sm-12 col-md-4">
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.discounts?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",
                // margin: "10px 0",
                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
              className="mt-4"
            >
              <Autocomplete
                // disablePortal

                multiple={true}
                options={
                  props?.discounts && props?.discounts?.length > 0
                    ? props?.discounts
                    : []
                }
                value={
                  props?.selectedDiscounts?.length > 0
                    ? props?.selectedDiscounts
                    : []
                }
                getOptionLabel={(option) => option?.name || ""}
                getOptionDisabled={(option) =>
                  !handleCheckDateIsBetweenRange(option)
                }
                onChange={(e, newValue) => {
                  console.log("discounts", newValue);
                  handleInputChange(2, newValue, "discounts");
                }}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(3, "unit_id")}
                  />
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.discounts")}
                    className=" errors"
                    variant="filled"
                  />
                )}
                className={`${ErrorsObject?.discounts?.error}`}
              />
            </Box>
            {ErrorsObject?.discounts?.error &&
            ErrorsObject?.discounts?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.discounts?.message &&
                ErrorsObject?.discounts?.message?.length > 0
                  ? ErrorsObject?.discounts?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>
          <ContainerOfSelectField
            mainTheme={maintheme}
            customeWidth={"100%"}
            hasError={ErrorsObject?.customer_id?.error}
            haswidth={true}
          >
            <Box
              sx={{
                position: "relative",

                width: "100%",
                backgroundColor: "#fff",
                marginTop: "10px !important",
              }}
            >
              <Autocomplete
                // disablePortal

                options={props?.customers ? props?.customers : []}
                value={props?.selectedCustomer}
                onChange={(e, newValue) =>
                  handleInputChange(5, newValue, "customer_id")
                }
                getOptionLabel={(option) => option?.user?.full_name || ""}
                popupIcon={<RiIcons.RiArrowDropDownLine />}
                clearIcon={
                  <CloseIcon
                    sx={{ fontSize: "20px", color: maintheme?.iconColor }}
                    onClick={() => clearInput(5, "customer_id")}
                  />
                }
                clearOnBlur={false}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t("ORDERS.customers")}
                    className="errors"
                    variant="filled"
                    onChange={(e) => {
                      props.handleSearch(
                        { search_text: e.target.value },
                        "customers"
                      );
                    }}
                  />
                )}
                readOnly={props?.customerReadOnly}
                className={`${ErrorsObject?.customer_id?.error}`}
              />
            </Box>
            {ErrorsObject?.customer_id?.error &&
            ErrorsObject?.customer_id?.message?.length ? (
              <Box
                sx={{
                  height: "fit-content",
                  padding: "5px",
                  display: "flex",
                  flexDirection: "column",
                  flexWrap: "wrap",
                  width: "95%",
                }}
              >
                {ErrorsObject?.customer_id?.message &&
                ErrorsObject?.customer_id?.message?.length > 0
                  ? ErrorsObject?.customer_id?.message?.map((messg) => (
                      <span
                        style={{
                          fontFamily: "Cairo-Bold",
                          fontSize: "14px",
                          color: "red",
                          height: "auto",
                        }}
                      >
                        {messg}
                      </span>
                    ))
                  : null}
              </Box>
            ) : null}
          </ContainerOfSelectField>

          <Box sx={{ margin: "10px 0" }}></Box>
          <CustomeSelectField
            label={t("UNITS.building")}
            haswidth={true}
            value={props?.selectedBuilding}
            list={props?.buildings ? props?.buildings : []}
            error={false}
            message={""}
            readOnly={props?.buildingRead}
            onChange={(e, newValue) => {
              props?.setSelectedBuilding(newValue);
            }}
            onClearClick={() => {
              props?.setSelectedBuilding(null);
              props?.setSelectedFloor(null);
              props?.setSelectedUnit(null);
            }}
            onChangeText={(e) => {
              props.handleSearch({ search_text: e.target.value }, "buildings");
            }}
          />
        </Box>
        <Box className="col-md-12 col-12 col-lg-12 mt-2">
          <Box
            sx={{
              display: "flex",
              paddingLeft: "10px",
            }}
          >
            {props?.selectedUnit ? (
              <>
                <Typography
                  sx={{
                    fontFamily: "Cairo-Bold",
                    marginTop: "10px",
                    display: "flex",
                    // flex: 1,
                    width: "250px",
                  }}
                >
                  {t("ORDERS.total_price") + " : "}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cairo-Bold",
                    marginTop: "10px",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  {(props?.selectedUnit?.iqd_usd == 1 ? "USD" : "IQD") +
                    " " +
                    props?.selectedUnit?.price}
                </Typography>
              </>
            ) : null}
            {/* {props?.selectedDiscounts?.length > 0 ?
                            <>

                                <Button variant="outlined"
                                    // spacing={2}
                                    sx={{
                                        width: '50% !important',
                                        // flexGrow: 1, 
                                        minWidth: '180px !important',

                                        margin: 1,
                                        backgroundColor: '#f7f7f7',
                                        borderColor: gridtheme?.colorWhite,
                                        color: gridtheme?.colorblack,
                                        boxShadow: '0 0 7px -2px white',
                                        //   color:SearchButtonTheme?.clear_button_text_color,
                                        '&:hover': {
                                            backgroundColor: '#f7f7f7',
                                            borderColor: gridtheme?.colorWhite,
                                            color: gridtheme?.colorblack,
                                            boxShadow: '0 0 7px 1px white',
                                            boxShadow: '0',


                                        },
                                        height: '35px',
                                        fontFamily: 'Cairo-Bold'

                                    }}
                                    className="iconeFilterClear"
                                    color="secondary"
                                    onClick={() => {
                                        props.calculateDiscountedPrice()
                                    }}
                                >
                                    {t('ORDERS.calculate_after_discount')}
                                </Button>
                            </> : null} */}
          </Box>
        </Box>
        <Box className="col-md-12 col-12 col-lg-12">
          <Box
            sx={{
              display: "flex",
              paddingLeft: "10px",
              alignItems: "center",
              justifyContent: "flex-start",
            }}
          >
            {props?.calculatedDiscountedPrice != null ? (
              <>
                <Typography
                  sx={{
                    fontFamily: "Cairo-Bold",
                    marginTop: "10px",
                    display: "flex",
                    // flex: 1,
                    width: "250px",
                  }}
                >
                  {t("ORDERS.discounted_price") + " : "}
                </Typography>
                <Typography
                  sx={{
                    fontFamily: "Cairo-Bold",
                    marginTop: "10px",
                    display: "flex",
                    flex: 1,
                  }}
                >
                  {(props?.selectedUnit?.iqd_usd == 1 ? "USD" : "IQD") +
                    " " +
                    props?.calculatedDiscountedPrice}
                </Typography>
              </>
            ) : null}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddFormDailog(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);

  const [customers, setCustomers] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [paymentTypes, setPaymentTypes] = useState([]);
  const [selectedPaymentType, setSelectedPaymentType] = useState(null);
  const [contractTemplates, setContractTemplates] = useState([]);
  const [selectedContractTemplate, setSelectedContractTemplate] =
    useState(null);
  const [units, setUnits] = useState([]);
  const [discounts, setDiscounts] = useState([]);
  const [selectedUnit, setSelectedUnit] = useState(null);
  const [selectedDiscounts, setSelectedDiscounts] = useState([]);
  const [orderedDate, setOrderedDate] = useState(null);
  const [calculatedDiscountedPrice, setCalculatedDiscountedPrice] =
    useState(null);
  const [reply, setReply] = useState("");
  const [customerReadOnly, setCustomerReadOnly] = useState(false);
  const [unitReadOnly, setUnitREadOnly] = useState(false);

  const [compounds, setCompounds] = useState([]);
  const [blocks, setBlocks] = useState([]);
  const [buildings, setBuildings] = useState([]);
  const [floors, setFloors] = useState();

  const [selectedCompound, setSelectedCompound] = useState(null);
  const [selectedBlock, setSelectedBlock] = useState(null);
  const [selectedBuilding, setSelectedBuilding] = useState(null);
  const [selectedFloor, setSelectedFloor] = useState(null);

  const [compoundRead, setCompountRead] = useState(false);
  const [blockRead, setBlockRead] = useState(false);
  const [buildingRead, setBuildingRead] = useState(false);
  const [floorRead, setFloorRead] = useState(false);
  const [firstOpen, setFirstOpen] = useState(false);

  const controller = new AbortController();

  useEffect(() => {
    if (props?.object && props?.object?.id) {
      setFirstOpen(true);
      let dumData = resetErrors(ErrorsObject);
      setSelectedPaymentType(props?.object?.payment_type);
      setSelectedContractTemplate(props?.object?.contract_template);
      setSelectedUnit(props?.object?.unit);
      setSelectedDiscounts(props?.object?.discounts);
      setSelectedCustomer(props?.object?.customer);
      setSelectedEmployee(props?.object?.employee);
      setOrderedDate(props?.object?.ordered_date);
      setCalculatedDiscountedPrice(null);
      setSelectedCompound(props?.object?.compound);
      setSelectedBlock(props?.object?.block);
      setSelectedBuilding(props?.object?.building);
      setSelectedFloor(props?.object?.unit?.floor);
    } else {
      let dumData = resetErrors(ErrorsObject);
      clearForm();
    }
  }, [props?.object]);

  useEffect(() => {
    let abortController = new AbortController();
    setLoading(true);

    // const p1 = new Promise((resolve, reject) => {
    //     loadDataOfPaymentType().then(() => resolve("loadDataOfPaymentType"));
    // });
    // const p2 = new Promise((resolve, reject) => {
    //     loadDataOfContractTemplate().then(() => resolve("loadDataOfContractTemplate"));
    // });
    const p3 = new Promise((resolve, reject) => {
      loadDataOfUnit().then(() => resolve("loadDataOfUnit"));
    });
    // const p4 = new Promise((resolve, reject) => {
    //     if (!props?.unit || !props?.unit?.id)
    //         loadDataOfDiscount().then(() => resolve("loadDataOfDiscount"));
    // });
    // const p5 = new Promise((resolve, reject) => {
    //     loadDataOfCustomer().then(() => resolve("loadDataOfCustomer"));
    // });
    // const p6 = new Promise((resolve, reject) => {
    //     loadDataOfEmployee().then(() => resolve("loadDataOfEmployee"));
    // });
    const p7 = new Promise((resolve, reject) => {
      loadMasterData().then(() => resolve("loadMasterData"));
    });
    Promise.all([
      // p1,
      // p2,
      p3,
      // p4,
      // p5,
      // p6,
      p7,
    ])
      .then((values) => {
        setLoading(false);
      })
      .catch((error) => {
        console.error(error.message);
      });

    // loadDataOfPaymentType()
    // loadDataOfContractTemplate()
    // loadDataOfUnit()
    // loadDataOfDiscount()
    // loadDataOfCustomer()
    // setLoading(false)

    return () => {
      abortController.abort();
    };
  }, []);
  const loadMasterData = async () => {
    let data = null;
    try {
      setLoading(true);
      let masters = [
        {
          type: "payment_types",
        },
        {
          type: "employees",
        },
        {
          type: "customers",
        },
        {
          type: "contract_templates",
        },
        {
          type: "discounts",
        },

        {
          type: "compounds",
        },
      ];

      data = await generalAPI.getMasters({
        params: {
          masters: masters,
        },
      });
      if (data && data?.status) {
        setPaymentTypes(
          Array.isArray(data?.data?.payment_types)
            ? data?.data?.payment_types
            : []
        );
        setEmployees(
          Array.isArray(data?.data?.employees) ? data?.data?.employees : []
        );
        setCustomers(
          Array.isArray(data?.data?.customers) ? data?.data?.customers : []
        );
        setContractTemplates(
          Array.isArray(data?.data?.contract_templates)
            ? data?.data?.contract_templates
            : []
        );
        if (!props?.unit || !props?.unit?.id)
          setDiscounts(
            Array.isArray(data?.data?.discounts) ? data?.data?.discounts : []
          );
        setCompounds(
          Array.isArray(data?.data?.compounds) ? data?.data?.compounds : []
        );
      } else {
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
    } catch (err) {
      console.log(err?.message);
    }
    setLoading(false);
  };
  // useEffect(() => {
  //     if (!props?.object) {
  //         clearForm()
  //         loadDataOfDiscount()
  //     }
  //     loadCompounds()
  // }, [props?.addForm, props?.object])

  useEffect(() => {
    loadBlocks({});

    return () => {};
  }, [selectedCompound]);

  useEffect(() => {
    loadBuildings({});

    return () => {};
  }, [selectedBlock]);

  useEffect(() => {
    loadFloors({});
    return () => {};
  }, [selectedBuilding]);

  useEffect(() => {
    loadDataOfUnit();

    return () => {};
  }, [selectedCompound, selectedBlock, selectedBuilding, selectedFloor]);

  const handleSearch = debounce((value, name) => {
    if (name == "blocks") loadBlocks(value);
    else if (name == "buildings") loadBuildings(value);
    else if (name == "floors") loadFloors(value);
    // else if (name == "unitTypes") loadDataOfUnitTypes(value);
    else if (name == "customers") loadCustomers(value);
  }, 500);

  const loadCompounds = async () => {
    if (props?.unit?.compound) {
      return;
    }
    setLoading(true);

    try {
      const result = await compoundAPI.compoundsList({
        params: {},
        signal: controller?.signal,
      });
      if (result.status) {
        setCompounds(result?.data);
        if (result?.data?.length == 1) {
          setSelectedCompound(result?.data[0]);
        }
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadBlocks = async (searchParams) => {
    if (props?.unit?.block) {
      return;
    }

    if (!selectedCompound || !selectedCompound?.id) {
      setFloors([]);
      setBuildings([]);
      setBlocks([]);
      return;
    }
    setLoading(true);
    setBlocks([]);

    try {
      const result = await blockAPI.blocks({
        params: {
          page_size: 100,
          page: 0,
          compound_id: selectedCompound?.id,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setBlocks(result?.data?.data);
        if (result?.data?.length == 1) {
          setSelectedBlock(result?.data[0]);
        }
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadBuildings = async (searchParams) => {
    if (props?.unit?.building) {
      return;
    }
    if (!selectedBlock || !selectedBlock?.id) {
      setBuildings([]);
      setFloors([]);
      return;
    }
    setLoading(true);
    try {
      const result = await buildingAPI.buildings({
        params: {
          page_size: 100,
          page: 0,
          block_id: selectedBlock?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setBuildings(result?.data?.data);
        if (result?.data?.length == 1) {
          setSelectedBuilding(result?.data[0]);
        }
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadFloors = async (searchParams) => {
    if (props?.unit?.floor) {
      return;
    }
    if (!selectedBuilding || !selectedBuilding?.id) {
      setFloors([]);
      return;
    }
    setLoading(true);
    try {
      const result = await floorAPI.floors({
        params: {
          page_size: 50,
          page: 0,
          building_id: selectedBuilding?.id,
          name: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setFloors(result?.data?.data);
        if (result?.data?.length == 1) {
          setSelectedFloor(result?.data[0]);
        }
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };
  const loadCustomers = async (searchParams) => {
    setLoading(true);
    setCustomers([]);
    try {
      const result = await customerAPI.customers({
        params: {
          page_size: 100,
          page: 0,
          search_text: searchParams?.search_text ? searchParams?.search_text : "",
        },
        signal: controller?.signal,
      });
      if (result.status) {
        setCustomers(result?.data?.data);
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
    setLoading(false);
  };

  const calculateDiscountedPrice = async () => {
    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);

      let params = {
        discount_ids:
          selectedDiscounts?.length > 0
            ? selectedDiscounts?.map((discount) => discount?.id)
            : [],
        unit_id: selectedUnit?.id,
        ordered_date: orderedDate ? helpers.formatDate(orderedDate) : null,
      };
      let result = null;

      console.log("susu", params)

      result = await orderAPI.calculateDiscountedPrice({
        params: params,
      });

      if (result?.status) {
        console.log("result?.data?.data", result?.data?.data);
        setCalculatedDiscountedPrice(result?.data?.data);
        setLoading(false);
        // if(!firstOpen)
        toast.success(t("NETWORKMESSAGE.calculatePriceSuccess"));
        // setFirstOpen(false)
      } else {
        if (typeof result.errorMessage === "object") {
          toast.error(t("NETWORKMESSAGE.messageError"));
        } else {
          toast.error(result?.errorMessage);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };
  const clearForm = () => {
    setSelectedPaymentType(null);
    setSelectedContractTemplate(null);
    setSelectedUnit(null);
    setSelectedDiscounts(null);
    setSelectedCustomer(null);
    setSelectedEmployee(null);
    setOrderedDate(null);
    setCalculatedDiscountedPrice(null);
    setSelectedBlock(null);
    setSelectedBuilding(null);
    setSelectedCompound(null);
    setSelectedFloor(null);
  };

  const submit = async () => {
    try {
      setLoading(true);
      let dumData = resetErrors(ErrorsObject);

      let data = {
        payment_type_id:
          selectedPaymentType && selectedPaymentType?.id
            ? selectedPaymentType?.id
            : null,
        contract_template_id:
          selectedContractTemplate && selectedContractTemplate?.id
            ? selectedContractTemplate?.id
            : null,
        unit_id: selectedUnit && selectedUnit?.id ? selectedUnit?.id : null,
        ordered_date: orderedDate ? helpers.formatDate(orderedDate) : null,
        customer_id:
          selectedCustomer && selectedCustomer?.id
            ? selectedCustomer?.id
            : null,
        employee_id:
          selectedEmployee && selectedEmployee?.id
            ? selectedEmployee?.id
            : null,
        ...(selectedDiscounts?.length > 0 && {
          discount_ids: selectedDiscounts?.map((item) => item?.id),
        }),
      };
      let result = null;
      if (props?.object && props?.object?.id) {
        data = {
          ...data,
          id: props?.object?.id,
          _method: "put",
          is_normal_update: 1,
        };
        result = await orderAPI.updateOrder({
          data: data,
        });
      } else {
        result = await orderAPI.Addorder({
          data: data,
        });
      }

      if (result?.status) {
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        clearForm();
        props.loadData();
        props.setAddForm(false);
      } else {
        if (typeof result.errorMessage === "object") {
          let updatedObject = resetErrors(ErrorsObject);
          setErrorsObject(checkErrors(result.errorMessage, updatedObject));
          toast.error(t("NETWORKMESSAGE.messageError"));
        } else {
          toast.error(result?.errorMessage);
        }
        setLoading(false);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const loadDataOfCustomer = async (searchParams) => {
    setLoading(true);

    const result = await customerAPI.customersList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setCustomers(result?.data);
      if (props?.customer) {
        setSelectedCustomer(props?.customer);
        setCustomerReadOnly(true);
      }
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfEmployee = async (searchParams) => {
    setLoading(true);

    const result = await EmployeeAPI.EmployeeList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setEmployees(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfPaymentType = async (searchParams) => {
    setLoading(true);

    const result = await paymentTypeAPI.PaymentTypeList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setPaymentTypes(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfContractTemplate = async (searchParams) => {
    setLoading(true);

    const result = await contractTemplateAPI.ContractTemplateList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setContractTemplates(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfUnit = async (searchParams) => {
    setLoading(true);

    const result = await unitAPI.unitsList({
      params: {
        ...searchParams,
        ...(selectedCompound?.id
          ? {
              compound_id: selectedCompound?.id,
            }
          : {}),
        ...(selectedBlock?.id
          ? {
              block_id: selectedBlock?.id,
            }
          : {}),
        ...(selectedBuilding?.id
          ? {
              building_id: selectedBuilding?.id,
            }
          : {}),
        ...(selectedFloor?.id
          ? {
              floor_id: selectedFloor?.id,
            }
          : {}),
      },
      signal: controller?.signal,
    });

    if (result.status) {
      //here will handle hide any sold unit from show in add order.
      let arr = [];
      result?.data &&
        result?.data?.length &&
        result?.data?.map((itm) => {
          if (itm?.unit_status_id == 1 || itm?.unit_status_id == 4) {
            arr.push(itm);
          }
        });

      setUnits(arr);
      if (result?.data?.length == 1) {
        setSelectedUnit(arr[0]);
      }
      if (props?.unit) {
        setSelectedUnit(props?.unit);
        setUnitREadOnly(true);
      }
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const loadDataOfDiscount = async (searchParams) => {
    setLoading(true);

    const result = await discountAPI.discountsList({
      params: {
        ...searchParams,
      },
      signal: controller?.signal,
    });

    if (result.status) {
      setDiscounts(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };

  useEffect(() => {
    if (props?.unit) {
      console.log("unitData", props?.unit);
      if (props?.unit?.block) {
        setSelectedBlock(props?.unit?.block);
        setBlockRead(true);
      }
      if (props?.unit?.compound) {
        setSelectedCompound(props?.unit?.compound);
        setCompountRead(true);
      }
      if (props?.unit?.building) {
        setSelectedBuilding(props?.unit?.building);
        setBuildingRead(true);
      }
      if (props?.unit?.floor) {
        setSelectedFloor(props?.unit?.floor);
        setFloorRead(true);
      }
    }
  }, [props?.unit]);
  useEffect(() => {
    if (selectedUnit && selectedUnit?.id) {
      if (selectedDiscounts && selectedDiscounts?.length > 0) {
        let arr = [];
        selectedDiscounts?.map((itm) => {
          if (selectedUnit?.iqd_usd == itm.iqd_usd) {
            arr.push(itm);
          }
        });
        setSelectedDiscounts(arr);
      }
      loadDataOfDiscount({
        iqd_usd: selectedUnit?.iqd_usd,
      });
    }
  }, [selectedUnit]);

  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      customeWidth={"80% !important"}
      title={props?.object ? t("ORDERS.updateTitle") : t("ORDERS.addTitle")}
      content={
        // loading ?
        // <Preloading /> :
        <RenderContent
          orderedDate={orderedDate}
          setOrderedDate={setOrderedDate}
          calculatedDiscountedPrice={calculatedDiscountedPrice}
          setCalculatedDiscountedPrice={setCalculatedDiscountedPrice}
          paymentTypes={paymentTypes}
          selectedPaymentType={selectedPaymentType}
          setSelectedPaymentType={setSelectedPaymentType}
          customers={customers}
          selectedCustomer={selectedCustomer}
          setSelectedCustomer={setSelectedCustomer}
          customerReadOnly={customerReadOnly}
          compounds={compounds}
          selectedCompound={selectedCompound}
          setSelectedCompound={setSelectedCompound}
          blocks={blocks}
          selectedBlock={selectedBlock}
          setSelectedBlock={setSelectedBlock}
          buildings={buildings}
          selectedBuilding={selectedBuilding}
          setSelectedBuilding={setSelectedBuilding}
          floors={floors}
          selectedFloor={selectedFloor}
          setSelectedFloor={setSelectedFloor}
          employees={employees}
          selectedEmployee={selectedEmployee}
          setSelectedEmployee={setSelectedEmployee}
          contractTemplates={contractTemplates}
          selectedContractTemplate={selectedContractTemplate}
          setSelectedContractTemplate={setSelectedContractTemplate}
          units={units}
          selectedUnit={selectedUnit}
          setSelectedUnit={setSelectedUnit}
          unitReadOnly={unitReadOnly}
          discounts={discounts}
          selectedDiscounts={selectedDiscounts}
          setSelectedDiscounts={setSelectedDiscounts}
          floorRead={floorRead}
          buildingRead={buildingRead}
          blockRead={blockRead}
          compoundRead={compoundRead}
          open={props.addForm}
          setOpen={props.setAddForm}
          setReply={setReply}
          reply={reply}
          calculateDiscountedPrice={calculateDiscountedPrice}
          handleSearch={handleSearch}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
