import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import WorkTypeError, { checkErrors, customerObjectMerge, resetErrors, restCustomeObject } from '../../../Global/Errors/WorkType/WorkType'
import CloseIcon from '@mui/icons-material/Close';
import WorkTypeAPI from '../../../Global/Network/WorkType'
import { toast } from 'react-toastify'


const RenderContent = (props) => {
    const [t] = useTranslation('common')
    const maintheme = useSelector(state => state.themeData.maintheme);
    const [workTypeErrorsObject, setWorkTypeErrorsObject] = useState(WorkTypeError)
    const handleChange = (e) => {
        props.setName(e.target.value)
        setWorkTypeErrorsObject(restCustomeObject(workTypeErrorsObject, 'name'))
    }
    const clearInput = () => {
        props.setName('')
        setWorkTypeErrorsObject(restCustomeObject(workTypeErrorsObject, 'name'))

    }

    return (
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                    <ContainerOfInputFields
                        mainTheme={maintheme}
                        customeWidth={"100%"}
                        isForm={true}

                    >
                        <Box
                            sx={{
                                position: "relative",
                                // margin: "5px 0",
                                width: "100%",
                                backgroundColor: "#fff",
                            }}
                            className="mt-3 orderdata"
                        >
                            <TextField
                                
                                variant="filled"
                                label={t("WORKTYPE.add_name")}
                                value={props?.name}
                                onChange={(e) => handleChange(e)}
                                type={'text'}
                                focused
                                className={`${workTypeErrorsObject?.name?.error ? 'errors' : ''}`}
                            />
                            {props?.name ? (
                                <CloseIcon
                                    className="closeIcon"
                                    onClick={() => clearInput()}
                                />
                            ) : null}
                        </Box>
                        {
                            workTypeErrorsObject?.name?.error && workTypeErrorsObject?.name?.message?.length ?
                                <Box sx={{
                                    height: 'fit-content',
                                    padding: '5px',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    flexWrap: 'wrap',
                                    width: '95%',
                                }}>
                                    {
                                        workTypeErrorsObject?.name?.message && workTypeErrorsObject?.name?.message?.length > 0 ? workTypeErrorsObject?.name?.message?.map((messg) => (
                                            <span style={{ fontFamily: 'Cairo-Bold', fontSize: '14px', color: 'red', height: 'auto' }}>{messg}</span>
                                        )) : null
                                    }
                                </Box> : null}
                    </ContainerOfInputFields>
                </Box>
                <Box className="col-12 col-sm-12 col-md-6"></Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddWorkType(props) {
    const [t] = useTranslation('common');
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [workTypeErrorsObject, setWorkTypeErrorsObject] = useState(WorkTypeError)


    useEffect(() => {
        if (props?.workType && props?.workType?.id) {
            let dumData = resetErrors(workTypeErrorsObject)
            setName(props?.workType?.name)
        } else {
            let dumData = resetErrors(workTypeErrorsObject)
            clearForm()
        }
    }, [props?.workType])

    const submit = async () => {
        try {
            setLoading(true)
            let dumData = resetErrors(workTypeErrorsObject)
            let data = {
                name: name,
            }
            let result = null;
            if (props?.workType && props?.workType?.id) {
                data = {
                    name: name,
                    id: props?.workType?.id,
                    _method: 'put'
                }
                result = await WorkTypeAPI.UpdateWorkType({
                    data: data
                });
            } else {
                result = await WorkTypeAPI.AddWorkType({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(workTypeErrorsObject)
                    setWorkTypeErrorsObject(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setName('')
    }

    useEffect(() => {
        if (!props?.workType)
            clearForm()
    }, [props?.addForm, props?.workType])

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.workType ? t('WORKTYPE.updateTitle') : t('WORKTYPE.addTitle')}
            content={<RenderContent name={name} setName={setName}
                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
