import callAPI from '../ApiConfig'
import store from '../../../app/store'

const notificationTypes = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notification_types",
        method:"get",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const notificationTypesList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notification_types_list",
        method:"get",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addNotificationType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notification_types",
        method:"post",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data,
    })
    
}
const updateNotificationType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notification_types/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
        data:props?.data
    })
    
}
const deleteNotificationType = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"notification_types/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        withOutGLovalLoading:props?.withOutGLovalLoading,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        },
    })
    
}



const notificationTypeAPI={
    notificationTypes:notificationTypes,
    notificationTypesList:notificationTypesList,
    addNotificationType:addNotificationType,
    updateNotificationType:updateNotificationType,
    deleteNotificationType:deleteNotificationType,


}
export default notificationTypeAPI