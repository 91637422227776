import React, { useState, useEffect, useMemo } from "react";
import Grid from "../../Grid/Grid";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import DropDownGrid from "../../Grid/Components/DropDownGrid";
import {
  Box,
  MenuItem,
  ListItemIcon,
  Typography,
  Divider,
  Autocomplete,
  TextField,
  FormControlLabel,
  Checkbox,
  Tooltip,
  IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import ViewWeekIcon from "@mui/icons-material/ViewWeek";
import AddFormDailog from "./AddFormDailog";
import swal from "sweetalert";
import SearchInput from "../../Components/SearchInput";
import SearchDate from "../../Components/SearchDate";
import EmployeeAPI from "../../../Global/Network/Employee/index";
import { from } from "stylis";
import helpers from "../../../assets/js/helper";
import { useGlobalContext } from "../../../context";
import * as MdIcons from "react-icons/md";
import ShoppingBasketIcon from "@mui/icons-material/ShoppingBasket";
import * as FaIcons from "react-icons/fa";
import customerAPI from "../../../Global/Network/Customer";
import notificationsAPI from "../../../Global/Network/Notification/index";
import RolesOfUserAPI from "../../../Global/Network/RoleUser/index";
import notificationTypeAPI from "../../../Global/Network/NotificationType";
import NotificationsActiveIcon from '@mui/icons-material/NotificationsActive';
import NotificationsPausedIcon from '@mui/icons-material/NotificationsPaused';
import { setNotificationsRedux, setTotalUnreadNotificationsCountRedux } from '../../../reduxStore/NotificationReducer';
import { debounce } from 'debounce';

const RenderSearchContent = (props) => {
  const [t] = useTranslation("common");
  console.log("user", props?.users);
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        flexDirection: "column",
        width: "100% !important",
      }}
    >
      <SearchInput
        value={props?.title}
        setValue={props?.setTitle}
        title={t("NOTIFICATIONS.title")}
      />
      <Box
        sx={{
          // margin: "10px 0",
          marginLeft: "25px",
          backgroundColor: "lightgray",
          // boxShadow: "0 0 10px -2px rgba(0,0,0,0.2)",
          padding: "5px 15px",
          width: "100%",
          "& .MuiTypography-root": {
            fontFamily: "Cairo",
          },
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={props?.sendPushNotification}
              onChange={(e) => {
                props?.setSendPushNotification(e?.target?.checked);
              }}
            />
          }
          sx={{
            fontFamily: "Cairo-Regular",
            width: "100%",
          }}
          label={t("NOTIFICATIONS.sendPushNotification")}
        />
      </Box>
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.notificationsType ? props.notificationsType : []}
        getOptionLabel={(option) => option?.name || ""}
        value={props?.selectedNotificationType}
        onChange={(e, v, r) => {
          props.setSelectedNotificationType(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("NOTIFICATIONS.notificationType")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
          />
        )}
      />
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t("NOTIFICATIONS.work_fromDate_search")}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t("NOTIFICATIONS.work_toDate_search")}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5,
          mt: 1,
          mb: 1,
          width: "95%",
          "& .MuiFormLabel-root,& .MuiInputBase-input": {
            fontFamily: "Cairo-Medium",
          },
        }}
        options={props.users ? props.users : []}
        clearOnBlur={false}
        getOptionLabel={(option) =>
          (option?.user && option?.user?.full_name) || ""
        }
        value={props?.createdBy}
        onChange={(e, v, r) => {
          props.setCreatedBy(v ? v : "");
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            placeholder={t("NOTIFICATIONS.SEARCH_USERS")}
            variant="standard"
            sx={{ fontFamily: "Cairo-Medium" }}
            onChange={(e) => {
              props.handleSearch({searchText: e.target.value});
            }}
          />
        )}
      />
    </Box>
  );
};

export default function Notification() {
  const [t] = useTranslation("common");
  const [rows, setRows] = useState([]);
  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [openFilterColumn, setOpenFilterColumn] = useState(false);
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([]);
  const [elementClicked, setElementClicked] = useState(null);
  const [addForm, setAddForm] = useState(false);
  const [selectedObject, setselectedObject] = useState(null);
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [objectName, setobjectName] = useState(null);
  const [createdBy, setCreatedBy] = useState(null);
  const [users, setUsers] = useState([]);
  const { exportToCSV } = useGlobalContext();
  const [filterParams, setFilterParams] = useState({});
  const rolesRedux = useSelector((state) => state.rolesData.roles);
  const directions = useSelector((state) => state.settingsData.directions);
  const dispatch=useDispatch()

  const [selectedNotificationType, setSelectedNotificationType] =
    useState(null);
  const [notificationsType, setNotificationsType] = useState([]);
  const [sendPushNotification, setSendPushNotification] = useState(null);
  const [title, setTitle] = useState("");

  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0);
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const renderGetBody = (body) => {
    if (body) {
      let message = body?.replace(/\s+/g, " ");
      if (message && message?.length > 80) {
        message = message?.slice(0, 80) + " ...";
      }

      return message;
    }
    return "";
  };
  const columns = useMemo(
    () => [
      {
        headerName: t("NOTIFICATIONS.id"),
        field: "id",
        flex: 0.5,
        minWidth: 50,
        maxWidth: 150,
        hide: false,
      },
      {
        headerName: t("NOTIFICATIONS.title"),
        field: "title",
        flex: 2,
        minWidth: 200,
        maxWidth: 350,
        hide: false,
      },
      {
        headerName: t("NOTIFICATIONS.body"),
        field: "body",
        flex: 2,
        minWidth: 350,
        maxWidth: 450,
        hide: false,
        renderCell: (rows) => (
          <Box sx={{ width: "100%" ,textAlign:'left',display:'flex',alignItems:'center',justifyContent:'flex-end',
          "& .MuiTypography-root":{
            textAlign:'justify'
          }
          
          }}>
            <Typography variant="p" component={'p'}>{renderGetBody(rows?.row?.body)}</Typography>
          </Box>
        ),
      },
      {
        headerName: t("NOTIFICATIONS.notification_type_id"),
        field: "notification_type_id",
        flex: 2,
        minWidth: 150,
        maxWidth: 250,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.notification_type?.name}
            </span>
          </Box>
        ),
      },
      {
        headerName: t("NOTIFICATIONS.send_push_notification"),
        field: "send_push_notification",
        flex: 2,
        minWidth:110,
        maxWidth: 110,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {rows?.row?.send_push_notification == 1 ? (
             
              <Tooltip arrow title={ <span
                style={{ fontFamily: "Cairo-Medium" }}
                className="gridHeaderItem"
              >
                {t("NOTIFICATIONS.hasNotify")}
              </span>}>
                    <IconButton sx={{width:40,height:40}}>
                      <NotificationsActiveIcon  style={{color:'orange'}}/>
                    </IconButton>
              </Tooltip>

            ) : (
              <Tooltip arrow title={ <span
                style={{ fontFamily: "Cairo-Medium" }}
                className="gridHeaderItem"
              >
                {t("NOTIFICATIONS.nothasNotify")}
              </span>}>
                    <IconButton sx={{width:40,height:40}}>
                      <NotificationsPausedIcon  style={{color:'gray'}}/>
                    </IconButton>
              </Tooltip>

            )}
          </Box>
        ),
      },

      {
        headerName: t("NOTIFICATIONS.created_at"),
        field: "created_at",
        flex: 2,
        minWidth: 120,
        maxWidth: 120,
        hide: false,
        renderCell: (rows) => (
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.created_at
                ? helpers.formatDate(rows?.row?.created_at)
                : "0000-00-00"}
            </span>
            <span
              style={{ fontFamily: "Cairo-Medium" }}
              className="gridHeaderItem"
            >
              {rows?.row?.created_at
                ? helpers.formatTime(rows?.row?.created_at)
                : "00:00:00"}
            </span>
          </Box>
        ),
      },
      {
        headerName: t("NOTIFICATIONS.actions"),
        field: "Actions",
        flex: 0.4,
        minWidth: 100,
        maxWidth: 250,
        hide: false,
        renderCell: (rows) => (
          <DropDownGrid className="containerOFDropDownToolTipeGrid">
            {rolesRedux?.update_notifications?.value ? (
              <MenuItem
                onClick={() => {
                  updateFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <EditIcon style={{ fill: "#1E6A99" }} />
                </ListItemIcon>
                {t("GLOBAL.action_edit")}
              </MenuItem>
            ) : null}
            {rolesRedux?.delete_notifications?.value ? (
              <MenuItem
                onClick={() => {
                  deleteFunction(rows?.row);
                }}
                className="ActionIcons"
              >
                <ListItemIcon
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "0px 7px 0 0",
                  }}
                >
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t("GLOBAL.action_delete")}
              </MenuItem>
            ) : null}
            {rolesRedux?.update_notifications?.value == false &&
            rolesRedux?.delete_notifications?.value == false ? (
              <p style={{ fontFamily: "Cairo", margin: "5px 20px" }}>
                {t("GLOBAL.emptyPoPup")}
              </p>
            ) : null}
          </DropDownGrid>
        ),
      },
    ],
    [rolesRedux]
  );
  useEffect(() => {
    let abortController = new AbortController();

    loadData();
    return () => {
      abortController.abort();
    };
  }, [pageNumber, pageSize,filterParams]);

  useEffect(() => {
    let abortController = new AbortController();

    console.log("saldkjaskd", 1);
    loadDataOfNotificationType();
    return () => {
      abortController.abort();
    };
  }, []);

  useEffect(() => {
    loadDataOfUsers({});
  }, []);

  const loadData = async (searchParams) => {
    setLoading(true);
    const filterParamsData = {
      ...filterParams,
      ...searchParams,
    };
    const result = await notificationsAPI.notifications({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData,
      },
      signal: controller?.signal,
    });

    setLoading(false);
    if (result.status) {
      setRows(result?.data?.data);
      setRowsTotal(result?.data?.total);
      setPageCount(result?.data?.last_page);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const handleSearch = debounce((value)=>{
    loadDataOfUsers(value);
  }, 500);
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)
  
    const result = await EmployeeAPI.Employee({
      params: {
        page_size: 3,
        page: 0,
        full_name: searchParams.searchText ? searchParams.searchText : "",
      },
      signal: controller?.signal,
    });
    setLoading(false)
    if (result.status) {
      setUsers(result?.data?.data)
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  const createFUnction = () => {
    setselectedObject(null);
    setAddForm(true);
  };
  const updateFunction = (obj) => {
    setselectedObject(obj);
    setAddForm(true);
  };
  const deleteFunction = async (obj) => {
    swal({
      title: "?هل انت متأكد",
      text: "لن تتمكن من التراجع عن هذا!",
      icon: "warning",
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      console.log("1");
      if (willDelete) {
        console.log("2");

        try {
          setLoading(true);

          let result = null;
          if (obj && obj?.id) {
            console.log("3");

            result = await notificationsAPI.deleteNotification({
              data: {
                id: obj.id,
              },
            });
          }

          if (result?.status) {
            setLoading(false);
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t("NETWORKMESSAGE.deleteSuccess")}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber,
            });
          } else {
            setLoading(false);
            if (result?.errorMessage) {
              toast.error(result?.errorMessage);
            } else toast.error(t("NETWORKMESSAGE.deleteErrorr"));
          }
          setLoading(false);
        } catch (error) {
          setLoading(false);
          toast.error(t(error?.message));
        }
      } else {
        // alert('canceled')
      }
    });
  };
  const clearFunction = () => {
    setobjectName("");
    setFromDate(null);
    setToDate(null);
    setCreatedBy(null);
    setFilterParams(null);
    setTitle("");
    setSendPushNotification(null);
    setSelectedNotificationType(null);
  };
  const searchFunction = () => {
    let data = {};
    if (title) {
      data = {
        ...data,
        title: title,
      };
    }
    if (sendPushNotification || sendPushNotification == false) {
      data = {
        ...data,
        send_push_notification: sendPushNotification ? 1 : 0,
      };
    }
    if (selectedNotificationType && selectedNotificationType?.id) {
      data = {
        ...data,
        notification_type_id: selectedNotificationType?.id,
      };
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id,
      };
    }
    setFilterParams(data);
    // loadData(data);
  };
  const loadDataForExport = async (searchParams) => {
    let result = await notificationsAPI.notifications({
      params: {
        page_size: rowsTotal,
        ...searchParams,
      },
      signal: controller?.signal,
    });
    return result;
  };
  const ExportFunction = async () => {
    let filteredData = {};
    if (title) {
      filteredData = {
        ...filteredData,
        title: title,
      };
    }
    if (sendPushNotification || sendPushNotification == false) {
      filteredData = {
        ...filteredData,
        send_push_notification: sendPushNotification ? 1 : 0,
      };
    }
    if (selectedNotificationType && selectedNotificationType?.id) {
      filteredData = {
        ...filteredData,
        notification_type_id: selectedNotificationType?.id,
      };
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate),
      };
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate),
      };
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy.user?.id,
      };
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات");
      return;
    }

    let arr = [];
    fetchedData?.data?.data &&
      fetchedData?.data?.data?.map((itm) => {
        arr.push({
          [`${t("NOTIFICATIONS.id")}`]: itm?.id,
          [`${t("NOTIFICATIONS.title")}`]: itm?.title,
          [`${t("NOTIFICATIONS.body")}`]: itm?.body,
          [`${t("NOTIFICATIONS.send_push_notification")}`]:
            itm?.send_push_notification == 1
              ? "ارسال اشعار"
              : "لم يتم ارسال اشعار",
          [`${t("NOTIFICATIONS.notificationType")}`]:
            itm?.notification_type?.name,
          [`${t("NOTIFICATIONS.created_at")}`]: itm?.created_at
            ? helpers.formatDate(itm?.created_at) +
              " " +
              helpers.formatTime(itm?.created_at)
            : "0000-00-00 00:00:00",
        });
      });
    exportToCSV(arr, "NOTIFICATIONS");
  };

  const loadDataOfNotificationType = async (searchParams) => {
    setLoading(true);

    const result = await notificationTypeAPI.notificationTypesList();

    setLoading(false);
    if (result.status) {
      setNotificationsType(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  useEffect(()=>{
    if(rows&&rows?.length>0){
      let ids=rows?.map(itm=>itm?.id)
      console.log('asdasIDS',ids)
      if(ids&&ids?.length>0)
      setNotificationIdsLoadedAsRead(ids)
    }
  },[rows])
  const setNotificationIdsLoadedAsRead = (notificationIdsLoaded) => {
    notificationsAPI.setMyNotificationAsRead({
        data: {
            notification_ids: notificationIdsLoaded
        },
        withOutGLovalLoading: true,

    }).then(() => {
        notificationsAPI.myUnreadNotificationsCount({
            withOutGLovalLoading: true,

        }).then((result) => {
            console.log("result_notification", result);
            if (result?.status) {
                dispatch(setTotalUnreadNotificationsCountRedux(result?.data?.data?.total_unread))
            }
        })
    })
}

  return (
    <Box
      sx={{
        // padding: '10px',
        // paddingTop: '20px',
        display: "flex",
        flexDirection: "column",
        height: "100%",
        width: "100%",
        justifyContent: "center",
        alignItems: "start",
        overflowX: "hidden",
      }}
      className=" pt-3 mt-1"
    >
      <AddFormDailog
        object={selectedObject}
        addForm={addForm}
        setAddForm={setAddForm}
        loadData={loadData}
      />

      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          width: "100%",
          height: "100%",
          justifyContent: "center",
          alignItems: "stretch",
          position: "relative",
        }}
      >
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            variant="h4"
            sx={{
              marginLeft: "10px",
              marginBottom: "10px",
              fontFamily: "Cairo-Bold",
              fontSize: "16px",
            }}
          >
            {t("NOTIFICATIONS.Pagetitle")}
          </Typography>
        </Box>

        <Box
          sx={{
            marginRight: "20px",
            backgroundColor: "#1E6A99",
            color: "#FFFFFF",
            width: "42px",
            height: "40px",
            borderRadius: "50%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
          }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          aria-controls={openFilterColumn ? "demo-customized-menu" : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? "true" : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser style={{ fontSize: "30px", color: "#fff" }} />
        </Box>
      </Box>

      <Divider
        sx={{
          backgroundColor: "#EBF2F7",
          margin: "30px",
          marginTop: "10px",
          width: "100%",
        }}
        style={{
          height: "3px",
        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_notifications?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_notifications?.value}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={
          <RenderSearchContent
            objectName={objectName}
            setobjectName={setobjectName}
            fromDate={fromDate}
            setFromDate={setFromDate}
            toDate={toDate}
            setToDate={setToDate}
            createdBy={createdBy}
            setCreatedBy={setCreatedBy}
            title={title}
            setTitle={setTitle}
            sendPushNotification={sendPushNotification}
            setSendPushNotification={setSendPushNotification}
            notificationsType={notificationsType}
            selectedNotificationType={selectedNotificationType}
            setSelectedNotificationType={setSelectedNotificationType}
            users={users}
            handleSearch={handleSearch}
          />
        }
      />
    </Box>
  );
}
