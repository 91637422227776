
import {  IconButton, Typography, Box } from '@mui/material';
import React from 'react';
import {ImForward} from 'react-icons/im'




const NotificationCard = ({ created_at, title, body, onClick }) => {
    
    

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'flex-start',
            flexDirection: 'column',

            width: '300px',
            borderBottom:'1px  dashed  lightgray'
        }}>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    width: '100%'

                }}
            >

                <Typography

                    sx={{
                        fontFamily: "Cairo-Medium",
                        fontSize: '14',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    }}
                >
                    {title ? title : ""}
                </Typography>

                <Typography
                    variant="body2"
                    color="textSecondary"
                    sx={{
                        fontFamily: "Cairo"
                    }}
                >
                    <small> {created_at ? created_at : ""}</small>
                </Typography>

            </Box>
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems:"center",
                    width: '100%'

                }}
            >
            <Typography
                 variant="body2"
                 color="textSecondary"
                sx={{
                    
                        fontFamily: "Cairo-Medium",
                        fontSize: '12px',
                        textOverflow: 'ellipsis',
                        overflow: 'hidden',
                        whiteSpace: 'nowrap',
                    
                }}
            >
                {body ? body : "لايوجد اشعارات"}
            </Typography>
            {/* <IconButton onClick={onClick} sx={{width:'40px',height:'40px'}}>
                <ImForward style={{color:"#126A99",fontSize:'20px'}} />
            </IconButton> */}
           
            </Box>

            
        </Box>
    );
};

export default NotificationCard;
