import { Backdrop, Box, CircularProgress } from '@mui/material'
import React from 'react'
import CustomMenu from './CommunicationsTabComponents/CustomMenu'
import MessageCard from './CommunicationsTabComponents/MessageCard'
import Preloading from '../../../Global/Preload/Preloading';
import { useTranslation } from 'react-i18next';



const CommunicationsTab = (props) => {
  const [t]=useTranslation('common')
  return (
    <Box sx={{
      display: 'flex',
      flexDirection: 'column',
      width:'100%',
      paddingLeft: '15px',
      paddingRight: '15px',
      // paddingTop: '15px',
      paddingBottom: '32px',
      position: 'relative',
      // backgroundColor:'rgba(0,0,0,.1)',
      backdropFilter:'blur(15px)',
      borderTopLeftRadius:'10px',
      borderTopRightRadius:'10px',

    }}>

      {
      props?.rows?.length>0?props?.rows?.map(data => <MessageCard 
        {...data} 
        itm={data}
        deleteFunction={props.deleteFunction}
        updateFunction={props.updateFunction}
        submitNeedFollowUp={props.submitNeedFollowUp}
        {...props}
        />):
      <Box sx={{width:'100%',height:'100%',display:'flex',justifyContent:'center'}}>
         <span style={{fontFamily:'Cairo-Medium'}}>
            {t('GLOBAL.noData')}
         </span>
      </Box>
      }

      {/* <Box
          sx={{
            position: 'absolute',
            top: '10px',
            right: '130px',
            height: 'max-content',
            backgroundColor: '#FFFFFF',
            display: 'block'
          }}
        >
          <CustomMenu />
        </Box> */}
    </Box>
  )
}

export default CommunicationsTab