import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import ObjectOfErrors, { checkErrors, resetErrors, resetCurrentObject, currentObjectMerge } from '../../../Global/Errors/Department/Department'
import CloseIcon from '@mui/icons-material/Close';
import { toast } from 'react-toastify'
import departmentAPI from '../../../Global/Network/Department/index'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import CompanyAPI from '../../../Global/Network/Companies'


const RenderContent = (props) => {
    const [t] = useTranslation('common')

    return (
        <Box className='Container-fluid'>
            <Box className="row mt-4">
                <Box className="col-12 col-sm-12 col-md-6 ">
                    <CustomTextField

                        label={t('DEPARTMENTS.name')}
                        value={props?.department?.name?.value}
                        error={props?.department?.name?.error}
                        message={props?.department?.name?.message}
                        readOnly={false}
                        haswidth={true}
                        onChange={(e) => {
                            props?.setDepartment({
                                ...props?.department,
                                name: {
                                    value: e.target.value,
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                        onClearClick={() => {
                            props?.setDepartment({
                                ...props?.department,
                                name: {
                                    value: "",
                                    error: false,
                                    message: []
                                }

                            })
                        }}
                    />
                </Box>
                <Box className="col-12 col-sm-12 col-md-6 ">
                {props?.showCompany ?
                    <CustomeSelectField
                        label={t('DEPARTMENTS.company_id')}
                        haswidth={true}
                        value={props?.department?.company_id?.value}
                        list={props?.companies ? props?.companies : []}
                        error={props?.department?.company_id?.error}
                        message={props?.department?.company_id?.message}
                        readOnly={false}
                        onChange={(e, newValue) => {

                            props?.setDepartment({
                                ...props?.department,
                                company_id: {
                                    value: newValue,
                                    error: false,
                                    message: []
                                }

                            })

                        }}
                        onClearClick={() => {
                            props?.setDepartment({
                                ...props?.department,
                                company_id: {
                                    value: {
                                        id: "",
                                        name: ""
                                    },
                                    error: false,
                                    message: []
                                }

                            })

                        }}
                    /> : null}
                </Box>
                <Box className="col-12 col-sm-12 col-md-6"></Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const rolesRedux=useSelector(state=>state.rolesData.roles)

    const [loading, setLoading] = useState(false)
    const [department, setDepartment] = useState(ObjectOfErrors)

    const [companies, setCompanies] = useState([])

    useEffect(() => {
        if (rolesRedux?.view_all_companies?.value) loadDataOfCompanyList();
        if (props?.object && props?.object?.id) {
            setDepartment(currentObjectMerge(props?.object))
        } else {

            clearForm()
        }
    }, [props?.object])

    const loadDataOfCompanyList = async () => {
        let data = null;
        try {
            data = await CompanyAPI.CompanyList();
            if (data && data?.status) {
                setCompanies(data?.data);
            } else {
                toast.error(t("NETWORKMESSAGE.messageError"));
            }
        } catch (err) {
            console.log(err?.message);
        }
    };

    const submit = async () => {
        try {
            setLoading(true)

            let data = {
                name: department?.name?.value,
                company_id: department?.company_id?.value?.id,
            }
            let result = null;
            if (props?.object && props?.object?.id) {
                data = {

                    ...data,
                    _method: 'put'
                }
                result = await departmentAPI.updateDepartment({
                    id: props?.object?.id,
                    data: data
                });
            } else {
                result = await departmentAPI.addDepartment({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(department)
                    setDepartment(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setDepartment({
            ...ObjectOfErrors
        })
    }

    useEffect(() => {
        if (!props?.object)
            clearForm()
    }, [props?.addForm, props?.object])

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('DEPARTMENTS.updateTitle') : t('DEPARTMENTS.addTitle')}
            content={<RenderContent
                department={department}
                setDepartment={setDepartment}
                companies={companies}
                showCompany={rolesRedux?.view_all_companies?.value}
                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
