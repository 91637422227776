import React, { useState, useEffect } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux';
import CompoundAPI from '../../../Global/Network/Compound/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, RadioGroup, FormControlLabel, Radio } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddEntity from './AddEntity';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import CustomGrid from '../../Grid/CustomGrid';
import { setRowsRedux } from '../../../reduxStore/CompoundNestedReducer'
import entityAPI from '../../../Global/Network/Entity';
import RolesOfUserAPI from '../../../Global/Network/RoleUser';
import { debounce } from 'debounce';
import CallAPI from '../../../Global/Network/Calls';
import CallCompounds from './PopUpComponents/CallCompounds';

const RenderSearchContent = (props) => {
  const [t] = useTranslation('common')
  return (
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.compoundName}
        setValue={props?.setCompoundName}
        title={t('COMPOUNDS.name_search')}
      />
      <Box
        sx={{
          display: "flex",
          
          justifyContent: "space-between",
          alignItems: "center",
          width: "100%",
        }}
      >
        
        <Box
          sx={{
            backgroundColor: "lightgray",
            display: "flex",
            flexDirection:'column',
            alignItems: "center",
            justifyContent: "start",
            ml: 1.5,
            mt: 1,
            mb: 1,
            width: "100%",
            "& .MuiTypography-root": {
              fontFamily: "Cairo",
            },
          }}
          className="px-2 rounded"
        >
          <Typography>
        {t('GLOBAL.is_it_linked_with_image')}
        </Typography>
         
          <RadioGroup
            row
            aria-labelledby="demo-row-radio-buttons-group-label"
            name="row-radio-buttons-group"
            sx={{
              width: "100%",
              "& .MuiTypography-root": {
                fontFamily: "Cairo",
              },
            }}
            value={props?.needsToBeLinked}
            onChange={(e) => {
              
              props.setNeedsToBeLinked(e?.target.value);
              // setGender(e?.target?.value)
            }}
          >
            <FormControlLabel
              value={1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.needs_draw")}
            />
            <FormControlLabel
              value={0}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.doesnt_need_draw")}
            />
            <FormControlLabel
              value={-1}
              control={<Radio sx={{ fontFamily: "Cairo" }} />}
              label={t("GLOBAL.all")}
            />
            
            
          </RadioGroup>
        </Box>
      </Box>
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('COMPOUNDS.fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('COMPOUNDS.toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        clearOnBlur={false}
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('COMPOUNDS.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({searchText: e.target.value});
            }}
          />
        )}
      />

    </Box>
  )
}

export default function Compound() {
  const rowsRedux = useSelector(state => {
    return state.compoundNested.rows
  })
  const [t] = useTranslation("common");
  const dispatch = useDispatch();

  const [pageSize, setPageSize] = useState(5);
  const [pageNumber, setPageNumber] = useState(1);
  const [loading, setLoading] = useState(false)
  const [pageCount, setPageCount] = useState(0)
  const [openFilterColumn, setOpenFilterColumn] = useState(false)
  const [openFilterColumnValue, setOpenFilterColumnValue] = useState([])
  const [elementClicked, setElementClicked] = useState(null)
  const [addForm, setAddForm] = useState(false)
  const [selectedEntity, setSelectedEntity] = useState(null)
  const [entityType, setEntityType] = useState('')
  const [parentId, setParentId] = useState('')
  const [fromDate, setFromDate] = useState(null)
  const [toDate, setToDate] = useState(null)
  const [compoundName, setCompoundName] = useState(null)
  const [needsToBeLinked, setNeedsToBeLinked] = useState(-1)
  const [createdBy, setCreatedBy] = useState(null)
  const [users, setUsers] = useState([])
  const { exportToCSV } = useGlobalContext()
  const [checkedRows, setCheckedRows] = useState([])
  const [isViewMode,setIsViewMode]=useState(false)
  const [filterParams,setFilterParams]=useState({})
  const rolesRedux=useSelector(state=>state.rolesData.roles)
  
  const [showCallCompounds,setShowCallCompounds]=useState(false);
  const [callCompounds,setCallCompounds]=useState([]);

  const [addMode,setAddMode]=useState({})
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const [columns, setColumns] = useState([
    { headerName: t('COMPOUNDS.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    { headerName: t('COMPOUNDS.name'), field: 'name', flex: 2, minWidth: 250, maxWidth: 450, hide: false },
    {
      headerName: t('COMPOUNDS.created_at'), field: 'created_at', flex: 2, minWidth: 250, maxWidth: 450, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatDate(rows?.row?.created_at) : '0000-00-00'}</span>
          <span style={{ fontFamily: 'Cairo-Medium' }} className="gridHeaderItem">{rows?.row?.created_at ? helpers.formatTime(rows?.row?.created_at) : '00:00:00'}</span>

        </Box>
      )
    },
    {
      headerName: t('COMPOUNDS.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid>

          <MenuItem onClick={() => { }} className="ActionIcons">
            <ListItemIcon sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 7px 0 0'
            }}>
              <EditIcon style={{ fill: "#1E6A99" }} />

            </ListItemIcon>
            {t('GLOBAL.action_edit')}
          </MenuItem>
          <MenuItem onClick={() => { }} className="ActionIcons">
            <ListItemIcon sx={{
              display: 'flex',
              justifyContent: 'center',
              margin: '0px 7px 0 0'
            }}>
              <DeleteIcon style={{ fill: "#f00" }} />
            </ListItemIcon>
            {t('GLOBAL.action_delete')}

          </MenuItem>

        </DropDownGrid>
      ),
    },
  ])
  useEffect(() => {
    loadData()
  }, [pageNumber, pageSize, filterParams])

  useEffect(() => {
    loadDataOfUsers({})
  }, [])
  const loadData = async (searchParams) => {

    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams
     }
    const result = await CompoundAPI.compoundsNested({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {

      let updatedData = setCheckedData(result?.data?.data)

      dispatch(setRowsRedux(updatedData))
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
      // setPage(result?.data?.current_page)
    }
    else {
      // alert('3')

      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const handleSearch = debounce((value)=>{
    loadDataOfUsers(value);
  }, 500);
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)
  
    const result = await EmployeeAPI.Employee({
      params: {
        page_size: 3,
        page: 0,
        full_name: searchParams.searchText ? searchParams.searchText : "",
      },
      signal: controller?.signal,
    });
    setLoading(false)
    if (result.status) {
      setUsers(result?.data?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const createFUnction = () => {
    setAddMode(null)
    setSelectedEntity(null)
    setEntityType('compounds')
    setParentId('')
    setAddForm(true)
  }
  const addEntity = (entityObject) => {
    
    setSelectedEntity(null)
    setAddMode(entityObject)
    setEntityType(entityObject?.type)
    setParentId(entityObject?.entity?.id)
    setAddForm(true)
  }
  const editEntity = (obj) => {
    
    setSelectedEntity(obj?.entity)
    setParentId(obj?.entity?.id)
    setEntityType(obj?.type)
    setAddForm(true)
  }
  const viewCallCompounds = async (obj) => {
    if(obj?.type !== "compounds") return;
    const result = await CallAPI.callCompounds({
      params: {
        compound_ids: [obj?.entity?.id],
      },
      signal: controller?.signal,
    })
    setCallCompounds(result?.data?.data);
    setShowCallCompounds(true);
  }
  const viewObject = (obj) => {
    setSelectedEntity(obj?.entity)
    setParentId(obj?.entity?.id)
    setEntityType(obj?.type)
    setAddForm(true)
    setIsViewMode(true)
  }
  const deleteObject = (obj) => {
    
    deleteFunction(obj?.entity, obj?.type)
  }
  const deleteFunction = async (obj, type) => {
    swal({
      title: '?هل انت متأكد',
      text: "لن تتمكن من التراجع عن هذا!",
      icon: 'warning',
      buttons: ["الغاء!", "نعم!"],
      dangerMode: true,
    }).then(async (willDelete) => {
      if (willDelete) {

        try {
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            result = await entityAPI.deleteEntity({
              type: type,
              data: obj
            });
          }

          if (result?.status) {
            setLoading(false)
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          }
          else {
            setLoading(false)
            if (result?.errorMessage) {
              toast.error(result?.errorMessage)
            }
            else {
              if(result?.errorMessage){
                toast.error(result?.errorMessage)
              }else
              toast.error(t('NETWORKMESSAGE.deleteErrorr'))
            }
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(error?.message)
        }
      } else {
        // alert('canceled')
      }
    })
  }
  const clearFunction = () => {
    setCompoundName('')
    setNeedsToBeLinked(-1)
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams(null)

  }
  const searchFunction = () => {
    let data = {};
    if (compoundName) {
      data = {
        ...data,
        name: compoundName
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    if (needsToBeLinked > -1) {
      data = {
        ...data,
        needs_to_be_linked: needsToBeLinked,
      };
    }
    setFilterParams(data)
    // loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await CompoundAPI.compoundsNested({
      params: {
        page_size: rowsTotal,
        // page: pageNumber,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const formatListOfCompoundAnditsChilds=(data,title)=>{
   let arr=[]
      data&&data?.length>0&&data?.map((itm)=>{
          let object={
            name:itm?.name,
            description:itm?.description,
          }
          if(itm?.blocks&&itm?.blocks?.length>0){
            itm?.blocks?.map((block)=>{
              object={
                ...object,
                block_name:block?.name,
                block_description:block?.description,
              }
              if(block?.buildings?.length>0){
                block?.buildings?.map((build)=>{
                  object={
                    ...object,
                    build_name:build?.name,
                    build_description:build?.description,
                  }
                  if(build?.floors?.length>0){
                    build?.floors?.map((floor)=>{
                      object={
                        ...object,
                        floor_name:floor?.name,
                        floor_description:floor?.description,
                      }
                        arr.push(object)
                    })
                  }else{
                      arr.push(object)
                  }
                })
              }else{
                arr.push(object)
              }
            })
          }else {
            arr.push(object)
          }

      })
      return arr;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (compoundName) {
      filteredData = {
        ...filteredData,
        name: compoundName
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }
    let dataList=await formatListOfCompoundAnditsChilds(fetchedData?.data?.data,'compound')
    // console.log('compounds=>',dataList)
    let arr=[]
    dataList&&dataList?.length>0&&dataList?.map((itm)=>{
       arr.push({
        [t('COMPOUNDS.compound_name')]:itm?.name,
        [t('COMPOUNDS.compound_description')]:itm?.description,
        [t('COMPOUNDS.block_name')]:itm?.block_name,
        [t('COMPOUNDS.block_description')]:itm?.block_description,
        [t('COMPOUNDS.build_name')]:itm?.build_name,
        [t('COMPOUNDS.build_description')]:itm?.build_description,
        [t('COMPOUNDS.floor_name')]:itm?.floor_name,
        [t('COMPOUNDS.floor_description')]:itm?.floor_description,
       })
    })
    exportToCSV(arr, 'Compound')
  }



  const setCheckedRow = (row, checked) => {

    var newRows = []
    for (let index = 0; index < rowsRedux?.length; index++) {
      const element = rowsRedux[index];
      var updatedElement = {
        ...element
      }


      if ((element?.id === row?.id && element?.parent_id === row?.parent_id) && (
        (element?.company_id && element?.company_id === row?.company_id)
        ||
        (element?.compound_id && element?.compound_id === row?.compound_id)
        ||
        (element?.block_id && element?.block_id === row?.block_id)
        ||
        (element?.building_id && element?.building_id === row?.building_id)
        ||
        (element?.floor_id && element?.floor_id === row?.floor_id))) {

        trachCheckedRows(element, checked)
        updatedElement = {
          ...updatedElement,
          checked: checked
        }

      }

      if (element?.blocks?.length > 0) {
        updatedElement = {
          ...updatedElement,
          blocks: setCheckRowRecursive(element?.blocks, row, checked)
        }
      }
      else if (element?.buildings?.length > 0) {
        updatedElement = {
          ...updatedElement,
          buildings: setCheckRowRecursive(element?.buildings, row, checked)
        }
      }
      else if (element?.floors?.length > 0) {
        updatedElement = {
          ...updatedElement,
          floors: setCheckRowRecursive(element?.floors, row, checked)
        }
      }

      newRows.push(updatedElement)
    }

    dispatch(setRowsRedux(newRows))
    // setRows(newRows)
  }

  const setCheckRowRecursive = (rows, row, checked) => {
    var newRows = []
    for (let index = 0; index < rows.length; index++) {
      const element = rows[index];
      var updatedElement = {
        ...element
      }

      if ((element?.id === row?.id && element?.parent_id === row?.parent_id) && (
        (element?.company_id && element?.company_id === row?.company_id)
        ||
        (element?.compound_id && element?.compound_id === row?.compound_id)
        ||
        (element?.block_id && element?.block_id === row?.block_id)
        ||
        (element?.building_id && element?.building_id === row?.building_id)
        ||
        (element?.floor_id && element?.floor_id === row?.floor_id))) {


        trachCheckedRows(element, checked)
        updatedElement = {
          ...updatedElement,
          checked: checked
        }
      }

      if (element?.blocks?.length > 0) {
        updatedElement = {
          ...updatedElement,
          blocks: setCheckRowRecursive(element?.blocks, row, checked)
        }
      }
      else if (element?.buildings?.length > 0) {
        updatedElement = {
          ...updatedElement,
          buildings: setCheckRowRecursive(element?.buildings, row, checked)
        }
      }
      else if (element?.floors?.length > 0) {
        updatedElement = {
          ...updatedElement,
          floors: setCheckRowRecursive(element?.floors, row, checked)
        }
      }
      newRows.push(updatedElement)
    }
    return newRows
  }

  const trachCheckedRows = (element, checked) => {
    let type
    if (element?.company_id) {
      //Compound
      type = "compound"
    }
    else if (element?.compound_id) {
      //Block
      type = "block"
    }
    else if (element?.block_id) {
      //Building
      type = "building"
    }
    else if (element?.building_id) {
      //floor
      type = "floor"
    }
    let checkedRow = checkedRows?.filter(row => (row?.type == type && row?.id == element?.id))
    if (checkedRow?.length) {
      setCheckedRows(checkedRows?.map(row => {
        if (row?.type == type && row?.id == element?.id) {
          return {
            type: type,
            id: element?.id,
            checked: checked
          }
        }
        return row
      }))
    }
    else {
      setCheckedRows([...checkedRows, {
        type: type,
        id: element?.id,
        checked: checked
      }])
    }
  }

  const setCheckedData = (data) => {

    var newRows = []
    for (let index = 0; index < data?.length; index++) {
      const element = data[index];
      var updatedElement = {
        ...element
      }
      let type
      if (element?.company_id) {
        //Compound
        type = "compound"
      }
      else if (element?.compound_id) {
        //Block
        type = "block"
      }
      else if (element?.block_id) {
        //Building
        type = "building"
      }
      else if (element?.building_id) {
        //floor
        type = "floor"
      }
      let checkedRow = checkedRows?.filter(row => (row?.type == type && row?.id == element?.id))
      if (checkedRow?.length) {
        updatedElement = {
          ...updatedElement,
          checked: checkedRow[0]?.checked
        }
      }


      if (element?.blocks?.length > 0) {
        updatedElement = {
          ...updatedElement,
          blocks: setCheckedData(element?.blocks)
        }
      }
      else if (element?.buildings?.length > 0) {
        updatedElement = {
          ...updatedElement,
          buildings: setCheckedData(element?.buildings)
        }
      }
      else if (element?.floors?.length > 0) {
        updatedElement = {
          ...updatedElement,
          floors: setCheckedData(element?.floors)
        }
      }

      newRows.push(updatedElement)
    }

    return newRows
    // setRows(newRows)
  }


  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className="mt-2 pt-1"
    >
      {
        rolesRedux?.view_compound?.value ?
          <AddEntity
            parent_id={parentId}
            type={entityType} entity={selectedEntity}
            addMode={addMode}
            addForm={addForm} setAddForm={setAddForm}
            loadData={loadData}
            isViewMode={isViewMode}
            setIsViewMode={setIsViewMode}
            setEntityType={setEntityType}
            setParentId={setParentId}
            setSelectedEntity={setSelectedEntity}
          /> : null}

          <CallCompounds
            addForm={showCallCompounds} 
            setAddForm={setShowCallCompounds}
            callCompounds={callCompounds}
            setCallCompounds={setCallCompounds}
          />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          // backgroundColor: '#fff',
          position: 'relative',
          // top:'-25px'
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('COMPOUNDS.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <MdIcons.MdWorkspaces
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />
      {
        rolesRedux?.view_compound?.value ?
          <CustomGrid
            rows={rowsRedux}
            notHaveGrid={true}
            columns={columns}
            addEntity={addEntity}
            editEntity={editEntity}
            viewObject={viewObject}
            viewCallCompounds={viewCallCompounds}
            deleteObject={deleteObject}
            setCheckedRow={setCheckedRow}
            setColumns={setColumns}
            pageSize={pageSize}
            setPageSize={setPageSize}
            pageNumber={pageNumber}
            setPageNumber={setPageNumber}
            loading={loading}
            handlePageChange={handlePageChange}
            rowsTotal={rowsTotal}
            checkScreenSize={screenwidth}
            pageCount={pageCount}
            openFilterColumn={openFilterColumn}
            elementClicked={elementClicked}
            setOpenFilterColumn={setOpenFilterColumn}
            setColumnFilterValue={setOpenFilterColumnValue}
            columFilterValue={openFilterColumnValue}
            clearFunction={clearFunction}
            searchFunction={searchFunction}
            creatFunction={createFUnction}
            ExportFunction={ExportFunction}
            importFunction={null}
            mapFunction={null}
            telegramFunction={null}
            hasCreate={rolesRedux?.add_compound?.value}
            hasImport={false}
            hasExport={rolesRedux?.export_compound?.value}
            hasMap={false}
            hasTetelgram={false}
             
            filterChilds={<RenderSearchContent
              compoundName={compoundName}
              setCompoundName={setCompoundName}
              setNeedsToBeLinked={setNeedsToBeLinked}
              needsToBeLinked={needsToBeLinked}
              fromDate={fromDate}
              setFromDate={setFromDate}
              toDate={toDate}
              setToDate={setToDate}
              createdBy={createdBy}
              setCreatedBy={setCreatedBy}
              users={users}
              handleSearch={handleSearch}
            />}
          /> : null
      }

    </Box>
  )
}
