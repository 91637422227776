import callAPI from '../ApiConfig'
import store from '../../../app/store'

const compoundDepartments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_departments",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const compoundDepartmentList = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_departments_list",
        method:"get",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const addCompoundDepartments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_departments",
        method:"post",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const updateCompoundDepartments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_departments/"+props?.data?.id,
        method:"put",
        signal:props?.signal,
        params:props?.params,
        data:props?.data,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}
const deleteCompoundDepartments = async (props) => {
    const storeState = store.getState();
    
    return await callAPI({
        route:"compound_departments/"+props?.data?.id,
        method:"delete",
        signal:props?.signal,
        params:props?.params,
        headers:{
            "Authorization": `Bearer ${storeState?.userData?.token}`,
        }
    })
    
}

const CompoundDepartmentAPI={
    compoundDepartments:compoundDepartments,
    compoundDepartmentList:compoundDepartmentList,
    addCompoundDepartments:addCompoundDepartments,
    updateCompoundDepartments:updateCompoundDepartments,
    deleteCompoundDepartments:deleteCompoundDepartments,
}
export default CompoundDepartmentAPI