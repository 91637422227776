import { Autocomplete, Box, Divider, TextField,  } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ContainerOfSelectField } from '../RegisteredCustomers/ThemDesign'
import { toast } from 'react-toastify'
import AddAPhotoIcon from '@mui/icons-material/AddAPhoto';
import CameraswitchIcon from '@mui/icons-material/Cameraswitch';
import DeleteIcon from '@mui/icons-material/Delete';
import * as FaIcons from 'react-icons/fa'
import CloseIcon from '@mui/icons-material/Close';
import entityAPI from '../../Global/Network/Entity'
import * as RiIcons from 'react-icons/ri'
import ImageTypeAPI from '../../Global/Network/ImageType'
import {useTranslation} from 'react-i18next'
import {useDispatch, useSelector} from 'react-redux'
import ImageDetails from './ImageDetails';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { setGlobalLoading } from '../../reduxStore/SettingsReducer';

import { async } from 'q';
export default function Images(props) {
  const [imageTypes, setImageTypes] = useState([]);
  const [selectedImage, setSelectedImage] = useState("");
  const [t]=useTranslation('common')
  const controller = new AbortController();
  const mainTheme = useSelector(state => state.themeData.maintheme)
  const maintheme = useSelector(state => state.themeData.maintheme);
  const [openAddImageDetails,setOpenAddImageDetails]=useState(false)
  const [imageDetailsName,setImageDetailsName]=useState('')
  const [imageDetailsDescription,setImageDetailsDescription]=useState('')
  const [imageFile,setImageFile]=useState(null)
  const [imageExtension,setImageExtension]=useState('')
  const [imageNameValue,setImageNameValue]=useState('')
  const [imageTypeSelected,setImageTypeSelected]=useState(null)
  const [isEditModeImage,setIsEditModeImage]=useState(false);
  const [selectedIndexFileOfImage,setSelectedIndexFileOfImage]=useState(null)
  const [imageUpdatedLocal,setImageUpdatedLocal]=useState(null)
  const [loading,setLoading]=useState(false)
  const rolesRedux = useSelector(state => state.rolesData.roles)

  const dispatch=useDispatch()
  /// images helper functions
  const handleSizeOfFile = async (e) => {
    if (e.size > 2097152) {
      toast.warn("حجم الملفة تجاوز الحد المسموح");
      return false;
    }
    return true;
  };
  const checkImageListHasPolygonImage=()=>{
    let check=false;
    let checkCount=0;
    let checkItmId=null;

    
    props?.imageList&&props.imageList?.length>0&&props?.imageList?.map((itm)=>{
        if((itm?.image_type?.id==4&&imageTypeSelected?.id==4) || (itm?.image_type?.id==5&&imageTypeSelected?.id==5)){
          check=true;
          checkCount++;
          checkItmId=itm?.id
        }
    })
    if(checkCount==1&&checkItmId==selectedIndexFileOfImage){
      check=false;
    }
    return check;
  }
  const handleImageChange = async (e) => {
    // console.log('file', e.files[0].size)
    if (e && e?.target && e.target.files[0]) {
      if (await handleSizeOfFile(e.target.files[0])) {
        try {
          if (e?.target?.files) {
            // alert('asdasd')
            if (!props?.isUpdateImage) {
              if (props?.editMode) {
                let formData = new FormData();
                formData.append(`files[0]`, e.target.files[0]);
                formData.append(`type`, props?.type);
                formData.append(`image_type_id`, props?.type);
                let params = {};

                let result = await entityAPI.AddRequestFile({
                  data: formData,
                  params: params,
                  id: props?.task_id,
                  headers: {
                    "Content-Type": "application/x-www-form-urlencoded",
                  },
                });

                if (result?.status && result?.data?.status) {
                  props?.setImageList((itms) => [
                    ...itms,
                    {
                      url: window.URL.createObjectURL(e.target.files[0]),
                      file: e.target.files[0],
                      index: itms[itms?.length - 1]?.index
                        ? itms[itms?.length - 1]?.index + 1
                        : 1,
                      type: "image",
                      name: "",
                      image_type:
                        imageTypes?.length > 0
                          ? imageTypes[0]
                          : {  },
                    },
                  ]);
                } else {
                }
              } else {
                props?.setImageList((itms) => [
                  ...itms,
                  {
                    url: window.URL.createObjectURL(e.target.files[0]),
                    file: e.target.files[0],
                    index: itms[itms?.length - 1]?.index
                      ? itms[itms?.length - 1]?.index + 1
                      : 1,
                    id: "",
                    type: "image",
                    name: "",
                    image_type:
                      imageTypes?.length > 0
                        ? imageTypes[0]
                        : {  },
                  },
                ]);
              }
            } else {
              let arr = [];

              props?.imageList &&
                props?.imageList?.length &&
                props?.imageList?.map((itm) => {
                  if (props?.isUpdateImage?.index == itm?.index) {
                    arr.push({
                      ...itm,
                      url: window.URL.createObjectURL(e.target.files[0]),
                      file: e.target.files[0],
                      type: "image",
                      name: "",
                    });
                  } else {
                    arr.push(itm);
                  }
                });
              props.setImageList(arr);
              props.setIsUpdatedImage(null);
            }
          }
        } catch (e) {
          console.log(e?.message);
        }
      }
    }
  };
  const addImageToList = (e) => {
    if (props?.imageList?.length > 9 && !props?.isUpdateImage) {
      // becase we started from index 0..4=5 items
      toast.warn("لا يمكن رفع اكثر من 10 صور");
      return;
    }

    let image = e.target?.querySelector("input");
    if (image) {
      image.click();
      image = null;
    }
  };
  const setSelectedImageToShow = (itm) => {
    setSelectedImage(itm?.url);
  };
  const handleUpdateImage = (e) => {
    // setSelectedImage(null)

    setImageUpdatedLocal(e)
    props?.setIsUpdatedImage(e);

    let data = document.getElementById("imagesForTask");

    if (data) {
      data.click();
    }
  };
 
  const handleDeleteImage = async (e) => {
    if (props?.editMode) {
      console.log(e);
      let params = {};
      dispatch(setGlobalLoading(true))

      let result = await entityAPI.DeleteRequestFile({
        params: params,
        type:props?.type,
        id: e?.id,
      });

      if (result?.status && result?.data?.success) {
        setSelectedImage(null);
        props.setImageList(
          props?.imageList.filter((itm) => itm?.index !== e.index)
        );
      dispatch(setGlobalLoading(false))

      } else {
      dispatch(setGlobalLoading(false))

      }
    } else {
      setSelectedImage(null);
      props.setImageList(
        props?.imageList.filter((itm) => itm?.index !== e.index)
      );
      dispatch(setGlobalLoading(false))

    }
  };

  useEffect(() => {
    loadResources();

    return () => {};
  }, []);

  const loadResources = async (searchParams) => {
    props?.setLoading(true);

    try {
      const result = await ImageTypeAPI.ImageTypeList({
        params: {
          ...searchParams,
        },
        signal: controller?.signal,
      });

      props?.setLoading(false);
      if (result.status) {
        let arr = [];
        result?.data &&
          result?.data?.length > 0 &&
          result?.data?.map((item) => {
            if(props?.notHavePolyImage){
              if(item?.id!==4)
              arr.push(item);
            }
            else{
            arr.push(item);
            }
          });
        setImageTypes(arr);
      } else {
      }
    } catch (err) {
      console.log(err?.message);
      toast.error(err?.message);
    }
  };
  const handleImageNewUploaded=async(e)=>{
    if (e && e?.target && e.target.files[0]) {
      if (await handleSizeOfFile(e.target.files[0])) {
        try {
          setImageFile(e?.target?.files[0])
          setOpenAddImageDetails(true)
          clearImageAfterUpload()
          
        }catch(err){
          console.log(err?.message);
        }
      }
    }
  }

  const handleAddImageToList=async(e)=>{
    if(checkImageListHasPolygonImage()){
      toast.warn('يمكنك اختيار صورة للتخطيط مرة واحدة فقط');
      return false;
    }

    if(!imageDetailsDescription||!imageDetailsName||(!imageTypeSelected&&!props?.hideImageType)){
      toast.warn('يجب ملىء بيانات الصورة')
      clearImageAfterUpload()
      return
    }
    if (props?.editMode) {
      setLoading(true)
      let formData = new FormData();
      formData.append(`type_id`, props?.type_id);
      formData.append(`image_type_id`,props?.hideImageType?1:imageTypeSelected?.id);
      formData.append(`name`,imageDetailsName);
      formData.append(`description`,imageDetailsDescription);
      let params = {};
      
      let type=props?.type
      if(type&&type?.slice(type?.length-1=='s')){
        type=type?.slice(0,type?.length-1);
      }
      formData.append(`type`, props?.notHasSlice?props?.type:type);

      if(isEditModeImage&&selectedIndexFileOfImage){
        if(!selectedIndexFileOfImage){
          toast.warn('خطء غير معروف')
          return
        }
      // formData.append(`id`, selectedIndexFileOfImage);
      formData.append(`_method`,'put');


        let result = await entityAPI.updateRequestFile({
          data: formData,
          params: params,
          id:selectedIndexFileOfImage,
          type:props?.notHasSlice?props?.type:type,
        });

        if (result?.status) {
          let arr=[]
          props?.imageList&&props?.imageList?.map((itm)=>{
             if(itm?.id===selectedIndexFileOfImage){
              arr.push({
                ...itm,
                name:imageDetailsName ,
                description:imageDetailsDescription,
                image_type:imageTypeSelected,
              })
             }else arr.push(itm)
          })
          props?.setImageList(arr);
          setOpenAddImageDetails(false)
          clearDataOfImageDetails()
          setLoading(false)

        } else {
          setLoading(false)

        }
        setLoading(false)

      }else{
        formData.append(`image`, imageFile);
        let result = await entityAPI.AddRequestFile({
          data: formData,
          params: params,
          // id: props?.task_id,
          type:props?.notHasSlice?props?.type:type,
        });

        if (result?.status) {
       
          if(result?.data?.images){
            let arr=[]
            result?.data?.images&&result?.data?.images?.map((image,index)=>{
              arr.push({
                url: process.env.REACT_APP_API_URL_image + image?.image_url,
                file: null,
                index: index,
                id: image?.id,
                type: 'image',
                name: image?.name,
                description:image?.description,
                image_type:image?.image_type,
            })
            })
            props?.setImageList(arr);
          }
          else
            props?.setImageList((itms) => [
              ...itms,
              {
                url: window.URL.createObjectURL(imageFile),
                file: imageFile,
                index: itms&&itms[itms?.length - 1]?.index
                  ? itms[itms?.length - 1]?.index + 1
                  : 1,
                type: "image",
                name:imageDetailsName ,
                description:imageDetailsDescription,
                image_type:imageTypeSelected,
              },
            ]);

          setOpenAddImageDetails(false)
          clearDataOfImageDetails()
          setLoading(false)

        } else {
          setLoading(false)

        }
        setLoading(false)

      }
    }else{
      if(!imageUpdatedLocal){
        let arr=[]
        props?.imageList&&props?.imageList?.length>0&&props?.imageList?.map((itm)=>{
          arr.push(itm)
        })
        arr.push({
          url: window.URL.createObjectURL(imageFile),
          file: imageFile,
          index: props?.imageList&&props?.imageList[props?.imageList?.length - 1]?.index
            ? props?.imageList[props?.imageList?.length - 1]?.index + 1
            : 1,
          type: "image",
          name: imageDetailsName,
          description:imageDetailsDescription,
          image_type:imageTypeSelected,
        })
        console.log('arr=>',arr)
        props?.setImageList(arr);
      }else{
        let arr=[]
        props?.imageList&&props?.imageList?.map((itm)=>{
           if(itm?.index===imageUpdatedLocal?.index){
            arr.push({
              ...itm,
              url: window.URL.createObjectURL(imageFile),
              file: imageFile,
              name:imageDetailsName ,
              description:imageDetailsDescription,
              image_type:imageTypeSelected,
            })
           }else arr.push(itm)
        })
        console.log('arr=>',arr)
        props?.setImageList(arr);
      }
        setImageUpdatedLocal(null)
        setImageFile(null)
        setOpenAddImageDetails(false)
        clearDataOfImageDetails()
    }
    clearImageAfterUpload()
   

  }
  const clearImageAfterUpload=()=>{
    try{
      let image = document?.querySelector("#imagesForTask");
      if(image){
        image.value='';
      }
    }catch(err){
      toast.warn(err?.message)
    }
  }
  const clearDataOfImageDetails=()=>{
    setIsEditModeImage(false)
    setImageDetailsName('')
    setImageDetailsDescription('')
    setImageTypeSelected(null)
    setSelectedIndexFileOfImage('')

  }

  const handleShowImageDetails=(obj)=>{
    
    setOpenAddImageDetails(true)
    setImageDetailsName(obj?.name)
    setImageDetailsDescription(obj?.description)
    setImageTypeSelected(obj?.image_type)
    setSelectedIndexFileOfImage(obj?.id)
    setIsEditModeImage(true)
  }

  return (
    <>
      <Box className="my-0 mb-3 me-4 ">
        {/* <Divider  sx={{
          borderColor:'lightgray',
          marginTop:'20px',
          marginBottom:'20px',
          border:'2px'
        }} /> */}
        <FaIcons.FaCommentAlt style={{ color: "grey", fontSize: "18px" }} />{" "}
        <span style={{ fontFamily: "Cairo-Medium", fontSize: "18px" }}>
          ملاحظة: اكبر عدد للملفات المسموح رفعها 10 بالاضافة ان لا يتجاوز حجم
          الملف
        </span>
        <span style={{ fontFamily: "Cairo-Medium", fontSize: "18px" }}>2MB</span>
      </Box>
      <Box
        sx={{
          width: "100%",
          display: "flex",
          justifyContent: "start",
          alignItems: "center",
          padding: "0 20px",
          flexWrap: "wrap",
        }}
      >
        {props?.imageList &&
          props.imageList?.length > 0 &&
          props.imageList?.map((itm) => (
            rolesRedux?.view_image?.value?
            <Box
              sx={{
                width: "200px !important",
                height: "200px !important",
                borderRadius: "5px",
                //  border:'3px dashed green',
                //  backgroundCOlor:'lightgrey',
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                cursor: "pointer",
                borderRadius: "3px",
                margin: "0px 10px",
                boxShadow:'0 0 15px -2px rgba(0,0,0,0.3)',
                margin:'10px 7px',
                // background: "#ebebeb",
                zIndex: 10,
                //  flexGrow:'1',
                //  flexBasis:'1',

                position: "relative",
                flexDirection: "column",
              }}
            >
              <Box sx={{width:'100%',height:'100%'}}>
                <img
                  src={itm?.url}
                  alt="task photo"
                  style={{
                    width: "200px",
                    height: "200px",
                    objectFit: "cover",
                    backgroundPosition: "center center",
                  }}
                  onClick={() => setSelectedImageToShow(itm)}
                />
                <Box
                  sx={{
                    width: "fit-content",
                    height: "fit-content",
                    padding: "3px 11px",
                    backgroundColor: "#414141",
                    borderRadius: "10px",
                    dispaly: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                    position: "absolute",
                    top: "5px",
                    right: "10px",
                    zIndex: 32,
                    // pointerEvents:''
                  }}
                >
                {
                  rolesRedux?.view_image?.value?
                  <VisibilityIcon
                    sx={{
                      color: "#fff",
                      fontSize: "20px",
                      margin: "0 3px",
                      cursor: "poinrt",
                      "&:hover": {
                        transform: "scale(1.3)",
                        color: "#fab9b4",
                      },
                    }}
                    onClick={() => handleShowImageDetails(itm)}
                  />:null
                 }

                  {
                    rolesRedux?.delete_image?.value&&!props?.isViewMode?
                  <DeleteIcon
                    sx={{
                      color: "#fff",
                      fontSize: "20px",
                      margin: "0 3px",
                      cursor: "poinrt",
                      "&:hover": {
                        transform: "scale(1.3)",
                        color: "#fab9b4",
                      },
                    }}
                    onClick={() => handleDeleteImage(itm)}
                  />:null
                 }
                  {!props?.editMode &&rolesRedux?.update_image?.value&& (
                    <CameraswitchIcon
                      sx={{
                        color: "#fff",
                        fontSize: "20px",
                        margin: "0 3px",
                        cursor: "poinrt",
                        "&:hover": {
                          color: "#486b99",
                          transform: "scale(1.3)",
                        },
                      }}
                      onClick={() => handleUpdateImage(itm)}
                    />
                  )}
                </Box>
              </Box>

           
            </Box>:null
          ))}
        {
        rolesRedux?.add_image?.value&&!props?.isViewMode?
        <Box
          sx={{
            width: "200px !important",
            height: "200px !important",
            borderRadius: "5px",
            border: "3px dashed green",
            //  backgroundCOlor:'lightgrey',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            cursor: "pointer",
            borderRadius: "3px",
            background: "#ebebeb",
            boxShadow: "14px 14px 14px #d8d8d8,-14px -14px 14px #fefefe",
          }}
          onClick={addImageToList}
        >
          <AddAPhotoIcon
            sx={{ fontSize: "40px", color: "black", pointerEvents: "none" }}
          />
          <input
            type="file"
            className="d-none"
            onChange={handleImageNewUploaded}
            id="imagesForTask"
            accept="image/png, image/gif, image/jpeg,image/jpg"
          />
        </Box>:null
         }

        {selectedImage ? (
          <Box
            sx={{
              position: "fixed",
              width: window.innerWidth,
              height: window.innerHeight,
              top: 0,
              right: 0,
              left: 0,
              bottom: 0,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backdropFilter: "blur(7px)",
              zIndex: "3539",
            }}
          >
            <img
              src={selectedImage}
              alt="selected photo to show"
              style={{
                width: "600px",
                height: "600px",
                objectFit: "cover",
                backgroundPosition: "center center",
              }}
            />
            <CloseIcon
              sx={{
                position: "fixed",
                top: 10,
                left: 20,
                color: "#47aeed",
                fontSize: "40px",
                zIndex: 234234234,
                cursor: "pinter !important",
                "&:hover": {
                  color: "red",
                },
              }}
              onClick={() => setSelectedImage("")}
            />
          </Box>
        ) : null}

        <ImageDetails
         title={t('COMPOUNDS.imageDetailsTitle')}
         titleFiled={t("COMPOUNDS.imageDetailsName")}
         titleFiled2={t("COMPOUNDS.imageDetailsDescription")}
         open={openAddImageDetails}
         setOpen={setOpenAddImageDetails}
         name={imageDetailsName}
         setName={setImageDetailsName}
         description={imageDetailsDescription}
         setDescription={setImageDetailsDescription}
         submit={handleAddImageToList}
         setImageTypeSelected={setImageTypeSelected}
         imageTypeSelected={imageTypeSelected}
         imageTypes={imageTypes}
         type={props?.type}
         isEditModeImage={isEditModeImage}
         selectedIndexFileOfImage={selectedIndexFileOfImage}
         editMode={props?.editMode}
         clearFlieldAfterClose={clearDataOfImageDetails}
         loading={loading}
         hideImageType={props?.hideImageType}
         isViewMode={props?.isViewMode}
        />
      </Box>
    </>
  );
}
