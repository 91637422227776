import React, { useEffect, useRef, useState } from 'react'
import { Box, Divider, Typography } from '@mui/material'
import InvoiceRow from './Components/InvoiceRow';
import { useReactToPrint } from 'react-to-print';
import helpers from '../../../assets/js/helper';
import logo from '../../../assets/img/milestone_logo.png'
import swal from 'sweetalert';
import Cookies from "universal-cookie";
import EmployeeAPI from '../../../Global/Network/Employee';

const cookies = new Cookies();

export default function PaymentPrint({ payment, print, setPrint }) {

  const [user, setUser] = useState(null);
  const printRef = useRef();
  const handlePrint = useReactToPrint({

    content: () => {
      const tableStat = printRef.current.cloneNode(true);
      const PrintElem = document.createElement('div');
      const header =
        `<img src="${logo}" alt="" class="watermark"/>`
        +
        `<div class="pageFooter">I'm The Footer</div>` +
        `<div class="page-header">I'm The Header</div>`;
      // PrintElem.innerHTML = header;
      PrintElem.appendChild(tableStat);
      return PrintElem;
    },
  });
  useEffect(() => {
    if (print) {
      handlePrint()
      setPrint(false)
    }

    return () => {

    }
  }, [print])


 const getUser = async () => {
  let data = null;
  try {
    let params={
      id:cookies.get('userable_id')
    }

    data=await EmployeeAPI.ShowEmployee({
        params:params,
    });

    setUser(data?.data?.user);

  } catch (error) {
    console.log(error)
  }
 }

 useEffect(() => {
  getUser()
 }, []);


  const getName = () => {
    let customer = payment?.invoice?.order?.customer
    let title = "السيد"
    if (customer?.user?.gender) {
      title = "السيدة"
      if (customer?.marital_status_id == 2) {
        title = "الانسة"
      }
    }
    return title + " " + (customer?.user?.full_name ? customer?.user?.full_name : "")
  }
  const getAddress = (lineNumber) => {
    let customerAddress = payment?.invoice?.order?.customer?.addresses?.length > 0 ? payment?.invoice?.order?.customer?.addresses[0] : null
    if (!customerAddress) {
      return
    }
    if (lineNumber == 1) {
      return customerAddress?.name ? " " + customerAddress?.name : " "
    }
    else if (lineNumber == 2) {
      return (customerAddress?.address ? " " + customerAddress?.address : " ")
    }
    else if (lineNumber == 3) {
      return (customerAddress?.first_phone ? " " + customerAddress?.first_phone : " ")
    }

    return ""

  }

  const getPaymentPriceFormated = () => {
    let paymentPrice = payment?.paid_amount
    let unitCurrencyCode = payment?.invoice?.order?.unit?.iqd_usd == 0 ? "IQD" : "USD"

    return "(" + paymentPrice?.format(payment?.invoice?.order?.unit?.iqd_usd == 1 ? 2 : 0, 3, ',', '.') + ") " + helpers?.tafqeet(paymentPrice, unitCurrencyCode)
  }

  const getNoteText = () => {
    let text = "سند قبض صادرة وفق الفاتورة المبينة أعلاه  "
    console.log("payment?.invoice?.payment_type_detail",payment?.invoice?.payment_type_detail);
    if (payment?.invoice?.payment_type_detail?.days_duration_from_contract == 0) {
      text += "عن الدفعة الأولى   "
    }
    text += "وحسب طريقة الدفع المبينة في العقد المذكور اعلاه."
    return text
  }
  
  function formatDate(inputDate) {
    try {
        const dateObj = new Date(inputDate);
        if (isNaN(dateObj)) {
        throw new Error("Invalid date");
        }
        
        const year = dateObj.getFullYear();
        const month = String(dateObj.getMonth() + 1).padStart(2, '0');
        const day = String(dateObj.getDate()).padStart(2, '0');
        
        return `${year}/${month}/${day}`;
    } catch (error) {
        return "Invalid date format";
    }
  }

  return (

    <Box sx={{
      display: 'flex',
      flexDirection: 'column',

      width: '100%',
      justifyContent: 'center',
      alignItems: 'stretch',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"
    >
      <Box
        sx={{
          display: 'flex',
          width: '100%',
          height: 'calc(100vh - 100px)',
          backgroundColor: "#FFFFFF",
          justifyContent: 'center',
          alignItems: 'center',
          overflow: 'scroll'
        }}
      >
        <Box
          ref={printRef}
          style={{
            backgroundColor: "#FFFFFF",
            width: '100%',
            padding: '20px',
            direction: 'rtl',
            height: "200px"
          }}>
          <img src={require("../../../assets/img/payment_header.png")} width="100%" />
          <Box
            sx={{
              display: 'flex',
              justifyContent:'space-between',
              paddingX: "40px"
            }}
          >
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "22px",
                marginTop: "20px"
            }}>
              <Typography
                  component={'span'}
                  style={{
                      fontWeight: 900,
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      lineHeight: 'normal',
                      fontSize: "22px",
                  }}>
                  {/* {"اسم المشروع: "} */}
                  {"التاريخ : "}
              </Typography>
              {payment?.created_at ? formatDate(payment?.created_at) : '0000-00-00' + ""}  
            </Typography>
            <InvoiceRow
              sx={{
                flex: 1,
                direction: "ltr"
              }}
              title={"No."}

              titleSX={{
                flex: 1,
                fontSize: "22px",
                fontWight: 800,
                color: "red"
              }}
              valueSX={{
                flex: 1,
                fontSize: "22px",
                fontWight: 400
              }}
              value={payment?.id + ""}
              paddingRight={"20px"}

            />
          </Box>
          <Box sx={{ paddingX: "40px"}}>
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "25px",
                marginTop: "20px",
                borderBottom: '1px solid black',
                fontWeight: 600,
            }}>
              <Typography
                  component={'span'}
                  style={{
                      fontWeight: 400,
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      lineHeight: 'normal',
                      fontSize: "24px",
                      borderBottom: '1px solid white'
                  }}>
                  {/* {"اسم المشروع: "} */}
                  {"إستلمنا من السيد / السيدة : "}
              </Typography>
              {getName()}  
            </Typography>
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "25px",
                marginTop: "15px",
                borderBottom: '1px solid black',
                fontWeight: 600,
            }}>
              <Typography
                  component={'span'}
                  style={{
                      fontWeight: 400,
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      lineHeight: 'normal',
                      fontSize: "24px",
                      borderBottom: '1px solid white'
                  }}>
                  {/* {"اسم المشروع: "} */}
                  {"مبلغ وقدره : "}
              </Typography>
              {getPaymentPriceFormated()}  
            </Typography>
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "25px",
                marginTop: "15px",
                borderBottom: '1px solid black',
                fontWeight: 600,
            }}>
              <Typography
                  component={'span'}
                  style={{
                      fontWeight: 400,
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      lineHeight: 'normal',
                      fontSize: "24px",
                      borderBottom: '1px solid white'
                  }}>
                  {/* {"اسم المشروع: "} */}
                  {"وذلك مقابل حجز وحدة سكنية في مجمع : "}
              </Typography>
              {payment?.order?.compound?.name}  
            </Typography>
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "24px",
                marginTop: "15px",
            }}>
              العمارة <span style={{ borderBottom: '1px solid black' }}>{payment?.order?.building?.name}</span> الطابق <span style={{ borderBottom: '1px solid black' }}>{payment?.invoice?.order?.unit?.floor?.name}</span> الشقة <span style={{ borderBottom: '1px solid black' }}>{payment?.invoice?.order?.unit?.name}</span>
            </Typography>
            <Typography
              style={{
                width: '100%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "24px",
                color: "blue",
                marginTop: "25px"
            }}>
              *هذا المبلغ هوه نسبة شركة بوابة المستقبل للاستثمار والتطوير العقاري ولا يعتبر هذا المبلغ جزءً من قيمة العقار, مدة الحجز (7) ايام (المبلغ المدفوع غير مسترد)
            </Typography>
            <Typography
              style={{
                width: '40%',
                wordWrap: 'break-word',
                fontSize: "20px",
                textAlign: 'justify',
                textJustify: 'inter-word',
                lineHeight: 'normal',
                fontSize: "25px",
                marginTop: "40px",
                borderBottom: '1px solid black',
                fontWeight: 600,
            }}>
              <Typography
                  component={'span'}
                  style={{
                      fontWeight: 400,
                      textAlign: 'justify',
                      textJustify: 'inter-word',
                      lineHeight: 'normal',
                      fontSize: "24px",
                      borderBottom: '1px solid white'
                  }}>
                  {"المحاسب : "}
              </Typography>
              {user?.full_name}  
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
