import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useSelector } from 'react-redux'
import initRoleObject, { checkErrors, resetErrors, resetObject, ObjectMerge } from '../../../Global/Errors/Roles/Roles'
import CloseIcon from '@mui/icons-material/Close';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify'
import RolesAPI from '../../../Global/Network/Roles'
import CustomTextField from '../../../General/CustomComponents/CustomTextField'
import CustomeSelectField from '../../../General/CustomComponents/CustomeSelectField'
import roleCategoryAPI from '../../../Global/Network/RoleCategory'


const RenderContent = (props) => {
    const [t] = useTranslation('common')


    return (
        <Box className='Container-fluid'>
            <Box className="row">
                <Box className="col-12 col-sm-12 col-md-10 m-auto">
                    <CustomTextField
                        label={t('ROLE.name')}
                        haswidth={true}
                        value={props?.roleObject?.name?.value}
                        error={props?.roleObject?.name?.error}
                        message={props?.roleObject?.name?.message}
                        readOnly={props?.roleObject?.name?.readOnly}
                        onChange={(e) => {
                            props?.setRoleObject({
                                ...props?.roleObject,
                                name: {
                                    ...props?.roleObject?.name,
                                    value: e.target.value,
                                    error: false,
                                    message: []
                                }
                            })

                        }}
                        onClearClick={() => {
                            props?.setRoleObject({
                                ...props?.roleObject,
                                name: {
                                    ...props?.roleObject?.name,
                                    value: "",
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                    <Box sx={{ margin: '10px 0' }}></Box>
                    <CustomeSelectField
                        label={t('ROLE.role_category')}
                        haswidth={true}
                        value={props?.roleObject?.role_category?.value}
                        list={props?.roleCategories ? props?.roleCategories : []}
                        error={false}
                        message={""}
                        readOnly={false}
                        onChange={(e, newValue) => {
                            props?.setRoleObject({
                                ...props?.roleObject,
                                role_category: {
                                    ...props?.roleObject?.role_category,
                                    value: newValue,
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                        onClearClick={() => {
                            props?.setRoleObject({
                                ...props?.roleObject,
                                role_category: {
                                    ...props?.roleObject?.role_category,
                                    value: {
                                        id: "",
                                        name: ""
                                    },
                                    error: false,
                                    message: []
                                }
                            })
                        }}
                    />
                </Box>
                <Box className="col-12 col-sm-12 col-md-6"></Box>
            </Box>
        </Box>
    )
}

const RenderFooter = (props) => {
    const [t] = useTranslation('common')
    const gridtheme = useSelector(state => state.themeData.gridtheme)

    return (
        <Box sx={{
            display: 'flex',
            alignItems: 'center',
        }}>


            <Box sx={{ display: 'flex', justifyContent: 'end', alignItems: 'center', width: '100%' }}>
                <Box display="flex" >

                    <Button variant="outlined"
                        // spacing={2}
                        sx={{
                            width: '50% !important',
                            // flexGrow: 1, 
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            margin: 1,
                            backgroundColor: '#f7f7f7',
                            borderColor: gridtheme?.colorWhite,
                            color: gridtheme?.colorblack,
                            boxShadow: '0 0 7px -2px white',
                            //   color:SearchButtonTheme?.clear_button_text_color,
                            '&:hover': {
                                backgroundColor: '#f7f7f7',
                                borderColor: gridtheme?.colorWhite,
                                color: gridtheme?.colorblack,
                                boxShadow: '0 0 7px 1px white',
                                boxShadow: '0',


                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'

                        }}
                        className="iconeFilterClear"
                        color="secondary"
                        onClick={() => {
                            props.setOpen(false)
                        }}
                    >
                        {t('GLOBAL.close')}
                    </Button>
                    <Button
                        variant="contained"
                        // spacing={2} 
                        sx={{
                            // flexGrow: 1,
                            margin: 1,
                            width: '80px !important',
                            minWidth: '80px !important',
                            maxWidth: '80px !important',
                            '&:hover': {
                                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
                            },
                            height: '35px',
                            fontFamily: 'Cairo-Bold'
                        }}
                        className="iconeFilterSearch"
                        onClick={() => {
                            props.submit()
                        }}
                    >
                        {t('GLOBAL.Save')}
                    </Button>
                </Box>
            </Box>
        </Box>
    )
}


export default function AddFormDailog(props) {
    const [t] = useTranslation('common');
    const [name, setName] = useState(null)
    const [loading, setLoading] = useState(false)
    const [roleObject, setRoleObject] = useState(initRoleObject)
    const [roleCategories, setRoleCategories] = useState([])
    const controller = new AbortController()

    useEffect(() => {
        if (props?.object && props?.object?.id) {

            setRoleObject(ObjectMerge(props?.object))
        } else {

            clearForm()
        }
    }, [props?.object])

    const submit = async () => {
        try {
            setLoading(true)

            let data = {
                name: roleObject?.name?.value,
                role_category_id: roleObject?.role_category?.value?.id
            }
            let result = null;
            if (props?.object && props?.object?.id) {
                data = {
                    ...data,
                    id: props?.object?.id,
                    _method: 'put'
                }
                result = await RolesAPI.updateRoole({
                    data: data
                });
            } else {
                result = await RolesAPI.addRoole({
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(roleObject)
                    setRoleObject(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
        }

    }

    const clearForm = () => {
        setRoleObject(initRoleObject)
    }

    useEffect(() => {
        loadRoleCategories()
        if (!props?.object)
            clearForm()
    }, [props?.addForm, props?.object])



    const loadRoleCategories = async () => {

        try {
            const result = await roleCategoryAPI.roleCategoriesList({
                signal: controller?.signal
            })
            if (result.status) {
                setRoleCategories(result?.data)
            }
        } catch (err) {
            console.log(err?.message)
            toast.error(err?.message)
        }
        setLoading(false)
    }

    return (
        <PopupForm
            open={props.addForm}
            setOpen={props.setAddForm}
            title={props?.object ? t('ROLE.updateTitle') : t('ROLE.addTitle')}
            content={<RenderContent
                roleObject={roleObject}
                setRoleObject={setRoleObject}
                roleCategories={roleCategories}
                open={props.addForm}
                setOpen={props.setAddForm}
            />}
            footer={<RenderFooter
                open={props.addForm}
                setOpen={props.setAddForm}
                submit={submit}
            />}
        />
    )
}
