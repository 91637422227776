import { Box, TextField, Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import PopupForm from "../../Components/PopupForm";
import { useTranslation } from "react-i18next";
import {
  ContainerOfInput,
  ContainerOfInputFields,
  ContainerOfSelectField,
} from "../../RegisteredCustomers/ThemDesign";
import { useSelector } from "react-redux";
import ObjectOfErrors, {
  checkErrors,
  ObjectMerge,
  resetErrors,
  restCustomeObject,
} from "../../../Global/Errors/CompoundDepartments/CompoundDepartment";
import CloseIcon from "@mui/icons-material/Close";
import MaritalStatusAPI from "../../../Global/Network/MaritalStatus/index";
import { toast } from "react-toastify";
import CompoundDepartmentAPI from "../../../Global/Network/CompoundDepartment/index";
import CustomeSelectField from "../../../General/CustomComponents/CustomeSelectField";
import CustomTextField from "../../../General/CustomComponents/CustomTextField";
import CompanyAPI from "../../../Global/Network/Companies";
import compoundAPI from "../../../Global/Network/Compound";
import ColorLensIcon from "@mui/icons-material/ColorLens";
import helpers from "../../../assets/js/helper";

const RenderContent = (props) => {
  const [t] = useTranslation("common");
  const maintheme = useSelector((state) => state.themeData.maintheme);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);

  const webOrMobileList = [
    {
      id: "mobile",
      name: t("CALLTYPE.mobile"),
    },
    {
      id: "web",
      name: t("CALLTYPE.web"),
    },
    {
      id: "both",
      name: t("CALLTYPE.both"),
    },
  ];
  const getWebOrMobileValue = (id) => {
    let webOrObileValueList = webOrMobileList?.filter((item) => item?.id == id);
    if (webOrObileValueList?.length) {
      return webOrObileValueList[0];
    } else {
      return {
        id: "",
        name: "",
      };
    }
  };

  return (
    <Box className="Container-fluid">
      <Box className="row">
        <Box className="col-12 col-sm-12 col-md-6">
          <CustomTextField
            label={t("COMPOUNDDEPARTMENTS.name")}
            haswidth={true}
            value={props?.name}
            error={ErrorsObject?.name?.error}
            message={ErrorsObject?.name?.message}
            readOnly={false}
            onChange={(e) => {
              props?.setName(e?.target?.value);
              setErrorsObject(restCustomeObject(ErrorsObject, "name"));
            }}
            onClearClick={() => {
              props?.setName("");
              setErrorsObject(restCustomeObject(ErrorsObject, "name"));
            }}
          />
        </Box>
        <Box className="col-12 col-sm-12 col-md-6">
          <CustomeSelectField
            label={t("COMPOUNDDEPARTMENTS.compound")}
            haswidth={true}
            value={props?.selectedCompound}
            list={props?.compounds ? props?.compounds : []}
            error={ErrorsObject?.compound_id.error}
            message={ErrorsObject?.compound_id.message}
            readOnly={false}
            focused={true}
            onChange={(e, newValue) => {
              props?.setSelectedCompound(newValue);
              setErrorsObject(restCustomeObject(ErrorsObject, "compound_id"));
            }}
            onClearClick={() => {
              props?.setSelectedCompound(null);
              setErrorsObject(restCustomeObject(ErrorsObject, "compound_id"));
            }}
          />
        </Box>
        <Box className="col-md-12 my-3">
          <Box
            sx={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                width: "200px",
                height: "200px",
                borderRadius: "50%",
                cursor: "pointer",
                backgroundColor: props?.color
                  ? props?.color
                  : "rgba(0,0,0,0.2)",
                border: "2px dashed green",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                position: "relative",
              }}
              onClick={() => {
                if (document.getElementById("colorPicker")) {
                  document.getElementById("colorPicker").click();
                }
              }}
            >
              <ColorLensIcon
                color="#fff"
                sx={{
                  fontSize: "30px",
                  color: helpers.checkDarknessOrLightnessForColor(
                    props?.color ? props?.color : "#ffffff"
                  )
                    ? "#fff"
                    : "#000",
                  position: "absolute",
                  left: "50%",
                  top: "50%",
                  transform: "translate(-50%,-50%)",
                  zIndex: "234234",
                }}
              />
              <input
                type="color"
                style={{
                  width: "100%",
                  height: "100%",
                  borderRadius: "50%",
                  visibility: "hidden",
                }}
                id="colorPicker"
                onChange={(e) => {
                  props?.setColor(e?.target.value);
                }}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

const RenderFooter = (props) => {
  const [t] = useTranslation("common");
  const gridtheme = useSelector((state) => state.themeData.gridtheme);

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "end",
          alignItems: "center",
          width: "100%",
        }}
      >
        <Box display="flex">
          <Button
            variant="outlined"
            // spacing={2}
            sx={{
              width: "50% !important",
              // flexGrow: 1,
              minWidth: "80px !important",
              maxWidth: "80px !important",
              margin: 1,
              backgroundColor: "#f7f7f7",
              borderColor: gridtheme?.colorWhite,
              color: gridtheme?.colorblack,
              boxShadow: "0 0 7px -2px white",
              //   color:SearchButtonTheme?.clear_button_text_color,
              "&:hover": {
                backgroundColor: "#f7f7f7",
                borderColor: gridtheme?.colorWhite,
                color: gridtheme?.colorblack,
                boxShadow: "0 0 7px 1px white",
                boxShadow: "0",
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterClear"
            color="secondary"
            onClick={() => {
              props.setOpen(false);
            }}
          >
            {t("GLOBAL.close")}
          </Button>
          <Button
            variant="contained"
            // spacing={2}
            sx={{
              // flexGrow: 1,
              margin: 1,
              width: "80px !important",
              minWidth: "80px !important",
              maxWidth: "80px !important",
              "&:hover": {
                //   backgroundColor:SearchButtonTheme?.search_button_color+'88',
              },
              height: "35px",
              fontFamily: "Cairo-Bold",
            }}
            className="iconeFilterSearch"
            onClick={() => {
              props.submit();
            }}
          >
            {t("GLOBAL.Save")}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default function AddFormDailog(props) {
  const [t] = useTranslation("common");
  const [name, setName] = useState(null);
  const [loading, setLoading] = useState(false);
  const [callTypeObject, setCallTypeObject] = useState(ObjectOfErrors);
  const [color, setColor] = useState("");
  const [compounds, setCompounds] = useState("");
  const [selectedCompound, setSelectedCompound] = useState(null);
  const [ErrorsObject, setErrorsObject] = useState(ObjectOfErrors);

  useEffect(() => {
    if (props?.object && props?.object?.id) {
      setName(props?.object?.name);
      setColor(props?.object?.color);
      setSelectedCompound({
        id:props?.object?.compound?.id,
        name:props?.object?.compound?.name,
      });
    } else {
      clearForm();
    }
  }, [props?.object]);

  const submit = async () => {
    try {
      setLoading(true);

      let data = {
        name: name,
        color: color,
        compound_id: selectedCompound.id,
      };
      let result = null;
      if (props?.object?.id) {
        data = {
          ...data,
          id: props?.object?.id,
          _method: "put",
        };
        result = await CompoundDepartmentAPI.updateCompoundDepartments({
          data: data,
        });
      } else {
        result = await CompoundDepartmentAPI.addCompoundDepartments({
          data: data,
        });
      }

      if (result?.status) {
        setLoading(false);
        toast.success(t("NETWORKMESSAGE.addCustomerMessageSuccess"));
        clearForm();
        props.loadData();
        props.setAddForm(false);
      } else {
        if (typeof result.errorMessage === "object") {
          let updatedObject = resetErrors(callTypeObject);
          setCallTypeObject(checkErrors(result.errorMessage, updatedObject));
        }
        setLoading(false);
        toast.error(t("NETWORKMESSAGE.messageError"));
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error(error?.message);
    }
  };

  const clearForm = () => {
    setName("");
    setColor("");
    setSelectedCompound(null);
  };

  useEffect(() => {
    if (!props?.object) clearForm();
    loadDataOfCompounds();
  }, [props?.addForm, props?.object]);

  const loadDataOfCompounds = async () => {
    const result = await compoundAPI.compoundsList();

    if (result.status) {
        let arr=[]
        result?.data&&result?.data?.length>0&&result?.data?.map((itm)=>{
           arr.push({
            id:itm?.id,
            name:itm?.name,
           })
        })
      setCompounds(result?.data);
    } else {
      if (!result?.data?.error) {
        toast.error(t("NETWORKMESSAGE.messageError"));
        return;
      }
    }
  };
  return (
    <PopupForm
      open={props.addForm}
      setOpen={props.setAddForm}
      title={
        props?.object
          ? t("COMPOUNDDEPARTMENTS.updateTitle")
          : t("COMPOUNDDEPARTMENTS.addTitle")
      }
      content={
        <RenderContent
          object={callTypeObject}
          setObject={setCallTypeObject}
          open={props.addForm}
          setOpen={props.setAddForm}
          compounds={compounds}
          selectedCompound={selectedCompound}
          setSelectedCompound={setSelectedCompound}
          name={name}
          setName={setName}
          color={color}
          setColor={setColor}
        />
      }
      footer={
        <RenderFooter
          open={props.addForm}
          setOpen={props.setAddForm}
          submit={submit}
        />
      }
    />
  );
}
