import React, { useState, useEffect } from "react";
import "devextreme/dist/css/dx.light.css";
import { Box, Typography, Divider, Button } from "@mui/material";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import * as AiIcons from "react-icons/ai";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import * as FaIcons from "react-icons/fa";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import BarCart from "./BarChart";
import PieChartDashboard from "./PieChartDashboard";
import RadialBar from "./RadialBar";
import StackedBarCgart from "./StackedBarCgart";
import LineColumnChart from "./LineColumnChart";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import * as GiIcons from "react-icons/gi";
import "../style.css";
import Filter from "./Filter";
import compoundAPI from "../../../Global/Network/Compound";
import { toast } from "react-toastify";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import CustomerOutStandingGrid from "./CustomerOutStandingGrid";
import { fontFamily } from "@mui/system";
import DelinquentCutomerGrid from "./DelinquentCutomerGrid";
import helpers from "../../../assets/js/helper";

export default function Finance(props) {
  const [t] = useTranslation("common");
  const dashboardtheme = useSelector((state) => state.themeData.dashboardtheme);
  const isopensidemenu = useSelector(
    (state) => state.settingsData.isopensidemenu
  );
  const screenwidth = useSelector((state) => state.settingsData.screenwidth);
  const [country, setCountry] = useState(1);
  const [isOutStandingOrDelinquent, setIsOutStandingOrDelinquent] =
    useState(false);
  const handleChangeCountry = (e) => {
    setCountry(e.target.value);
  };
  const payments_over_due = [
    {
      iqd_usd: 0,
      payments_over_due: 26000000,
    },
    {
      iqd_usd: 1,
      payments_over_due: 1200,
    },
  ];

  return (
    <Box className="row px-0 mx-0 py-1" sx={{ width: "100%", }}>
      <Box
        sx={{
          display:'flex',
          justifyContent:'start',
          alignItems:'center',
          flexWrap:'wrap',
          width:'100%',
          padding:'0'
        }}
      >
      {props?.dashboards?.finance?.customers_outstanding &&
        props?.dashboards?.finance?.customers_outstanding?.length > 0 &&
        props?.dashboards?.finance?.customers_outstanding?.map((itm) => (
            <Box
              style={{
                display: "flex",
                justifyContent: "center",
                // padding:'0 10px',
                alignItems: "center",
                alignContent: "center",
                backgroundColor: dashboardtheme?.colorWhite,
                borderRadius: " 3px",
                boxShadow: "0px 0px 12px 1px #0000000A",
                opacity: 1,
                height: "100px",
                overflowX: "auto",
                overflowY: "hidden",
                width:'fit-content',
                minWidth:'310px',
                maxWidth:'400px',
                height:'fit-content',
                padding: '5px 10px',
                margin: '10px ',
                marginLeft:'0',
              }}
            >
              <Box style={{ margin: "0 -10px 0 30px" }}>
                {itm?.iqd_usd == 0 ? (
                  <GiIcons.GiMoneyStack
                    size={55}
                    color={dashboardtheme?.background}
                  />
                ) : (
                  <MonetizationOnIcon
                    size={55}
                    color={dashboardtheme?.background}
                    sx={{ color: dashboardtheme?.background, fontSize: "40px" }}
                  />
                )}
              </Box>
              <Box
                style={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "center",
                  position: "relative",
                  top: "10px",
                }}
              >
                <span
                  style={{
                    textAlign: "left",
                    font: "normal normal 28px/48px Rajdhani-Bold",
                    letterSpacing: "1.19px",
                    color: "#333333",
                    opacity: 1,
                  }}
                >
                  {helpers?.getFormatedPrice(itm?.customers_outstanding_value,itm?.iqd_usd)}
                </span>
                <span
                  style={{
                    position: "relative",
                    textAlign: "left",
                    font: "normal normal  16px/45px Cairo-BOld",
                    letterSpacing: "0px",
                    color: "#7D8185",
                    opacity: "1",
                    top: "-15px",
                  }}
                >
                  {itm?.iqd_usd == 0
                    ? t("DASHBOARD.outstanding_IQD")
                    : t("DASHBOARD.outstanding_USD")}
                </span>
              </Box>
            </Box>
        ))}
      {props?.dashboards?.finance?.payments_over_due &&
        props?.dashboards?.finance?.payments_over_due?.length > 0 &&
        props?.dashboards?.finance?.payments_over_due?.map((itm) => (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              // padding:'0 10px',
              alignItems: "center",
              alignContent: "center",
              backgroundColor: dashboardtheme?.colorWhite,
              borderRadius: " 3px",
              boxShadow: "0px 0px 12px 1px #0000000A",
              opacity: 1,
              height: "100px",
              overflowX: "auto",
              overflowY: "hidden",
              width:'fit-content',
              maxWidth:'400px',
              minWidth:'310px',
              height:'fit-content',
              padding: '5px 10px',
              margin: '10px',
              marginLeft:'0',
            }}
          >
            <Box style={{ margin: "0 -10px 0 30px" }}>
              {itm?.iqd_usd == 0? (
                <GiIcons.GiMoneyStack
                  size={55}
                  color={dashboardtheme?.background}
                />
              ) : (
                <MonetizationOnIcon
                  size={55}
                  color={dashboardtheme?.background}
                  sx={{ color: dashboardtheme?.background, fontSize: "40px" }}
                />
              )}
            </Box>
            <Box
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                alignContent: "center",
                position: "relative",
                top: "10px",
              }}
            >
              <span
                style={{
                  textAlign: "left",
                  font: "normal normal 28px/48px Rajdhani-Bold",
                  letterSpacing: "1.19px",
                  color: "#333333",
                  opacity: 1,
                }}
              >
                {helpers.getFormatedPrice(itm?.payments_over_due,itm?.iqd_usd)}
              </span>
              <span
                style={{
                  position: "relative",
                  textAlign: "left",
                  font: "normal normal  16px/45px Cairo-BOld",
                  letterSpacing: "0px",
                  color: "#7D8185",
                  opacity: "1",
                  top: "-15px",
                }}
              >
                {itm?.iqd_usd == 0
                  ? t("DASHBOARD.paymentOverDue_IQD")
                  : t("DASHBOARD.paymentOverDue_USD")}
              </span>
            </Box>
          </Box>
      ))}
     </Box>
      {/* grid for outStanding customers  */}
      <Box   
        className="col-12 col-md-12 col-lg-12   my-1 px-0"
        sx={{
          width:'100%',
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "start",
            alignItems: "center",
            width: "100%",
          }}
        >
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: !isOutStandingOrDelinquent
                ? "#1e6a99"
                : "#4a687a",
              color: "#fff",
              padding: "10px 20px",
              borderBottom:!isOutStandingOrDelinquent?'5px solid lightgray':'0',
              margin: "10px",
              fontFamily: "Cairo",
              "&:hover": {
                backgroundColor: !isOutStandingOrDelinquent
                  ? "#1e6a99"
                  : "#4a687a",
              },
            }}
            onClick={() => {
              setIsOutStandingOrDelinquent(false);
            }}
          >
            {t("DASHBOARD.CustomerOutStandingGrid")}
          </Button>
          <Button
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              backgroundColor: isOutStandingOrDelinquent
                ? "#1e6a99"
                : "#4a687a",
              color: "#fff",
              padding: "10px 20px",
              margin: "10px",
              borderBottom:isOutStandingOrDelinquent?'5px solid lightgray':'0',
              fontFamily: "Cairo",
              "&:hover": {
                backgroundColor: isOutStandingOrDelinquent
                  ? "#1e6a99"
                  : "#4a687a",
                

              },
            }}
            onClick={() => {
              setIsOutStandingOrDelinquent(true);
            }}
          >
            {t("DASHBOARD.Customerdelinquent")}
          </Button>
        </Box>
        {!isOutStandingOrDelinquent ? (
          <CustomerOutStandingGrid dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
              pageNumber={props?.pageNumber}
              setPageNumber={props?.setPageNumber}
              pageSize={props?.pageSize}
              setPageSize={props?.setPageSize}
          />
        ) : (
          <DelinquentCutomerGrid 
          dashboards={props?.dashboards} loadDataOfDashboards={props?.loadDataOfDashboards}
          pageNumber={props?.pageNumber}
          setPageNumber={props?.setPageNumber}
          pageSize={props?.pageSize}
          setPageSize={props?.setPageSize}
          />
        )}
      </Box>

    </Box>
  );
}
