import React, { useState, useEffect, useMemo } from 'react'
import Grid from '../../Grid/Grid'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux';
import MaritalStatusAPI from '../../../Global/Network/MaritalStatus/index'
import { toast } from 'react-toastify';
import DropDownGrid from '../../Grid/Components/DropDownGrid'
import { Box, MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField, FormControlLabel, Checkbox } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ViewWeekIcon from '@mui/icons-material/ViewWeek';
import AddFormDailog from './AddFormDailog';
import swal from 'sweetalert';
import SearchInput from '../../Components/SearchInput';
import SearchDate from '../../Components/SearchDate';
import EmployeeAPI from '../../../Global/Network/Employee/index'
import { from } from 'stylis';
import helpers from '../../../assets/js/helper';
import { useGlobalContext } from '../../../context'
import * as MdIcons from 'react-icons/md'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket';
import * as FaIcons from 'react-icons/fa'
import customerAPI from '../../../Global/Network/Customer';
import CompanyAPI from '../../../Global/Network/Companies/index';
import RolesOfUserAPI from '../../../Global/Network/RoleUser/index';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';
import { debounce } from 'debounce';
import {useNavigate} from 'react-router-dom'
const RenderSearchContent=(props)=>{
  const [t]=useTranslation('common')
  console.log('user',props?.users)
  return(
    <Box sx={{
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: 'column',
      width: '100% !important',
    }}>
      <SearchInput
        value={props?.objectName}
        setValue={props?.setobjectName}
        title={t('COMPANIES.name')}
      />
      <SearchInput
        value={props?.email}
        setValue={props?.setEmail}
        title={t('COMPANIES.email')}
      />
      <Box sx={{
        backgroundColor: 'lightgray', width: '97%', display: 'flex', alignItems: 'center', justifyContent: 'start', ml: 1.5, mt: 1, mb: 1, width: '95%',
        '& .MuiTypography-root': {
          fontFamily: 'Cairo'
        }
      }} className="px-2 rounded" >
        <FormControlLabel control={<Checkbox checked={props?.activeStatus}
          onChange={(e) => {
            props.setActiveStatus(e?.target.checked)
          }}
        />} sx={{}} label={t('COMPANIES.active_status')}
        />
      </Box>
      <SearchDate
        value={props?.fromDate}
        setValue={props?.setFromDate}
        title={t('COMPANIES.work_fromDate_search')}
        minDate={null}
        maxDate={props?.toDate}
      />
      <SearchDate
        value={props?.toDate}
        setValue={props?.setToDate}
        title={t('COMPANIES.work_toDate_search')}
        minDate={props?.fromDate}
        maxDate={null}
      />
      <Autocomplete
        sx={{
          ml: 1.5, mt: 1, mb: 1, width: '95%',
          '& .MuiFormLabel-root,& .MuiInputBase-input': {
            fontFamily: 'Cairo-Medium'
          }
        }}
        options={props.users ? props.users : []}
        getOptionLabel={(option) => option?.user && option?.user?.full_name || ""}
        clearOnBlur={false}
        value={props?.createdBy}
        onChange={(e, v, r) => { props.setCreatedBy(v ? v : ''); }}
        renderInput={(params) => (
          <TextField {...params}
            placeholder={t('COMPANIES.SEARCH_USERS')}
            variant="standard" sx={{ fontFamily: 'Cairo-Medium' }}
            onChange={(e) => {
              props.handleSearch({searchText: e.target.value});
            }} />
        )}
      />

    </Box>
  )
}

export default function Company() {
 const [t] = useTranslation("common");
 const [rows,setRows]=useState([])
 const [pageSize, setPageSize] = useState(5);
 const [pageNumber, setPageNumber] = useState(1);
 const [loading,setLoading]=useState(false)
 const [pageCount,setPageCount]=useState(0)
 const [openFilterColumn,setOpenFilterColumn]=useState(false)
 const [openFilterColumnValue,setOpenFilterColumnValue]=useState([])
 const [elementClicked,setElementClicked]=useState(null)
 const [addForm,setAddForm]=useState(false)
 const [selectedObject,setselectedObject]=useState(null)
 const [fromDate,setFromDate]=useState(null)
 const [toDate,setToDate]=useState(null)
 const [objectName,setobjectName]=useState(null)
 const [createdBy,setCreatedBy]=useState(null)
 const [users,setUsers]=useState([])
 const { exportToCSV} = useGlobalContext()
 const [description,setDescription]=useState('')
 const [email,setEmail]=useState('')
 const [activeStatus,setActiveStatus]=useState(null)
 const navigate=useNavigate()
 const [filterParams,setFilterParams]=useState({})
 const rolesRedux = useSelector(state => state.rolesData.roles)

  const getFormatDescription = (desc) => {
    let string = desc.replace(/\s\s+/g, ' ');
    return string
  }
  const handlePageChange = (newPage) => {
    setPageNumber(newPage + 1);
  };
  const controller = new AbortController();

  const [rowsTotal, setRowsTotal] = useState(0)
  const screenwidth = useSelector(state => state.settingsData.screenwidth)
  const columns = useMemo(() => [
    { headerName: t('COMPANIES.id'), field: 'id', flex: 0.5, minWidth: 50, maxWidth: 150, hide: false },
    { headerName: t('COMPANIES.name'), field: 'name', flex: 2, minWidth: 90, maxWidth: 250, hide: false },
    //   {headerName:t('COMPANIES.description'),field:'description',flex:2,minWidth:150,maxWidth:350,hide:false,
    //   renderCell:(rows)=>(
    //     <Box
    //       sx={{
    //         display:'flex',
    //         justifyContent:'start',
    //         alignItems:'center',
    //         fontFamily:'Cairo',
    //         textAlign:'justify',
    //         wordBreak:'break-all',
    //         wordWrap:'break-word',
    //         wordSpacing:'break-word',
    //         whiteSpace:'break-spaces'
    //       }}
    //     >
    //       {rows?.row?.description?rows?.row?.description?.length<110?getFormatDescription(rows?.row?.description) :getFormatDescription(rows?.row?.description?.slice(0,110)) +'...':''}
    //     </Box>
    //   )
    // },
    { headerName: t('COMPANIES.email'), field: 'email', flex: 2, minWidth: 170, maxWidth: 250, hide: false },
    {
      headerName: t('COMPANIES.active_status'), field: 'active_status', flex: 1, minWidth: 90, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'start',
            alignItems: 'center'
          }}
        >
          {rows?.row?.active_status ? t('COMPANIES.active') : t('COMPANIES.unActive')}
        </Box>
      )
    },


    //   {headerName:t('COMPANIES.created_at'),field:'created_at',flex:2,minWidth:250,maxWidth:450,hide:false,
    //   renderCell: (rows) => (
    //     <Box 
    //     sx={{
    //       display:'flex',
    //       flexDirection:'column',
    //       alignItems: 'center',
    //       justifyContent: 'center',
    //   }}
    //     >
    //       <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.created_at?helpers.formatDate(rows?.row?.created_at):'0000-00-00'}</span>
    //       <span style={{fontFamily:'Cairo-Medium'}}  className="gridHeaderItem">{rows?.row?.created_at?helpers.formatTime(rows?.row?.created_at):'00:00:00'}</span>

    //     </Box>
    //   )
    // },
    {
      headerName: t('COMPANIES.actions'), field: 'Actions', flex: 0.4, minWidth: 100, maxWidth: 250, hide: false,
      renderCell: (rows) => (
        <DropDownGrid className="containerOFDropDownToolTipeGrid">
          {
            rolesRedux?.view_company?.value ?
              <MenuItem onClick={() => { viewFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <RemoveRedEyeIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_view')}
              </MenuItem> : null
          }
          {
            rolesRedux?.update_company?.value ?
              <MenuItem onClick={() => { updateFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <EditIcon style={{ fill: "#1E6A99" }} />

                </ListItemIcon>
                {t('GLOBAL.action_edit')}
              </MenuItem> : null
          }
          {
            rolesRedux?.delete_company?.value && rolesRedux?.view_all_companies?.value ?
              <MenuItem onClick={() => { deleteFunction(rows?.row) }} className="ActionIcons">
                <ListItemIcon sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  margin: '0px 7px 0 0'
                }}>
                  <DeleteIcon style={{ fill: "#f00" }} />
                </ListItemIcon>
                {t('GLOBAL.action_delete')}

              </MenuItem> : null
          }
          {
            rolesRedux?.update_company?.value== false && rolesRedux?.delete_company?.value == false ?
              <p style={{ fontFamily: 'Cairo', margin: '5px 20px' }}>{t('GLOBAL.emptyPoPup')}</p>
              : null
          }

        </DropDownGrid>
      ),
    },
  ], [rolesRedux])

  useEffect(() => {
    let abortController = new AbortController();
    loadData()
    return () => {
      abortController.abort();
    }
  }, [pageNumber, pageSize,filterParams])

  useEffect(() => {
    let abortController = new AbortController();

    console.log('saldkjaskd', 1);
     
    loadDataOfUsers({})
    return () => {
      abortController.abort();
    }
  }, [])

  const loadData = async (searchParams) => {
    setLoading(true)
    const filterParamsData={
      ...filterParams,
      ...searchParams,
     }
    const result = await CompanyAPI.Companies({
      params: {
        page_size: pageSize,
        page: pageNumber,
        ...filterParamsData
      },
      signal: controller?.signal
    })

    setLoading(false)
    if (result.status) {
      setRows(result?.data?.data)
      setRowsTotal(result?.data?.total)
      setPageCount(result?.data?.last_page)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }
  const handleSearch = debounce((value)=>{
    loadDataOfUsers(value);
  }, 500);
  const loadDataOfUsers = async (searchParams) => {
    setLoading(true)
  
    const result = await EmployeeAPI.Employee({
      params: {
        page_size: 3,
        page: 0,
        full_name: searchParams.searchText ? searchParams.searchText : "",
      },
      signal: controller?.signal,
    });
    setLoading(false)
    if (result.status) {
      setUsers(result?.data?.data)
    }
    else {
      if (!result?.data?.error) {
        toast.error(t('NETWORKMESSAGE.messageError'))
        return
      }
    }

  }


const createFUnction=()=>{
  setselectedObject(null)
   setAddForm(true)
}
const viewFunction=(row)=>{
   navigate('/admin/company/profile',{
    state:{
      data:{
        company:row,
      }
    }
   })
}
const updateFunction=(obj)=>{
  setselectedObject(obj)
  setAddForm(true)
}
const deleteFunction=async(obj)=>{
  swal({
    title: '?هل انت متأكد',
    text: "لن تتمكن من التراجع عن هذا!",
    icon: 'warning',
    buttons: ["الغاء!", "نعم!"],
    dangerMode: true,
  }).then(async(willDelete) => {
    console.log('1')
    if (willDelete) {
      console.log('2')

      try{
          setLoading(true)

          let result = null;
          if (obj && obj?.id) {
            console.log('3')

            result = await CompanyAPI.deleteCompany({
              data: {
                id: obj.id,
              }
            });
          }

          if (result?.status) {
            setLoading(false)
            // toast.success(t('NETWORKMESSAGE.deleteSuccess'))
            swal(`${t('NETWORKMESSAGE.deleteSuccess')}`, {
              icon: "success",
            });
            loadData({
              page: pageNumber
            })
          } else {
            setLoading(false)
            if(result?.errorMessage){
              toast.error(result?.errorMessage)
            }else
            toast.error(t('NETWORKMESSAGE.deleteErrorr'))
          }
          setLoading(false)
        } catch (error) {
          setLoading(false)
          toast.error(t(error?.message))
        }
      } else {
        // alert('canceled')
      }
    })
}
  const clearFunction = () => {
    setobjectName('')
    setFromDate(null)
    setToDate(null)
    setCreatedBy(null)
    setFilterParams(null)
  }
  const searchFunction = () => {
    let data = {};
    if (objectName) {
      data = {
        ...data,
        name: objectName
      }
    }
    if (email) {
      data = {
        ...data,
        email: email
      }
    }
    if (activeStatus) {
      data = {
        ...data,
        active_status: activeStatus ? 1 : 0
      }
    }
    if (fromDate) {
      data = {
        ...data,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      data = {
        ...data,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.user && createdBy.user?.id) {
      data = {
        ...data,
        created_by_id: createdBy.user?.id
      }
    }
    setFilterParams(data)

    // loadData(data)
  }
  const loadDataForExport = async (searchParams) => {
    let result = await CompanyAPI.Companies({
      params: {
        page_size: rowsTotal,
        ...searchParams
      },
      signal: controller?.signal
    })
    return result;
  }
  const ExportFunction = async () => {
    let filteredData = {}
    if (objectName) {
      filteredData = {
        ...filteredData,
        name: objectName
      }
    }
    if (email) {
      filteredData = {
        ...filteredData,
        email: email
      }
    }
    if (activeStatus) {
      filteredData = {
        ...filteredData,
        active_status: activeStatus ? 1 : 0
      }
    }
    if (fromDate) {
      filteredData = {
        ...filteredData,
        creation_date_from: helpers.formatDate(fromDate)
      }
    }
    if (toDate) {
      filteredData = {
        ...filteredData,
        creation_date_to: helpers.formatDate(toDate)
      }
    }
    if (createdBy && createdBy?.id) {
      filteredData = {
        ...filteredData,
        created_by_id: createdBy?.id
      }
    }

    const fetchedData = await loadDataForExport(filteredData);
    if (!fetchedData || !fetchedData?.status) {
      toast.error("لا يمكن استرجاع البيانات")
      return
    }

    let arr = [];
    fetchedData?.data?.data && fetchedData?.data?.data?.map((itm) => {
      arr.push({
        [`${t('COMPANIES.id')}`]: itm?.id,
        [`${t('COMPANIES.name')}`]: itm?.name,
        [`${t('COMPANIES.description')}`]: itm?.description,
        [`${t('COMPANIES.email')}`]: itm?.email,
        [`${t('COMPANIES.active_status')}`]: itm?.active_status ? t('COMPANIES.active') : t('COMPANIES.unActive'),

        [`${t('COMPANIES.created_at')}`]: itm?.created_at ? helpers.formatDate(itm?.created_at) + " " + helpers.formatTime(itm?.created_at) : '0000-00-00 00:00:00',
      })
    })
    exportToCSV(arr, 'COMPANIES')

  }



  return (
    <Box sx={{
      // padding: '10px',
      // paddingTop: '20px',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      width: '100%',
      justifyContent: 'center',
      alignItems: 'start',
      overflowX: 'hidden',

    }}
      className=" pt-3 mt-1"

    >
      <AddFormDailog object={selectedObject} addForm={addForm} setAddForm={setAddForm} loadData={loadData}  />

      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          width: '100%',
          height: '100%',
          justifyContent: 'center',
          alignItems: 'stretch',
          position: 'relative',
        }}
      >
        <Box sx={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <Typography
            variant='h4'
            sx={{
              marginLeft: '10px',
              marginBottom: '10px',
              fontFamily: 'Cairo-Bold',
              fontSize: '16px'
            }}>
            {t('COMPANIES.title')}
          </Typography>
        </Box>

        <Box sx={{ marginRight: '20px', backgroundColor: '#1E6A99', color: '#FFFFFF', width: '42px', height: '40px', borderRadius: '50%', display: 'flex', justifyContent: 'center', alignItems: 'center', cursor: 'pointer' }}
          onClick={(event) => {
            // setOpenFilterColumn(true)
            // setElementClicked(event.currentTarget)
          }}
          
          aria-controls={openFilterColumn ? 'demo-customized-menu' : undefined}
          aria-haspopup="true"
          aria-expanded={openFilterColumn ? 'true' : undefined}
          variant="contained"
        >
          {/* <ViewWeekIcon sx={{color:'#fffff'}}/> */}
          <FaIcons.FaHouseUser
            style={{ fontSize: '30px', color: '#fff' }}
          />
        </Box>

      </Box>

      <Divider
        sx={{
          backgroundColor: '#EBF2F7',
          margin: '30px',
          marginTop: '10px',
          width: '100%'
        }}
        style={{
          height: '3px',

        }}
      />

      <Grid
        rows={rows}
        columns={columns}
        setColumns={null}
        pageSize={pageSize}
        setPageSize={setPageSize}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        loading={loading}
        handlePageChange={handlePageChange}
        rowsTotal={rowsTotal}
        checkScreenSize={screenwidth}
        pageCount={pageCount}
        openFilterColumn={openFilterColumn}
        elementClicked={elementClicked}
        setOpenFilterColumn={setOpenFilterColumn}
        setColumnFilterValue={setOpenFilterColumnValue}
        columFilterValue={openFilterColumnValue}
        clearFunction={clearFunction}
        searchFunction={searchFunction}
        creatFunction={createFUnction}
        ExportFunction={ExportFunction}
        importFunction={null}
        mapFunction={null}
        telegramFunction={null}
        hasCreate={rolesRedux?.add_company?.value && rolesRedux?.view_all_companies?.value}
        hasImport={false}
        hasExport={rolesRedux?.export_company?.value}
        hasMap={false}
        hasTetelgram={false}
        filterChilds={<RenderSearchContent
          objectName={objectName}
          setobjectName={setobjectName}
          description={description}
          setDescription={setDescription}
          email={email}
          setEmail={setEmail}
          activeStatus={activeStatus}
          setActiveStatus={setActiveStatus}
          fromDate={fromDate}
          setFromDate={setFromDate}
          toDate={toDate}
          setToDate={setToDate}
          createdBy={createdBy}
          setCreatedBy={setCreatedBy}
          users={users}
          handleSearch={handleSearch}
        />}
      />

    </Box>
  )
}
