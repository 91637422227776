const initRoleObject = {
    id: "",
    name: {
        value: "",
        error: false,
        message: "",
        required: false
    },
    role_category: {
        value: {
            id: "",
            name: ""
        },
        error: false,
        message: "",
        required: false
    },
}
export const resetErrors = (object) => {
    var objectKeys = Object.keys(object);

    var updatedObject = {
        ...object
    }
    try {
        objectKeys.filter(key => key !== "id").map((key) => {

            updatedObject[key]["error"] = false
            updatedObject[key]["message"] = ""
        })
    } catch (error) {

    }

    return updatedObject

}
export const checkErrors = (errorMessages, object) => {

    const errorMessagesKeys = Object.keys(errorMessages);
    errorMessagesKeys.map((key) => {
        if (!key?.includes('contacts')) {
            let messages = []
            errorMessages[key].map((message) => {
                messages.push(message)
            })

            object[key]["error"] = true
            object[key]["message"] = messages
        }
    })






    return object
}
export const ObjectMerge = (updatedObject) => {
    const mergedCustomerObject = {
        id: updatedObject?.id,
        name: {
            value: updatedObject?.name,
            error: false,
            message: "",
            required: false
        },
        role_category: {
            value: updatedObject?.role_category,
            error: false,
            message: "",
            required: false
        },

    }

    return mergedCustomerObject;
}
export const resetObject = (object, obj) => {
    // var objectKeys = Object.keys(object);

    try {

        object[obj]["error"] = false
        object[obj]["message"] = ""
    } catch (error) {

    }

    return object
}
export default initRoleObject;