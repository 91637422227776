import { Box } from '@mui/material'
import React from 'react'
import CustomTableHeader from './CustomTableHeader'
import CustomTableRow from './CustomTableRow'

function CustomTable(props) {
  
  return (
    <Box sx={{
        backgroundColor: '#FFFFFF',
        ...props?.sx
     }}>
        <CustomTableHeader columns={props?.columns} />
        {props?.rows?.map((row)=> <CustomTableRow 
        key={row?.id} 
        row={row} 
         
        type={props?.type}
        onEdit={()=>props?.onEdit(row)}
        onDelete={()=>props?.onDelete(row)}
        firstCellIcon={props?.firstCellIcon}
        rowsStyle={props?.rowsStyle}
        />
        )}
    </Box>
  )
}

export default CustomTable