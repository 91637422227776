import { Box, TextField, Button } from '@mui/material'
import React, { useState, useEffect } from 'react'
import PopupForm from '../../Components/PopupForm'
import { useTranslation } from 'react-i18next'
import { ContainerOfInput, ContainerOfInputFields, ContainerOfSelectField } from '../../RegisteredCustomers/ThemDesign'
import { useDispatch, useSelector } from 'react-redux'
import EntityError, { checkErrors, entityObjectMerge, resetErrors, resetEntityObject } from '../../../Global/Errors/Entity/Entity'
import CloseIcon from '@mui/icons-material/Close';
import entityAPI from '../../../Global/Network/Entity'
import { toast } from 'react-toastify'
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import RenderContent from './PopUpComponents/RenderContent'
import RenderFooter from './PopUpComponents/RenderFooter'
import PhaseAPI from '../../../Global/Network/Phase'
import CompoundPhaseAPI from '../../../Global/Network/compoundPhase'
import { setGlobalLoading } from '../../../reduxStore/SettingsReducer'





export default function AddEntity(props) {
    const [t] = useTranslation('common');
    const [name, setName] = useState('')
    const [skip, setSkip] = useState(false)
    const [description, setDescription] = useState('')
    const [floorNumber, setFloorNumber] = useState('')
    const [loading, setLoading] = useState(false)
    const [entityErrorsObject, setEntityErrorsObject] = useState(EntityError)
    const [imageList, setImageList] = useState([])
    const [addAddress,setAddAddresses]=useState(false)
    const [phases,setPhases]=useState([])
    const [selectedPhase,setSelectedPhase]=useState(null)
    const [listOfAddresses,setListOfAddresses]=useState([])
    const [selectedAddress,setSelectedAddress]=useState(null)
    const dispatch=useDispatch()

    useEffect(()=>{
    
    },[props?.type])
    const submit = async () => {
        try {
            dispatch(setGlobalLoading(true))
            
            console.log("--------------------floorNumber",floorNumber);
            setLoading(true)
            let dumData = resetErrors(entityErrorsObject)
            let data = {
                name: name,
                skip:skip,
                description: description,
                floor_number:floorNumber,
                images: imageList,
                parent_id:props?.parent_id,
                addresses:listOfAddresses
            }
            if(selectedPhase&&selectedPhase?.id){
              data={
                ...data,
                compound_phase_id:selectedPhase?.id,
              }
            }
            
            let result = null;
            if (props?.entity && props?.entity?.id) {
                data = {
                    ...data,
                    id: props?.entity?.id,
                    parent_id:props?.entity?.parent_id,
                    _method: 'put'
                }
                result = await entityAPI.updateEntity({
                    type:props?.type,
                    data: data
                });
            } else {
                
                result = await entityAPI.addEntity({
                    type:props?.type,
                    data: data
                });
            }

            if (result?.status) {
                setLoading(false)
                toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
                clearForm()
                props.loadData()
                props.setAddForm(false)
                clearStates()
                // dispatch(setGlobalLoading(false))

            } else {

                if (typeof result.errorMessage === "object") {
                    let updatedObject = resetErrors(entityErrorsObject)
                    setEntityErrorsObject(checkErrors(result.errorMessage, updatedObject))
                }
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))
                // dispatch(setGlobalLoading(false))

            }
            setLoading(false)
            dispatch(setGlobalLoading(false))

        } catch (error) {
            setLoading(false)
            toast.error(error?.message)
            dispatch(setGlobalLoading(false))

        }

    }

    const clearForm = () => {
        setName('')
        setFloorNumber('')
        setSkip(false)
        setImageList([])
        setDescription('')
        setListOfAddresses([])
        setSelectedPhase(null)
        setCompoundId(null)
        setPhases([])
      
    }
    const [compoundId, setCompoundId] = useState("")
    const getDataOfSelectedObject = async (id,Type) => {
        let result = null
        let type=Type
        if(type&&type?.slice(type?.length-1=='s')){
            type=type?.slice(0,type?.length-1);
        }
        
        try {
            result = await entityAPI.entity({
                type:type,
                id: id,
                params:{
                    id:id,
                }
            })

            if (result?.status) {
               
                setName(result?.data?.name)
                setFloorNumber(result?.data?.floor_number)
                setSkip(result?.data?.skip)
                setDescription(result?.data?.description)
                setSelectedPhase(result?.data?.compound_phase)
                setCompoundId(result?.data?.compound?result?.data?.compound?.id:result?.data?.id)
                let images = []
                result?.data?.images && result?.data?.images?.length > 0 && result?.data?.images?.map((image, index) => {
                    // let checkImage = getExtension(image?.file_path)
                    images.push({
                        url: process.env.REACT_APP_API_URL_image + image?.image_url,
                        file: null,
                        index: index,
                        id: image?.id,
                        type: 'image',
                        name: image?.name,
                        description:image?.description,
                        image_type:image?.image_type,
                    })
                })
                let addArr=[]
                result?.data?.addresses&&result?.data?.addresses?.length>0&&result?.data?.addresses?.map((itm,index)=>{
                     let code1='',code2='',code3=''
                     let phone1='',phone2='',phone3=''

                     if(itm?.first_phone){
                       let temp=itm?.first_phone?.split('-');
                       code1=temp[0]
                       phone1=temp[1]
                    }
                     if(itm?.second_phone){
                        let temp=itm?.second_phone?.split('-');
                        code2=temp[0]
                        phone2=temp[1]
                    }
                    if(itm?.third_phone){
                        let temp=itm?.third_phone?.split('-');
                        code3=temp[0]
                        phone3=temp[1]
                    }

                     addArr.push({
                        ...itm,
                        code1:code1,
                        code2:code2,
                        code3:code3,
                        first_phone:"0"+phone1,
                        second_phone:"0"+phone2,
                        third_phone:"0"+phone3,
                        index:index,

                     })
                })
                setListOfAddresses(addArr)
                setImageList(images)

                setLoading(false)
                // toast.success(t('NETWORKMESSAGE.addCustomerMessageSuccess'))
            } else {
                setLoading(false)
                toast.error(t('NETWORKMESSAGE.messageError'))

            }
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toast.error(error.message)
        }
    }

    useEffect(() => {
        let abortController = new AbortController();
        

        
        if (!props?.entity&&!props?.addMode) {
            clearForm()
        }
        if(!props?.entity&&props?.addMode){
            clearForm()
           
            if(props?.addMode?.type=='blocks'){
               
                loadDataOfPhases({
                    compound_id:props?.addMode?.entity?.id
                })
            }else if(props?.addMode?.type=='buildings'){
               
               
                loadDataOfPhases({
                    compound_id:props?.addMode?.entity?.compound_id
                })
            }else if(props?.addMode?.type=='floors'){
             
              
                loadDataOfPhases({
                    compound_id:props?.addMode?.entity?.compound?.id
                })
            }
        }

        else {
            if(props?.entity?.id&&props?.type)
            getDataOfSelectedObject(props?.entity?.id,props?.type)
        }
        return()=>{
            abortController.abort();
        }
    }, [props?.addForm, props?.entity,props?.type])

    useEffect(() => {
      if(compoundId)
      {
        loadDataOfPhases()
      }
    
      return () => {
        
      }
    }, [compoundId])
    

    const handleGetNewAddress=async(newAddress)=>{
        if(!props?.entity?.id){
            setListOfAddresses(itm=>[...itm,{
                ...newAddress,
                index:listOfAddresses[listOfAddresses?.length-1]&&listOfAddresses[listOfAddresses?.length-1]?.index?listOfAddresses[listOfAddresses?.length-1]?.index+1:1,
            }
            ])
            setAddAddresses(false)
            setSelectedAddress(null)

       }else{
         dispatch(setGlobalLoading(true))
        try{
            let type=props?.type
            if(type&&type?.slice(type?.length-1=='s')){
            type=type?.slice(0,type?.length-1);
            }
            if(!selectedAddress){
                    let data=await entityAPI.addAddress({
                        type:type,
                        type_id:props?.entity?.id,
                        data:{
                            ...newAddress,
                            governorate_id:newAddress?.governorate?.id,
                        },
                    })
                    if(data&&data?.status){
                        let code1='',code2='',code3=''
                        let phone1='',phone2='',phone3=''
   
                       if(newAddress?.first_phone){
                          let temp=newAddress?.first_phone?.split('-');
                          code1=temp[0]
                          phone1=temp[1]
                       }
                        if(newAddress?.second_phone){
                           let temp=newAddress?.second_phone?.split('-');
                           code2=temp[0]
                           phone2=temp[1]
                       }
                       if(newAddress?.third_phone){
                           let temp=newAddress?.third_phone?.split('-');
                           code3=temp[0]
                           phone3=temp[1]
                       }

                      if(data?.data?.addresses){
                        let addArr=[]
                        data?.data?.addresses&&data?.data?.addresses?.length>0&&data?.data?.addresses?.map((itm,index)=>{
                             let code1='',code2='',code3=''
                             let phone1='',phone2='',phone3=''
        
                             if(itm?.first_phone){
                               let temp=itm?.first_phone?.split('-');
                               code1=temp[0]
                               phone1=temp[1]
                            }
                             if(itm?.second_phone){
                                let temp=itm?.second_phone?.split('-');
                                code2=temp[0]
                                phone2=temp[1]
                            }
                            if(itm?.third_phone){
                                let temp=itm?.third_phone?.split('-');
                                code3=temp[0]
                                phone3=temp[1]
                            }
        
                             addArr.push({
                                ...itm,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                                index:index,
        
                             })
                        })
                        setListOfAddresses(addArr)
                      }else{
                        setListOfAddresses(itm=>[...itm,{
                                ...newAddress,
                                index:listOfAddresses[listOfAddresses?.length-1]&&listOfAddresses[listOfAddresses?.length-1]?.index?listOfAddresses[listOfAddresses?.length-1]?.index+1:1,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                            
                            }
                        ])
                     }

                      setAddAddresses(false)
                      setSelectedAddress(null)
                      dispatch(setGlobalLoading(false))

                    }else{
                        setAddAddresses(false)
                        dispatch(setGlobalLoading(false))

                    }

            }else{
                
                let data=await entityAPI.updateAddress({
                    id:newAddress?.id,
                    type:type,
                    type_id:props?.entity?.id,
                    data:{
                        ...newAddress,
                        governorate_id:newAddress?.governorate?.id,

                    },
                })
                if(data&&data?.status){
                    let arr=[]
                    listOfAddresses&&listOfAddresses?.length>0&&listOfAddresses?.map((itm)=>{
                        if(itm?.id==newAddress?.id){
                            let code1='',code2='',code3=''
                            let phone1='',phone2='',phone3=''
       
                            if(newAddress?.first_phone){
                              let temp=newAddress?.first_phone?.split('-');
                              code1=temp[0]
                              phone1=temp[1]
                           }
                            if(newAddress?.second_phone){
                               let temp=newAddress?.second_phone?.split('-');
                               code2=temp[0]
                               phone2=temp[1]
                           }
                           if(newAddress?.third_phone){
                               let temp=newAddress?.third_phone?.split('-');
                               code3=temp[0]
                               phone3=temp[1]
                           }

                            arr.push({
                                ...itm,
                                ...newAddress,
                                code1:code1,
                                code2:code2,
                                code3:code3,
                                first_phone:"0"+phone1,
                                second_phone:"0"+phone2,
                                third_phone:"0"+phone3,
                            })
                        }else{
                           arr.push(itm)
                        }
                        setListOfAddresses(arr)
                    })
                    setAddAddresses(false)
                    setSelectedAddress(null)
                    dispatch(setGlobalLoading(false))
                
                }else{
                    setAddAddresses(false)
                    dispatch(setGlobalLoading(false))
                    toast.error(t('NETWORKMESSAGE.messageError'))
                }

            }
        }catch(err){
          
         dispatch(setGlobalLoading(false))

        }
       }

    }

    useEffect(()=>{
        return()=>{
            setAddAddresses(false)
            setListOfAddresses([])
        }
    },[])

    const clearStates=()=>{
        setAddAddresses(false)
        setListOfAddresses([])
    }
    const loadDataOfPhases=async(searchData)=>{
        let data=null
        try{
           
            data=await CompoundPhaseAPI.CompoundPhaseList({
                params:{
                    compound_id:compoundId,
                    ...searchData,
                }
            })
            if(data&&data?.status){
                
               setPhases(data?.data)
            }else{
              toast.error(t('NETWORKMESSAGE.messageError'))
            }
        }catch(err){
            toast.error(t('NETWORKMESSAGE.messageError'))
            
        }
    }

    
    return (
        <PopupForm
            width={'100%'}
            customeHeight={'100%'}
            open={props.addForm}
            setOpen={props.setAddForm}
            customePadding="10px 20px !important"
            isFullScreen={true}
            title={props?.entity ? t(props?.type?.toUpperCase()+'.updateTitle') : t(props?.type?.toUpperCase()+'.addTitle')}
            content={<RenderContent
                name={name}
                setName={setName}
                floorNumber={floorNumber}
                setFloorNumber={setFloorNumber}
                editMode={props?.entity?.id?true:false}
                skip={skip}
                setSkip={setSkip}
                type={props?.type}
                type_id={props?.entity?.id}
                description={description}
                setDescription={setDescription}
                open={props.addForm}
                setImageList={setImageList}
                imageList={imageList}
                setOpen={props.setAddForm}
                setAddAddresses={setAddAddresses}
                addAddress={addAddress}
                setListOfAddresses={setListOfAddresses}
                listOfAddresses={listOfAddresses}
                handleGetNewAddress={handleGetNewAddress}
                setSelectedPhase={setSelectedPhase}
                selectedPhase={selectedPhase}
                setPhases={setPhases}
                phases={phases}
                setSelectedAddress={setSelectedAddress}
                selectedAddress={selectedAddress}
                isViewMode={props?.isViewMode}

            />}
            footer={<RenderFooter
                open={props.addForm}
                type={props?.type}
                setOpen={props.setAddForm}
                submit={submit}
                clearStates={clearStates}
                isViewMode={props?.isViewMode}
                setIsViewMode={ props?.setIsViewMode}
                setSelectedEntity={props?.setSelectedEntity}
                setParentId={props?.setParentId}
                setEntityType={props?.setEntityType}
            />}
        />
    )
}
