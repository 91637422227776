import * as React from 'react';
import { styled } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import { useTranslation } from 'react-i18next';
import PlaceIcon from '@mui/icons-material/Place';
import DropDownGrid from '../../../Grid/Components/DropDownGrid'
import { Box, IconButton,MenuItem, ListItemIcon, Typography, Divider, Autocomplete, TextField } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit';
import entityAPI from '../../../../Global/Network/Entity';
import { useSelector } from 'react-redux';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: '#1E6A99 !important',
      color: theme.palette.common.white,
      outerHeight:'30px !important'
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));
  
  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
    '& td':{
        borderLeft:'0px',
        borderRight:'0px',
    },
    '& th':{
        padding:'0px !important',
    }
  }));
  

export default function AddressesTable(props) {
  const [t]=useTranslation('common')
  const rolesRedux = useSelector(state => state.rolesData.roles)


  const handleRemoveLocation=async(obj)=>{
    // alert(obj?.id)
    if(obj?.id){
    try{
      let type=props?.type
      if(!props?.deleteAddressNotHasSlice&&type&&type!='company'&&type?.slice(type?.length-1=='s')){
      type=type?.slice(0,type?.length-1);
      }

      let data=await entityAPI.deleteAddress({
        type:type,
        id:obj?.id,
      })
      if(data&&data?.status){
        let arr=[];
        props?.listOfAddresses&& props?.listOfAddresses?.map((itm)=>{
           if(itm?.id!=obj?.id){
             arr.push(itm)
           }
        })
        props.setListOfAddresses(arr)
      }else{

      }
    }catch(err){
      console.log(err?.message)
    }
  }else{
    let arr=[];
    props?.listOfAddresses&& props?.listOfAddresses?.map((itm)=>{
       if(itm?.index!=obj?.index){
         arr.push(itm)
       }
    })
    props.setListOfAddresses(arr)
   }
  }

  return (
    <Box sx={{width:'100%',margin:'10px 20px 10px 10px',
    '& th':{
        padding:'5px 15px !important',
    }
    }}>
    <TableContainer >
      <Table sx={{ minWidth: '100%' }} aria-label="customized table">
        <TableHead sx={{height:'30px !important'}}>
          <TableRow sx={{height:'30px !important'}}>
            <StyledTableCell>#</StyledTableCell>
            <StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>
               <Box  sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    flexDirection:'column',
                }}>
                 <span  style={{fontFamily:'Cairo-Bold'}}> {t('ADDRESSES.name')}</span>
                 <span  style={{fontFamily:'Cairo-Bold'}}> {t('ADDRESSES.address_name')}</span>
                </Box>
            </StyledTableCell>
            <StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>{t('ADDRESSES.main_phone')}</StyledTableCell>
            <StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>
                <Box  sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    flexDirection:'column',
                }}>
                 <span  style={{fontFamily:'Cairo-Bold'}}> {t('ADDRESSES.other_phones')}</span>
                 {/* <span  style={{fontFamily:'Cairo-Bold'}}> {t('ADDRESSES.third_phone')}</span> */}
                </Box>
            </StyledTableCell>
            <StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>
                {/* {t('ADDRESSES.longitude')} */}
                <Box  sx={{
                    display:'flex',
                    justifyContent:'center',
                    alignItems:'start',
                    flexDirection:'column',
                }}>
                  <PlaceIcon sx={{color:'#fff',fontSize:'30px'}}/>
                </Box>
            </StyledTableCell>
            <StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>{t('ADDRESSES.governorates')}</StyledTableCell>
            {!props?.isViewMode?<StyledTableCell align="left" sx={{fontFamily:'Cairo-Bold'}}>{t('ADDRESSES.options')}</StyledTableCell>:null}
          </TableRow>
        </TableHead>
        <TableBody>
          {props?.listOfAddresses?.length>0?props?.listOfAddresses?.map((row) => (
            <StyledTableRow key={row.name}>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>
                  <Box  sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    flexDirection:'column',
                    fontWeight:'bold',
                }}>
                {row.index}
                </Box>
              </StyledTableCell>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>
                <Box  sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    flexDirection:'column',
                }}>
                    <span style={{fontFamily:'Cairo-Medium'}}>{row.name}</span>
                    <span style={{fontFamily:'Cairo-Medium',color:'gray'}}>{row.address}</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo',direction:'rtl'}}>{row.first_phone}</StyledTableCell>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>
                    <Box  sx={{
                        display:'flex',
                        justifyContent:'start',
                        alignItems:'start',
                        flexDirection:'column',
                    }}>
                        <span style={{fontFamily:'Cairo-Medium',direction:'ltr'}}>{row.second_phone}</span>
                        <span  style={{fontFamily:'Cairo-Medium',direction:'ltr'}}>{row.third_phone}</span>
                   </Box>
                </StyledTableCell>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>
                <Box  sx={{
                    display:'flex',
                    justifyContent:'start',
                    alignItems:'start',
                    flexDirection:'column',
                }}>
                  <span  style={{fontFamily:'Cairo-Medium'}}>{row.longitude}</span> 
                  <span  style={{fontFamily:'Cairo-Medium'}}>{row.latitude}</span>
                </Box>
              </StyledTableCell>
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>{row.governorate?.name}</StyledTableCell>
              {!props?.isViewMode?
              <StyledTableCell align="left" sx={{fontFamily:'Cairo'}}>
             
                    <DropDownGrid>
                        {
                        props?.editMode&&rolesRedux?.update_address?.value&&!props?.isViewMode?
                        <MenuItem onClick={() => { props?.handleUpdateAddress(row)  }} className="ActionIcons">
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0px 7px 0 0'
                        }}>
                            <EditIcon style={{ fill: "#1E6A99" }} />

                        </ListItemIcon>
                        {t('GLOBAL.action_edit')}
                        </MenuItem>:null
                        }
                        {rolesRedux?.delete_address?.value&&!props?.isViewMode?
                        <MenuItem onClick={() => { handleRemoveLocation(row) }} className="ActionIcons">
                        <ListItemIcon sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            margin: '0px 7px 0 0'
                        }}>
                            <DeleteIcon sx={{color:'red'}}/>
                        </ListItemIcon>
                        {t('GLOBAL.action_delete')}

                        </MenuItem>:null}
                        {!rolesRedux?.delete_address?.value&&!rolesRedux?.update_address?.value?<Box sx={{
                          width:'100%',
                          justifyContent:'center',
                          alignItems:'center',
                          padding:'5px 20px'
                        }}>
                          <span style={{fontFamily:'Cairo-Light'}}>{t('GLOBAL.noData')}</span>
                        </Box>:null}
                    </DropDownGrid>

              </StyledTableCell>:null}
            </StyledTableRow>
          )):
            <StyledTableCell component="th" scope="row" colSpan={7}>
                <Box sx={{fontFamily:'Cairo-Medium',display:'flex',justifyContent:"center",alignItems:'center'}}>
                    {t('GLOBAL.noData')}
                </Box>
            </StyledTableCell>
        }
        </TableBody>
      </Table>
    </TableContainer>

    </Box>
  )
}
